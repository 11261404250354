import { ShareBlock } from '@shared/ShareBlock/ShareBlock';
import styles from './ShareDetails.module.scss';
import { CrossSvg } from '@assets/colorless';
import { Button } from '@shared/Button/Button';

type ShareDetailsProps = {
  onClose: () => void;

  title: string;
  text: string;
  image: string;
  url?: string;
};

export const ShareDetails = (props: ShareDetailsProps) => {
  return (
    <div className={styles.root}>
      <ShareBlock
        className={styles.socials}
        limit={13}
        title={props.title}
        msg={props.text}
        image={props.image}
        url={props.url}
      />
      <Button type="button" onClick={props.onClose} Icon={<CrossSvg />} onlyIcon className={styles.button} />
    </div>
  );
};
