export const booleanFromString = (v: string) => {
  switch (v) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return false;
  }
};
