import React, { useEffect, useRef, useState } from 'react';
import styles from './Overview.module.scss';
import { Trans } from 'react-i18next';
import { slideData } from './slides';
import Parallax from 'parallax-js';
import { ReactComponent as ShortArrowRight } from './images/short_arrow_right.svg';
import { ReactComponent as ShortArrowLeft } from './images/short_arrow_left.svg';
import { Link, useHistory } from 'react-router-dom';
import { BROWSER_DASH } from '@utils/links';

export const delay = async (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const Overview = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [frameClass, setFrameClass] = useState(`${styles.Overview__content}`);
  const contentDiv = useRef<HTMLDivElement>(null);
  const dotsDiv = useRef<HTMLDivElement>(null);
  const arrowRight = useRef<HTMLButtonElement>(null);
  const arrowLeft = useRef<HTMLButtonElement>(null);
  const [swipeStart, setSwipeStart] = useState(null);
  const [swipeEnd, setSwipeEnd] = useState(null);
  const history = useHistory();
  const [width, setWidth] = useState(window.innerWidth);

  const minSwipeDistance = 75;

  const onSwipeStart = (e: any, desktop: boolean = false) => {
    setSwipeEnd(null);
    setSwipeStart(desktop ? e.clientX : e.targetTouches[0].clientX);
  };

  const onSwipeMove = (e: any, desktop: boolean = false) =>
    setSwipeEnd(desktop ? e.clientX : e.targetTouches[0].clientX);

  const onSwipeEnd = () => {
    if (!swipeStart || !swipeEnd || frameClass !== `${styles.Overview__content}`) {
      setSwipeStart(null);
      return;
    }

    const distance = swipeStart - swipeEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    if (isRightSwipe) {
      arrowLeft.current?.click();
    } else if (isLeftSwipe) {
      arrowRight.current?.click();
    }
  };

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const checkBool = () =>
    new Promise((resolve) => {
      contentDiv.current!.addEventListener('transitionend', resolve);
    });

  const checkLoadImg = () =>
    new Promise((resolve) => {
      contentDiv.current?.childNodes[1].addEventListener('load', resolve);
    });

  const handleArrows = (e: any) => {
    if (frameClass !== `${styles.Overview__content}`) {
      return;
    } else if (e.code === 'ArrowRight') {
      arrowRight.current?.click();
    } else if (e.code === 'ArrowLeft') {
      arrowLeft.current?.click();
    }
  };

  const handleExternalChangeSlide = async (newSlideIndexToShow: number) => {
    const defaultFrameState = frameClass;
    if (newSlideIndexToShow < activeSlide) {
      setFrameClass(`${styles.Overview__content} ${styles.Overview__content__slideEntryBack}`);
      await checkBool();
      setActiveSlide(newSlideIndexToShow);
      setFrameClass(`${styles.Overview__content} ${styles.Overview__content__slideBeforeBack}`);
      await checkLoadImg();
      await delay(60);
      setFrameClass(`${styles.Overview__content} ${styles.Overview__content__slideExit}`);
      await checkBool();
    } else {
      setFrameClass(`${styles.Overview__content} ${styles.Overview__content__slideEntryForward}`);
      await checkBool();
      setActiveSlide(newSlideIndexToShow);
      setFrameClass(`${styles.Overview__content} ${styles.Overview__content__slideBeforeForward}`);
      await checkLoadImg();
      await delay(60);
      setFrameClass(`${styles.Overview__content} ${styles.Overview__content__slideExit}`);
      await checkBool();
    }
    setFrameClass(defaultFrameState);
  };

  const scrollOnLoad = () => window.scrollTo(0, 1);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    document.addEventListener('keydown', handleArrows);
    const scenes = document.querySelectorAll('[data-scene]');
    scenes.forEach((el) => new Parallax(el as HTMLElement));
    window.addEventListener('load', () => window.scrollTo(0, 1));
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
      document.removeEventListener('keydown', handleArrows);
      window.removeEventListener('load', () => scrollOnLoad);
    };
  }, []);

  const reversedLink = (link: {
    name: string;
    icon: React.ReactNode;
    link: string;
    isReversed?: boolean | undefined;
    isBlack?: boolean;
  }) => {
    if (link.isReversed === true) {
      return (
        <Link
          key={link.name}
          to={{ pathname: link.link }}
          className={`${styles.Overview__link__blue} ${styles.Overview__link}`}
          rel="noreferrer"
        >
          <span>
            <Trans i18nKey={link.name} components={{ b: <b /> }} />
          </span>
          <img src={link.icon as string} alt={link.name} className={styles.Overview__link__icon2} />
        </Link>
      );
    } else {
      return (
        <Link
          key={link.name}
          to={{ pathname: link.link }}
          className={link.isBlack ? `${styles.Overview__link} ${styles.Overview__link__black}` : styles.Overview__link}
          rel="noreferrer"
        >
          {link.isBlack ? (
            <img src={link.icon as string} alt={link.name} className={styles.Overview__link__icon} />
          ) : (
            link.icon
          )}
          <span>
            <Trans i18nKey={link.name} components={{ b: <b /> }} />
          </span>
        </Link>
      );
    }
  };
  const renderLinks = () => {
    const currentLinks = slideData[activeSlide]?.links;

    if (!currentLinks || currentLinks.length === 0) {
      return null;
    }
    return currentLinks.map((link) => reversedLink(link));
  };

  const handleClose = () => {
    history.goBack();
  };

  const handleEndClose = () => {
    history.push(BROWSER_DASH);
  };

  const isMobile = width <= 768;

  return (
    <>
      <div className={`${styles.Overview}`}>
        <div className={styles.Overview__container}>
          <button className={styles.close} onClick={handleClose}>
            <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.40078 7.49999L0.600781 2.69999C0.158953 2.25816 0.158954 1.54182 0.600781 1.09999C1.04261 0.65816 1.75895 0.65816 2.20078 1.09999L7.00078 5.89999L11.8008 1.09999C12.2426 0.658159 12.959 0.65816 13.4008 1.09999C13.8426 1.54182 13.8426 2.25816 13.4008 2.69999L8.60078 7.49999L13.4008 12.3C13.8426 12.7418 13.8426 13.4582 13.4008 13.9C12.959 14.3418 12.2426 14.3418 11.8008 13.9L7.00078 9.09999L2.20078 13.9C1.75895 14.3418 1.04261 14.3418 0.600781 13.9C0.158953 13.4582 0.158953 12.7418 0.600781 12.3L5.40078 7.49999Z"
                fill="#B3B3B3"
              />
            </svg>
          </button>
          <div
            className={`${styles.Overview__container} ${styles.Overview__container__second}`}
            onMouseDown={(e) => onSwipeStart(e, true)}
            onMouseUp={onSwipeEnd}
            onMouseMove={(e) => onSwipeMove(e, true)}
            onTouchStart={onSwipeStart}
            onTouchEnd={onSwipeEnd}
            onTouchMove={onSwipeMove}
          >
            <div className={frameClass} ref={contentDiv}>
              <div className={styles.Overview__textBlock}>
                <div className={styles.Overview__title}>
                  <Trans i18nKey={slideData[activeSlide].title} components={{ b: <b /> }} />
                </div>
                <div className={styles.Overview__text}>
                  <Trans i18nKey={slideData[activeSlide].text} components={{ b: <b /> }} />
                </div>
                <div className={styles.Overview__links}>{slideData[activeSlide].links !== null && renderLinks()}</div>
              </div>
              <img
                className={styles.Overview__picture}
                src={isMobile ? slideData[activeSlide].imageMobile : slideData[activeSlide].image}
                alt={slideData[activeSlide].title}
              />
            </div>
          </div>
          <div className={styles.Overview__pagination}>
            <button
              className={
                activeSlide === 0 ? `${styles.sliderButton} ${styles.sliderButton__hidden}` : `${styles.sliderButton}`
              }
              disabled={activeSlide === 0 || frameClass !== `${styles.Overview__content}`}
              ref={arrowLeft}
              onClick={() => handleExternalChangeSlide(activeSlide - 1)}
            >
              <ShortArrowLeft />
            </button>
            <div className={styles.sliderDots} ref={dotsDiv}>
              {slideData.map((_, index) => (
                <div
                  key={index}
                  className={`${styles.dot} ${activeSlide === index ? styles.activeDot : ''}`}
                  onClick={() => index !== activeSlide && handleExternalChangeSlide(index)}
                >
                  <i></i>
                  <b
                    className={index < activeSlide ? styles.filledDot : ''}
                    onAnimationEnd={() => activeSlide !== slideData.length - 1 && arrowRight.current?.click()}
                  ></b>
                </div>
              ))}
            </div>
            <button
              className={styles.sliderButton}
              disabled={frameClass !== `${styles.Overview__content}`}
              ref={arrowRight}
              onClick={() =>
                activeSlide === slideData.length - 1 ? handleEndClose() : handleExternalChangeSlide(activeSlide + 1)
              }
            >
              <ShortArrowRight />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
