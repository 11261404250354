import { PROMO_ERROR, PROMO_REQUEST, PROMO_SUCCESS } from '../constant';
import { AppDispatch } from '../../index';
import { rpc } from '../../backend/Rpc';
import { showToast, ToastKind } from '../../components/Toast/Toast';

export function createPromoCode() {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: PROMO_REQUEST });
      await rpc.transmit('farm.create-promo-code', {});
      const resp = await rpc.transmit('farm.promo-codes', {});
      dispatch({ type: PROMO_SUCCESS, promos: resp });
      showToast('Pop_Up_Code_Created', ToastKind.Success);
    } catch (e) {
      dispatch({ type: PROMO_ERROR });
    }
  };
}
