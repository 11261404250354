import React, { FC, FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import styles from './SupportFormBot.module.scss';
import { Button, ButtonVariant } from '@shared/index';
import { SupportWithdrawals } from '../component/SupportWithdrawals/SupportWithdrawals';
import { captcha } from '@utils/captcha';
import ReCAPTCHA from 'react-google-recaptcha';
import { ReactComponent as Arrow } from '@assets/colorless/arrowLeft.svg';
import { ReactComponent as Check } from '@assets/colorless/check.svg';
import { Withdrawal } from '../../../../backend/JsonRpcApi';
import { SupportError, SupportErrorVariant } from '../../SupportError';

type SupportFormBotProps = {
  withdrawals: Withdrawal[];
  getWithdrawals: () => void;
  onSubmit: (id: string) => void;
  onBack: () => void;
  hasRequested: boolean;
  isSubmitting?: boolean;
};

export const SupportFormBot: FC<SupportFormBotProps> = (props) => {
  const { withdrawals, getWithdrawals, onSubmit, onBack, hasRequested, isSubmitting } = props;
  const googleRe = useRef<ReCAPTCHA>(null);
  const [selectedWithdrawalId, setSelectedWithdrawalId] = useState<string | null>(null);
  const [error, setError] = useState<SupportError>({
    error: '',
    variant: SupportErrorVariant.Default,
  });

  useEffect(() => {
    document.body.classList.add('bot');
    return () => {
      document.body.classList.remove('bot');
    };
  }, []);

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      if (!googleRe.current?.getValue()) {
        return setError({
          error: 'Enter captcha',
          variant: SupportErrorVariant.Captcha,
        });
      }

      if (!selectedWithdrawalId) {
        return setError({
          error: 'Enter captcha',
          variant: SupportErrorVariant.SelectedWithdrawal,
        });
      }

      onSubmit(selectedWithdrawalId!);
    },
    [selectedWithdrawalId, onSubmit]
  );

  return (
    <div className={styles.container}>
      <div className={styles.decoration}></div>
      <form className={styles.card} onSubmit={handleSubmit}>
        <div className={styles.title}>Withdraw request</div>
        <div className={styles.body}>
          <SupportWithdrawals
            getWithdrawals={getWithdrawals}
            selectedWithdrawalId={selectedWithdrawalId}
            withdrawals={withdrawals}
            onWithdrawalSelect={(id) => setSelectedWithdrawalId(id)}
            error={error}
            hasRequested={hasRequested}
          />
          <ReCAPTCHA sitekey={captcha} ref={googleRe} className={styles.captcha} />
          <div className={styles.error}>{error.variant === SupportErrorVariant.Captcha && error.error}</div>
        </div>
        <div className={styles.buttons}>
          <Button onClick={onBack} type="button" Icon={<Arrow />} size="medium">
            BACK
          </Button>
          <Button
            type="button"
            variant={ButtonVariant.Primary}
            size="medium"
            Icon={<Check color="#fff" />}
            disabled={isSubmitting}
          >
            SUBMIT
          </Button>
        </div>
      </form>
    </div>
  );
};
