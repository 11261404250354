import styles from './Socials.module.scss';
import { configSocials } from '@shared/Socials/config';

export default function Socials() {
  return (
    <div className={styles.container}>
      {configSocials.map((s) => (
        <a key={s.id} href={s.link} target="_blank" className={styles.item}>
          {s.Icon}
        </a>
      ))}
    </div>
  );
}
