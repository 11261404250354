import { ChevronDownSvg, IconWalletSvg } from '@assets/colorless';
import { jsColors } from '@utils/jsColors';
import { ReactComponent as Plus } from '@assets/colorless/plus.svg';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useMemo, useRef } from 'react';
import useClickOutside from '@hooks/useClickOutside';
import { WithdrawAddresses, WithdrawalsState } from '@service/reducers/withdrawalsReducer';
import { NcwLogo } from '@assets/colored';
import cn from 'classnames';
import styles from './WithdrawalView.module.scss';
import { BROWSER_WITHDRAWAL_NCW_WALLET } from '@utils/links';
import { Button } from '@shared/Button/Button';

type WithdrawalAddressesProps = {
  show: boolean;
  setShow: (v: boolean) => void;
  openAddressList: () => void;
  withdrawals: WithdrawalsState;
  handleAddressClick: (e: React.MouseEvent<HTMLDivElement>, item: WithdrawAddresses) => void;
  isBitcoin: boolean;
};

export default function WithdrawalAddresses(props: WithdrawalAddressesProps) {
  const { show, setShow, withdrawals, openAddressList, handleAddressClick, isBitcoin } = props;
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const handleClose = useCallback(() => setShow(false), [setShow]);
  useClickOutside(ref, handleClose, show);

  const withdrawalLast = useMemo(
    () => withdrawals.withdrawalsAddresses.find((address) => !address.is_local),
    [withdrawals.withdrawalsAddresses]
  );

  return (
    <>
      <div className={styles.group__select} onClick={openAddressList} ref={ref}>
        <ChevronDownSvg
          className={cn(`arr ${styles.group__select_arr}`, show && styles[`group__select_arr--open`])}
          color={jsColors.blue}
        />
        <div className={cn(styles.group__list, show && styles[`group__list--show`])}>
          {withdrawals.withdrawalsAddresses.map((item) => {
            return (
              <div key={item.address} onClick={(e) => handleAddressClick(e, item)} className={styles.group__list_item}>
                <>
                  <div
                    className={cn(
                      styles.group__list_container,
                      item.address === withdrawalLast?.address && styles[`group__list_container--recent`]
                    )}
                  >
                    <NcwLogo /> <span className={styles.group__list_address}>{item.address}</span>
                  </div>
                  {item.address === withdrawalLast?.address && <WithdrawalAddressesLabel text={t('recent')} />}
                </>
              </div>
            );
          })}
          <Button
            to={`${BROWSER_WITHDRAWAL_NCW_WALLET}/${isBitcoin ? 'BTC' : 'HSH'}`}
            className={styles.group__list_item}
            type={'link'}
            Icon={<Plus />}
          >
            {t('Add New Address')}
          </Button>
        </div>
      </div>
    </>
  );
}

export const WithdrawalAddressesLabel = ({ text, className }: { text: string; className?: string }) => (
  <span className={cn(styles.recent, className)}>{text}</span>
);
