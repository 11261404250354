import { POOL_LANDINGS_ERROR, POOL_LANDINGS_REQUEST, POOL_LANDINGS_SUCCESS } from '@service/constant';
import { AppDispatch } from '../..';
import { rpc } from '../../backend/Rpc';

export const getPoolLandings = () => {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch({ type: POOL_LANDINGS_REQUEST });
      const resp = await rpc.transmit('promo.get_pool_landings', {});
      const lands = resp.map((item) => ({
        ...item,
        url: item.url.replace(/{USER_ID}/gi, ''),
      }));
      dispatch({ type: POOL_LANDINGS_SUCCESS, lands });
    } catch (e) {
      dispatch({ type: POOL_LANDINGS_ERROR });
      console.log(e);
    }
  };
};
