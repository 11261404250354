import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NewsElement } from '@service/reducers/newsReducer';
import styles from './DashboardNews.module.scss';
import { NewsItem } from './NewsItem';
import { Root } from '@service/reducers';
import { LoadSpinner } from '../Loader';
import { Card } from '@shared/Card/Card';
import { ArrowRightSmSvg } from '@assets/colorless';
import { jsColors } from '@utils/jsColors';
import Socials from '@shared/Socials/Socials';
import { Button } from '@shared/Button/Button';
import { useBreakpoints } from '@hooks/useBreakpoints';

export function News() {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();
  const store = useSelector((store: Root) => store.news);
  const lengthOfTypes = useMemo(() => {
    const tipsLength = store.news.filter((i) => i.type === 'tips').length;
    const digestLength = store.news.filter((i) => i.type === 'digest').length;
    return { tipsLength, digestLength };
  }, [store.news]);

  return (
    <div className={styles.wrapper}>
      <Card
        className={styles.card}
        headerClass={styles.header}
        TitleElement={
          <div className={styles.dashboardNewsTitle}>
            <a href="https://cryptobrowser.site/news/" target="_blank" rel="noreferrer" className={styles.title}>
              <Trans i18nKey="CryptoTab News Center" components={{ b: <b /> }} />
              <Button type="button" Icon={<ArrowRightSmSvg color={jsColors.blue} />} onlyIcon />
            </a>
            <Socials />
          </div>
        }
      >
        <div className={styles.container}>
          {!store.newsRequeted ? (
            <LoadSpinner />
          ) : (
            <>
              <div className={styles.dashboardNewsWrapper}>
                <div className={styles.dashboardNewsTabs}>
                  <div className={`${styles.dashboardNewsTab} ${styles.dashboardNewsTab__active}`}>
                    <span>{t('All')}</span>
                    <b className={styles.dashboardNewsTabsNewsNumber}>{store.news.length}</b>
                  </div>
                  <a
                    target="_blank"
                    href="https://cryptobrowser.site/flow/"
                    rel="noreferrer"
                    className={styles.dashboardNewsTab}
                  >
                    <span>{t('Tips and News')}</span>
                    <b className={styles.dashboardNewsTabsNewsNumber}>{lengthOfTypes.tipsLength}</b>
                  </a>
                  <a
                    target="_blank"
                    href="https://cryptobrowser.site/digest/"
                    rel="noreferrer"
                    className={styles.dashboardNewsTab}
                  >
                    <span>{t('Industry News')}</span>
                    <p className={styles.dashboardNewsTabsNewsNumber}>{lengthOfTypes.digestLength}</p>
                  </a>
                </div>
                <div className={styles.dashboardNewsInner}>
                  {isMobile
                    ? store.news.slice(0, 3).map((item: NewsElement) => <NewsItem key={item.id} item={item} />)
                    : store.news.slice(0, 6).map((item: NewsElement) => <NewsItem key={item.id} item={item} />)}
                </div>
              </div>
            </>
          )}
        </div>
      </Card>
      <div className={styles.buttons}>
        <Button
          type="link"
          to={'https://cryptobrowser.site/news/'}
          size="medium"
          external
          iconPosition="right"
          Icon={<ArrowRightSmSvg />}
        >
          {t('View all')}
        </Button>
      </div>
    </div>
  );
}
