import { Container } from '@shared/index';
import CommonMobControls from './components/CommonMobControls/CommonMobControls';
import { ActivationsCard } from '@pages/FarmDashboard/view/components';
import { MinerCard } from './components/MinerCard';
import { useDispatch, useSelector } from 'react-redux';
import { Root } from '@service/reducers';
import { useEffect } from 'react';
import getPromos from '@service/actions/getPromos';
import { rootBalanceType } from '@service/reducers/balanceReducer';
import getBalances from '@service/actions/getBalances';
import { EarningsCard } from './components/EarnigsCard';

interface FarmStatisticsViewProps {}

const FarmStatisticsView: React.FC<FarmStatisticsViewProps> = () => {
  const dispatch = useDispatch();
  const balance = useSelector((store: { balance: rootBalanceType }) => store.balance);

  useEffect(() => {
    !balance.get_balance && dispatch(getBalances());
  }, [dispatch, balance.get_balance]);
  return (
    <Container marginTop={20} marginBottom={60}>
      <CommonMobControls />
      <ActivationsCard />
      <MinerCard />
      <EarningsCard />
    </Container>
  );
};

export default FarmStatisticsView;
