import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useState } from 'react';
import cn from 'classnames';
import { rpc } from '../../../../backend/Rpc';
import { Root } from '@service/reducers';
import { NcwLogo } from '@assets/colored';
import { Button, ButtonVariant } from '@shared/Button/Button';
import ModalLoader from '@shared/Loader/ModalLoader/ModalLoader';
import ModalNcwSuccess from '../Modals/ModalNcwSuccess';
import ModalNcwError from '../Modals/ModalNcwError';
import { ArrowRightSvg, Close, IconWalletSvg } from '@assets/colorless';
import styles from './WithdrawalNcwWalletView.module.scss';
import { useRouteMatch } from 'react-router-dom';
import { externalLinks } from '@utils/externalLinks';
import ModalBtcConnect from '@pages/WithdrawalNcwWallet/view/Modals/ModalBtcConnect';
import { BtcDecorImg, BtcDecorRetinaImg, HshDecorImg, HshDecorRetinaImg } from '@assets/img';

export const BTC_NCW_CONNECT = 'btcNcwConnect';
export const HSH_NCW_CONNECT = 'hshNcwConnect';

export default function WithdrawalNcwWalletView() {
  const userEmail = useSelector((store: Root) => store.user.email);
  const dispatch = useDispatch();
  const [email, setEmail] = useState(userEmail || '');
  const [isLoading, setIsLoading] = useState(false);
  const [isBlurEmail, setIsBlurEmail] = useState(false);
  const [isBtcModal, setIsBtcModal] = useState(false);
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [isErrorModal, setIsErrorModal] = useState(false);
  const { t } = useTranslation();
  const match = useRouteMatch<{ BTCOrHSH: string }>();

  const handleSubmit = useCallback(
    async (email: string) => {
      try {
        setIsLoading(true);
        const res = await rpc.transmit('ncw.find_account', { email });

        if (!res) throw new Error('Account not found');

        if (res) {
          const address = { address: res.email || res.account_id, is_ncw: true };
          localStorage.setItem(
            match.params.BTCOrHSH === 'BTC' ? BTC_NCW_CONNECT : HSH_NCW_CONNECT,
            JSON.stringify(address)
          );
          setIsSuccessModal(true);
        }
      } catch (e) {
        setIsErrorModal(true);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );

  const changeBtcIsShow = useCallback(() => {
    setIsBtcModal(false);
  }, []);

  const changeErrorIsShow = useCallback(() => {
    setIsErrorModal(false);
  }, []);

  const changeSuccessIsShow = useCallback(() => {
    setIsSuccessModal(false);
  }, []);

  const changeIsBlurEmail = useCallback(() => {
    if (!email) setIsBlurEmail(true);
  }, []);

  return (
    <div className={styles.wrapper}>
      {match.params.BTCOrHSH !== 'BTC' ? (
        <img src={BtcDecorImg} srcSet={BtcDecorRetinaImg + ' 2x'} alt="btc" className={styles.decoration} />
      ) : (
        <img src={HshDecorImg} srcSet={HshDecorRetinaImg + ' 2x'} alt="hsh" className={styles.decoration} />
      )}

      <div className={styles.wrapper__container}>
        <div className={styles.wrapper__body}>
          <NcwLogo className={styles.wrapper__logo} />
          <h2 className={styles.wrapper__title}>{t('Connect_NCW.Title')}</h2>
          <span className={styles.wrapper__text}>
            <Trans i18nKey={'Connect_NCW.Description'} components={{ b: <b /> }} />
          </span>
          <input
            type="text"
            placeholder={t('NCW_placeholder')}
            id="address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onBlur={changeIsBlurEmail}
            disabled={isLoading}
            className={cn(styles.wrapper__input, isBlurEmail && !email && styles['wrapper__input--error'])}
          />
          {match.params.BTCOrHSH === 'BTC' && (
            <Button
              type="button"
              onClick={() => setIsBtcModal(true)}
              Icon={<Close />}
              className={styles[`wrapper__btc-connect`]}
            >
              {t('Btc_address.Selection')}
            </Button>
          )}
        </div>
      </div>
      <div className={styles.wrapper__footer}>
        <Button
          onClick={() => window.open(externalLinks.ncw)}
          className={styles.wrapper__create}
          type={'button'}
          Icon={<IconWalletSvg />}
        >
          {t('CREATE WALLET')}
        </Button>
        <Button
          className={styles.wrapper__connect}
          type={'button'}
          variant={ButtonVariant.Primary}
          onClick={() => handleSubmit(email)}
          Icon={<ArrowRightSvg />}
          iconPosition={'right'}
          disabled={!email || isLoading}
          isLoading={isLoading}
        >
          {t('Connect')}
        </Button>
      </div>
      <ModalLoader isShow={isLoading} />
      <ModalBtcConnect isShow={isBtcModal} changeIsShow={changeBtcIsShow} BTCOrHSH={match.params.BTCOrHSH} />
      <ModalNcwSuccess
        isShow={isSuccessModal}
        changeIsShow={changeSuccessIsShow}
        email={email}
        BTCOrHSH={match.params.BTCOrHSH}
      />
      <ModalNcwError isShow={isErrorModal} changeIsShow={changeErrorIsShow} email={email} />
    </div>
  );
}
