import { SET_BROWSER_PERIOD } from '@service/constant';
import { Root } from '@service/reducers';
import { Card, DatePicker } from '@shared/index';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './CommonMobControls.module.scss';
export default function CommonMobControls() {
  const dispatch = useDispatch();
  const graphPeriod = useSelector((store: Root) => ({
    start: store.browserGraph.period_start,
    end: store.browserGraph.period_end,
  }));
  const onPeriodChange = useCallback(
    (period: { period_start: string; period_end: string }) => {
      dispatch({
        type: SET_BROWSER_PERIOD,
        ...period,
      });
    },
    [dispatch]
  );
  return (
    <Card className={styles.card}>
      <DatePicker period={graphPeriod} onPeriodChange={onPeriodChange} />
    </Card>
  );
}
