import { useTranslation } from 'react-i18next';
import Subheader from '../../components/Subheader';
import { HelmetHead } from '@pages/headersComponents/HelmetHead';
import WithdrawalNcwWalletView from './view/WithdrawalNcwWalletView';

export default function WithdrawalNcwWallet() {
  const { t } = useTranslation();

  return (
    <>
      <HelmetHead title={t('Connect NC Wallet')} />
      <Subheader />
      <WithdrawalNcwWalletView />
    </>
  );
}
