import { HTMLAttributes, PropsWithChildren } from 'react';
import styles from './Card.module.scss';
import cls from 'classnames';

export type CardProps = PropsWithChildren<
  HTMLAttributes<HTMLDivElement> & {
    title?: string;
    TitleElement?: React.ReactNode;
    Switch?: React.ReactNode | string;
    headerClass?: string;
    ref?: React.RefObject<HTMLDivElement>;
  }
>;

export function Card({ title, Switch, TitleElement, className, children, id, headerClass, ...rest }: CardProps) {
  return (
    <div className={cls([styles.card, className])} id={id} {...rest}>
      {(title || TitleElement) && (
        <div className={cls(styles.header, headerClass)}>
          {TitleElement || <span>{title}</span>}
          {Switch && Switch}
        </div>
      )}
      {children}
    </div>
  );
}
