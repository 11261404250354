import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Root } from '@service/reducers';
import { AggregatedFarmDataType, FarmDataType } from '@service/reducers/farmGraphReducer';

export type FarmLinks = {
  open: number; // clicks
  install: number; // referals
  revenue: number; // miners
  active: number;
};

export default function useFilteredFarmLinks(id?: number | string): FarmLinks {
  const store = useSelector((store: Root) => store.farmGraph);
  return useMemo(() => {
    let resp = {
      open: 0, // clicks
      install: 0, // referals
      revenue: 0, // miners
      active: 0,
    };

    // const miners = store.purchase_data;
    // const revenue = store.earning_data;
    const aggregatedData = store.aggregated_data;

    resp.active = aggregatedData.reduce(
      (acc: number, item: AggregatedFarmDataType) => (acc += Number(item.workers_count)),
      0
    );
    resp.revenue = +aggregatedData
      .reduce((acc: number, item: AggregatedFarmDataType) => acc + Number(item.value), 0)
      .toFixed(8);

    // resp.active = miners?.reduce((acc: number, item: FarmDataType) => (acc += Number(item.value)), 0);
    // resp.revenue = +revenue.reduce((acc: number, item) => acc + Number(item.value), 0).toFixed(8);

    return resp;
  }, [store, id]);
}
