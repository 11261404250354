import { AppDispatch } from '../..';
import {
  BROWSER_LINKS_ERROR,
  GET_POOL_LEVELS,
  POOL_CHANGE_EARNINGS_TOTAL,
  POOL_INSTALLS_SUCCESS,
  POOL_LINKS_REQUEST,
  POOL_LINKS_SUCCESS,
  POOL_MINERS_SUCCESS,
  POOL_OPENS_SUCCESS,
  POOL_STATISTIC_UPDATE,
} from '../constant';
import { rpc } from '../../backend/Rpc';
import { AffiliateGroup, Discreteness, Product } from '../../backend/JsonRpcApi';
import { Span } from '@shared/Graph/Tabs';
import { Item, mockLevels, searchItem } from '@utils/browserLevelsUtils';
import { EarningsStatisticsResponse } from '@service/reducers/browserStatsReducer';
import checkE from '@utils/checkE';
import { PoolResp } from '@service/reducers/poolStatisticReducer';
const updateTimestamp = <T extends { timestamp: number }>(arr: T[]): T[] =>
  arr.map((item) => ({ ...item, timestamp: item.timestamp * 1000 }));

export default function getPoolDashboardInfo(
  period_start: string,
  period_end: string,
  earningsDiscreteness: Discreteness,
  updateDate?: boolean
) {
  return async function (dispatch: AppDispatch) {
    try {
      const mockLevels = new Array(10).fill({ ref: 0, mined: 0 });
      const doEmptyLevels = (arr: PoolResp[], levels: Record<string, Item[]>) => {
        arr.forEach((item: PoolResp) => {
          if (!levels.hasOwnProperty(`${item.account_id}`)) {
            levels[`${item.account_id}`] = [...mockLevels];
          }
        });
      };
      let miners;
      if (updateDate) {
        dispatch({ type: POOL_LINKS_REQUEST });
        const opens = await rpc.transmit('statistics.v2.clicks', {
          start_date: period_start,
          end_date: period_end,
          discreteness: Span.Day,
          product_ids: [Product.Pool],
        });
        const updateOpens = opens
          .map((item) => ({ timestamp: item.timestamp, account_id: +item.advert_id, value: item.value }))
          .sort((a, b) => a.timestamp - b.timestamp);
        dispatch({ type: POOL_OPENS_SUCCESS, opens: updateOpens });
        const installs = await rpc.transmit('statistics.referrals', {
          start_date: period_start,
          end_date: period_end,
          discreteness: Span.Day,
        });
        const installsData = updateTimestamp(installs.items).sort((a, b) => a.timestamp - b.timestamp);
        dispatch({
          type: POOL_INSTALLS_SUCCESS,
          installs: installsData,
        });

        miners = await rpc.transmit('statistics.referrals.payments', {
          start_date: period_start,
          end_date: period_end,
          group: AffiliateGroup.Pool,
          discreteness: Span.Day,
        });
        const minersData = miners.items
          .map((item) => ({
            ...item,
            timestamp: item.timestamp! * 1000,
            value: item.users,
          }))
          .sort((a, b) => a.timestamp - b.timestamp);
        dispatch({
          type: POOL_MINERS_SUCCESS,
          miners: minersData,
        });
        dispatch({ type: POOL_MINERS_SUCCESS, miners: minersData });
        dispatch({ type: POOL_LINKS_SUCCESS });

        const levels: Record<string, Item[]> = {};
        let total: Item[] = [...mockLevels];
        installs.items.forEach((item) => {
          total[item.lvl - 1] = {
            ref: total[item.lvl - 1].ref + item.value,
            mined: 0,
          };
        });
        minersData.forEach((item) => {
          total[item.lvl - 1] = {
            ...total[item.lvl - 1],
            mined: total[item.lvl - 1].mined + item.value,
          };
        });

        doEmptyLevels(installs.items, levels);
        doEmptyLevels(minersData, levels);

        const levelsKeys = Object.keys(levels);
        levelsKeys.forEach((item: string) => {
          for (let lvl = 1; lvl <= 10; lvl++) {
            levels[item][lvl - 1] = {
              ref: searchItem(item, lvl, installs.items),
              mined: searchItem(item, lvl, minersData),
            };
          }
        });
        levels.total = total;
        dispatch({ type: GET_POOL_LEVELS, levels: levels });
      } else {
        miners = await rpc.transmit('statistics.referrals.payments', {
          start_date: period_start,
          end_date: period_end,
          group: AffiliateGroup.Pool,
          discreteness: earningsDiscreteness,
        });
      }

      const totalResp: EarningsStatisticsResponse[] = [...miners.items].map((item) => ({
        timestamp: item.timestamp ? item.timestamp * 1000 : undefined,
        value: +checkE(item.amount),
      }));
      const earningTotal = checkE([...miners.items].reduce((acc, item) => acc + item.amount, 0));
      dispatch({ type: POOL_CHANGE_EARNINGS_TOTAL, earningsTotal: earningTotal });
      dispatch({
        type: POOL_STATISTIC_UPDATE,
        data: totalResp,
        dataType: 'earnings',
      });
    } catch (e) {
      dispatch({ type: BROWSER_LINKS_ERROR });
      console.log(e);
    }
  };
}
