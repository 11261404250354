import React from 'react';
import { BrowserSubheader } from '../headersComponents/BrowserSubheader';
import { BrowserFeedbackView } from './view/BrowserFeedbackView';
import feedbackConfig from './feebackConfig.json';
import { HelmetHead } from '../headersComponents/HelmetHead';
import { useTranslation } from 'react-i18next';

export const BrowserFeedback = () => {
  const config = JSON.parse(JSON.stringify(feedbackConfig));
  const { t } = useTranslation();
  return (
    <>
      <HelmetHead title={t('Support')} />
      <BrowserSubheader />
      <BrowserFeedbackView feedbackConfig={config} />
    </>
  );
};
