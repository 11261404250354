import React from 'react';

export type PictureProps = {
  imageDesktop?: string;
  imageDesktop2x?: string;
  imageMob?: string;
  imageMob2x?: string;
  breakPoint?: string;
  className?: string;
  alt?: string;
  onClick?: () => void;
};

export const Picture = (props: PictureProps) => {
  const { imageDesktop, imageDesktop2x, imageMob, imageMob2x, breakPoint = '600px', className, alt, onClick } = props;

  return (
    <picture>
      {imageMob && (
        <source srcSet={`${imageMob}, ${imageMob2x ? imageMob2x : ''} 2x`} media={`(max-width: ${breakPoint})`} />
      )}
      <source srcSet={`${imageDesktop}, ${imageDesktop2x ? imageDesktop2x : ''} 2x`} />
      <img
        src={`${imageDesktop}`}
        srcSet={`${imageDesktop2x ? imageDesktop2x : ''} 2x`}
        className={className}
        alt={alt}
        onClick={onClick}
      />
    </picture>
  );
};
