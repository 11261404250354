import { build, version } from './version';
import getUtmParamsString from '../utils/getUtmParamsString';

function assembleAppParameters() {
  const av = version + '.' + build;

  return `av=${av}&v=0.0.0&app_id=web&platform=web`;
}

const app = assembleAppParameters();

export function getApiUrl(path: string): string {
  const origin = window.app_config.baseAPIURL;
  return `${origin}${path}`;
}

export function getFcmUrl(lang: string, did: string | null): string {
  const origin = window.app_config.baseAPIURL;
  const device = did ? '&did=' + did : '';
  return `${origin}fcm/register?sv=3.0.0&${app}&lang=${lang}${device}`;
}

export function getLoginMessagePathname(did: string | null): string {
  const path = 'login/message';
  const device = did ? '&did=' + did : '';
  const utm = getUtmParamsString();
  return `${path}?${app}${device}${utm}&lang=en`;
}

export function getDeviceRegisterPathname(): string {
  const path = 'devices/register';
  const utm = getUtmParamsString();
  return `${path}?${app}${utm}`;
}

export function getAuthTokensPathname(did: string | null): string {
  const origin = window.app_config.baseAPIURL;
  const path = 'auth/tokens';
  const device = did ? '&did=' + did : '';
  const utm = getUtmParamsString();
  return `${origin}${path}?${app}${device}${utm}`;
}

export function getRefreshTokenPathname(did: string | null): string {
  const origin = window.app_config.baseAPIURL;
  const path = 'auth/tokens/refresh';
  const device = did ? '&did=' + did : '';
  return `${origin}${path}?${app}${device}`;
}
