import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import styles from './BrowserGraph.module.scss';
import { BrowserResp, BrowserShortResp } from '@service/reducers/browserStatsReducer';
import { Span } from '@shared/Graph/Tabs';
import IdList from './IdList/IdList';
import {
  BROWSER_CHANGE_DISCREETNESS_INSTALLS,
  BROWSER_CHANGE_DISCREETNESS_OPENS,
  SET_BROWSER_PERIOD,
} from '@service/constant';
import { Root } from '@service/reducers';
import GraphComponent from '@shared/Graph/GraphComponent/GraphComponent';
import { GraphDateConfig } from '@utils/graph';

const DEFAULT_ID = 'All Link IDs';

export type BrowserGraphProps = {
  type: 'open' | 'install';
  id?: number;
};

const convertToShortResp = (data: (BrowserResp | BrowserShortResp)[]): BrowserShortResp[] => {
  return data
    .filter((item): item is BrowserShortResp => item.timestamp !== undefined)
    .map((item) => ({
      ...item,
      timestamp: item.timestamp as number,
    }));
};

function filterData<P>(setGraphData: (val: P[]) => void, data: P[], id: string) {
  if (id === DEFAULT_ID) {
    setGraphData(data);
  } else {
    const newData = data?.filter((item: any) => item.account_id === +id);
    setGraphData(newData);
  }
}

export default function BrowserGraph({ type, id }: BrowserGraphProps) {
  const { t } = useTranslation();
  const browserGraph = useSelector((store: Root) => store.browserGraph);
  const graphPeriod = useSelector((store: Root) => ({
    start: store.browserGraph.period_start,
    end: store.browserGraph.period_end,
  }));
  const installsDiscreteness = useSelector((store: Root) => store.browserGraph.installsDiscreteness);
  const opensDiscreteness = useSelector((store: Root) => store.browserGraph.opensDiscreteness);

  const period = type === 'open' ? (opensDiscreteness as Span) : (installsDiscreteness as Span);
  const idName = type === 'open' ? 'open' : 'install';
  const dispatch = useDispatch();

  const onPeriodChange = useCallback(
    (period: { period_start: string; period_end: string }) => {
      dispatch({
        type: SET_BROWSER_PERIOD,
        ...period,
      });
    },
    [dispatch]
  );

  const names = {
    open: t('Link Opens'),
    install: t('Referrals'),
  };
  const tooltip = type === 'open' ? t('Linkclicks.tooltip') : t('Registrations.tooltip');
  const data = useMemo(
    () => ({
      open: browserGraph.opens,
      install: browserGraph.installs,
    }),
    [browserGraph]
  );

  const [active, setActive] = useState(`${!!id ? id : DEFAULT_ID}`);
  const [graphData, setGraphData] = useState<(BrowserResp | BrowserShortResp)[]>([]);

  const handleChangePeriod = useCallback(
    (span: Span) => {
      dispatch({
        type: type === 'open' ? BROWSER_CHANGE_DISCREETNESS_OPENS : BROWSER_CHANGE_DISCREETNESS_INSTALLS,
        span: span,
      });
    },
    [type, dispatch]
  );

  const handleChangeId = useCallback(
    (val: string) => {
      setActive(val);
      filterData(setGraphData, graphData, val);
    },
    [graphData]
  );

  useEffect(() => {
    !browserGraph.stats_links_get && setGraphData(data[type]);
    const temp = convertToShortResp(data[type]);
    filterData(setGraphData, temp, active);
  }, [active, browserGraph.stats_links_get, data, type, period]);

  let total = useMemo(() => {
    const res = graphData.reduce((acc: number, item: any) => acc + item.value, 0);
    return res;
  }, [graphData]);

  return (
    <GraphComponent
      id={idName}
      SwitchElem={
        <IdList
          id={!!id && id + ''}
          active={t(active)}
          containerClass={styles.list__container}
          setActive={handleChangeId}
        />
      }
      total={total}
      tooltip={tooltip}
      onPeriodChange={onPeriodChange}
      period={graphPeriod}
      type={'value'}
      onSpanChanged={handleChangePeriod}
      span={period}
      title={names[type]}
      data={graphData}
    />
  );
}
