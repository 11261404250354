import { ModalOverlay } from '@shared/index';
import { ModalNetworkAlert } from '../../../../Withdrawal/view/WithdrawalModals/ModalNetworkAlert';
import styles from './Modal.module.scss';

type ModalProps = {
  onClose: () => void;
};

export function Modal({ onClose }: ModalProps) {
  return (
    <ModalOverlay onClick={onClose} active>
      <div onClick={(e) => e.stopPropagation()} className={styles.card}>
        <ModalNetworkAlert onClose={onClose} />
      </div>
    </ModalOverlay>
  );
}
