import { AppDispatch } from '../../index';
import {
  GET_WITHDRAWAL_ERROR,
  GET_WITHDRAWAL_REQUEST,
  GET_WITHDRAWAL_RESET,
  GET_WITHDRAWAL_SUCCESS,
} from '../constant';
import { rpc } from '../../backend/Rpc';
import { updateDataToBTC } from '@utils/updateDataToBTC';
import { Withdrawal } from '../../backend/JsonRpcApi';

export const getWithdrawal = (id: string, isBitcoin: boolean) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: GET_WITHDRAWAL_REQUEST });
    let res: Withdrawal;

    if (isBitcoin) {
      res = await rpc.transmit('withdrawals.get_withdrawal', { id });
    } else {
      let resHSH = await rpc.transmit('wallets.withdrawals.get', { id });
      res = updateDataToBTC(resHSH);
    }

    dispatch({ type: GET_WITHDRAWAL_SUCCESS, withdrawal: res });
  } catch (e) {
    dispatch({ type: GET_WITHDRAWAL_ERROR });
  }
};

export const resetWithdrawal = () => (dispatch: AppDispatch) => {
  dispatch({ type: GET_WITHDRAWAL_RESET });
};
