import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { LinkAcc } from '@service/reducers/balanceReducer';
import { Button } from '@shared/index';
import { BROWSER_DASH, MAIN_URL } from '@utils/links';
import styles from './LinkCard.module.scss';
import BoxWithLabel from '../BoxWithLabel/BoxWithLabel';
import CopyButton from '@shared/PromoCard/CopyButton';
import { ArrowLeftSmSvg, GeneratePost } from '@assets/colorless';
import { CopyText } from '@shared/CopyText/CopyText';

export type LinkCardProps = {
  link: LinkAcc;
  url?: string;
  onSave: (link: LinkAcc) => Promise<void>;
  onGeneratePost: () => void;
};

export function LinkCard(props: LinkCardProps) {
  const { link, url, onSave, onGeneratePost } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const goBack = useCallback(() => {
    if (location.key) {
      history.goBack();
    } else {
      history.push(MAIN_URL);
    }
  }, [history]);

  const handleSaveComment = useCallback(async (comment: string) => {
    await onSave({ ...link, comment });
  }, []);

  const handleSaveName = useCallback(async (name: string) => {
    await onSave({ ...link, name });
  }, []);

  if (link.type === 'cb_click') {
    window.open('https://cb.click/');
    history.replace({ pathname: BROWSER_DASH });
    return null;
  }

  return (
    <>
      <button type="button" onClick={goBack} className={styles.back}>
        <ArrowLeftSmSvg />
        {t('GO BACK')}
      </button>
      <div className={styles.container}>
        <div className={styles.top}>
          <div className={styles.label}>{t('Link ID / Code')}</div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.center}>
            <div className={styles.id}>{link.id}</div>
            <div className={styles.buttons}>
              <CopyText text={`${url}${link.id}`} isBtn={true} />
              <Button
                onClick={onGeneratePost}
                type="button"
                className={styles.btn}
                size="medium"
                Icon={<GeneratePost />}
              >
                {t('GeneratePost.button')}
              </Button>
            </div>
          </div>
          <div className={styles.bottom}>
            <BoxWithLabel
              text={`${url}${link.id}`}
              label={t('Default Address')}
              icon={<CopyButton copyText={`${url}${link.id}`} noBackground />}
              isBlue
            />
            <BoxWithLabel text={link.name || t('None')} label={t('Name')} isEditable onSave={handleSaveName} />
            <BoxWithLabel text={link.comment || t('None')} label={t('Comment')} isEditable onSave={handleSaveComment} />
          </div>
        </div>
      </div>
    </>
  );
}
