export function getClientHeight() {
  let height = window.innerHeight;

  if (!height) {
    height = document.documentElement.clientHeight;

    if (!height) {
      height = document.body.offsetHeight;
    }
  }

  return height;
}

export const getClientWidth = () => {
  let width = window.innerWidth;

  if (!width) {
    width = document.documentElement.clientWidth;

    if (!width) {
      width = document.body.offsetWidth;
    }
  }

  return width;
};
