import React from 'react';
import { ReactComponent as StarCardSvg } from './icons/star-card.svg';
import { ReactComponent as CrowdSvg } from './icons/crowd.svg';
import { ReactComponent as MoneyBagSvg } from './icons/money-bag.svg';
import { ReactComponent as LaptopSvg } from './icons/laptop.svg';
import { useTranslation } from 'react-i18next';
import { Steps } from '../Steps/Steps';

export const FarmAboutCodeSteps = () => {
  const { t } = useTranslation();

  return (
    <Steps>
      <Steps.Item Icon={StarCardSvg} title={t('Generate your CryptoTab Farm discount code')} withArrow={false} />
      <Steps.Item Icon={CrowdSvg} title={t('Share your code with friends and followers')} />
      <Steps.Item Icon={LaptopSvg} title={t('Users get up to %(span)s25%%%(span_end)s discount on their purchases')} />
      <Steps.Item Icon={MoneyBagSvg} title={t('You get a reward equal to 15%% of their mining')} />
    </Steps>
  );
};
