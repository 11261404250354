import React from 'react';

import { BannerVariants } from '@shared/BannerVariants/BannerVariants';
import { useTranslation } from 'react-i18next';
import Icon from '@assets/pages-banners/product-small-icon.png';
import bigIcon from '@assets/pages-banners/product-big-icon.png';
import { Button, ButtonVariant } from '@shared/index';
import { ReactComponent as Arrow } from '@assets/colorless/arrow-right-sm.svg';
import { ECOSYSTEM } from '@utils/links';

const BannerMedia = () => {
  return (
    <picture>
      <source srcSet={`${bigIcon}`} media="(max-width: 768px)" />
      <source srcSet={`${Icon},`} />
      <img src={Icon} alt="banner" />
    </picture>
  );
};

export default function BannerDashboard() {
  const { t } = useTranslation();
  return (
    <BannerVariants
      description={t('MoreBTC.Banner.Description')}
      title={t('MoreBTC.Banner.Title')}
      Media={<BannerMedia />}
      offsetBottom={20}
      id={'MoreBTC.Banner.Description'}
      Button={
        <Button
          type="link"
          to={{ pathname: ECOSYSTEM }}
          variant={ButtonVariant.Primary}
          Icon={<Arrow color="#fff" />}
          iconPosition="right"
          size="medium"
        >
          {t('MoreBTC.Banner.Btn')}
        </Button>
      }
    />
  );
}
