import { addZeroToNumber } from './addZeroToNumber';
import { PRODUCT_KIND } from './checkPathname';

export type PromoImageProduct = 'ct-browser' | 'ct-farm' | 'ct-farm-pro' | 'ct-pool';
export type PromoImageType = 'landscape' | 'square' | 'portrait';
export type ProductsWithPromo = PRODUCT_KIND.Browser | PRODUCT_KIND.Farm | PRODUCT_KIND.FarmPro | PRODUCT_KIND.Pool;

export const createLinkToPromo = (productKind: ProductsWithPromo, imageType: PromoImageType, num: number) => {
  return `https://cdn.cryptobrowser.site/images/aff-promo-post-images/${productToImageMap[productKind]}/${
    productToImageMap[productKind]
  }_post-img-${addZeroToNumber(num)}_${imageType.toLowerCase()}@2x.png`;
};

const productToImageMap: Record<ProductsWithPromo, PromoImageProduct> = {
  [PRODUCT_KIND.Browser]: 'ct-browser',
  [PRODUCT_KIND.Farm]: 'ct-farm',
  [PRODUCT_KIND.FarmPro]: 'ct-farm-pro',
  [PRODUCT_KIND.Pool]: 'ct-pool',
};
