import { useEffect } from 'react';
import { BrowserLinksView } from './view/BrowserLinksView';
import { BrowserSubheader } from '../headersComponents/BrowserSubheader';
import { useTranslation } from 'react-i18next';
import { HelmetHead } from '../headersComponents/HelmetHead';
import { useDispatch, useSelector } from 'react-redux';
import getBalances from '@service/actions/getBalances';
import { product } from '@utils/productName';
import { Root } from '@service/reducers';
import getPromos from '@service/actions/getPromos';
import getBrowserLand from '@service/actions/getBrowserLand';
import { PRODUCT_KIND } from '@utils/checkPathname';
import { useGeneratePostModal } from '@hooks/useGeneratePostModal';
import getBrowserDashboardInfo from '@service/actions/getBrowserDashboardInfo';
import { Span } from '@shared/Graph/Tabs';

export const BrowserLinks = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const balance = useSelector((store: Root) => store.balance);
  const promo = useSelector((store: Root) => store.promo);
  const browserLandings = useSelector((store: Root) => store.browserPromo);
  useEffect(() => {
    !balance.get_balance && dispatch(getBalances());
  }, [dispatch, balance.get_balance]);
  useEffect(() => {
    !promo.promo_get && !promo.promo_request && dispatch(getPromos());
    !browserLandings.land_get && !browserLandings.land_request && dispatch(getBrowserLand());
  }, [promo.promo_get, browserLandings.land_get]);

  const store = useSelector((store: Root) => store.browserGraph);
  useEffect(() => {
    !store.stats_links_get && dispatch(getBrowserDashboardInfo(store.period_start, store.period_end, Span.Day, true));
  }, [dispatch, store.period_start, store.period_end]);

  const { handleGeneratePostClick } = useGeneratePostModal(PRODUCT_KIND.Browser, { timeoutDelay: 2000 });

  return (
    <>
      <HelmetHead title={t('Links')} product={product.ct} />
      <BrowserSubheader />
      <BrowserLinksView onLinkCreate={handleGeneratePostClick} />
    </>
  );
};
