import { useEffect, useState } from 'react';

export const useLockBodyScroll = (initialLocked = false, withWindowWidth = true) => {
  const [locked, setLocked] = useState(initialLocked);

  useEffect(() => {
    if (!locked) {
      return;
    }
    // сохраняем начальные состояния стилей 'body'
    const originalOverflow = document.body.style.overflow;
    const originalPaddingRight = document.body.style.paddingRight;

    // получаем ширину скроллбара
    const root = document.getElementById('root');
    const scrollBarWidth = root ? window.innerWidth - root.offsetWidth : 0;

    // блокируем скролл
    document.body.style.overflow = 'hidden';

    // компенсируем ширину окна при выключении скролла
    if (scrollBarWidth && withWindowWidth) {
      document.body.style.paddingRight = `${scrollBarWidth}px`;
    }

    return () => {
      document.body.style.overflow = originalOverflow;

      if (scrollBarWidth && withWindowWidth) {
        document.body.style.paddingRight = originalPaddingRight;
      }
    };
  }, [locked]);

  // обновляем состояние, если начальное значение изменяется
  useEffect(() => {
    if (locked !== initialLocked) {
      setLocked(initialLocked);
    }
  }, [initialLocked]);

  return { locked, setLocked };
};
