import { useCallback } from 'react';
import { WithdrawError } from '@pages/Withdrawal/view/WithdrawalViewWithMoney';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { BROWSER_BALANCE } from '@utils/links';
import checkE from '@utils/checkE';
import { rpc } from '../../../backend/Rpc';
import {
  ADD_HSH_WITHDRAWAL,
  ADD_NEW_WITHDRAWAL,
  ERROR_WITHDRAWAL,
  GET_WITHDRAWALS_FORCE_REQUEST,
} from '@service/constant';
import getBalances from '@service/actions/getBalances';
import { ErrorWithdrawal } from '@service/reducers/withdrawalsReducer';
import { WithdrawalModalState } from '@pages/Withdrawal/view/WithdrawalView';
import { convertBigValueToLocale } from '@utils/convertBigValueToLocale';
import { BTC_NCW_CONNECT } from '@pages/WithdrawalNcwWallet/view/WithdrawalNcwWalletView/WithdrawalNcwWalletView';

export const useHandleSubmit = (
  min: number,
  isNCW: boolean,
  minHSH: number,
  amount: string,
  address: string,
  history: any,
  setModal: (val: WithdrawalModalState) => void,
  setError: (val: WithdrawError | undefined) => void,
  balance: number,
  balanceHSH: number,
  isBitcoin: boolean
) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return useCallback(async () => {
    if (amount === '' || address === '') {
      return null;
    }

    if (isNaN(+amount)) {
      setError({ type: 'amount', text: t('Alert.Number') });
      return null;
    }

    if (isBitcoin && +amount < min) {
      setError({
        type: 'amount',
        text: `${t('Alert.lesserThanMin')} ${min}.`,
      });
      return null;
    }

    if (isBitcoin && +amount > balance) {
      setError({
        type: 'amount',
        text: `${t('Alert.BiggerThanMax')} ${checkE(balance)}`,
      });
      return null;
    }

    if (!isBitcoin && +amount > balanceHSH) {
      setError({
        type: 'amount',
        text: `${t('Alert.BiggerThanMax')} ${convertBigValueToLocale(balanceHSH)}`,
      });
      return null;
    }

    if (!isBitcoin && +amount < minHSH) {
      setError({
        type: 'amount',
        text: `${t('Alert.lesserThanMin')} ${minHSH}.`,
      });
      return null;
    }

    setError(undefined);

    if (isBitcoin) {
      await rpc
        .transmit('withdrawals.create_withdrawal', {
          address: address,
          type: 'regular',
          amount: +amount,
        })
        .then((data) => {
          const localBTCConnect = JSON.parse(localStorage.getItem(BTC_NCW_CONNECT) || '{}');

          if (!localBTCConnect?.is_ncw) {
            localStorage.removeItem(BTC_NCW_CONNECT);
          }

          dispatch({ type: GET_WITHDRAWALS_FORCE_REQUEST });
          dispatch({ type: ADD_NEW_WITHDRAWAL, newWithdrawal: data });
          dispatch(getBalances());
          if (data.state === 1) {
            setModal(WithdrawalModalState.Confirm);
          } else {
            history.push(`${BROWSER_BALANCE}/BTC`);
          }
        })
        .catch((err) => {
          dispatch({
            type: ERROR_WITHDRAWAL,
            error: ErrorWithdrawal[err.code as keyof typeof ErrorWithdrawal] || 'Allert.Wrong',
          });
          setModal(WithdrawalModalState.Error);
        });
    } else {
      await rpc
        .transmit('wallets.withdrawals.create', {
          address: address,
          type: 'hsh',
          amount: +amount,
        })
        .then((data) => {
          dispatch({ type: GET_WITHDRAWALS_FORCE_REQUEST });
          dispatch({ type: ADD_NEW_WITHDRAWAL, newWithdrawal: data });
          dispatch(getBalances());

          if (data.status === 'created') {
            setModal(WithdrawalModalState.Confirm);
          } else {
            dispatch({ type: ADD_HSH_WITHDRAWAL });
            history.push(`${BROWSER_BALANCE}/HSH`);
          }
        })
        .catch((err) => {
          dispatch({
            type: ERROR_WITHDRAWAL,
            error: ErrorWithdrawal[err.code as keyof typeof ErrorWithdrawal] || 'Allert.Wrong',
          });
          setModal(WithdrawalModalState.Error);
        });
    }
  }, [setError, setModal, min, t, address, amount, balance, dispatch, history, isBitcoin, isNCW]);
};
