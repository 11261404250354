import styles from './AffiliateFeatures.module.scss';
import { ReactComponent as MoneyBagPng } from './icons/money-bag.svg';
import { ReactComponent as PalettePng } from './icons/palette.svg';
import { ReactComponent as BitcoinPng } from './icons/bitcoin.svg';
import { ReactComponent as RocketPng } from './icons/rocket.svg';
import { ReactComponent as SupportPng } from './icons/support.svg';
import { AffiliateFeature } from './AffiliateFeature';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { FC, SVGProps } from 'react';

export type AffiliateFeaturesProps = {
  firstFeature?: string;
  secondFeature?: string;
  thirdFeature?: string;
  forthFeature?: string;
  fifthFeature?: string;
  className?: string;
  isAnimation?: boolean;

  features?: Feature[];
};

export type Feature = {
  Icon: FC<SVGProps<SVGSVGElement>>;
  title: string;
};

const DEFAULT_FEATURES: Feature[] = [
  {
    Icon: MoneyBagPng,
    title: 'High profitable rates for comissions',
  },
  {
    Icon: PalettePng,
    title: 'Get access to thousands of promo materials',
  },
  {
    Icon: BitcoinPng,
    title: 'Steady income in BTC',
  },
  {
    Icon: RocketPng,
    title: 'Withdraw with no holds and limits',
  },
  {
    Icon: SupportPng,
    title: 'Friendly reliable support for users',
  },
];

export const AffiliateFeatures = ({ features = DEFAULT_FEATURES, ...props }: AffiliateFeaturesProps) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.root, props.className)}>
      {features.map((feature) => (
        <AffiliateFeature
          key={feature.title}
          Icon={feature.Icon}
          title={t(feature.title)}
          isAnimation={props.isAnimation}
        />
      ))}
    </div>
  );
};
