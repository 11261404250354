import { useCallback, useMemo, useState } from 'react';

export const usePromoShowOthers = (STEP: number, MAX: number) => {
  const [visible, setVisible] = useState({ from: 0, to: STEP });
  const showAll = useCallback(() => {
    if (visible.to === MAX) {
      setVisible({ from: 0, to: STEP });
    } else {
      setVisible({ from: 0, to: MAX });
    }
  }, [visible.to, MAX, STEP]);
  const onOther = useCallback(() => {
    visible.to < MAX
      ? setVisible(({ from, to }) => ({ from: from + STEP, to: to + STEP }))
      : setVisible({ from: 0, to: STEP });
  }, [MAX, STEP, visible]);
  const isHide = useMemo(() => visible.to - visible.from >= MAX, [visible]);
  return { visible, showAll, onOther, isHide };
};
