import { AppDispatch } from '../..';
import { PROMO_ERROR, PROMO_REQUEST, PROMO_SUCCESS } from '../constant';
import { rpc } from '../../backend/Rpc';

export default function getPromos() {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch({ type: PROMO_REQUEST });
      const resp = await rpc.transmit('farm.promo-codes', {});
      dispatch({ type: PROMO_SUCCESS, promos: resp });
    } catch (e) {
      dispatch({ type: PROMO_ERROR });
      console.log(e);
    }
  };
}
