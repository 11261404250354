// @ts-ignore
const isObject = function (x) {
  return Object.prototype.toString.call(x) === '[object Object]';
};

// @ts-ignore
export const getKeys = function (obj, prefix = '') {
  const keys = Object.keys(obj);
  prefix = prefix ? prefix + '/' : '/';

  // @ts-ignore
  return keys.reduce(function (result, key) {
    if (isObject(obj[key])) {
      // @ts-ignore
      result = result.concat(getKeys(obj[key], prefix + key));
    } else {
      // @ts-ignore
      result.push(prefix);
    }
    return result.map((it) =>
      it
        // @ts-ignore
        .split('/') // @ts-ignore
        .filter((_it) => _it !== 'sections')
        .join('/')
    );
  }, []);
};

export function findByPath(obj: Record<string, any>, path: string) {
  const paths = path.split('/').filter((it) => it);
  return traverse(obj, paths, 0);
}

// @ts-ignore
export function traverse(list, paths, level) {
  if (!paths.length) return list;
  // @ts-ignore
  const node = Object.entries(list['sections']).find(([k, _]) => {
    return k === paths[level];
  })[1];

  // @ts-ignore
  if (level === paths.length - 1 || !('sections' in node)) {
    return node;
  } else {
    // @ts-ignore
    return traverse(node, paths, level + 1);
  }
}
