import styles from './ModalPromo.module.scss';
import { useTranslation } from 'react-i18next';
import { Button, ButtonVariant } from '@shared/index';
import { ReactComponent as Arrow } from '@assets/colorless/arrowRight.svg';
import { ReactComponent as Close } from '@assets/colored/circle-close.svg';
import { useCallback, useEffect, useState } from 'react';
import { ContentPage } from '../../../../backend/JsonRpcApi';
import { SHOW_PROMO_LOCAL_KEY } from '@hooks/usePromoBannerVariant';

export type PromoStorage = 'promo_ncw_modal_show' | 'promo_eggs_modal_show';

export type PromoModalWithBotAndCheckProps = {
  onClose: () => void;
} & Partial<ContentPage>;

export function PromoModalWithBotAndCheck({
  onClose,
  actions,
  image,
  body,
  id,
  title,
}: PromoModalWithBotAndCheckProps) {
  const { t } = useTranslation();
  const url = actions?.[0]?.url;
  const handleCreate = useCallback(() => {
    window.open(actions?.[0]?.url);
    onClose();
  }, [onClose, actions]);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    if (/advPromo=hide/gi.test(document.cookie)) {
      onClose();
    } else if (id) {
      const shown = window.localStorage.getItem(SHOW_PROMO_LOCAL_KEY);
      window.localStorage.setItem(SHOW_PROMO_LOCAL_KEY, `${id} ${shown}`);
      document.cookie = 'advPromo=hide; max-age=10800;';
      setLoad(true);
    }
  }, [id, onClose]);
  if (!load) {
    return null;
  }
  return (
    <>
      <Close className={styles.close} onClick={onClose} />
      <div className={styles.content}>
        <a href={url} target="_blank" rel="noreferrer" className={styles.img__link}>
          <picture>
            <source srcSet={image} media="(max-width: 768px)" />
            <source srcSet={image} />
            <img src={image} className={styles.img} alt="promo" />
          </picture>
        </a>
        <div className={styles.body}>
          <a href={url} target="_blank" rel="noreferrer" className={styles.title}>
            {title}
          </a>
          <div
            className={styles.text}
            dangerouslySetInnerHTML={{
              __html: body || '',
            }}
          />
          <div className={styles.footer}>
            <button className={styles.cancel} onClick={onClose}>
              {t('Cancel')}
            </button>
            <Button
              Icon={<Arrow className={styles.create__jackbot} />}
              type="button"
              iconPosition="right"
              onClick={handleCreate}
              className={styles.create}
              variant={ButtonVariant.Primary}
              size="medium"
            >
              {actions?.[0].title || t('Learn more')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

const storageMap: { [K in PromoStorage]: string } = {
  promo_ncw_modal_show: 'ncw',
  promo_eggs_modal_show: 'eggs',
};
