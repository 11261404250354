// browserLevelsUtils.ts

import { BrowserResp } from '@service/reducers/browserStatsReducer';

export type Item = { ref: number; mined: number };
export const mockLevels = new Array(10).fill({ ref: 0, mined: 0 });

export const doEmptyLevels = (arr: BrowserResp[], levels: Record<string, Item[]>) => {
  arr.forEach((item: BrowserResp) => {
    if (!levels.hasOwnProperty(`${item.account_id}`)) {
      levels[`${item.account_id}`] = [...mockLevels];
    }
  });
};

export const searchItem = (item: string, lvl: number, arr: BrowserResp[]) =>
  arr
    .filter((respItem: BrowserResp) => respItem.account_id === Number(item) && respItem.lvl === lvl)
    .reduce((acc, value) => acc + value.value, 0);
