export const BROWSER_DASH = '/app/browser/dashboard';
export const BROWSER_ABOUT = '/app/browser/about';
export const BROWSER_PROMO = '/app/browser/promo';
export const BROWSER_LINKS = '/app/browser/links';
export const BROWSER_LINKS_CREATE = '/app/browser/links/create';
export const BROWSER_STATISTIC = '/app/browser/statistic';
export const BROWSER_BALANCE = '/app/history';
export const BROWSER_BALANCE_ITEM = '/app/history/item';
export const BROWSER_WITHDRAWAL = '/app/withdrawal';
export const BROWSER_WITHDRAWAL_NCW_WALLET = '/app/ncw/connect';
export const BROWSER_FEEDBACK = '/app/feedback';
export const BROWSER_BANNED = '/app/banned';

export const MAIN_URL = '/app';
export const MAIN_STATISTIC_URL = '/app/statistic';
export const MAIN_LINK_URL = '/app/links';
export const MAIN_PROMO_URL = '/app/promo';
export const MAIN_ABOUT_URL = '/app/about';
export const BROWSER_SUSPEND = '/app/suspend';

export const FARM_DASH = '/app/farm/dashboard';
export const FARM_STATISTIC = '/app/farm/statistic';
export const FARM_ABOUT = '/app/farm/about';
export const FARM_ABOUT_PRO = '/app/farm-pro/about';
export const FARM_PROMO = '/app/farm/promo';
// export const FARM_NEWS = '/app/news';
export const FARM_GENERATE_CODE = '/app/farm_promo_codes';
export const FARM_LINK_INFO = '/app/farm/links';

export const NFT_DASH = '/app/nft/dashboard';
export const NFT_ABOUT = '/app/nft/about';
export const NFT_PROMO = '/app/nft/promo';

export const POOL_DASH = '/app/pool/dashboard';
export const POOL_STATISTIC = '/app/pool/statistic';
export const POOL_ABOUT = '/app/pool/about';
export const POOL_PROMO = '/app/pool/promo';
export const POOL_LINKS = '/app/pool/links';

export const SETTINGS = '/app/settings';
export const EMAIL_VERIFY = '/app/email/verify';
export const SUPPORT = '/app/support';
export const SUPPORT_SUCCESS = '/app/support/success';
export const COMMON_ABOUT = '/app/about';
export const AFFILIATE_OVERVIEW = '/app/overview';

export const ECOSYSTEM = '/app/eco-system';
