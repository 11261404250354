import { PRODUCT_KIND } from '@utils/checkPathname';

export const productURLsMap: Record<PRODUCT_KIND, string> = {
  [PRODUCT_KIND.Farm]: 'https://cryptotab.farm/',
  [PRODUCT_KIND.Browser]: 'https://cryptotabbrowser.com/',
  [PRODUCT_KIND.Nft]: 'https://ctnft.net/',
  [PRODUCT_KIND.Pool]: 'https://pool.cryptobrowser.site/',
  [PRODUCT_KIND.FarmPro]: 'https://cryptotabfarm.com/',
  [PRODUCT_KIND.All]: 'https://cryptotabbrowser.com/',
};
