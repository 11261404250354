import { useTranslation } from 'react-i18next';
import styles from './GenerateBanners.module.scss';
import { BannerType } from '@service/reducers/bannerReducer';
import { ArrowRightSmSvg } from '@assets/colorless';
import { Button } from '@shared/Button/Button';
import ImageOpen from './ImageOpen/ImageOpen';

export type GenerateBannersItemProps = BannerType & {
  Tabs: (props: any) => JSX.Element;
  list: string[];
  land: any[];
  handleClickDetail: (banner: BannerType) => void;
};
export default function GenerateBannersItem(props: GenerateBannersItemProps) {
  const { t } = useTranslation();
  const { handleClickDetail } = props;
  const src = props.images?.[''] || props.default_images?.[''];
  const srcSet = (props.images?.['2x'] || props.default_images?.['2x']) + ' 2x';

  const clickDetail = () => {
    handleClickDetail(props);
  };
  return (
    <div className={styles.item}>
      <ImageOpen
        className={styles.item__top}
        href={props.images?.['2x'] || props.default_images?.['2x'] || src}
        src={src}
        srcSet={srcSet}
      />
      <div className={styles.item__bottom}>
        <Button type="button" size="medium" onClick={clickDetail} iconPosition="right" Icon={<ArrowRightSmSvg />}>
          {t('viewDetails.button')}
        </Button>
      </div>
    </div>
  );
}
