import React, { useMemo } from 'react';
import { Container, GenerateBanners, PromoTop } from '@shared/index';
import mobPromo from '@assets/promo-materials/browser/browser-mob.png';
import mobPromo2x from '@assets/promo-materials/browser/browser-mob@2x.png';
import pcPromo from '@assets/promo-materials/browser/browser.png';
import pcPromo2x from '@assets/promo-materials/browser/browser@2x.png';
import { BrandBlock } from './components/BrandBlock';
import { useTranslation } from 'react-i18next';
import BannerTabs from './components/BannerTabs';
import { BrowserPromoLand } from '@service/reducers/browserPromoReducer';
import BrowserLandingsBlock from './components/BrowserLandingsBlock';
import { BrowserTextBlock } from './components/BrowserTextBlock';
import { BrowserVideoBlock } from './components/BrowserVideoBlock';
import styles from './BrowserPromoView.module.scss';
import { PromoImagesSection } from '@shared/PromoImagesSection/PromoImagesSection';
import { PRODUCT_KIND } from '@utils/checkPathname';
import srcMob from '@assets/banners/browser/mob.png';
import srcMob2x from '@assets/banners/browser/mob@2x.png';
import srcPc from '@assets/banners/browser/pc.png';
import srcPc2x from '@assets/banners/browser/pc@2x.png';

export type BrowserPromoViewProps = {
  list: string[];
  land: BrowserPromoLand[];
};

export const BrowserPromoView = ({ list, land }: BrowserPromoViewProps) => {
  const { t } = useTranslation();
  const tags = useMemo(
    () => [
      { name: t('No text'), code: 'simple' },
      { name: t('With text'), code: 'text' },
      { name: t('Mobile'), code: 'mobile' },
      { name: t('Mobile Pro'), code: 'pro' },
    ],
    [t]
  );
  return (
    <Container vertical={20}>
      <PromoTop
        imageDesktop={pcPromo}
        imageDesktop2x={pcPromo2x} //replace
        imageMob={mobPromo}
        imageMob2x={mobPromo2x}
        classImg={styles.img}
      />
      <BrandBlock />

      <BrowserLandingsBlock list={list} />
      <GenerateBanners
        title={t('Link ID')}
        land={land}
        tags={tags}
        list={list}
        Tabs={BannerTabs}
        srcMob={srcMob}
        srcPc={srcPc}
        srcSetMob={srcMob2x}
        srcSetPc={srcPc2x}
      />
      <PromoImagesSection productKind={PRODUCT_KIND.Browser} />
      <BrowserTextBlock />
      <BrowserVideoBlock />
    </Container>
  );
};
