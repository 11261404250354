import { SupportError, SupportErrorVariant } from './SupportError';

export const MAX_MESSAGE_LENGTH = 30;

const emailRegexp = new RegExp(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/);

type ValidateResult = {
  isValid: boolean;
  error?: SupportError;
};

export const validateEmail = (email: string): ValidateResult => {
  if (email === '') {
    return {
      isValid: false,
      error: {
        error: 'This field is required.',
        variant: SupportErrorVariant.Email,
      },
    };
  }

  if (!emailRegexp.test(email)) {
    return {
      isValid: false,
      error: {
        error: 'Enter correct email.',
        variant: SupportErrorVariant.Email,
      },
    };
  }
  return { isValid: true };
};

export const validateMessage = (message: string): ValidateResult => {
  if (message === '' || message.length < MAX_MESSAGE_LENGTH) {
    return {
      isValid: false,
      error: {
        error: 'Minimum 30 symbols',
        variant: SupportErrorVariant.Text,
      },
    };
  }
  return { isValid: true };
};

export const validateAccountId = (data: string): ValidateResult => {
  if (data === '') {
    return {
      isValid: false,
      error: {
        error: 'This field is required.',
        variant: SupportErrorVariant.AccountId,
      },
    };
  }

  return { isValid: true };
};

export const validateDate = (data: string): ValidateResult => {
  if (data === '') {
    return {
      isValid: false,
      error: {
        error: 'This field is required.',
        variant: SupportErrorVariant.Date,
      },
    };
  }

  return { isValid: true };
};
