import { Trans, useTranslation } from 'react-i18next';
import Container from '../Container/Container';
import styles from './Shared.module.scss';
import bot from '@assets/all-about-share-bot.png';
import botRetina from '@assets/all-about-share-bot@2x.png';
import { Card, ShareBlock } from '@shared/index';
import './share.scss';
import { CircleQuestionSvg } from '@assets/colored';

export default function Shared() {
  const { t } = useTranslation();
  return (
    <Card id="all_share" className={styles.card}>
      <Container
        Icon={<CircleQuestionSvg className="animate__animated" data-anim="fadeInDown" />}
        innerTop={0}
        title={
          <span className="animate__animated" data-anim="fadeIn">
            {t('ct-affiliate_about_text-edits.invite.Share_the_link_with')}
          </span>
        }
        text={
          <span className="animate__animated" data-anim="fadeIn">
            <Trans
              i18nKey="ct-affiliate_about_text-edits.invite.Join_the_new_CryptoT"
              components={{
                b: <b />,
                a: <a href="https://cryptobrowser.site/journal/" target="blank" />,
                a2: <a href="https://discord.com/invite/cryptotab-community-997079413013352529" target="blank" />,
              }}
            />
          </span>
        }
      >
        <div className={styles.share + ' animate__animated'} data-anim="fadeInUp">
          <div className={styles.share__label}>{t('Invitewith.button')}</div>
          <ShareBlock msg={t('about.meta.description')} size="l" limit={5} />
        </div>
      </Container>
      <img src={bot} srcSet={botRetina + ' 2x'} className={styles.img + ' animate__animated'} data-anim="fadeIn" />
    </Card>
  );
}
