import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { BANNER_CLEAR } from '@service/constant';
import { BannerType } from '@service/reducers/bannerReducer';
import { PromoSection } from '../PromoSection/PromoSection';
import GenerateBannersItem from './GeneraBannersItem';
import styles from './GenerateBanners.module.scss';
import { GenerateForm } from './GenerateForm';
import { Root } from '@service/reducers';
import { Picture } from '@shared/Picture/Picture';
import { Pagination } from '@shared/Pagination/Pagination';
import { useModalContext } from '@context/ModalContext';
import GenerateBannerModal from './GenerateBannerModal/GenerateBannerModal';
import { Choice } from '@shared/Select/Select';
import { bannerSizes } from '@utils/bannerInfo';
import TextArea from '@shared/TextArea/TextArea';
import Search from './Search/Search';
import { set } from 'lodash';
import { EmptySearchSvg } from '@assets/colored';

export type GenerateBannersProps = {
  srcMob: string;
  srcSetMob: string;
  srcPc: string;
  srcSetPc: string;
  Tabs: (props: any) => JSX.Element;
  title: string;
  list: string[];
  tags: GenerateTag[];
  land: any[];
  EmptyBlock?: React.ReactNode;
};

type GenerateTag = {
  name: string;
  code: string;
};

export function GenerateBanners({
  srcMob,
  srcSetMob,
  srcPc,
  srcSetPc,
  Tabs,
  list,
  title,
  tags,
  land,
  EmptyBlock,
}: GenerateBannersProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = useSelector((store: Root) => store);
  const { createModalContent, openModal, closeModal, opened } = useModalContext();
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 12;

  const mappedList = useMemo(() => list.map((item) => ({ value: item, label: item })), [list]);

  const [currentSize, setSelectedSize] = useState<Choice>(bannerSizes[0]);
  const startIndex = (currentPage - 1) * perPage;
  const [searchResults, setSearchResults] = useState<BannerType[]>([]);
  const [searchInput, setSearchInput] = useState('');
  const [currentIndexSlide, setCurrentIndexSlide] = useState(0);
  const banners = searchInput ? searchResults : store.banners?.banners;

  const paginatedBanners = banners?.slice(startIndex, startIndex + perPage) || [];

  const mappedTags = [{ name: t('All'), code: 'All' }, ...tags].map((item) => ({ value: item.code, label: item.name }));
  const mappedLands = land?.map((item) => ({ value: item.id, label: item.id, url: item.url }));

  const resetCurrentPage = useCallback(() => {
    setCurrentPage(1);
  }, []);

  useEffect(() => {
    return () => {
      dispatch({ type: BANNER_CLEAR });
    };
  }, [dispatch]);

  const handleSelectedSize = useCallback((val: Choice) => {
    setSelectedSize(val);
  }, []);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const orientation = useMemo(() => getOrientation(currentSize.value), [currentSize]);

  const findIndexBanner = useCallback(
    (banner: BannerType) => store.banners?.banners?.findIndex((item: BannerType) => item.id === banner.id),
    [store.banners?.banners]
  );

  const handleCloseModal = () => {
    if (currentIndexSlide !== null) {
      const newPage = Math.floor(currentIndexSlide / perPage) + 1;
      setCurrentPage(newPage);
    }
  };

  useEffect(() => {
    if (!opened) handleCloseModal();
  }, [opened]);

  const handleClickDetail = (banner: BannerType) => {
    if (!opened) openModal();

    const currentIndex = findIndexBanner(banner);
    setCurrentIndexSlide(currentIndex);

    const showBannerDetails = (index: number) => {
      const selectedBanner = store.banners?.banners[index];
      if (!selectedBanner) return;

      createModalContent(
        <GenerateBannerModal
          lands={mappedLands}
          orientation={orientation}
          banner={selectedBanner}
          list={mappedList}
          onNext={() => handleNextBanner(index)}
          onPrevious={() => handlePreviousBanner(index)}
          isNextDisabled={index >= store.banners?.banners.length - 1}
          isPreviousDisabled={index <= 0}
        />,
        styles.modal
      );
    };

    showBannerDetails(currentIndex);
  };

  const handleNextBanner = (index: number) => {
    const nextIndex = index + 1;
    if (nextIndex < store.banners?.banners?.length) {
      setCurrentIndexSlide(nextIndex);
      handleClickDetail(store.banners?.banners[nextIndex]);
    }
  };

  const handlePreviousBanner = (index: number) => {
    const prevIndex = index - 1;
    if (prevIndex >= 0) {
      setCurrentIndexSlide(prevIndex);
      handleClickDetail(store.banners?.banners[prevIndex]);
    }
  };

  const handleChangeSearch = (val: string) => {
    const filtered = store.banners?.banners?.filter((item: BannerType) => `${item.id}`.includes(val.toLowerCase()));
    setSearchResults(filtered);
  };

  const setSearchResultsValue = (val: BannerType[]) => {
    setSearchResults(val);
  };

  const setSearchInputHandler = (val: string) => {
    setSearchInput(val);
    resetCurrentPage();
  };
  const onGenerateForm = useCallback(() => {
    resetCurrentPage();
    setSearchInput('');
    setSearchResults([]);
  }, [resetCurrentPage, setSearchInput]);
  return (
    <>
      <PromoSection
        title={t('Generate Banners')}
        overflowHide
        text={t('Here you can generate banners with your personal link to promote CryptoTab Browser.')}
        offsetBottom={0}
        id={'banners'}
        isFooterOpacity={banners.length > perPage}
        Switch={
          store.banners?.banners?.length ? (
            <Search
              handleChangeSearch={handleChangeSearch}
              results={searchResults}
              setSearchResults={setSearchResultsValue}
              setSearchInput={setSearchInputHandler}
              value={searchInput}
            />
          ) : (
            ''
          )
        }
        Footer={
          banners.length > perPage && (
            <Pagination
              totalPage={Math.ceil(banners.length / perPage)}
              index={currentPage}
              handleChangePage={handlePageChange}
            />
          )
        }
      >
        <div className={styles.body}>
          {list.length > 0 ? (
            <GenerateForm
              currentSize={currentSize}
              handleSelectedSize={handleSelectedSize}
              title={title}
              list={mappedList}
              tags={mappedTags}
              onGenerateForm={onGenerateForm}
            />
          ) : (
            EmptyBlock
          )}
          {paginatedBanners.length > 0 ? (
            <div className={styles.results}>
              {paginatedBanners.map((banner: BannerType) => (
                <GenerateBannersItem
                  handleClickDetail={() => handleClickDetail(banner)}
                  land={land}
                  list={list}
                  Tabs={Tabs}
                  {...banner}
                  key={banner.images?.[''] || banner.default_images?.['']}
                />
              ))}
            </div>
          ) : searchInput ? (
            <EmptySearch />
          ) : (
            <Picture
              imageDesktop={srcPc}
              imageDesktop2x={srcSetPc}
              imageMob={srcMob}
              imageMob2x={srcSetMob}
              className={styles.body__img}
            />
          )}
        </div>
      </PromoSection>
    </>
  );
}

export function getOrientation(size: string): 'vertical' | 'horizontal' {
  if (!size) {
    return 'vertical';
  }
  const match = size.match(/^(\d+)x(\d+)$/);
  if (!match) {
    console.warn(`Invalid size format: ${size}. Expected format is "widthxheight". Defaulting to "vertical".`);
    return 'vertical';
  }

  const [_, width, height] = match;
  return parseInt(width, 10) > parseInt(height, 10) ? 'horizontal' : 'vertical';
}

const EmptySearch = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.empty}>
      <EmptySearchSvg />
      <p className={styles.empty__text}>{t('noResults')}</p>
    </div>
  );
};
