import { AppDispatch } from '../..';
import {
  BROWSER_CHANGE_DISCREETNESS_OPENS,
  BROWSER_CHANGE_TABLE_INSTALLS,
  BROWSER_CHANGE_TABLE_MINERS,
  BROWSER_CHANGE_TABLE_OPENS,
  BROWSER_EARNING_SUCCESS,
  BROWSER_EARNING_TOTAL,
  BROWSER_INSTALLS_SUCCESS,
  BROWSER_LINKS_ERROR,
  BROWSER_LINKS_REQUEST,
  BROWSER_LINKS_SUCCESS,
  BROWSER_MINERS_SUCCESS,
  BROWSER_OPENS_SUCCESS,
  GET_BROWSER_LEVELS,
} from '../constant';
import { rpc } from '../../backend/Rpc';
import { AffiliateGroup, Discreteness, Product } from '../../backend/JsonRpcApi';
import { Span } from '@shared/Graph/Tabs';
import { doEmptyLevels, Item, searchItem } from '@utils/browserLevelsUtils';
import { EarningsStatisticsResponse } from '@service/reducers/browserStatsReducer';
import checkE from '@utils/checkE';

export default function getBrowserDashboardInfo(
  period_start: string,
  period_end: string,
  earningsDiscreteness: Discreteness,
  updateDate?: boolean
) {
  return async function (dispatch: AppDispatch) {
    const mockLevels = new Array(10).fill({ ref: 0, mined: 0 });

    const updateTimestamp = <T extends { timestamp: number }>(arr: T[]): T[] =>
      arr.map((item) => ({ ...item, timestamp: item.timestamp * 1000 }));
    try {
      let miners;

      if (updateDate) {
        dispatch({ type: BROWSER_LINKS_REQUEST });

        const opens = await rpc.transmit('statistics.v2.clicks', {
          start_date: period_start,
          end_date: period_end,
          discreteness: Span.Day,
          product_ids: [Product.CT],
        });
        const updateOpens = opens
          .map((item) => ({ timestamp: item.timestamp, account_id: +item.advert_id, value: item.value }))
          .sort((a, b) => a.timestamp - b.timestamp);
        dispatch({ type: BROWSER_OPENS_SUCCESS, opens: updateOpens });
        dispatch({ type: BROWSER_CHANGE_TABLE_OPENS, payload: updateOpens });

        const installs = await rpc.transmit('statistics.referrals', {
          start_date: period_start,
          end_date: period_end,
          discreteness: Span.Day,
        });
        const modifiedInstalls = updateTimestamp(installs.items).sort((a, b) => a.timestamp - b.timestamp);
        dispatch({
          type: BROWSER_INSTALLS_SUCCESS,
          installs: modifiedInstalls,
        });
        dispatch({ type: BROWSER_CHANGE_TABLE_INSTALLS, payload: modifiedInstalls });
        miners = await rpc.transmit('statistics.referrals.payments', {
          start_date: period_start,
          end_date: period_end,
          group: AffiliateGroup.Browser,
          discreteness: Span.Day,
        });
        const minersData = miners.items
          .map((item) => ({
            ...item,
            timestamp: item.timestamp! * 1000,
            value: item.users,
          }))
          .sort((a, b) => a.timestamp - b.timestamp);

        dispatch({
          type: BROWSER_MINERS_SUCCESS,
          miners: minersData,
        });
        dispatch({ type: BROWSER_CHANGE_TABLE_MINERS, payload: minersData });
        const levels: Record<string, Item[]> = {};
        let total: Item[] = [...mockLevels];

        installs.items.forEach((item) => {
          total[item.lvl - 1] = {
            ref: total[item.lvl - 1].ref + item.value,
            mined: 0,
          };
        });
        minersData.forEach((item) => {
          total[item.lvl - 1] = {
            ...total[item.lvl - 1],
            mined: total[item.lvl - 1].mined + item.value,
          };
        });

        doEmptyLevels(installs.items, levels);
        doEmptyLevels(minersData, levels);

        const levelsKeys = Object.keys(levels);
        levelsKeys.forEach((item: string) => {
          for (let lvl = 1; lvl <= 10; lvl++) {
            levels[item][lvl - 1] = {
              ref: searchItem(item, lvl, installs.items),
              mined: searchItem(item, lvl, minersData),
            };
          }
        });

        levels.total = total;
        dispatch({ type: BROWSER_LINKS_SUCCESS });
        dispatch({ type: GET_BROWSER_LEVELS, levels: levels });
      } else {
        miners = await rpc.transmit('statistics.referrals.payments', {
          start_date: period_start,
          end_date: period_end,
          group: AffiliateGroup.Browser,
          discreteness: earningsDiscreteness,
        });
      }

      const totalResp: EarningsStatisticsResponse[] = [...miners.items].map((item) => ({
        timestamp: item.timestamp ? item.timestamp * 1000 : undefined,
        value: +checkE(item.amount),
      }));
      const earningTotal = checkE([...miners.items].reduce((acc, item) => acc + item.amount, 0));
      dispatch({ type: BROWSER_EARNING_TOTAL, earnings: earningTotal });
      dispatch({ type: BROWSER_EARNING_SUCCESS, earnings: totalResp });
    } catch (e) {
      dispatch({ type: BROWSER_LINKS_ERROR });
      console.log(e);
    }
  };
}
