import { AppDispatch } from '../..';
import {
  BROWSER_CHANGE_TABLE_INSTALLS,
  BROWSER_CHANGE_TABLE_MINERS,
  BROWSER_CHANGE_TABLE_OPENS,
  BROWSER_INSTALLS_SUCCESS,
  BROWSER_LINKS_ERROR,
  BROWSER_LINKS_REQUEST,
  BROWSER_LINKS_SUCCESS,
  BROWSER_MINERS_SUCCESS,
  BROWSER_OPENS_SUCCESS,
  POOL_INSTALLS_SUCCESS,
  POOL_MINERS_SUCCESS,
  POOL_OPENS_SUCCESS,
} from '../constant';
import { rpc } from '../../backend/Rpc';
import { AffiliateGroup, ClicksV2StatisticsItem, Discreteness, Product } from '../../backend/JsonRpcApi';
import { Span } from '@shared/Graph/Tabs';
const updateTimestamp = <T extends { timestamp: number }>(arr: T[]): T[] =>
  arr.map((item) => ({ ...item, timestamp: item.timestamp * 1000 }));
export function getBrowserStatsOpens(period_start: string, period_end: string, discreteness?: Discreteness) {
  return async function (dispatch: AppDispatch) {
    try {
      const opens = await rpc.transmit('statistics.v2.clicks', {
        start_date: period_start,
        end_date: period_end,
        discreteness: discreteness || Span.Day,
      });

      const updateOpens = opens
        .map((item: ClicksV2StatisticsItem) => ({
          timestamp: item.timestamp,
          account_id: +item.advert_id,
          value: item.value,
        }))
        .sort((a, b) => a.timestamp - b.timestamp);

      dispatch({ type: BROWSER_OPENS_SUCCESS, opens: updateOpens });
    } catch (error) {
      console.error('Error fetching browser stats opens:', error);
    }
  };
}

export function getBrowserStatsInstalls(period_start: string, period_end: string, discreteness?: Discreteness) {
  return async function (dispatch: AppDispatch) {
    try {
      const installs = await rpc.transmit('statistics.referrals', {
        start_date: period_start,
        end_date: period_end,
        discreteness: discreteness || Span.Day,
      });
      dispatch({
        type: BROWSER_INSTALLS_SUCCESS,
        installs: updateTimestamp(installs.items).sort((a, b) => a.timestamp - b.timestamp),
      });
    } catch (error) {
      console.error('Error fetching browser stats installs:', error);
    }
  };
}

export function getBrowserStatsMiners(period_start: string, period_end: string, discreteness?: Discreteness) {
  return async function (dispatch: AppDispatch) {
    try {
      const miners = await rpc.transmit('statistics.referrals.payments', {
        start_date: period_start,
        end_date: period_end,
        group: AffiliateGroup.Browser,
        discreteness: discreteness || Span.Day,
      });

      dispatch({
        type: BROWSER_MINERS_SUCCESS,
        miners: miners.items
          .map((item) => ({
            ...item,
            timestamp: item.timestamp! * 1000,
            value: item.users,
          }))
          .sort((a, b) => a.timestamp - b.timestamp),
      });
    } catch (error) {
      console.error('Error fetching browser stats miners:', error);
    }
  };
}
export default function getCommonLinksInfo(period_start: string, period_end: string) {
  return async function (dispatch: AppDispatch) {
    try {
      const opensBrowser = await rpc.transmit('statistics.v2.clicks', {
        start_date: period_start,
        end_date: period_end,
        discreteness: Span.Day,
        product_ids: [Product.CT],
      });
      const opensPool = await rpc.transmit('statistics.v2.clicks', {
        start_date: period_start,
        end_date: period_end,
        discreteness: Span.Day,
        product_ids: [Product.Pool],
      });

      const updateOpensBrowser = opensBrowser
        .map((item) => ({ timestamp: item.timestamp, account_id: +item.advert_id, value: item.value }))
        .sort((a, b) => a.timestamp - b.timestamp);
      const updateOpensPool = opensPool
        .map((item) => ({ timestamp: item.timestamp, account_id: +item.advert_id, value: item.value }))
        .sort((a, b) => a.timestamp - b.timestamp);

      dispatch({ type: BROWSER_CHANGE_TABLE_OPENS, payload: updateOpensBrowser });
      dispatch({ type: POOL_OPENS_SUCCESS, opens: updateOpensPool });
      const installs = await rpc.transmit('statistics.referrals', {
        start_date: period_start,
        end_date: period_end,
        discreteness: Span.Day,
      });
      const updateInstalls = updateTimestamp(installs.items).sort((a, b) => a.timestamp - b.timestamp);
      dispatch({
        type: BROWSER_CHANGE_TABLE_INSTALLS,
        payload: updateInstalls,
      });
      dispatch({
        type: POOL_INSTALLS_SUCCESS,
        installs: updateInstalls,
      });
      const minersBrowser = await rpc.transmit('statistics.referrals.payments', {
        start_date: period_start,
        end_date: period_end,
        group: AffiliateGroup.Browser,
        discreteness: Span.Day,
      });
      const minersPool = await rpc.transmit('statistics.referrals.payments', {
        start_date: period_start,
        end_date: period_end,
        group: AffiliateGroup.Pool,
        discreteness: Span.Day,
      });

      dispatch({
        type: BROWSER_CHANGE_TABLE_MINERS,
        payload: minersBrowser.items
          .map((item) => ({
            ...item,
            timestamp: item.timestamp! * 1000,
            value: item.users,
          }))
          .sort((a, b) => a.timestamp - b.timestamp),
      });
      dispatch({
        type: POOL_MINERS_SUCCESS,
        miners: minersPool.items
          .map((item) => ({
            ...item,
            timestamp: item.timestamp! * 1000,
            value: item.users,
          }))
          .sort((a, b) => a.timestamp - b.timestamp),
      });
    } catch (e) {
      dispatch({ type: BROWSER_LINKS_ERROR });
      console.log(e);
    }
  };
}
