import React, { FC, useRef, useState } from 'react';
import styles from './NftIntroduction.module.scss';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperClass, SwiperSlide, SwiperRef } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Keyboard, Navigation, Pagination } from 'swiper/modules';
import NftSliderItem from './NftSliderItem/NftSliderItem';
import classNames from 'classnames';
import { Card } from '@shared/Card/Card';
import { ReactComponent as PresentSvg } from '@assets/colorless/present.svg';
import cls from 'classnames';

export type NftIntroductionProps = {
  withDivider?: boolean;
  black?: boolean;
  Icon?: React.ReactNode;
};

const HIDE_BANNER = true;

const initialSlider = [
  {
    id: '1',
    title: 'Age of Eggs: Your Own Mining-RPG',
    text: 'You don’t have to use only a PC version of CryptoTab Browser to earn Bitcoins. CTB is available on mobile devices as well! Mining, Cloud.Boost, Referral Program - all features of CryptoTab Browser are also available on mobile devices. Try it now for free! ',
    src: 'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-browser/promo-nft@2x.png',
    link: 'https://market.ctnft.net/',
    icon: PresentSvg,
  },
  {
    id: '2',
    title: 'Age of Eggs: Your Own Mining-RPG',
    text: 'You don’t have to use only a PC version of CryptoTab Browser to earn Bitcoins. CTB is available on mobile devices as well! Mining, Cloud.Boost, Referral Program - all features of CryptoTab Browser are also available on mobile devices. Try it now for free! ',
    src: 'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-browser/devices.png',
    link: 'https://cryptotabnft.net/',
    icon: PresentSvg,
  },
  {
    id: '3',
    title: 'Age of Eggs: Your Own Mining-RPG',
    text: 'You don’t have to use only a PC version of CryptoTab Browser to earn Bitcoins. CTB is available on mobile devices as well! Mining, Cloud.Boost, Referral Program - all features of CryptoTab Browser are also available on mobile devices. Try it now for free! ',
    src: 'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-browser/devices.png',
    link: 'https://cryptotabnft.net/',
    icon: PresentSvg,
  },
  {
    id: '4',
    title: 'Age of Eggs: Your Own Mining-RPG',
    text: 'You don’t have to use only a PC version of CryptoTab Browser to earn Bitcoins. CTB is available on mobile devices as well! Mining, Cloud.Boost, Referral Program - all features of CryptoTab Browser are also available on mobile devices. Try it now for free! ',
    src: 'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-browser/devices.png',
    link: 'https://cryptotabnft.net/',
    icon: PresentSvg,
  },
];

export const NftIntroduction: FC<NftIntroductionProps> = (props) => {
  const ref = useRef<SwiperRef>(null);

  const [currentSlideIdx, setCurrentSlideIdx] = useState(0);

  const [slider, setSlider] = useState<SwiperClass>();

  if (HIDE_BANNER) {
    return null;
  }

  return (
    <Card className={styles.card}>
      <div className={`${styles.nftIntro} ${props.black && styles.nftIntro_black}`}>
        <Swiper
          ref={ref}
          className={styles.swiper}
          spaceBetween={50}
          slidesPerView={1}
          loop={true}
          modules={[Autoplay, Pagination, Navigation, Keyboard]}
          wrapperClass={`${classNames(['swiper-wrapper', styles.swiper__wrapper])}`}
          onInit={(swiper: SwiperClass) => {
            setSlider(swiper);
            setCurrentSlideIdx(0);
          }}
          keyboard={true}
          onSlideChange={(swiper: SwiperClass) => {
            setCurrentSlideIdx(swiper.realIndex);
          }}
          pagination={{
            clickable: true,
            el: '.swiper-pagination',
            type: 'bullets',
            bulletClass: `${cls(['swiper-pagination-bullet', styles.swiper__bullet])}`,
            bulletActiveClass: `${cls(['swiper-pagination-bullet-active', styles.swiper__bullet_active])}`,
            renderBullet: function (index: number, className: string) {
              return '<span class="' + className + '">' + '<i></i>' + '<b></b>' + '</span>';
            },
          }}
          autoplay={{
            delay: 10000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
          }}
        >
          {initialSlider.map((slide, index) => (
            <SwiperSlide
              key={slide.id + 'sw'}
              className={classNames([
                styles.swiper__slide,
                ref.current?.swiper.activeIndex === index && styles.swiper__slide_active,
              ])}
            >
              <NftSliderItem
                title={slide.title}
                text={slide.text}
                src={slide.src}
                link={slide.link}
                Icon={slide.icon}
                isVisible={currentSlideIdx === index}
                onCounterEnd={() => slider?.slideNext()}
              />
            </SwiperSlide>
          ))}
          <div className={cls(['swiper-pagination', styles.swiper__pagination])}></div>
        </Swiper>
      </div>
    </Card>
  );
};
