import styles from './FooterPromoSection.module.scss';
import { Button } from '@shared/Button/Button';
import { useTranslation } from 'react-i18next';
import { ArrowRightSmSvg, RefreshSvg } from '@assets/colorless';

export type FooterPromoSectionProps = {
  onOtherClick: () => void;
  onAllClick: () => void;
  isHide?: boolean;
};

export default function FooterPromoSection(props: FooterPromoSectionProps) {
  const { t } = useTranslation();
  const { onOtherClick, onAllClick, isHide } = props;
  if (isHide) {
    return null;
  }
  return (
    <div className={styles.footer}>
      <Button type="button" size="medium" onClick={onOtherClick} Icon={<RefreshSvg />}>
        {t('Show other')}
      </Button>
      <Button type="button" size="medium" onClick={onAllClick} iconPosition="right" Icon={<ArrowRightSmSvg />}>
        {t('View all')}
      </Button>
    </div>
  );
}
