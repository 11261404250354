import { Container } from '@shared/index';
import { MinerCard } from '@pages/FarmStatistics/view/components/MinerCard';
import { EarningsCard } from '@pages/FarmStatistics/view/components/EarnigsCard';
import { LinkCard } from './LinkCard/LinkCard';

export type LinkInfoViewProps = {
  id: number;
  onGeneratePost: () => void;
};

export function LinkInfoView({ id, onGeneratePost }: LinkInfoViewProps) {
  return (
    <Container vertical={20}>
      <LinkCard id={id} onGeneratePost={onGeneratePost} />
      <MinerCard />
      <EarningsCard />
    </Container>
  );
}
