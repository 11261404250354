import { WithdrawStatus } from '@utils/transactionStatus';
import { TFunction } from 'i18next';

export function mapStatus(status: number, t: TFunction): [string, string] {
  switch (status) {
    case WithdrawStatus.created:
      return [t('NotConfirmed.Confirm'), t('created')];
    case WithdrawStatus.confirmed:
      return [t('Processing.Processing'), t('confirmed')];
    case WithdrawStatus.canceled:
      return [t('canceled.canceled'), t('canceled')];
    case WithdrawStatus.rejected:
      return [t('rejected.rejected'), t('rejected')];
    case WithdrawStatus.approved:
      return [t('Processing.Processing'), t('approved')];
    case WithdrawStatus.sent:
      return [t('InProgress.InProgress'), t('sent')];
    case WithdrawStatus.processed:
      return [t('InProgress.InProgress'), t('processed')];
    case WithdrawStatus.error:
      return [t('error.error'), t('error')];
    case WithdrawStatus.complete:
      return [t('complete.complete'), t('complete')];
    case WithdrawStatus.reviewed:
      return [t('Processing.Processing'), t('reviewed')];
    case WithdrawStatus.reconfirm:
      return [t('reconfirm.reconfirm'), t('reconfirm')];
    case WithdrawStatus.reconfirmed:
      return [t('Processing.Processing'), t('reconfirmed')];

    default:
      return ['', ''];
  }
}
