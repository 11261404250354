const toFixedDown = (x: number, digits: number) => {
  const factor = 10 ** digits;
  return (Math.round(x * factor - 0.5) / factor).toFixed(digits);
};

export function fixedNumber(numToFix: number, flag?: boolean) {
  return (num: number) => {
    if (num === 0 || num === null || !num) {
      return Number(0).toFixed(numToFix);
    }

    const [, afterNumber] = num.toString().split('.');

    if (!afterNumber || afterNumber?.length < numToFix || flag) {
      return num.toFixed(numToFix);
    }

    return !!('' + num).match('e') ? toFixedDown(num, numToFix) : ('' + num).slice(0, 10);
  };
}
