import { AppDispatch } from '../..';
import {
  POOL_INSTALLS_SUCCESS,
  POOL_LINKS_ERROR,
  POOL_LINKS_REQUEST,
  POOL_LINKS_SUCCESS,
  POOL_MINERS_SUCCESS,
  POOL_OPENS_SUCCESS,
} from '../constant';
import { rpc } from '../../backend/Rpc';
import { AffiliateGroup, Product } from '../../backend/JsonRpcApi';
import { Span } from '@shared/Graph/Tabs';
export default function getPoolStatsLinks(period_start: string, period_end: string) {
  return async function (dispatch: AppDispatch) {
    const updateTimestamp = <T extends { timestamp: number }>(arr: T[]): T[] =>
      arr.map((item) => ({ ...item, timestamp: item.timestamp * 1000 }));
    try {
      dispatch({ type: POOL_LINKS_REQUEST });
      const opens = await rpc.transmit('statistics.v2.clicks', {
        start_date: period_start,
        end_date: period_end,
        discreteness: Span.Day,
        product_ids: [Product.Pool],
      });
      const updateOpens = opens
        .map((item) => ({ timestamp: item.timestamp, account_id: +item.advert_id, value: item.value }))
        .sort((a, b) => a.timestamp - b.timestamp);
      dispatch({ type: POOL_OPENS_SUCCESS, opens: updateOpens });
      const installs = await rpc.transmit('statistics.referrals', {
        start_date: period_start,
        end_date: period_end,
        discreteness: Span.Day,
      });
      dispatch({
        type: POOL_INSTALLS_SUCCESS,
        installs: updateTimestamp(installs.items).sort((a, b) => a.timestamp - b.timestamp),
      });
      const miners = await rpc.transmit('statistics.referrals.payments', {
        start_date: period_start,
        end_date: period_end,
        group: AffiliateGroup.Pool,
        discreteness: Span.Day,
      });
      dispatch({
        type: POOL_MINERS_SUCCESS,
        miners: miners.items
          .map((item) => ({
            ...item,
            timestamp: item.timestamp! * 1000,
            value: item.users,
          }))
          .sort((a, b) => a.timestamp - b.timestamp),
      });
      dispatch({ type: POOL_LINKS_SUCCESS });
    } catch (e) {
      dispatch({ type: POOL_LINKS_ERROR });
      console.log(e);
    }
  };
}
