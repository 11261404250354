import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BrowserPromoVideo } from '@service/reducers/browserPromoReducer';
import { LandingCard, PromoSection } from '@shared/index';
import styles from './BrowserVideoBlock.module.scss';
import { Root } from '@service/reducers';
import { usePromoShowOthers } from '@hooks/usePromoShowOthers';
import FooterPromoSection from '@shared/FooterPromoSection/FooterPromoSection';
import { useMemo } from 'react';
const STEP = 6;
export function BrowserVideoBlock() {
  const { t } = useTranslation();
  const videos = useSelector<Root, BrowserPromoVideo[]>((store) => store.browserPromo.video);
  const { visible, showAll, onOther, isHide } = usePromoShowOthers(STEP, videos.length);

  return (
    <PromoSection
      title={t('Promo post videos')}
      text={t('With these videos, you can draw more attention to your promo-posts and increase your mining network.')}
      id="video"
      Footer={<FooterPromoSection onOtherClick={onOther} onAllClick={showAll} isHide={isHide} />}
    >
      <div className={styles.container}>
        {videos.slice(visible.from, visible.to).map((item, index) => (
          <LandingCard
            key={item.id + index}
            src={item.image}
            className={styles.image}
            disabledLink
            href={`https://youtu.be/${item.id}`}
            linkStyle={styles.linkStyle}
          />
        ))}
      </div>
    </PromoSection>
  );
}
