import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import getBalances from '@service/actions/getBalances';
import { getWithdrawals } from '@service/actions/getWithdrawals';
import getWithdrawalsLastAddresses from '@service/actions/getWithdrawalsLastAddresses';
import getNetworkAlert from '@service/actions/getNetworkAlert';
import { Root } from '@service/reducers';
import { HelmetHead } from '../headersComponents/HelmetHead';
import { WithdrawalView } from './view/WithdrawalView';
import { EMAIL_VERIFY } from '@utils/links';
import { CHANGE_IS_BITCOIN } from '@service/constant';
import { BrowserSubheader } from '@pages/headersComponents/BrowserSubheader';

export default function Withdrawal() {
  const dispatch = useDispatch();
  const store = useSelector((store: Root) => store);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch<{ BTCOrHSH: string }>();

  useEffect(() => {
    dispatch(getWithdrawalsLastAddresses());
    !store.balance.get_balance && dispatch(getBalances());
    dispatch(getNetworkAlert());
  }, [dispatch]);

  useEffect(() => {
    if (store.user.hasRequested && !store.user.email_verified) {
      history.replace(`${EMAIL_VERIFY}?next=/app/withdrawal`);
    }

    return () => {
      window.localStorage.removeItem('resolve_change_ncw');
    };
  }, []);

  useEffect(() => {
    if (store.user.hasRequested && !store.user.email_verified) {
      history.replace(`${EMAIL_VERIFY}?next=/app/withdrawal`);
    }
  }, [store.user.hasRequested]);

  useEffect(() => {
    if (match.params.BTCOrHSH != undefined) {
      dispatch({ type: CHANGE_IS_BITCOIN, isBitcoin: match.params.BTCOrHSH === 'BTC' });
    }

    dispatch(getWithdrawals(true));
  }, []);

  return (
    <>
      <HelmetHead title={t('Withdraw')} />
      <BrowserSubheader />
      <WithdrawalView />
    </>
  );
}
