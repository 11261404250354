import { AffiliateBenefits, AffiliateIntroduction, NftIntroduction } from '@shared/index';
import { BROWSER_DASH, BROWSER_PROMO } from '@utils/links';
import { AffiliatePromoAccess } from '@shared/AffiliatePromoAccess/AffiliatePromoAccess';
import { Develop } from './components/Develop/Develop';
import { BrowserCalc } from './components/BrowserCalc/BrowserCalc';
import { Trans, useTranslation } from 'react-i18next';
import { Container } from '@shared/Container/Container';
import { useAnimation } from '@hooks/useAnimation';
import { AffilateVideo } from '@shared/AffilateVideo/AffilateVideo';
import { Start } from './components/Start/Start';
import { Earn } from '@pages/BrowserAbout/view/components/Earn/Earn';

export const BrowserAboutView = () => {
  const { t } = useTranslation();
  useAnimation();

  return (
    <Container vertical={20}>
      <AffiliateIntroduction
        MainBlock={
          <AffilateVideo
            previewSrc={
              'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-browser/browser-about-video-preview.jpg'
            }
            previewRetinaSrc={
              'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-browser/browser-about-video-preview@2x.jpg'
            }
            decorationImage={'https://cdn.cryptobrowser.site/images/affiliate-redesign/general/video-coins.png'}
            player="youtube"
            videos={{
              en: '0rvMqQo5W00',
              ru: 'oSLpLTThIfo',
              fr: '3VI5hMNtdeU',
              de: '8F9i3cuA-14',
              es: 'EZhWC6hsCkw',
              it: 'OdhW5bZgu1M',
              pt: 'JJxYAI4yG-c',
            }}
          />
        }
        Title={t('Invite new users and earn Bitcoins')}
        Description={
          <Trans
            i18nKey={
              'Invite new people to CryptoTab Browser and get a percentage of their income up to the 10th level of referral network. You can <strong>earn more than 1 BTC per month!</strong>'
            }
            components={{
              strong: <strong />,
            }}
          />
        }
        withParallax
        dashboardUrl={BROWSER_DASH}
        extraButtonUrl={'https://cryptobrowser.site'}
      />
      <Develop />
      <BrowserCalc />
      <Start />

      <AffiliateBenefits>
        <AffiliateBenefits.Item title={t('2-in-1 solution, surf the web and earn at the same time')} />
        <AffiliateBenefits.Item title={t('Sync data across all of your devices')} />
        <AffiliateBenefits.Item title={t('High-speed built-in mining algorithm')} />
        <AffiliateBenefits.Item title={t('10-level referral system')} />
        <AffiliateBenefits.Item title={t('10-level referral system')} />
        <AffiliateBenefits.Item title={t('Easy withdrawal to a BTC wallet at any time')} />
      </AffiliateBenefits>

      <AffiliatePromoAccess
        src={'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-browser/ready_made@2x.png'}
        srcMob={'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-browser/ready_made_mobile@2x.png'}
        promoUrl={BROWSER_PROMO}
      />
      <Earn />
      <NftIntroduction />
    </Container>
  );
};
