import { Card } from '@shared/Card/Card';
import React, { MutableRefObject, ReactNode, useRef } from 'react';
import { Span } from '../Tabs';
import styles from './GraphComponent.module.scss';
import { Graph } from '..';
import cls from 'classnames';
import { Button } from '@shared/Button/Button';
import { ReactComponent as Arrow } from '@assets/colorless/arrowRight.svg';
import { ReactComponent as Question } from '@assets/colored/question.svg';
import { StatisticAction } from '@pages/PoolStatistic/view/components/PoolStatisticGraph';
import { useTranslation } from 'react-i18next';
interface GraphComponentProps {
  title?: string;
  yFormat?: (v: any) => string;
  xFormat?: (v: any) => string;
  span: Span;
  dataRef?: MutableRefObject<ReactNode>;
  onSpanChanged: (val: Span) => void;
  type?: string;
  data: any[];
  period: { start: string; end: string };
  onPeriodChange: (period: { period_start: string; period_end: string }) => void;
  total?: number | string;
  SwitchElem?: React.ReactNode;
  TotalIcon?: ReactNode;
  actions?: StatisticAction[];
  tooltip?: string;
  className?: string;
  id?: string;
}

export default function GraphComponent(props: GraphComponentProps) {
  const {
    title,
    span,
    onSpanChanged,
    data,
    TotalIcon,
    type,
    xFormat,
    yFormat,
    period,
    onPeriodChange,
    total,
    SwitchElem,
    tooltip,
    actions,
    className,
    id,
  } = props;
  const dataRef = useRef<ReactNode>();
  const { t } = useTranslation();
  return (
    <Card
      title={title}
      headerClass={styles.cardHeader}
      className={className}
      id={id}
      TitleElement={
        <span className={styles.title}>
          {tooltip ? (
            <span className={styles.title}>
              {title} <Question className={styles.icon} />
              <div className={styles.tooltip}>{tooltip}</div>
            </span>
          ) : (
            title
          )}
          {total !== undefined && !TotalIcon && data.length > 0 ? <b className={styles.total_mob}>{total}</b> : ''}
        </span>
      }
      Switch={
        <div className={cls([styles.header], { [styles.noSwitch]: !SwitchElem })}>
          {SwitchElem && <div className={styles.switch}>{SwitchElem}</div>}
          <div className={styles.date}>{dataRef.current}</div>
        </div>
      }
    >
      <Graph
        title={title}
        span={span}
        onSpanChanged={onSpanChanged}
        data={data}
        withDate
        dataRef={dataRef}
        type={type}
        period={period}
        TotalIcon={TotalIcon}
        onPeriodChange={onPeriodChange}
        total={total}
        xFormat={xFormat}
        yFormat={yFormat}
      />
      {actions && actions.length > 0 && (
        <div className={styles.btns_wrapper}>
          {actions.map((el) => (
            <Button
              key={el.path}
              type="link"
              Icon={<Arrow />}
              size="medium"
              iconPosition={'right'}
              to={el.path}
              className={styles.link_btn}
            >
              {t(el.title)}
            </Button>
          ))}
        </div>
      )}
    </Card>
  );
}
