import React, { useEffect } from 'react';
import { ReactComponent as Change } from '@assets/colored/change-wallet-icon.svg';
import { useTranslation } from 'react-i18next';
import { Button, ButtonVariant } from '@shared/index';
import { CheckSvg, CircleCancelSvg } from '@assets/colorless';
import styles from './WithdrawalModal.module.scss';

export type ModalChangeProps = {
  onClose: () => void;
  onResolveChange: () => void;
};

export default function ModalChange({ onClose, onResolveChange }: ModalChangeProps) {
  useEffect(() => {
    window.localStorage.removeItem('changeShow');
  }, []);
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.body}>
        <Change />
        <div className={styles.title}>{t('changewallet.title')}</div>
        <div className={styles.text}>{t('changewallet.txt')}</div>
      </div>
      <div className={styles.footer}>
        <Button type="button" onClick={onClose} size="medium" Icon={<CircleCancelSvg />}>
          {t('changewallet.button.cancel')}
        </Button>
        <Button
          type="button"
          variant={ButtonVariant.Primary}
          onClick={onResolveChange}
          size="medium"
          Icon={<CheckSvg />}
        >
          {t('changewallet.button.confirm')}
        </Button>
      </div>
    </>
  );
}
