import React, { useEffect, useState } from 'react';

export const useContainerWidth = (containerRef: React.RefObject<HTMLDivElement>) => {
  const [containerWidth, setContainerWidth] = useState<number | null>(null);

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };

    updateWidth();

    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [containerRef]);

  return containerWidth;
};
