export enum SupportErrorVariant {
  Default,
  Email,
  Text,
  AccountId,
  Captcha,
  Date,
  SelectedWithdrawal,
  Url,
}

export type SupportError = {
  error: string;
  variant: SupportErrorVariant;
};
