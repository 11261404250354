import Balance, { BalanceVariant } from '../../../components/Balance';
import { PromoType } from '@service/reducers/promoReducer';
import { Container, News, ProductPromoBanner } from '@shared/index';
import { FARM_PROMO } from '@utils/links';
import { Promo } from './components/';
import BannerDashboard from '@shared/BannerDashboard/BannerDashboard';

export type FarmDashboardViewProps = {
  promo: PromoType[];
  createCode: () => void;
};

export default function FarmDashboardView({ promo, createCode }: FarmDashboardViewProps) {
  return (
    <Container vertical={20}>
      <Balance variant={BalanceVariant.Farm} />
      <BannerDashboard />
      <Promo promo={promo} createCode={createCode} isNeedRequest={false} />
      <ProductPromoBanner
        cookie="farmbanner=hide"
        link={FARM_PROMO}
        image={{
          srcMob: 'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-farm/farm-promo-mob.png',
          srcSetMob: 'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-farm/farm-promo-mob@2x.png',
          src: 'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-farm/farm-promo.png',
          srcSet: 'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-farm/farm-promo@2x.png',
        }}
      />
      <News />
    </Container>
  );
}
