import { FC } from 'react';
import styles from './AndroidModal.module.scss';
import { useTranslation } from 'react-i18next';
import {
  AccessSvg,
  LightBigSvg,
  LightBigDarkSvg,
  MaxBigSvg,
  MaxBigDarkSvg,
  ProBigSvg,
  ProBigDarkSvg,
  NoAdsSvg,
  SecureSvg,
  SpeedSvg,
  UserSvg,
} from '@assets/colored';
import { CardItem } from './AndroidCardItem';
import { LightSmallSvg, MaxSmallSvg, ProSmallSvg, ArrowLeftSVG } from '@assets/colored';
import cls from 'classnames';

const cardItems = [
  {
    id: '1',
    IconBig: LightBigSvg,
    IconBigDark: LightBigDarkSvg,
    IconSmall: LightSmallSvg,
    features: [
      {
        Icon: AccessSvg,
        text: 'CTB_Lite.Point_1',
      },
      {
        Icon: SpeedSvg,
        text: 'CTB_Lite.Point_2',
      },
    ],
    to: 'https://play.app.goo.gl/?link=https%3A//play.google.com/store/apps/details%3Fid%3Dlite.cryptotab.android',
    buttonStyle: styles.blue,
  },
  {
    id: '2',
    IconBig: ProBigSvg,
    IconBigDark: ProBigDarkSvg,
    IconSmall: ProSmallSvg,
    features: [
      {
        Icon: NoAdsSvg,
        text: 'CTB_Pro.Point_1',
      },
      {
        Icon: SpeedSvg,
        text: 'CTB_Pro.Point_2',
      },
      {
        Icon: UserSvg,
        text: 'CTB_Pro.Point_3',
      },
      {
        Icon: SecureSvg,
        text: 'CTB_Pro.Point_4',
      },
    ],
    to: 'https://play.app.goo.gl/?link=https%3A//play.google.com/store/apps/details%3Fid%3Dpro.cryptotab.android%26referrer%3Dutm_source%253Dlanding%2526utm_medium%253Dfooter%2526utm_content%253D00371d56-3676-4df2-4cfa-1d5636764cfa%2526utm_cid%253D9F36JMeTzV3aFwohWgA%2526utm_uid%253D8uzTch7ZjPfXF7-8aEA',
  },
  {
    id: '3',
    IconBig: MaxBigSvg,
    IconBigDark: MaxBigDarkSvg,
    IconSmall: MaxSmallSvg,
    features: [
      {
        Icon: NoAdsSvg,
        text: 'CTB_Pro.Point_1',
      },
      {
        Icon: SpeedSvg,
        text: 'CTB_Max.Point_2',
      },
      {
        Icon: UserSvg,
        text: 'CTB_Pro.Point_3',
      },
      {
        Icon: SecureSvg,
        text: 'CTB_Pro.Point_4',
      },
    ],
    to: 'https://play.google.com/store/apps/details?id=max.cryptotab.android',
    buttonStyle: styles.violet,
  },
];

type AndroidModalProps = {
  onClose: () => void;
};

export const AndroidModal: FC<AndroidModalProps> = (props) => {
  const { onClose } = props;
  const { t } = useTranslation();

  return (
    <div className={styles.androidModal__card}>
      <div className={styles.androidModal__card__header}>
        <h4>{t('Welc_land.CTB_Popup.Title')}</h4>
        <button onClick={onClose} className={cls([styles.androidModal__close, styles.androidModal__close_mobile])}>
          <ArrowLeftSVG className={styles.androidModal__close__icon} />
        </button>
      </div>
      <div className={styles.androidModal__card_wrapper}>
        <p className={styles.androidModal__description}>{t('Welc_land.CTB_Popup.Description')}</p>
        <div className={styles.androidModal__card_body}>
          {cardItems.map(({ IconBig, IconBigDark, features, to, IconSmall, id, buttonStyle }, index) => (
            <CardItem
              key={index}
              IconBig={IconBig}
              IconBigDark={IconBigDark}
              features={features}
              link={to}
              IconSmall={IconSmall}
              buttonStyle={buttonStyle}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
