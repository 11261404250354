import { useTranslation } from 'react-i18next';
import { Banner } from './Banner';
import src from '@assets/social-nft.png';
import srcSet from '@assets/social-nft.ios.png';
import srcMob from '@assets/social-nft-mob.png';
import styles from './Banner.module.scss';
import { ReactComponent as Discord } from '@assets/colored/discord.svg';
import { ReactComponent as TG } from '@assets/colored/telegram.svg';
import { ReactComponent as TW } from '@assets/colored/twitter.svg';

export type SocialNftBannerProps = {
  bottom?: number;
};

export function SocialNftBanner({ bottom }: SocialNftBannerProps) {
  const { t } = useTranslation();
  return (
    <Banner
      Media={<BannerMedia />}
      offsetBottom={bottom}
      title={t('Join_Media_Banner.Title')}
      description={'Join_Media_Banner.Description'}
      Button={<SocialButton />}
    />
  );
}

const BannerMedia = () => {
  return (
    <picture>
      <source srcSet={`${srcMob}`} media="(max-width: 768px)" />
      <source srcSet={`${src}, ${srcSet} 2x`} />
      <img src={src} srcSet={`${srcSet} 2x`} className="loaded" alt="nft" />
    </picture>
  );
};

const SocialButton = () => (
  <div className={styles.social}>
    <a href="https://discord.gg/jx8REbtgnV" target="_blank" rel="noreferrer">
      <Discord />
    </a>
    <a href="https://t.me/CryptoTabChannel" target="_blank" rel="noreferrer">
      <TG />
    </a>
    <a href="https://twitter.com/CryptoTabNFT" target="_blank" rel="noreferrer">
      <TW />
    </a>
  </div>
);
