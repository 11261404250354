import React, { ReactNode, useEffect } from 'react';
import overlayStyle from './ModalOverlay.module.scss';

type ModalOverlayProps = {
  onClick: () => void;
  children: ReactNode;
  active: boolean;
};

/**
 * @deprecated use src/shared/Modal
 */
export function ModalOverlay(props: ModalOverlayProps) {
  useEffect(() => {
    const handleEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        props.onClick();
      }
    };

    document.addEventListener('keydown', handleEsc);

    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, [props]);
  const active = props.active ? `${overlayStyle.overlay} ${overlayStyle.active}` : overlayStyle.overlay;
  return (
    <div className={active} onClick={props.onClick}>
      {props.children}
    </div>
  );
}
