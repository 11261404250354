import { useTranslation } from 'react-i18next';
import { PromoSection, TextCard } from '@shared/index';
import { browserTexts } from '@constants/promo/browserTexts';
import styles from './BrowserTextBlock.module.scss';
import { usePromoShowOthers } from '@hooks/usePromoShowOthers';
import FooterPromoSection from '@shared/FooterPromoSection/FooterPromoSection';
import { useMemo } from 'react';
const STEP = 3;
const MAX = browserTexts.length;
export function BrowserTextBlock() {
  const { t } = useTranslation();
  const { visible, onOther, showAll, isHide } = usePromoShowOthers(STEP, MAX);

  return (
    <PromoSection
      title={t('Texts for promo posts')}
      text={t('Use these prepared texts to make your posts in social with ease.')}
      id="text"
      Footer={<FooterPromoSection onOtherClick={onOther} onAllClick={showAll} isHide={isHide} />}
    >
      <div className={styles.container}>
        {browserTexts.slice(visible.from, visible.to).map((item) => (
          <TextCard text={item} key={item} />
        ))}
      </div>
    </PromoSection>
  );
}
