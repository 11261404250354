import { useMemo } from 'react';
import farmLands from '@utils/FarmLand.json';
import { useSelector } from 'react-redux';
import { Root } from '@service/reducers';

export default function useProductCardsInfo() {
  const store = useSelector((store: Root) => store);
  return useMemo(
    () => ({
      browserLinks: store.browserPromo.land.map((i) => i.url),
      browserPromos: store.balance.links.map((i) => i.id.toString()),
      farmLinks: farmLands.farm.filter((i) => i.kind === 'default').map((i) => i.url),
      farmProLinks: farmLands.farm.filter((i) => i.kind === 'pro').map((i) => i.url),
      farmPromos: store.promo.promos.map((p) => p.code),
      poolLinks: store.poolLandings.lands.map((i) => i.url),
      poolPromos: store.balance.links.map((i) => i.id.toString()),
    }),
    [store]
  );
}
