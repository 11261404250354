import React, { FC, ReactNode } from 'react';
import styles from './MineOnDevice.module.scss';
import { useTranslation } from 'react-i18next';
import { Picture } from '@shared/Picture/Picture';
import { Card } from '@shared/Card/Card';
import { StoreButton, StoreButtonKind } from '@shared/StoreButton/StoreButton';

export type MineOnDeviceProps = {
  title: string;
  description: string;
  StoreButtons?: ReactNode;
  withDivider?: boolean;
};

export const MineOnDevice: FC<MineOnDeviceProps> = (props) => {
  const { StoreButtons, title, description, withDivider } = props;
  const { t } = useTranslation();
  return (
    <Card className={styles.card}>
      <div className={styles.mineOnDevice}>
        <p className={styles.mineOnDevice__description}>{t('MobileAppBlock.SmallTitle')}</p>
        <h2 className={styles.mineOnDevice__title}>{t('MobileAppBlock.Title')}</h2>
        <div className={styles.mineOnDevice__btn_group}>
          <>
            <StoreButton
              kind={StoreButtonKind.PlayMarketFull}
              link={'https://play.google.com/store/apps/details?id=com.ctfarm'}
              target={'_blank'}
            />
            <StoreButton
              kind={StoreButtonKind.AppStoreFull}
              link={'https://apps.apple.com/app/cryptotab-farm/id1633191121'}
              target={'_blank'}
            />
          </>
        </div>
        <div className={styles.mineOnDevice__image}>
          <Picture
            imageDesktop={'https://cdn.cryptobrowser.site/images/affiliate-redesign/ctfarm-pro/mobile-app.png'}
            imageDesktop2x={'https://cdn.cryptobrowser.site/images/affiliate-redesign/ctfarm-pro/mobile-app@2x.png'}
            imageMob={'https://cdn.cryptobrowser.site/images/affiliate-redesign/ctfarm-pro/mobile-app-mob.png'}
            imageMob2x={'https://cdn.cryptobrowser.site/images/affiliate-redesign/ctfarm-pro/mobile-app-mob@2x.png'}
            breakPoint={'640px'}
          ></Picture>
        </div>
      </div>
    </Card>
  );
};
