import styles from './Subheader.module.scss';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cls from 'classnames';
import { AllSvg, CtpLogoSvg } from '@assets/colored';
import { CtSmLogoSvg, FarmSmLogoSvg } from '@assets/index';
import { useMemo } from 'react';
import { tabsConfig, TabsConfigTags } from './tabs-config';
import checkPathname, { PRODUCT_KIND } from '@utils/checkPathname';

export type TabsButtonsProps = {
  isLight?: boolean;
};

export default function TabsButtons({ isLight }: TabsButtonsProps) {
  const { t } = useTranslation();
  const className = cls({
    [styles.subheader__toggleitem]: true,
    [styles.subheader__toggleitem_white]: isLight,
  });
  const { pathname } = useLocation();
  const { farm, pool, browser, all } = useMemo(() => {
    const _arr = pathname.split('/');
    const last = _arr[_arr.length - 1];
    if (tabsConfig.hasOwnProperty(last)) {
      return tabsConfig[last as TabsConfigTags];
    }

    return tabsConfig.dashboard;
  }, [pathname]);
  return (
    <>
      <NavLink
        className={className}
        exact
        to={{ pathname: all }}
        activeClassName={styles.active}
        isActive={(match, location) => checkPathname(location.pathname) === PRODUCT_KIND.All}
      >
        <AllSvg />
        <span>{t('All')}</span>
      </NavLink>
      <NavLink
        className={className}
        exact
        to={{ pathname: browser }}
        activeClassName={styles.active}
        isActive={(match, location) => !!location.pathname.match('/browser')}
      >
        <CtSmLogoSvg />
        <span>{t('CT BROWSER')}</span>
      </NavLink>
      <NavLink
        className={className}
        exact
        isActive={(match, location) => !!location.pathname.match('/farm')}
        to={{ pathname: farm }}
        activeClassName={styles.active}
      >
        <FarmSmLogoSvg />
        <span>{t('CT FARM')}</span>
      </NavLink>
      <NavLink
        className={className}
        exact
        isActive={(match, location) => !!location.pathname.match('/pool')}
        to={{ pathname: pool }}
        activeClassName={styles.active}
      >
        <CtpLogoSvg />
        <span>{t('CT POOL')}</span>
      </NavLink>
    </>
  );
}
