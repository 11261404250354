import i18next from 'i18next';
import { NEWS_ERROR, NEWS_REQUEST, NEWS_SUCCESS } from '../constant';

const initialState = {
  news: [] as NewsElement[],
  newsRequest: false,
  newsError: false,
  newsRequeted: false,
  lang: i18next.language.split('-')[0],
};

export type NewsElement = {
  id: string;
  name: string;
  url: string;
  title: string;
  description: string;
  body: string;
  image: string;
  published_at: string;
  tags: string[];
  placing: string[];
  type: 'tips' | 'digest';
};

type Action = {
  type: string;
  news?: NewsElement[];
  lang?: string;
};

export type rootNews = typeof initialState;

export const newsReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case NEWS_REQUEST: {
      return {
        ...state,
        newsRequest: true,
        newsError: false,
      };
    }
    case NEWS_ERROR: {
      return {
        ...state,
        newsRequest: false,
        newsError: true,
      };
    }

    case NEWS_SUCCESS: {
      return {
        ...state,
        news: action.news,
        lang: action.lang,
        newsRequest: false,
        newsError: false,
        newsRequeted: true,
      };
    }
    default:
      return state;
  }
};
