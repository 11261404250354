import { BROWSER_ABOUT, BROWSER_DASH, FARM_ABOUT, FARM_DASH, POOL_ABOUT, POOL_DASH } from '@utils/links';
import Container from '../Container/Container';
import styles from './ProjectSection.module.scss';
import { ReactComponent as Farm } from '@assets/farm-icon.svg';
import { ReactComponent as FarmPro } from '@assets/farmPro.svg';
import { ReactComponent as CT } from '@assets/browser-icon.svg';
import { ReactComponent as CTP } from '@assets/colored/ctp-logo.svg';
import { Button, ButtonVariant, Card } from '@shared/index';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Rect } from '@assets/colorless/rects.svg';
import { ReactComponent as Info } from '@assets/colorless/info.svg';
import { Link } from 'react-router-dom';

export enum ProjectSectionVariant {
  CT,
  Farm,
  FarmPro,
  Pool,
}

export type ProjectSectionProps = {
  variant: ProjectSectionVariant;
  divider?: boolean;
};

const content = {
  [ProjectSectionVariant.CT]: {
    Icon: CT,
    title: 'ct-affiliate_about_text-edits.first.block.Product_Icon',
    text: 'ct-affiliate_about_text-edits.Invite_new_people_to',
    about: BROWSER_ABOUT,
    dash: BROWSER_DASH,
    src: 'https://cdn.cryptobrowser.site/images/affiliate-redesign/all-promo/all-about-browser.png',
    srcSet: 'https://cdn.cryptobrowser.site/images/affiliate-redesign/all-promo/all-about-browser@2x.png',
  },
  [ProjectSectionVariant.Farm]: {
    Icon: Farm,
    title: 'ct-affiliate_about_text-edits.first.Product_Icon',
    text: 'ct-affiliate_about_text-edits.Invite_new_people_to.67',
    about: FARM_ABOUT,
    dash: FARM_DASH,
    src: 'https://cdn.cryptobrowser.site/images/affiliate-redesign/all-promo/all-about-farm.png',
    srcSet: 'https://cdn.cryptobrowser.site/images/affiliate-redesign/all-promo/all-about-farm@2x.png',
  },
  [ProjectSectionVariant.FarmPro]: {
    Icon: FarmPro,
    title: 'CryptoTab Farm PRO',
    text: 'ctfarmpro_product_desc',
    about: FARM_ABOUT,
    dash: FARM_DASH,
    src: 'https://cdn.cryptobrowser.site/images/affiliate-redesign/all-promo/all-about-farm-pro.png',
    srcSet: 'https://cdn.cryptobrowser.site/images/affiliate-redesign/all-promo/all-about-farm-pro@2x.png',
  },
  [ProjectSectionVariant.Pool]: {
    Icon: CTP,
    title: 'CT Pool',
    text: 'ct-affiliate_about_text-edits.ct_pool_common_page_description',
    about: POOL_ABOUT,
    dash: POOL_DASH,
    src: 'https://cdn.cryptobrowser.site/images/affiliate-redesign/all-promo/all-about-pool.png',
    srcSet: 'https://cdn.cryptobrowser.site/images/affiliate-redesign/all-promo/all-about-pool@2x.png',
  },
};

export default function ProjectSection({ variant, divider }: ProjectSectionProps) {
  const { Icon, title, text, about, dash, src, srcSet } = content[variant];
  const { t } = useTranslation();
  return (
    <Card className={styles.card}>
      <Container
        Icon={
          <Link to={{ pathname: about }}>
            <Icon className="animate__animated" width={90} height={90} data-anim="fadeInDown" />
          </Link>
        }
        text={
          <span className="animate__animated" data-anim="fadeIn">
            {t(text)}
          </span>
        }
        innerTop={0}
        title={
          <Link to={{ pathname: about }} className={styles.title + ' animate__animated'} data-anim="fadeIn">
            {t(title)}
          </Link>
        }
      >
        <div className={styles.btns}>
          <Button type="link" to={about} Icon={<Info />} className={styles.primary + ' animate__animated'}>
            {t('VIEW DETAILS')}
          </Button>
          <Button
            type="link"
            variant={ButtonVariant.Primary}
            Icon={<Rect />}
            to={dash}
            className={styles.default + ' animate__animated'}
          >
            {t('OPEN DASHBOARD')}
          </Button>
        </div>
        <img src={src} srcSet={srcSet + ' 2x'} className={styles.img + ' animate__animated'} data-anim="fadeInUp" />
      </Container>
    </Card>
  );
}
