import styles from './ModalPromo.module.scss';
import src from '@assets/wallet-modal.png';
import srcSet from '@assets/wallet-modal@2x.png';
import { useTranslation } from 'react-i18next';
import { Button, ButtonVariant } from '@shared/index';
import { ReactComponent as Wallet } from '@assets/colored/icon-wallet.svg';
import { ReactComponent as Close } from '@assets/colored/circle-close.svg';
import { useCallback } from 'react';
import { externalLinks } from '@utils/externalLinks';

export type ModalWalletProps = {
  onClose: () => void;
  link?: string;
};

export function ModalWallet({ onClose, link }: ModalWalletProps) {
  const { t } = useTranslation();
  const handleCreate = useCallback(() => {
    window.open(link || externalLinks.ncw);
    onClose();
  }, [onClose, link]);
  return (
    <>
      <Close className={styles.close} onClick={onClose} />
      <div className={styles.content}>
        <img src={src} srcSet={srcSet + ' 2x'} alt="wallet" className={styles.img} />
        <div className={`${styles.body} ${styles.body__wall}`}>
          <a href={externalLinks.ncw} target="_blank" className={styles.title}>
            {t('PopUp1.Title')}
          </a>
          <div className={`${styles.text} ${styles.text__wall}`}>{t('PopUp1.Description')}</div>
        </div>
        <div className={`${styles.footer} ${styles.footer__wall}`}>
          <Button
            Icon={<Wallet />}
            type="button"
            onClick={handleCreate}
            className={styles.create}
            variant={ButtonVariant.Primary}
            size="medium"
          >
            {t('CREATE WALLET')}
          </Button>
        </div>
      </div>
    </>
  );
}
