import dayjs from 'dayjs';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { NewsElement } from '@service/reducers/newsReducer';
import styles from './DashboardNews.module.scss';

export function NewsItem({ item }: { item: NewsElement }) {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.dashboardNewsItem}>
        <a className={styles.dashboardNewsItemLink} href={item.url + item.name} target="_blank" rel="noreferrer">
          <div className={styles.dashboardNewsImage}>
            <img src={item.image} alt="preview" />
          </div>
          <div className={styles.dashboardNewsContent}>
            <span className={item.type === 'digest' ? styles.dashboardNewsCategory : styles.dashboardNewsCategoryBlue}>
              {t(tagName[item.type])}
            </span>
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </div>
        </a>
        <div className={styles.dashboardNewsFooter}>
          <div className={styles.dashboardNewsTags}>
            {item.tags.map((tag, index) => (
              <a
                href={`https://cryptobrowser.site/news/?tag=${tag}`}
                target="_blank"
                rel="noreferrer"
                key={item.id + index}
                className={styles.dashboardNewsTag}
              >
                #{tag}
              </a>
            ))}
          </div>
          <p className={styles.dashboardNewsPublished}>
            <b>{t('Published')}</b>
            <span>{dayjs(item.published_at).locale(i18next.language).format('D MMM YYYY')}</span>
          </p>
        </div>
      </div>
    </>
  );
}

const tagName = {
  tips: 'Tips and News',
  digest: 'INDUSTRY NEWS',
};
