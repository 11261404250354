import { AppDispatch } from '../..';
import { useFarmRespInfo } from '@hooks/useFarmRespInfo';
import { Span } from '@shared/Graph/Tabs';
import quickObjectSort from '@utils/quickObjectSort';
import { GET_FARM_EARNINGS_DATA } from '../constant';
import { FarmDataType } from '../reducers/farmGraphReducer';
import { rpc } from '../../backend/Rpc';

type Resp = {
  timestamp: number;
};

export default function getFarmEarnings(period: Span, userId: number, period_start: string, period_end: string) {
  return async function (dispatch: AppDispatch) {
    const respInfo = useFarmRespInfo(period);
    const resp = await rpc.transmit('farm.get_payments_statistics', {
      farm_ids: [userId],
      start_time: `${period_start}`,
      end_time: `${period_end}`,
      discreteness: `${respInfo.discreteness}` as any,
      payment_type_ids: [3],
    });

    let sortData = [];
    if (resp.items.length > 0) {
      sortData = quickObjectSort(resp.items, 'value');
    }
    if (sortData[0]?.value > 0.00000001) {
      sortData = resp.items.map((item: FarmDataType) => ({
        value: Number(item.value),
        timestamp: item.timestamp * 1000,
      }));
    } else {
      sortData = resp.items.map((item: FarmDataType) => ({
        value: (Number(item.value) * 100000000).toFixed(4),
        timestamp: item.timestamp * 1000,
      }));
    }
    dispatch({
      type: GET_FARM_EARNINGS_DATA,
      earning: sortData.sort((a: Resp, b: Resp) => a.timestamp - b.timestamp),
    });
  };
}
