import cls from 'classnames';
import styles from './WithdrawalView.module.scss';
import { WarningSvg } from '@assets/colorless';

export type WithdrawErrorType = {
  text: string;
  align?: 'left' | 'center' | 'right';
};

export function WithdrawalError({ text, align = 'left' }: WithdrawErrorType) {
  return (
    <div className={cls([styles.group__info, styles.group__info_error, styles[`group__info_error--${align}`]])}>
      <WarningSvg className={styles.info_error__svg} />
      <span className={styles.info_error__text}>{text}</span>
    </div>
  );
}
