import { NftIntroduction } from '@shared/NftIntroduction/NftIntroduction';
import { POOL_DASH, POOL_PROMO } from '@utils/links';
import { AffiliatePromoAccess } from '@shared/AffiliatePromoAccess/AffiliatePromoAccess';
import { Trans, useTranslation } from 'react-i18next';
import Lottie from 'lottie-react';
import { pool_bot } from '@utils/animation';
import styles from './PoolAboutView.module.scss';
import { AffiliateIntroduction } from '@shared/AffiliateIntroduction/AffiliateIntroduction';
import PoolAboutGenerate from '@pages/PoolAbout/view/PoolAboutGenerate';
import { AffiliateBenefits, Container } from '@shared/index';
import { useAnimation } from '@hooks/useAnimation';
import { PoolAccess } from '@pages/PoolAbout/view/PoolAccess';
import { ReactComponent as PoolAffiliateSvg } from '@assets/colored/pool-affiliate.svg';

export default function PoolAboutView() {
  const { t } = useTranslation();

  useAnimation();

  return (
    <Container vertical={20}>
      <AffiliateIntroduction
        MainBlock={
          <Lottie
            // @ts-ignore
            renderer="canvas"
            animationData={pool_bot}
            loop
            className={styles.bot}
          />
        }
        Title={t('CT_Pool.About.Title')}
        Description={<Trans i18nKey="CT_Pool.About.Description" components={{ b: <strong /> }} />}
        descriptionClass={styles.desc}
        titleClass={styles.title}
        withParallax
        dashboardUrl={POOL_DASH}
        extraButtonUrl={'https://pool.cryptobrowser.site/landing/'}
        Icon={<PoolAffiliateSvg />}
      />

      <PoolAboutGenerate />
      <AffiliateBenefits>
        <AffiliateBenefits.Item title={t('Mining on any device, without hardware')} />
        <AffiliateBenefits.Item title={t('Speed of up to 20 million H/s on any device')} />
        <AffiliateBenefits.Item title={t('No apps — manage everything in favorite browser')} />
        <AffiliateBenefits.Item title={t('Up to 10 levels of referral network')} />
        <AffiliateBenefits.Item title={t('Fast withdrawals with no limits')} />
        <AffiliateBenefits.Item title={t('BTC revenue from each pool miner in your network')} />
      </AffiliateBenefits>

      <AffiliatePromoAccess
        src={'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-pool/pool-promo-preview.png'}
        srcMob={'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-pool/pool-promo-preview@2x.png'}
        noMore
        promoUrl={POOL_PROMO}
      />
      <PoolAccess />
      <NftIntroduction black withDivider />
    </Container>
  );
}
