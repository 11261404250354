import React from 'react';
import { PoolLinks, Container, BannerAddLinks } from '@shared/index';
import styles from './PoolLinksView.module.scss';

type PoolLinksViewProps = {
  onLinkCreate: () => void;
};

export const PoolLinksView = ({ onLinkCreate }: PoolLinksViewProps) => {
  return (
    <Container vertical={20} className={styles.container}>
      <PoolLinks />
      <BannerAddLinks onLinkCreate={onLinkCreate} />
    </Container>
  );
};
