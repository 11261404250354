import { Trans, useTranslation } from 'react-i18next';
import { ComponentType, useRef, useState } from 'react';
import {
  BLACK_FRIDAY_BANNER_ID,
  BLACK_FRIDAY_INFO_URL,
  getIsBlackFriday,
} from '@shared/BannerVariants/BlackFriday/BlackFriday.helpers';
import cn from 'classnames';
import styles from './BlackFriday.module.scss';
import { ReactComponent as CloseSVG } from '@assets/colorless/cross.svg';
import { Button, ButtonVariant } from '@shared/Button/Button';
import { ArrowRightSvg } from '@assets/colorless';
import { useSelector } from 'react-redux';
import {
  blackFridayDesktopBackground,
  blackFridayMobileBackground,
  StatsIcon,
} from '@shared/BannerVariants/BlackFriday/assets';
import { Root } from '@service/reducers';
import { useContainerWidth } from '@shared/BannerVariants/hooks';

type BlackFridayWrapperProps = {
  onCloseBanner: () => void;
  offsetBottom?: number;
  offsetTop?: number;
};

const BlackFridayBannerContent = ({ onCloseBanner, offsetBottom, offsetTop }: BlackFridayWrapperProps) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const containerWidth = useContainerWidth(containerRef);
  const userTags = useSelector((state: Root) => state.user.tags);
  const isBlackFridayParticipant = userTags?.includes('black_friday_24');
  const isCompact = (containerWidth ?? 0) <= 768;

  const onClickCloseBanner = () => {
    const timestamp = Date.now();
    localStorage.setItem(BLACK_FRIDAY_BANNER_ID, timestamp.toString());
    onCloseBanner();
  };

  return (
    <>
      <div
        ref={containerRef}
        className={cn(styles.banner, isCompact && styles.responsiveBanner)}
        style={{ marginBottom: offsetBottom, marginTop: offsetTop }}
      >
        <button className={styles.close} onClick={onClickCloseBanner}>
          <CloseSVG color={'#B3B3B3'} />
        </button>
        <picture className={styles.background}>
          <img src={isCompact ? blackFridayMobileBackground : blackFridayDesktopBackground} alt="banner" />
        </picture>
        <div className={styles.content}>
          <div className={styles.texts}>
            <h3 className={styles.title}>{t('BF24.Banner.Short.Title')}</h3>
            <p className={styles.description}>
              <Trans
                i18nKey={isCompact ? 'BF24.Banner.Long.Descr' : 'BF24.Banner.Short.Descr'}
                components={{ b: <b /> }}
              />
            </p>
          </div>
          <div className={styles.buttons}>
            <Button
              type="link"
              target="_blank"
              external
              to={BLACK_FRIDAY_INFO_URL}
              variant={ButtonVariant.Primary}
              Icon={isBlackFridayParticipant ? <StatsIcon width={20} /> : <ArrowRightSvg />}
              iconPosition="right"
              size="medium"
              className={styles.enterButton}
            >
              {isBlackFridayParticipant ? t('BF24.Banner.Btn') : t('BF24.Banner.Short.Btn')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export const withBlackFridayWrapper = <
  P extends {
    offsetBottom?: number;
    offsetTop?: number;
  }
>(
  WrappedComponent: ComponentType<P>
) => {
  const BlackFridayHOC: React.FC<P> = (props) => {
    const [isBlackFridayVisible, setIsBlackFridayBannerVisible] = useState<boolean>(getIsBlackFriday);

    if (!isBlackFridayVisible) return <WrappedComponent {...props} />;

    return (
      <BlackFridayBannerContent
        onCloseBanner={() => setIsBlackFridayBannerVisible(false)}
        offsetBottom={props.offsetBottom}
        offsetTop={props.offsetTop}
      />
    );
  };

  return BlackFridayHOC;
};
