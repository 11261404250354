import styles from './SupportSuccessView.module.scss';
import { ReactComponent as Ok } from '@assets/colored/ok-green.svg';
import { Button, ButtonVariant } from '@shared/index';
import { BROWSER_DASH } from '@utils/links';
import { useTranslation } from 'react-i18next';

export default function SupportSuccessView() {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.decoration}></div>
      <div className={styles.card}>
        <div className={styles.body}>
          <Ok className={styles.img} />
          <div className={styles.title}>{t('Successfully sent')}</div>
          <div className={styles.text}>{t('Support.Description2')}</div>
        </div>
        <div className={styles.footer}>
          <Button
            type={'link'}
            to={BROWSER_DASH}
            className={styles.back_btn}
            size="medium"
            variant={ButtonVariant.Primary}
          >
            {t('Back')}
          </Button>
        </div>
      </div>
    </div>
  );
}
