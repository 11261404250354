import styles from './Select.module.scss';
import classNames from 'classnames';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import useClickOutside from '@hooks/useClickOutside';
import { useOnKeyClick } from '@hooks/useOnKeyClick';
import { KeyCodes } from '@constants/keyCodes';
import { getClientHeight } from '@utils/getClientDimensions';
import { ArrowDownShortSvg } from '@assets/colorless';
export interface Choice<T = string, P = Record<string, unknown>> {
  value: T;
  label: string;
  meta?: P;
  disabled?: boolean;
  Icon?: JSX.Element;
}

interface SelectProps<T, P> {
  className?: string;
  listClassName?: string;
  name: string;
  current?: Choice<T, P>;
  choices?: Choice<T, P>[];
  label?: string;
  disabled?: boolean;
  onChange?: (choice: Choice<T, P>) => void;
  renderChoise?: (choices: Choice<T, P>) => ReactNode;
  dropdownUp?: boolean;
}

export const Select = <T, P>({
  className,
  current,
  choices,
  disabled,
  label,
  onChange,
  dropdownUp,
  renderChoise,
  listClassName,
}: SelectProps<T, P>) => {
  const [menuOpened, setMenuOpened] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLUListElement>(null);

  const currentChoice = current || (choices && choices[0]) || { value: '', label: '' };

  const [currentSuggestIndex, setCurrentSuggestIndex] = useState(
    choices?.findIndex((choice) => choice === currentChoice) ?? 0
  );

  const handleOpen = () => {
    if (!menuOpened) {
      setMenuOpened(true);
    }
  };

  const handleClose = () => {
    setMenuOpened(false);
    setCurrentSuggestIndex(-1);
  };

  const handleToggle = useCallback(() => {
    if (menuOpened) {
      handleClose();
    } else {
      handleOpen();
    }
  }, [menuOpened]);

  const handleChange = (choice: Choice<T, P>) => {
    handleClose();
    onChange && onChange(choice);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (menuOpened && choices) {
        if (event.key === KeyCodes.DOWN) {
          event.preventDefault();
          setCurrentSuggestIndex((prevIndex) => Math.min(prevIndex + 1, choices.length - 1));
        } else if (event.key === KeyCodes.UP) {
          event.preventDefault();
          setCurrentSuggestIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        } else if (event.key === KeyCodes.ENTER && currentSuggestIndex >= 0) {
          event.preventDefault();
          handleChange(choices[currentSuggestIndex]);
        } else if (event.key === KeyCodes.ESC) {
          event.preventDefault();
          handleClose();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [menuOpened, currentSuggestIndex, choices]);

  useEffect(() => {
    if (menuOpened && menuRef.current) {
      const items = menuRef.current.querySelectorAll('li');
      if (items[currentSuggestIndex]) {
        items[currentSuggestIndex].focus();
      }
    }
  }, [menuOpened, currentSuggestIndex]);

  useOnKeyClick(handleClose, [KeyCodes.ESC]);
  useClickOutside(ref, handleClose, menuOpened);

  return (
    <div
      className={classNames(styles.root, className, {
        [styles.active]: menuOpened,
        [styles.disabled]: disabled,
        [styles.noChoices]: choices === undefined,
      })}
      onClick={handleToggle}
      ref={ref}
      tabIndex={0}
    >
      {label && <div className={styles.label}>{label}</div>}
      <div className={styles.current}>
        {'Icon' in currentChoice && currentChoice.Icon && <>{currentChoice.Icon}</>}
        {currentChoice.label}
      </div>
      {choices && (
        <ul
          ref={menuRef}
          className={classNames(styles.choices, listClassName, {
            [styles.opened]: menuOpened,
            [styles.up]:
              dropdownUp ||
              (ref.current
                ? getClientHeight() - ref.current.getBoundingClientRect().top - ref.current.offsetHeight < 320
                : false),
          })}
        >
          {choices.map((choice, key) => (
            <li
              key={key}
              className={classNames(styles.choice, {
                [styles.focusedChoice]: key === currentSuggestIndex,
                [styles.currentChoice]: currentChoice.value === choice.value,
                [styles.disabled]: choice.disabled,
              })}
              onClick={() => handleChange(choice)}
              onMouseOver={() => setCurrentSuggestIndex(key)}
              tabIndex={-1}
            >
              {renderChoise ? renderChoise(choice) : choice.label}
            </li>
          ))}
        </ul>
      )}
      <span>
        <ArrowDownShortSvg className={styles.arrow} />
      </span>
    </div>
  );
};
