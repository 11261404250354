import {
  GET_AGGREGATED_FARM_DATA,
  GET_FARM_ACTIVATION_DATA,
  GET_FARM_EARNINGS_DATA,
  GET_FARM_MINING_DATA,
  GET_FARM_OPENS_DATA,
} from '../constant';
const initialState = {
  activation_data: [],
  purchase_data: [],
  earning_data: [],
  referrals_data: [],
  aggregated_data: [],
  opens: [],
  isLoaded: false,
};

export default function farmGraphReducers(state = initialState, action: ActionType) {
  switch (action.type) {
    case GET_FARM_ACTIVATION_DATA: {
      return {
        ...state,
        activation_data: action.activation,
      };
    }
    case GET_FARM_MINING_DATA: {
      return {
        ...state,
        purchase_data: action.purchase,
      };
    }
    case GET_FARM_EARNINGS_DATA: {
      return {
        ...state,
        earning_data: action.earning,
      };
    }
    case GET_FARM_OPENS_DATA: {
      return {
        ...state,
        opens: action.opens,
      };
    }
    case GET_AGGREGATED_FARM_DATA: {
      return {
        ...state,
        aggregated_data: action.stats,
      };
    }

    default:
      return state;
  }
}

export type rootFarmGraph = {
  activation_data: FarmDataType[];
  purchase_data: FarmDataType[];
  earning_data: FarmDataType[];
  aggregated_data: AggregatedFarmDataType[];
  opens: FarmOpensDataType[];
};

type ActionType = {
  type: string;
  activation?: FarmDataType[];
  purchase?: FarmDataType[];
  earning?: FarmDataType[];
  opens: FarmOpensDataType[];
  stats: AggregatedFarmDataType[];
  payload: boolean;
};

export type FarmDataType = {
  value: number;
  timestamp: number;
};

export type AggregatedFarmDataType = {
  farm_id: number;
  value: number;
  workers_count: number;
};

export type FarmOpensDataType = {
  advert_id: string;
  timestamp: number;
  value: number;
};
