import React, { useState } from 'react';
import styles from './Tooltip.module.scss';
import classNames from 'classnames';

type TooltipProps = {
  text: string;
  children: React.ReactNode;
  className?: string;
};

export const Tooltip = ({ text, children, className }: TooltipProps) => {
  const [visible, setVisible] = useState(false);

  const showTooltip = () => setVisible(true);
  const hideTooltip = () => setVisible(false);

  return (
    <div className={styles.root}>
      <div className={styles.container} onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
        {children}
      </div>
      {visible && <div className={classNames(styles.text, className)}>{text}</div>}
    </div>
  );
};
