import React from 'react';
import styles from './BrandBlock.module.scss';
import { BrandLink, BrandLinkProps } from './BrandLink';
import cn from 'classnames';

export type BrandCardProps = {
  title: string;
  src: string;
  srcSet?: string;
  list: Array<BrandLinkProps>;
  imgStyle?: React.CSSProperties;
};

export function BrandCard({ title, src, srcSet, list, imgStyle }: BrandCardProps) {
  return (
    <div className={styles.card}>
      <div className={styles.card__title}>{title}</div>
      <div className={styles.card__img_container}>
        <img src={src} srcSet={(srcSet || src) + ' 2x'} alt="icon" className={styles.card__img} style={imgStyle} />
      </div>
      <div className={cn(styles.list, { [styles.center]: list.length === 1 })}>
        {list.map((item) => {
          return (
            <React.Fragment key={item.href}>
              <BrandLink res={item.res} title={item.title} href={item.href} />
              {item !== list[list.length - 1] && <div className={styles.line} />}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}
