import styles from './Tabs.module.scss';
import cn from 'classnames';

type Item<T> = { text: string; kind: T };

type TabsProps<T> = {
  active: T;
  setState: (v: T) => void;
  items: Item<T>[];
  className?: string;
};

export function Tabs<T>(props: TabsProps<T>) {
  const { items, active, className, setState } = props;
  return (
    <div className={cn(styles.container, className)}>
      {items.map(({ text, kind }) => (
        <div
          key={text}
          onClick={() => setState(kind)}
          className={cn(styles.item, kind === active && styles.item__active)}
        >
          {text}
        </div>
      ))}
    </div>
  );
}
