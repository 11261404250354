import { useDispatch } from 'react-redux';
import { showToast, ToastKind } from '../../components/Toast/Toast';
import getBalances from '@service/actions/getBalances';
import { Button } from '@shared/Button/Button';
import { CommonLinkModal } from '@shared/CommonLinkModal/CommonLinkModal';
import { rpc } from '../../backend/Rpc';
import { PlusSvg } from '@assets/colorless';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AffiliateLinkSvg } from '@assets/colored/affiliate-link.svg';
import { ReactComponent as LinkSvg } from '@assets/colorless/link.svg';
import { useModalContext } from '@context/ModalContext';

type AddLinkButtonProps = {
  onLinkCreate: () => void;
  btnClassName?: string;
};
export const AddLinkButton = (props: AddLinkButtonProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { createModalContent, openModal, closeModal } = useModalContext();

  const handleOpenModal = () => {
    createModalContent(
      <CommonLinkModal
        title={t('Create Link ID')}
        description={t(
          'Create additional personal link to track different traffic sources with different metrics to understand conversion rates.'
        )}
        btnText={t('Create')}
        withComment={true}
        onSubmit={handleLinkCreate}
        icon={<AffiliateLinkSvg />}
        btnIcon={<LinkSvg />}
      />
    );
    openModal();
  };

  const handleLinkCreate = async (comment: string) => {
    try {
      await rpc.transmit('users.me.create_link', {
        comment,
      });
      dispatch(getBalances());
      closeModal();
      props.onLinkCreate();
      showToast('Link/Code successfully added!', ToastKind.Success);
    } catch (e) {
      showToast('error.code', ToastKind.Error);
    }
  };

  return (
    <Button type="button" onClick={handleOpenModal} className={props.btnClassName} size="medium" Icon={<PlusSvg />}>
      {t('Add link')}
    </Button>
  );
};
