import React from 'react';
import styles from './NotFoundView.module.scss';
import { Card, Container } from '@shared/index';
import { useTranslation } from 'react-i18next';
import ErrorImg from '@assets/404.png';
import ErrorImg2x from '@assets/404@2x.png';
export const NotFoundView = () => {
  const { t } = useTranslation();
  return (
    <Container vertical={'auto'} horizontal={'auto'} className={styles.notFound__container}>
      <Card className={styles.notFound__card}>
        <div className={styles.notFound__body}>
          <div className={styles.notFound__text}>
            <h1 className={styles.notFound__heading}>
              {t('error404.Title')} - <span className={styles.notFound__status}>{t('error.code')} 404</span>
            </h1>
            <p>{t('error404.Description')}</p>
          </div>
          <img src={ErrorImg} srcSet={ErrorImg2x} alt="referral" />
        </div>
      </Card>
    </Container>
  );
};
