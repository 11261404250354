import { Button, ButtonVariant } from '@shared/index';
import { Trans, useTranslation } from 'react-i18next';
import { NcwLogo, RedClockSvg } from '@assets/colored';
import { WatchCvg } from '@assets/colorless';
import { useCallback } from 'react';
import { externalLinks } from '@utils/externalLinks';
import styles from './WithdrawalModal.module.scss';

export type ModalNetworkAlertProps = {
  onClose: () => void;
};
const goToNcWallet = () => {
  window.open(externalLinks.ncw);
};
export const ModalNetworkAlert = ({ onClose }: ModalNetworkAlertProps) => {
  const { t } = useTranslation();

  const handleClose = useCallback(() => {
    document.cookie = 'networkBtcAlert=hide; max-age=43200;';
    onClose();
  }, [onClose]);

  return (
    <>
      <div className={styles.body}>
        <RedClockSvg />
        <div className={styles[`title--network`]}>
          <Trans
            i18nKey={t('BTC_Overload.Description')}
            components={{
              a: <a href={externalLinks.fee} target="_blank" rel="noreferrer" />,
              a2: <a href={externalLinks.ncw} target="_blank" rel="noreferrer" />,
              b: <b />,
              p: <p />,
            }}
          />
        </div>
      </div>
      <div className={styles.footer}>
        <Button type="button" size="medium" Icon={<WatchCvg />} variant={ButtonVariant.Primary} onClick={handleClose}>
          {t('OK, GOT IT')}
        </Button>
        <Button
          type="button"
          size="medium"
          noSvgColorized
          Icon={<NcwLogo width={20} height={20} />}
          onClick={goToNcWallet}
        >
          {t('NC Wallet')}
        </Button>
      </div>
    </>
  );
};
