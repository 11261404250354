import styles from './CommonPromoView.module.scss';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonVariant } from '@shared/Button/Button';
import { ArrowRightSmSvg } from '@assets/colorless';

interface ProductProps {
  className?: string;
  bg: string;
  img: { src: string; srcSet: string };
  title: string;
  text: string;
  Icon: ReactNode;
  url: string;
}

export default function Product(props: ProductProps) {
  const { bg, url, text, img, Icon, title, className } = props;
  const { t } = useTranslation();
  return (
    <a className={styles.root} href={url}>
      <div className={classNames(styles.container, className)} style={{ backgroundImage: `url(${bg})` }} {...props}>
        <div className={styles.content}>
          {Icon}
          <h3 className={styles.content__title}>{t(title)}</h3>
          <p className={styles.content__text}>{t(text)}</p>
        </div>
        <div className={styles.btnWrapper}>
          <Button
            to={url}
            external={false}
            type="link"
            size="medium"
            className={styles.btn}
            iconPosition="right"
            Icon={<ArrowRightSmSvg />}
            variant={ButtonVariant.Primary}
          >
            {t('promoButton')}
          </Button>
        </div>
        <img className={styles.img} src={img.src} srcSet={img.srcSet + ' 2x'} alt={title} />
      </div>
    </a>
  );
}
