import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { rootUser } from '@service/reducers/userReducer';
import { Button, ButtonVariant, ModalOverlay } from '@shared/index';
import { LoadSpinner } from '@shared/Loader';
import { SettingsItem } from './SettingsItem';
import { SettingsLogin } from './SettingsLogIn';
import styles from './SettingsView.module.scss';
import { ReactComponent as PlusSvg } from '@assets/colorless/add_sign.svg';
import { ReactComponent as DoorSvg } from '@assets/colorless/door.svg';
import { ReactComponent as ArrowSvg } from '@assets/colorless/arrowLeft.svg';
import { ModalWallet } from '../../Withdrawal/view/PromoModals/ModalWallet';

export function SettingsView() {
  const { t } = useTranslation();
  const user = useSelector((store: { user: rootUser }) => store.user);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <div className={styles.container}>
        <div className={styles.card}>
          <div className={styles.card__header}>{t('Account')}</div>
          <div className={styles.card__body}>
            {user.social_request && (
              <div className={styles.info}>
                <LoadSpinner />
              </div>
            )}
            {user.social_error && (
              <div className={styles.info}>
                <span>{t('Something Went Wrong')}...</span>
              </div>
            )}
            {!user.social_request &&
              user.social.map((item) => (
                <SettingsItem
                  showPromo={() => setShowModal(true)}
                  key={item.id}
                  {...item}
                  canDelete={false && user.social.length === 1}
                />
              ))}
            {show && <SettingsLogin />}
          </div>
          <div className={styles.card__footer}>
            {!show ? (
              <>
                <Button
                  type="button"
                  size="medium"
                  iconPosition="right"
                  Icon={<DoorSvg color="#4285F4" />}
                  onClick={() => {
                    localStorage.removeItem('tokens');
                    window.location.href = `/logout/?next=/login/`;
                  }}
                >
                  {t('Logout')}
                </Button>
                <Button
                  type="button"
                  size="medium"
                  Icon={<PlusSvg color="#fff" />}
                  variant={ButtonVariant.Primary}
                  onClick={() => setShow(true)}
                >
                  {t('Add account')}
                </Button>
              </>
            ) : (
              <Button type="button" size="medium" Icon={<ArrowSvg />} onClick={() => setShow(false)}>
                {t('Back')}
              </Button>
            )}
          </div>
        </div>
      </div>
      {showModal && (
        <ModalOverlay active={showModal} onClick={() => setShowModal(false)}>
          <div className={styles.card} onClick={(e) => e.stopPropagation()}>
            <ModalWallet
              onClose={() => setShowModal(false)}
              link="https://ncwallet.page.link/?link=https%3A%2F%2Fapp.ncwallet.net&apn=com.ncwallet&amv=0&ibi=com.ncwallet&imv=0&isi=1615381976&referrer=utm_source%3Dsite%26utm_medium%3Daffiliate_user"
            />
          </div>
        </ModalOverlay>
      )}
    </>
  );
}
