import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CopyText } from '../CopyText/CopyText';
import { ShareBlock } from '../ShareBlock/ShareBlock';
import styles from './TextCard.module.scss';
import { Button } from '@shared/Button/Button';
import cls from 'classnames';
import { ArrowBottomSmSvg, ShareSvg } from '@assets/colorless';
import useClickOutside from '@hooks/useClickOutside';
export type TextCardProps = {
  text: string;
};

const MAX_LENGTH = 320;

export function TextCard({ text }: TextCardProps) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  return (
    <div className={`${styles.container} ${show && styles.container__show}`}>
      <div className={styles.body}>
        <div className={styles.text}>
          {show ? t(text) : t(text).length > MAX_LENGTH ? t(text).slice(0, MAX_LENGTH) + '...' : t(text)}
        </div>
        {t(text).length > MAX_LENGTH && (
          <div className={styles.more} onClick={() => setShow(!show)}>
            <span>{!show ? t('Learn more') : t('Show Less second')}</span>
            <ArrowBottomSmSvg className={cls([show && styles.active])} />
          </div>
        )}
      </div>
      <div className={styles.row}>
        <Share text={t(text)} />
        <CopyText isBtn text={t(text)} />
      </div>
    </div>
  );
}

const Share = ({ text }: { text: string }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);
  useClickOutside(ref, () => setShow(false), show);
  return (
    <Button
      ref={ref}
      type="button"
      size="medium"
      className={styles.share}
      Icon={<ShareSvg />}
      onClick={() => setShow((prev) => !prev)}
    >
      {t('share')}
      {show && (
        <div className={styles.share__list} id="commonAboutShare">
          <ShareBlock msg={t(text)} size="l" limit={20} />
        </div>
      )}
    </Button>
  );
};
