import { useTranslation } from 'react-i18next';
import { Button, ButtonVariant } from '../Button/Button';
import { ButtonGroup } from '../ButtonGroup/ButtonGroup';
import styles from './Graph.module.scss';
import cn from 'classnames';

export type TabsProps = {
  span: Span;
  onSpanChanged: (val: Span) => void;
  className?: string;
};

export enum Span {
  Day = 'days',
  Week = 'weeks',
  Month = 'months',
}

export default function Tabs({ span, onSpanChanged, className }: TabsProps) {
  const { t } = useTranslation();
  return (
    <div className={cn(styles.tabs, className)}>
      <Button
        type={'button'}
        size={'small'}
        variant={ButtonVariant.Default}
        isActive={span === Span.Day}
        onClick={() => onSpanChanged(Span.Day)}
        className={styles.btn}
      >
        {t('Day')}
      </Button>
      <Button
        type={'button'}
        size={'small'}
        variant={ButtonVariant.Default}
        isActive={span === Span.Week}
        onClick={() => onSpanChanged(Span.Week)}
        className={styles.btn}
      >
        {t('Week')}
      </Button>
      <Button
        type={'button'}
        size={'small'}
        variant={ButtonVariant.Default}
        isActive={span === Span.Month}
        onClick={() => onSpanChanged(Span.Month)}
        className={styles.btn}
      >
        {t('Month')}
      </Button>
    </div>
  );
}
