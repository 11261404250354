import { BROWSER_LAND_ERROR, BROWSER_LAND_REQUEST, BROWSER_LAND_SUCCESS, BROWSER_VIDEO_SUCCESS } from '../constant';

const initialState = {
  land_request: false,
  land_error: false,
  land_get: false,
  land: [],
  video: [],
  video_get: false,
};

export type BrowserPromoLand = {
  id: number;
  category: string;
  url: string;
  image: string;
  image2x?: string;
};

type ActionType = {
  type: string;
  land?: BrowserPromoLand[];
  video?: BrowserPromoVideo[];
};

export type rootBrowserPromo = Omit<typeof initialState, 'land' | 'video'> & {
  land: BrowserPromoLand[];
  video: BrowserPromoVideo[];
};

export type BrowserPromoVideo = {
  image: string;
  id: string;
};

export default function browserPromoReducer(state = initialState, action: ActionType) {
  switch (action.type) {
    case BROWSER_LAND_REQUEST: {
      return {
        ...state,
        land_request: true,
        land_error: false,
      };
    }
    case BROWSER_LAND_ERROR: {
      return {
        ...state,
        land_request: false,
        land_error: true,
      };
    }
    case BROWSER_LAND_SUCCESS: {
      return {
        ...state,
        land_request: false,
        land_error: false,
        land: action.land,
        land_get: true,
      };
    }
    case BROWSER_VIDEO_SUCCESS: {
      return {
        ...state,
        video: action.video,
        video_get: true,
      };
    }
    default:
      return state;
  }
}
