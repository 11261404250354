import styles from './Modal.module.scss';
import { ReactComponent as CrossSvg } from '@assets/colorless/cross.svg';
import classNames from 'classnames';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { KeyCodes } from '@constants/keyCodes';
import { useOnKeyClick } from '@hooks/useOnKeyClick';
import useClickOutside from '@hooks/useClickOutside';

export type ModalProps = PropsWithChildren & {
  onClose: () => void;
  opened: boolean;
  isDontClose?: boolean;
  className?: string;
  closeBtnClassName?: string;
  classNameBackground?: string;
};

export const Modal = (props: ModalProps) => {
  const { onClose, opened = false, isDontClose, closeBtnClassName, classNameBackground } = props;
  const [isRendered, setIsRendered] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  useClickOutside(modalRef, isDontClose ? () => {} : props.onClose, opened);
  useOnKeyClick(isDontClose ? () => {} : props.onClose, [KeyCodes.ESC]);

  useEffect(() => {
    if (opened) {
      setIsRendered(true);
    } else {
      setIsRendered(false);
    }
    return () => {
      setIsRendered(false);
    };
  }, [opened]);

  const modalClasses = classNames(styles.root, props.className, {
    [styles.rendered]: isRendered,
  });

  if (!opened) {
    return null;
  }

  return createPortal(
    <div className={classNames(styles.overlay, { [styles.opened]: opened }, classNameBackground)}>
      <div className={classNames(modalClasses, classNameBackground)} ref={modalRef}>
        <button onClick={onClose} className={classNames(styles.close, closeBtnClassName)}>
          <CrossSvg />
        </button>
        <div className={classNames([styles.content, classNameBackground])}>{props.children}</div>
      </div>
    </div>,
    document.body
  );
};
