import styles from './CalcModal.module.scss';
import { Button, ButtonVariant } from '@shared/index';

import { ReactComponent as TickSvg } from '@assets/colorless/tick.svg';
import { ReactComponent as AffiliateModalSvg } from '@assets/colored/affiliate-modal.svg';
import { Trans, useTranslation } from 'react-i18next';
import { FC } from 'react';

type CalcModalProps = {
  onClose: () => void;
};

export const CalcModal: FC<CalcModalProps> = (props) => {
  const { onClose } = props;
  const { t } = useTranslation();
  return (
    <div className={styles.calcModal__card}>
      <div className={styles.calcModal__card__header}>
        <AffiliateModalSvg />
        <h4>{t('Affiliate Program Income')}</h4>
      </div>
      <div className={styles.calcModal__card__body}>
        <p className={styles.calcModal__card_subtitle}>
          {t(
            'Once you join Affiliate Program and invite referrals, you get a bonus equal to a percentage of their mining profits. And also, an additional percentage is given for each user invited by your referrals — up to the 10 level of your mining network.'
          )}
        </p>
        <div className={styles.calcModal__overflow}>
          <div className={styles.calcModal__overflow_inner}>
            <div className={styles.calcModal__frame}>
              <h5>{t('Bonuses from referral system:')}</h5>
              <ul>
                <li>
                  <Trans
                    i18nKey={'%(level)s level — %(percent)s%% of referrals mining'}
                    values={{
                      level: 1,
                      percent: 15,
                    }}
                    components={{ b: <b /> }}
                  />
                </li>
                <li>
                  <Trans
                    i18nKey={'%(level)s level — %(percent)s%% of referrals mining'}
                    values={{
                      level: 2,
                      percent: 10,
                    }}
                    components={{ b: <b /> }}
                  />
                </li>
                <li>...</li>
                <li>
                  <Trans
                    i18nKey={'%(level)s level — %(percent)s%% of referrals mining'}
                    values={{
                      level: 10,
                      percent: 0.0625,
                    }}
                    components={{ b: <b /> }}
                  />
                </li>
              </ul>
            </div>

            <p className={styles.calcModal__card_note}>
              <Trans
                i18nKey={
                  'Note: we use the average monthly income of the CryptoTab users equal to $4 in BTC when calculating estimated income. You get bonuses from Affiliate Program as long as your referrals actively use CryptoTab Browser.'
                }
                components={{ b: <b /> }}
              />
            </p>
          </div>
          <div className={styles.calcModal__card__footer}>
            <Button
              type={'button'}
              variant={ButtonVariant.Primary}
              onClick={onClose}
              Icon={<TickSvg />}
              className={styles.calcModal__card__footer__btn}
            >
              {t('Ok')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
