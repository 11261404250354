import styles from './Top.module.scss';
import { Button } from '@shared/Button/Button';
import { MagicSvg } from '@assets/colorless';
import { useTranslation } from 'react-i18next';
import { useGeneratePostModal } from '@hooks/useGeneratePostModal';
import { PRODUCT_KIND } from '@utils/checkPathname';

type ProductFooterProps = {
  productKind: PRODUCT_KIND;
};

export default function ProductFooter({ productKind }: ProductFooterProps) {
  const { t } = useTranslation();
  const { handleGeneratePostClick } = useGeneratePostModal(productKind);
  return (
    <Button size="medium" Icon={<MagicSvg />} type="button" className={styles.btn} onClick={handleGeneratePostClick}>
      {t('GeneratePost.button')}
    </Button>
  );
}
