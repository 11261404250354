import styles from './NCWCheck.module.scss';
import cls from 'classnames';
import { ReactComponent as Logo } from '@assets/colored/ncwallet-logo.svg';
import { ReactComponent as Wallet } from '@assets/colorless/wallet.svg';
import { ReactComponent as Info } from '@assets/colorless/info.svg';
import { ReactComponent as Check } from '@assets/colorless/check.svg';
import { ReactComponent as LogoDisabled } from '@assets/colored/ncwallet-logo-gray.svg';
import { useTranslation } from 'react-i18next';
import { Button } from '@shared/index';

export type NCWCheckProps = {
  isCheck: boolean;
  showPromo: () => void;
};

export default function NCWCheck({ isCheck, showPromo }: NCWCheckProps) {
  const { t } = useTranslation();
  const containerClass = cls({
    [styles.container]: true,
    [styles.container__checked]: isCheck,
  });

  const textClass = cls({
    [styles.text]: true,
    [styles.text__checked]: isCheck,
  });
  return (
    <div className={containerClass}>
      {isCheck ? <Logo width={32} height={32} /> : <LogoDisabled />}
      <div className={textClass}>NC Wallet {isCheck ? t('Connected') : t('Not Connected')}</div>
      {isCheck ? <Check width={16} height={16} color="#37CC33" /> : <Info width={16} height={16} color="#F74249" />}
      {!isCheck && (
        <Button className={styles.btn} type="button" onClick={showPromo} size="small" Icon={<Wallet color="#4285F4" />}>
          {t('Connect')}
        </Button>
      )}
    </div>
  );
}
