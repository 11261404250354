import styles from './Language.module.scss';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Toggle from './Toggle';
import { CheckSvg, LanguageSvg } from '@assets/colorless';
import i18next from 'i18next';
import cls from 'classnames';
import { AppLang, RuSvg, ItSvg, PtSvg, DeSvg, EnSvg, EsSvg, FrSvg } from '@shared/Lang/Lang';

type LangsConfig = Array<{
  code: AppLang;
  name: string;
  Icon: ReactNode;
}>;

export const langs: LangsConfig = [
  {
    code: 'ru',
    name: 'Russian',
    Icon: <RuSvg />,
  },
  {
    code: 'en',
    name: 'English',
    Icon: <EnSvg />,
  },
  {
    code: 'es',
    name: 'Spanish',
    Icon: <EsSvg />,
  },
  {
    code: 'fr',
    name: 'French',
    Icon: <FrSvg />,
  },
  {
    code: 'de',
    name: 'German',
    Icon: <DeSvg />,
  },
  {
    code: 'it',
    name: 'Italian',
    Icon: <ItSvg />,
  },
  {
    code: 'pt',
    name: 'Portuguese',
    Icon: <PtSvg />,
  },
];
export default function Language() {
  const { t } = useTranslation();

  return (
    <Toggle Icon={<LanguageSvg />} title={t('Language')}>
      <div className={styles.container}>
        {langs.map((l) => (
          <div
            className={cls([styles.item, i18next.language.includes(l.code) && styles.item__active])}
            onClick={() => i18next.changeLanguage(l.code)}
            key={l.code}
          >
            {l.Icon}
            <span>{l.name}</span>
            {i18next.language.includes(l.code) && <CheckSvg />}
          </div>
        ))}
      </div>
    </Toggle>
  );
}
