import { PromoSection } from '@shared/PromoSection/PromoSection';
import { useTranslation } from 'react-i18next';
import { BrandContainer } from '@shared/Brand/BrandContainer';
import { BrandCard } from '@shared/Brand/BrandCard';
import ctpIcon from '@assets/ctp-icon.svg';
import ctpIcon500 from '@assets/ctp-icon-500.png';
import ctpIcon2000 from '@assets/ctp-icon-2000.png';
import ctpLogo from '@assets/ctp-logo.svg';
import ctpLogo500 from '@assets/ctp-logo-500.png';
import ctpLogo2000 from '@assets/ctp-logo-2000.png';
import archives from '../../../../archives/ct-pool_ui-screens.zip';
import ui from '@assets/pool-ui-preview.png';
import ui2x from '@assets/pool-ui-preview-retina.png';
export default function PoolPromoBrand() {
  const { t } = useTranslation();
  return (
    <PromoSection
      title={t('Brand resources')}
      text={t('Copy icon, logo, interface screenshots and create your unique promotional content')}
    >
      <BrandContainer>
        <BrandCard
          title={t('Product Icon')}
          src={ctpIcon}
          imgStyle={{ height: 80 }}
          list={[
            { res: '(.png)', title: t('500px'), href: ctpIcon500 },
            { res: '(.png)', title: t('2000px'), href: ctpIcon2000 },
            { res: '(.svg)', title: t('Vector'), href: ctpIcon },
          ]}
        />
        <BrandCard
          title={t('Product Logo')}
          src={ctpLogo}
          imgStyle={{ height: 40 }}
          list={[
            { res: '(.png)', title: t('500px'), href: ctpLogo500 },
            { res: '(.png)', title: t('2000px'), href: ctpLogo2000 },
            { res: '(.svg)', title: t('Vector'), href: ctpLogo },
          ]}
        />
        <BrandCard
          title={t('UI Screens')}
          src={ui}
          srcSet={ui2x}
          list={[{ res: '(.zip)', title: t('Download UI Screens'), href: archives }]}
        />
      </BrandContainer>
    </PromoSection>
  );
}
