export const browserTexts = [
  'Do you want to explore the crypto industry but don’t know how to start? Do you want to spend more time with your family and do things around, but are wasting your time in the office and it is killing you?  Do you want to run your own business with friends, but it’s complicated? There’s a way out! CryptoTab Browser with a built-in mining feature can solve your problem! Familiar interface, a lot of useful features, and earning possibility — it’s all about CryptoTab Browser! Use it as a default browser, share the link with friends, and build your own mining farm. Get discounts, bonuses, and gifts for using it and make more money! I know, you’ll definitely love it!',
  'The price for Bitcoin is surely unstable, but mining is always on time. If you look at the price graph, you’ll see that it constantly goes up and down. But the decrease is not a reason to get upset. You can mine now and ever, so that when the price goes up you’ll have much more money than before. And a good help in mining is CryptoTab Browser! Simple and easy to use, with the embedded mining feature, it’ll become the best substitute for your daily browser. Act now, don’t waste your time.',
  'How much do you know about mining? If you still think that it takes a bundle of cumbersome equipment, you’re absolutely wrong! The matter is that the world already has the first browser with the in-built mining feature — CryptoTab Browser! Still not convinced? Download and install it now — see with your own eyes how simply it works!',
  'It’s so simple to earn BTC nowadays! Until recently, you had to invest or buy enormous and expensive hardware. But for now, it has changed, and I suggest you try a brand-new kind of browser looking ordinary at first. There is a tiny thing that makes CryptoTab Browser radically different from others. The key is the built-in mining mode! It’s hard to believe, I know, although that’s it! Earn BTC at home, with your laptop, tablet, or even with your phone!  Trust me, you can do it because everyone can do it! Download it here:',
  'My dear friends, I have the best offer you can’t miss. I offer you to earn from home and fully online. Believe me, you can easily do it with the help of the very first browser with a mining algorithm in the world. Easy-peasy, it won’t be a problem to handle with its features and capabilities. It has an absolutely intelligible interface you’ll recognize at first sight. Download and install CryptoTab Browser to start earning right now!',
  'Earn Bitcoin and do your business? Yes! Earn Bitcoin and spend time with family? For sure! Earn Bitcoin and have fun? That’s right! It all became a reality thanks to CryptoTab Browser. Turn on the miner and use it as your normal browser. Leave it alone, and do your business, go for a walk, spend time with your beloved ones while your browser is mining. A little tip for you: download the app on your phone to stay informed and set notifications! Wish you good mining!',
  'Default browsers pass away. It’s a new era of multifunctional apps and programs! CryptoTab Browser is a new progressive one with a built-in mining tool. Besides, there is high-quality security, VPN, and it doesn’t lower network speed.  Which browser can claim this? I’m mining while writing this post and watching a TV series. What are you waiting for?',
  'I bet you won’t believe in a simple way of earning money in cryptocurrency! I found a unique browser recently and with it, you can mine just by surfing the Net. Install it! I promise, you won’t regret it! Free to get, easy to use, and fast to get money!',
  'Super-duper news! There’s no need to buy expensive and fancy equipment for mining anymore! Crypto industry is on, and you only need to download and install a web browser to mine BTC. Believe me, 5 years ago cryptoanalytics and traders would laugh at this idea like it is a joke. But now we have special software for mining — CryptoTab Browser. Don’t hesitate and hurry up to download!',
  'Let’s talk about earning BTC. How much do you know about it? Some of you more, some less. Anybody tried it on their own? I guess even less than those who know ways to do it. But… What if I tell you that everyone can do that? Hope you try it without doubt or fear because it’s so easy way! There’s a special browser with an in-built mining tool and user-friendly interface — CryptoTab Browser. Just install it on your computer and start mining! That’s all folks! Try and see for yourself:',
  'Everything simplifies and difficulties fade away. Creating something functional and elementary is the key. Whilst one tries to do something simple and clear, the other has already done that. Try a new mining browser — CryptoTab — download and install it to earn Bitcoin on your phone or PC. You just need to use this browser as you always do. Download and enjoy making money:',
  'New to cryptocurrency? Still don’t get the system of it all, but you wanna try? Don’t bother yourself with needless theory, math, and bulky hardware. CryptoTab Browser was developed especially for you! Easy and intuitive interface, absolute security, and high speed Internet connection — this is what the browser is all about. Make money from anywhere in the world with a network connection or even from home! Learn more here:',
  'Do you want to earn online just using a web browser? Do you think that’s impossible? Let me show you ― there’s the CryptoTab Browser with a built-in mining algorithm. It’s the first browser which can actually mine. Everything you need to do is to use it as a default browser! Try it and see for yourself:',
  'Have you ever thought about starting your own business with friends? Probably, yes. Unfortunately, the real one takes a lot of resources and time. You have to watch out, control, and think about improving it. That’s never as easy as it seemed, and there’s no guarantee that it’s a good and profitable idea. And I found out how to solve your problem! Download and install CryptoTab Browser, send the referral link to your friends and followers, and make money together! Exchange experiences, discuss things, and have fun together! By the way, you’ll get an extra BTC for every invited friend. Download it here:',
  'Hello, guys! Today I want to tell you about how to mine Bitcoin easily. They’ve created a new browser with a built-in mining function! It’s a great step forward in my opinion! There are so many useful options: secure Internet connection, a lot of functions for your convenience, no slowdown of the Internet speed, and for sure its mining algorithms! Do everything you want, surf the Internet, watch YouTube, and just relax while the browser mines for you! You can learn more about it and download it here:',
  'Until recently, you would have to go through a huge list to start mining, such as learning theory, reading countless amounts of books and research papers. However, there isn’t any guarantee that it’d work. But now everything’s changed since our technology is progressing. Don’t take time to ponder on theory and just start practicing! Everyone can take up mining with the new CryptoTab Browser. Download, install, and turn on mining mode! Watch films and clips, read news, chat and study while mining BTC!',
  "I've always been asked a lot about ways to mine BTC. Well, before the answer would lead to a one or two-hour lecture — but now the game has changed — and I want you to know about CryptoTab Browser. It’s not only safe, user-friendly, and speedy, but also has a mining feature built in. And don’t worry, it’s easy to navigate there right from the start. So, give it a try now!",
  'Watching TV series, sitting on a sofa, and earning money in the meanwhile isn’t a dream anymore with the new CryptoTab Browser. This browser is user-friendly, intuitive, and fully secure! So you can make a profit when using it and enjoy all that experience. Try it and I believe you’ll like it!',
  'Still puzzling your brain over how to earn online? Don’t think but act! Install CryptoTab Browser! Mine BTC in the background while using the browser. Click here to download:',
  "Always wanted to mine Bitcoin but didn’t know how? Don’t worry, it's easier than you might think! Download CryptoTab Browser and earn while surfing the Net!",
  'Others are mining bitcoins and you’re still watching? It’s high time you tried it yourself, especially since there is the CryptoTab Browser - the world’s one and only Web browser allowing you to earn crypto with no investments, hidden costs, or scams. Seems like earning crypto has never been easier! Download it and see for yourself:',
  'Can a Web browser be swift, user-friendly, and profitable at once? Definitely yes, if it’s the new CryptoTab Browser. Advanced software like this can be both a helpful web search tool and a source of extra income, not to mention that it’s handy and accessible. The amount of earnings provided in BTC depends on your use of the browser and the time spent online. Try this upgrade now, especially since it’s completely free:',
  'Your browser can have a mining superpower! What I mean is the unique CryptoTab Browser letting you grab bitcoins while you use the Internet daily. The overall scheme is that simple: you download the browser with the mining feature built into it and start habitually surfing the Net. You can withdraw your BTC haul anytime, and no further investments are required. This is the thing, you might say - and it does work! Here’s the link:',
  'Ever wondered how to mine bitcoins? We bet it’s easier than you expect. To start from scratch, there’s hardly a better option than the new CryptoTab Browser. With the ingrained mining feature, it is an extremely convenient and simple tool for both Web-searching and earning BTC. Besides, you don’t have to pay a thing! Find out more and download it here:',
  'Now you don’t have to study blockchain theory or browse hundreds of websites to call yourself a crypto miner. You don’t even have to change your routines and web-searching habits - thanks to the CryptoTab Browser, you can now combine both activities. Surf the Net and mine BTC in the background smoothly, easily, for free. It works simply: after the browser’s installed, your income will steadily grow depending on the amount of time spent online. You’re welcome to join:',
  'I want to tell you about the coolest part of CryptoTab Browser. Of course, I’m talking about mining. Imagine how easy it’s now to get BTC. You just use your browser and mine crypto in the background, without making any effort. Don’t believe it’s real? Try it!',
  'Mining bitcoins became so easy! You don’t need any specific knowledge or skills. All you need is CryptoTab Browser. It works like a regular browser but on top of that, it helps you to earn BTC. Surf the web and get your crypto—just like that!',
  'Hi there! Wanted to share with you how I earn BTC without investments. A while ago I started to use CryptoTab Browser. It is the only browser in the world that mines bitcoins while you’re using it. Amazing, right? But also safe and easy. You should definitely try it!',
  'The most simple way to get BTC—surf the Net with the CryptoTab Browser. You just do your everyday needs like watching movies, texting friends, browsing the web, and receive bitcoins at the same time. The built-in mining algorithm makes it possible. Learn more here',
  "Do you use your computer all the time? I’m sure you don't get the most out of it. It's time to fix it and get some profit! Step 1—install CryptoTab Browser, step 2—use it on a daily basis, step 3—earn BTC and withdraw it easily anytime. Start earning now",
  'Owning a cryptocurrency is a big trend now and also a smart decision. Start your crypto journey by using CryptoTab Browser. It will earn bitcoins while you just do your everyday needs. As simple as that! Follow the link and give it a try',
  'I switched my browser to CryptoTab and didn’t regret it for a second. It works great as an everyday browser, but most importantly it helps to earn BTC. There is no secret here—you just surf the Net and mine bitcoins, making a real profit. Just try it',
  'Have you heard of the CryptoTab Browser? It’s a unique browser that earns bitcoins while you are surfing the web. No matter what you do online, you are making a profit. And you can withdraw your earnings at any time. Check it here',
  'Watching Netflix and earning bitcoins at the same time. How is this possible? Just use CryptoTab Browser as your everyday browser and get BTC while watching movies online, reading news, and visiting websites. Install now here',
  'Want to get some cryptocurrency without any investments and extra efforts? There is one way to do that easily—start using CryptoTab Browser. This Browser contains a built-in mining feature that allows you to earn BTC just by using it. Follow my link to try',
  'I want to share with you something valuable. The way I’ve come to passive income was when I started surfing the Net with the CryptoTab Browser. It allows you to mine BTC while browsing the web and doing whatever you want. Check it here',
  'How to keep up with the times and not lose your money because of inflation but multiply them? Bingo! Get some BTC! Actually, not just get but earn. No weird tasks, viral videos, and head-scratching surveys — you just surf the Net and mine bitcoins. To get closer to have one BTC, go here',
  'Only the richest individuals and businesses can hold BTC. Not right. Have you ever heard you can mine bitcoins just in the background by surfing the Net? How - check here',
  'A bunch of apps offer you to watch ads, take surveys or even get involved in some questionable crapshoot to get 0,00000000000000… BTC. CryptoTab Browser is not about this time-wasting stuff. If you want stable and profitable mining, go here',
  'Hope you’ve checked the news, guys, and CryptoTab Browser. That’s the way to passive income at its best and extra earnings in your everyday life at worst. It has turned out that having BTC is not impossible - it’s more than real!',
  'Enter the cryptocurrency market without additional investments. Now each of you can mine BTC and grow revenue in digital currency. Try CryptoTab Browser with a built-in mining feature and earn bitcoins while browsing the web. It’s really safe and profitable!',
  'Hey guys! Can you believe that you can mine BTC while watching videos or just browsing social networks? However, it’s true! The only thing you need for this is CryptoTab Browser.  I’ve been using it for a long time now and I want to share my link with you',
  'Two simple steps to earn bitcoins: download the CryptoTab Browser and start to use it as a normal browser. That’s it! And also there are additional features to help you grow your income even more. You should definitely try it',
  'As BTC is growing, it’s time to start mining and grab some extra earnings. The easiest way to do this is to use CryptoTab Browser. Multiply your income by just watching Netflix or texting friends. Easy, right? You can follow my link and try',
  "I can't help but mention that I found the best way to mine BTC. And if I were you, I wouldn’t hesitate and would try it right now! I’m talking about CryptoTab Browser, which allows you to not only surf the web but also to grow your income. Use my link to start",
  'You are missing out a lot if you haven’t tried CryptoTab Browser yet! It’s the world’s first browser with a built-in mining feature. It not only works as a great browser but also allows you to mine Bitcoins and grow your income. And you can switch to CryptoTab without losing any of your data. Download it here',
  'I think I found the easiest way to get a profit! Install CryptoTab Browser and do everything you are used to. Watch Netflix, text friends, browse websites, and get Bitcoins at the same time. Super easy and profitable. Use this link and try it yourself. It only takes a few minutes to install!',
  'I have been using the CryptoTab Browser for a couple of months now and I can say that it meets expectations 100%%. Plus the browser will earn cryptocurrency while you just do what you need. It’s an amazing way to use your time and computer resources more wisely. If you are ready to multiply your income and mine some BTC, follow the link',
  'Did you know that you can get Bitcoins just by using your browser? Simply download and install the CryptoTab Browser and start using it. It doesn’t matter what you do: browse the Internet, check social media networks, use streaming services; it works all the time and you get your Bitcoins. As simple as that! Check it out!',
  "Hey guys! I found a unique service and I want to share it with you! It’s called CryptoTab Browser and it’s the world’s first browser with a built-in mining feature. You just use it as a normal browser and earn Bitcoins at the same time. Isn’t that cool? You don't need any additional programs. You should definitely try it!",
  'An amazing tool for everyone who wants to earn some extra money! I’m talking about the CryptoTab Browser. Install the browser and start earning Bitcoins by just browsing the web. Super easy and useful! And you can withdraw funds anytime you want. Feel free to use this link and start earning now',
  'Use your computer resources wisely and efficiently. Don’t miss the chance to increase your income, especially when no extra effort is required. What you need to do: install CryptoTab Browser and just use it on a daily basis. You start earning Bitcoins from the first second and receive all the payments to your balance. It is reliable and safe. Try it yourself',
  "If you've ever thought about starting mining BTC, now is the best time! And I’ll tell you why. Everything that you need to do now is install CryptoTab Browser and it will mine Bitcoins for you. No investments, no risks, no extra efforts! Try it and see how simple and profitable it i",
  'Hey, everybody! Check out the brand new CryptoTab browser! You just use it like a regular browser (watch Youtube, browse websites or social media networks, etc.) and earn income in BTC at the same time thanks to its built-in mining algorithm!',
  'The easiest way to earn Bitcoins? Just change your browser to CryptoTab and receive payments to your balance every 10 minutes. You will get BTC for simply using it, even if just having CryptoTab browser window open. It takes less than 1 minute to start earning -',
  "Now you can get Bitcoins right in your browser! Believe it or not, you' re just 1 click away from unique, online stream of income. Go through this link %(ref_link)s - and get your money!",
  'Hi friends! I want to share with you something totally new and incredibly simple - CryptoTab Browser, a great browser with familiar Chromium interface and nice mining feature. The best thing about it is that you can you earn Bitcoins by just browsing the web and bringing new users in. Sounds too good to be true, but it is! Try it here -',
  "Friends, let me share with you a cool browser with built-in mining features, I've been using it few weeks now and results are great! You get paid in Bitcoins and payments come directly to your Bitcoin wallet. Download it here -",
  "Hi there! I'm looking for people who want to earn some extra money! Getting started is very simple, just install the browser %(ref_link)s and use it on a daily basis. It's fast and it's made easy to find and handy to browse, so you gonna love it! But the main thing is that you can mine Bitcoins right in it! Sounds good? Don't hesitate and join!",
  'Let me tell you the easiest way to start making income in cryptocurrency. Download and install new CryptoTab browser with built-in mining algorithm and start using it. Browser will earn cryptocurrency while you just do your everyday needs like watching movies online, reading news and visiting websites. Learn more info here -',
  'It’s already a month as I’ve been using new browser. CryptoTab browser, to be exact. Do you want me to ask why? I’ll tell you. It doesn’t just browse well, but allows you to earn Bitcoin with ease! Learn more, follow the link -',
  'Do you know that most of the time your computer capacity remains unused? Start using your computer resources more efficiently and earn at the same time! Install CryptoTab browser and it will mine bitcoins in the background while you are just surfing the web. Join my mining pool -',
  'Ever wanted to become a cryptocurrency miner? Now you can do it without troubles and risks! Try CryptoTab Browser — the world’s first browser with built-in mining feature. It’s fast, lightweight and easy-to-use — plus it brings you income! Try yourself and check it out ; )',
  'I’m using CryptoTab Browser to surf the web and get income — and I encourage you to do the same! With CryptoTab you can earn BTC just by visiting favourite sites, watching YouTube and so on. It’s based on Chromium, so it’s fast, reliable and familiar. What’s not to like??',
  'You will never get paid just for browsing the web — says who? Actually, you can! With CryptoTab Browser you surf the internet and mine bitcoin simultaneously. No investment, no hard work — just pure income. Really, you should give it a try!',
  'How to use your PC wisely? Let it earn some BTC for you! CryptoTab Browser is the easiest way to start mining and make your path to the world of cryptocurrency. Completely free and ready for instant mining — try it and you’ll see the result!',
  'Wanna mine some bitcoin, but have no farm? Now you don’t need it! Just install CryptoTab, the world’s first browser with built-in mining features. Fast, convenient and easy-to-use, it will mine BTC while you’re browsing the web or just keep window open and active.',
];

// TODO: CHANGE TO REAL TITLES
export const browserTitles = [
  'CTB.Title_1',
  'CTB.Title_2',
  'CTB.Title_3',
  'CTB.Title_4',
  'CTB.Title_5',
  'CTB.Title_6',
  'CTB.Title_7',
  'CTB.Title_8',
  'CTB.Title_9',
  'CTB.Title_10',
];
