import styles from './Footer.module.scss';
import { HTMLAttributeAnchorTarget, PropsWithChildren, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Socials from '@shared/Socials/Socials';
import { ChevronRightSvg } from '@assets/colorless';
import { externalLinks } from '@utils/externalLinks';
import cls from 'classnames';
import { CtSmLogoSvg } from '@assets/index';
import { SUPPORT, BROWSER_FEEDBACK } from '@utils/links';
import { build, version } from '../../../constants/version';
import { Link } from 'react-router-dom';
export default function Footer() {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.cell}>
        <Links inner text={t('FAQ')} target="_self" url={BROWSER_FEEDBACK} />
        {/* <Links inner text={t('Support')} target="_self" url={SUPPORT} /> */}
        <Links text={t('Terms of Use')} url={externalLinks.terms} />
      </div>
      <div className={styles.cell}>
        <Socials />
      </div>
      <div className={styles.cell}>
        <div className={styles.logo}>
          <CtSmLogoSvg width={20} height={20} />
          <span>{t('CryptoTab Family')}</span>
        </div>
        <Links
          text={
            <>
              CryptoTab <b>Browser</b>
            </>
          }
          url={externalLinks.ctb}
        />
        <Links
          text={
            <>
              CryptoTab <b>for Android</b>
            </>
          }
          bonus={{
            text: 'lite',
            variant: 'blue',
          }}
          url={externalLinks.lite}
        />
        <Links
          text={
            <>
              CryptoTab <b>for Android</b>
            </>
          }
          bonus={{
            text: 'pro',
            variant: 'orange',
          }}
          url={externalLinks.pro}
        />
        <Links
          text={
            <>
              CryptoTab <b>for Android</b>
            </>
          }
          bonus={{
            text: 'max',
            variant: 'purple',
          }}
          url={externalLinks.max}
        />
        <Links
          text={
            <>
              CryptoTab <b>Farm</b>
            </>
          }
          url={externalLinks.farm}
        />
        <Links
          text={<b>CT STORE</b>}
          bonus={{
            text: 'new',
            variant: 'red',
          }}
          url={externalLinks.store}
        />
        <Links text={<b>CT NFT</b>} url={externalLinks.nft} />
        <Links text={<b>CT VPN</b>} url={externalLinks.vpn} />
        <Links text={<b>CT TAGS</b>} url={externalLinks.tags} />
        <Links text={<b>CB.click</b>} url={externalLinks.click} />
        <Links
          text={
            <>
              CryptoTab <b>Start</b>
            </>
          }
          url={externalLinks.start}
        />
      </div>
      <div className={styles.cell}>
        <div className={styles.version}>
          {' '}
          Build: <span>{version + '.' + build}</span>
        </div>
      </div>
    </div>
  );
}

type LinksProps = {
  text: string | ReactNode;
  inner?: boolean;
  url?: string;
  onClick?: () => void;
  target?: HTMLAttributeAnchorTarget;
  bonus?: {
    text: string;
    variant: 'blue' | 'orange' | 'purple' | 'red';
  };
};

const Links = (props: LinksProps) => {
  const { text, bonus } = props;
  return (
    <LinkContainer {...props}>
      <ChevronRightSvg />
      <span>{text}</span>
      {bonus && <span className={cls([styles.bonus, styles[`bonus__${bonus.variant}`]])}>{bonus.text}</span>}
    </LinkContainer>
  );
};

const LinkContainer = (props: PropsWithChildren<LinksProps>) => {
  const { inner, url, onClick, target, children } = props;
  if (!inner) {
    return (
      <a
        href={url}
        onClick={(e) => {
          if (onClick) {
            e.preventDefault();
            onClick();
          }
        }}
        target={target || '_blank'}
        rel="noreferrer"
        className={styles.url}
      >
        {children}
      </a>
    );
  }

  return (
    <Link
      to={url}
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          onClick();
        }
      }}
      target={target || '_blank'}
      rel="noreferrer"
      className={styles.url}
    >
      {children}
    </Link>
  );
};
