import { useTranslation } from 'react-i18next';
import styles from './MinedView.module.scss';
import { ReactComponent as Question } from '@assets/colored/question.svg';
import cls from 'classnames';
import { useBreakpoints } from '@hooks/useBreakpoints';

export type MinedLevelsProps = {
  id?: string;
  total: MinedLevelsItem;
  data: MindedLevels;
};
export type MinedLevelsItem = {
  ref: number;
  mined: number;
};
export type MindedLevels = MinedLevelsItem[];
export default function MinedLevels({ id, total, data }: MinedLevelsProps) {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();
  return (
    <div className={styles.levels__wrapper}>
      <div className={`${styles.levels__row} ${styles.levels__row_first}`}>
        <span>{t('Referrals by Level')}</span>
        <span>{t('Referrals')}</span>
        <span>
          {t('Active Miners')}
          <Question />
          <div className={styles.levels__tooltip}>{t('Referrals that have been mining in past 24 hours')}</div>
        </span>
      </div>
      {data?.map(
        (item, index: number) =>
          (item.mined !== 0 || item.ref !== 0 || ((item.mined === 0 || item.ref === 0) && index < 3)) && (
            <div className={styles.levels__row} key={index}>
              <span className={styles.orange}>
                {!isMobile && t('Level')} {index + 1}
              </span>
              <span className={styles.big}>{item.ref}</span>
              <span className={styles.big}>{item.mined}</span>
            </div>
          )
      )}
      <div className={cls([styles.levels__row, styles.levels__row_total])}>
        <span>{t('Total')}</span>
        <span className={styles.big}>{total?.ref || 0}</span>
        <span className={styles.big}>{total?.mined || 0}</span>
      </div>
    </div>
  );
}
