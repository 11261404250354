import React, { FC, HTMLAttributes } from 'react';
import styles from './StoreButton.module.scss';
import cls from 'classnames';
import { ReactComponent as AppStoreSmallSvg } from './icons/app-store-small.svg';
import { ReactComponent as PlayMarketSmallSvg } from './icons/play-market-small.svg';
import { ReactComponent as AppStoreSvg } from './icons/app-store.svg';
import { ReactComponent as PlayMarketSvg } from './icons/play-market.svg';
import { ReactComponent as WebSvg } from './icons/web.svg';

export enum StoreButtonKind {
  PlayMarket,
  AppStore,
  PlayMarketFull,
  AppStoreFull,
  Web,
}

export type StoreButtonProps = HTMLAttributes<HTMLElement> & {
  kind: StoreButtonKind;
  link?: string;
  label?: string;
  as?: 'a' | 'button';
  target?: '_blank';
  text?: string;
  disabled?: boolean;
};

export const StoreButton: FC<StoreButtonProps> = (props) => {
  const { kind, link, label, as = 'a', target = '_blank', disabled = false, text, ...rest } = props;
  const classnames = cls({
    [styles.storeBtn]: true,
    [styles.storeBtn__disabled]: disabled,
    'android-btn': kind === StoreButtonKind.PlayMarket,
    'apple-btn': kind === StoreButtonKind.AppStore,
  });

  if (as === 'a') {
    return (
      <a href={link} target={target} className={classnames} {...rest}>
        {kind === StoreButtonKind.PlayMarket && <PlayMarketSmallSvg />}
        {kind === StoreButtonKind.AppStore && <AppStoreSmallSvg />}
        {kind === StoreButtonKind.PlayMarketFull && <PlayMarketSvg />}
        {kind === StoreButtonKind.AppStoreFull && <AppStoreSvg />}
        {kind === StoreButtonKind.Web && <WebSvg />}
        {label && <span className={styles.storeBtn__label}>{label}</span>}
        {text && <span className={styles.storeBtn__text}>{text}</span>}
      </a>
    );
  }

  return (
    <button className={classnames} {...rest}>
      {kind === StoreButtonKind.PlayMarket && <PlayMarketSmallSvg />}
      {kind === StoreButtonKind.AppStore && <AppStoreSmallSvg />}
      {kind === StoreButtonKind.PlayMarketFull && <PlayMarketSvg />}
      {kind === StoreButtonKind.AppStoreFull && <AppStoreSvg />}
      {kind === StoreButtonKind.Web && <WebSvg />}
      {label && <span className={styles.storeBtn__label}>{label}</span>}
      {text && <span className={styles.storeBtn__text}>{text}</span>}
    </button>
  );
};
