import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as Coin } from '@assets/coin.svg';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { IconWalletSvg, LockSvg, QuestionSvg } from '@assets/colorless';
import { convertBigValueToLocale } from '@utils/convertBigValueToLocale';
import { jsColors } from '@utils/jsColors';
import checkE from '@utils/checkE';
import WithdrawalAddresses from '@pages/Withdrawal/view/WithdrawalAddresses';
import { Root } from '@service/reducers';
import { WithdrawAddresses } from '@service/reducers/withdrawalsReducer';
import { nextPurchaseType } from '@service/reducers/balanceReducer';
import { NcwIcon } from '@assets/img';
import { HshSvg } from '@assets/colored';
import { WithdrawalError } from '@pages/Withdrawal/view/WithdrawalError';
import { WithdrawalModalState } from './WithdrawalView';
import styles from './WithdrawalView.module.scss';

export type WithdrawalViewWithMoneyProps = {
  address: WithdrawAddresses | null | undefined;
  setAddress: (val: WithdrawAddresses | string, skip?: boolean) => void;
  isLockAddress: boolean;
  isBitcoin: boolean;
  amount: string;
  isNcw: boolean;
  setAmount: (val: string) => void;
  error?: WithdrawError;
  setError: (val?: WithdrawError) => void;
  balance: string;
  balanceHSH: string;
  min: number;
  pendingBalance: number;
  accruedBalance: number;
  nextPaymentHSH: nextPurchaseType;
  modalState: WithdrawalModalState;
  openChangeModal: () => void;
  isLoading: boolean;
};

export type WithdrawError = {
  type: 'address' | 'amount' | 'captcha';
  text: string;
};

export function WithdrawalViewWithMoney({
  address,
  isLockAddress,
  setAddress,
  isBitcoin,
  amount,
  isNcw,
  setAmount,
  error,
  balance,
  balanceHSH,
  min,
  pendingBalance,
  accruedBalance,
  nextPaymentHSH,
  modalState,
  openChangeModal,
  isLoading,
  setError,
}: WithdrawalViewWithMoneyProps) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const addressInput = useRef<HTMLInputElement>(null);
  const { withdrawals } = useSelector((store: Root) => store);
  const { isMobile } = useBreakpoints();

  useEffect(() => {
    if (modalState === WithdrawalModalState.Change) {
      setShow(false);
    }
  }, [modalState]);

  useEffect(() => {
    setAmount('');
    setError(undefined);
  }, [isBitcoin, setAmount, setError]);

  const handleAddressClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>, item: WithdrawAddresses) => {
      e.stopPropagation();
      setAddress(item);
      setShow(false);
    },
    [setAddress]
  );

  const openAddressList = useCallback(() => {
    !show && openChangeModal();
    setShow((prev) => !prev);
  }, [openChangeModal, show]);

  const onAmountChange = useCallback(
    (val: string) => {
      setError(undefined);

      const isNotNumber = /(^[^0-9])|([^0-9.])/g.test(val);
      const countDot = val.match(/\./g);

      if (isNotNumber || (countDot?.length || 0) > 1) {
        return;
      }

      const [beforeDot, afterDot] = val.split('.');
      const isNotValidNumber = /^0[0-9]/gi.test(beforeDot);

      if (!isNotValidNumber && (!afterDot || (isBitcoin ? afterDot.length <= 8 : afterDot.length <= 4))) {
        setAmount(val);
      }
    },
    [t, setAmount, min, setError, isBitcoin]
  );

  return (
    <>
      <div className={cn(styles.group, styles.group_mob)}>
        <label htmlFor="amount">
          <div className={styles.icon_mob}>
            {isBitcoin ? <Coin className={styles.label__logo_mob} /> : <HshSvg className={styles.label__logo_mob} />}
          </div>
          <span>{t('Payment amount')}</span>
        </label>
        <div className={cn(styles.group__container, error?.type === 'amount' && styles.input_error)}>
          <div className={styles.icon}>
            {isBitcoin ? <Coin className={styles.label__logo} /> : <HshSvg className={styles.label__logo} />}
          </div>
          <input
            type="text"
            placeholder={isBitcoin ? (0).toFixed(8) + ' BTC' : (0).toFixed(4) + ' HSH'}
            id="amount"
            className={cn(styles.input_count, error?.type === 'amount' && styles[`input_count--error`])}
            value={amount}
            onChange={(e) => onAmountChange(e.target.value)}
          />
        </div>
        <div className={styles.group__footer}>
          <span className={styles.group__min}>
            {isBitcoin && (
              <span className={styles.group__min_btc} onClick={() => setAmount(checkE(min))}>
                {t('min')} {checkE(min)} BTC
              </span>
            )}
            {!isBitcoin && +pendingBalance > 0 && (
              <>
                <div className={styles.question}>
                  <LockSvg className={styles.question__lock} />
                  <span className={styles.question__text}>
                    <b>
                      {isMobile
                        ? `${convertBigValueToLocale(pendingBalance)} HSH`
                        : t('HSH.Withdr_scr.Pending_info', { HSH: convertBigValueToLocale(pendingBalance) })}
                    </b>
                  </span>
                  <div className={styles.question__container}>
                    <QuestionSvg className={styles.question__logo} />
                    <span className={styles.question__prompt}>
                      <span>
                        <Trans
                          i18nKey="HSH.Interf.Info.Balance"
                          components={{ b: <b /> }}
                          values={{ HSH: convertBigValueToLocale(balanceHSH) }}
                        />
                      </span>
                      <span>
                        <Trans
                          i18nKey="HSH.Interf.Info.Pending"
                          components={{ b: <b /> }}
                          values={{ HSH: convertBigValueToLocale(pendingBalance) }}
                        />
                      </span>
                      {pendingBalance > 0 && (
                        <span className={styles.question__prompt_next}>
                          <b>{t('Next_confirm_in')} </b>
                          {(nextPaymentHSH?.days || 0) > 0 && t('Next_conf.Days', { days: nextPaymentHSH?.days })}{' '}
                          {(nextPaymentHSH?.hours || 0) > 0 && t('Next_conf.Hours', { hours: nextPaymentHSH?.hours })}{' '}
                          {(nextPaymentHSH?.mins || 0) > 0 && t('Next_conf.Min', { minutes: nextPaymentHSH?.mins })}
                        </span>
                      )}
                      <span>
                        {' '}
                        <Trans
                          i18nKey="HSH.Interf.Info.Total"
                          components={{ b: <b /> }}
                          values={{ HSH: convertBigValueToLocale(accruedBalance) }}
                        />
                      </span>
                    </span>
                  </div>
                </div>
              </>
            )}
          </span>
          <div
            className={styles.group__max}
            onClick={() => setAmount(isBitcoin ? balance : '' + (+balanceHSH - +pendingBalance) + '')}
          >
            {t('max-tag') + ' '}
            <b>{isBitcoin ? balance + ' BTC' : convertBigValueToLocale(+balanceHSH - +pendingBalance) + ' HSH'}</b>
          </div>
        </div>
        {error?.type === 'amount' && <WithdrawalError text={error?.text} align={isBitcoin ? 'left' : 'right'} />}
        {/* {!isNcw && isBitcoin && hasRequested && (
          <div className={cn(styles.ncw__subtitle, styles.ncw__subtitle_red)}>
            <InfoSvg color={jsColors.white} />
            <p>
              <Trans
                i18nKey={'BTC_Overload.Short_1'}
                values={{
                  fee: (min / 3).toFixed(8),
                  min_fee: min,
                }}
              />
            </p>
          </div>
        )} */}
      </div>
      <div className={cn(styles.group, styles.group_last)}>
        <label htmlFor="address">
          <Icon isNcw={isNcw || !isBitcoin} isMob={true} className={styles.icon_mob} />
          <span>{!isNcw ? t('Bitcoin Address') : t('NCW_ID.Title')}</span>
        </label>
        <div className={cn(styles.group__container, error?.type === 'address' && styles.input_error)}>
          <Icon isNcw={isNcw || !isBitcoin} className={styles.icon} />
          <input
            type="text"
            placeholder={isBitcoin ? t('Add New Address') : t('NCW_ID.Title')}
            disabled={isLoading || isLockAddress}
            id="address"
            value={address?.address || ''}
            ref={addressInput}
            onChange={(e) => setAddress(e.target.value)}
          />
          <WithdrawalAddresses
            show={show}
            setShow={setShow}
            openAddressList={openAddressList}
            withdrawals={withdrawals}
            handleAddressClick={handleAddressClick}
            isBitcoin={isBitcoin}
          />
        </div>
        {error?.type === 'address' && <WithdrawalError text={error?.text} />}
      </div>
    </>
  );
}

const Icon = ({ isNcw, isMob, className }: { isNcw: boolean; isMob?: boolean; className?: string }) => (
  <div className={className}>
    {isNcw ? (
      <NcwIcon className={styles[`label__logo${isMob ? '_mob' : ''}`]} />
    ) : (
      <IconWalletSvg color={jsColors.orange} className={styles[`label__logo${isMob ? '_mob' : ''}`]} />
    )}
  </div>
);
