import { WithdrawalPaymentService } from '../backend/JsonRpcApi';

export const getTransactionLink = (type: WithdrawalPaymentService, id: string) => {
  switch (type) {
    case 'bitcoin': {
      return `https://blockchain.info/tx/${id}`;
    }
    case 'ncw': {
      return `https://ncwallet.page.link/?link=https%3A%2F%2Fapp.ncwallet.net&apn=com.ncwallet&amv=0&ibi=com.ncwallet&imv=0&isi=1615381976`;
    }
  }
};
