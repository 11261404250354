import React from 'react';
import { BannerAddLinks, BrowserLinks, Container } from '@shared/index';
import styles from './BrowserLinksView.module.scss';

type BrowserLinksViewProps = {
  onLinkCreate: () => void;
};

export const BrowserLinksView = ({ onLinkCreate }: BrowserLinksViewProps) => {
  return (
    <Container vertical={20} className={styles.container}>
      <BrowserLinks />
      <BannerAddLinks onLinkCreate={onLinkCreate} />
    </Container>
  );
};
