import styles from './BrowserAboutCalc.module.scss';
import { Calc } from './Calc/Calc';
import { CalcModal } from './CalcModal/CalcModal';
import { Card } from '@shared/index';
import { useModal } from '@shared/Modal/useModal';
import { Modal } from '@shared/Modal/Modal';
export const BrowserCalc = () => {
  const { opened, onOpen, onClose } = useModal();

  return (
    <Card className={styles.card}>
      <div>
        <Calc onModalCall={onOpen} />
        <Modal onClose={onClose} opened={opened}>
          <CalcModal onClose={onClose} />
        </Modal>
      </div>
    </Card>
  );
};
