export const BLACK_FRIDAY_INFO_URL = 'https://cryptobrowser.site/promo/blackfriday-2024/';
export const BLACK_FRIDAY_END_DATE = '2024-12-15T23:59:59';

export const BLACK_FRIDAY_BANNER_ID = 'BlackFridayBannerClosedAt';

export const getIsBlackFriday = (): boolean => {
  const isBlackFridayClosedAt = localStorage.getItem(BLACK_FRIDAY_BANNER_ID);

  if (isBlackFridayClosedAt) return false;

  const currentDate = new Date();
  const blackFridayDate = new Date(BLACK_FRIDAY_END_DATE);
  const isBlackFriday = currentDate < blackFridayDate;

  if (!isBlackFriday) {
    console.warn('Black Friday is over, please remove the Black Friday banner', 'color: red; font-size: 20px;');
  }

  return isBlackFriday;
};
