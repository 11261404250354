import { ReactNode } from 'react';
import styles from './Simple.module.scss';
import Switch from '@shared/Switch/Switch';

export type SimpleSwitchProps = {
  title: string;
  Icon: ReactNode;
  isActive: boolean;
  onToggle: () => void;
};

export default function SimpleSwitch(props: SimpleSwitchProps) {
  const { Icon, onToggle, isActive, title } = props;
  return (
    <div className={styles.container} onClick={onToggle}>
      {Icon}
      <span className={styles.title}>{title}</span>
      <Switch className={styles.switch} isActive={isActive} onToggle={onToggle} />
    </div>
  );
}
