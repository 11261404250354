import { HelmetHead } from '@pages/headersComponents/HelmetHead';
import { product } from '@utils/productName';
import PoolSubheader from '@pages/headersComponents/PoolSubheader';
import PoolPromoView from '@pages/PoolPromo/view/PoolPromoView';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Root } from '@service/reducers';
import { useEffect, useMemo } from 'react';
import getBalances from '@service/actions/getBalances';
import { getPoolLandings } from '@service/actions/getPoolLandings';

export default function PoolPromo() {
  const { t } = useTranslation();
  const store = useSelector((store: Root) => store);
  const dispatch = useDispatch();
  useEffect(() => {
    !store.balance.get_balance &&
      !store.balance.balance_request &&
      !store.balance.balance_error &&
      dispatch(getBalances());
    !store.poolLandings.poolLandsGet && dispatch(getPoolLandings());
  }, [dispatch]);

  const list = useMemo(
    () => store.balance.links?.filter((item) => item.type === '' || item.type === null).map((item) => '' + item.id),
    [store.balance.links]
  );

  return (
    <>
      <HelmetHead title={t('Promo')} product={product.pool} />
      <PoolSubheader />
      <PoolPromoView links={store.balance.links.filter((i) => i.type === '' || i.type === null)} list={list} />
    </>
  );
}
