import styles from './GeneratePostModalFooter.module.scss';
import { Choice, Select } from '@shared/Select/Select';
import { LinkAcc } from '@service/reducers/balanceReducer';
import { BrowserPromoLand } from '@service/reducers/browserPromoReducer';
import { PromoType } from '@service/reducers/promoReducer';
import { useEffect, useMemo, useState } from 'react';
import { Button, ButtonVariant } from '@shared/Button/Button';
import { CopySvg, CheckSvg, ShareSvg, ExternalLinkSvg } from '@assets/colorless';
import { ShareDetails } from '../ShareDetails/ShareDetails';
import { useTranslation } from 'react-i18next';
import { AllProductTabs } from '@shared/CommonProduct/CommonProduct';
import { productURLsMap } from '@constants/productURLsMap';
import { PRODUCT_KIND } from '@utils/checkPathname';
import BoxWithLabel from '@shared/BoxWithLabel/BoxWithLabel';
import CopyToClipboard from 'react-copy-to-clipboard';

type BasePostModalFooterProps = {
  title: string;
  text: string;
  image: string;
  initialLink?: number;
  setCurrentLinkId?: (val: number) => void;
};

type GeneratePostModalFooterWithCodesProps = BasePostModalFooterProps & {
  entityType: AllProductTabs.Promo;
  codes: PromoType[];
};

type GeneratePostModalFooterWithLinksProps = BasePostModalFooterProps & {
  entityType: AllProductTabs.Link;
  productKind: PRODUCT_KIND;
  links: LinkAcc[];
  landings: BrowserPromoLand[];
};

type GeneratePostModalFooterProps = GeneratePostModalFooterWithCodesProps | GeneratePostModalFooterWithLinksProps;

type WithLinksChoice = Choice<number, { comment: string | null }>;
type WithLandingsChoice = Choice<number, { img: string; url: string }>;
type WithCodesChoice = Choice<string>;

export const GeneratePostModalFooter = (props: GeneratePostModalFooterProps) => {
  const { entityType } = props;
  if (entityType === AllProductTabs.Promo) {
    return (
      <GeneratePostModalFooterWithCodes
        codes={props.codes}
        title={props.title}
        text={props.text}
        image={props.image}
        entityType={entityType}
      />
    );
  }

  return (
    <GeneratePostModalFooterWithLinks
      links={props.links}
      landings={props.landings}
      title={props.title}
      text={props.text}
      image={props.image}
      entityType={entityType}
      productKind={props.productKind}
      initialLink={props.initialLink}
      setCurrentLinkId={props.setCurrentLinkId}
    />
  );
};

export const GeneratePostModalFooterWithCodes = (props: GeneratePostModalFooterWithCodesProps) => {
  const codes = useMemo(() => codesToChoice(props.codes), [props.codes]);
  const { t } = useTranslation();
  const [showShareDetails, setShowShareDetails] = useState(false);
  const [currectCode, setCurrentCode] = useState<WithCodesChoice>(codes[0]);
  const [isCopied, setIsCopied] = useState(false);

  const renderCodesChoise = (choice: WithCodesChoice) => {
    return (
      <div className={styles.item}>
        <div>{choice.label}</div>
      </div>
    );
  };

  const handleChangeCode = (choice: WithCodesChoice) => {
    setCurrentCode(choice);
  };

  const handleCopyCode = () => {
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const handleToggleShareDetails = () => {
    setShowShareDetails((prev) => !prev);
  };

  const sharedText = `${props.text}\n${t('Promo code')}: ${currectCode.label}`;
  return (
    <div className={styles.root}>
      {showShareDetails ? (
        <ShareDetails onClose={handleToggleShareDetails} title={props.title} text={sharedText} image={props.image} />
      ) : (
        <div className={styles.wrapper}>
          <Select
            listClassName={styles.choices}
            name={'codes'}
            current={currectCode}
            choices={codes}
            onChange={handleChangeCode}
            dropdownUp
            renderChoise={renderCodesChoise}
            label={t('Share_block.Code')}
          />
          <CopyToClipboard text={currectCode.value} onCopy={handleCopyCode}>
            <Button
              type="button"
              Icon={isCopied ? <CheckSvg height={16} width={16} /> : <CopySvg height={16} width={16} />}
              className={styles.button}
              disabled={isCopied}
            >
              {t('Share_block.Copy.Btn')}
            </Button>
          </CopyToClipboard>
          <Button type="button" onClick={handleToggleShareDetails} Icon={<ShareSvg />} className={styles.button}>
            {t('Share_block.Share.Btn')}
          </Button>
        </div>
      )}
    </div>
  );
};

export const GeneratePostModalFooterWithLinks = (props: GeneratePostModalFooterWithLinksProps) => {
  const { t } = useTranslation();
  const links = useMemo(() => linksToChoice(props.links), [props.links]);
  const landings = useMemo(() => landingsToChoice(props.landings), [props.landings]);

  const [currentLinkId, setCurrentLinkId] = useState<WithLinksChoice>(
    links.find((_) => _.value === props.initialLink) || links[0]
  );
  const [currentLandingId, setCurrentLandingId] = useState<WithLandingsChoice>(landings[0]);

  const [isCopied, setIsCopied] = useState(false);
  const [showShareDetails, setShowShareDetails] = useState(false);
  const renderLinksChoise = (choice: WithLinksChoice) => {
    return (
      <div className={styles.item}>
        <div>{choice.label}</div>
        {choice.meta && <div className={styles.comment}>{choice.meta.comment}</div>}
      </div>
    );
  };

  const renderLandingsChoise = (choice: WithLandingsChoice) => {
    return (
      <div className={styles.item}>
        <div>
          <img className={styles.img} src={choice.meta?.img} alt={choice.label} />
        </div>
        <div className={styles.content}>
          <div>{choice.label}</div>
          <div className={styles.linkWrapper}>
            <ExternalLinkSvg className={styles.link} width={16} height={16} />
            <a className={styles.link} href={choice.meta?.url}>
              Open URL link
            </a>
          </div>
        </div>
      </div>
    );
  };

  const handleCopy = () => {
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const handleToggleShareDetails = () => {
    setShowShareDetails((prev) => !prev);
  };

  const handleChangeLinkId = (choice: WithLinksChoice) => {
    setCurrentLinkId(choice);
    props.setCurrentLinkId && props.setCurrentLinkId(choice.value);
  };

  const handleChangeLandingId = (choice: WithLandingsChoice) => {
    setCurrentLandingId(choice);
  };

  useEffect(() => {
    props.setCurrentLinkId && props.setCurrentLinkId(currentLinkId.value);
  }, []);

  if (showShareDetails) {
    return (
      <div className={styles.root}>
        <ShareDetails
          onClose={handleToggleShareDetails}
          title={props.title}
          text={props.text}
          image={props.image}
          url={`${productURLsMap[props.productKind]}${currentLinkId.label}`}
        />
      </div>
    );
  }
  return (
    <div className={styles.gridRoot}>
      <Select
        listClassName={styles.choices}
        name={'links'}
        choices={links}
        current={currentLinkId}
        onChange={handleChangeLinkId}
        renderChoise={renderLinksChoise}
        label={t('Share_block.Link_ID')}
        dropdownUp
      />

      <Select
        listClassName={styles.choices}
        name={'landings'}
        choices={landings}
        current={currentLandingId}
        onChange={handleChangeLandingId}
        renderChoise={renderLandingsChoise}
        label={t('Share_block.Landing_ID')}
        dropdownUp
      />
      <CopyToClipboard text={String(`${productURLsMap[props.productKind]}${currentLinkId.label}`)} onCopy={handleCopy}>
        <Button
          type="button"
          onClick={handleCopy}
          Icon={isCopied ? <CheckSvg height={16} width={16} /> : <CopySvg height={16} width={16} />}
          className={styles.button}
          disabled={isCopied}
        >
          {t('Share_block.Copy.Btn')}
        </Button>
      </CopyToClipboard>
      <BoxWithLabel
        className={styles.referal}
        textClassName={styles.referalText}
        text={`${productURLsMap[props.productKind]}${currentLinkId.label}`}
        label={t('Share_block.Reflink')}
      />
      <Button
        type="button"
        variant={ButtonVariant.Primary}
        onClick={handleToggleShareDetails}
        Icon={<ShareSvg />}
        className={styles.button}
      >
        {t('Share_block.Share.Btn')}
      </Button>
    </div>
  );
};

const linksToChoice = (links: LinkAcc[]): WithLinksChoice[] => {
  return links.map((_) => ({
    label: String(_.id),
    value: _.id,
    meta: {
      comment: _.comment,
    },
  }));
};

const landingsToChoice = (landings: BrowserPromoLand[]): WithLandingsChoice[] => {
  return landings.map((_) => ({
    label: `ID: ${_.id}`,
    value: _.id,
    meta: {
      img: _.image,
      url: _.url,
    },
  }));
};

const codesToChoice = (codes: PromoType[]): Choice<string>[] => {
  return codes.map((_) => ({
    label: _.code,
    value: _.code,
  }));
};
