import classNames from 'classnames';
import { useEffect, useRef } from 'react';

export type ShareBlockProps = {
  size?: string;
  limit?: number;
  url?: string;
  msg?: string;
  title?: string;
  image?: string;
  className?: string;
};

export function ShareBlock({ size, msg, limit, url, className, title, image }: ShareBlockProps) {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // @ts-ignore
    window.CT.init(ref.current);
  }, []);

  return (
    <div
      className={classNames('social-btns', className)}
      data-url={url || 'https://cryptobrowser.site/app/'}
      // data-services="fb,tw,wa,tg,rd,eml,pt,li,flb,vk,th,ln,vb"
      data-limit={`${limit || 6}`}
      data-size={size || 's'}
      data-title={title}
      datda-image={image}
      data-description={msg}
      ref={ref}
    ></div>
  );
}
