import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BrowserPromoLand, rootBrowserPromo } from '@service/reducers/browserPromoReducer';
import { SmallList } from '@shared/SmallList/SmallList';
import { TabBannersProps } from '@pages/FarmPromo/view/components/TabBanners/TabBanners';
import styles from '@pages/FarmPromo/view/components/TabBanners/TabBanners.module.scss';

export type BannerTabsProps = TabBannersProps;

export default function BannerTabs({ setLand, setPromo, promoAll }: BannerTabsProps) {
  const { t } = useTranslation();
  const land = useSelector<{ browserPromo: rootBrowserPromo }, BrowserPromoLand[]>((store) => store.browserPromo.land);
  return (
    <div className={styles.tab}>
      <div className={styles.tab__title}>{t('Link Settings')}</div>
      <div className={styles.tab__list}>
        <SmallList
          title={t('Promo Code')}
          List={
            <>
              {promoAll?.map((item) => (
                <div key={item} className={styles.tab__promo} onClick={() => setPromo(item)}>
                  {item}
                </div>
              ))}
            </>
          }
        />
      </div>
      <div className={styles.tab__list}>
        <SmallList
          title={t('Landing ID')}
          List={
            <>
              {land?.map((item) => (
                <div key={item.id} onClick={() => setLand(item.url)} className={styles.tab__land}>
                  <img src={item.image} alt="img" />
                  <span>{item.id}</span>
                </div>
              ))}
            </>
          }
        />
      </div>
    </div>
  );
}
