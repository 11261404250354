import styles from './Switch.module.scss';
import cls from 'classnames';

export type SwitchProps = {
  isActive: boolean;
  disabled?: boolean;
  onToggle: () => void;
  className?: string;
};

export default function Switch(props: SwitchProps) {
  const { isActive, disabled, onToggle, className } = props;
  return (
    <div
      className={cls([styles.container, className, isActive && styles.active, disabled && styles.disabled])}
      onClick={onToggle}
    >
      <div className={styles.circle}></div>
    </div>
  );
}
