import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useClickOutside from '@hooks/useClickOutside';
import { LazyImage } from '../LazyImage/LazyImage';
import styles from './LandingCard.module.scss';
import { LandingCardCopy } from './LandingCardCopy';
import { ReactComponent as OpenLinkSvg } from '@assets/colorless/open-link.svg';
import { ReactComponent as AddSignSvg } from '@assets/colorless/add_sign.svg';
import { useHistory } from 'react-router-dom';
import { FARM_GENERATE_CODE } from '@utils/links';
import i18next from 'i18next';
import { SmallList } from '@shared/SmallList/SmallList';
import { LandIdItem } from '@pages/CommonAbout/view/components/Top/shareBlocks/LandIdItem';
import { ReactComponent as Plus } from '@assets/colorless/plus.svg';
import cls from 'classnames';
import { ArrowShortBottomSvg } from '@assets/colorless';

export type LandingCardProps = {
  src: string;
  srcSet?: string;
  href: string;
  listTitle?: string;
  className?: string;
  list?: string[];
  links?: {
    id: string | number;
    name?: string | null;
    comment?: string | null;
  }[];
  disabledLink?: boolean;
  linkStyle?: string;
};

export function LandingCard({ src, srcSet, href, listTitle, list, links, className, linkStyle }: LandingCardProps) {
  const { t } = useTranslation();
  const [active, setActive] = useState(!!list?.[0] ? list?.[0] : '');
  const history = useHistory();
  useEffect(() => {
    list && setActive(list[0]);
  }, [list]);
  const [show, setShow] = useState(false);
  const link = useMemo(() => href.replace(/\{USER_ID\}/, '') + active || '', [active, i18next.language]);
  const ref = useRef<HTMLDivElement>(null);
  const handleClose = useCallback(() => setShow(false), []);
  useClickOutside(ref, handleClose, show);
  const handleAdd = useCallback(() => {
    history.push(FARM_GENERATE_CODE);
  }, [history]);
  return (
    <div className={styles.card}>
      <a href={link} className={cls([styles.card__img_link, linkStyle])} rel="noreferrer" target="_blank">
        <LazyImage src={src} srcSet={srcSet} className={cls([styles.card__img, className])} />
      </a>
      <div className={styles.card__body}>
        <div className={styles.card__title}>
          {listTitle && list?.length! > 1 && <span>{t('Your personal link')}</span>}
          {links && links.length > 1 ? (
            <SmallList
              Arrow={<ArrowShortBottomSvg />}
              className={`${styles.common_list_tag} ${styles.static} ${styles.common_list_tag_browser}`}
              listClassName={styles.common_list}
              title={t('Link ID')}
              listStyles={{ width: '330px', minWidth: 'unset', maxWidth: 'none' }}
              List={
                <>
                  {links.map((i) => (
                    <LandIdItem key={i.id} link={i} onClick={() => setActive(i.id.toString())} />
                  ))}
                  <div onClick={handleAdd} className={styles.landLink}>
                    <Plus />
                    <span>{t('Add link')}</span>
                  </div>
                </>
              }
            />
          ) : (
            <>
              {listTitle && list?.length! > 1 && (
                <div className={styles.select} onClick={() => setShow(!show)} ref={ref}>
                  <span>{listTitle}</span>
                  <ArrowShortBottomSvg color="#B3B3B3" />
                  {show && (
                    <div className={styles.select__list}>
                      {list &&
                        list.map((item) => (
                          <div key={item} onClick={() => setActive(item)} className={styles.select__list_item}>
                            {item}
                          </div>
                        ))}
                      <div className={styles.select__add} onClick={handleAdd}>
                        <AddSignSvg />
                        {t('Add promo code')}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
        <div className={styles.card__link}>{link}</div>
        <div className={styles.card__row}>
          <a href={link} className={styles.card__open} target="_blank" rel="noreferrer">
            <OpenLinkSvg />
            <span>{t('Open')}</span>
          </a>
          <LandingCardCopy text={link} />
        </div>
      </div>
    </div>
  );
}
