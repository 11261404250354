import React, { useCallback, useMemo, useState, useEffect } from 'react';
import styles from './Pagination.module.scss';
import { useDispatch } from 'react-redux';
import { getNextWithdrawals } from '@service/actions/getWithdrawals';
import { useIsMobile } from '@hooks/useIsMobile';

export type PaginationProps = {
  index: number;
  itemsCount: number;
  perPage: number;
  isNextWithdrawalsLoading: boolean;
  isMobile?: boolean;
};

export const Pagination = (props: PaginationProps) => {
  const { itemsCount, perPage, index, isNextWithdrawalsLoading, isMobile } = props;
  const dispatch = useDispatch();
  const [step, setStep] = useState(index);
  const [pages, setPage] = useState<number[]>([]);
  const totalPage = useMemo(() => Math.ceil(itemsCount / perPage), [perPage, itemsCount]);

  useEffect(() => {
    setStep(index);
  }, [index]);

  useEffect(() => {
    let result = [];
    if (totalPage <= 10) {
      setPage(
        Array(totalPage)
          .fill(0)
          .map((n, i) => i)
      );
      return;
    }
    for (let i = step - 2 > 0 ? step - 2 : 0; step - 2 > 0 ? i < step + 9 : i < step + 10; i++) {
      if (i > totalPage - 1) {
        break;
      }
      result.push(i);
    }
    if (result.length < 10 && totalPage > 10) {
      result = [];
      for (let i = totalPage - 1; i > totalPage - 11; i--) {
        result.push(i);
      }
      setPage(result.reverse());
    } else {
      setPage(isMobile ? result.slice(0, 5) : result.slice(0, 10));
    }
  }, [step, totalPage]);

  const handleClick = useCallback(
    (idx: number) => {
      window.scrollTo(0, 0);
      setStep(idx);
      dispatch(getNextWithdrawals(idx + 1));
    },
    [dispatch]
  );

  return itemsCount && itemsCount / perPage >= 1 ? (
    <div className={`${styles.pagination} ${isMobile && styles.modile}`}>
      {step > 2 && totalPage > 10 && (
        <div
          data-disabled={isNextWithdrawalsLoading}
          className={styles.pagination__item}
          onClick={() => handleClick(0)}
        >
          ...
        </div>
      )}
      {pages.map((e, idx) => {
        return (
          <div
            data-disabled={isNextWithdrawalsLoading}
            key={idx}
            className={`${styles.pagination__item} ${step === e ? styles[`pagination__item__active`] : ''}`}
            onClick={() => handleClick(e)}
          >
            {e + 1}
          </div>
        );
      })}
      {step <= totalPage - 9 && totalPage > 10 && (
        <div
          data-disabled={isNextWithdrawalsLoading}
          className={styles.pagination__item}
          onClick={() => handleClick(totalPage - 1)}
        >
          ...
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};
