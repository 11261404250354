import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import styles from './App.module.scss';
import {
  BROWSER_DASH,
  BROWSER_ABOUT,
  FARM_DASH,
  FARM_ABOUT,
  FARM_ABOUT_PRO,
  BROWSER_PROMO,
  BROWSER_LINKS,
  FARM_PROMO,
  FARM_GENERATE_CODE,
  BROWSER_LINKS_CREATE,
  BROWSER_BALANCE,
  SETTINGS,
  BROWSER_WITHDRAWAL,
  BROWSER_FEEDBACK,
  SUPPORT,
  SUPPORT_SUCCESS,
  NFT_DASH,
  NFT_ABOUT,
  NFT_PROMO,
  BROWSER_BANNED,
  COMMON_ABOUT,
  POOL_DASH,
  POOL_STATISTIC,
  POOL_ABOUT,
  POOL_PROMO,
  POOL_LINKS,
  AFFILIATE_OVERVIEW,
  MAIN_PROMO_URL,
  MAIN_LINK_URL,
  BROWSER_SUSPEND,
  BROWSER_STATISTIC,
  MAIN_URL,
  MAIN_ABOUT_URL,
  ECOSYSTEM,
  MAIN_STATISTIC_URL,
  FARM_STATISTIC,
  FARM_LINK_INFO,
  EMAIL_VERIFY,
  BROWSER_WITHDRAWAL_NCW_WALLET,
  BROWSER_BALANCE_ITEM,
} from '@utils/links';
import BrowserDashboard from '@pages/BrowserDashboard';
import BrowserAbout from '@pages/BrowserAbout';
import { Components } from '@pages/Components/Components';
import FarmDashboard from '@pages/FarmDashboard';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useLayoutEffect } from 'react';
import getRate from '@service/actions/getRate';
import { NotFound } from '@pages/NotFound';
import { BrowserPromo } from '@pages/BrowserPromo';
import { BrowserLinks } from '@pages/BrowserLinks';
import { FarmAbout } from '@pages/FarmAbout';
import { FarmAboutPro } from '@pages/FarmAboutPro';
import { FarmPromo } from '@pages/FarmPromo';
import { FarmGenerateCode } from '@pages/FarmGenerateCode';
import LinkInfo from '@pages/BrowserLinkInfo';
import { BrowserBalance } from '@pages/BrowserBalance';
import LinkEdit from '@pages/LinkEdit';
import LinksCreate from '@pages/LinksCreate';
import Settings from '@pages/Settings';
import Withdrawal from '@pages/Withdrawal/Withdrawal';
import { BrowserFeedback } from '@pages/BrowserFeedback';
import Support from '@pages/Support';
import SupportSuccess from '@pages/SupportSuccess';
import { BrowserBanned } from '@pages/BrowserBanned/BrowserBanned';
import CommonAbout from '@pages/CommonAbout';
import PoolDashboard from '@pages/PoolDashboard';
import PoolStatistic from '@pages/PoolStatistic';
import PoolAbout from '@pages/PoolAbout';
import { useDetectMobileType } from '@hooks/useDetectMobileType';
import PoolPromo from '@pages/PoolPromo';
import { PoolLinks } from '@pages/PoolLinks';
import PoolLinkInfo from '@pages/PoolLinkInfo';
import CommonPromo from '@pages/CommonPromo';
import CommonLinks from '@pages/CommonLinks';
import { Overview } from '@pages/Overview';
import { BrowserSuspend } from '@pages/BrowserSuspend/BrowserSuspend';
import NetworkStatusWrapper from '../NetworkStatusWrapper/NetworkStatusWrapper';
import { LayoutRoute } from '../Providers/LayoutRoute/LayoutRoute';
import { BrowserStatistics } from '@pages/BrowserStatistics';
import { Root } from '@service/reducers';
import CommonDashboard from '@pages/CommonDashboard';
import { GET_HSH_WELCOME_BONUS, UPDATE_MOBILE } from '@service/constant';
import EcoSystem from '@pages/EcoSystem';
import { CommonStatistics } from '@pages/CommonStatistics';
import { BREAKPOINTS } from '@constants/breakpoints';
import { FarmStatistics } from '@pages/FarmStatistics';
import { ModalProvider } from '@context/ModalContext';
import FarmLinkInfo from '@pages/FarmLinkInfo';
import { EmailConfirmation } from '../EmailConfirmation';
import { useBannedInterceptor } from '@hooks/useBannedInterceptor';
import { PromoModal } from '../PromoModal/PromoModal';
import { usePromoVariant } from '@hooks/usePromoVariant';
import { useModal } from '@shared/Modal/useModal';
import { rpc } from '../../backend/Rpc';
import WithdrawalNcwWallet from '@pages/WithdrawalNcwWallet/WithdrawalNcwWallet';
import { ADD_INCREASED_BALANCE, CHANGE_WITHDRAWAL_STATUS } from '@service/constant';
import { updateDataToBTC } from '@utils/updateDataToBTC';
import { WithdrawalDetails } from '@pages/WithdrawalDetails';
import toast from 'react-hot-toast';
import { showToast, ToastKind } from '../Toast/Toast';
import { convertBigValueToLocale } from '@utils/convertBigValueToLocale';
import { useTranslation } from 'react-i18next';
import ModalHSH from '../ModalHSH';

function App() {
  const location = useLocation();
  useDetectMobileType();
  const dispatch = useDispatch();
  useBannedInterceptor();
  const { modals, regainPromos } = usePromoVariant();
  let modal = modals[0];
  const { onOpen, opened, onClose } = useModal();
  const { t } = useTranslation();

  const isDarkTheme = useSelector((store: Root) => store.system.isDarkTheme);
  const incBalanceHSH = useSelector((store: Root) => store.balance.increased_balance_hsh);
  const welcomeBonusHSH = useSelector((store: Root) => store.balance.hsh_balance.bonus);

  useEffect(() => {
    document.body.setAttribute('dark-theme', String(isDarkTheme));
  }, [isDarkTheme]);

  useEffect(() => {
    if (location.pathname !== BROWSER_BALANCE) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }

    const modalInteral = setInterval(() => {
      const modalsThrottle = localStorage.getItem('modalsThrottle');

      modal = regainPromos().modals[0];

      if (['/app/', BROWSER_DASH, FARM_DASH, POOL_DASH].includes(location.pathname) && modal && !opened) {
        if ((modalsThrottle && Date.now() > Number(modalsThrottle) * 1000) || !modalsThrottle) {
          onOpen();
        }
      }
    }, 60000);

    return () => {
      onClose();
      clearInterval(modalInteral);
    };
  }, [location.pathname]);

  useEffect(() => {
    rpc.onmethod('wallets.pending_payment', async (e) => {
      dispatch({
        type: ADD_INCREASED_BALANCE,
        increasedBalanceHSH: {
          message: e.meta?.message || '',
          amount: +e.amount,
        },
      });
    });
    rpc.onmethod('wallets.withdrawals.change', async (e) => {
      dispatch({ type: CHANGE_WITHDRAWAL_STATUS, updatableWithdrawal: updateDataToBTC(e) });
    });
    rpc.onmethod('hsh.user.registration', async (e) => {
      dispatch({ type: GET_HSH_WELCOME_BONUS, amount: +e?.amount });
    });
    dispatch(getRate());
  }, [dispatch]);

  useLayoutEffect(() => {
    const handleResize = () => {
      let breakpoints = {
        isMobile: false,
        isTable: false,
        isDesktop: false,
      };

      if (window.innerWidth < BREAKPOINTS.small) {
        breakpoints = { ...breakpoints, isMobile: true };
      } else if (window.innerWidth < BREAKPOINTS.medium && window.innerWidth > BREAKPOINTS.small) {
        breakpoints = { ...breakpoints, isTable: true };
      } else {
        breakpoints = { ...breakpoints, isDesktop: true };
      }

      dispatch({ type: UPDATE_MOBILE, breakpoints });
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [dispatch]);

  useEffect(() => {
    if (incBalanceHSH.totalAmount > 0 && incBalanceHSH.isShowing) {
      toast.remove();
      showToast(
        t('HSH_Upd.Dash.Top_up_HSH_not_b', { HSH: convertBigValueToLocale(incBalanceHSH.totalAmount) }),
        ToastKind.Success
      );
    } else {
      toast.remove();
    }
  }, [incBalanceHSH]);

  useEffect(() => {
    if (welcomeBonusHSH > 0 && welcomeBonusHSH) {
      showToast(t('Toast.Welc_bonus', { HSH: convertBigValueToLocale(welcomeBonusHSH) }), ToastKind.Success);
    }
  }, [welcomeBonusHSH]);

  return (
    <NetworkStatusWrapper>
      <ModalProvider>
        <ModalHSH />
        {modal && <PromoModal opened={opened} modal={modal} onClose={onClose} />}
        <Switch location={location}>
          <LayoutRoute isProtected exact path={MAIN_URL} component={CommonDashboard} />
          <LayoutRoute isProtected exact path={EMAIL_VERIFY} component={EmailConfirmation} />

          <LayoutRoute isProtected exact path={MAIN_PROMO_URL} component={CommonPromo} />
          <LayoutRoute isProtected exact path={MAIN_LINK_URL} component={CommonLinks} />
          <LayoutRoute isGetUser exact path={MAIN_ABOUT_URL} component={CommonAbout} />
          <LayoutRoute isProtected exact path={MAIN_STATISTIC_URL} component={CommonStatistics} />
          <LayoutRoute exact path={'/dashboard'} children={<Redirect to={BROWSER_DASH} />} />
          <LayoutRoute exact path={'/about'} children={<Redirect to={BROWSER_ABOUT} />} />
          <LayoutRoute exact path={'/promo'} children={<Redirect to={BROWSER_PROMO} />} />
          <LayoutRoute exact path={'/links'} children={<Redirect to={BROWSER_LINKS} />} />
          <LayoutRoute exact path={'/dashboard/farm'} children={<Redirect to={FARM_DASH} />} />
          <LayoutRoute exact path={'/promo/farm'} children={<Redirect to={FARM_PROMO} />} />
          <LayoutRoute isProtected exact path={FARM_STATISTIC} component={FarmStatistics} />
          <LayoutRoute exact path={'/app/balance'} children={<Redirect to={BROWSER_BALANCE} />} />
          <LayoutRoute isProtected exact path={BROWSER_DASH} component={BrowserDashboard} />
          <LayoutRoute exact path={BROWSER_ABOUT} component={BrowserAbout} />
          <LayoutRoute isProtected exact path={BROWSER_PROMO} component={BrowserPromo} />
          <LayoutRoute isProtected exact path={BROWSER_LINKS} component={BrowserLinks} />
          <LayoutRoute isProtected exact path={BROWSER_LINKS_CREATE} component={LinksCreate} />
          <LayoutRoute isProtected exact path={BROWSER_STATISTIC} component={BrowserStatistics} />
          <LayoutRoute isProtected exact path={BROWSER_LINKS + '/:id'} component={LinkInfo} />
          <LayoutRoute isProtected exact path={`${BROWSER_BALANCE}/:BTCOrHSH?`} component={BrowserBalance} />
          <LayoutRoute isProtected exact path={`${BROWSER_WITHDRAWAL}/:BTCOrHSH?`} component={Withdrawal} />
          <LayoutRoute
            isProtected
            exact
            path={`${BROWSER_WITHDRAWAL_NCW_WALLET}/:BTCOrHSH?`}
            component={WithdrawalNcwWallet}
          />
          <LayoutRoute
            isProtected
            exact
            path={`${BROWSER_BALANCE_ITEM}/id=:id isBitcoin=:isBitcoin`}
            component={WithdrawalDetails}
          />
          <LayoutRoute isProtected exact path={BROWSER_LINKS + '/:id/edit'} component={LinkEdit} />
          <LayoutRoute path={BROWSER_FEEDBACK}>
            <BrowserFeedback />
          </LayoutRoute>
          <LayoutRoute isProtected exact path={FARM_DASH} component={FarmDashboard} />
          <LayoutRoute isGetUser exact path={FARM_ABOUT} component={FarmAbout} />
          <LayoutRoute isGetUser exact path={FARM_ABOUT_PRO} component={FarmAboutPro} />
          <LayoutRoute isProtected exact path={FARM_PROMO} component={FarmPromo} />
          <LayoutRoute isProtected exact path={FARM_LINK_INFO} component={FarmLinkInfo} />
          <LayoutRoute isProtected exact path={FARM_GENERATE_CODE} component={FarmGenerateCode} />
          <LayoutRoute isProtected exact path={NFT_DASH} children={<Redirect to={MAIN_URL} />} />
          <LayoutRoute isProtected exact path={NFT_PROMO} children={<Redirect to={MAIN_URL} />} />
          <LayoutRoute exact path={NFT_ABOUT} children={<Redirect to={MAIN_URL} />} />
          <LayoutRoute isProtected exact path={POOL_DASH} component={PoolDashboard} />
          <LayoutRoute isProtected exact path={POOL_STATISTIC} component={PoolStatistic} />
          <LayoutRoute isProtected exact path={POOL_LINKS} component={PoolLinks} />
          <LayoutRoute isProtected exact path={POOL_LINKS + '/:id'} component={PoolLinkInfo} />
          <LayoutRoute isProtected exact path={POOL_LINKS + '/:id/edit'} component={LinkEdit} />
          <LayoutRoute exact path={POOL_ABOUT} component={PoolAbout} />
          <LayoutRoute isProtected exact path={POOL_PROMO} component={PoolPromo} />
          <LayoutRoute isProtected exact path={SETTINGS} component={Settings} />
          <Route exact path={AFFILIATE_OVERVIEW} component={Overview} />
          <LayoutRoute exact path={SUPPORT_SUCCESS} component={SupportSuccess} />
          <LayoutRoute exact path={`${SUPPORT}`} component={Support} />
          <LayoutRoute exact path={`${SUPPORT}/:topic`} component={Support} />
          <LayoutRoute exact path={BROWSER_BANNED} component={BrowserBanned} />
          <LayoutRoute exact path={BROWSER_SUSPEND} component={BrowserSuspend} />
          <LayoutRoute exact path={COMMON_ABOUT} children={<Redirect to={'/app/'} />} />
          <LayoutRoute exact path={'/app/components'} component={Components} />
          <LayoutRoute exact path={ECOSYSTEM} component={EcoSystem} />
          <LayoutRoute path="*" component={NotFound} />
        </Switch>
      </ModalProvider>
    </NetworkStatusWrapper>
  );
}

export default App;
