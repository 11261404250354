import { FC, useCallback, useEffect, useState } from 'react';
import { Card, Container } from '@shared/index';
import { useTranslation } from 'react-i18next';
import { BalanceTable } from './components/BalanceTable/BalanceTable';
import { Withdrawal } from '../../../backend/JsonRpcApi';
import { Pagination } from './components/Pagination/Pagination';
import { LoadSpinner } from '@shared/Loader';
import { Modal } from './components/Modal/Modal';
import { useSelector } from 'react-redux';
import { Root } from '@service/reducers';
import MobileFooter from '@pages/BrowserBalance/view/components/MobileFooter/MobileFooter';
import HistoryBanner from './components/HistoryBanner/HistoryBanner';
import { WithdrawalSwitchTabs } from '@pages/Withdrawal/view/WithdrawalSwitchTabs';
import styles from './BrowserBalanceView.module.scss';
import { BtcDecorImg, BtcDecorRetinaImg, HshDecorImg, HshDecorRetinaImg } from '@assets/img';

export type BrowserBalanceViewProps = {
  history: Withdrawal[];
  index: number;
  perPage: number;
  limit: number;
  isNextWithdrawalsLoading: boolean;
  isBitcoin: boolean;
  setIsBitcoin: (val: boolean) => void;
  hasRequested: boolean;
};

export const BrowserBalanceView: FC<BrowserBalanceViewProps> = (props) => {
  const { history, limit, perPage, index, isNextWithdrawalsLoading, isBitcoin, setIsBitcoin, hasRequested } = props;
  const { networkAlert, system } = useSelector((store: Root) => store);
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, document.getElementById('story')?.getBoundingClientRect().top! - 180);
  }, []);

  const [modal, setModal] = useState(true);

  const showModal = !/networkBtcAlert=hide/gi.test(document.cookie) && modal && networkAlert.overload;

  const handleCloseModal = useCallback(() => {
    document.cookie = 'networkBtcAlert=hide; max-age=43200;';
    setModal(false);
  }, []);

  return (
    <>
      <div className={styles.browserBalance}>
        <div className={styles.browserBalance__decoration}>
          {isBitcoin ? (
            <img
              src={BtcDecorImg}
              srcSet={BtcDecorRetinaImg + ' 2x'}
              className={styles.decoration__img}
              alt="background"
            />
          ) : (
            <img
              src={HshDecorImg}
              srcSet={HshDecorRetinaImg + ' 2x'}
              className={styles.decoration__img}
              alt="background"
            />
          )}
        </div>
        <Container>
          <Card
            className={styles.card}
            headerClass={styles.card_header}
            TitleElement={<CardTitle title={t('Payment History')} isBitcoin={isBitcoin} setIsBitcoin={setIsBitcoin} />}
            id="story"
          >
            {!hasRequested ? (
              <div className={styles.browserBalance__card__loader}>
                <LoadSpinner />
              </div>
            ) : (
              <BalanceTable history={history} isBitcoin={isBitcoin} perPage={perPage} />
            )}
          </Card>
          {system.isMobile ? (
            <>
              <MobileFooter />
              <Pagination
                itemsCount={limit}
                perPage={perPage}
                index={index}
                isNextWithdrawalsLoading={isNextWithdrawalsLoading}
                isMobile={system.isMobile}
              />
              <div style={{ marginTop: 20 }}>
                <HistoryBanner />
              </div>
            </>
          ) : (
            <>
              <HistoryBanner />
              <Pagination
                itemsCount={limit}
                perPage={perPage}
                index={index}
                isNextWithdrawalsLoading={isNextWithdrawalsLoading}
              />
            </>
          )}
        </Container>
      </div>
      {showModal && <Modal onClose={handleCloseModal} />}
    </>
  );
};

const CardTitle = ({
  title,
  isBitcoin,
  setIsBitcoin,
}: {
  title: string;
  isBitcoin: boolean;
  setIsBitcoin: (val: boolean) => void;
}) => {
  return (
    <div className={styles.browserBalance__cardHeader}>
      <h2 className={styles.browserBalance__cardTitle}>{title}</h2>
      <WithdrawalSwitchTabs
        className={styles.browserBalance__cardSwitch}
        isBitcoin={isBitcoin}
        setIsBitcoin={setIsBitcoin}
      />
    </div>
  );
};
