import styles from './Footer.module.scss';
import { config, FooterConfigItem } from '@pages/headersComponents/config';
import React, { useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import checkPathname from '@utils/checkPathname';
import BottomMobileSafeArea from '../BottomMobileSafeArea/BottomMobileSafeArea';

export default function MobileFooter() {
  const { pathname } = useLocation();

  const info = useMemo(() => {
    let resp: FooterConfigItem[] = [];
    const active = checkPathname(pathname);

    if (active) {
      resp = config[active];
    }

    return resp;
  }, [pathname]);

  if (info.length === 0) {
    return null;
  }
  return (
    <>
      <div className={styles.mob_container}>
        {info.map((i) => (
          <NavLink exact to={{ pathname: i.url }} key={i.url} activeClassName={styles.mob_active}>
            {i.Icon}
          </NavLink>
        ))}
      </div>
      <BottomMobileSafeArea />
    </>
  );
}
