import styles from './Simple.module.scss';
import { ReactNode, useCallback } from 'react';
import { ArrowRightSmSvg } from '@assets/colorless';
import { useHistory } from 'react-router-dom';

export type SimpleLinkProps = {
  Icon: ReactNode;
  title: string;
  url: string;
  inApp?: boolean;
};

export default function SimpleLink(props: SimpleLinkProps) {
  const { Icon, url, title, inApp } = props;
  const navigation = useHistory();
  const onClick = useCallback(() => {
    if (inApp) {
      navigation.push(url);
    } else {
      window.open(url, '_blank');
    }
  }, [inApp, navigation, url]);
  return (
    <div className={styles.container} onClick={onClick}>
      {Icon}
      <span className={styles.title}>{title}</span>
      <ArrowRightSmSvg className={styles.arrow} />
    </div>
  );
}
