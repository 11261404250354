import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PoolMinerResp, PoolResp, PoolShortResp } from '@service/reducers/poolStatisticReducer';
import { Root } from '@service/reducers';

export default function useFilteredPoolLinks(id?: number | string) {
  const store = useSelector((store: Root) => store.poolStatistic);
  return useMemo(() => {
    let resp = {
      open: 0,
      install: 0,
      revenue: 0,
      active: 0,
    };
    function filterOnId<T>(arr: T[], arrId: string | number) {
      return arr?.filter((item) => (item as any).account_id === Number(arrId));
    }
    let opens = store.opens;
    let installs = store.installs;
    let miners = store.miners;
    if (id && id !== 'All Link IDs') {
      miners = filterOnId<PoolMinerResp>(miners, id);
      opens = filterOnId<PoolShortResp>(opens, id);
      installs = filterOnId<PoolResp>(installs, id);
    }

    resp.open = opens?.reduce((acc: number, item: PoolShortResp) => (acc += item.value), 0);
    resp.install = installs?.reduce((acc: number, item: PoolResp) => (item.lvl === 1 ? (acc += item.value) : acc), 0);
    resp.active = miners?.reduce((acc: number, item: PoolMinerResp) => (acc += item.value), 0);
    resp.revenue = miners?.reduce(
      (acc: number, item: PoolMinerResp) => (acc += parseFloat((item.amount ?? 0).toFixed(12))),
      0
    );
    return resp;
  }, [store, id]);
}
