import { WithdrawalPaymentService } from '../backend/JsonRpcApi';

export enum WithdrawStatus {
  created = 1,
  confirmed = 2,
  canceled = 3,
  rejected = 4,
  approved = 5,
  sent = 6,
  processed = 7,
  error = 8,
  complete = 9,
  reviewed = 10,
  reconfirm = 11,
  reconfirmed = 12,
}

export enum Color {
  BLUE,
  RED,
  GOLD,
  SUCCESS,
  GRAY,
  DEFAULT,
}

export const getTransactionColor = (status: number | undefined, type?: WithdrawalPaymentService): Color => {
  switch (status) {
    case WithdrawStatus.canceled:
    case WithdrawStatus.rejected:
    case WithdrawStatus.error:
      return Color.RED;
    case WithdrawStatus.created:
    case WithdrawStatus.reconfirm:
      return Color.GRAY;
    case WithdrawStatus.confirmed:
    case WithdrawStatus.approved:
    case WithdrawStatus.sent:
    case WithdrawStatus.processed:
    case WithdrawStatus.reviewed:
    case WithdrawStatus.reconfirmed:
      return Color.BLUE;
    case WithdrawStatus.complete:
      return Color.SUCCESS;
    default:
      return Color.DEFAULT;
  }
};
