import styles from './BtcRate.module.scss';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Line, LineChart, Tooltip, YAxis } from 'recharts';
import { rootRateType } from '@service/reducers/rateReducer';

export default function BtcRate() {
  const rate = useSelector((store: { rate: rootRateType }) => store.rate);
  const handleClick = useCallback(() => window.open('https://coinmarketcap.com/currencies/bitcoin/#charts'), []);
  return (
    <div className={styles.btc_chart__container}>
      <div className={styles.btc_chart__line_chart}>
        <LineChart
          width={180}
          margin={{ top: 8, right: 8, bottom: 8, left: 8 }}
          height={36}
          data={rate.btc_history}
          onClick={handleClick}
        >
          <YAxis type="number" hide={true} domain={['dataMin', 'dataMax']} />

          <Tooltip content={<CustomTooltip />} cursor={false} />

          <Line type="monotone" dataKey="avg" dot={false} stroke="#F7931A" strokeWidth={1.5} />
        </LineChart>
      </div>
      <div className={styles.btc_chart__rates}>
        <b>BTC / USD</b>
        <span>{rate.btc2usd}</span>
      </div>
    </div>
  );
}

const CustomTooltip = (props: any) => {
  if (!props.active) {
    return null;
  }
  return (
    <div
      style={{
        whiteSpace: 'nowrap',
        backgroundColor: 'rgba(0,0,0,0.9)',
        color: '#fff',
        fontSize: '11px',
        padding: '4px',
        borderRadius: '2px',
      }}
    >
      <b>{props.payload[0].payload.date}</b>, BTC / USD – <b>{props.payload[0].value}</b>
    </div>
  );
};
