import React, { FC } from 'react';
import styles from './PoolAccess.module.scss';
import { useTranslation } from 'react-i18next';
import { Card } from '@shared/Card/Card';
import { ReactComponent as Arrow } from '@assets/colorless/arrow-right-sm.svg';
import { Button, ButtonVariant } from '@shared/Button/Button';
import { StoreButton, StoreButtonKind } from '@shared/StoreButton/StoreButton';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { Picture } from '@shared/Picture/Picture';

export const PoolAccess = () => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();

  return (
    <Card className={styles.card}>
      <div className={`${styles.poolAccess}`}>
        <div className={styles.poolAccess__content}>
          <h2 className={styles.poolAccess__title}>{t('MobileVersion.Title')}</h2>
          <p className={styles.poolAccess__description}>{t('MobileVersion.Description')}</p>

          <div className={styles.poolAccess__btn_group}>
            {!isMobile ? (
              <>
                <StoreButton
                  link={'https://play.google.com/store/apps/details?id=net.ctpool'}
                  kind={StoreButtonKind.PlayMarketFull}
                  target={'_blank'}
                />
                <StoreButton kind={StoreButtonKind.AppStoreFull} link={'#'} target={'_blank'} disabled />
                <StoreButton kind={StoreButtonKind.Web} link={'https://app.ctpool.net/'} target={'_blank'} />
              </>
            ) : (
              <Button
                type={'link'}
                to={'https://ctpool.net/'}
                className={styles.poolAccess__btn}
                external
                variant={ButtonVariant.Primary}
                iconPosition="right"
                Icon={<Arrow color="#fff" />}
              >
                {t('Learn more')}
              </Button>
            )}
          </div>
        </div>
        <div className={styles.poolAccess__image}>
          <Picture
            imageDesktop={'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-pool/pool-access-img.png'}
            imageDesktop2x={'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-pool/pool-access-img@2x.png'}
            imageMob={'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-pool/pool-access-img-mob.png'}
            imageMob2x={'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-pool/pool-access-img-mob@2x.png'}
            breakPoint={'600px'}
          ></Picture>
        </div>
      </div>
    </Card>
  );
};
