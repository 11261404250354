import Decimal from 'decimal.js';
import i18next from 'i18next';

export function convertBigValueToLocale(num: number | string, decimalFlag?: boolean) {
  if (num < 1) {
    return num;
  }

  const decimalNum = new Decimal(num);

  if (decimalNum.isZero() || decimalNum.isNaN()) {
    return new Decimal(0).toNumber().toLocaleString(i18next.language);
  }

  if (decimalFlag) {
    // If the flag is true, return the original number without flooring, formatted as a localized string
    return decimalNum.toNumber().toLocaleString(i18next.language);
  }

  // Otherwise, return the floored number formatted as a localized string
  const numAsInt = decimalNum.floor();
  return numAsInt.toNumber().toLocaleString(i18next.language);
}
