import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useModal } from '@shared/Modal/useModal';
import { CheckSvg, CrossCircleSvg, IconWalletSvg } from '@assets/colorless';
import { BtcSvg } from '@assets/colored';
import { Button, ButtonVariant } from '@shared/Button/Button';
import { Modal } from '@shared/Modal/Modal';
import cn from 'classnames';
import { WithdrawalError } from '@pages/Withdrawal/view/WithdrawalError';
import { jsColors } from '@utils/jsColors';
import { rpc } from '../../../../../backend/Rpc';
import { BROWSER_WITHDRAWAL } from '@utils/links';
import styles from './ModalBtcConnect.module.scss';
import { BTC_NCW_CONNECT } from '@pages/WithdrawalNcwWallet/view/WithdrawalNcwWalletView/WithdrawalNcwWalletView';

interface ModalBtcConnectProps {
  isShow: boolean;
  changeIsShow: () => void;
  BTCOrHSH: string;
}

export default function ModalBtcConnect({ isShow, changeIsShow, BTCOrHSH }: ModalBtcConnectProps) {
  const { opened, onOpen, onClose } = useModal(isShow);
  const [address, setAddress] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (isShow) onOpen();
  }, [isShow]);

  const handleClose = useCallback(() => {
    changeIsShow();
    onClose();
  }, []);

  const handleSubmit = useCallback(async (address: string) => {
    try {
      setIsLoading(true);
      const res = await rpc.transmit('wallets.check_address', { address });

      if (res) {
        let addressToLocal;
        if ('email' in res) {
          addressToLocal = { address: res.email || res.account_id, is_ncw: res.type === 'ncw' };
        } else {
          addressToLocal = { address, is_ncw: res.type === 'ncw' };
        }
        localStorage.setItem(BTC_NCW_CONNECT, JSON.stringify(addressToLocal));
        window.open(`${BROWSER_WITHDRAWAL}/${BTCOrHSH}`, '_self');
      }
    } catch (e) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return (
    <Modal onClose={handleClose} opened={opened} className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.wrapper__logo}>
          <BtcSvg className={styles.wrapper__icon} />
        </div>
        <span className={styles.wrapper__title}>{t('BTC_address.Title')}</span>
        <span className={styles.wrapper__text}>{t('BTC_address.Descr')}</span>
        <div className={styles.wrapper__container}>
          <div className={cn(styles.address, error && styles.input_error)}>
            <IconWalletSvg color={jsColors.orange} className={styles.address__logo} />
            <input
              type="text"
              placeholder={t('Add New Address')}
              disabled={isLoading}
              id="address"
              autoFocus={true}
              value={address}
              onChange={(e) => {
                setAddress(e.target.value);
                setError(false);
              }}
              className={styles.address__input}
            />
          </div>
          {error && <WithdrawalError text={t('BTC_address.Error')} />}
        </div>
        <div className={styles.wrapper__details}>
          <Button
            type={'button'}
            onClick={handleClose}
            className={styles.details__button}
            variant={ButtonVariant.Default}
            Icon={<CrossCircleSvg />}
          >
            {t('Cancel')}
          </Button>
          <Button
            type={'button'}
            className={styles.details__button}
            variant={ButtonVariant.Primary}
            Icon={<CheckSvg />}
            isLoading={isLoading}
            disabled={!address || isLoading || error}
            onClick={() => handleSubmit(address)}
          >
            {t('Confirm')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
