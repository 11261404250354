import { ColorMap, Colors, getRandomColor } from '@utils/colors';
import {
  INTERNAL_ERROR,
  SET_COLOR,
  SET_DARK_THEME,
  SET_RANDOM,
  SET_TITLE_PAGE,
  UPDATE_MOBILE,
} from '@service/constant';
import { booleanFromString } from '@utils/booleanFromString';
import { JsonRpcException } from '../../backend/JsonRpcException';

export const localStorageDarkKey = 'dark';
export const localStorageRandomKey = 'random';
export const localStorageColorKey = 'color';

const setLocalStorage = (key: string, value: string) => window.localStorage.setItem(key, value);

const getLocalStorage = (key: string, defaultValue: string) => {
  return window.localStorage.getItem(key) || defaultValue;
};

const getInitColor = () => {
  if (booleanFromString(getLocalStorage(localStorageRandomKey, 'false'))) {
    return getRandomColor();
  }
  const color = getLocalStorage(localStorageColorKey, Colors.Orange) as Colors;
  document.body.style.cssText = `--background-color: ${ColorMap[color]};`;
  return color;
};

const initialState = {
  backgroundColor: getInitColor(),
  pageTitle: '',
  isRandomBg: booleanFromString(getLocalStorage(localStorageRandomKey, 'false')),
  isDesktop: false,
  isTable: false,
  isMobile: false,
  isDarkTheme: booleanFromString(getLocalStorage(localStorageDarkKey, 'false')),
  internalError: false,
};

type Breakpoints = {
  isDesktop: boolean;
  isTable: boolean;
  isMobile: boolean;
};

export type rootSystem = typeof initialState;

type Action = {
  type: string;
  value: boolean | Colors;
  breakpoints?: Breakpoints;
  title?: string;
  payload?: JsonRpcException;
};

export default function systemReducer(state = initialState, action: Action) {
  switch (action.type) {
    case SET_DARK_THEME: {
      setLocalStorage(localStorageDarkKey, `${action.value}`);
      return {
        ...state,
        isDarkTheme: action.value,
      };
    }
    case SET_RANDOM: {
      setLocalStorage(localStorageRandomKey, `${action.value}`);
      return {
        ...state,
        isRandomBg: action.value,
      };
    }
    case SET_COLOR: {
      setLocalStorage(localStorageColorKey, `${action.value}`);
      return {
        ...state,
        backgroundColor: action.value,
      };
    }
    case UPDATE_MOBILE: {
      return {
        ...state,
        isMobile: action.breakpoints?.isMobile,
        isTable: action.breakpoints?.isTable,
        isDesktop: action.breakpoints?.isDesktop,
      };
    }

    case INTERNAL_ERROR: {
      return {
        ...state,
        internalError: action.payload,
      };
    }

    case SET_TITLE_PAGE: {
      return {
        ...state,
        pageTitle: action.title,
      };
    }

    case SET_TITLE_PAGE: {
      return {
        ...state,
        pageTitle: action.title,
      };
    }

    default:
      return state;
  }
}
