import styles from './Top.module.scss';
import { Card } from '@shared/Card/Card';
import { useTranslation } from 'react-i18next';
import { ArrowRightSmSvg } from '@assets/colorless';
import { Tabs } from '@shared/Tabs/Tabs';
import { ReactComponent as CT } from '@assets/browser-icon.svg';
import { useState } from 'react';
import {
  AllProductTabs,
  CommonProduct,
  CommonProductLinks,
  CommonProductState,
} from '@shared/CommonProduct/CommonProduct';
import { BROWSER_DASH, FARM_DASH, POOL_DASH } from '@utils/links';
import { FarmSmLogoSvg } from '@assets/index';
import { FarmProLogoSvg } from '@assets/colored';
import ProductFooter from '@pages/CommonDashboard/view/components/Top/ProductFooter';
import { PRODUCT_KIND } from '@utils/checkPathname';

export type CommonDashboardTopProps = CommonProductLinks;

export default function Top(props: CommonDashboardTopProps) {
  const { farmLinks, farmProLinks, browserLinks, farmPromos, browserPromos, poolPromos, poolLinks } = props;
  const { t } = useTranslation();
  const [tab, setTab] = useState(AllProductTabs.Link);
  const [state, setState] = useState<CommonProductState>({});
  const baseProps = { state, active: tab, setState };
  return (
    <Card
      TitleElement={
        <div className={styles.header}>
          <div className={styles.header__product}>
            <span>{t('ProductTitle')}</span>
            <ArrowRightSmSvg />
          </div>
          <Tabs
            active={tab}
            setState={setTab}
            items={[
              { text: t('Links'), kind: AllProductTabs.Link },
              { text: t('Promo Codes'), kind: AllProductTabs.Promo },
            ]}
          />
        </div>
      }
    >
      <div className={styles.body}>
        <CommonProduct
          Icon={<CT />}
          index={'br'}
          title={'CryptoTab Browser'}
          url={BROWSER_DASH}
          text={t('ct-affiliate_about_text-edits.first.block.Product_Icon.45')}
          links={browserLinks}
          promoCodes={browserPromos}
          Footer={<ProductFooter productKind={PRODUCT_KIND.Browser} />}
          {...baseProps}
        />
        <CommonProduct
          Icon={<FarmSmLogoSvg width={48} height={48} />}
          title={'CT Farm'}
          index={'ctf'}
          url={FARM_DASH}
          text={t('ct-affiliate_about_text-edits.first.Product_Icon.84')}
          links={farmLinks}
          promoCodes={farmPromos}
          Footer={<ProductFooter productKind={PRODUCT_KIND.Farm} />}
          {...baseProps}
        />
        <CommonProduct
          index={'ctfp'}
          Icon={<FarmProLogoSvg />}
          title={'CT Farm Pro'}
          url={FARM_DASH}
          text={t('ct-affiliate_about_text-edits.first.block.Product_Icon.45')}
          links={farmProLinks}
          promoCodes={farmPromos}
          Footer={<ProductFooter productKind={PRODUCT_KIND.FarmPro} />}
          {...baseProps}
        />
        <CommonProduct
          index={'ctp'}
          Icon={<CT />}
          title={'CT Pool'}
          url={POOL_DASH}
          text={t('ct-affiliate_about_text-edits.first.ct_pool_small_card_description')}
          links={poolLinks}
          promoCodes={poolPromos}
          Footer={<ProductFooter productKind={PRODUCT_KIND.Pool} />}
          {...baseProps}
        />
      </div>
    </Card>
  );
}
