import { FC, useCallback, useEffect, useState } from 'react';
import { SuspendCard } from './SuspendCard';
import styles from './suspendCard.module.scss';
import { Button, ButtonVariant } from '@shared/index';
import { ReactComponent as Arrow } from '@assets/colorless/arrowLeft.svg';
import { ReactComponent as Check } from '@assets/colorless/check.svg';
import { useHistory } from 'react-router-dom';
import { validateEmail, validateUrl } from '@pages/BrowserSuspend/validators';
import { SupportErrorVariant } from '@pages/Support/SupportError';
import { rpc } from '../../../../backend/Rpc';
import { useSelector } from 'react-redux';
import { Root } from '@service/reducers';
import { SUPPORT_SUCCESS } from '@utils/links';

type SuspendControllerProps = {
  suspendConfig: Record<string, any>;
};

export type SupportError = {
  error: string;
  variant: SupportErrorVariant;
};

export const SuspendController: FC<SuspendControllerProps> = ({ suspendConfig }) => {
  const { goBack, replace } = useHistory();
  const { title, description, next, notReadPage } = suspendConfig;
  const [formState, setFormState] = useState<{ [key: string]: string }[]>([]);
  const [page, setPage] = useState(0);
  const [isNext, setIsNext] = useState(false);
  const [notRead, setNotRead] = useState(false);
  const currentPage = notRead ? notReadPage : next[page];
  const [error, setError] = useState<SupportError>({
    error: '',
    variant: SupportErrorVariant.Default,
  });
  const [isSending, setIsSending] = useState(false);
  const user = useSelector((store: Root) => store.user);

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
    setIsNext(false);
  }, [page]);

  const handleSubmit = useCallback(
    async (formState: { [key: string]: string }[]) => {
      setIsSending(true);
      try {
        let message = '';
        formState.forEach((item, idx) => {
          message += `\n\n*${idx}*`;
          for (const [key, value] of Object.entries(item)) {
            message += `\n*${key}*: ${value}`;
          }
        });
        await rpc.transmit('support.create_issue', {
          subject_id: 37,
          email: user.email,
          summary: 'Suspend Form Message',
          message,
        });
      } catch (e) {
        console.log(e);
      } finally {
        setIsSending(false);
        replace({ pathname: SUPPORT_SUCCESS });
      }
    },
    [user.email, replace]
  );

  const handleNextPage = () => {
    if (page === 6) {
      const { isValid: isEmailValid, error: emailError } = validateEmail(formState[page].Email);
      if (!isEmailValid) {
        return setError(emailError!);
      }
    }
    if (page === 5) {
      const { isValid: isUrlValid, error: urlError } = validateUrl(formState[page].Attach);
      if (!isUrlValid) {
        return setError(urlError!);
      }
    }
    if (formState[page].Biling && page === 9) {
      const { isValid: isUrlValid, error: urlError } = validateUrl(formState[page].Biling);
      if (!isUrlValid) {
        return setError(urlError!);
      }
    }
    setPage(page + 1);
  };

  const handlePrevPage = () => {
    setFormState((prevFormState) => {
      const newFormState = [...prevFormState];
      newFormState[page - 1] = {};
      return newFormState;
    });
    setPage(page - 1);
  };

  return (
    <div className={styles.suspendCard}>
      <h2 className={styles.suspendCard__title}>{title}</h2>
      <div className={styles.suspendCard__body}>
        <p className={styles.suspendCard__description}>{description}</p>
        <SuspendCard
          {...currentPage}
          setIsNext={setIsNext}
          page={page}
          setNotRead={setNotRead}
          notRead={notRead}
          formState={formState}
          setFormState={setFormState}
          error={error}
        />
      </div>
      <div className={styles.suspendCard__controls}>
        {notRead ? (
          <Button type={'button'} size="medium" onClick={() => setNotRead(false)} Icon={<Arrow />}>
            Back
          </Button>
        ) : (
          <Button
            type={'button'}
            size="medium"
            onClick={() => (page === 0 ? goBack() : handlePrevPage())}
            Icon={<Arrow />}
          >
            Back
          </Button>
        )}
        {notRead ? (
          <Button
            type={'button'}
            variant={ButtonVariant.Primary}
            size="medium"
            onClick={() => setNotRead(false)}
            Icon={<Check />}
          >
            Next
          </Button>
        ) : next.length - 1 !== page ? (
          <Button
            type={'button'}
            size="medium"
            onClick={() => handleNextPage()}
            variant={ButtonVariant.Primary}
            disabled={!isNext}
          >
            Next
          </Button>
        ) : (
          <Button
            type={'button'}
            variant={ButtonVariant.Primary}
            size="medium"
            onClick={() => handleSubmit(formState)}
            Icon={<Check />}
            disabled={!isNext || isSending}
          >
            Send
          </Button>
        )}
      </div>
    </div>
  );
};
