export enum Colors {
  Black = 'black',
  White = 'white',
  Orange = 'orange',
  Blue = 'blue',
  Green = 'green',
  Cyan = 'cyan',
  Mint = 'mint',
  Yellow = 'yellow',
  Pink = 'pink',
  Pistacho = 'pistacho',
  Purple = 'purple',
  Red = 'red',
  Success = 'success',
  Indigo = 'indigo',
  Grass = 'grass',
  Watter = 'watter',
  Violet = 'violet',
  Brown = 'brown',
  Blood = 'blood',
  Asphalt = 'asphalt',
  Swamp = 'swamp',
  See = 'see',
  Dirty = 'dirty',
  LightViolet = 'light_violet',
}

export const LocalStorageColorKey = 'bg';
export const RandomColor = 'random';

export const ColorMap: { [K in Colors]: string } = {
  [Colors.Black]: '#000',
  [Colors.White]: '#fff',
  [Colors.Purple]: '#A033CC',
  [Colors.Blue]: '#4285F4',
  [Colors.Green]: '#37CC33',
  [Colors.Cyan]: '#1AB9E5',
  [Colors.Mint]: '#1DC886',
  [Colors.Yellow]: '#F7C21A',
  [Colors.Pink]: '#FC4BAC',
  [Colors.Pistacho]: '#C9F85D',
  [Colors.Red]: '#F74249',
  [Colors.Success]: '#17EF46',
  [Colors.Indigo]: '#3F3DA5',
  [Colors.Grass]: '#3CA867',
  [Colors.Watter]: '#5D868F',
  [Colors.Violet]: '#6B1F77',
  [Colors.Brown]: '#826A5C',
  [Colors.Blood]: '#791130',
  [Colors.Asphalt]: '#455265',
  [Colors.Swamp]: '#7A825C',
  [Colors.See]: '#5D88C8',
  [Colors.Dirty]: '#437155',
  [Colors.LightViolet]: '#9265A7',
  [Colors.Orange]: '#F7931A',
};

export const getRandomColor = () => {
  const colors = Object.keys(ColorMap);
  return colors[Math.ceil(Math.random() * (colors.length - 1))] as Colors;
};
