import { Button } from '@shared/Button/Button';
import styles from './GeneratePostModalHeader.module.scss';
import { HTMLAttributes } from 'react';
import { ReactComponent as Refresh } from '@assets/refresh.svg';
import { RefreshContent } from '../GeneratePostModal';
import { Tooltip } from '@shared/Tooltip/Toolkit';
import { useTranslation } from 'react-i18next';
import { Tabs } from '@shared/Tabs/Tabs';
import { AllProductTabs } from '@shared/CommonProduct/CommonProduct';
import { PRODUCT_KIND } from '@utils/checkPathname';

type GeneratePostModalHeaderProps = HTMLAttributes<HTMLDivElement> & {
  title: string;
  onRefresh: (evt: React.MouseEvent<HTMLButtonElement>) => void;

  currentTab: AllProductTabs;
  setCurrentTab: (tab: AllProductTabs) => void;
  productKind: PRODUCT_KIND;
  showTabs?: boolean;
};

export const GeneratePostModalHeader = (props: GeneratePostModalHeaderProps) => {
  const { t } = useTranslation();
  const { title, onRefresh, productKind, currentTab, setCurrentTab, showTabs = true, ...rest } = props;

  return (
    <div className={styles.root} {...rest}>
      <div className={styles.wrapper}>
        <h4 className={styles.title}>{title}</h4>
        <Tooltip text={t('tip.post')} className={styles.tooltip}>
          <Button
            type="button"
            name={RefreshContent.All}
            onClick={onRefresh}
            Icon={<Refresh />}
            onlyIcon
            className={styles.refresh}
          />
        </Tooltip>
      </div>
      {((showTabs && productKind === PRODUCT_KIND.Farm) || productKind === PRODUCT_KIND.FarmPro) && (
        <Tabs
          className={styles.tabs}
          active={currentTab}
          setState={setCurrentTab}
          items={[
            { text: t('Share_block.Link'), kind: AllProductTabs.Link },
            { text: t('Share_block.Code'), kind: AllProductTabs.Promo },
          ]}
        />
      )}
    </div>
  );
};
