import React, { FC, useCallback, useState } from 'react';
import styles from './Calc.module.scss';
import { Button, ButtonVariant, Container } from '@shared/index';
import { CalcSlider } from '../CalcSlider/CalcSlider';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as CalculatorSvg } from '@assets/colorless/calculator.svg';
import { CalcResult } from '../CalcResult/CalcResult';
import LeftSvg from './left.svg';
import RightSvg from './right.svg';
import MobileSvg from './mobile.svg';

type CalcProps = {
  onModalCall: () => void;
};

export const Calc: FC<CalcProps> = (props) => {
  const { onModalCall } = props;
  const { t } = useTranslation();

  const [friends, setFriends] = useState(5);
  const [friendsFinal, setFriendsFinal] = useState(0);
  const [friendsOfFriends, setFriendsOfFriends] = useState(5);
  const [friendsOfFriendsFinal, setFriendsOfFriendsFinal] = useState(0);

  const [isResultVisible, setIsResultVisible] = useState(false);

  const handlePressResult = useCallback(() => {
    setIsResultVisible(true);
    setFriendsFinal(friends);
    setFriendsOfFriendsFinal(friendsOfFriends);
  }, [friends, friendsOfFriends]);

  return (
    <div className={styles.calcWrapper}>
      <div className={styles.calc}>
        <div className={styles.calc__top}>
          <h3 className={styles.calc__title}>{t('Calculate how much you could earn monthly')}</h3>
        </div>
        <div className={styles.calc__body}>
          <div className={styles.calc__body_inner}>
            <div className={styles.calc__slider}>
              <CalcSlider
                value={friends}
                max={10}
                label={t('Number of new users invited by you')}
                tooltip={t('tip.YourUsers')}
                onChange={setFriends}
              />
            </div>
            <div className={styles.calc__slider}>
              <CalcSlider
                value={friendsOfFriends}
                max={10}
                label={t('How many friends they lead')}
                tooltip={t('tip.ReferralUsers')}
                onChange={setFriendsOfFriends}
              />
            </div>

            <Button
              type={'button'}
              variant={ButtonVariant.Primary}
              onClick={handlePressResult}
              Icon={<CalculatorSvg />}
              className={styles.calc__btn}
            >
              <Trans
                i18nKey={'<strong>Calculate</strong> My Income'}
                components={{
                  strong: <strong />,
                }}
              />
            </Button>
          </div>
          <div className={styles.calc__decoration}>
            <img src={LeftSvg} alt="background" className={styles.calc__left_decoration} />
            <img src={RightSvg} alt="background" className={styles.calc__right_decoration} />
          </div>
        </div>

        {!isResultVisible && (
          <div className={styles.calc__decoration_mobile}>
            <img src={MobileSvg} alt="background" />
          </div>
        )}
        {isResultVisible && (
          <CalcResult friends={friendsFinal} friendsOfFriends={friendsOfFriendsFinal} onModalCall={onModalCall} />
        )}
      </div>
    </div>
  );
};
