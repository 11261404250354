import {
  GET_WITHDRAWALS_SUCCESS,
  GET_WITHDRAWALS_FORCE_REQUEST,
  GET_WITHDRAWALS_REQUEST,
  GET_WITHDRAWALS_ERROR,
  GET_WITHDRAWALS_NEXT_REQUEST,
  GET_WITHDRAWALS_NEXT,
  GET_WITHDRAWALS_ADDRESSES,
  UPDATE_WITHDRAWALS,
  ADD_NEW_WITHDRAWAL,
  ERROR_WITHDRAWAL,
  GET_NCW_ADDRESSES,
  ADD_NEW_WITHDRAWAL_ADDRESS,
  UPDATE_MOB_WITHDRAWALS,
  ADD_HSH_WITHDRAWAL,
  CHANGE_WITHDRAWAL_STATUS,
} from '../constant';
import { Withdrawal } from '../../backend/JsonRpcApi';

export type WithdrawAddresses = {
  address: string;
  is_ncw?: boolean;
  is_local?: boolean;
};

export type WithdrawalsState = {
  isLoading: boolean;
  isNextWithdrawalsLoading: boolean;
  forceRequest: boolean;
  hasRequested: boolean;
  isError: boolean;
  withdrawals: Withdrawal[];
  mob_withdrawals: Withdrawal[];
  withdrawalsToShow: Withdrawal[];
  withdrawalsAddresses: WithdrawAddresses[];
  isAddedWithdrawalHSH: boolean;
  index: number;
  perPage: number;
  total: number;
  limit: number;
  errorWithdrawal: string;
};

const initialState: WithdrawalsState = {
  isLoading: false,
  isNextWithdrawalsLoading: false,
  forceRequest: false,
  hasRequested: false,
  isError: false,
  withdrawals: [],
  withdrawalsToShow: [],
  withdrawalsAddresses: [],
  isAddedWithdrawalHSH: false,
  index: 0,
  perPage: 10,
  limit: 0,
  total: 0,
  errorWithdrawal: 'Allert.Wrong',
  mob_withdrawals: [],
};

export const withdrawalsReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case GET_WITHDRAWALS_FORCE_REQUEST:
      return {
        ...state,
        forceRequest: true,
      };
    case GET_WITHDRAWALS_REQUEST:
      return {
        ...state,
        hasRequested: false,
        isLoading: true,
      };
    case GET_WITHDRAWALS_SUCCESS:
      return {
        ...state,
        isError: false,
        isLoading: false,
        hasRequested: true,
        forceRequest: false,
        isAddedWithdrawalHSH: false,
        withdrawals: action.withdrawals,
        limit: action.limit,
      };
    case GET_WITHDRAWALS_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        hasRequested: false,
        forceRequest: false,
      };
    case GET_WITHDRAWALS_NEXT_REQUEST:
      return {
        ...state,
        isNextWithdrawalsLoading: true,
      };
    case GET_WITHDRAWALS_NEXT:
      return {
        ...state,
        withdrawalsToShow: action.withdrawalsToShow,
        index: action.index,
        isNextWithdrawalsLoading: false,
        total: action.total,
      };
    case GET_WITHDRAWALS_ADDRESSES: {
      return {
        ...state,
        withdrawalsAddresses: action.withdrawalsAddresses || [],
      };
    }
    case UPDATE_WITHDRAWALS: {
      return {
        ...state,
        withdrawals: state.withdrawals.map((item) => (item.id === action.id ? { ...item, state: action.state } : item)),
      };
    }
    case UPDATE_MOB_WITHDRAWALS: {
      return {
        ...state,
        mob_withdrawals:
          action.index === 0
            ? action.withdrawalsToShow
            : [...state.mob_withdrawals, ...(action.withdrawalsToShow || [])],
        index: action.index,
        isNextWithdrawalsLoading: false,
        total: action.total,
      };
    }
    case ADD_NEW_WITHDRAWAL: {
      return {
        ...state,
        withdrawals: [action.newWithdrawal, ...state.withdrawals],
        limit: state.limit + 1,
      };
    }
    case ADD_HSH_WITHDRAWAL: {
      return {
        ...state,
        isAddedWithdrawalHSH: true,
      };
    }
    case ERROR_WITHDRAWAL: {
      return {
        ...state,
        errorWithdrawal: action.error,
      };
    }
    case GET_NCW_ADDRESSES: {
      return {
        ...state,
        ncwAddressess: action.ncwAddresses,
      };
    }
    case ADD_NEW_WITHDRAWAL_ADDRESS: {
      const isInclude = !!state.withdrawalsAddresses.find((address) => {
        return address.address == action.newWithdrawAddress?.address;
      });
      return isInclude
        ? state
        : {
            ...state,
            withdrawalsAddresses: [action.newWithdrawAddress, ...state.withdrawalsAddresses],
          };
    }
    case CHANGE_WITHDRAWAL_STATUS: {
      return {
        ...state,
        withdrawalsToShow: state.withdrawalsToShow.map((withdrawal: Withdrawal) => {
          return withdrawal.id === action?.updatableWithdrawal?.id
            ? { ...withdrawal, state: action?.updatableWithdrawal?.state }
            : withdrawal;
        }),
      };
    }
    default:
      return state;
  }
};

export type ActionType = {
  type: string;
  withdrawals?: Withdrawal[];
  withdrawalsToShow?: Withdrawal[];
  newWithdrawal?: Withdrawal;
  index: number;
  limit?: number;
  withdrawalsAddresses?: string[];
  newWithdrawAddress?: WithdrawAddresses;
  id?: string;
  state?: number;
  total?: number;
  error?: string;
  ncwAddresses?: string[];
  hshAddresses?: string;
  updatableWithdrawal?: Withdrawal;
};

export const ErrorWithdrawal = {
  1006: 'Allert.Invalid',
  11000: 'Allert.Capcha',
  7003: 'You have reached the limit of 10 simultaneous withdrawals. To request a new one, please wait for the current withdrawal to be completed or cancel it.',
  7004: 'Allert.Amount',
};
