import styles from './LinksView.module.scss';
import { Card } from '@shared/Card/Card';
import { ReactComponent as Refresh } from '@assets/refresh.svg';
import { DatePicker } from '@shared/DatePicker/DatePicker';
import { LinkAcc } from '@service/reducers/balanceReducer';
import { ReactComponent as Arrow } from '@assets/colorless/arrowRight.svg';
import { Button } from '@shared/Button/Button';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Root } from '@service/reducers';
import LinksRow from '@shared/LinksView/LinksRow';
import LinksTotal, { LinksTotalInfo } from '@shared/LinksView/LinksTotal';
import { AddLinkButton } from '@shared/AddLinkButton/AddLinkButton';
import classNames from 'classnames';

type Period = { start: string; end: string };

type StatisticLinksInfo = {
  path: string;
  title: string;
};

export type ProjectType = 'browser' | 'pool' | 'farm';
export type LinksViewProps = {
  count: number;
  handleRefresh: () => void;
  projectType: ProjectType;
  link: string;
  landLink: string;
  onLinkCreate: () => void;
  period: Period;
  totalInfo: LinksTotalInfo;
  onPeriodChange: (period: { period_start: string; period_end: string }) => void;
  linksStatisticInfo: StatisticLinksInfo;
  isLoading?: boolean;
};

export const LinkTableHeader = ({ title }: { title?: string }) => {
  const { t } = useTranslation();

  return (
    <div className={`${styles.row} ${styles.row__header}`}>
      <span>{title || t('Link ID / Code')}</span>
      <span>{t('Copy')}</span>
      <span>{t('Comment')}</span>
      <span>{t('Opens')}</span>
      <span>{t('Referrals')}</span>
      <span>{t('Active Miners')}</span>
      <span>{t('Earnings')}</span>
    </div>
  );
};

export default function LinksView(props: LinksViewProps) {
  const { t } = useTranslation();
  const { balance } = useSelector((store: Root) => store);
  const {
    count,
    onLinkCreate,
    link,
    landLink,
    projectType,
    handleRefresh,
    period,
    onPeriodChange,
    totalInfo,
    linksStatisticInfo,
    isLoading,
  } = props;
  return (
    <>
      <div className={styles.wrapper}>
        <Card
          className={styles.card}
          headerClass={styles.card__header}
          TitleElement={
            <div className={styles.title}>
              <span className={styles.title__link}>
                <span>{t('menu.LinksAndCodes')}</span>
              </span>
              <span className={styles.title__count}>{count}</span>
              <Button
                type="button"
                onClick={handleRefresh}
                Icon={<Refresh />}
                onlyIcon
                className={classNames(styles.refresh, { [styles.loading]: isLoading })}
              />
            </div>
          }
          Switch={<DatePicker period={period} onPeriodChange={onPeriodChange} />}
        >
          <div className={styles.body}>
            <div className={styles.table}>
              <LinkTableHeader />
              {balance.links?.map(
                (linkAcc: LinkAcc) =>
                  linkAcc.type !== 'farm' && (
                    <LinksRow
                      {...linkAcc}
                      projectType={projectType}
                      name={linkAcc.name}
                      link={link}
                      landLink={landLink}
                      key={linkAcc.id}
                    />
                  )
              )}
              <LinksTotal info={totalInfo} />
            </div>
          </div>
        </Card>
        <div className={styles.footer}>
          <AddLinkButton btnClassName={styles.link_btn} onLinkCreate={onLinkCreate} />
          <Button
            type="link"
            Icon={<Arrow />}
            size="medium"
            iconPosition={'right'}
            to={linksStatisticInfo.path}
            className={styles.link_btn}
          >
            {t(linksStatisticInfo.title)}
          </Button>
        </div>
      </div>
    </>
  );
}
