export default function quickObjectSort(array, params) {
  if (array.length <= 1) {
    return array;
  }
  let pivotIndex = Math.floor(array.length / 2);
  let pivot = array[pivotIndex];
  let less = [];
  let greater = [];
  for (let i = 0; i < array.length; i++) {
    if (i === pivotIndex) continue;
    if (array[i][params] > pivot[params]) {
      less.push(array[i]);
    } else {
      greater.push(array[i]);
    }
  }
  return [...quickObjectSort(less, params), pivot, ...quickObjectSort(greater, params)];
}
