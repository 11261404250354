import React, { FC } from 'react';
import { Withdrawal } from '../../../backend/JsonRpcApi';
import styles from './WithdrawalCancelModal.module.scss';
import { Button, ButtonVariant, ModalOverlay } from '@shared/index';
import { ReactComponent as CloseSvg } from './assets/close.svg';
import { useTranslation } from 'react-i18next';
import checkE from '@utils/checkE';
import { convertBigValueToLocale } from '@utils/convertBigValueToLocale';

type WithdrawalCancelModalProps = {
  withdrawal: Withdrawal;
  isVisible: boolean;
  onClose: () => void;
  onCancel: () => void;
  isBitcoin?: boolean;
};

export const WithdrawalCancelModal: FC<WithdrawalCancelModalProps> = (props) => {
  const { t } = useTranslation();
  const { withdrawal, isVisible, onClose, onCancel, isBitcoin } = props;

  return (
    <ModalOverlay onClick={onClose} active={isVisible}>
      <div className={styles.withdrawalModal}>
        <div className={styles.withdrawalModal__body}>
          <CloseSvg className={styles.withdrawalModal__body__icon} />
          <h2 className={styles.withdrawalModal__body__header}>{t('Cancel Withdrawal')}</h2>
          <p className={styles.withdrawalModal__body__description}>
            {t(
              'If you’d like to cancel this %(amount)s %(coin)s withdrawal please confirm your action by clicking the button below.',
              {
                amount: isBitcoin ? checkE(+withdrawal.amount) : convertBigValueToLocale(+withdrawal.amount),
                coin: isBitcoin ? 'BTC' : 'HSH',
              }
            )}
          </p>
        </div>
        <div className={styles.withdrawalModal__footer}>
          <Button type={'button'} size="medium" onClick={onClose} className={styles.withdrawalModal__btn}>
            {t('Close')}
          </Button>
          <Button
            type={'button'}
            onClick={() => onCancel()}
            className={styles.withdrawalModal__btn}
            size="medium"
            variant={ButtonVariant.Primary}
          >
            {t('Confirm')}
          </Button>
        </div>
      </div>
    </ModalOverlay>
  );
};
