import React, { FC, PropsWithChildren } from 'react';
import { ButtonProps } from '../Button/Button';
import styles from './ButtonGroup.module.scss';

export type ButtonGroupProps = PropsWithChildren<{
  children: ButtonProps[];
  className?: string;
}>;

export const ButtonGroup: FC<ButtonGroupProps> = ({ children, className, ...props }) => {
  return (
    <div className={`${styles.buttonGroup} ${className}`} {...props}>
      {children}
    </div>
  );
};
