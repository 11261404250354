import { BrowserAboutView } from './view/BrowserAboutView';
import { BrowserSubheader } from '../headersComponents/BrowserSubheader';
import { HelmetHead } from '../headersComponents/HelmetHead';
import { useTranslation } from 'react-i18next';
import { product } from '@utils/productName';

export default function BrowserAbout() {
  const { t } = useTranslation();
  return (
    <>
      <HelmetHead title={t('About')} product={product.ct} />
      <BrowserSubheader />
      <BrowserAboutView />
    </>
  );
}
