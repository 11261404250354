import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getPromos from '@service/actions/getPromos';
import { Root } from '@service/reducers';

export default function usePromoLoad() {
  const dispatch = useDispatch();
  const promo = useSelector((store: Root) => store.promo);
  const user = useSelector((store: Root) => store.user);
  useEffect(() => {
    if (user.id && !promo.promo_get) {
      dispatch(getPromos());
    }
  }, [dispatch, promo.promo_get, user.tokens]);

  return { promo };
}
