import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App/App';
import './styles/_global.scss';
import './styles/scss/utils.scss';
import './utils/share';
import './utils/i18n';
import { BrowserRouter as Router } from 'react-router-dom';
import thunk from 'redux-thunk';
import { ThunkAction } from 'redux-thunk';
import { createStore, applyMiddleware, ActionCreator, Action } from 'redux';
import { Provider } from 'react-redux';
import { rootReducer } from './service/reducers';
import i18next from 'i18next';
import * as Sentry from '@sentry/react';
import { Backend, rpc } from './backend/Rpc';
import { JsonRpc } from './backend';
import { JsonRpcApi, JsonRpcApiMethods } from './backend/JsonRpcApi';
import { build, version } from './constants/version';
import { apiClient } from './api/apiClient';
import getApiUrlParams from './utils/getApiUrlParam';
import { watchLocalStorage } from './utils/storage';
import { error } from './utils/log';
import { INTERNAL_ERROR } from '@service/constant';
import { composeWithDevTools } from 'redux-devtools-extension';
import refreshUser from '@service/actions/refreshUser';

const langs = ['en', 'ru', 'fr', 'it', 'de', 'es', 'pt'];

const enhancer = composeWithDevTools(applyMiddleware(thunk));

export const store = createStore(rootReducer, enhancer);
const storedLand =
  window.localStorage.getItem('lang') ||
  (langs.includes(navigator.language.split('-')[0].toLowerCase())
    ? navigator.language.split('-')[0].toLowerCase()
    : 'en');
i18next.changeLanguage(storedLand).then(() => (document.documentElement.lang = storedLand));
export type rootStoreType = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<TReturn = void> = ActionCreator<ThunkAction<TReturn, Action, rootStoreType, Action>>;

Sentry.init({
  dsn: window.app_config.sentryDSN,
  tracesSampleRate: 0.1,
  release: `${version + '.' + build}`,
});

async function watchDeviceId(): Promise<string> {
  const did = await watchLocalStorage('did', 3000);
  return did ?? '';
}

async function getDeviceId() {
  let did = localStorage.getItem('did');
  if (did === 'undefined') {
    localStorage.removeItem('did');
    did = null;
  }
  while (!did) {
    const did_ts = localStorage.getItem('did_loading');
    if (!did_ts || isNaN(parseInt(did_ts)) || Date.now() - parseInt(did_ts) > 10000) {
      localStorage.setItem('did_loading', '' + Date.now());
      try {
        const urlToken = window.app_config.baseAPIURL + 'devices/register' + getApiUrlParams();
        const response: any = await new apiClient().post(urlToken);
        if (response && response.did) {
          did = '' + response.did;
          localStorage.setItem('did', did);
        }
      } catch (e) {
        error('register device fail', e);
      } finally {
        localStorage.removeItem('did_loading');
      }
    } else {
      try {
        did = await watchDeviceId();
      } catch (e) {
        error('register device fail', e);
      }
    }
  }
  return did ?? '';
}

async function createBackend(refreshUser: () => void): Promise<Backend> {
  JsonRpc.debug = false;
  const did = await getDeviceId();
  const URL =
    window.app_config.baseWebsocketURL + `?av=${version}.${build}&v=1.0.0&app_id=affiliate&platform=web&bid=${did}`;
  const backend = new JsonRpc<JsonRpcApi, JsonRpcApiMethods>(URL, refreshUser);
  window.rpc = backend;
  return backend;
}

export async function init(): Promise<void> {
  const backend = await createBackend(refreshUser);
  await rpc.init(backend);
  rpc.interceptors.error.use((e) => {
    if (e.code === -32603) {
      store.dispatch({ type: INTERNAL_ERROR, payload: e });
    }
  });
  rpc.onmethod('app.reload', async () => {
    window.location.reload();
  });
  window.rpc = rpc;
}

init().then(() => {
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
  root.render(
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  );
});
