import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { rootUser } from '@service/reducers/userReducer';
import { EmailConfirmationForm } from './EmailConfirmationForm';
import { EmailAlreadyConfirmed } from './EmailAlreadyConfirmed';
import { EmailConfirmationSent } from './EmailConfirmationSent';
import styles from './EmailConfirmation.module.scss';
import { UsersMeSetEmailError } from '../../backend/JsonRpcApi';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { BROWSER_DASH } from '@utils/links';
import { useHistory } from 'react-router-dom';
import { HelmetHead } from '@pages/headersComponents/HelmetHead';

const EmailConfirmationContent = () => {
  const user = useSelector((store: { user: rootUser }) => store.user);
  const [isEmailVerified, setIsEmailVerified] = useState(user.email_verified);
  const { t } = useTranslation();

  const [email, setEmail] = useState(user.email);
  const [isConfirmationSent, setIsConfirmationSent] = useState(false);

  const history = useHistory();

  const onGoBack = () => {
    if (history.length > 2) {
      history.goBack();
      return;
    }
    history.push(BROWSER_DASH);
  };

  const onSubmitted = (email: string) => {
    setEmail(email);
    setIsConfirmationSent(true);
  };

  const onError = (code: number) => {
    switch (code) {
      case UsersMeSetEmailError.EmailAlreadyConfirmed:
        setIsEmailVerified(true);
        break;
      case UsersMeSetEmailError.Throttled:
        toast.error(t('TooManyRequests'));
        break;
      default:
        toast.error(t('Something Went Wrong'));
        break;
    }
  };

  if (isConfirmationSent) return <EmailConfirmationSent email={email} onGoBack={onGoBack} />;
  if (isEmailVerified) return <EmailAlreadyConfirmed email={email} onGoBack={onGoBack} />;

  return <EmailConfirmationForm email={email} onSubmitted={onSubmitted} onError={onError} onGoBack={onGoBack} />;
};

export const EmailConfirmation = () => {
  const { t } = useTranslation();
  return (
    <>
      <HelmetHead title={t('Aff.Verify.Title')} />
      <div className={styles.pageContainer}>
        <EmailConfirmationContent />
      </div>
    </>
  );
};
