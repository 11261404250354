export const pool_bot = {
  v: '5.7.11',
  fr: 30,
  ip: 0,
  op: 150,
  w: 842,
  h: 524,
  nm: 'about',
  ddd: 0,
  assets: [
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Group 13',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.4], y: [1] },
                  o: { x: [0.525], y: [0] },
                  t: -15,
                  s: [0],
                  e: [360],
                },
                { t: 15 },
              ],
              ix: 10,
            },
            p: { a: 0, k: [605.611, 150.187, 0], ix: 2, l: 2 },
            a: { a: 0, k: [426.469, 10.63, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.268, 0.268, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: -15,
                  s: [0, 0, 100],
                  e: [100, 100, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.799, 0.799, 0.333], y: [0, 0, 0] },
                  t: 0,
                  s: [100, 100, 100],
                  e: [0, 0, 100],
                },
                { t: 15 },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.964, -0.408],
                        [1.642, 1.049],
                        [1.358, 0.717],
                        [-0.56, 1.43],
                        [-0.407, 1.955],
                        [1.041, -1.643],
                        [0.717, -1.357],
                        [1.439, 0.559],
                        [1.955, 0.408],
                        [-1.643, -1.041],
                        [-1.357, -0.718],
                        [0.56, -1.43],
                        [0.408, -1.963],
                        [-1.041, 1.642],
                        [-0.717, 1.358],
                        [-1.431, -0.559],
                      ],
                      o: [
                        [-1.5, -1.334],
                        [-1.307, -0.82],
                        [0.662, -1.387],
                        [0.691, -1.822],
                        [-1.325, 1.501],
                        [-0.819, 1.306],
                        [-1.388, -0.662],
                        [-1.821, -0.693],
                        [1.501, 1.326],
                        [1.306, 0.82],
                        [-0.662, 1.386],
                        [-0.691, 1.814],
                        [1.334, -1.5],
                        [0.819, -1.307],
                        [1.379, 0.662],
                        [1.821, 0.693],
                      ],
                      v: [
                        [10.38, 5.211],
                        [5.626, 1.733],
                        [1.623, -0.544],
                        [3.475, -4.758],
                        [5.212, -10.38],
                        [1.741, -5.625],
                        [-0.535, -1.623],
                        [-4.759, -3.474],
                        [-10.38, -5.212],
                        [-5.624, -1.742],
                        [-1.622, 0.536],
                        [-3.474, 4.75],
                        [-5.212, 10.38],
                        [-1.741, 5.625],
                        [0.535, 1.622],
                        [4.751, 3.473],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [426.469, 10.63], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: -15,
          op: 46,
          st: -15,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Group 10',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.4], y: [1] },
                  o: { x: [0.525], y: [0] },
                  t: 135,
                  s: [0],
                  e: [360],
                },
                { t: 165 },
              ],
              ix: 10,
            },
            p: { a: 0, k: [605.611, 150.187, 0], ix: 2, l: 2 },
            a: { a: 0, k: [426.469, 10.63, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.268, 0.268, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 135,
                  s: [0, 0, 100],
                  e: [100, 100, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.799, 0.799, 0.333], y: [0, 0, 0] },
                  t: 150,
                  s: [100, 100, 100],
                  e: [0, 0, 100],
                },
                { t: 165 },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.964, -0.408],
                        [1.642, 1.049],
                        [1.358, 0.717],
                        [-0.56, 1.43],
                        [-0.407, 1.955],
                        [1.041, -1.643],
                        [0.717, -1.357],
                        [1.439, 0.559],
                        [1.955, 0.408],
                        [-1.643, -1.041],
                        [-1.357, -0.718],
                        [0.56, -1.43],
                        [0.408, -1.963],
                        [-1.041, 1.642],
                        [-0.717, 1.358],
                        [-1.431, -0.559],
                      ],
                      o: [
                        [-1.5, -1.334],
                        [-1.307, -0.82],
                        [0.662, -1.387],
                        [0.691, -1.822],
                        [-1.325, 1.501],
                        [-0.819, 1.306],
                        [-1.388, -0.662],
                        [-1.821, -0.693],
                        [1.501, 1.326],
                        [1.306, 0.82],
                        [-0.662, 1.386],
                        [-0.691, 1.814],
                        [1.334, -1.5],
                        [0.819, -1.307],
                        [1.379, 0.662],
                        [1.821, 0.693],
                      ],
                      v: [
                        [10.38, 5.211],
                        [5.626, 1.733],
                        [1.623, -0.544],
                        [3.475, -4.758],
                        [5.212, -10.38],
                        [1.741, -5.625],
                        [-0.535, -1.623],
                        [-4.759, -3.474],
                        [-10.38, -5.212],
                        [-5.624, -1.742],
                        [-1.622, 0.536],
                        [-3.474, 4.75],
                        [-5.212, 10.38],
                        [-1.741, 5.625],
                        [0.535, 1.622],
                        [4.751, 3.473],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [426.469, 10.63], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 135,
          op: 196,
          st: 135,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Group 9',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.4], y: [1] },
                  o: { x: [0.525], y: [0] },
                  t: 120,
                  s: [0],
                  e: [360],
                },
                { t: 150 },
              ],
              ix: 10,
            },
            p: { a: 0, k: [191.011, 167.588, 0], ix: 2, l: 2 },
            a: { a: 0, k: [11.869, 28.03, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.268, 0.268, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 120,
                  s: [0, 0, 100],
                  e: [100, 100, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.799, 0.799, 0.333], y: [0, 0, 0] },
                  t: 135,
                  s: [100, 100, 100],
                  e: [0, 0, 100],
                },
                { t: 150 },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.198, -0.457],
                        [1.837, 1.174],
                        [1.519, 0.803],
                        [-0.625, 1.6],
                        [-0.456, 2.189],
                        [1.165, -1.839],
                        [0.802, -1.52],
                        [1.609, 0.626],
                        [2.189, 0.456],
                        [-1.838, -1.164],
                        [-1.519, -0.803],
                        [0.626, -1.6],
                        [0.457, -2.198],
                        [-1.165, 1.838],
                        [-0.802, 1.519],
                        [-1.6, -0.625],
                      ],
                      o: [
                        [-1.679, -1.493],
                        [-1.463, -0.918],
                        [0.741, -1.552],
                        [0.776, -2.04],
                        [-1.485, 1.68],
                        [-0.918, 1.462],
                        [-1.553, -0.741],
                        [-2.04, -0.775],
                        [1.68, 1.483],
                        [1.462, 0.918],
                        [-0.741, 1.553],
                        [-0.774, 2.03],
                        [1.493, -1.679],
                        [0.918, -1.463],
                        [1.545, 0.74],
                        [2.039, 0.776],
                      ],
                      v: [
                        [11.619, 5.834],
                        [6.298, 1.941],
                        [1.818, -0.609],
                        [3.889, -5.326],
                        [5.834, -11.619],
                        [1.95, -6.296],
                        [-0.599, -1.816],
                        [-5.326, -3.889],
                        [-11.619, -5.833],
                        [-6.296, -1.95],
                        [-1.816, 0.6],
                        [-3.888, 5.318],
                        [-5.833, 11.619],
                        [-1.949, 6.297],
                        [0.599, 1.817],
                        [5.318, 3.888],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [11.869, 28.03], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 120,
          op: 196,
          st: 120,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Group 8',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.4], y: [1] },
                  o: { x: [0.525], y: [0] },
                  t: 105,
                  s: [0],
                  e: [360],
                },
                { t: 135 },
              ],
              ix: 10,
            },
            p: { a: 0, k: [305.583, 203.158, 0], ix: 2, l: 2 },
            a: { a: 0, k: [126.441, 63.6, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.268, 0.268, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 105,
                  s: [0, 0, 100],
                  e: [100, 100, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.799, 0.799, 0.333], y: [0, 0, 0] },
                  t: 120,
                  s: [100, 100, 100],
                  e: [0, 0, 100],
                },
                { t: 135 },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.628, -0.546],
                        [2.196, 1.404],
                        [1.818, 0.96],
                        [-0.746, 1.913],
                        [-0.546, 2.617],
                        [1.392, -2.198],
                        [0.96, -1.816],
                        [1.924, 0.749],
                        [2.617, 0.545],
                        [-2.198, -1.393],
                        [-1.818, -0.96],
                        [0.747, -1.914],
                        [0.547, -2.628],
                        [-1.393, 2.198],
                        [-0.96, 1.816],
                        [-1.912, -0.747],
                      ],
                      o: [
                        [-2.007, -1.785],
                        [-1.749, -1.098],
                        [0.886, -1.856],
                        [0.929, -2.439],
                        [-1.773, 2.009],
                        [-1.098, 1.749],
                        [-1.858, -0.886],
                        [-2.439, -0.927],
                        [2.01, 1.774],
                        [1.749, 1.098],
                        [-0.887, 1.856],
                        [-0.927, 2.428],
                        [1.784, -2.008],
                        [1.097, -1.749],
                        [1.846, 0.885],
                        [2.439, 0.928],
                      ],
                      v: [
                        [13.893, 6.975],
                        [7.531, 2.32],
                        [2.173, -0.729],
                        [4.65, -6.369],
                        [6.976, -13.894],
                        [2.332, -7.529],
                        [-0.716, -2.173],
                        [-6.369, -4.65],
                        [-13.893, -6.976],
                        [-7.529, -2.331],
                        [-2.171, 0.717],
                        [-4.649, 6.359],
                        [-6.975, 13.894],
                        [-2.331, 7.529],
                        [0.717, 2.173],
                        [6.358, 4.648],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [126.441, 63.6], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 105,
          op: 196,
          st: 105,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Group 7',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.4], y: [1] },
                  o: { x: [0.525], y: [0] },
                  t: 90,
                  s: [0],
                  e: [360],
                },
                { t: 120 },
              ],
              ix: 10,
            },
            p: { a: 0, k: [261.011, 302.588, 0], ix: 2, l: 2 },
            a: { a: 0, k: [81.869, 163.03, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.268, 0.268, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 90,
                  s: [0, 0, 100],
                  e: [100, 100, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.799, 0.799, 0.333], y: [0, 0, 0] },
                  t: 105,
                  s: [100, 100, 100],
                  e: [0, 0, 100],
                },
                { t: 120 },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.198, -0.457],
                        [1.837, 1.174],
                        [1.519, 0.803],
                        [-0.625, 1.6],
                        [-0.456, 2.189],
                        [1.165, -1.839],
                        [0.802, -1.52],
                        [1.609, 0.626],
                        [2.189, 0.456],
                        [-1.838, -1.164],
                        [-1.519, -0.803],
                        [0.626, -1.6],
                        [0.457, -2.198],
                        [-1.165, 1.838],
                        [-0.802, 1.519],
                        [-1.6, -0.625],
                      ],
                      o: [
                        [-1.679, -1.493],
                        [-1.463, -0.918],
                        [0.741, -1.552],
                        [0.776, -2.04],
                        [-1.485, 1.68],
                        [-0.918, 1.462],
                        [-1.553, -0.741],
                        [-2.04, -0.775],
                        [1.68, 1.483],
                        [1.462, 0.918],
                        [-0.741, 1.553],
                        [-0.774, 2.03],
                        [1.493, -1.679],
                        [0.918, -1.463],
                        [1.545, 0.74],
                        [2.039, 0.776],
                      ],
                      v: [
                        [11.619, 5.834],
                        [6.298, 1.941],
                        [1.818, -0.609],
                        [3.889, -5.326],
                        [5.834, -11.619],
                        [1.95, -6.296],
                        [-0.599, -1.816],
                        [-5.326, -3.889],
                        [-11.619, -5.833],
                        [-6.296, -1.95],
                        [-1.816, 0.6],
                        [-3.888, 5.318],
                        [-5.833, 11.619],
                        [-1.949, 6.297],
                        [0.599, 1.817],
                        [5.318, 3.888],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [81.869, 163.03], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 90,
          op: 196,
          st: 90,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'Group 1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.4], y: [1] },
                  o: { x: [0.525], y: [0] },
                  t: 75,
                  s: [0],
                  e: [360],
                },
                { t: 105 },
              ],
              ix: 10,
            },
            p: { a: 0, k: [541.761, 242.019, 0], ix: 2, l: 2 },
            a: { a: 0, k: [362.619, 102.461, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.268, 0.268, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 75,
                  s: [0, 0, 100],
                  e: [100, 100, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.799, 0.799, 0.333], y: [0, 0, 0] },
                  t: 90,
                  s: [100, 100, 100],
                  e: [0, 0, 100],
                },
                { t: 105 },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.198, -0.456],
                        [1.837, 1.174],
                        [1.519, 0.803],
                        [-0.625, 1.6],
                        [-0.456, 2.189],
                        [1.165, -1.838],
                        [0.802, -1.519],
                        [1.609, 0.626],
                        [2.189, 0.456],
                        [-1.838, -1.165],
                        [-1.519, -0.803],
                        [0.626, -1.6],
                        [0.457, -2.198],
                        [-1.165, 1.839],
                        [-0.803, 1.52],
                        [-1.6, -0.625],
                      ],
                      o: [
                        [-1.679, -1.492],
                        [-1.463, -0.918],
                        [0.741, -1.553],
                        [0.776, -2.039],
                        [-1.484, 1.68],
                        [-0.918, 1.463],
                        [-1.553, -0.741],
                        [-2.04, -0.776],
                        [1.68, 1.484],
                        [1.462, 0.918],
                        [-0.741, 1.552],
                        [-0.774, 2.031],
                        [1.493, -1.679],
                        [0.918, -1.462],
                        [1.544, 0.741],
                        [2.039, 0.775],
                      ],
                      v: [
                        [11.619, 5.833],
                        [6.298, 1.94],
                        [1.818, -0.609],
                        [3.889, -5.327],
                        [5.834, -11.619],
                        [1.95, -6.297],
                        [-0.599, -1.817],
                        [-5.326, -3.889],
                        [-11.619, -5.834],
                        [-6.296, -1.95],
                        [-1.816, 0.6],
                        [-3.888, 5.317],
                        [-5.833, 11.619],
                        [-1.949, 6.296],
                        [0.6, 1.816],
                        [5.318, 3.888],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [362.619, 102.461], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 75,
          op: 106,
          st: 75,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'Group 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.4], y: [1] },
                  o: { x: [0.525], y: [0] },
                  t: 60,
                  s: [0],
                  e: [360],
                },
                { t: 90 },
              ],
              ix: 10,
            },
            p: { a: 0, k: [613.583, 322.158, 0], ix: 2, l: 2 },
            a: { a: 0, k: [434.441, 182.6, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.268, 0.268, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 60,
                  s: [0, 0, 100],
                  e: [100, 100, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.799, 0.799, 0.333], y: [0, 0, 0] },
                  t: 75,
                  s: [100, 100, 100],
                  e: [0, 0, 100],
                },
                { t: 90 },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.628, -0.546],
                        [2.196, 1.404],
                        [1.818, 0.96],
                        [-0.747, 1.913],
                        [-0.546, 2.617],
                        [1.392, -2.198],
                        [0.96, -1.816],
                        [1.924, 0.749],
                        [2.617, 0.545],
                        [-2.198, -1.393],
                        [-1.818, -0.96],
                        [0.747, -1.914],
                        [0.547, -2.628],
                        [-1.393, 2.198],
                        [-0.96, 1.816],
                        [-1.913, -0.747],
                      ],
                      o: [
                        [-2.007, -1.785],
                        [-1.749, -1.098],
                        [0.886, -1.856],
                        [0.929, -2.439],
                        [-1.773, 2.009],
                        [-1.098, 1.749],
                        [-1.858, -0.886],
                        [-2.439, -0.927],
                        [2.01, 1.774],
                        [1.749, 1.098],
                        [-0.887, 1.856],
                        [-0.927, 2.428],
                        [1.784, -2.008],
                        [1.097, -1.749],
                        [1.846, 0.885],
                        [2.439, 0.928],
                      ],
                      v: [
                        [13.893, 6.975],
                        [7.531, 2.32],
                        [2.173, -0.729],
                        [4.65, -6.369],
                        [6.976, -13.893],
                        [2.332, -7.529],
                        [-0.716, -2.173],
                        [-6.369, -4.65],
                        [-13.893, -6.975],
                        [-7.529, -2.331],
                        [-2.171, 0.717],
                        [-4.649, 6.359],
                        [-6.975, 13.894],
                        [-2.331, 7.529],
                        [0.717, 2.173],
                        [6.358, 4.648],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [434.441, 182.6], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 60,
          op: 106,
          st: 60,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: 'Group 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.4], y: [1] },
                  o: { x: [0.525], y: [0] },
                  t: 45,
                  s: [0],
                  e: [360],
                },
                { t: 75 },
              ],
              ix: 10,
            },
            p: { a: 0, k: [605.611, 150.187, 0], ix: 2, l: 2 },
            a: { a: 0, k: [426.469, 10.63, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.268, 0.268, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 45,
                  s: [0, 0, 100],
                  e: [100, 100, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.799, 0.799, 0.333], y: [0, 0, 0] },
                  t: 60,
                  s: [100, 100, 100],
                  e: [0, 0, 100],
                },
                { t: 75 },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.964, -0.408],
                        [1.642, 1.049],
                        [1.358, 0.717],
                        [-0.56, 1.43],
                        [-0.407, 1.955],
                        [1.041, -1.643],
                        [0.717, -1.357],
                        [1.439, 0.559],
                        [1.955, 0.408],
                        [-1.643, -1.041],
                        [-1.357, -0.718],
                        [0.56, -1.43],
                        [0.408, -1.963],
                        [-1.041, 1.642],
                        [-0.717, 1.358],
                        [-1.431, -0.559],
                      ],
                      o: [
                        [-1.5, -1.334],
                        [-1.307, -0.82],
                        [0.662, -1.387],
                        [0.691, -1.822],
                        [-1.325, 1.501],
                        [-0.819, 1.306],
                        [-1.388, -0.662],
                        [-1.821, -0.693],
                        [1.501, 1.326],
                        [1.306, 0.82],
                        [-0.662, 1.386],
                        [-0.691, 1.814],
                        [1.334, -1.5],
                        [0.819, -1.307],
                        [1.379, 0.662],
                        [1.821, 0.693],
                      ],
                      v: [
                        [10.38, 5.211],
                        [5.626, 1.733],
                        [1.623, -0.544],
                        [3.475, -4.758],
                        [5.212, -10.38],
                        [1.741, -5.625],
                        [-0.535, -1.623],
                        [-4.759, -3.474],
                        [-10.38, -5.212],
                        [-5.624, -1.742],
                        [-1.622, 0.536],
                        [-3.474, 4.75],
                        [-5.212, 10.38],
                        [-1.741, 5.625],
                        [0.535, 1.622],
                        [4.751, 3.473],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [426.469, 10.63], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 45,
          op: 106,
          st: 45,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: 'Group 4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.4], y: [1] },
                  o: { x: [0.525], y: [0] },
                  t: 30,
                  s: [0],
                  e: [360],
                },
                { t: 60 },
              ],
              ix: 10,
            },
            p: { a: 0, k: [191.011, 167.588, 0], ix: 2, l: 2 },
            a: { a: 0, k: [11.869, 28.03, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.268, 0.268, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 30,
                  s: [0, 0, 100],
                  e: [100, 100, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.799, 0.799, 0.333], y: [0, 0, 0] },
                  t: 45,
                  s: [100, 100, 100],
                  e: [0, 0, 100],
                },
                { t: 60 },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.198, -0.457],
                        [1.837, 1.174],
                        [1.519, 0.803],
                        [-0.625, 1.6],
                        [-0.456, 2.189],
                        [1.165, -1.839],
                        [0.802, -1.52],
                        [1.609, 0.626],
                        [2.189, 0.456],
                        [-1.838, -1.164],
                        [-1.519, -0.803],
                        [0.626, -1.6],
                        [0.457, -2.198],
                        [-1.165, 1.838],
                        [-0.802, 1.519],
                        [-1.6, -0.625],
                      ],
                      o: [
                        [-1.679, -1.493],
                        [-1.463, -0.918],
                        [0.741, -1.552],
                        [0.776, -2.04],
                        [-1.485, 1.68],
                        [-0.918, 1.462],
                        [-1.553, -0.741],
                        [-2.04, -0.775],
                        [1.68, 1.483],
                        [1.462, 0.918],
                        [-0.741, 1.553],
                        [-0.774, 2.03],
                        [1.493, -1.679],
                        [0.918, -1.463],
                        [1.545, 0.74],
                        [2.039, 0.776],
                      ],
                      v: [
                        [11.619, 5.834],
                        [6.298, 1.941],
                        [1.818, -0.609],
                        [3.889, -5.326],
                        [5.834, -11.619],
                        [1.95, -6.296],
                        [-0.599, -1.816],
                        [-5.326, -3.889],
                        [-11.619, -5.833],
                        [-6.296, -1.95],
                        [-1.816, 0.6],
                        [-3.888, 5.318],
                        [-5.833, 11.619],
                        [-1.949, 6.297],
                        [0.599, 1.817],
                        [5.318, 3.888],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [11.869, 28.03], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 30,
          op: 106,
          st: 30,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: 'Group 5',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.4], y: [1] },
                  o: { x: [0.525], y: [0] },
                  t: 15,
                  s: [0],
                  e: [360],
                },
                { t: 45 },
              ],
              ix: 10,
            },
            p: { a: 0, k: [305.583, 203.158, 0], ix: 2, l: 2 },
            a: { a: 0, k: [126.441, 63.6, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.268, 0.268, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 15,
                  s: [0, 0, 100],
                  e: [100, 100, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.799, 0.799, 0.333], y: [0, 0, 0] },
                  t: 30,
                  s: [100, 100, 100],
                  e: [0, 0, 100],
                },
                { t: 45 },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.628, -0.546],
                        [2.196, 1.404],
                        [1.818, 0.96],
                        [-0.746, 1.913],
                        [-0.546, 2.617],
                        [1.392, -2.198],
                        [0.96, -1.816],
                        [1.924, 0.749],
                        [2.617, 0.545],
                        [-2.198, -1.393],
                        [-1.818, -0.96],
                        [0.747, -1.914],
                        [0.547, -2.628],
                        [-1.393, 2.198],
                        [-0.96, 1.816],
                        [-1.912, -0.747],
                      ],
                      o: [
                        [-2.007, -1.785],
                        [-1.749, -1.098],
                        [0.886, -1.856],
                        [0.929, -2.439],
                        [-1.773, 2.009],
                        [-1.098, 1.749],
                        [-1.858, -0.886],
                        [-2.439, -0.927],
                        [2.01, 1.774],
                        [1.749, 1.098],
                        [-0.887, 1.856],
                        [-0.927, 2.428],
                        [1.784, -2.008],
                        [1.097, -1.749],
                        [1.846, 0.885],
                        [2.439, 0.928],
                      ],
                      v: [
                        [13.893, 6.975],
                        [7.531, 2.32],
                        [2.173, -0.729],
                        [4.65, -6.369],
                        [6.976, -13.894],
                        [2.332, -7.529],
                        [-0.716, -2.173],
                        [-6.369, -4.65],
                        [-13.893, -6.976],
                        [-7.529, -2.331],
                        [-2.171, 0.717],
                        [-4.649, 6.359],
                        [-6.975, 13.894],
                        [-2.331, 7.529],
                        [0.717, 2.173],
                        [6.358, 4.648],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [126.441, 63.6], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 15,
          op: 106,
          st: 15,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 4,
          nm: 'Group 6',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.4], y: [1] },
                  o: { x: [0.525], y: [0] },
                  t: 0,
                  s: [0],
                  e: [360],
                },
                { t: 30 },
              ],
              ix: 10,
            },
            p: { a: 0, k: [261.011, 302.588, 0], ix: 2, l: 2 },
            a: { a: 0, k: [81.869, 163.03, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.268, 0.268, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 0,
                  s: [0, 0, 100],
                  e: [100, 100, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.799, 0.799, 0.333], y: [0, 0, 0] },
                  t: 15,
                  s: [100, 100, 100],
                  e: [0, 0, 100],
                },
                { t: 30 },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.198, -0.457],
                        [1.837, 1.174],
                        [1.519, 0.803],
                        [-0.625, 1.6],
                        [-0.456, 2.189],
                        [1.165, -1.839],
                        [0.802, -1.52],
                        [1.609, 0.626],
                        [2.189, 0.456],
                        [-1.838, -1.164],
                        [-1.519, -0.803],
                        [0.626, -1.6],
                        [0.457, -2.198],
                        [-1.165, 1.838],
                        [-0.802, 1.519],
                        [-1.6, -0.625],
                      ],
                      o: [
                        [-1.679, -1.493],
                        [-1.463, -0.918],
                        [0.741, -1.552],
                        [0.776, -2.04],
                        [-1.485, 1.68],
                        [-0.918, 1.462],
                        [-1.553, -0.741],
                        [-2.04, -0.775],
                        [1.68, 1.483],
                        [1.462, 0.918],
                        [-0.741, 1.553],
                        [-0.774, 2.03],
                        [1.493, -1.679],
                        [0.918, -1.463],
                        [1.545, 0.74],
                        [2.039, 0.776],
                      ],
                      v: [
                        [11.619, 5.834],
                        [6.298, 1.941],
                        [1.818, -0.609],
                        [3.889, -5.326],
                        [5.834, -11.619],
                        [1.95, -6.296],
                        [-0.599, -1.816],
                        [-5.326, -3.889],
                        [-11.619, -5.833],
                        [-6.296, -1.95],
                        [-1.816, 0.6],
                        [-3.888, 5.318],
                        [-5.833, 11.619],
                        [-1.949, 6.297],
                        [0.599, 1.817],
                        [5.318, 3.888],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [81.869, 163.03], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 106,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_1',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'bag_back Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [738.251, 433.423, 0], ix: 2, l: 2 },
            a: { a: 0, k: [69.37, 74.044, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.725, 4.79],
                        [6.93, 2.341],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.468, 0.33],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.14, 0.276],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.085, -0.064],
                        [0.064, -1.149],
                        [0, 0],
                        [-0.224, -0.096],
                        [0.202, 0.053],
                        [0, 0],
                        [1.554, 0.372],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.277, -0.319],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.416, -0.352],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.959, 8.324],
                        [4.918, 2.522],
                      ],
                      o: [
                        [0.989, -6.547],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.448, -0.351],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.193, -0.267],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.223, 0.553],
                        [0, 0],
                        [0.148, 0.042],
                        [-0.182, -0.043],
                        [0, 0],
                        [-0.267, 0.66],
                        [0.053, 0.074],
                        [0, 0],
                        [0, 0],
                        [1.331, 0.33],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.491, 0.393],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [9.346, 1.746],
                        [2.384, -6.707],
                        [3.545, -0.82],
                      ],
                      v: [
                        [23.341, -6.11],
                        [12.344, -18.522],
                        [14.591, -27.39],
                        [9.108, -28.731],
                        [6.926, -20.098],
                        [2.529, -21.109],
                        [4.733, -29.806],
                        [-0.75, -31.148],
                        [-2.996, -22.28],
                        [-6.499, -23.089],
                        [-6.487, -23.121],
                        [-14.046, -24.974],
                        [-15.505, -19.214],
                        [-11.522, -18.235],
                        [-8.968, -15.095],
                        [-11.522, -4.992],
                        [-10.948, -4.811],
                        [-11.522, -4.95],
                        [-15.111, 9.198],
                        [-17.622, 10.486],
                        [-21.603, 9.507],
                        [-24.329, 15.681],
                        [-17.186, 17.427],
                        [-13.28, 18.416],
                        [-15.547, 27.391],
                        [-10.064, 28.732],
                        [-7.819, 19.854],
                        [-3.453, 20.971],
                        [-5.689, 29.807],
                        [-0.207, 31.148],
                        [2.061, 22.196],
                        [21.402, 14.904],
                        [16.366, 1.81],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.767, -7.004],
                        [3.715, 0.905],
                        [0, 0],
                      ],
                      o: [
                        [-1.693, 6.707],
                        [0, 0],
                        [3.705, 0.905],
                      ],
                      v: [
                        [10.811, 11.188],
                        [-6.061, 13.36],
                        [-3.051, 1.48],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.607, -6.355],
                        [3.098, 0.766],
                        [0, 0],
                      ],
                      o: [
                        [-1.545, 6.1],
                        [0, 0],
                        [3.097, 0.767],
                      ],
                      v: [
                        [12.504, -6.217],
                        [-1.676, -3.981],
                        [1.05, -14.755],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 1,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [66.021, 95.227], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.372, 0.681],
                        [1.523, 5.035],
                        [-1.012, -0.128],
                        [-0.052, -0.266],
                        [3.194, -5.367],
                        [0.915, 0.511],
                      ],
                      o: [
                        [2.396, -4.524],
                        [-0.298, -0.979],
                        [0.862, 0.096],
                        [2.182, 5.865],
                        [-0.426, 0.596],
                        [-0.671, -0.383],
                      ],
                      v: [
                        [-3.374, 6.967],
                        [-1.598, -7.436],
                        [-0.086, -9.266],
                        [1.564, -8.776],
                        [-0.129, 8.693],
                        [-2.821, 8.883],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [72.648, 41.984], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [8.421, 6.6],
                        [-0.607, -0.606],
                        [-3.268, -9.656],
                        [1.171, 0.032],
                      ],
                      o: [
                        [-3.354, -9.879],
                        [1.202, -0.597],
                        [7.633, 6.462],
                        [0.32, 1.129],
                        [0, 0],
                      ],
                      v: [
                        [6.76, 13.908],
                        [-10.411, -11.587],
                        [-6.803, -13.387],
                        [10.091, 11.758],
                        [8.367, 13.961],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [81.43, 45.137], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.021, 0],
                        [0, 0],
                        [0, 1.022],
                        [-1.022, 0],
                        [0, 0],
                        [0, -1.022],
                      ],
                      o: [
                        [0, 0],
                        [-1.022, 0],
                        [0, -1.022],
                        [0, 0],
                        [1.021, 0],
                        [0, 1.022],
                      ],
                      v: [
                        [13.477, 1.852],
                        [-13.476, 1.852],
                        [-15.329, 0],
                        [-13.476, -1.852],
                        [13.477, -1.852],
                        [15.329, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [67.731, 35.603], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.979, -0.117],
                        [0, 0],
                        [0.106, 0.98],
                        [0, 0],
                        [-0.978, 0.107],
                        [0, 0],
                        [-0.108, -0.979],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-0.981, 0.117],
                        [0, 0],
                        [-0.117, -0.979],
                        [0, 0],
                        [0.979, -0.117],
                        [0, 0],
                        [0.107, 0.969],
                      ],
                      v: [
                        [12.514, 0.415],
                        [-12.098, 3.226],
                        [-14.057, 1.661],
                        [-14.068, 1.544],
                        [-12.504, -0.415],
                        [12.109, -3.225],
                        [14.068, -1.66],
                        [14.078, -1.544],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [68.428, 32.175], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [0.862, -9.415],
                        [-7.41, 9.415],
                        [7.41, -9.415],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.819607902976, 0.466666696586, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [58.159, 45.796], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [2.502, 0.352],
                        [2.257, 0.585],
                        [0.267, -0.394],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [-2.501, -0.351],
                        [-0.447, -0.118],
                        [-0.202, 0.308],
                        [0, 0],
                      ],
                      v: [
                        [10.497, 13.807],
                        [3.471, -13.073],
                        [0.776, -13.073],
                        [-3.982, -14.009],
                        [-10.294, -14.009],
                        [8.079, 14.403],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.819607902976, 0.466666696586, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [53.018, 19.54], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 7',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [42.655, -9.815],
                        [23.217, 67.427],
                        [0, 0],
                        [0, 0],
                        [2.992, -0.959],
                        [5.067, 5.546],
                        [0, 0],
                        [0, 0],
                        [-5.779, -33.064],
                      ],
                      o: [
                        [36.045, -0.149],
                        [-12.615, -36.653],
                        [0, 0],
                        [1.319, -2.853],
                        [0, 0],
                        [0, 0],
                        [-2.278, -2.799],
                        [0, 0],
                        [3.375, 19.396],
                      ],
                      v: [
                        [-35.938, 67.348],
                        [12.721, 6.062],
                        [-25.284, -39.447],
                        [-8.271, -62.824],
                        [-12.659, -67.783],
                        [-27.593, -70.169],
                        [-28.018, -70.69],
                        [-31.33, -38.977],
                        [-7.911, 18.868],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.819607902976, 0.466666696586, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [102.551, 74.349], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 8',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 8,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-35.438, 0.244],
                        [23.219, 67.427],
                        [0, 0],
                        [0, 0],
                        [2.991, -0.959],
                        [1.598, -0.266],
                        [2.022, 2.502],
                        [0, 0],
                        [3.014, -1.991],
                        [0, 0],
                        [3.674, 1.234],
                        [0, 0],
                        [-0.597, -0.968],
                        [0, 0],
                        [11.911, -31.371],
                      ],
                      o: [
                        [36.045, -0.256],
                        [-12.613, -36.653],
                        [0, 0],
                        [1.319, -2.853],
                        [0, 0],
                        [-3.182, 0.522],
                        [0, 0],
                        [-2.278, -2.798],
                        [0, 0],
                        [-3.215, 2.139],
                        [-2.49, -0.841],
                        [-1.129, -0.139],
                        [0, 0],
                        [0, 0],
                        [-22.068, 58.187],
                      ],
                      v: [
                        [-2.757, 67.651],
                        [45.901, 6.366],
                        [7.898, -39.143],
                        [24.91, -62.52],
                        [20.523, -67.48],
                        [15.264, -66.608],
                        [5.589, -69.865],
                        [5.163, -70.388],
                        [-4.153, -71.802],
                        [-8.017, -69.237],
                        [-18.726, -68.949],
                        [-29.19, -70.184],
                        [-30.509, -68.12],
                        [-13.285, -39.899],
                        [-47.052, 6.355],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.968627510819, 0.576470588235, 0.101960791794, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [69.37, 74.043], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 9',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 9,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 150,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_2',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 0,
          nm: 'bitcoin_cycle',
          refId: 'comp_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [646.881, 349.958, 0], ix: 2, l: 2 },
            a: { a: 0, k: [210.381, 120.958, 0], ix: 1, l: 2 },
            s: { a: 0, k: [58, 58, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 842,
          h: 524,
          ip: -95,
          op: 55,
          st: -95,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 0,
          nm: 'bitcoin_cycle',
          refId: 'comp_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [696.381, 289.958, 0], ix: 2, l: 2 },
            a: { a: 0, k: [210.381, 120.958, 0], ix: 1, l: 2 },
            s: { a: 0, k: [91, 91, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 842,
          h: 524,
          ip: -100,
          op: 50,
          st: -100,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 0,
          nm: 'bitcoin_cycle',
          refId: 'comp_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [744.381, 184.958, 0], ix: 2, l: 2 },
            a: { a: 0, k: [210.381, 120.958, 0], ix: 1, l: 2 },
            s: { a: 0, k: [83, 83, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 842,
          h: 524,
          ip: -105,
          op: 45,
          st: -105,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 0,
          nm: 'bitcoin_cycle',
          refId: 'comp_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [647.381, 178.958, 0], ix: 2, l: 2 },
            a: { a: 0, k: [210.381, 120.958, 0], ix: 1, l: 2 },
            s: { a: 0, k: [58, 58, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 842,
          h: 524,
          ip: -110,
          op: 40,
          st: -110,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 0,
          nm: 'bitcoin_cycle',
          refId: 'comp_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [653.881, 99.458, 0], ix: 2, l: 2 },
            a: { a: 0, k: [210.381, 120.958, 0], ix: 1, l: 2 },
            s: { a: 0, k: [79, 79, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 842,
          h: 524,
          ip: -115,
          op: 35,
          st: -115,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 0,
          nm: 'bitcoin_cycle',
          refId: 'comp_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [527.381, 123.458, 0], ix: 2, l: 2 },
            a: { a: 0, k: [210.381, 120.958, 0], ix: 1, l: 2 },
            s: { a: 0, k: [96, 96, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 842,
          h: 524,
          ip: -120,
          op: 30,
          st: -120,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 0,
          nm: 'bitcoin_cycle',
          refId: 'comp_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [339.381, 97.958, 0], ix: 2, l: 2 },
            a: { a: 0, k: [210.381, 120.958, 0], ix: 1, l: 2 },
            s: { a: 0, k: [120, 120, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 842,
          h: 524,
          ip: -125,
          op: 25,
          st: -125,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 0,
          nm: 'bitcoin_cycle',
          refId: 'comp_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [343.381, 205.458, 0], ix: 2, l: 2 },
            a: { a: 0, k: [210.381, 120.958, 0], ix: 1, l: 2 },
            s: { a: 0, k: [55, 55, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 842,
          h: 524,
          ip: -130,
          op: 20,
          st: -130,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 0,
          nm: 'bitcoin_cycle',
          refId: 'comp_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [265.381, 179.958, 0], ix: 2, l: 2 },
            a: { a: 0, k: [210.381, 120.958, 0], ix: 1, l: 2 },
            s: { a: 0, k: [55, 55, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 842,
          h: 524,
          ip: -135,
          op: 15,
          st: -135,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 0,
          nm: 'bitcoin_cycle',
          refId: 'comp_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [138.381, 251.958, 0], ix: 2, l: 2 },
            a: { a: 0, k: [210.381, 120.958, 0], ix: 1, l: 2 },
            s: { a: 0, k: [124, 124, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 842,
          h: 524,
          ip: -140,
          op: 9.99999999999999,
          st: -140,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 0,
          nm: 'bitcoin_cycle',
          refId: 'comp_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [73.881, 161.458, 0], ix: 2, l: 2 },
            a: { a: 0, k: [210.381, 120.958, 0], ix: 1, l: 2 },
            s: { a: 0, k: [77, 77, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 842,
          h: 524,
          ip: -145,
          op: 5.00000000000001,
          st: -145,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 12,
          ty: 0,
          nm: 'bitcoin_cycle',
          refId: 'comp_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [646.881, 349.958, 0], ix: 2, l: 2 },
            a: { a: 0, k: [210.381, 120.958, 0], ix: 1, l: 2 },
            s: { a: 0, k: [58, 58, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 842,
          h: 524,
          ip: 55,
          op: 205,
          st: 55,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 13,
          ty: 0,
          nm: 'bitcoin_cycle',
          refId: 'comp_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [696.381, 289.958, 0], ix: 2, l: 2 },
            a: { a: 0, k: [210.381, 120.958, 0], ix: 1, l: 2 },
            s: { a: 0, k: [91, 91, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 842,
          h: 524,
          ip: 50,
          op: 200,
          st: 50,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 14,
          ty: 0,
          nm: 'bitcoin_cycle',
          refId: 'comp_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [744.381, 184.958, 0], ix: 2, l: 2 },
            a: { a: 0, k: [210.381, 120.958, 0], ix: 1, l: 2 },
            s: { a: 0, k: [83, 83, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 842,
          h: 524,
          ip: 45,
          op: 195,
          st: 45,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 15,
          ty: 0,
          nm: 'bitcoin_cycle',
          refId: 'comp_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [647.381, 178.958, 0], ix: 2, l: 2 },
            a: { a: 0, k: [210.381, 120.958, 0], ix: 1, l: 2 },
            s: { a: 0, k: [58, 58, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 842,
          h: 524,
          ip: 40,
          op: 190,
          st: 40,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 16,
          ty: 0,
          nm: 'bitcoin_cycle',
          refId: 'comp_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [653.881, 99.458, 0], ix: 2, l: 2 },
            a: { a: 0, k: [210.381, 120.958, 0], ix: 1, l: 2 },
            s: { a: 0, k: [79, 79, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 842,
          h: 524,
          ip: 35,
          op: 185,
          st: 35,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 17,
          ty: 0,
          nm: 'bitcoin_cycle',
          refId: 'comp_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [527.381, 123.458, 0], ix: 2, l: 2 },
            a: { a: 0, k: [210.381, 120.958, 0], ix: 1, l: 2 },
            s: { a: 0, k: [96, 96, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 842,
          h: 524,
          ip: 30,
          op: 180,
          st: 30,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 18,
          ty: 0,
          nm: 'bitcoin_cycle',
          refId: 'comp_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [339.381, 97.958, 0], ix: 2, l: 2 },
            a: { a: 0, k: [210.381, 120.958, 0], ix: 1, l: 2 },
            s: { a: 0, k: [120, 120, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 842,
          h: 524,
          ip: 25,
          op: 175,
          st: 25,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 19,
          ty: 0,
          nm: 'bitcoin_cycle',
          refId: 'comp_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [343.381, 205.458, 0], ix: 2, l: 2 },
            a: { a: 0, k: [210.381, 120.958, 0], ix: 1, l: 2 },
            s: { a: 0, k: [55, 55, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 842,
          h: 524,
          ip: 20,
          op: 170,
          st: 20,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 20,
          ty: 0,
          nm: 'bitcoin_cycle',
          refId: 'comp_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [265.381, 179.958, 0], ix: 2, l: 2 },
            a: { a: 0, k: [210.381, 120.958, 0], ix: 1, l: 2 },
            s: { a: 0, k: [55, 55, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 842,
          h: 524,
          ip: 15,
          op: 165,
          st: 15,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 21,
          ty: 0,
          nm: 'bitcoin_cycle',
          refId: 'comp_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [138.381, 251.958, 0], ix: 2, l: 2 },
            a: { a: 0, k: [210.381, 120.958, 0], ix: 1, l: 2 },
            s: { a: 0, k: [124, 124, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 842,
          h: 524,
          ip: 10,
          op: 160,
          st: 10,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 22,
          ty: 0,
          nm: 'bitcoin_cycle',
          refId: 'comp_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [73.881, 161.458, 0], ix: 2, l: 2 },
            a: { a: 0, k: [210.381, 120.958, 0], ix: 1, l: 2 },
            s: { a: 0, k: [77, 77, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 842,
          h: 524,
          ip: 5,
          op: 155,
          st: 5,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 23,
          ty: 0,
          nm: 'bitcoin_cycle',
          refId: 'comp_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [210.381, 120.958, 0], ix: 2, l: 2 },
            a: { a: 0, k: [210.381, 120.958, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 842,
          h: 524,
          ip: 0,
          op: 150,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_3',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 0,
          nm: 'bitcoin',
          refId: 'comp_4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 0,
                  s: [0],
                  e: [9],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 15,
                  s: [9],
                  e: [0],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 30,
                  s: [0],
                  e: [9],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 45,
                  s: [9],
                  e: [0],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 60,
                  s: [0],
                  e: [9],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 75,
                  s: [9],
                  e: [0],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 90,
                  s: [0],
                  e: [9],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 105,
                  s: [9],
                  e: [0],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 120,
                  s: [0],
                  e: [9],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 135,
                  s: [9],
                  e: [0],
                },
                { t: 150 },
              ],
              ix: 10,
            },
            p: { a: 0, k: [210.381, 120.958, 0], ix: 2, l: 2 },
            a: { a: 0, k: [210.381, 120.958, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 0,
                  s: [100, 100, 100],
                  e: [118, 118, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 15,
                  s: [118, 118, 100],
                  e: [100, 100, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 30,
                  s: [100, 100, 100],
                  e: [118, 118, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 45,
                  s: [118, 118, 100],
                  e: [100, 100, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 60,
                  s: [100, 100, 100],
                  e: [118, 118, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 75,
                  s: [118, 118, 100],
                  e: [100, 100, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 90,
                  s: [100, 100, 100],
                  e: [118, 118, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 105,
                  s: [118, 118, 100],
                  e: [100, 100, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 120,
                  s: [100, 100, 100],
                  e: [118, 118, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 135,
                  s: [118, 118, 100],
                  e: [100, 100, 100],
                },
                { t: 150 },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          w: 842,
          h: 524,
          ip: 0,
          op: 150,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_4',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'bitcoins Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [408.881, 189.458, 0], ix: 2, l: 2 },
            a: { a: 0, k: [357.25, 126.25, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.423, 2.624],
                        [3.723, 1.269],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.761, 0.169],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.592, 0.169],
                        [0, 0],
                        [0, 0],
                        [0.085, 0],
                        [0.085, -0.592],
                        [0, 0],
                        [-0.17, 0],
                        [0.169, 0],
                        [0, 0],
                        [0.846, 0.254],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.677, -0.169],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.761, -0.169],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.607, 4.485],
                        [2.623, 1.354],
                      ],
                      o: [
                        [0.507, -3.553],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.761, -0.17],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.677, -0.169],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.185, 0.254],
                        [0, 0],
                        [0.085, 0],
                        [-0.085, 0],
                        [0, 0],
                        [-0.17, 0.339],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.677, 0.169],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.761, 0.254],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [4.992, 0.931],
                        [1.27, -3.639],
                        [1.861, -0.508],
                      ],
                      v: [
                        [12.566, -3.301],
                        [6.643, -9.985],
                        [7.827, -14.723],
                        [4.866, -15.485],
                        [3.681, -10.831],
                        [1.312, -11.339],
                        [2.496, -15.993],
                        [-0.465, -16.753],
                        [-1.65, -12.016],
                        [-3.511, -12.438],
                        [-7.573, -13.454],
                        [-8.334, -10.324],
                        [-6.219, -9.816],
                        [-4.865, -8.124],
                        [-6.219, -2.708],
                        [-5.88, -2.624],
                        [-6.219, -2.708],
                        [-8.164, 4.907],
                        [-9.519, 5.584],
                        [-11.634, 5.076],
                        [-13.073, 8.376],
                        [-9.265, 9.307],
                        [-7.15, 9.816],
                        [-8.334, 14.639],
                        [-5.372, 15.399],
                        [-4.188, 10.661],
                        [-1.819, 11.253],
                        [-3.004, 15.992],
                        [-0.042, 16.753],
                        [1.143, 11.93],
                        [11.55, 8.038],
                        [8.843, 1.015],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.931, -3.723],
                        [2.03, 0.507],
                        [0, 0],
                      ],
                      o: [
                        [-0.93, 3.639],
                        [0, 0],
                        [2.031, 0.423],
                      ],
                      v: [
                        [5.796, 6.007],
                        [-3.257, 7.192],
                        [-1.65, 0.846],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.846, -3.469],
                        [1.692, 0.423],
                        [0, 0],
                      ],
                      o: [
                        [-0.846, 3.3],
                        [0, 0],
                        [1.693, 0.339],
                      ],
                      v: [
                        [6.727, -3.301],
                        [-0.888, -2.115],
                        [0.55, -7.869],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 1,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [157.438, 57.75], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-15.188, 0],
                        [0, 15.188],
                        [15.188, 0],
                        [0, -15.188],
                      ],
                      o: [
                        [15.188, 0],
                        [0, -15.188],
                        [-15.188, 0],
                        [0, 15.188],
                      ],
                      v: [
                        [0, 27.5],
                        [27.5, 0],
                        [0, -27.5],
                        [-27.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.968627510819, 0.576470588235, 0.101960791794, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [158.75, 57.75], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 302,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_5',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'pc Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [611.584, 317.262, 0], ix: 2, l: 2 },
            a: { a: 0, k: [75.996, 183.7, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.064, 0],
                        [0, 0],
                        [0, 1.064],
                        [0, 0],
                        [-1.075, 0],
                        [0, 0],
                        [0, -1.075],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-1.064, 0],
                        [0, 0],
                        [0, -1.064],
                        [0, 0],
                        [1.064, 0],
                        [0, 0],
                        [0, 1.064],
                      ],
                      v: [
                        [4.355, 5.802],
                        [-4.353, 5.802],
                        [-6.292, 3.865],
                        [-6.292, -3.864],
                        [-4.353, -5.802],
                        [4.355, -5.802],
                        [6.292, -3.864],
                        [6.292, 3.865],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.247058838489, 0.247058838489, 0.247058838489, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [89.607, 339.344], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.064, 0],
                        [0, 0],
                        [0, 1.075],
                        [0, 0],
                        [-1.075, 0],
                        [0, 0],
                        [0, -1.075],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-1.064, 0],
                        [0, 0],
                        [0, -1.066],
                        [0, 0],
                        [1.064, 0],
                        [0, 0],
                        [0, 1.064],
                      ],
                      v: [
                        [4.355, 11.529],
                        [-4.353, 11.529],
                        [-6.292, 9.592],
                        [-6.292, -9.591],
                        [-4.353, -11.529],
                        [4.355, -11.529],
                        [6.292, -9.591],
                        [6.292, 9.592],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.188235309077, 0.188235309077, 0.188235309077, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [89.607, 333.617], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.075, 0],
                        [0, 0],
                        [0, 1.076],
                        [0, 0],
                        [-1.075, 0],
                        [0, 0],
                        [0, -1.075],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-1.064, 0],
                        [0, 0],
                        [0, -1.065],
                        [0, 0],
                        [1.064, 0],
                        [0, 0],
                        [0, 1.076],
                      ],
                      v: [
                        [4.355, 5.802],
                        [-4.353, 5.802],
                        [-6.292, 3.864],
                        [-6.292, -3.864],
                        [-4.353, -5.802],
                        [4.355, -5.802],
                        [6.292, -3.864],
                        [6.292, 3.864],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.215686289469, 0.800000059838, 0.20000001496, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [73.189, 327.89], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.075, 0],
                        [0, 0],
                        [0, 1.075],
                        [0, 0],
                        [-1.075, 0],
                        [0, 0],
                        [0, -1.075],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-1.064, 0],
                        [0, 0],
                        [0, -1.066],
                        [0, 0],
                        [1.064, 0],
                        [0, 0],
                        [0, 1.064],
                      ],
                      v: [
                        [4.355, 11.529],
                        [-4.353, 11.529],
                        [-6.292, 9.592],
                        [-6.292, -9.591],
                        [-4.353, -11.529],
                        [4.355, -11.529],
                        [6.292, -9.591],
                        [6.292, 9.592],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.188235309077, 0.188235309077, 0.188235309077, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [73.189, 333.617], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.064, 0],
                        [0, 0],
                        [0, 1.064],
                        [0, 0],
                        [-1.075, 0],
                        [0, 0],
                        [0, -1.075],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-1.064, 0],
                        [0, 0],
                        [0, -1.064],
                        [0, 0],
                        [1.064, 0],
                        [0, 0],
                        [0, 1.064],
                      ],
                      v: [
                        [4.355, 5.802],
                        [-4.353, 5.802],
                        [-6.292, 3.865],
                        [-6.292, -3.864],
                        [-4.353, -5.802],
                        [4.355, -5.802],
                        [6.292, -3.864],
                        [6.292, 3.865],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.247058838489, 0.247058838489, 0.247058838489, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [56.787, 339.344], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.064, 0],
                        [0, 0],
                        [0, 1.075],
                        [0, 0],
                        [-1.075, 0],
                        [0, 0],
                        [0, -1.075],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-1.064, 0],
                        [0, 0],
                        [0, -1.066],
                        [0, 0],
                        [1.064, 0],
                        [0, 0],
                        [0, 1.064],
                      ],
                      v: [
                        [4.355, 11.529],
                        [-4.353, 11.529],
                        [-6.292, 9.592],
                        [-6.292, -9.591],
                        [-4.353, -11.529],
                        [4.355, -11.529],
                        [6.292, -9.591],
                        [6.292, 9.592],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.188235309077, 0.188235309077, 0.188235309077, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [56.787, 333.617], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.597, 0],
                        [0, 1.607],
                        [1.607, 0],
                        [0, -1.609],
                      ],
                      o: [
                        [1.607, 0],
                        [0, -1.609],
                        [-1.607, 0],
                        [0.01, 1.607],
                      ],
                      v: [
                        [0, 2.906],
                        [2.906, 0],
                        [0, -2.906],
                        [-2.906, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.968627510819, 0.258823529412, 0.286274509804, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [41.937, 324.995], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 7',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.607, 0],
                        [0, 1.607],
                        [1.607, 0],
                        [0, -1.609],
                      ],
                      o: [
                        [1.607, 0],
                        [0, -1.609],
                        [-1.607, 0],
                        [0, 1.607],
                      ],
                      v: [
                        [0, 2.906],
                        [2.906, 0],
                        [0, -2.906],
                        [-2.906, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.215686289469, 0.800000059838, 0.20000001496, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [31.91, 324.995], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 8',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 8,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.607, 0],
                        [0, 1.607],
                        [1.607, 0],
                        [0, -1.609],
                      ],
                      o: [
                        [1.607, 0],
                        [0, -1.609],
                        [-1.607, 0],
                        [0, 1.607],
                      ],
                      v: [
                        [0, 2.906],
                        [2.906, 0],
                        [0, -2.906],
                        [-2.906, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.188235309077, 0.188235309077, 0.188235309077, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [21.883, 324.995], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 9',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 9,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.82, 0],
                        [0, 0],
                        [0, 0.83],
                        [-0.83, 0],
                        [0, 0],
                        [0, -0.83],
                      ],
                      o: [
                        [0, 0],
                        [-0.82, 0],
                        [0, -0.82],
                        [0, 0],
                        [0.82, 0],
                        [0, 0.83],
                      ],
                      v: [
                        [15.271, 1.501],
                        [-15.27, 1.501],
                        [-16.772, 0],
                        [-15.27, -1.501],
                        [15.271, -1.501],
                        [16.772, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.188235309077, 0.188235309077, 0.188235309077, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [117.725, 343.645], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 10',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 10,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.82, 0],
                        [0, 0],
                        [0, 0.831],
                        [-0.83, 0],
                        [0, 0],
                        [0, -0.83],
                      ],
                      o: [
                        [0, 0],
                        [-0.82, 0],
                        [0, -0.818],
                        [0, 0],
                        [0.82, 0],
                        [-0.01, 0.831],
                      ],
                      v: [
                        [15.271, 1.501],
                        [-15.27, 1.501],
                        [-16.772, 0],
                        [-15.27, -1.501],
                        [15.271, -1.501],
                        [16.772, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.188235309077, 0.188235309077, 0.188235309077, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [117.725, 338.631], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 11',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 11,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.82, 0],
                        [0, 0],
                        [0, 0.83],
                        [-0.83, 0],
                        [0, 0],
                        [0, -0.83],
                      ],
                      o: [
                        [0, 0],
                        [-0.82, 0],
                        [0, -0.82],
                        [0, 0],
                        [0.82, 0],
                        [-0.01, 0.841],
                      ],
                      v: [
                        [15.271, 1.501],
                        [-15.27, 1.501],
                        [-16.772, 0],
                        [-15.27, -1.501],
                        [15.271, -1.501],
                        [16.772, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.188235309077, 0.188235309077, 0.188235309077, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [117.725, 333.607], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 12',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 12,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.82, 0],
                        [0, 0],
                        [0, 0.83],
                        [-0.83, 0],
                        [0, 0],
                        [0, -0.83],
                      ],
                      o: [
                        [0, 0],
                        [-0.82, 0],
                        [0, -0.819],
                        [0, 0],
                        [0.82, 0],
                        [0, 0.83],
                      ],
                      v: [
                        [15.271, 1.501],
                        [-15.27, 1.501],
                        [-16.772, 0],
                        [-15.27, -1.501],
                        [15.271, -1.501],
                        [16.772, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.188235309077, 0.188235309077, 0.188235309077, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [117.725, 328.591], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 13',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 13,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.82, 0],
                        [0, 0],
                        [0, 0.83],
                        [-0.83, 0],
                        [0, 0],
                        [0, -0.83],
                      ],
                      o: [
                        [0, 0],
                        [-0.82, 0],
                        [0, -0.82],
                        [0, 0],
                        [0.82, 0],
                        [0, 0.83],
                      ],
                      v: [
                        [15.271, 1.501],
                        [-15.27, 1.501],
                        [-16.772, 0],
                        [-15.27, -1.501],
                        [15.271, -1.501],
                        [16.772, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.188235309077, 0.188235309077, 0.188235309077, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [117.725, 323.579], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 14',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 14,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.246, 0],
                        [0, 2.246],
                        [2.246, 0],
                        [0, -2.246],
                      ],
                      o: [
                        [2.246, 0],
                        [0, -2.246],
                        [-2.246, 0],
                        [0.01, 2.246],
                      ],
                      v: [
                        [0, 4.066],
                        [4.066, 0.001],
                        [0, -4.066],
                        [-4.066, 0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.117647066303, 0.117647066303, 0.117647066303, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [125.504, 260.59], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 15',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 15,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.235, 0],
                        [0, 2.246],
                        [2.246, 0],
                        [0, -2.246],
                      ],
                      o: [
                        [2.246, 0],
                        [0, -2.246],
                        [-2.246, 0],
                        [0, 2.246],
                      ],
                      v: [
                        [0, 4.066],
                        [4.066, 0],
                        [0, -4.066],
                        [-4.066, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.117647066303, 0.117647066303, 0.117647066303, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [114.516, 260.623], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 16',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 16,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.235, 0],
                        [0, 2.246],
                        [2.246, 0],
                        [0, -2.246],
                      ],
                      o: [
                        [2.246, 0],
                        [0, -2.246],
                        [-2.246, 0],
                        [0.01, 2.256],
                      ],
                      v: [
                        [0, 4.066],
                        [4.066, 0.001],
                        [0, -4.066],
                        [-4.066, 0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.258823529412, 0.521568627451, 0.956862804936, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [103.531, 260.665], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 17',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 17,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.235, 0],
                        [0, 2.247],
                        [2.246, 0],
                        [0, -2.246],
                      ],
                      o: [
                        [2.246, 0],
                        [0, -2.246],
                        [-2.246, 0],
                        [0.01, 2.247],
                      ],
                      v: [
                        [0, 4.066],
                        [4.066, 0],
                        [0, -4.066],
                        [-4.066, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.258823529412, 0.521568627451, 0.956862804936, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [92.543, 260.707], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 18',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 18,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.235, 0],
                        [0, 2.246],
                        [2.246, 0],
                        [0, -2.246],
                      ],
                      o: [
                        [2.246, 0],
                        [0, -2.246],
                        [-2.246, 0],
                        [0.01, 2.246],
                      ],
                      v: [
                        [0, 4.066],
                        [4.066, 0],
                        [0, -4.066],
                        [-4.066, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.258823529412, 0.521568627451, 0.956862804936, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [81.559, 260.751], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 19',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 19,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.235, 0],
                        [0, 2.246],
                        [2.246, 0],
                        [0, -2.246],
                      ],
                      o: [
                        [2.246, 0],
                        [0, -2.246],
                        [-2.246, 0],
                        [0.01, 2.246],
                      ],
                      v: [
                        [0, 4.066],
                        [4.066, 0],
                        [0, -4.066],
                        [-4.066, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.258823529412, 0.521568627451, 0.956862804936, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [70.574, 260.783], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 20',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 20,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.246, 0],
                        [0, 2.246],
                        [2.246, 0],
                        [0, -2.246],
                      ],
                      o: [
                        [2.246, 0],
                        [0, -2.246],
                        [-2.246, 0],
                        [0, 2.246],
                      ],
                      v: [
                        [0, 4.066],
                        [4.066, 0],
                        [0, -4.066],
                        [-4.066, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.258823529412, 0.521568627451, 0.956862804936, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [59.598, 260.826], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 21',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 21,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.246, 0],
                        [0, 2.246],
                        [2.246, 0],
                        [0, -2.247],
                      ],
                      o: [
                        [2.246, 0],
                        [0, -2.247],
                        [-2.246, 0],
                        [0, 2.246],
                      ],
                      v: [
                        [0, 4.066],
                        [4.066, 0.001],
                        [0, -4.066],
                        [-4.066, 0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.258823529412, 0.521568627451, 0.956862804936, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [48.613, 260.868], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 22',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 22,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.246, 0],
                        [0, 2.246],
                        [2.246, 0],
                        [0, -2.246],
                      ],
                      o: [
                        [2.246, 0],
                        [0, -2.246],
                        [-2.246, 0],
                        [0, 2.246],
                      ],
                      v: [
                        [0, 4.066],
                        [4.066, 0],
                        [0, -4.066],
                        [-4.066, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.258823529412, 0.521568627451, 0.956862804936, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [37.625, 260.91], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 23',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 23,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.246, 0],
                        [0, 2.247],
                        [2.246, 0],
                        [0, -2.246],
                      ],
                      o: [
                        [2.246, 0],
                        [0, -2.246],
                        [-2.246, 0],
                        [0, 2.247],
                      ],
                      v: [
                        [0, 4.066],
                        [4.066, 0],
                        [0, -4.066],
                        [-4.066, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.258823529412, 0.521568627451, 0.956862804936, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [26.641, 260.942], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 24',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 24,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.682, 0],
                        [0, 0],
                        [0, 0.681],
                        [0, 0],
                        [-0.681, 0],
                        [0, 0],
                        [0, -0.681],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-0.691, 0],
                        [0, 0],
                        [0, -0.692],
                        [0, 0],
                        [0.692, 0],
                        [0, 0],
                        [0, 0.681],
                      ],
                      v: [
                        [52.274, 3.8],
                        [-52.242, 4.173],
                        [-53.487, 2.938],
                        [-53.508, -2.555],
                        [-52.274, -3.8],
                        [52.242, -4.173],
                        [53.487, -2.938],
                        [53.508, 2.555],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.247058838489, 0.247058838489, 0.247058838489, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [76.028, 248.285], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 25',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 25,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-53.088, 29.056],
                        [53.301, 28.672],
                        [53.312, 32.941],
                        [-53.077, 33.324],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-53.119, 19.495],
                        [53.269, 19.113],
                        [53.28, 23.382],
                        [-53.109, 23.766],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-53.151, 9.925],
                        [53.237, 9.542],
                        [53.248, 13.812],
                        [-53.141, 14.195],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 3,
                  ty: 'sh',
                  ix: 4,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-53.184, 0.344],
                        [53.205, -0.039],
                        [53.216, 4.232],
                        [-53.173, 4.615],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 4',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 4,
                  ty: 'sh',
                  ix: 5,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [58.634, 38.243],
                        [58.592, 25.202],
                        [58.581, 23.37],
                        [58.571, 19.102],
                        [58.571, 18.357],
                        [58.528, 6.327],
                        [58.516, 4.232],
                        [58.507, -0.039],
                        [58.496, -1.528],
                        [58.464, -9.341],
                        [53.163, -9.32],
                        [53.173, -5.318],
                        [-53.215, -4.935],
                        [-53.226, -8.937],
                        [58.464, -9.341],
                        [58.358, -38.659],
                        [-58.634, -38.244],
                        [-58.527, -8.927],
                        [-54.152, -8.937],
                        [-58.527, -8.927],
                        [-58.517, -4.924],
                        [-58.495, 0.376],
                        [-58.484, 4.646],
                        [-58.464, 9.948],
                        [-58.453, 14.215],
                        [-58.432, 19.518],
                        [-58.421, 23.785],
                        [-58.4, 29.087],
                        [-58.389, 33.357],
                        [-58.367, 38.659],
                        [-53.067, 38.635],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 5',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 1,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.188235309077, 0.188235309077, 0.188235309077, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [76.13, 278.417], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 26',
              np: 7,
              cix: 2,
              bm: 0,
              ix: 26,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [5.493, -0.011],
                        [0, 0],
                        [-0.022, -5.503],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-5.504, 0.021],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.02, -5.493],
                      ],
                      v: [
                        [48.41, -22.1],
                        [-48.579, -21.758],
                        [-58.543, -11.72],
                        [-58.426, 22.111],
                        [58.565, 21.695],
                        [58.448, -12.135],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-0.009, -5.057],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-5.057, 0.01],
                      ],
                      o: [
                        [5.057, -0.021],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.021, -5.056],
                        [0, 0],
                      ],
                      v: [
                        [48.41, -21.258],
                        [57.607, -12.124],
                        [57.724, 20.876],
                        [-57.596, 21.281],
                        [-57.713, -11.72],
                        [-48.579, -20.917],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 1,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.239215701234, 0.239215701234, 0.239215701234, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [75.934, 218.042], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 27',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 27,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.224, 1.48],
                        [2.129, 0.713],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.458, 0.096],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.341, 0.086],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.032, -0.021],
                        [0.021, -0.351],
                        [0, 0],
                        [-0.065, -0.021],
                        [0.064, 0.011],
                        [0, 0],
                        [0.479, 0.117],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.394, -0.096],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.436, -0.106],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.905, 2.566],
                        [1.512, 0.777],
                      ],
                      o: [
                        [0.298, -2.012],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.447, -0.106],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.362, -0.074],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.681, 0.171],
                        [0, 0],
                        [0.054, 0.01],
                        [-0.053, -0.011],
                        [0, 0],
                        [-0.085, 0.213],
                        [0.011, 0.022],
                        [0, 0],
                        [0, 0],
                        [0.405, 0.096],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.457, 0.118],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.874, 0.521],
                        [0.724, -2.065],
                        [1.107, -0.255],
                      ],
                      v: [
                        [7.17, -1.916],
                        [3.784, -5.716],
                        [4.466, -8.442],
                        [2.784, -8.846],
                        [2.124, -6.185],
                        [0.772, -6.494],
                        [1.441, -9.165],
                        [-0.24, -9.57],
                        [-0.921, -6.845],
                        [-1.996, -7.09],
                        [-1.996, -7.1],
                        [-4.327, -7.665],
                        [-4.774, -5.887],
                        [-3.55, -5.589],
                        [-2.762, -4.631],
                        [-3.54, -1.522],
                        [-3.369, -1.469],
                        [-3.55, -1.512],
                        [-4.636, 2.842],
                        [-5.413, 3.236],
                        [-6.637, 2.938],
                        [-7.468, 4.844],
                        [-5.275, 5.376],
                        [-4.072, 5.674],
                        [-4.764, 8.431],
                        [-3.082, 8.836],
                        [-2.4, 6.11],
                        [-1.049, 6.451],
                        [-1.73, 9.166],
                        [-0.048, 9.57],
                        [0.644, 6.824],
                        [6.584, 4.556],
                        [5.019, 0.532],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.533, -2.15],
                        [1.139, 0.266],
                        [0, 0],
                      ],
                      o: [
                        [-0.511, 2.065],
                        [0, 0],
                        [1.139, 0.266],
                      ],
                      v: [
                        [3.337, 3.417],
                        [-1.847, 4.109],
                        [-0.931, 0.458],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.489, -1.948],
                        [0.948, 0.224],
                        [0, 0],
                      ],
                      o: [
                        [-0.469, 1.873],
                        [0, 0],
                        [0.958, 0.245],
                      ],
                      v: [
                        [3.838, -1.937],
                        [-0.517, -1.235],
                        [0.303, -4.556],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 1,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [109.913, 218.17], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 28',
              np: 5,
              cix: 2,
              bm: 0,
              ix: 28,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.033, -8.239],
                        [8.25, 2.023],
                        [-2.023, 8.24],
                        [-8.24, -2.022],
                      ],
                      o: [
                        [-2.021, 8.24],
                        [-8.24, -2.022],
                        [2.023, -8.239],
                        [8.26, 2.012],
                      ],
                      v: [
                        [14.914, 3.661],
                        [-3.672, 14.913],
                        [-14.924, -3.663],
                        [3.652, -14.915],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.968627510819, 0.576470588235, 0.101960791794, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0.269, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [110.59, 218.744], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 29',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 29,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.033, -8.239],
                        [8.25, 2.023],
                        [-2.023, 8.24],
                        [-8.24, -2.022],
                      ],
                      o: [
                        [-2.021, 8.24],
                        [-8.24, -2.022],
                        [2.023, -8.239],
                        [8.26, 2.012],
                      ],
                      v: [
                        [14.914, 3.661],
                        [-3.672, 14.913],
                        [-14.924, -3.663],
                        [3.652, -14.915],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.968627510819, 0.576470588235, 0.101960791794, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [110.59, 218.744], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 30',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 30,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [11.72, -21.951],
                            [-1.469, 21.918],
                            [-11.72, 21.95],
                            [1.48, -21.919],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.56862745098, 0.56862745098, 0.56862745098, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [85.252, 217.999], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Group 31',
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [20.029, -21.983],
                            [6.839, 21.886],
                            [-20.03, 21.982],
                            [-6.84, -21.887],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.56862745098, 0.56862745098, 0.56862745098, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [61.787, 218.074], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Group 32',
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 2,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [69.365, 218.052], ix: 2 },
                  a: { a: 0, k: [69.365, 218.052], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 15, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 80',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 31,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.022, -5.493],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-5.503, 0.021],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.022, -5.503],
                        [0, 0],
                        [5.504, -0.011],
                      ],
                      v: [
                        [58.443, -12.13],
                        [58.56, 21.69],
                        [-58.421, 22.105],
                        [-58.538, -11.715],
                        [-48.575, -21.753],
                        [48.404, -22.094],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.188235309077, 0.188235309077, 0.188235309077, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [75.929, 218.036], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 33',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 32,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [5.493, -0.011],
                        [0, 0],
                        [-0.022, -5.503],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-5.504, 0.021],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.02, -5.493],
                      ],
                      v: [
                        [48.41, -22.1],
                        [-48.579, -21.758],
                        [-58.543, -11.72],
                        [-58.426, 22.111],
                        [58.565, 21.695],
                        [58.448, -12.135],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.188235309077, 0.188235309077, 0.188235309077, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [75.934, 218.042], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 34',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 33,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.385, 0],
                        [0, 2.395],
                        [2.395, 0],
                        [0, -2.396],
                      ],
                      o: [
                        [2.395, 0],
                        [0, -2.385],
                        [-2.396, 0],
                        [0.011, 2.395],
                      ],
                      v: [
                        [0.001, 4.332],
                        [4.332, 0],
                        [0.001, -4.333],
                        [-4.332, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.117647066303, 0.117647066303, 0.117647066303, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [76.172, 290.578], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 35',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 34,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.947, -0.256],
                        [0.98, -0.128],
                        [3.576, 1.746],
                        [-0.15, 0.575],
                        [-2.257, 1.011],
                        [-1.618, 1.468],
                        [0.096, 0.447],
                        [3.608, 3.459],
                        [0.266, -0.946],
                        [0.372, -0.915],
                        [3.289, -2.226],
                        [0.426, 0.426],
                        [-0.245, 2.448],
                        [0.458, 2.139],
                        [0.437, 0.138],
                        [4.801, -1.394],
                        [-0.692, -0.703],
                        [-0.607, -0.778],
                        [-0.362, -3.938],
                        [0.608, -0.159],
                        [1.959, 1.416],
                        [2.086, 0.681],
                        [0.351, -0.31],
                        [1.193, -4.855],
                        [-0.947, 0.255],
                        [-0.98, 0.128],
                        [-3.598, -1.575],
                        [0.159, -0.651],
                        [2.194, -0.981],
                        [1.618, -1.468],
                        [-0.096, -0.446],
                        [-3.609, -3.46],
                        [-0.265, 0.947],
                        [-0.372, 0.905],
                        [-3.194, 2.309],
                        [-0.469, -0.438],
                        [0.255, -2.394],
                        [-0.457, -2.138],
                        [-0.436, -0.148],
                        [-4.8, 1.394],
                        [0.693, 0.703],
                        [0.606, 0.777],
                        [0.341, 3.949],
                        [-0.574, 0.18],
                        [-1.992, -1.438],
                        [-2.085, -0.68],
                        [-0.34, 0.308],
                        [-1.192, 4.854],
                      ],
                      o: [
                        [-0.958, 0.256],
                        [-3.311, 0.478],
                        [0.276, -0.511],
                        [4.215, -0.01],
                        [2.097, -0.926],
                        [0.341, -0.309],
                        [-0.99, -4.748],
                        [-0.714, -0.671],
                        [-0.256, 0.97],
                        [-1.236, 3.099],
                        [-0.309, -0.511],
                        [2.076, -3.642],
                        [0.245, -2.288],
                        [-0.096, -0.448],
                        [-4.598, -1.522],
                        [-0.937, 0.277],
                        [0.713, 0.702],
                        [2.033, 2.575],
                        [-0.649, 0.01],
                        [-2.118, -3.545],
                        [-1.853, -1.353],
                        [-0.436, -0.138],
                        [-3.609, 3.225],
                        [-0.234, 0.946],
                        [0.958, -0.257],
                        [3.204, -0.469],
                        [-0.309, 0.566],
                        [-4.077, 0.074],
                        [-2.097, 0.925],
                        [-0.34, 0.308],
                        [0.99, 4.748],
                        [0.712, 0.669],
                        [0.256, -0.969],
                        [1.204, -3.012],
                        [0.351, 0.531],
                        [-2.001, 3.587],
                        [-0.246, 2.289],
                        [0.096, 0.448],
                        [4.599, 1.524],
                        [0.938, -0.278],
                        [-0.712, -0.702],
                        [-2.044, -2.597],
                        [0.628, -0.032],
                        [2.119, 3.597],
                        [1.851, 1.351],
                        [0.437, 0.139],
                        [3.61, -3.226],
                        [0.245, -0.958],
                      ],
                      v: [
                        [22.297, 4.311],
                        [19.38, 4.897],
                        [6.606, 3.534],
                        [7.234, 1.883],
                        [17.964, -0.256],
                        [23.606, -3.886],
                        [24, -5.111],
                        [17.017, -17.756],
                        [14.877, -17.151],
                        [13.93, -14.33],
                        [6.382, -3.971],
                        [5.285, -5.397],
                        [8.756, -15.692],
                        [8.437, -22.387],
                        [7.574, -23.335],
                        [-6.871, -23.612],
                        [-7.414, -21.451],
                        [-5.445, -19.225],
                        [-0.261, -7.708],
                        [-2.135, -7.453],
                        [-9.203, -15.425],
                        [-15.164, -18.502],
                        [-16.42, -18.225],
                        [-23.883, -5.855],
                        [-22.286, -4.311],
                        [-19.369, -4.897],
                        [-6.946, -3.706],
                        [-7.648, -1.884],
                        [-17.964, 0.245],
                        [-23.606, 3.874],
                        [-24, 5.098],
                        [-17.016, 17.746],
                        [-14.877, 17.138],
                        [-13.93, 14.328],
                        [-6.648, 4.152],
                        [-5.413, 5.61],
                        [-8.766, 15.679],
                        [-8.447, 22.375],
                        [-7.585, 23.333],
                        [6.86, 23.611],
                        [7.403, 21.45],
                        [5.435, 19.224],
                        [0.239, 7.643],
                        [2.038, 7.324],
                        [9.193, 15.436],
                        [15.153, 18.511],
                        [16.409, 18.235],
                        [23.872, 5.865],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.247058838489, 0.247058838489, 0.247058838489, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [76.165, 290.578], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 36',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 35,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [58.384, -25.309],
                        [-58.565, -24.894],
                        [-58.385, 25.309],
                        [58.565, 24.894],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.117647066303, 0.117647066303, 0.117647066303, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [76.186, 291.755], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 37',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 36,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.022, -5.493],
                        [0, 0],
                        [5.504, -0.021],
                        [0, 0],
                        [0.022, 5.502],
                        [0, 0],
                        [-5.503, 0.022],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0.022, 5.503],
                        [0, 0],
                        [-5.502, 0.022],
                        [0, 0],
                        [-0.022, -5.504],
                        [0, 0],
                        [5.504, -0.022],
                      ],
                      v: [
                        [58.321, -48.702],
                        [58.66, 48.266],
                        [48.697, 58.304],
                        [-48.283, 58.655],
                        [-58.321, 48.692],
                        [-58.66, -48.276],
                        [-48.697, -58.315],
                        [48.282, -58.655],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.149019607843, 0.149019607843, 0.149019607843, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [76.052, 254.608], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 38',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 37,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [5.833, -0.021],
                        [0, 0],
                        [-0.022, -5.972],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-3.673, 0],
                        [0, 0],
                        [0, 3.674],
                      ],
                      o: [
                        [0, 0],
                        [-0.021, -5.972],
                        [0, 0],
                        [-5.834, 0.021],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 3.674],
                        [0, 0],
                        [3.673, 0],
                        [0, 0],
                      ],
                      v: [
                        [62.26, 46.232],
                        [61.854, -67.864],
                        [51.209, -78.69],
                        [-51.667, -78.329],
                        [-62.237, -67.428],
                        [-61.823, 48.754],
                        [-61.812, 48.754],
                        [-61.812, 72.056],
                        [-55.159, 78.711],
                        [55.605, 78.711],
                        [62.26, 72.056],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.149019607843, 0.149019607843, 0.149019607843, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [76.07, 271.077], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 39',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 38,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.064, 0],
                        [0, 0],
                        [0, 1.075],
                        [0, 0],
                        [-1.075, 0],
                        [0, 0],
                        [0, -1.076],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-1.064, 0],
                        [0, 0],
                        [0, -1.065],
                        [0, 0],
                        [1.064, 0],
                        [0, 0],
                        [0, 1.075],
                      ],
                      v: [
                        [4.355, 5.802],
                        [-4.353, 5.802],
                        [-6.292, 3.864],
                        [-6.292, -3.864],
                        [-4.353, -5.802],
                        [4.355, -5.802],
                        [6.292, -3.864],
                        [6.292, 3.864],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.247058838489, 0.247058838489, 0.247058838489, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [88.713, 165.209], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 40',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 39,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.064, 0],
                        [0, 0],
                        [0, 1.076],
                        [0, 0],
                        [-1.075, 0],
                        [0, 0],
                        [0, -1.065],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-1.064, 0],
                        [0, 0],
                        [0, -1.065],
                        [0, 0],
                        [1.064, 0],
                        [0, 0],
                        [0, 1.065],
                      ],
                      v: [
                        [4.355, 11.529],
                        [-4.353, 11.529],
                        [-6.292, 9.591],
                        [-6.292, -9.591],
                        [-4.353, -11.529],
                        [4.355, -11.529],
                        [6.292, -9.591],
                        [6.292, 9.602],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.188235309077, 0.188235309077, 0.188235309077, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [89.607, 160.376], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 41',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 40,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.075, 0],
                        [0, 0],
                        [0, 1.076],
                        [0, 0],
                        [-1.075, 0],
                        [0, 0],
                        [0, -1.065],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-1.064, 0],
                        [0, 0],
                        [0, -1.065],
                        [0, 0],
                        [1.064, 0],
                        [0, 0],
                        [0, 1.065],
                      ],
                      v: [
                        [4.355, 5.801],
                        [-4.353, 5.801],
                        [-6.292, 3.864],
                        [-6.292, -3.865],
                        [-4.353, -5.801],
                        [4.355, -5.801],
                        [6.292, -3.865],
                        [6.292, 3.864],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.215686289469, 0.800000059838, 0.20000001496, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [73.189, 154.66], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 42',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 41,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.075, 0],
                        [0, 0],
                        [0, 1.076],
                        [0, 0],
                        [-1.075, 0],
                        [0, 0],
                        [0, -1.065],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-1.064, 0],
                        [0, 0],
                        [0, -1.065],
                        [0, 0],
                        [1.064, 0],
                        [0, 0],
                        [0, 1.065],
                      ],
                      v: [
                        [4.355, 11.529],
                        [-4.353, 11.529],
                        [-6.292, 9.591],
                        [-6.292, -9.591],
                        [-4.353, -11.529],
                        [4.355, -11.529],
                        [6.292, -9.591],
                        [6.292, 9.602],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.188235309077, 0.188235309077, 0.188235309077, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [73.189, 160.376], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 43',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 42,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.064, 0],
                        [0, 0],
                        [0, 1.075],
                        [0, 0],
                        [-1.075, 0],
                        [0, 0],
                        [0, -1.076],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-1.064, 0],
                        [0, 0],
                        [0, -1.065],
                        [0, 0],
                        [1.064, 0],
                        [0, 0],
                        [0, 1.075],
                      ],
                      v: [
                        [4.355, 5.802],
                        [-4.353, 5.802],
                        [-6.292, 3.864],
                        [-6.292, -3.864],
                        [-4.353, -5.802],
                        [4.355, -5.802],
                        [6.292, -3.864],
                        [6.292, 3.864],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.247058838489, 0.247058838489, 0.247058838489, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [56.787, 166.103], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 44',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 43,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.064, 0],
                        [0, 0],
                        [0, 1.076],
                        [0, 0],
                        [-1.075, 0],
                        [0, 0],
                        [0, -1.065],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-1.064, 0],
                        [0, 0],
                        [0, -1.065],
                        [0, 0],
                        [1.064, 0],
                        [0, 0],
                        [0, 1.065],
                      ],
                      v: [
                        [4.355, 11.529],
                        [-4.353, 11.529],
                        [-6.292, 9.591],
                        [-6.292, -9.591],
                        [-4.353, -11.529],
                        [4.355, -11.529],
                        [6.292, -9.591],
                        [6.292, 9.602],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.188235309077, 0.188235309077, 0.188235309077, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [56.787, 160.376], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 45',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 44,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.597, 0],
                        [0, 1.608],
                        [1.607, 0],
                        [0, -1.607],
                      ],
                      o: [
                        [1.607, 0],
                        [0, -1.607],
                        [-1.607, 0],
                        [0.01, 1.608],
                      ],
                      v: [
                        [0, 2.906],
                        [2.906, -0.001],
                        [0, -2.906],
                        [-2.906, -0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.968627510819, 0.258823529412, 0.286274509804, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [41.937, 151.753], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 46',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 45,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.607, 0],
                        [0, 1.608],
                        [1.607, 0],
                        [0, -1.607],
                      ],
                      o: [
                        [1.607, 0],
                        [0, -1.607],
                        [-1.607, 0],
                        [0, 1.608],
                      ],
                      v: [
                        [0, 2.906],
                        [2.906, -0.001],
                        [0, -2.906],
                        [-2.906, -0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.215686289469, 0.800000059838, 0.20000001496, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [31.91, 151.753], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 47',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 46,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.607, 0],
                        [0, 1.608],
                        [1.607, 0],
                        [0, -1.607],
                      ],
                      o: [
                        [1.607, 0],
                        [0, -1.607],
                        [-1.607, 0],
                        [0, 1.608],
                      ],
                      v: [
                        [0, 2.906],
                        [2.906, -0.001],
                        [0, -2.906],
                        [-2.906, -0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.188235309077, 0.188235309077, 0.188235309077, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [21.883, 151.753], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 48',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 47,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.82, 0],
                        [0, 0],
                        [0, 0.83],
                        [-0.83, 0],
                        [0, 0],
                        [0, -0.831],
                      ],
                      o: [
                        [0, 0],
                        [-0.82, 0],
                        [0, -0.82],
                        [0, 0],
                        [0.82, 0],
                        [0, 0.83],
                      ],
                      v: [
                        [15.271, 1.501],
                        [-15.27, 1.501],
                        [-16.772, 0],
                        [-15.27, -1.501],
                        [15.271, -1.501],
                        [16.772, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.188235309077, 0.188235309077, 0.188235309077, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [117.725, 170.404], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 49',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 48,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.82, 0],
                        [0, 0],
                        [0, 0.83],
                        [-0.83, 0],
                        [0, 0],
                        [0, -0.831],
                      ],
                      o: [
                        [0, 0],
                        [-0.82, 0],
                        [0, -0.82],
                        [0, 0],
                        [0.82, 0],
                        [-0.01, 0.83],
                      ],
                      v: [
                        [15.271, 1.501],
                        [-15.27, 1.501],
                        [-16.772, 0],
                        [-15.27, -1.501],
                        [15.271, -1.501],
                        [16.772, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.188235309077, 0.188235309077, 0.188235309077, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [117.725, 165.391], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 50',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 49,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.82, 0],
                        [0, 0],
                        [0, 0.83],
                        [-0.83, 0],
                        [0, 0],
                        [0, -0.83],
                      ],
                      o: [
                        [0, 0],
                        [-0.82, 0],
                        [0, -0.82],
                        [0, 0],
                        [0.82, 0],
                        [-0.01, 0.83],
                      ],
                      v: [
                        [15.271, 1.501],
                        [-15.27, 1.501],
                        [-16.772, 0],
                        [-15.27, -1.501],
                        [15.271, -1.501],
                        [16.772, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.188235309077, 0.188235309077, 0.188235309077, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [117.725, 160.376], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 51',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 50,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.82, 0],
                        [0, 0],
                        [0, 0.83],
                        [-0.83, 0],
                        [0, 0],
                        [0, -0.83],
                      ],
                      o: [
                        [0, 0],
                        [-0.82, 0],
                        [0, -0.82],
                        [0, 0],
                        [0.82, 0],
                        [0, 0.83],
                      ],
                      v: [
                        [15.271, 1.501],
                        [-15.27, 1.501],
                        [-16.772, 0],
                        [-15.27, -1.501],
                        [15.271, -1.501],
                        [16.772, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.188235309077, 0.188235309077, 0.188235309077, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [117.725, 155.363], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 52',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 51,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.82, 0],
                        [0, 0],
                        [0, 0.83],
                        [-0.83, 0],
                        [0, 0],
                        [0, -0.831],
                      ],
                      o: [
                        [0, 0],
                        [-0.82, 0],
                        [0, -0.82],
                        [0, 0],
                        [0.82, 0],
                        [-0.01, 0.841],
                      ],
                      v: [
                        [15.271, 1.501],
                        [-15.27, 1.501],
                        [-16.772, 0],
                        [-15.27, -1.501],
                        [15.271, -1.501],
                        [16.772, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.188235309077, 0.188235309077, 0.188235309077, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [117.725, 150.338], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 53',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 52,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.246, 0],
                        [0, 2.247],
                        [2.246, 0],
                        [0, -2.246],
                      ],
                      o: [
                        [2.246, 0],
                        [0, -2.246],
                        [-2.246, 0],
                        [0.01, 2.257],
                      ],
                      v: [
                        [0, 4.066],
                        [4.066, 0],
                        [0, -4.066],
                        [-4.066, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.117647066303, 0.117647066303, 0.117647066303, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [125.504, 87.35], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 54',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 53,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.235, 0],
                        [0, 2.246],
                        [2.246, 0],
                        [0, -2.246],
                      ],
                      o: [
                        [2.246, 0],
                        [0, -2.246],
                        [-2.246, 0],
                        [0.01, 2.246],
                      ],
                      v: [
                        [0, 4.066],
                        [4.066, 0.001],
                        [0, -4.066],
                        [-4.066, 0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.117647066303, 0.117647066303, 0.117647066303, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [114.516, 87.391], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 55',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 54,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.235, 0],
                        [0, 2.246],
                        [2.246, 0],
                        [0, -2.246],
                      ],
                      o: [
                        [2.246, 0],
                        [0, -2.246],
                        [-2.246, 0],
                        [0.01, 2.246],
                      ],
                      v: [
                        [0, 4.066],
                        [4.066, 0.001],
                        [0, -4.066],
                        [-4.066, 0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.258823529412, 0.521568627451, 0.956862804936, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [103.531, 87.435], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 56',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 55,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.235, 0],
                        [0, 2.246],
                        [2.246, 0],
                        [0, -2.246],
                      ],
                      o: [
                        [2.246, 0],
                        [0, -2.246],
                        [-2.246, 0],
                        [0.01, 2.246],
                      ],
                      v: [
                        [0, 4.066],
                        [4.066, 0.001],
                        [0, -4.066],
                        [-4.066, 0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.258823529412, 0.521568627451, 0.956862804936, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [92.543, 87.466], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 57',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 56,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.235, 0],
                        [0, 2.246],
                        [2.246, 0],
                        [0, -2.246],
                      ],
                      o: [
                        [2.246, 0],
                        [0, -2.246],
                        [-2.246, 0],
                        [0.01, 2.246],
                      ],
                      v: [
                        [0, 4.066],
                        [4.066, 0.001],
                        [0, -4.066],
                        [-4.066, 0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.258823529412, 0.521568627451, 0.956862804936, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [81.559, 87.509], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 58',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 57,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.235, 0],
                        [0, 2.246],
                        [2.246, 0],
                        [0, -2.246],
                      ],
                      o: [
                        [2.246, 0],
                        [0, -2.246],
                        [-2.246, 0],
                        [0.01, 2.246],
                      ],
                      v: [
                        [0, 4.066],
                        [4.066, 0],
                        [0, -4.066],
                        [-4.066, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.258823529412, 0.521568627451, 0.956862804936, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [70.574, 87.551], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 59',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 58,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.246, 0],
                        [0, 2.246],
                        [2.246, 0],
                        [0, -2.246],
                      ],
                      o: [
                        [2.246, 0],
                        [0, -2.246],
                        [-2.246, 0],
                        [0, 2.246],
                      ],
                      v: [
                        [0, 4.066],
                        [4.066, 0.001],
                        [0, -4.066],
                        [-4.066, 0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.258823529412, 0.521568627451, 0.956862804936, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [59.598, 87.595], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 60',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 59,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.246, 0],
                        [0, 2.246],
                        [2.246, 0],
                        [0, -2.246],
                      ],
                      o: [
                        [2.246, 0],
                        [0, -2.246],
                        [-2.246, 0],
                        [0, 2.246],
                      ],
                      v: [
                        [0, 4.066],
                        [4.066, 0.001],
                        [0, -4.066],
                        [-4.066, 0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.258823529412, 0.521568627451, 0.956862804936, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [48.613, 87.626], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 61',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 60,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.246, 0],
                        [0, 2.246],
                        [2.246, 0],
                        [0, -2.246],
                      ],
                      o: [
                        [2.246, 0],
                        [0, -2.246],
                        [-2.246, 0],
                        [0, 2.246],
                      ],
                      v: [
                        [0, 4.066],
                        [4.066, 0.001],
                        [0, -4.066],
                        [-4.066, 0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.258823529412, 0.521568627451, 0.956862804936, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [37.625, 87.67], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 62',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 61,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.246, 0],
                        [0, 2.246],
                        [2.246, 0],
                        [0, -2.246],
                      ],
                      o: [
                        [2.246, 0],
                        [0, -2.246],
                        [-2.246, 0],
                        [0, 2.256],
                      ],
                      v: [
                        [0, 4.066],
                        [4.066, 0.001],
                        [0, -4.066],
                        [-4.066, 0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.258823529412, 0.521568627451, 0.956862804936, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [26.641, 87.701], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 63',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 62,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.682, 0],
                        [0, 0],
                        [0, 0.681],
                        [0, 0],
                        [-0.681, 0],
                        [0, 0],
                        [0, -0.681],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-0.691, 0],
                        [0, 0],
                        [0, -0.692],
                        [0, 0],
                        [0.692, 0],
                        [0, 0],
                        [0, 0.692],
                      ],
                      v: [
                        [52.274, 3.8],
                        [-52.242, 4.174],
                        [-53.487, 2.938],
                        [-53.508, -2.554],
                        [-52.274, -3.8],
                        [52.242, -4.174],
                        [53.487, -2.938],
                        [53.508, 2.555],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.247058838489, 0.247058838489, 0.247058838489, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [76.028, 75.043], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 64',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 63,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-53.088, 29.067],
                        [53.301, 28.684],
                        [53.312, 32.953],
                        [-53.077, 33.336],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-53.119, 19.497],
                        [53.269, 19.114],
                        [53.28, 23.382],
                        [-53.109, 23.766],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-53.151, 9.927],
                        [53.237, 9.544],
                        [53.248, 13.812],
                        [-53.141, 14.196],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 3,
                  ty: 'sh',
                  ix: 4,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-53.184, 0.357],
                        [53.205, -0.026],
                        [53.216, 4.242],
                        [-53.173, 4.626],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 4',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 4,
                  ty: 'sh',
                  ix: 5,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [58.634, 38.243],
                        [58.592, 25.203],
                        [58.581, 23.372],
                        [58.571, 19.103],
                        [58.571, 18.358],
                        [58.528, 6.329],
                        [58.516, 4.232],
                        [58.507, -0.037],
                        [58.496, -1.527],
                        [58.464, -9.341],
                        [53.163, -9.32],
                        [53.173, -5.317],
                        [-53.215, -4.934],
                        [-53.226, -8.936],
                        [58.464, -9.341],
                        [58.358, -38.658],
                        [-58.634, -38.243],
                        [-58.527, -8.926],
                        [-54.152, -8.936],
                        [-58.527, -8.926],
                        [-58.517, -4.923],
                        [-58.495, 0.378],
                        [-58.484, 4.647],
                        [-58.464, 9.948],
                        [-58.453, 14.217],
                        [-58.432, 19.518],
                        [-58.421, 23.787],
                        [-58.4, 29.088],
                        [-58.389, 33.357],
                        [-58.367, 38.658],
                        [-53.067, 38.637],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 5',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 1,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.188235309077, 0.188235309077, 0.188235309077, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [76.13, 105.175], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 65',
              np: 7,
              cix: 2,
              bm: 0,
              ix: 64,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [5.493, -0.021],
                        [0, 0],
                        [-0.022, -5.504],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-5.504, 0.021],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.02, -5.504],
                      ],
                      v: [
                        [48.41, -22.095],
                        [-48.579, -21.754],
                        [-58.543, -11.715],
                        [-58.426, 22.116],
                        [58.565, 21.699],
                        [58.448, -12.13],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-0.009, -5.057],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-5.057, 0.011],
                      ],
                      o: [
                        [5.057, -0.021],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.021, -5.057],
                        [0, 0],
                      ],
                      v: [
                        [48.41, -21.264],
                        [57.607, -12.13],
                        [57.724, 20.87],
                        [-57.596, 21.275],
                        [-57.713, -11.725],
                        [-48.579, -20.923],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 1,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.239215701234, 0.239215701234, 0.239215701234, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [75.934, 44.805], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 66',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 65,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.224, 1.48],
                        [2.129, 0.713],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.458, 0.096],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.341, 0.085],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.032, -0.022],
                        [0.021, -0.351],
                        [0, 0],
                        [-0.064, -0.021],
                        [0.064, 0.011],
                        [0, 0],
                        [0.479, 0.118],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.393, -0.095],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.437, -0.107],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.904, 2.565],
                        [1.511, 0.777],
                      ],
                      o: [
                        [0.298, -2.012],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.447, -0.106],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.36, -0.074],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.681, 0.159],
                        [0, 0],
                        [0.053, 0.011],
                        [-0.054, -0.01],
                        [0, 0],
                        [-0.085, 0.213],
                        [0.011, 0.022],
                        [0, 0],
                        [0, 0],
                        [0.404, 0.096],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.458, 0.117],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.875, 0.522],
                        [0.724, -2.066],
                        [1.117, -0.256],
                      ],
                      v: [
                        [7.175, -1.916],
                        [3.789, -5.716],
                        [4.471, -8.441],
                        [2.778, -8.846],
                        [2.118, -6.185],
                        [0.766, -6.493],
                        [1.437, -9.165],
                        [-0.245, -9.57],
                        [-0.928, -6.845],
                        [-2.002, -7.089],
                        [-2.002, -7.1],
                        [-4.333, -7.664],
                        [-4.78, -5.887],
                        [-3.556, -5.588],
                        [-2.768, -4.63],
                        [-3.545, -1.522],
                        [-3.375, -1.469],
                        [-3.556, -1.511],
                        [-4.642, 2.843],
                        [-5.419, 3.236],
                        [-6.643, 2.938],
                        [-7.473, 4.844],
                        [-5.28, 5.376],
                        [-4.078, 5.674],
                        [-4.769, 8.432],
                        [-3.088, 8.836],
                        [-2.406, 6.111],
                        [-1.054, 6.452],
                        [-1.736, 9.166],
                        [-0.054, 9.57],
                        [0.638, 6.824],
                        [6.578, 4.557],
                        [5.014, 0.533],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.532, -2.15],
                        [1.139, 0.267],
                        [0, 0],
                      ],
                      o: [
                        [-0.511, 2.065],
                        [0, 0],
                        [1.141, 0.266],
                      ],
                      v: [
                        [3.342, 3.417],
                        [-1.842, 4.109],
                        [-0.928, 0.458],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.489, -1.959],
                        [0.948, 0.223],
                        [0, 0],
                      ],
                      o: [
                        [-0.469, 1.874],
                        [0, 0],
                        [0.958, 0.234],
                      ],
                      v: [
                        [3.843, -1.937],
                        [-0.512, -1.234],
                        [0.308, -4.556],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 1,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [109.907, 44.939], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 67',
              np: 5,
              cix: 2,
              bm: 0,
              ix: 66,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.033, -8.24],
                        [8.25, 2.022],
                        [-2.023, 8.24],
                        [-8.24, -2.023],
                      ],
                      o: [
                        [-2.021, 8.239],
                        [-8.24, -2.023],
                        [2.023, -8.239],
                        [8.26, 2.023],
                      ],
                      v: [
                        [14.914, 3.662],
                        [-3.672, 14.915],
                        [-14.924, -3.661],
                        [3.652, -14.913],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.968627510819, 0.576470588235, 0.101960791794, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0.269, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [110.59, 45.502], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 68',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 67,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.033, -8.24],
                        [8.25, 2.022],
                        [-2.023, 8.24],
                        [-8.24, -2.023],
                      ],
                      o: [
                        [-2.021, 8.239],
                        [-8.24, -2.023],
                        [2.023, -8.239],
                        [8.26, 2.023],
                      ],
                      v: [
                        [14.914, 3.662],
                        [-3.672, 14.915],
                        [-14.924, -3.661],
                        [3.652, -14.913],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.968627510819, 0.576470588235, 0.101960791794, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [110.59, 45.502], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 69',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 68,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [11.72, -21.951],
                            [-1.469, 21.919],
                            [-11.72, 21.951],
                            [1.48, -21.918],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.56862745098, 0.56862745098, 0.56862745098, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [85.252, 44.757], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Group 70',
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [20.029, -21.983],
                            [6.839, 21.886],
                            [-20.03, 21.982],
                            [-6.84, -21.887],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.56862745098, 0.56862745098, 0.56862745098, 1],
                        ix: 4,
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [61.787, 44.843], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Group 71',
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 2,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [69.365, 44.816], ix: 2 },
                  a: { a: 0, k: [69.365, 44.816], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 15, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 81',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 69,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.022, -5.493],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-5.503, 0.022],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.022, -5.504],
                        [0, 0],
                        [5.504, -0.031],
                      ],
                      v: [
                        [58.443, -12.131],
                        [58.56, 21.689],
                        [-58.421, 22.115],
                        [-58.538, -11.704],
                        [-48.575, -21.743],
                        [48.404, -22.084],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.188235309077, 0.188235309077, 0.188235309077, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [75.929, 44.805], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 72',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 70,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [5.493, -0.021],
                        [0, 0],
                        [-0.022, -5.504],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-5.504, 0.021],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.02, -5.504],
                      ],
                      v: [
                        [48.41, -22.095],
                        [-48.579, -21.754],
                        [-58.543, -11.715],
                        [-58.426, 22.116],
                        [58.565, 21.699],
                        [58.448, -12.13],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.188235309077, 0.188235309077, 0.188235309077, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [75.934, 44.805], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 73',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 71,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.385, 0],
                        [0, 2.395],
                        [2.395, 0],
                        [0, -2.396],
                      ],
                      o: [
                        [2.395, 0],
                        [0, -2.385],
                        [-2.396, 0],
                        [0.011, 2.395],
                      ],
                      v: [
                        [0.001, 4.332],
                        [4.332, 0.001],
                        [0.001, -4.332],
                        [-4.332, 0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.117647066303, 0.117647066303, 0.117647066303, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [76.172, 117.347], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 74',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 72,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.947, -0.245],
                        [0.98, -0.127],
                        [3.576, 1.746],
                        [-0.15, 0.575],
                        [-2.257, 1.011],
                        [-1.618, 1.469],
                        [0.096, 0.447],
                        [3.608, 3.459],
                        [0.266, -0.947],
                        [0.372, -0.916],
                        [3.289, -2.225],
                        [0.426, 0.426],
                        [-0.245, 2.449],
                        [0.458, 2.139],
                        [0.437, 0.139],
                        [4.801, -1.395],
                        [-0.692, -0.703],
                        [-0.607, -0.777],
                        [-0.362, -3.938],
                        [0.608, -0.16],
                        [1.959, 1.416],
                        [2.086, 0.682],
                        [0.351, -0.309],
                        [1.193, -4.855],
                        [-0.947, 0.256],
                        [-0.98, 0.127],
                        [-3.598, -1.576],
                        [0.159, -0.649],
                        [2.194, -0.979],
                        [1.618, -1.469],
                        [-0.096, -0.448],
                        [-3.609, -3.46],
                        [-0.265, 0.948],
                        [-0.372, 0.916],
                        [-3.194, 2.311],
                        [-0.469, -0.437],
                        [0.255, -2.395],
                        [-0.457, -2.14],
                        [-0.436, -0.139],
                        [-4.8, 1.395],
                        [0.693, 0.703],
                        [0.606, 0.777],
                        [0.341, 3.95],
                        [-0.574, 0.17],
                        [-1.992, -1.437],
                        [-2.085, -0.681],
                        [-0.34, 0.308],
                        [-1.192, 4.854],
                      ],
                      o: [
                        [-0.958, 0.255],
                        [-3.311, 0.479],
                        [0.276, -0.511],
                        [4.215, -0.011],
                        [2.097, -0.926],
                        [0.341, -0.309],
                        [-0.99, -4.748],
                        [-0.714, -0.671],
                        [-0.256, 0.969],
                        [-1.236, 3.098],
                        [-0.309, -0.511],
                        [2.076, -3.64],
                        [0.245, -2.288],
                        [-0.096, -0.448],
                        [-4.598, -1.522],
                        [-0.937, 0.276],
                        [0.713, 0.702],
                        [2.033, 2.576],
                        [-0.649, 0.011],
                        [-2.118, -3.534],
                        [-1.853, -1.351],
                        [-0.436, -0.138],
                        [-3.609, 3.225],
                        [-0.234, 0.947],
                        [0.958, -0.255],
                        [3.204, -0.469],
                        [-0.309, 0.564],
                        [-4.077, 0.075],
                        [-2.097, 0.926],
                        [-0.34, 0.309],
                        [0.99, 4.747],
                        [0.712, 0.671],
                        [0.256, -0.968],
                        [1.204, -3.012],
                        [0.351, 0.533],
                        [-2.001, 3.587],
                        [-0.246, 2.289],
                        [0.096, 0.447],
                        [4.599, 1.522],
                        [0.938, -0.276],
                        [-0.712, -0.702],
                        [-2.044, -2.598],
                        [0.628, -0.031],
                        [2.119, 3.598],
                        [1.851, 1.352],
                        [0.437, 0.139],
                        [3.61, -3.226],
                        [0.245, -0.937],
                      ],
                      v: [
                        [22.297, 4.322],
                        [19.38, 4.907],
                        [6.606, 3.545],
                        [7.234, 1.895],
                        [17.964, -0.245],
                        [23.606, -3.875],
                        [24, -5.099],
                        [17.017, -17.745],
                        [14.877, -17.139],
                        [13.93, -14.318],
                        [6.382, -3.96],
                        [5.285, -5.387],
                        [8.756, -15.681],
                        [8.437, -22.376],
                        [7.574, -23.324],
                        [-6.871, -23.6],
                        [-7.414, -21.439],
                        [-5.445, -19.215],
                        [-0.261, -7.697],
                        [-2.135, -7.441],
                        [-9.203, -15.415],
                        [-15.164, -18.491],
                        [-16.42, -18.214],
                        [-23.883, -5.844],
                        [-22.286, -4.301],
                        [-19.369, -4.886],
                        [-6.946, -3.694],
                        [-7.648, -1.874],
                        [-17.964, 0.255],
                        [-23.606, 3.885],
                        [-24, 5.11],
                        [-17.016, 17.756],
                        [-14.877, 17.149],
                        [-13.93, 14.328],
                        [-6.648, 4.151],
                        [-5.413, 5.61],
                        [-8.766, 15.68],
                        [-8.447, 22.376],
                        [-7.585, 23.324],
                        [6.86, 23.6],
                        [7.403, 21.439],
                        [5.435, 19.215],
                        [0.239, 7.632],
                        [2.038, 7.313],
                        [9.193, 15.425],
                        [15.153, 18.501],
                        [16.409, 18.225],
                        [23.872, 5.855],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.247058838489, 0.247058838489, 0.247058838489, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [76.165, 117.326], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 75',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 73,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [58.384, -25.309],
                        [-58.565, -24.894],
                        [-58.385, 25.309],
                        [58.565, 24.894],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.117647066303, 0.117647066303, 0.117647066303, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [76.186, 118.524], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 76',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 74,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.022, -5.493],
                        [0, 0],
                        [5.504, -0.021],
                        [0, 0],
                        [0.022, 5.503],
                        [0, 0],
                        [-5.503, 0.021],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0.022, 5.504],
                        [0, 0],
                        [-5.502, 0.021],
                        [0, 0],
                        [-0.022, -5.503],
                        [0, 0],
                        [5.504, -0.021],
                      ],
                      v: [
                        [58.321, -48.697],
                        [58.66, 48.271],
                        [48.697, 58.309],
                        [-48.283, 58.661],
                        [-58.321, 48.697],
                        [-58.66, -48.282],
                        [-48.697, -58.32],
                        [48.282, -58.661],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.149019607843, 0.149019607843, 0.149019607843, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [76.052, 81.372], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 77',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 75,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [5.833, -0.021],
                        [0, 0],
                        [-0.021, -5.972],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-3.673, 0],
                        [0, 0],
                        [0, 3.673],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-0.021, -5.971],
                        [0, 0],
                        [-5.834, 0.021],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 3.673],
                        [0, 0],
                        [3.673, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [62.264, 46.233],
                        [61.86, -67.864],
                        [51.215, -78.69],
                        [-51.672, -78.328],
                        [-62.243, -67.427],
                        [-61.828, 48.756],
                        [-61.817, 48.756],
                        [-61.817, 72.058],
                        [-55.164, 78.711],
                        [55.6, 78.711],
                        [62.254, 72.058],
                        [62.254, 46.233],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.149019607843, 0.149019607843, 0.149019607843, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [76.067, 97.835], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 78',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 76,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-8.378, 0],
                        [0, 0],
                        [0, -8.378],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, -8.378],
                        [0, 0],
                        [8.378, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-75.746, 183.45],
                        [-75.746, -168.28],
                        [-60.577, -183.45],
                        [60.577, -183.45],
                        [75.746, -168.28],
                        [75.746, 183.45],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.188235309077, 0.188235309077, 0.188235309077, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [75.996, 183.7], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 79',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 77,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 150,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: 'blicks',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [421, 262, 0], ix: 2, l: 2 },
        a: { a: 0, k: [421, 262, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 842,
      h: 524,
      ip: 0,
      op: 150,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'bag Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 0,
              s: [352.805, 385.876, 0],
              e: [352.805, 369.876, 0],
              to: [0, -2.667, 0],
              ti: [0, 2.667, 0],
            },
            {
              i: { x: 0.682, y: 0.682 },
              o: { x: 0.167, y: 0.167 },
              t: 15,
              s: [352.805, 369.876, 0],
              e: [352.805, 369.876, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.791, y: 0 },
              t: 80,
              s: [352.805, 369.876, 0],
              e: [352.805, 385.876, 0],
              to: [0, 2.667, 0],
              ti: [0, -2.667, 0],
            },
            { t: 94 },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [126.058, 149.256, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.763, 21.771],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.776, -21.77],
                    [0, 0],
                  ],
                  v: [
                    [25.86, 19.669],
                    [-24.084, -0.001],
                    [20.143, -21.662],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.278431372549, 0.278431372549, 0.278431372549, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 29.471, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [108.766, 95.448], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.308, 8.723],
                    [12.6, 4.28],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [2.678, 0.599],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [2.069, 0.501],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.151, -0.109],
                    [0.121, -2.091],
                    [0, 0],
                    [-0.393, -0.152],
                    [0.371, 0.087],
                    [0, 0],
                    [2.821, 0.697],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-2.331, -0.588],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-2.581, -0.642],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-5.38, 15.16],
                    [8.951, 4.585],
                  ],
                  o: [
                    [1.808, -11.915],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-2.625, -0.642],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-2.167, -0.491],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [4.04, 0.992],
                    [0, 0],
                    [0.282, 0.066],
                    [-0.327, -0.076],
                    [0, 0],
                    [-0.49, 1.209],
                    [0.098, 0.141],
                    [0, 0],
                    [0, 0],
                    [2.417, 0.599],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [2.723, 0.73],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [17.021, 3.169],
                    [4.345, -12.196],
                    [6.533, -1.481],
                  ],
                  v: [
                    [42.49, -11.108],
                    [22.485, -33.706],
                    [26.568, -49.846],
                    [16.593, -52.297],
                    [12.617, -36.582],
                    [4.623, -38.433],
                    [8.631, -54.257],
                    [-1.346, -56.707],
                    [-5.441, -40.567],
                    [-11.811, -42.038],
                    [-11.799, -42.092],
                    [-25.566, -45.479],
                    [-28.223, -34.981],
                    [-20.969, -33.205],
                    [-16.321, -27.488],
                    [-20.969, -9.094],
                    [-19.934, -8.767],
                    [-20.993, -9.028],
                    [-27.514, 16.728],
                    [-32.089, 19.059],
                    [-39.342, 17.272],
                    [-44.298, 28.522],
                    [-31.316, 31.713],
                    [-24.204, 33.521],
                    [-28.331, 49.846],
                    [-18.368, 52.297],
                    [-14.273, 36.146],
                    [-6.322, 38.182],
                    [-10.396, 54.257],
                    [-0.42, 56.707],
                    [3.709, 40.415],
                    [38.907, 27.15],
                    [29.736, 3.322],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [3.213, -12.731],
                    [6.763, 1.655],
                    [0, 0],
                  ],
                  o: [
                    [-3.082, 12.198],
                    [0, 0],
                    [6.762, 1.655],
                  ],
                  v: [
                    [19.696, 20.376],
                    [-11.016, 24.33],
                    [-5.537, 2.701],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 2,
              ty: 'sh',
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [2.93, -11.576],
                    [5.63, 1.384],
                    [0, 0],
                  ],
                  o: [
                    [-2.81, 11.098],
                    [0, 0],
                    [5.63, 1.384],
                  ],
                  v: [
                    [22.778, -11.283],
                    [-3.033, -7.21],
                    [1.933, -26.824],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 3',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [117.467, 202.495], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 5,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.653, 1.241],
                    [2.755, 9.17],
                    [-1.852, -0.207],
                    [-0.109, -0.49],
                    [5.826, -9.769],
                    [1.644, 0.914],
                  ],
                  o: [
                    [4.367, -8.234],
                    [-0.534, -1.786],
                    [1.58, 0.174],
                    [3.963, 10.672],
                    [-0.774, 1.089],
                    [-1.242, -0.686],
                  ],
                  v: [
                    [-6.163, 12.693],
                    [-2.918, -13.531],
                    [-0.163, -16.874],
                    [2.854, -15.981],
                    [-0.24, 15.818],
                    [-5.129, 16.168],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [132.039, 105.59], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [15.302, 12.023],
                    [-1.089, -1.1],
                    [-5.957, -17.577],
                    [2.133, 0.065],
                  ],
                  o: [
                    [-6.099, -17.98],
                    [2.188, -1.089],
                    [13.907, 11.762],
                    [0.579, 2.048],
                    [0, 0],
                  ],
                  v: [
                    [12.3, 25.315],
                    [-18.945, -21.09],
                    [-12.388, -24.368],
                    [18.366, 21.395],
                    [15.23, 25.402],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [148.02, 111.34], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.862, 0],
                    [0, 0],
                    [0, 1.863],
                    [-1.863, 0],
                    [0, 0],
                    [0, -1.862],
                  ],
                  o: [
                    [0, 0],
                    [-1.863, 0],
                    [0, -1.862],
                    [0, 0],
                    [1.862, 0],
                    [0, 1.863],
                  ],
                  v: [
                    [24.532, 3.376],
                    [-24.531, 3.376],
                    [-27.907, 0],
                    [-24.531, -3.376],
                    [24.532, -3.376],
                    [27.907, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [123.08, 93.98], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.774, -0.196],
                    [0, 0],
                    [0.196, 1.776],
                    [0, 0],
                    [-1.775, 0.196],
                    [0, 0],
                    [-0.195, -1.775],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.775, 0.207],
                    [0, 0],
                    [-0.207, -1.775],
                    [0, 0],
                    [1.774, -0.207],
                    [0, 0],
                    [0.206, 1.775],
                  ],
                  v: [
                    [22.773, 0.768],
                    [-22.02, 5.876],
                    [-25.593, 3.033],
                    [-25.615, 2.805],
                    [-22.772, -0.768],
                    [22.021, -5.876],
                    [25.592, -3.034],
                    [25.616, -2.804],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.819607902976, 0.466666696586, 0.082352941176, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [124.348, 87.748], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [1.563, -17.131],
                    [-13.489, 17.131],
                    [13.489, -17.131],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.819607902976, 0.466666696586, 0.082352941176, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [105.665, 112.537], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 7',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [4.552, 0.643],
                    [4.106, 1.046],
                    [0.479, -0.73],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-4.552, -0.642],
                    [-0.828, -0.207],
                    [-0.37, 0.556],
                    [0, 0],
                  ],
                  v: [
                    [19.091, 25.125],
                    [6.305, -23.807],
                    [1.415, -23.807],
                    [-7.242, -25.495],
                    [-18.721, -25.495],
                    [14.725, 26.225],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.819607902976, 0.466666696586, 0.082352941176, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [96.307, 64.769], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 8',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 8,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [77.639, -17.861],
                    [42.244, 122.716],
                    [0, 0],
                    [0, 0],
                    [5.456, -1.754],
                    [9.225, 10.096],
                    [0, 0],
                    [0, 0],
                    [-10.499, -60.192],
                  ],
                  o: [
                    [65.616, -0.272],
                    [-22.969, -66.704],
                    [0, 0],
                    [2.406, -5.206],
                    [0, 0],
                    [0, 0],
                    [-4.139, -5.097],
                    [0, 0],
                    [6.153, 35.341],
                  ],
                  v: [
                    [-50.997, 125.693],
                    [37.576, 14.151],
                    [-31.589, -68.682],
                    [-0.628, -111.243],
                    [-8.61, -120.271],
                    [-35.805, -124.617],
                    [-36.577, -125.575],
                    [-42.611, -67.866],
                    [0.004, 37.424],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.819607913494, 0.466666698456, 0.082352943718, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [172.041, 161.394], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 9',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 9,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-64.516, 0.458],
                    [42.245, 122.715],
                    [0, 0],
                    [0, 0],
                    [5.457, -1.754],
                    [2.919, -0.491],
                    [3.703, 4.552],
                    [0, 0],
                    [5.467, -3.638],
                    [0, 0],
                    [6.665, 2.255],
                    [0, 0],
                    [-1.078, -1.764],
                    [0, 0],
                    [21.674, -57.121],
                  ],
                  o: [
                    [65.616, -0.468],
                    [-22.969, -66.705],
                    [0, 0],
                    [2.407, -5.206],
                    [0, 0],
                    [-5.783, 0.958],
                    [0, 0],
                    [-4.138, -5.097],
                    [0, 0],
                    [-5.858, 3.898],
                    [-4.541, -1.535],
                    [-2.058, -0.24],
                    [0, 0],
                    [0, 0],
                    [-40.196, 105.9],
                  ],
                  v: [
                    [-5.01, 128.592],
                    [83.563, 17.05],
                    [14.398, -65.783],
                    [45.359, -108.343],
                    [37.377, -117.371],
                    [27.793, -115.781],
                    [10.172, -121.717],
                    [9.399, -122.675],
                    [-7.546, -125.245],
                    [-14.572, -120.562],
                    [-34.055, -120.051],
                    [-53.091, -122.294],
                    [-55.498, -118.537],
                    [-24.144, -67.155],
                    [-85.612, 17.05],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.968627510819, 0.576470588235, 0.101960791794, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [126.058, 158.497], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 10',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 10,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 150,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'shadow',
      sr: 1,
      ks: {
        o: { a: 0, k: 15, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [417.393, 419.315, 0], ix: 2, l: 2 },
        a: { a: 0, k: [55.262, 81.365, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [3.39, -7.25],
                    [0, 0],
                    [3.23, 8.26],
                    [-2.301, 0.91],
                    [1.27, 6.17],
                    [0.129, 0],
                    [0.349, 2.21],
                    [0.009, 7.13],
                    [-0.12, 2.37],
                    [-2.109, 0.271],
                    [-1.21, 5.71],
                    [-0.05, 0.241],
                    [-0.029, 0.151],
                    [-0.55, 2.07],
                    [-4.39, 8.05],
                    [-1.979, -0.59],
                    [-4.371, 4.4],
                    [-1.641, 1.5],
                    [-2.88, 1.949],
                    [-1.141, -0.99],
                    [-1.39, -1.29],
                    [-1.229, -1.219],
                    [-0.29, -0.3],
                    [-1.75, -1.9],
                    [-1.559, -1.79],
                    [-3.12, -4.23],
                    [-1.86, -2.83],
                    [-0.601, -0.96],
                    [0, 0],
                    [-0.431, -0.69],
                    [-0.99, -1.69],
                    [0, 0],
                    [-0.57, -1.03],
                    [-1.14, -2.231],
                    [-0.3, -0.59],
                    [-0.87, -1.889],
                    [-1.74, -4.59],
                    [-1.98, -6.979],
                    [-0.48, -1.95],
                    [-0.679, -4.239],
                    [-0.18, -3.991],
                  ],
                  o: [
                    [0, 0],
                    [-4.711, -6.93],
                    [-0.9, -2.3],
                    [-2.11, -5.719],
                    [-0.131, 0],
                    [-2.17, 0],
                    [-1.071, -6.93],
                    [0, -2.37],
                    [0.109, -2.199],
                    [0.431, -5.95],
                    [0.051, -0.24],
                    [0.03, -0.15],
                    [-1.839, -0.93],
                    [2.509, -9.56],
                    [1.03, -1.88],
                    [3.391, -5.55],
                    [-1.191, -1.799],
                    [2.639, -2.43],
                    [0.93, 0.759],
                    [1.189, 1.04],
                    [1.12, 1.04],
                    [0.29, 0.27],
                    [1.621, 1.61],
                    [1.48, 1.611],
                    [2.981, 3.48],
                    [1.87, 2.54],
                    [0.62, 0.92],
                    [0, 0],
                    [0.43, 0.67],
                    [1.01, 1.61],
                    [0, 0],
                    [0.59, 1],
                    [1.19, 2.11],
                    [0.3, 0.579],
                    [0.91, 1.809],
                    [1.94, 4.191],
                    [3.08, 8.12],
                    [0.59, 2.04],
                    [1.22, 4.781],
                    [0.75, 4.57],
                    [0.52, 11.239],
                  ],
                  v: [
                    [50.678, 81.365],
                    [-34.332, 81.365],
                    [-46.282, 58.484],
                    [-43.742, 52.685],
                    [-48.832, 34.815],
                    [-49.222, 34.825],
                    [-53.642, 31.035],
                    [-55.262, 9.845],
                    [-55.072, 2.704],
                    [-51.173, -1.516],
                    [-48.713, -19.055],
                    [-48.562, -19.775],
                    [-48.463, -20.225],
                    [-50.762, -25.345],
                    [-40.352, -51.895],
                    [-35.173, -54.035],
                    [-23.512, -69.025],
                    [-22.812, -74.775],
                    [-14.512, -81.365],
                    [-11.402, -78.736],
                    [-7.522, -75.245],
                    [-4.003, -71.855],
                    [-3.133, -70.995],
                    [1.938, -65.725],
                    [6.497, -60.626],
                    [15.688, -49.065],
                    [21.308, -41.015],
                    [23.138, -38.205],
                    [23.138, -38.195],
                    [24.428, -36.155],
                    [27.428, -31.195],
                    [27.428, -31.186],
                    [29.168, -28.135],
                    [32.668, -21.624],
                    [33.558, -19.864],
                    [36.228, -14.316],
                    [41.768, -1.146],
                    [49.338, 21.485],
                    [50.948, 27.475],
                    [53.787, 40.995],
                    [55.168, 53.836],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [55.263, 81.365], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 150,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'hand Outlines 2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.413], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 42,
              s: [-80],
              e: [0],
            },
            {
              i: { x: [0.709], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 56,
              s: [0],
              e: [0],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.791], y: [0] },
              t: 80,
              s: [0],
              e: [-80],
            },
            { t: 94 },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.413, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 42,
              s: [564.503, 455.299, 0],
              e: [493.503, 245.299, 0],
              to: [9.167, -144.333, 0],
              ti: [32.997, 25.701, 0],
            },
            {
              i: { x: 0.41, y: 1 },
              o: { x: 0.167, y: 0 },
              t: 56,
              s: [493.503, 245.299, 0],
              e: [507.003, 245.299, 0],
              to: [5.753, -0.201, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.784, y: 1 },
              o: { x: 0.744, y: 0 },
              t: 62,
              s: [507.003, 245.299, 0],
              e: [493.503, 245.299, 0],
              to: [0, 0, 0],
              ti: [4.497, -0.049, 0],
            },
            {
              i: { x: 0.258, y: 1 },
              o: { x: 0.187, y: 0 },
              t: 68,
              s: [493.503, 245.299, 0],
              e: [507.003, 245.299, 0],
              to: [-4.497, 0.049, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.836, y: 1 },
              o: { x: 0.747, y: 0 },
              t: 74,
              s: [507.003, 245.299, 0],
              e: [493.503, 245.299, 0],
              to: [0, 0, 0],
              ti: [4.684, -0.049, 0],
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.791, y: 0 },
              t: 80,
              s: [493.503, 245.299, 0],
              e: [564.503, 455.299, 0],
              to: [84.816, 63.049, 0],
              ti: [-1.22, -33.992, 0],
            },
            { t: 94 },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [98.898, 103.804, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.413, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 42,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-4.533, -17.447],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [4.533, 17.447],
                          [0, 0],
                        ],
                        v: [
                          [-11.549, -3.646],
                          [20.239, 10.861],
                          [-0.916, 37.029],
                        ],
                        c: false,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [0, 0],
                          [-20.061, -37.845],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [4.716, 8.904],
                          [0, 0],
                        ],
                        v: [
                          [-24.955, -35.045],
                          [20.239, 10.861],
                          [0.257, 35.045],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.836, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 56,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-20.061, -37.845],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [4.716, 8.904],
                          [0, 0],
                        ],
                        v: [
                          [-24.955, -35.045],
                          [20.239, 10.861],
                          [0.257, 35.045],
                        ],
                        c: false,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [0, 0],
                          [-20.061, -37.845],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [4.716, 8.904],
                          [0, 0],
                        ],
                        v: [
                          [-24.955, -35.045],
                          [20.239, 10.861],
                          [0.257, 35.045],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.791, y: 0 },
                    t: 80,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-20.061, -37.845],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [4.716, 8.904],
                          [0, 0],
                        ],
                        v: [
                          [-24.955, -35.045],
                          [20.239, 10.861],
                          [0.257, 35.045],
                        ],
                        c: false,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [0, 0],
                          [-4.533, -17.447],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [4.533, 17.447],
                          [0, 0],
                        ],
                        v: [
                          [-11.549, -3.646],
                          [20.239, 10.861],
                          [-0.916, 37.029],
                        ],
                        c: false,
                      },
                    ],
                  },
                  { t: 94 },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.278431372549, 0.278431372549, 0.278431372549, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 20.932, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [77.285, 87.375], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.413, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 42,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-14.543, 1.851],
                          [11.183, -0.75],
                        ],
                        c: false,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-11.183, 0.75],
                          [11.183, -0.75],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.836, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 56,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-11.183, 0.75],
                          [11.183, -0.75],
                        ],
                        c: false,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-11.183, 0.75],
                          [11.183, -0.75],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.791, y: 0 },
                    t: 80,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-11.183, 0.75],
                          [11.183, -0.75],
                        ],
                        c: false,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-14.543, 1.851],
                          [11.183, -0.75],
                        ],
                        c: false,
                      },
                    ],
                  },
                  { t: 94 },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.219607858097, 0.219607858097, 0.219607858097, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 20.932, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [84.358, 102.92], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 150,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'hand Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [520.003, 282.799, 0], ix: 2, l: 2 },
        a: { a: 0, k: [125.398, 141.304, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.413, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 42,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-2.893, -79.978],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-51.575, 31.874],
                          [9.769, 136.46],
                        ],
                        c: false,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [0, 0],
                          [117.107, 127.772],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-39.075, 53.124],
                          [-57.231, -77.29],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.41, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 56,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [117.107, 127.772],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-39.075, 53.124],
                          [-57.231, -77.29],
                        ],
                        c: false,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [0, 0],
                          [113.357, 135.022],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-39.075, 53.124],
                          [-45.731, -78.04],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.784, y: 1 },
                    o: { x: 0.744, y: 0 },
                    t: 62,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [113.357, 135.022],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-39.075, 53.124],
                          [-45.731, -78.04],
                        ],
                        c: false,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [0, 0],
                          [117.107, 127.772],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-39.075, 53.124],
                          [-57.231, -77.29],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.258, y: 1 },
                    o: { x: 0.187, y: 0 },
                    t: 68,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [117.107, 127.772],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-39.075, 53.124],
                          [-57.231, -77.29],
                        ],
                        c: false,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [0, 0],
                          [113.357, 135.022],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-39.075, 53.124],
                          [-45.731, -78.04],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.836, y: 1 },
                    o: { x: 0.747, y: 0 },
                    t: 74,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [113.357, 135.022],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-39.075, 53.124],
                          [-45.731, -78.04],
                        ],
                        c: false,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [0, 0],
                          [117.107, 127.772],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-39.075, 53.124],
                          [-57.231, -77.29],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.791, y: 0 },
                    t: 80,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [117.107, 127.772],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-39.075, 53.124],
                          [-57.231, -77.29],
                        ],
                        c: false,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [0, 0],
                          [-2.893, -79.978],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-51.575, 31.874],
                          [9.769, 136.46],
                        ],
                        c: false,
                      },
                    ],
                  },
                  { t: 94 },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 31, ix: 1 },
              e: { a: 0, k: 62, ix: 2 },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.968627510819, 0.576470588235, 0.101960791794, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 22.336, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [155.769, 176.273], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.413, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 42,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-2.643, -80.478],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-51.575, 31.874],
                          [9.768, 136.46],
                        ],
                        c: false,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [0, 0],
                          [116.857, 127.272],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-39.075, 53.124],
                          [-57.231, -77.29],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.41, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 56,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [116.857, 127.272],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-39.075, 53.124],
                          [-57.231, -77.29],
                        ],
                        c: false,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [0, 0],
                          [113.107, 134.772],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-39.075, 53.124],
                          [-45.731, -78.04],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.784, y: 1 },
                    o: { x: 0.744, y: 0 },
                    t: 62,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [113.107, 134.772],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-39.075, 53.124],
                          [-45.731, -78.04],
                        ],
                        c: false,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [0, 0],
                          [116.857, 127.272],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-39.075, 53.124],
                          [-57.231, -77.29],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.258, y: 1 },
                    o: { x: 0.187, y: 0 },
                    t: 68,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [116.857, 127.272],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-39.075, 53.124],
                          [-57.231, -77.29],
                        ],
                        c: false,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [0, 0],
                          [113.107, 134.772],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-39.075, 53.124],
                          [-45.731, -78.04],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.836, y: 1 },
                    o: { x: 0.747, y: 0 },
                    t: 74,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [113.107, 134.772],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-39.075, 53.124],
                          [-45.731, -78.04],
                        ],
                        c: false,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [0, 0],
                          [116.857, 127.272],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-39.075, 53.124],
                          [-57.231, -77.29],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.791, y: 0 },
                    t: 80,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [116.857, 127.272],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-39.075, 53.124],
                          [-57.231, -77.29],
                        ],
                        c: false,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [0, 0],
                          [-2.643, -80.478],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-51.575, 31.874],
                          [9.768, 136.46],
                        ],
                        c: false,
                      },
                    ],
                  },
                  { t: 94 },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.219607858097, 0.219607858097, 0.219607858097, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 8.718, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [155.769, 176.273], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 150,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'head Outlines',
      parent: 8,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0,
              s: [2],
              e: [0],
            },
            {
              i: { x: [0.413], y: [1] },
              o: { x: [0.33], y: [0] },
              t: 15,
              s: [0],
              e: [0],
            },
            {
              i: { x: [0.41], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 56,
              s: [0],
              e: [-3],
            },
            {
              i: { x: [0.784], y: [1] },
              o: { x: [0.744], y: [0] },
              t: 62,
              s: [-3],
              e: [0],
            },
            {
              i: { x: [0.258], y: [1] },
              o: { x: [0.187], y: [0] },
              t: 68,
              s: [0],
              e: [-3],
            },
            {
              i: { x: [0.836], y: [1] },
              o: { x: [0.747], y: [0] },
              t: 74,
              s: [-3],
              e: [0],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.791], y: [0] },
              t: 80,
              s: [0],
              e: [2],
            },
            { t: 94 },
          ],
          ix: 10,
        },
        p: { a: 0, k: [49.968, 23.513, 0], ix: 2, l: 2 },
        a: { a: 0, k: [71.262, 174.369, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [8.324, 4.597],
                    [-4.596, 8.324],
                    [-8.325, -4.597],
                    [4.598, -8.325],
                  ],
                  o: [
                    [-8.325, -4.597],
                    [4.598, -8.325],
                    [8.326, 4.597],
                    [-4.596, 8.338],
                  ],
                  v: [
                    [-8.325, 15.082],
                    [-15.083, -8.324],
                    [8.324, -15.082],
                    [15.081, 8.324],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.968627510819, 0.576470588235, 0.101960791794, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [86.461, 19.93], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [5.552, -27.148],
                    [-5.552, 27.148],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.207843152214, 0.207843152214, 0.207843152214, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 7.513, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [80.247, 47.529], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [31.758, -19.89],
                          [-3.899, 5.625],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [1.581, -5.783],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [16.868, 12.507],
                          [-37.403, 9.583],
                          [-35.954, -9.702],
                          [-18.895, -19.633],
                          [7.687, -19.028],
                          [27.721, -8.239],
                          [37.535, 4.037],
                          [39.852, 19.633],
                        ],
                        c: true,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [0, 0],
                          [31.758, -19.89],
                          [-3.899, 5.625],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [1.581, -5.783],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [16.868, 12.507],
                          [-37.403, 9.583],
                          [-35.954, -9.702],
                          [-18.895, -19.633],
                          [7.687, -19.028],
                          [27.721, -8.239],
                          [37.535, 4.037],
                          [39.852, 19.633],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 15,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [31.758, -19.89],
                          [-3.899, 5.625],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [1.581, -5.783],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [16.868, 12.507],
                          [-37.403, 9.583],
                          [-35.954, -9.702],
                          [-18.895, -19.633],
                          [7.687, -19.028],
                          [27.721, -8.239],
                          [37.535, 4.037],
                          [39.852, 19.633],
                        ],
                        c: true,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [0, 0],
                          [31.758, -19.89],
                          [-3.899, 5.625],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [1.581, -5.783],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [16.868, 12.507],
                          [-37.403, 9.583],
                          [-35.954, -9.702],
                          [-18.895, -19.633],
                          [7.687, -19.028],
                          [27.721, -8.239],
                          [37.535, 4.037],
                          [39.852, 19.633],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 33,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [31.758, -19.89],
                          [-3.899, 5.625],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [1.581, -5.783],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [16.868, 12.507],
                          [-37.403, 9.583],
                          [-35.954, -9.702],
                          [-18.895, -19.633],
                          [7.687, -19.028],
                          [27.721, -8.239],
                          [37.535, 4.037],
                          [39.852, 19.633],
                        ],
                        c: true,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [0, 0],
                          [33.097, 52.263],
                          [-3.899, 5.625],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [1.581, -5.783],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [17.618, 53.007],
                          [-45.403, 43.333],
                          [-35.954, -9.702],
                          [-18.895, -19.633],
                          [7.687, -19.028],
                          [27.721, -8.239],
                          [37.535, 4.037],
                          [39.852, 19.633],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 37,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [33.097, 52.263],
                          [-3.899, 5.625],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [1.581, -5.783],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [17.618, 53.007],
                          [-45.403, 43.333],
                          [-35.954, -9.702],
                          [-18.895, -19.633],
                          [7.687, -19.028],
                          [27.721, -8.239],
                          [37.535, 4.037],
                          [39.852, 19.633],
                        ],
                        c: true,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [0, 0],
                          [31.758, -19.89],
                          [-3.899, 5.625],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [1.581, -5.783],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [21.493, 10.007],
                          [-37.403, 9.583],
                          [-35.954, -9.702],
                          [-18.895, -19.633],
                          [7.687, -19.028],
                          [27.721, -8.239],
                          [37.535, 4.037],
                          [39.852, 19.633],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.693, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 41,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [31.758, -19.89],
                          [-3.899, 5.625],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [1.581, -5.783],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [21.493, 10.007],
                          [-37.403, 9.583],
                          [-35.954, -9.702],
                          [-18.895, -19.633],
                          [7.687, -19.028],
                          [27.721, -8.239],
                          [37.535, 4.037],
                          [39.852, 19.633],
                        ],
                        c: true,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [0, 0],
                          [31.758, -19.89],
                          [-3.899, 5.625],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [1.581, -5.783],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [21.493, 10.007],
                          [-37.403, 9.583],
                          [-35.954, -9.702],
                          [-18.895, -19.633],
                          [7.687, -19.028],
                          [27.721, -8.239],
                          [37.535, 4.037],
                          [39.852, 19.633],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.791, y: 0 },
                    t: 80,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [31.758, -19.89],
                          [-3.899, 5.625],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [1.581, -5.783],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [21.493, 10.007],
                          [-37.403, 9.583],
                          [-35.954, -9.702],
                          [-18.895, -19.633],
                          [7.687, -19.028],
                          [27.721, -8.239],
                          [37.535, 4.037],
                          [39.852, 19.633],
                        ],
                        c: true,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [0, 0],
                          [31.758, -19.89],
                          [-3.899, 5.625],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [1.581, -5.783],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [16.868, 12.507],
                          [-37.403, 9.583],
                          [-35.954, -9.702],
                          [-18.895, -19.633],
                          [7.687, -19.028],
                          [27.721, -8.239],
                          [37.535, 4.037],
                          [39.852, 19.633],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 94,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [31.758, -19.89],
                          [-3.899, 5.625],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [1.581, -5.783],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [16.868, 12.507],
                          [-37.403, 9.583],
                          [-35.954, -9.702],
                          [-18.895, -19.633],
                          [7.687, -19.028],
                          [27.721, -8.239],
                          [37.535, 4.037],
                          [39.852, 19.633],
                        ],
                        c: true,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [0, 0],
                          [31.758, -19.89],
                          [-3.899, 5.625],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [1.581, -5.783],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [16.868, 12.507],
                          [-37.403, 9.583],
                          [-35.954, -9.702],
                          [-18.895, -19.633],
                          [7.687, -19.028],
                          [27.721, -8.239],
                          [37.535, 4.037],
                          [39.852, 19.633],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 120,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [31.758, -19.89],
                          [-3.899, 5.625],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [1.581, -5.783],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [16.868, 12.507],
                          [-37.403, 9.583],
                          [-35.954, -9.702],
                          [-18.895, -19.633],
                          [7.687, -19.028],
                          [27.721, -8.239],
                          [37.535, 4.037],
                          [39.852, 19.633],
                        ],
                        c: true,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [0, 0],
                          [33.097, 52.263],
                          [-3.899, 5.625],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [1.581, -5.783],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [17.618, 53.007],
                          [-45.403, 43.333],
                          [-35.954, -9.702],
                          [-18.895, -19.633],
                          [7.687, -19.028],
                          [27.721, -8.239],
                          [37.535, 4.037],
                          [39.852, 19.633],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 124,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [33.097, 52.263],
                          [-3.899, 5.625],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [1.581, -5.783],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [17.618, 53.007],
                          [-45.403, 43.333],
                          [-35.954, -9.702],
                          [-18.895, -19.633],
                          [7.687, -19.028],
                          [27.721, -8.239],
                          [37.535, 4.037],
                          [39.852, 19.633],
                        ],
                        c: true,
                      },
                    ],
                    e: [
                      {
                        i: [
                          [0, 0],
                          [31.758, -19.89],
                          [-3.899, 5.625],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [1.581, -5.783],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [16.868, 12.507],
                          [-37.403, 9.583],
                          [-35.954, -9.702],
                          [-18.895, -19.633],
                          [7.687, -19.028],
                          [27.721, -8.239],
                          [37.535, 4.037],
                          [39.852, 19.633],
                        ],
                        c: true,
                      },
                    ],
                  },
                  { t: 128 },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.968627510819, 0.576470588235, 0.101960791794, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [55.618, 105.474], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.132, 2.951],
                    [-2.74, 0.197],
                    [-0.132, -2.951],
                    [2.74, -0.198],
                  ],
                  o: [
                    [-0.132, -2.95],
                    [2.726, -0.211],
                    [0.131, 2.95],
                    [-2.74, 0.197],
                  ],
                  v: [
                    [-4.946, 0.375],
                    [-0.243, -5.341],
                    [4.947, -0.362],
                    [0.244, 5.355],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 0,
                    s: [59.937, 138.328],
                    e: [54.937, 138.328],
                    to: [-0.833, 0],
                    ti: [0.833, 0],
                  },
                  {
                    i: { x: 0.667, y: 0.667 },
                    o: { x: 0.333, y: 0.333 },
                    t: 15,
                    s: [54.937, 138.328],
                    e: [54.937, 138.328],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 33,
                    s: [54.937, 138.328],
                    e: [60.937, 138.328],
                    to: [1, 0],
                    ti: [-1, 0],
                  },
                  {
                    i: { x: 0.693, y: 0.693 },
                    o: { x: 0.333, y: 0.333 },
                    t: 41,
                    s: [60.937, 138.328],
                    e: [60.937, 138.328],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.791, y: 0 },
                    t: 80,
                    s: [60.937, 138.328],
                    e: [59.937, 138.328],
                    to: [-0.167, 0],
                    ti: [0.167, 0],
                  },
                  { t: 94 },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.197, 4.07],
                    [-3.754, 0.289],
                    [-0.184, -4.057],
                    [3.754, -0.29],
                  ],
                  o: [
                    [-0.185, -4.071],
                    [3.754, -0.277],
                    [0.184, 4.07],
                    [-3.754, 0.29],
                  ],
                  v: [
                    [-6.796, 0.507],
                    [-0.341, -7.369],
                    [6.798, -0.52],
                    [0.344, 7.357],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 0,
                    s: [35.564, 122.443],
                    e: [31.564, 130.443],
                    to: [-0.667, 1.333],
                    ti: [0.667, -1.333],
                  },
                  {
                    i: { x: 0.667, y: 0.667 },
                    o: { x: 0.333, y: 0.333 },
                    t: 15,
                    s: [31.564, 130.443],
                    e: [31.564, 130.443],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 33,
                    s: [31.564, 130.443],
                    e: [35.564, 130.443],
                    to: [0.667, 0],
                    ti: [-0.667, 0],
                  },
                  {
                    i: { x: 0.693, y: 0.693 },
                    o: { x: 0.333, y: 0.333 },
                    t: 41,
                    s: [35.564, 130.443],
                    e: [35.564, 130.443],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.791, y: 0 },
                    t: 80,
                    s: [35.564, 130.443],
                    e: [35.564, 122.443],
                    to: [0, -1.333],
                    ti: [0, 1.333],
                  },
                  { t: 94 },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.212, -4.979],
                    [-13.514, -3.109],
                    [-1.211, 4.979],
                    [13.528, 3.096],
                  ],
                  o: [
                    [-1.212, 4.993],
                    [13.515, 3.109],
                    [1.212, -4.992],
                    [-13.515, -3.108],
                  ],
                  v: [
                    [-24.487, -4.881],
                    [-1.727, 0.52],
                    [24.487, 6.395],
                    [2.173, -8.266],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.207843152214, 0.207843152214, 0.207843152214, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [72.482, 79.091], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.171, 13.686],
                    [-7.864, 0.132],
                    [0.158, -13.686],
                    [7.864, -0.132],
                  ],
                  o: [
                    [0.158, -13.673],
                    [7.877, -0.132],
                    [-0.158, 13.673],
                    [-7.877, 0.132],
                  ],
                  v: [
                    [-14.246, 0.243],
                    [0.296, -24.77],
                    [14.259, -0.244],
                    [-0.283, 24.77],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.207843152214, 0.207843152214, 0.207843152214, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 0,
                    s: [47.096, 136.895],
                    e: [42.096, 143.895],
                    to: [-0.833, 1.167],
                    ti: [0.833, -1.167],
                  },
                  {
                    i: { x: 0.667, y: 0.667 },
                    o: { x: 0.333, y: 0.333 },
                    t: 15,
                    s: [42.096, 143.895],
                    e: [42.096, 143.895],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  {
                    i: { x: 0.667, y: 0.44 },
                    o: { x: 0.333, y: 0 },
                    t: 33,
                    s: [42.096, 143.895],
                    e: [44.596, 146.895],
                    to: [0.417, -0.5],
                    ti: [-1.042, 1.25],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0.267 },
                    t: 37,
                    s: [44.596, 146.895],
                    e: [47.096, 137.895],
                    to: [1.042, -1.25],
                    ti: [-0.417, 0.5],
                  },
                  {
                    i: { x: 0.693, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 41,
                    s: [47.096, 137.895],
                    e: [47.096, 137.895],
                    to: [0.833, -1],
                    ti: [0, 0],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.791, y: 0 },
                    t: 80,
                    s: [47.096, 137.895],
                    e: [47.096, 136.895],
                    to: [0, 0],
                    ti: [0, 0.167],
                  },
                  {
                    i: { x: 0.667, y: 0.667 },
                    o: { x: 0.333, y: 0.333 },
                    t: 94,
                    s: [47.096, 136.895],
                    e: [47.096, 136.895],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 120,
                    s: [47.096, 136.895],
                    e: [47.096, 141.895],
                    to: [0, 0.833],
                    ti: [0, 0],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 124,
                    s: [47.096, 141.895],
                    e: [47.096, 136.895],
                    to: [0, 0],
                    ti: [0, 0.833],
                  },
                  { t: 128 },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 7',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.015, 22.459],
                    [-15.569, 1.265],
                    [-1.015, -22.472],
                    [15.571, -1.265],
                  ],
                  o: [
                    [-1.015, -22.459],
                    [15.571, -1.264],
                    [1.013, 22.458],
                    [-15.569, 1.251],
                  ],
                  v: [
                    [-28.194, 2.285],
                    [-1.837, -40.67],
                    [28.197, -2.272],
                    [1.837, 40.683],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 33,
                    s: [44.238, 132.966],
                    e: [47.238, 132.966],
                    to: [0.5, 0],
                    ti: [-0.5, 0],
                  },
                  {
                    i: { x: 0.682, y: 0.682 },
                    o: { x: 0.333, y: 0.333 },
                    t: 41,
                    s: [47.238, 132.966],
                    e: [47.238, 132.966],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.791, y: 0 },
                    t: 80,
                    s: [47.238, 132.966],
                    e: [44.238, 132.966],
                    to: [-0.5, 0],
                    ti: [0.5, 0],
                  },
                  { t: 94 },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 8',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 8,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [30.717, 2.95],
                    [-3.886, 30.903],
                    [-30.717, -2.937],
                    [3.886, -30.903],
                  ],
                  o: [
                    [-30.731, -2.938],
                    [3.886, -30.902],
                    [30.718, 2.938],
                    [-3.886, 30.902],
                  ],
                  v: [
                    [-7.033, 55.936],
                    [-55.627, -5.328],
                    [7.033, -55.949],
                    [55.626, 5.316],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.968627510819, 0.576470588235, 0.101960791794, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [59.762, 130.601], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 9',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 9,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 150,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: 'cryptobot Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [454.14, 409.79, 0], ix: 2, l: 2 },
        a: { a: 0, k: [92.261, 91.14, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.71, 1.31],
                    [9.04, 2.05],
                    [0.259, 0.06],
                    [1.039, -1.13],
                    [0, -0.01],
                    [0.17, -0.81],
                    [0.02, -0.2],
                    [-2.21, -0.48],
                    [-4.75, -2.61],
                    [0, 0],
                    [-2.62, -2.01],
                    [-0.951, 0],
                    [-0.84, 0.79],
                    [-0.16, 0.21],
                    [-0.13, 0.28],
                  ],
                  o: [
                    [-7.98, -6.11],
                    [-0.26, -0.07],
                    [-1.611, -0.34],
                    [0, 0],
                    [-0.519, 0.57],
                    [-0.051, 0.21],
                    [-0.16, 2.19],
                    [5.081, 1.08],
                    [0, 0],
                    [2.769, 1.5],
                    [0.809, 0.62],
                    [1.11, 0],
                    [0.19, -0.16],
                    [0.19, -0.25],
                    [0.891, -1.87],
                  ],
                  v: [
                    [36.701, -73.53],
                    [11.051, -85.82],
                    [10.261, -86],
                    [6.031, -84.64],
                    [6.02, -84.64],
                    [4.951, -82.55],
                    [4.861, -81.93],
                    [8.39, -77.24],
                    [23.15, -71.7],
                    [23.161, -71.7],
                    [31.251, -66.42],
                    [33.971, -65.5],
                    [37.011, -66.69],
                    [37.531, -67.25],
                    [38.02, -68.05],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.621, 2.31],
                    [0.021, 0.06],
                    [0.52, 0.51],
                    [1.63, -0.51],
                    [4.151, -2.41],
                    [1.31, -0.9],
                    [2.639, -2.43],
                    [-1.19, -1.8],
                    [-0.17, -0.18],
                    [-1.21, 0],
                    [-0.86, 0.8],
                    [-3.21, 2],
                    [-0.5, 0.29],
                    [-1.691, 0.75],
                    [-2.04, 0.64],
                  ],
                  o: [
                    [-0.01, -0.06],
                    [-0.229, -0.74],
                    [-1.141, -1.1],
                    [-4.41, 1.38],
                    [-1.349, 0.78],
                    [-2.879, 1.95],
                    [-1.641, 1.5],
                    [0.121, 0.2],
                    [0.89, 0.96],
                    [1.08, 0],
                    [2.91, -2.68],
                    [0.5, -0.31],
                    [1.639, -0.95],
                    [2, -0.92],
                    [2.3, -0.72],
                  ],
                  v: [
                    [-13.51, -81.5],
                    [-13.56, -81.68],
                    [-14.719, -83.57],
                    [-19.169, -84.62],
                    [-32.07, -78.91],
                    [-36.07, -76.39],
                    [-44.369, -69.8],
                    [-45.07, -64.05],
                    [-44.629, -63.48],
                    [-41.329, -62.03],
                    [-38.299, -63.22],
                    [-29.079, -70.27],
                    [-27.579, -71.17],
                    [-22.579, -73.73],
                    [-16.499, -76.07],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 2,
              ty: 'sh',
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.809, 2.32],
                    [5.05, 7.62],
                    [2.059, -1.36],
                    [0.13, -0.11],
                    [-1.261, -1.91],
                    [-3.029, -8.68],
                    [-1.851, 0],
                    [-0.491, 0.17],
                    [-0.019, 0.01],
                  ],
                  o: [
                    [-3.281, -9.39],
                    [-1.361, -2.06],
                    [-0.15, 0.1],
                    [-1.7, 1.46],
                    [4.639, 7],
                    [0.651, 1.85],
                    [0.49, 0],
                    [0.02, -0.01],
                    [2.311, -0.83],
                  ],
                  v: [
                    [68.761, -26.66],
                    [56.211, -52.29],
                    [50.011, -53.55],
                    [49.581, -53.23],
                    [48.751, -47.34],
                    [60.31, -23.71],
                    [64.541, -20.7],
                    [66.011, -20.96],
                    [66.06, -20.98],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 3',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 3,
              ty: 'sh',
              ix: 4,
              ks: {
                a: 0,
                k: {
                  i: [
                    [2.17, 1.18],
                    [0.3, 0.08],
                    [1.03, -1.88],
                    [2.51, -9.56],
                    [-1.84, -0.93],
                    [-0.01, 0],
                    [-0.311, -0.08],
                    [-0.38, 0],
                    [-0.531, 2.01],
                    [-4.051, 7.42],
                  ],
                  o: [
                    [-0.29, -0.16],
                    [-1.979, -0.59],
                    [-4.39, 8.05],
                    [-0.549, 2.07],
                    [0, 0],
                    [0.271, 0.14],
                    [0.38, 0.1],
                    [1.979, 0],
                    [2.33, -8.87],
                    [1.18, -2.17],
                  ],
                  v: [
                    [-55.829, -48.7],
                    [-56.73, -49.06],
                    [-61.909, -46.92],
                    [-72.32, -20.37],
                    [-70.02, -15.25],
                    [-70.01, -15.24],
                    [-69.129, -14.9],
                    [-67.989, -14.75],
                    [-63.659, -18.09],
                    [-54.049, -42.63],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 4',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 4,
              ty: 'sh',
              ix: 5,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.52, 8.65],
                    [2.34, -0.28],
                    [0.09, -0.02],
                    [-0.42, -2.44],
                    [0, -8.02],
                    [0.021, -0.71],
                    [-2.47, -0.05],
                    [0, 0],
                    [-0.22, 0.04],
                    [-0.04, 2.21],
                    [0, 0.78],
                  ],
                  o: [
                    [-0.41, -2.35],
                    [-0.091, 0],
                    [-2.439, 0.43],
                    [1.439, 8.14],
                    [0, 0.73],
                    [-0.04, 2.48],
                    [0, 0],
                    [0.229, 0],
                    [2.12, -0.321],
                    [0.019, -0.77],
                    [0, -8.54],
                  ],
                  v: [
                    [74.531, -4.31],
                    [69.611, -7.98],
                    [69.35, -7.95],
                    [65.711, -2.76],
                    [67.871, 21.59],
                    [67.85, 23.75],
                    [72.251, 28.31],
                    [72.331, 28.31],
                    [73.011, 28.25],
                    [76.801, 23.91],
                    [76.82, 21.59],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 5',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 5,
              ty: 'sh',
              ix: 6,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.381, 2.44],
                    [0, 6.67],
                    [-0.12, 2.22],
                    [2.471, 0.131],
                    [0.261, -0.031],
                    [0.109, -2.199],
                    [0, -2.37],
                    [-1.071, -6.93],
                    [-2.17, 0],
                    [-0.131, 0],
                    [-0.101, 0.01],
                  ],
                  o: [
                    [-1, -6.48],
                    [0, -2.22],
                    [0.12, -2.47],
                    [-0.269, -0.009],
                    [-2.109, 0.27],
                    [-0.12, 2.371],
                    [0.01, 7.13],
                    [0.349, 2.21],
                    [0.129, 0],
                    [0.1, -0.02],
                    [2.45, -0.38],
                  ],
                  v: [
                    [-66.35, 34.64],
                    [-67.86, 14.82],
                    [-67.69, 8.13],
                    [-71.94, 3.429],
                    [-72.73, 3.46],
                    [-76.629, 7.679],
                    [-76.82, 14.82],
                    [-75.199, 36.01],
                    [-70.779, 39.8],
                    [-70.389, 39.79],
                    [-70.089, 39.75],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 6',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 6,
              ty: 'sh',
              ix: 7,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.88, 0.87],
                    [0.31, 0.07],
                    [0.56, -2.41],
                    [3.91, -7.54],
                    [-0.72, -1.27],
                    [-0.8, -0.42],
                    [-0.31, -0.08],
                    [-0.38, 0],
                    [-0.791, 1.53],
                    [-1.889, 5.81],
                    [0, 0.01],
                    [-0.82, 3.49],
                  ],
                  o: [
                    [-0.269, -0.13],
                    [-2.411, -0.57],
                    [-2.069, 8.86],
                    [-0.72, 1.399],
                    [0.41, 0.73],
                    [0.3, 0.15],
                    [0.381, 0.1],
                    [1.62, 0],
                    [2.709, -5.21],
                    [0, -0.01],
                    [1.09, -3.33],
                    [0.5, -2.09],
                  ],
                  v: [
                    [70.93, 47.03],
                    [70.051, 46.72],
                    [64.68, 50.06],
                    [55.671, 74.78],
                    [55.74, 79.05],
                    [57.581, 80.82],
                    [58.49, 81.17],
                    [59.631, 81.32],
                    [63.611, 78.91],
                    [70.52, 62.35],
                    [70.531, 62.32],
                    [73.39, 52.09],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 7',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 7,
              ty: 'sh',
              ix: 8,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.639, 0.89],
                    [3.309, 8.479],
                    [2.3, -0.89],
                    [-0.9, -2.3],
                    [-4.711, -6.93],
                    [0, 0],
                  ],
                  o: [
                    [-4.96, -6.91],
                    [-0.901, -2.3],
                    [-2.301, 0.91],
                    [3.229, 8.26],
                    [0, 0],
                    [0.08, -1.01],
                  ],
                  v: [
                    [-47.039, 83.39],
                    [-59.499, 60.2],
                    [-65.299, 57.66],
                    [-67.839, 63.46],
                    [-55.889, 86.34],
                    [-46.219, 86.34],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 8',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.278431372549, 0.278431372549, 0.278431372549, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [77.07, 95.69], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 10,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.439, 0.848],
                    [-0.548, -0.049],
                    [-0.41, -0.628],
                    [-0.015, -0.737],
                    [0.827, -0.426],
                    [1.922, -0.596],
                    [2.636, 0.402],
                    [2.214, 1.482],
                    [1.62, 2.396],
                    [0.22, 7.566],
                    [-3.121, 4.391],
                    [-5.28, -0.116],
                    [-1.953, -0.931],
                    [-1.076, -0.788],
                    [-0.249, -0.487],
                    [-0.023, -0.708],
                    [0.386, -0.548],
                    [0.526, 0.014],
                    [0.299, 0.208],
                    [1.54, 0.691],
                    [2.206, 0.072],
                    [1.689, -0.937],
                    [1.095, -1.676],
                    [-0.16, -5.573],
                    [-2.326, -3.678],
                    [-4.454, -0.607],
                    [-1.496, 0.44],
                  ],
                  o: [
                    [0.291, -0.152],
                    [0.555, 0.075],
                    [0.412, 0.628],
                    [0.033, 1.338],
                    [-1.71, 1.035],
                    [-1.909, 0.594],
                    [-2.596, -0.395],
                    [-2.181, -1.463],
                    [-3.365, -4.945],
                    [-0.217, -7.553],
                    [3.081, -4.383],
                    [2.606, 0.044],
                    [1.962, 0.915],
                    [1.076, 0.788],
                    [0.245, 0.475],
                    [0.027, 0.72],
                    [-0.372, 0.547],
                    [-0.54, -0.01],
                    [-1.504, -1.104],
                    [-1.527, -0.697],
                    [-2.182, -0.078],
                    [-1.678, 0.936],
                    [-2.124, 3.286],
                    [0.161, 5.574],
                    [2.56, 4.021],
                    [2.076, 0.287],
                    [1.493, -0.454],
                  ],
                  v: [
                    [12.616, 17.376],
                    [13.863, 17.229],
                    [15.286, 18.269],
                    [15.934, 20.313],
                    [14.771, 22.972],
                    [9.316, 25.421],
                    [2.504, 25.701],
                    [-4.69, 22.885],
                    [-10.379, 17.101],
                    [-15.75, -1.633],
                    [-11.419, -19.543],
                    [1.068, -25.987],
                    [7.915, -24.54],
                    [12.488, -21.967],
                    [14.469, -20.048],
                    [14.861, -18.258],
                    [14.325, -16.348],
                    [12.958, -15.543],
                    [11.703, -15.891],
                    [7.146, -18.573],
                    [1.549, -19.713],
                    [-4.25, -18.417],
                    [-8.399, -14.515],
                    [-11.328, -1.249],
                    [-7.634, 12.583],
                    [2.839, 19.527],
                    [8.211, 19.301],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 3.548, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [71.151, 113.867], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.439, 0.848],
                    [-0.548, -0.049],
                    [-0.41, -0.628],
                    [-0.015, -0.737],
                    [0.827, -0.426],
                    [1.922, -0.596],
                    [2.636, 0.402],
                    [2.214, 1.482],
                    [1.62, 2.396],
                    [0.22, 7.566],
                    [-3.121, 4.391],
                    [-5.28, -0.116],
                    [-1.953, -0.931],
                    [-1.076, -0.788],
                    [-0.249, -0.487],
                    [-0.023, -0.708],
                    [0.386, -0.548],
                    [0.526, 0.014],
                    [0.299, 0.208],
                    [1.54, 0.691],
                    [2.206, 0.072],
                    [1.689, -0.937],
                    [1.095, -1.676],
                    [-0.16, -5.573],
                    [-2.326, -3.678],
                    [-4.454, -0.607],
                    [-1.496, 0.44],
                  ],
                  o: [
                    [0.291, -0.152],
                    [0.555, 0.075],
                    [0.412, 0.628],
                    [0.033, 1.338],
                    [-1.71, 1.035],
                    [-1.909, 0.594],
                    [-2.596, -0.395],
                    [-2.181, -1.463],
                    [-3.365, -4.945],
                    [-0.217, -7.553],
                    [3.081, -4.383],
                    [2.606, 0.044],
                    [1.962, 0.915],
                    [1.076, 0.788],
                    [0.245, 0.475],
                    [0.027, 0.72],
                    [-0.372, 0.547],
                    [-0.54, -0.01],
                    [-1.504, -1.104],
                    [-1.527, -0.697],
                    [-2.182, -0.078],
                    [-1.678, 0.936],
                    [-2.124, 3.286],
                    [0.161, 5.574],
                    [2.56, 4.021],
                    [2.076, 0.287],
                    [1.493, -0.454],
                  ],
                  v: [
                    [12.616, 17.376],
                    [13.863, 17.229],
                    [15.286, 18.269],
                    [15.934, 20.313],
                    [14.771, 22.972],
                    [9.316, 25.421],
                    [2.504, 25.701],
                    [-4.69, 22.885],
                    [-10.379, 17.101],
                    [-15.75, -1.633],
                    [-11.419, -19.543],
                    [1.068, -25.987],
                    [7.915, -24.54],
                    [12.488, -21.967],
                    [14.469, -20.048],
                    [14.861, -18.258],
                    [14.325, -16.348],
                    [12.958, -15.543],
                    [11.703, -15.891],
                    [7.146, -18.573],
                    [1.549, -19.713],
                    [-4.25, -18.417],
                    [-8.399, -14.515],
                    [-11.328, -1.249],
                    [-7.634, 12.583],
                    [2.839, 19.527],
                    [8.211, 19.301],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [71.151, 113.867], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-3.02, 1.601],
                    [-1.115, -0.187],
                    [-0.871, -1.3],
                    [-0.046, -1.537],
                    [1.709, -0.781],
                    [3.987, -0.951],
                    [5.264, 1.14],
                    [4.249, 3.15],
                    [3.022, 4.774],
                    [0.424, 14.407],
                    [-5.733, 8.704],
                    [-10.292, 0.495],
                    [-3.985, -1.603],
                    [-2.244, -1.504],
                    [-0.506, -0.959],
                    [-0.036, -1.486],
                    [0.793, -1.184],
                    [1.144, 0.009],
                    [0.635, 0.407],
                    [3.163, 1.261],
                    [4.384, -0.065],
                    [3.287, -2.005],
                    [2.056, -3.352],
                    [-0.301, -10.722],
                    [-4.343, -7.218],
                    [-8.749, -1.622],
                    [-3.053, 0.72],
                  ],
                  o: [
                    [0.611, -0.3],
                    [1.139, 0.183],
                    [0.877, 1.327],
                    [0.083, 2.766],
                    [-3.603, 1.907],
                    [-3.909, 0.936],
                    [-5.123, -1.115],
                    [-4.156, -3.088],
                    [-6.256, -9.729],
                    [-0.399, -14.414],
                    [5.748, -8.829],
                    [5.191, -0.257],
                    [4.058, 1.629],
                    [2.277, 1.523],
                    [0.493, 0.961],
                    [0.034, 1.485],
                    [-0.795, 1.172],
                    [-1.128, 0.003],
                    [-3.12, -2.105],
                    [-3.103, -1.233],
                    [-4.301, 0.074],
                    [-3.214, 1.963],
                    [-3.965, 6.5],
                    [0.301, 10.722],
                    [4.853, 8.001],
                    [4.178, 0.771],
                    [3.139, -0.696],
                  ],
                  v: [
                    [24.352, 35.318],
                    [26.954, 35.148],
                    [29.968, 37.394],
                    [31.343, 41.684],
                    [28.886, 47.015],
                    [17.476, 51.302],
                    [3.692, 50.967],
                    [-10.355, 44.554],
                    [-21.106, 32.773],
                    [-31.028, -3.313],
                    [-23.107, -37.892],
                    [0.83, -51.981],
                    [14.606, -49.977],
                    [24.093, -45.278],
                    [28.27, -41.562],
                    [29.08, -37.882],
                    [27.947, -33.886],
                    [25.04, -32.134],
                    [22.399, -32.748],
                    [12.989, -37.786],
                    [1.742, -39.528],
                    [-9.601, -36.411],
                    [-17.498, -28.433],
                    [-22.958, -2.641],
                    [-16.019, 24.201],
                    [4.292, 38.675],
                    [15.149, 38.763],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [69.196, 113.919], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.99, -49.68],
                    [14.139, -12.63],
                    [0, 0],
                    [-0.611, 29.691],
                    [-12.43, 13.7],
                    [-8.549, 2.57],
                    [-2.89, 0.26],
                    [-0.83, 0.03],
                    [-0.441, 0],
                  ],
                  o: [
                    [-0.56, 27.28],
                    [0, 0],
                    [-19.119, -16.01],
                    [0.46, -22.689],
                    [6.421, -7.1],
                    [2.731, -0.82],
                    [0.83, -0.08],
                    [0.44, -0.01],
                    [33.319, -0.12],
                  ],
                  v: [
                    [58.72, 13.01],
                    [34.321, 74.51],
                    [-28.61, 74.51],
                    [-59.099, -0.54],
                    [-38.44, -57.73],
                    [-15.8, -72.58],
                    [-7.36, -74.21],
                    [-4.88, -74.37],
                    [-3.559, -74.39],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.968627510819, 0.576470588235, 0.101960791794, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [73.25, 107.52], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [17.02, -19.279],
                    [0, 0],
                    [3.771, 20.12],
                    [-29.34, 15.73],
                    [-0.67, 0.32],
                    [-0.78, 0.32],
                    [-9.49, -2.009],
                    [-3.63, -1.671],
                    [-6.18, -32.931],
                  ],
                  o: [
                    [0, 0],
                    [-12.02, -12.64],
                    [-7.539, -40.271],
                    [0.669, -0.36],
                    [0.771, -0.37],
                    [9.41, -3.99],
                    [3.77, 0.78],
                    [20.359, 9.389],
                    [6.59, 35.149],
                  ],
                  v: [
                    [43.25, 78.594],
                    [-36.84, 78.594],
                    [-61.601, 27.945],
                    [-25.44, -71.695],
                    [-23.431, -72.715],
                    [-21.11, -73.766],
                    [7.59, -76.585],
                    [18.71, -72.885],
                    [62.55, -7.645],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.219607858097, 0.219607858097, 0.219607858097, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [73.491, 103.435], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.38, -10.01],
                    [1.14, -6.009],
                    [3.5, -7.701],
                    [0, 0],
                    [1.641, -2.81],
                    [1.131, -1.67],
                    [0, 0],
                    [3.89, 10.611],
                    [1.269, 6.17],
                    [0.15, 0.78],
                    [-0.81, 11.24],
                    [-1.21, 5.71],
                    [-0.05, 0.24],
                    [-0.03, 0.15],
                    [-0.02, 0.061],
                    [-0.03, 0.1],
                    [-6.02, 9.81],
                    [-4.37, 4.4],
                    [-4.37, 2.71],
                    [-0.66, 0.38],
                    [-4.07, 1.25],
                    [0, 0],
                    [-1.829, 0.35],
                    [-3.85, -0.09],
                    [-2.261, -0.33],
                    [-8.77, -6.53],
                    [0, 0],
                    [-1.29, -1.11],
                    [-3.86, -5.13],
                    [-4.11, -12.53],
                    [-1, -4.521],
                    [-0.31, -1.65],
                  ],
                  o: [
                    [-0.229, 6.49],
                    [-1.71, 9.09],
                    [0, 0],
                    [-1.39, 3.08],
                    [-1.039, 1.78],
                    [0, 0],
                    [-6.25, -8.36],
                    [-2.11, -5.72],
                    [-0.161, -0.769],
                    [-2.1, -11.25],
                    [0.43, -5.95],
                    [0.051, -0.24],
                    [0.03, -0.15],
                    [0.009, -0.05],
                    [0.02, -0.1],
                    [2.72, -12.15],
                    [3.39, -5.55],
                    [3.7, -3.73],
                    [0.65, -0.4],
                    [3.93, -2.24],
                    [0, 0],
                    [1.809, -0.55],
                    [3.841, -0.76],
                    [2.28, 0.04],
                    [9.829, 1.44],
                    [0, 0],
                    [1.33, 1],
                    [4.49, 3.83],
                    [6.72, 8.91],
                    [1.36, 4.14],
                    [0.369, 1.599],
                    [2.03, 10.82],
                  ],
                  v: [
                    [73.085, 26.375],
                    [71.005, 45.155],
                    [63.125, 70.436],
                    [63.125, 70.445],
                    [58.564, 79.295],
                    [55.314, 84.465],
                    [-49.855, 84.465],
                    [-65.225, 55.785],
                    [-70.314, 37.915],
                    [-70.775, 35.585],
                    [-72.655, 1.585],
                    [-70.195, -15.955],
                    [-70.045, -16.675],
                    [-69.945, -17.125],
                    [-69.905, -17.295],
                    [-69.835, -17.595],
                    [-56.655, -50.935],
                    [-44.995, -65.925],
                    [-32.885, -75.635],
                    [-30.925, -76.805],
                    [-18.905, -82.025],
                    [-18.894, -82.025],
                    [-13.436, -83.375],
                    [-1.875, -84.375],
                    [4.936, -83.805],
                    [33.145, -71.725],
                    [33.155, -71.715],
                    [37.085, -68.565],
                    [49.655, -55.105],
                    [66.135, -22.855],
                    [69.686, -9.854],
                    [70.695, -4.975],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.278431372549, 0.278431372549, 0.278431372549, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [76.996, 97.565], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 7',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.38, -10.01],
                    [1.14, -6.009],
                    [3.5, -7.701],
                    [0, 0],
                    [1.641, -2.81],
                    [1.131, -1.67],
                    [0, 0],
                    [3.89, 10.611],
                    [1.269, 6.17],
                    [0.15, 0.78],
                    [-0.81, 11.24],
                    [-1.21, 5.71],
                    [-0.05, 0.24],
                    [-0.039, 0.15],
                    [-0.01, 0.061],
                    [-0.03, 0.1],
                    [-6.02, 9.81],
                    [-4.37, 4.4],
                    [-4.37, 2.71],
                    [-0.66, 0.38],
                    [-4.07, 1.25],
                    [0, 0],
                    [-1.829, 0.35],
                    [-3.85, -0.09],
                    [-2.261, -0.33],
                    [-8.77, -6.53],
                    [0, 0],
                    [-1.29, -1.11],
                    [-3.86, -5.13],
                    [-4.11, -12.53],
                    [-1, -4.521],
                    [-0.31, -1.65],
                  ],
                  o: [
                    [-0.229, 6.49],
                    [-1.71, 9.09],
                    [0, 0],
                    [-1.39, 3.08],
                    [-1.039, 1.78],
                    [0, 0],
                    [-6.25, -8.36],
                    [-2.11, -5.72],
                    [-0.161, -0.769],
                    [-2.1, -11.25],
                    [0.43, -5.95],
                    [0.051, -0.24],
                    [0.04, -0.15],
                    [0.011, -0.06],
                    [0.02, -0.1],
                    [2.72, -12.15],
                    [3.39, -5.55],
                    [3.7, -3.73],
                    [0.65, -0.4],
                    [3.93, -2.24],
                    [0, 0],
                    [1.809, -0.55],
                    [3.841, -0.76],
                    [2.28, 0.04],
                    [9.829, 1.44],
                    [0, 0],
                    [1.33, 1],
                    [4.49, 3.83],
                    [6.72, 8.91],
                    [1.36, 4.14],
                    [0.369, 1.599],
                    [2.03, 10.82],
                  ],
                  v: [
                    [73.085, 26.375],
                    [71.005, 45.155],
                    [63.125, 70.436],
                    [63.125, 70.445],
                    [58.564, 79.295],
                    [55.314, 84.465],
                    [-49.855, 84.465],
                    [-65.225, 55.785],
                    [-70.314, 37.915],
                    [-70.775, 35.585],
                    [-72.655, 1.585],
                    [-70.195, -15.955],
                    [-70.045, -16.675],
                    [-69.936, -17.115],
                    [-69.905, -17.295],
                    [-69.835, -17.595],
                    [-56.655, -50.935],
                    [-44.995, -65.925],
                    [-32.885, -75.635],
                    [-30.925, -76.805],
                    [-18.905, -82.025],
                    [-18.894, -82.025],
                    [-13.436, -83.375],
                    [-1.875, -84.375],
                    [4.936, -83.805],
                    [33.145, -71.725],
                    [33.155, -71.715],
                    [37.085, -68.565],
                    [49.655, -55.105],
                    [66.135, -22.855],
                    [69.686, -9.854],
                    [70.695, -4.975],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.278431372549, 0.278431372549, 0.278431372549, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [76.996, 97.565], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 8',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 8,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.198, 1.422],
                    [-0.527, 0.158],
                    [-0.501, -0.435],
                    [-0.158, -0.739],
                    [0.685, -0.738],
                    [1.699, -1.37],
                    [2.528, -0.672],
                    [2.332, 0.567],
                    [1.95, 1.712],
                    [1.566, 7.362],
                    [-2.135, 5.598],
                    [-4.98, 2.042],
                    [-1.989, -0.118],
                    [-1.159, -0.341],
                    [-0.317, -0.382],
                    [-0.157, -0.711],
                    [0.238, -0.698],
                    [0.501, -0.198],
                    [0.315, 0.079],
                    [1.568, 0.052],
                    [2.081, -0.803],
                    [1.422, -1.621],
                    [0.725, -2.094],
                    [-1.147, -5.44],
                    [-2.845, -2.674],
                    [-4.294, 1.212],
                    [-1.344, 1.027],
                  ],
                  o: [
                    [0.237, -0.277],
                    [0.513, -0.158],
                    [0.5, 0.435],
                    [0.278, 1.303],
                    [-1.423, 1.726],
                    [-1.687, 1.356],
                    [-2.517, 0.671],
                    [-2.318, -0.553],
                    [-4.083, -3.504],
                    [-1.569, -7.364],
                    [2.094, -5.559],
                    [2.463, -1.001],
                    [2.029, 0.119],
                    [1.158, 0.357],
                    [0.315, 0.369],
                    [0.158, 0.698],
                    [-0.262, 0.698],
                    [-0.513, 0.198],
                    [-1.608, -0.474],
                    [-1.567, -0.053],
                    [-2.054, 0.803],
                    [-1.41, 1.581],
                    [-1.395, 4.096],
                    [1.159, 5.427],
                    [3.122, 2.924],
                    [2.014, -0.567],
                    [1.277, -1.027],
                  ],
                  v: [
                    [13.877, 13.278],
                    [15.023, 12.632],
                    [16.552, 13.067],
                    [17.525, 14.832],
                    [16.92, 17.914],
                    [12.231, 22.538],
                    [5.882, 25.581],
                    [-1.39, 25.725],
                    [-7.792, 22.314],
                    [-16.234, 6.02],
                    [-15.404, -13.396],
                    [-4.827, -24.83],
                    [1.864, -26.174],
                    [6.633, -25.49],
                    [8.846, -24.395],
                    [9.543, -22.775],
                    [9.398, -20.681],
                    [8.252, -19.337],
                    [7.015, -19.179],
                    [2.246, -19.969],
                    [-3.221, -18.837],
                    [-8.449, -15.201],
                    [-11.638, -9.669],
                    [-11.993, 4.61],
                    [-6, 16.742],
                    [5.105, 19.337],
                    [10.123, 16.939],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.219607858097, 0.219607858097, 0.219607858097, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [94.601, 105.321], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 9',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 9,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-2.542, 2.793],
                    [-1.107, 0.264],
                    [-1.054, -0.948],
                    [-0.316, -1.502],
                    [1.462, -1.476],
                    [3.583, -2.555],
                    [5.164, -1.014],
                    [4.57, 1.383],
                    [3.701, 3.464],
                    [2.99, 14.069],
                    [-3.821, 10.92],
                    [-9.603, 4.675],
                    [-4.03, 0.04],
                    [-2.384, -0.566],
                    [-0.645, -0.739],
                    [-0.302, -1.45],
                    [0.54, -1.49],
                    [1.054, -0.461],
                    [0.671, 0.133],
                    [3.188, -0.04],
                    [4.111, -1.858],
                    [2.713, -3.281],
                    [1.331, -4.149],
                    [-2.226, -10.445],
                    [-5.4, -5.335],
                    [-8.536, 1.948],
                    [-2.739, 1.938],
                  ],
                  o: [
                    [0.515, -0.54],
                    [1.106, -0.276],
                    [1.067, 0.95],
                    [0.567, 2.7],
                    [-3.057, 3.346],
                    [-3.504, 2.515],
                    [-5.019, 0.976],
                    [-4.48, -1.357],
                    [-7.655, -7.059],
                    [-2.99, -14.054],
                    [3.819, -11.025],
                    [4.834, -2.358],
                    [4.11, -0.039],
                    [2.411, 0.568],
                    [0.646, 0.737],
                    [0.316, 1.448],
                    [-0.54, 1.475],
                    [-1.053, 0.447],
                    [-3.307, -0.817],
                    [-3.134, 0.039],
                    [-4.031, 1.818],
                    [-2.675, 3.239],
                    [-2.554, 8.022],
                    [2.226, 10.459],
                    [6.02, 5.914],
                    [4.071, -0.936],
                    [2.779, -1.949],
                  ],
                  v: [
                    [26.891, 27.721],
                    [29.315, 26.495],
                    [32.555, 27.496],
                    [34.623, 31.172],
                    [33.281, 37.429],
                    [23.322, 46.281],
                    [10.294, 51.549],
                    [-4.089, 50.931],
                    [-16.339, 43.672],
                    [-32.2, 12.085],
                    [-31.014, -25.246],
                    [-11.045, -48.862],
                    [2.272, -52.486],
                    [12.046, -51.709],
                    [16.643, -49.745],
                    [18.079, -46.452],
                    [17.737, -42.052],
                    [15.339, -39.141],
                    [12.745, -38.668],
                    [2.996, -39.814],
                    [-7.91, -36.955],
                    [-18.012, -29.288],
                    [-23.994, -18.211],
                    [-24.454, 9.45],
                    [-13.061, 33.109],
                    [8.674, 39.142],
                    [18.896, 34.833],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.219607858097, 0.219607858097, 0.219607858097, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [94.039, 104.738], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 10',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 10,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [11.31, -18.37],
                    [0, -0.01],
                    [2.74, -2.99],
                    [0, 0],
                    [2.591, -1.979],
                    [4.729, -1.67],
                    [0, 0],
                    [7.991, 10.43],
                    [3.199, 14.19],
                    [0.411, 3.12],
                    [0.089, 0.79],
                    [-1.58, 9.479],
                    [-9.8, 10.59],
                    [-1.981, 1.62],
                    [-0.7, 0.51],
                    [-0.289, 0.19],
                    [-1.481, 0.83],
                    [-2.099, 0.8],
                    [-3.82, 0.4],
                    [0, 0],
                    [-2.59, -0.2],
                    [0, 0],
                    [-3.269, -0.98],
                    [0, 0],
                    [-1.63, -0.69],
                    [-6.68, -29.69],
                  ],
                  o: [
                    [0, 0.01],
                    [-2.239, 3.64],
                    [0, 0],
                    [-2.281, 2.5],
                    [-4.09, 3.151],
                    [0, 0],
                    [-10.81, -3.78],
                    [-7.86, -10.23],
                    [-0.691, -3.08],
                    [-0.109, -0.791],
                    [-1.09, -9.939],
                    [2.73, -16.31],
                    [1.761, -1.91],
                    [0.679, -0.56],
                    [0.29, -0.22],
                    [1.391, -0.99],
                    [2.04, -1.15],
                    [3.73, -1.45],
                    [0, 0],
                    [2.589, -0.26],
                    [0, 0],
                    [3.329, 0.24],
                    [0, 0],
                    [1.659, 0.5],
                    [18.96, 8.01],
                    [6.261, 27.82],
                  ],
                  v: [
                    [45.305, 55.1],
                    [45.294, 55.13],
                    [37.825, 65.09],
                    [37.825, 65.1],
                    [30.514, 71.829],
                    [17.235, 79.12],
                    [-12.406, 79.12],
                    [-41.126, 57.16],
                    [-58.185, 19.92],
                    [-59.836, 10.62],
                    [-60.135, 8.249],
                    [-59.345, -21.15],
                    [-40.286, -62.87],
                    [-34.675, -68.17],
                    [-32.605, -69.78],
                    [-31.736, -70.4],
                    [-27.435, -73.13],
                    [-21.216, -76.06],
                    [-9.855, -78.82],
                    [-9.845, -78.82],
                    [-2.076, -78.92],
                    [-2.065, -78.92],
                    [7.844, -77.07],
                    [7.855, -77.06],
                    [12.794, -75.27],
                    [54.964, -17.16],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.219607858097, 0.219607858097, 0.219607858097, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [102.296, 102.91], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 11',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 11,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.02, -0.04],
                    [0, 0],
                    [1.99, -0.64],
                    [0.29, -0.03],
                    [0.031, 0],
                    [0.92, -6.029],
                    [-0.03, 0],
                    [-0.389, -1.659],
                    [0.13, -0.69],
                    [4.31, -8.53],
                    [1.2, -0.37],
                    [0.96, 0.48],
                    [0.04, 0.009],
                    [4.201, -4.48],
                    [-0.04, -0.04],
                    [-0.009, -0.01],
                    [0, 0],
                    [1.679, 1.829],
                    [0.019, -0.03],
                    [0.639, -0.2],
                    [1.08, 1.29],
                    [4.219, 8.559],
                    [0.079, 0.34],
                    [-1.82, 1.06],
                    [-0.029, 0.01],
                    [1.671, 5.99],
                    [0.031, -0.02],
                    [0, 0],
                    [0.58, 2.4],
                    [0, 0],
                    [0.23, 9.58],
                    [-1.97, 0.62],
                    [-0.311, 0.02],
                    [0, 0],
                    [-0.99, 6.14],
                    [0.04, 0],
                    [0.38, 1.66],
                    [-0.15, 0.68],
                    [-2.45, 6.22],
                    [-1.31, 2.64],
                    [-1.199, 0.37],
                    [-0.95, -0.49],
                    [-0.041, -0.02],
                    [-2.039, 2.76],
                    [-0.5, 0.63],
                    [-1.41, 1.52],
                    [0.039, 0.04],
                    [0.179, 0.67],
                    [0, 0.06],
                    [-1.23, 1.15],
                    [-0.73, 0.64],
                    [0, 0],
                    [-1.451, 1.07],
                    [-1.76, 1.07],
                    [-4.689, 1.51],
                    [0, 0],
                    [-0.58, -2.4],
                    [-0.02, -0.06],
                    [-5.39, -0.31],
                    [-0.62, -0.06],
                    [-0.011, 0.05],
                    [-1.64, 0.51],
                    [-0.64, -0.12],
                    [-8.23, -5.23],
                    [-0.27, -1.08],
                    [0.68, -1.1],
                    [0.01, -0.04],
                    [-4.16, -4.6],
                    [-0.03, 0.03],
                    [-0.63, 0.2],
                    [-1.07, -1.29],
                    [-4.12, -8.72],
                    [-0.07, -0.29],
                    [0, -0.32],
                    [1.58, -0.89],
                    [0.019, -0.01],
                    [-1.69, -6.15],
                    [-0.03, 0.01],
                    [-0.601, -2.361],
                    [0, 0],
                    [-0.35, -9.27],
                  ],
                  o: [
                    [0, 0],
                    [0.061, 2.12],
                    [-0.29, 0.09],
                    [-0.03, 0.01],
                    [0.01, 6.5],
                    [0.02, 0.011],
                    [1.62, 0.25],
                    [0.16, 0.65],
                    [-1.929, 10.04],
                    [-0.58, 1.16],
                    [-1.059, 0.33],
                    [-0.04, -0.02],
                    [-3.25, 5.689],
                    [0.04, 0.02],
                    [0.01, 0.01],
                    [0, 0],
                    [-1.78, -1.66],
                    [-0.041, 0.04],
                    [-0.531, 0.48],
                    [-1.641, 0.51],
                    [-5.81, -6.92],
                    [-0.141, -0.301],
                    [-0.481, -2.039],
                    [0.03, -0.02],
                    [-2.46, -5.51],
                    [-0.02, 0.011],
                    [0, 0],
                    [-2.321, 0.73],
                    [0, 0],
                    [-2.209, -9.179],
                    [-0.049, -2.09],
                    [0.3, -0.09],
                    [0, 0],
                    [0.08, -6.38],
                    [-0.04, -0.01],
                    [-1.63, -0.25],
                    [-0.16, -0.66],
                    [1.38, -6.91],
                    [1.11, -2.81],
                    [0.57, -1.17],
                    [1.051, -0.32],
                    [0.05, 0.02],
                    [1.719, -3.07],
                    [0.461, -0.65],
                    [1.28, -1.65],
                    [-0.04, -0.04],
                    [-0.461, -0.52],
                    [-0.021, -0.06],
                    [-0.35, -1.6],
                    [0.71, -0.67],
                    [0, 0],
                    [1.38, -1.21],
                    [1.679, -1.25],
                    [4.36, -2.64],
                    [0, 0],
                    [2.309, -0.74],
                    [0.01, 0.08],
                    [5.301, -1.26],
                    [0.62, 0.03],
                    [0.01, -0.05],
                    [0.359, -1.73],
                    [0.66, -0.22],
                    [8.94, 1.49],
                    [0.91, 0.57],
                    [0.3, 1.23],
                    [-0.01, 0.04],
                    [4.66, 3.36],
                    [0.04, -0.03],
                    [0.521, -0.46],
                    [1.62, -0.49],
                    [5.711, 6.91],
                    [0.14, 0.28],
                    [0.07, 0.31],
                    [0.061, 1.76],
                    [-0.01, 0.01],
                    [2.33, 5.43],
                    [0.03, -0.01],
                    [2.34, -0.75],
                    [0, 0],
                    [2.25, 9.39],
                    [0.02, 0.04],
                  ],
                  v: [
                    [78.834, 16.38],
                    [78.834, 16.42],
                    [75.524, 21.16],
                    [74.654, 21.33],
                    [74.574, 21.34],
                    [73.194, 40.18],
                    [73.284, 40.191],
                    [76.614, 43.35],
                    [76.654, 45.37],
                    [67.234, 73.33],
                    [64.454, 75.74],
                    [61.334, 75.51],
                    [61.244, 75.441],
                    [50.024, 90.75],
                    [50.124, 90.85],
                    [50.154, 90.89],
                    [-39.086, 90.89],
                    [-44.275, 85.651],
                    [-44.355, 85.74],
                    [-46.105, 86.77],
                    [-50.496, 85.53],
                    [-65.605, 62.191],
                    [-65.945, 61.23],
                    [-63.656, 55.97],
                    [-63.576, 55.92],
                    [-69.816, 38.64],
                    [-69.886, 38.68],
                    [-69.895, 38.68],
                    [-75.156, 35.67],
                    [-75.186, 35.58],
                    [-78.846, 7.3],
                    [-75.546, 2.6],
                    [-74.605, 2.43],
                    [-74.506, 2.43],
                    [-72.886, -16.41],
                    [-72.996, -16.42],
                    [-76.316, -19.6],
                    [-76.336, -21.61],
                    [-70.566, -41.35],
                    [-66.936, -49.54],
                    [-64.156, -51.98],
                    [-61.056, -51.75],
                    [-60.945, -51.67],
                    [-55.316, -60.42],
                    [-53.866, -62.33],
                    [-49.826, -67.08],
                    [-49.945, -67.2],
                    [-50.855, -68.99],
                    [-50.886, -69.18],
                    [-49.456, -73.63],
                    [-47.286, -75.6],
                    [-47.275, -75.6],
                    [-43.025, -79.02],
                    [-37.866, -82.5],
                    [-24.206, -88.75],
                    [-24.195, -88.75],
                    [-18.936, -85.75],
                    [-18.906, -85.56],
                    [-2.826, -86.96],
                    [-0.966, -86.82],
                    [-0.945, -86.97],
                    [2.274, -90.61],
                    [4.234, -90.77],
                    [30.114, -80.64],
                    [31.934, -78.07],
                    [31.344, -74.41],
                    [31.264, -74.3],
                    [44.544, -62.38],
                    [44.624, -62.46],
                    [46.344, -63.46],
                    [50.764, -62.18],
                    [65.574, -38.61],
                    [65.884, -37.75],
                    [65.994, -36.81],
                    [63.534, -32.41],
                    [63.475, -32.38],
                    [69.504, -15],
                    [69.574, -15.04],
                    [74.855, -12.059],
                    [74.895, -11.88],
                    [78.814, 16.24],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.219607858097, 0.219607858097, 0.219607858097, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [105.376, 91.14], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 12',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 12,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 150,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: 'neck Outlines',
      parent: 7,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0,
              s: [4],
              e: [0],
            },
            {
              i: { x: [0.682], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 15,
              s: [0],
              e: [0],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.791], y: [0] },
              t: 80,
              s: [0],
              e: [4],
            },
            { t: 94 },
          ],
          ix: 10,
        },
        p: { a: 0, k: [88.072, 18.428, 0], ix: 2, l: 2 },
        a: { a: 0, k: [69.219, 102.792, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-1.147, -2.99],
                    [-1.094, -3.029],
                    [-1.027, -3.043],
                    [-0.882, -3.122],
                    [-0.658, -3.228],
                    [-0.369, -3.372],
                    [0, -3.503],
                    [0.343, -3.569],
                    [0.619, -3.61],
                    [0.844, -3.583],
                    [0.989, -3.544],
                    [1.08, -3.504],
                    [1.16, -3.478],
                    [1.187, -3.464],
                    [1.237, -3.438],
                    [1.251, -3.425],
                    [1.264, -3.398],
                    [1.277, -3.385],
                    [1.291, -3.372],
                  ],
                  o: [
                    [1.265, 3.267],
                    [1.238, 3.293],
                    [1.212, 3.306],
                    [1.107, 3.332],
                    [0.949, 3.359],
                    [0.699, 3.398],
                    [0.356, 3.386],
                    [0, 3.346],
                    [-0.315, 3.307],
                    [-0.579, 3.28],
                    [-0.764, 3.24],
                    [-0.882, 3.214],
                    [-0.988, 3.201],
                    [-1.053, 3.201],
                    [-1.105, 3.201],
                    [-1.147, 3.201],
                    [-1.172, 3.201],
                    [-1.186, 3.2],
                    [-1.211, 3.201],
                    [0, 0],
                  ],
                  v: [
                    [-17.467, -89.73],
                    [15.872, -94.38],
                    [-10.459, -71.13],
                    [22.932, -74.831],
                    [-4.492, -53.098],
                    [28.846, -53.439],
                    [-1.015, -35.868],
                    [31.297, -29.678],
                    [-1.015, -18.678],
                    [29.084, -5.967],
                    [-4.177, -0.395],
                    [23.973, 16.136],
                    [-9.418, 18.678],
                    [17.558, 37.093],
                    [-15.808, 38.107],
                    [10.538, 57.457],
                    [-22.788, 57.668],
                    [3.214, 77.492],
                    [-30.086, 77.295],
                    [-4.269, 97.369],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.219607858097, 0.219607858097, 0.219607858097, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 8.569, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [52.719, 118.792], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 150,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 0,
      nm: 'bag',
      refId: 'comp_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [658.661, 501.172, 0], ix: 2, l: 2 },
        a: { a: 0, k: [737.661, 501.172, 0], ix: 1, l: 2 },
        s: { a: 0, k: [129, 129, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 842,
      h: 524,
      ip: 0,
      op: 150,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 0,
      nm: 'bag',
      refId: 'comp_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [189.661, 501.172, 0], ix: 2, l: 2 },
        a: { a: 0, k: [737.661, 501.172, 0], ix: 1, l: 2 },
        s: { a: 0, k: [129, 129, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 842,
      h: 524,
      ip: 0,
      op: 150,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 0,
      nm: 'bag',
      refId: 'comp_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [113.161, 501.172, 0], ix: 2, l: 2 },
        a: { a: 0, k: [737.661, 501.172, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 842,
      h: 524,
      ip: 0,
      op: 150,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 12,
      ty: 0,
      nm: 'bag',
      refId: 'comp_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [737.661, 501.172, 0], ix: 2, l: 2 },
        a: { a: 0, k: [737.661, 501.172, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 842,
      h: 524,
      ip: 0,
      op: 150,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 13,
      ty: 0,
      nm: 'bicoins_all',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [421, 262, 0], ix: 2, l: 2 },
        a: { a: 0, k: [421, 262, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 842,
      h: 524,
      ip: 0,
      op: 150,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 14,
      ty: 0,
      nm: 'pc',
      refId: 'comp_5',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [38.5, 262, 0], ix: 2, l: 2 },
        a: { a: 0, k: [421, 262, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 842,
      h: 524,
      ip: 0,
      op: 150,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 15,
      ty: 0,
      nm: 'pc',
      refId: 'comp_5',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [421, 262, 0], ix: 2, l: 2 },
        a: { a: 0, k: [421, 262, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 842,
      h: 524,
      ip: 0,
      op: 150,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 16,
      ty: 0,
      nm: 'pc',
      refId: 'comp_5',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [312.584, 500.712, 0], ix: 2, l: 2 },
        a: { a: 0, k: [611.584, 500.712, 0], ix: 1, l: 2 },
        s: { a: 0, k: [92, 92, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 842,
      h: 524,
      ip: 0,
      op: 150,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 17,
      ty: 0,
      nm: 'pc',
      refId: 'comp_5',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [527.584, 500.712, 0], ix: 2, l: 2 },
        a: { a: 0, k: [611.584, 500.712, 0], ix: 1, l: 2 },
        s: { a: 0, k: [92, 92, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 842,
      h: 524,
      ip: 0,
      op: 150,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 18,
      ty: 4,
      nm: 'back Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [421, 439, 0], ix: 2, l: 2 },
        a: { a: 0, k: [362.33, 430.54, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 0,
              s: [100, 100, 100],
              e: [96, 96, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 75,
              s: [96, 96, 100],
              e: [100, 100, 100],
            },
            { t: 150 },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [18.88, 44.62],
                    [33.231, 33.23],
                    [43.09, 18.23],
                    [48.85, 0],
                    [44.621, -18.87],
                    [33.229, -33.23],
                    [18.23, -43.1],
                    [0, -48.85],
                    [-4.32, -22.46],
                    [0, 0],
                    [0, 23.51],
                    [-189.621, 0],
                    [0, -189.62],
                    [4.52, -22.19],
                    [0, 0],
                    [0, 23.26],
                  ],
                  o: [
                    [-18.221, -43.1],
                    [-33.229, -33.23],
                    [-44.62, -18.87],
                    [-48.85, 0],
                    [-43.099, 18.23],
                    [-33.231, 33.23],
                    [-18.87, 44.62],
                    [0, 23.26],
                    [0, 0],
                    [-4.52, -22.19],
                    [0, -189.62],
                    [189.629, 0],
                    [0, 23.51],
                    [0, 0],
                    [4.319, -22.46],
                    [0, -48.85],
                  ],
                  v: [
                    [333.436, 5.75],
                    [255.885, -109.28],
                    [140.866, -186.83],
                    [-0.004, -215.27],
                    [-140.865, -186.83],
                    [-255.895, -109.28],
                    [-333.445, 5.75],
                    [-361.885, 146.61],
                    [-355.395, 215.27],
                    [-337.015, 215.27],
                    [-343.905, 146.61],
                    [-0.004, -197.29],
                    [343.896, 146.61],
                    [337.005, 215.27],
                    [355.396, 215.27],
                    [361.885, 146.61],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.968627510819, 0.576470588235, 0.101960791794, 1],
                ix: 4,
              },
              o: { a: 0, k: 15, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [361.885, 215.27], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [60.01, 60.01],
                    [84.87, 0],
                    [60.01, -60.01],
                    [0, -84.87],
                    [-4.889, -22.29],
                    [0, 0],
                    [0, 23.62],
                    [-165.28, 0],
                    [0, -165.28],
                    [5.189, -22.05],
                    [0, 0],
                    [0, 23.38],
                  ],
                  o: [
                    [-60.021, -60.01],
                    [-84.87, 0],
                    [-60.019, 60.01],
                    [0, 23.38],
                    [0, 0],
                    [-5.19, -22.05],
                    [0, -165.28],
                    [165.281, 0],
                    [0, 23.62],
                    [0, 0],
                    [4.891, -22.29],
                    [0, -84.87],
                  ],
                  v: [
                    [224.68, -100.135],
                    [-0.001, -193.195],
                    [-224.671, -100.135],
                    [-317.74, 124.535],
                    [-310.341, 193.195],
                    [-291.81, 193.195],
                    [-299.75, 124.535],
                    [-0.001, -175.215],
                    [299.749, 124.535],
                    [291.81, 193.195],
                    [310.339, 193.195],
                    [317.74, 124.535],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.258823529412, 0.521568627451, 0.956862804936, 1],
                ix: 4,
              },
              o: { a: 0, k: 30, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [361.88, 237.345], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [52.09, 52.09],
                    [73.66, 0],
                    [52.09, -52.09],
                    [0, -73.66],
                    [-5.66, -22.17],
                    [0, 0],
                    [0, 23.77],
                    [-142.14, 0],
                    [0, -142.14],
                    [6.05, -21.87],
                    [0, 0],
                    [0, 23.47],
                  ],
                  o: [
                    [-52.08, -52.09],
                    [-73.66, 0],
                    [-52.09, 52.09],
                    [0, 23.47],
                    [0, 0],
                    [-6.05, -21.87],
                    [0, -142.14],
                    [142.15, 0],
                    [0, 23.77],
                    [0, 0],
                    [5.66, -22.17],
                    [0, -73.66],
                  ],
                  v: [
                    [194.995, -91.445],
                    [-0.005, -172.215],
                    [-195.005, -91.445],
                    [-275.775, 103.555],
                    [-267.205, 172.215],
                    [-248.515, 172.215],
                    [-257.795, 103.555],
                    [-0.005, -154.235],
                    [257.785, 103.555],
                    [248.506, 172.215],
                    [267.205, 172.215],
                    [275.775, 103.555],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.968627510819, 0.576470588235, 0.101960791794, 1],
                ix: 4,
              },
              o: { a: 0, k: 45, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [361.885, 258.325], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [44.16, 44.17],
                    [62.46, 0],
                    [44.17, -44.16],
                    [0, -62.45],
                    [-6.71, -21.969],
                    [0, 0],
                    [0, 23.99],
                    [-119, 0],
                    [0, -119],
                    [7.27, -21.58],
                    [0, 0],
                    [0, 23.62],
                  ],
                  o: [
                    [-44.16, -44.16],
                    [-62.45, 0],
                    [-44.159, 44.17],
                    [0, 23.62],
                    [0, 0],
                    [-7.26, -21.58],
                    [0, -119],
                    [119.011, 0],
                    [0, 23.99],
                    [0, 0],
                    [6.71, -21.969],
                    [0, -62.45],
                  ],
                  v: [
                    [165.33, -82.755],
                    [0, -151.235],
                    [-165.33, -82.755],
                    [-233.81, 82.575],
                    [-223.62, 151.235],
                    [-204.62, 151.235],
                    [-215.819, 82.575],
                    [0, -133.245],
                    [215.83, 82.575],
                    [204.63, 151.235],
                    [223.62, 151.235],
                    [233.81, 82.575],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.258823529412, 0.521568627451, 0.956862804936, 1],
                ix: 4,
              },
              o: { a: 0, k: 60, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [361.88, 279.305], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [105.79, 0],
                    [0, -105.78],
                    [-8.201, -21.33],
                    [0, 0],
                    [0, 24.37],
                    [-95.87, 0],
                    [0, -95.86],
                    [9.1, -21.07],
                    [0, 0],
                    [0, 24.18],
                  ],
                  o: [
                    [-105.78, 0],
                    [0, 24.18],
                    [0, 0],
                    [-9.099, -21.07],
                    [0, -95.86],
                    [95.87, 0],
                    [0, 24.37],
                    [0, 0],
                    [8.201, -21.33],
                    [0, -105.78],
                  ],
                  v: [
                    [0, -130.25],
                    [-191.85, 61.59],
                    [-179.149, 130.25],
                    [-159.72, 130.25],
                    [-173.86, 61.59],
                    [0, -112.27],
                    [173.86, 61.59],
                    [159.72, 130.25],
                    [179.15, 130.25],
                    [191.851, 61.59],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.968627510819, 0.576470588235, 0.101960791794, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [361.88, 300.29], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 150,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};

export const logo_browser = {
  v: '5.5.5',
  fr: 25,
  ip: 0,
  op: 42,
  w: 619,
  h: 148,
  nm: 'texts',
  ddd: 0,
  assets: [
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 0,
          nm: 'crypto',
          refId: 'comp_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [180.5, 840, 0], ix: 2 },
            a: { a: 0, k: [50, 50, 0], ix: 1 },
            s: { a: 0, k: [202, 202, 100], ix: 6 },
          },
          ao: 0,
          w: 100,
          h: 100,
          ip: 0,
          op: 1000,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 0,
          nm: 'cryptotab 3',
          refId: 'comp_2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [388.967, 846.969, 0], ix: 2 },
            a: { a: 0, k: [169.841, 35.588, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          w: 340,
          h: 72,
          ip: 7,
          op: 153,
          st: 7,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'browser Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.48], y: [1] },
                  o: { x: [0.298], y: [0] },
                  t: 17,
                  s: [-59],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.406], y: [0] },
                  t: 24,
                  s: [8],
                },
                {
                  i: { x: [0.385], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 28,
                  s: [-4],
                },
                { t: 31, s: [0] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [539.643, 831.921, 0], ix: 2 },
            a: { a: 0, k: [8.117, 8.4, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.48, 0.48, 0.667], y: [1, 1, 1] },
                  o: { x: [0.298, 0.298, 0.333], y: [0, 0, 0] },
                  t: 17,
                  s: [0, 0, 100],
                },
                { t: 24, s: [100, 100, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.359, -1.152],
                        [0, -1.662],
                        [-1.247, -0.944],
                        [-0.726, -0.265],
                        [-0.746, -0.207],
                        [-0.726, -0.179],
                        [-0.547, -0.227],
                        [0, -0.906],
                        [3.304, 0],
                        [0.925, 0.246],
                        [0.406, 0.18],
                        [0.273, 0],
                        [0.207, -0.235],
                        [0, -0.292],
                        [-0.16, -0.208],
                        [-0.207, -0.093],
                        [-2.399, 0],
                        [-1.34, 1.038],
                        [0, 1.624],
                        [1.245, 0.907],
                        [0.717, 0.255],
                        [0.754, 0.189],
                        [0.718, 0.17],
                        [0.567, 0.265],
                        [0, 1.076],
                        [-0.962, 0.567],
                        [-1.454, 0],
                        [-1.227, -0.434],
                        [-0.235, 0],
                        [-0.198, 0.256],
                        [0, 0.284],
                        [0.529, 0.188],
                        [2.057, 0],
                      ],
                      o: [
                        [-1.247, 1.056],
                        [0, 1.549],
                        [0.566, 0.415],
                        [0.727, 0.264],
                        [0.746, 0.208],
                        [0.727, 0.179],
                        [1.265, 0.548],
                        [0, 2.172],
                        [-1.228, 0],
                        [-0.926, -0.245],
                        [-0.406, -0.179],
                        [-0.274, 0],
                        [-0.208, 0.236],
                        [0, 0.293],
                        [0.16, 0.208],
                        [2.096, 0.907],
                        [2.189, 0],
                        [1.303, -1.02],
                        [0, -1.699],
                        [-0.566, -0.415],
                        [-0.718, -0.255],
                        [-0.756, -0.189],
                        [-0.717, -0.17],
                        [-1.245, -0.547],
                        [0, -1.039],
                        [0.944, -0.547],
                        [1.586, 0],
                        [0.416, 0.151],
                        [0.236, 0],
                        [0.198, -0.254],
                        [0, -0.566],
                        [-1.699, -0.623],
                        [-2.286, 0],
                      ],
                      v: [
                        [238.621, -6.415],
                        [236.751, -2.338],
                        [238.621, 1.401],
                        [240.561, 2.421],
                        [242.77, 3.128],
                        [244.978, 3.709],
                        [246.89, 4.318],
                        [248.788, 6.499],
                        [243.832, 9.756],
                        [240.603, 9.387],
                        [238.606, 8.75],
                        [237.587, 8.482],
                        [236.866, 8.835],
                        [236.553, 9.628],
                        [236.794, 10.379],
                        [237.346, 10.831],
                        [244.087, 12.191],
                        [249.382, 10.633],
                        [251.337, 6.669],
                        [249.468, 2.76],
                        [247.543, 1.755],
                        [245.333, 1.09],
                        [243.124, 0.551],
                        [241.197, -0.101],
                        [239.329, -2.536],
                        [240.773, -4.943],
                        [244.371, -5.764],
                        [248.59, -5.113],
                        [249.566, -4.887],
                        [250.217, -5.27],
                        [250.515, -6.077],
                        [249.723, -7.208],
                        [244.087, -8.144],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.794, -1.907],
                        [0.037, -2.983],
                        [-1.793, -1.888],
                        [-3.002, 0],
                        [-1.813, 1.906],
                        [-0.076, 3.002],
                        [1.756, 1.868],
                        [2.983, 0],
                      ],
                      o: [
                        [-1.793, 1.888],
                        [0.037, 2.983],
                        [1.794, 1.906],
                        [2.983, 0],
                        [1.756, -1.869],
                        [-0.076, -3.001],
                        [-1.813, -1.907],
                        [-3.002, 0],
                      ],
                      v: [
                        [187.926, -5.283],
                        [185.181, 2.023],
                        [187.926, 9.331],
                        [195.121, 12.191],
                        [202.314, 9.331],
                        [205.061, 2.023],
                        [202.314, -5.283],
                        [195.121, -8.144],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.322, 1.493],
                        [0, 2.246],
                        [-1.284, 1.435],
                        [-2.304, 0],
                        [-1.321, -1.492],
                        [0, -2.246],
                        [1.284, -1.434],
                        [2.303, 0],
                      ],
                      o: [
                        [-1.284, -1.434],
                        [0, -2.246],
                        [1.322, -1.492],
                        [2.303, 0],
                        [1.284, 1.435],
                        [0, 2.246],
                        [-1.321, 1.493],
                        [-2.304, 0],
                      ],
                      v: [
                        [189.682, 7.546],
                        [187.757, 2.023],
                        [189.682, -3.499],
                        [195.121, -5.736],
                        [200.557, -3.499],
                        [202.483, 2.023],
                        [200.557, 7.546],
                        [195.121, 9.784],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 4',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 3,
                  ty: 'sh',
                  ix: 4,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.208, -0.681],
                        [0, 0],
                        [0, 0],
                        [0.273, 0.198],
                        [0.34, 0],
                        [0.274, -0.198],
                        [0.095, -0.32],
                        [0, 0],
                        [0, 0],
                        [0.68, 0],
                        [0.227, -0.218],
                        [0, -0.255],
                        [-0.057, -0.208],
                        [0, 0],
                        [-0.811, 0],
                        [-0.311, 0.237],
                        [-0.113, 0.378],
                        [0, 0],
                        [0, 0],
                        [-0.311, -0.235],
                        [-0.378, 0],
                        [-0.302, 0.849],
                        [0, 0],
                        [0, 0.255],
                        [0.225, 0.217],
                        [0.378, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [-0.093, -0.32],
                        [-0.274, -0.198],
                        [-0.34, 0],
                        [-0.274, 0.198],
                        [0, 0],
                        [0, 0],
                        [-0.207, -0.681],
                        [-0.339, 0],
                        [-0.226, 0.217],
                        [0, 0.255],
                        [0, 0],
                        [0.302, 0.849],
                        [0.378, 0],
                        [0.313, -0.235],
                        [0, 0],
                        [0, 0],
                        [0.114, 0.378],
                        [0.312, 0.237],
                        [0.812, 0],
                        [0, 0],
                        [0.057, -0.208],
                        [0, -0.255],
                        [-0.226, -0.218],
                        [-0.642, 0],
                      ],
                      v: [
                        [231.88, -6.982],
                        [227.121, 8.651],
                        [222.335, -6.926],
                        [221.785, -7.704],
                        [220.863, -8.001],
                        [219.944, -7.704],
                        [219.39, -6.926],
                        [214.605, 8.651],
                        [209.846, -6.982],
                        [208.515, -8.001],
                        [207.665, -7.676],
                        [207.327, -6.969],
                        [207.411, -6.275],
                        [212.962, 10.775],
                        [214.632, 12.049],
                        [215.666, 11.695],
                        [216.303, 10.775],
                        [220.863, -3.669],
                        [225.423, 10.775],
                        [226.061, 11.695],
                        [227.095, 12.049],
                        [228.764, 10.775],
                        [234.316, -6.275],
                        [234.4, -6.969],
                        [234.061, -7.676],
                        [233.155, -8.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 5',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 4,
                  ty: 'sh',
                  ix: 5,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.245, -0.255],
                        [0, -0.359],
                        [0, 0],
                        [-0.245, -0.246],
                        [-0.358, 0],
                        [-0.255, 0.246],
                        [0, 0.359],
                        [0, 0],
                        [0, 0],
                        [-0.585, -0.425],
                        [-0.339, -0.718],
                        [0, -1.529],
                        [0, 0],
                        [-0.236, -0.235],
                        [-0.378, 0],
                        [-0.245, 0.246],
                        [0, 0.359],
                        [0, 0],
                        [0.397, 1.076],
                        [1.359, 0.359],
                        [-0.614, 0.944],
                        [0, 1.096],
                        [1.226, 1.038],
                        [2.587, 0],
                      ],
                      o: [
                        [-0.358, 0],
                        [-0.245, 0.254],
                        [0, 0],
                        [0, 0.359],
                        [0.245, 0.246],
                        [0.358, 0],
                        [0.255, -0.246],
                        [0, 0],
                        [0, 0],
                        [1.076, 0],
                        [0.585, 0.425],
                        [0.34, 0.717],
                        [0, 0],
                        [0, 0.378],
                        [0.236, 0.237],
                        [0.378, 0],
                        [0.245, -0.246],
                        [0, 0],
                        [0, -1.265],
                        [-0.604, -1.605],
                        [1.246, -0.434],
                        [0.613, -0.944],
                        [0, -1.699],
                        [-1.323, -1.095],
                        [0, 0],
                      ],
                      v: [
                        [274.645, -7.861],
                        [273.738, -7.478],
                        [273.371, -6.557],
                        [273.371, 10.775],
                        [273.738, 11.681],
                        [274.645, 12.049],
                        [275.564, 11.681],
                        [275.947, 10.775],
                        [275.947, 4.204],
                        [281.809, 4.204],
                        [284.301, 4.842],
                        [285.689, 6.555],
                        [286.199, 9.924],
                        [286.199, 10.775],
                        [286.553, 11.695],
                        [287.475, 12.049],
                        [288.408, 11.681],
                        [288.777, 10.775],
                        [288.777, 9.472],
                        [288.181, 5.961],
                        [285.236, 3.015],
                        [288.027, 0.948],
                        [288.947, -2.111],
                        [287.106, -6.217],
                        [281.243, -7.861],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 6',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 5,
                  ty: 'sh',
                  ix: 6,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.906, -0.642],
                        [0, -1.133],
                        [0.972, -0.622],
                        [1.586, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [1.963, 0],
                        [0.755, 0.566],
                        [0, 1.209],
                        [-0.971, 0.624],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [275.947, -5.424],
                        [280.932, -5.424],
                        [285.236, -4.462],
                        [286.368, -1.913],
                        [284.91, 0.834],
                        [281.074, 1.769],
                        [275.947, 1.769],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 7',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 6,
                  ty: 'sh',
                  ix: 7,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.245, -0.255],
                        [0, -0.359],
                        [0, 0],
                        [-0.245, -0.246],
                        [-0.359, 0],
                        [0, 0],
                        [-0.236, 0.226],
                        [0, 0.34],
                        [0.236, 0.236],
                        [0.34, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.236, 0.226],
                        [0, 0.34],
                        [0.236, 0.236],
                        [0.339, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.236, 0.226],
                        [0, 0.34],
                        [0.236, 0.236],
                        [0.34, 0],
                      ],
                      o: [
                        [-0.359, 0],
                        [-0.245, 0.254],
                        [0, 0],
                        [0, 0.359],
                        [0.245, 0.245],
                        [0, 0],
                        [0.34, 0],
                        [0.236, -0.226],
                        [0, -0.339],
                        [-0.236, -0.236],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.339, 0],
                        [0.236, -0.227],
                        [0, -0.34],
                        [-0.236, -0.236],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.34, 0],
                        [0.236, -0.227],
                        [0, -0.339],
                        [-0.236, -0.236],
                        [0, 0],
                      ],
                      v: [
                        [256.577, -7.861],
                        [255.669, -7.478],
                        [255.301, -6.557],
                        [255.301, 10.633],
                        [255.669, 11.54],
                        [256.577, 11.908],
                        [267.932, 11.908],
                        [268.796, 11.568],
                        [269.15, 10.717],
                        [268.796, 9.855],
                        [267.932, 9.5],
                        [257.879, 9.5],
                        [257.879, 3.214],
                        [266.913, 3.214],
                        [267.777, 2.874],
                        [268.132, 2.023],
                        [267.777, 1.16],
                        [266.913, 0.806],
                        [257.879, 0.806],
                        [257.879, -5.452],
                        [267.932, -5.452],
                        [268.796, -5.792],
                        [269.15, -6.643],
                        [268.796, -7.507],
                        [267.932, -7.861],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 8',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 7,
                  ty: 'sh',
                  ix: 8,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.245, -0.255],
                        [0, -0.359],
                        [0, 0],
                        [-0.246, -0.246],
                        [-0.36, 0],
                        [-0.255, 0.246],
                        [0, 0.359],
                        [0, 0],
                        [0, 0],
                        [-0.585, -0.425],
                        [-0.34, -0.718],
                        [0, -1.529],
                        [0, 0],
                        [-0.235, -0.235],
                        [-0.378, 0],
                        [-0.246, 0.246],
                        [0, 0.359],
                        [0, 0],
                        [0.397, 1.076],
                        [1.36, 0.359],
                        [-0.613, 0.944],
                        [0, 1.096],
                        [1.228, 1.038],
                        [2.587, 0],
                      ],
                      o: [
                        [-0.36, 0],
                        [-0.246, 0.254],
                        [0, 0],
                        [0, 0.359],
                        [0.245, 0.246],
                        [0.359, 0],
                        [0.255, -0.246],
                        [0, 0],
                        [0, 0],
                        [1.077, 0],
                        [0.585, 0.425],
                        [0.34, 0.717],
                        [0, 0],
                        [0, 0.378],
                        [0.237, 0.237],
                        [0.378, 0],
                        [0.245, -0.246],
                        [0, 0],
                        [0, -1.265],
                        [-0.605, -1.605],
                        [1.246, -0.434],
                        [0.614, -0.944],
                        [0, -1.699],
                        [-1.321, -1.095],
                        [0, 0],
                      ],
                      v: [
                        [167.48, -7.861],
                        [166.574, -7.478],
                        [166.205, -6.557],
                        [166.205, 10.775],
                        [166.574, 11.681],
                        [167.48, 12.049],
                        [168.4, 11.681],
                        [168.783, 10.775],
                        [168.783, 4.204],
                        [174.645, 4.204],
                        [177.137, 4.842],
                        [178.525, 6.555],
                        [179.035, 9.924],
                        [179.035, 10.775],
                        [179.387, 11.695],
                        [180.309, 12.049],
                        [181.243, 11.681],
                        [181.611, 10.775],
                        [181.611, 9.472],
                        [181.017, 5.961],
                        [178.072, 3.015],
                        [180.861, 0.948],
                        [181.781, -2.111],
                        [179.941, -6.217],
                        [174.079, -7.861],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 9',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 8,
                  ty: 'sh',
                  ix: 9,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.907, -0.642],
                        [0, -1.133],
                        [0.973, -0.622],
                        [1.586, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [1.965, 0],
                        [0.755, 0.566],
                        [0, 1.209],
                        [-0.972, 0.624],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [168.783, -5.424],
                        [173.767, -5.424],
                        [178.072, -4.462],
                        [179.204, -1.913],
                        [177.746, 0.834],
                        [173.908, 1.769],
                        [168.783, 1.769],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 10',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 9,
                  ty: 'sh',
                  ix: 10,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.245, -0.255],
                        [0, -0.359],
                        [0, 0],
                        [-0.246, -0.246],
                        [-0.359, 0],
                        [0, 0],
                        [-1.209, 1.02],
                        [-0.265, 0.679],
                        [0, 0.736],
                        [0.207, 0.528],
                        [1.793, 0.604],
                        [0, 2.038],
                        [1.076, 0.944],
                        [2.077, 0],
                      ],
                      o: [
                        [-0.359, 0],
                        [-0.246, 0.254],
                        [0, 0],
                        [0, 0.359],
                        [0.245, 0.245],
                        [0, 0],
                        [2.529, 0],
                        [0.586, -0.491],
                        [0.264, -0.68],
                        [0, -0.736],
                        [-0.585, -1.379],
                        [1.849, -0.869],
                        [0, -1.53],
                        [-1.076, -0.944],
                        [0, 0],
                      ],
                      v: [
                        [147.853, -7.861],
                        [146.947, -7.478],
                        [146.579, -6.557],
                        [146.579, 10.633],
                        [146.947, 11.54],
                        [147.853, 11.908],
                        [154.707, 11.908],
                        [160.314, 10.379],
                        [161.59, 8.623],
                        [161.986, 6.499],
                        [161.673, 4.602],
                        [158.105, 1.628],
                        [160.882, -2.734],
                        [159.267, -6.445],
                        [154.536, -7.861],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 11',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 10,
                  ty: 'sh',
                  ix: 11,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.708, -0.53],
                        [0, -0.888],
                        [0.133, -0.397],
                        [2.435, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [1.304, 0],
                        [0.708, 0.528],
                        [0, 0.452],
                        [-0.472, 1.302],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [149.156, -5.452],
                        [154.338, -5.452],
                        [157.355, -4.66],
                        [158.416, -2.536],
                        [158.218, -1.262],
                        [153.858, 0.693],
                        [149.156, 0.693],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 12',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 11,
                  ty: 'sh',
                  ix: 12,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.849, -0.509],
                        [0, -1.133],
                        [0.17, -0.397],
                        [2.68, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [1.643, 0],
                        [0.85, 0.509],
                        [0, 0.491],
                        [-0.51, 1.284],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [149.156, 3.015],
                        [154.48, 3.015],
                        [158.218, 3.78],
                        [159.493, 6.243],
                        [159.238, 7.575],
                        [154.452, 9.5],
                        [149.156, 9.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 13',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-138.462, 16.261], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 13,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 3,
          op: 149,
          st: 3,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_1',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Group 1',
          parent: 3,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [113.909, 93.441, 0], ix: 2 },
            a: { a: 0, k: [113.909, 93.441, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, -7.221],
                        [-3.331, 1.942],
                      ],
                      o: [
                        [-3.666, -1.909],
                        [0, 7.221],
                        [0, 0],
                      ],
                      v: [
                        [5.105, -5.312],
                        [-5.105, 0],
                        [5.105, 5.246],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2.8, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [113.821, 93.531], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 100, ix: 1 },
              e: {
                a: 1,
                k: [
                  {
                    i: { x: [0.083], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 10,
                    s: [100],
                  },
                  { t: 17, s: [0] },
                ],
                ix: 2,
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
          ],
          ip: 0,
          op: 1000,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Group 2',
          parent: 3,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [113.909, 93.441, 0], ix: 2 },
            a: { a: 0, k: [113.909, 93.441, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [7.004, -6.485],
                        [0, 0],
                        [-13.846, 7.233],
                      ],
                      o: [
                        [0, 0],
                        [-7.003, 6.484],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [11.907, -11.307],
                        [-4.904, -10.392],
                        [-7.402, 5.736],
                        [11.907, 9.643],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 3.5, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [111.121, 94.288], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 100, ix: 1 },
              e: {
                a: 1,
                k: [
                  {
                    i: { x: [0.083], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 6,
                    s: [100],
                  },
                  { t: 13, s: [0] },
                ],
                ix: 2,
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
          ],
          ip: 0,
          op: 1000,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Group 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.289], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 0,
                  s: [-180],
                },
                { t: 13, s: [0] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [49.909, 49.941, 0], ix: 2 },
            a: { a: 0, k: [113.909, 93.441, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 0,
                  s: [0, 0, 100],
                },
                { t: 7, s: [100, 100, 100] },
              ],
              ix: 6,
              x: 'var $bm_rt;\nvar amp, freq, decay, n, n, t, t, v;\namp = 0.06;\nfreq = 2;\ndecay = 2;\n$bm_rt = n = 0;\nif (numKeys > 0) {\n    $bm_rt = n = nearestKey(time).index;\n    if (key(n).time > time) {\n        n--;\n    }\n}\nif (n == 0) {\n    $bm_rt = t = 0;\n} else {\n    $bm_rt = t = $bm_sub(time, key(n).time);\n}\nif (n > 0 && t < 1) {\n    v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10)));\n    $bm_rt = $bm_sum(value, $bm_div($bm_mul($bm_mul(v, amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))));\n} else {\n    $bm_rt = value;\n}',
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -12.427],
                        [12.427, 0],
                        [0, 12.426],
                        [-12.426, 0],
                      ],
                      o: [
                        [0, 12.426],
                        [-12.426, 0],
                        [0, -12.427],
                        [12.427, 0],
                      ],
                      v: [
                        [22.5, 0],
                        [0, 22.5],
                        [-22.5, 0],
                        [0, -22.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.969000004787, 0.57599995931, 0.102000000898, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [113.909, 93.441], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 1000,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_2',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Group 3',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.146], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 0,
                  s: [0],
                },
                { t: 7, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.146, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 0,
                  s: [136, 36, 0],
                  to: [5.667, 0, 0],
                  ti: [-5.667, 0, 0],
                },
                { t: 7, s: [170, 36, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [143, 36, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.662, -3.626],
                        [0, -6.23],
                        [-3.776, -3.701],
                        [-2.418, -0.925],
                        [-2.813, 0],
                        [-2.019, 0.68],
                        [-1.774, 0.981],
                        [0, 1.02],
                        [0.396, 0.434],
                        [0.547, 0],
                        [0.303, -0.152],
                        [1.585, -0.49],
                        [2.228, 0],
                        [2.757, 2.982],
                        [0, 4.532],
                        [-2.53, 2.756],
                        [-1.907, 0.794],
                        [-2.361, 0],
                        [-1.586, -0.49],
                        [-1.51, -0.793],
                        [-0.547, 0],
                        [-0.396, 0.433],
                        [0, 0.548],
                        [0.227, 0.359],
                        [1.077, 0.585],
                        [2.021, 0.681],
                        [2.794, 0],
                      ],
                      o: [
                        [-3.776, 3.7],
                        [0, 6.231],
                        [1.774, 1.775],
                        [2.416, 0.925],
                        [2.812, 0],
                        [2.021, -0.679],
                        [0.832, -0.416],
                        [0, -0.566],
                        [-0.396, -0.435],
                        [-0.547, 0],
                        [-1.51, 0.793],
                        [-1.586, 0.492],
                        [-4.834, 0],
                        [-2.53, -2.757],
                        [0, -4.531],
                        [1.284, -1.398],
                        [1.908, -0.793],
                        [2.36, 0],
                        [1.585, 0.491],
                        [0.303, 0.151],
                        [0.547, 0],
                        [0.396, -0.435],
                        [0, -0.547],
                        [-0.226, -0.359],
                        [-1.076, -0.586],
                        [-2.019, -0.679],
                        [-5.777, 0],
                      ],
                      v: [
                        [13.781, 15.084],
                        [8.117, 29.98],
                        [13.781, 44.877],
                        [20.069, 48.927],
                        [27.913, 50.315],
                        [35.162, 49.295],
                        [40.855, 46.803],
                        [42.101, 44.65],
                        [41.507, 43.15],
                        [40.091, 42.498],
                        [38.816, 42.725],
                        [34.172, 44.65],
                        [28.451, 45.387],
                        [17.066, 40.913],
                        [13.271, 29.98],
                        [17.066, 19.049],
                        [21.852, 15.763],
                        [28.253, 14.574],
                        [34.172, 15.31],
                        [38.816, 17.236],
                        [40.091, 17.462],
                        [41.507, 16.812],
                        [42.101, 15.338],
                        [41.761, 13.979],
                        [39.807, 12.564],
                        [35.162, 10.665],
                        [27.941, 9.647],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 4',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [25.109, 29.981], ix: 2 },
                  a: { a: 0, k: [25.109, 29.981], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 146,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Group 12',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.146], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 1,
                  s: [0],
                },
                { t: 8, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.146, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 1,
                  s: [136, 36, 0],
                  to: [5.667, 0, 0],
                  ti: [-5.667, 0, 0],
                },
                { t: 8, s: [170, 36, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [143, 36, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [2.644, -3.55],
                        [0, 0],
                        [0.511, 0.511],
                        [0.717, 0],
                        [0.492, -0.509],
                        [0, -0.718],
                        [0, 0],
                        [-0.49, -0.491],
                        [-0.718, 0],
                        [-0.509, 0.491],
                        [0, 0.717],
                        [0, 0],
                        [-1.491, 0.925],
                        [-1.888, 0],
                        [0, 0],
                        [-0.492, 0.434],
                        [0, 0.736],
                        [0.471, 0.491],
                        [0.717, 0],
                      ],
                      o: [
                        [-3.286, 0],
                        [0, 0],
                        [0, -0.718],
                        [-0.509, -0.509],
                        [-0.718, 0],
                        [-0.49, 0.511],
                        [0, 0],
                        [0, 0.717],
                        [0.492, 0.491],
                        [0.717, 0],
                        [0.511, -0.491],
                        [0, 0],
                        [0.981, -1.473],
                        [1.491, -0.926],
                        [0, 0],
                        [0.756, 0],
                        [0.49, -0.435],
                        [0, -0.736],
                        [-0.473, -0.491],
                        [0, 0],
                      ],
                      v: [
                        [62.606, 20.295],
                        [53.713, 25.619],
                        [53.713, 23.184],
                        [52.948, 21.342],
                        [51.108, 20.578],
                        [49.294, 21.342],
                        [48.558, 23.184],
                        [48.558, 47.483],
                        [49.294, 49.295],
                        [51.108, 50.032],
                        [52.948, 49.295],
                        [53.713, 47.483],
                        [53.713, 30.264],
                        [57.423, 26.667],
                        [62.493, 25.279],
                        [62.775, 25.279],
                        [64.645, 24.628],
                        [65.381, 22.872],
                        [64.674, 21.031],
                        [62.889, 20.295],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 13',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [56.969, 35.164], ix: 2 },
                  a: { a: 0, k: [56.969, 35.164], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 12',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 1,
          op: 147,
          st: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Group 1',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.146], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 2,
                  s: [0],
                },
                { t: 9, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.146, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 2,
                  s: [136, 36, 0],
                  to: [5.667, 0, 0],
                  ti: [-5.667, 0, 0],
                },
                { t: 9, s: [170, 36, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [143, 36, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.491, -1.133],
                        [0, 0],
                        [0, 0],
                        [1.247, 0],
                        [0.49, -0.509],
                        [0, -0.528],
                        [-0.151, -0.378],
                        [0, 0],
                        [0, 0],
                        [0, -0.49],
                        [-0.472, -0.491],
                        [-0.68, 0],
                        [-0.49, 1.17],
                        [0, 0],
                        [0, 0.547],
                        [0.491, 0.511],
                        [0.717, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [-0.453, -1.058],
                        [-0.604, 0],
                        [-0.491, 0.511],
                        [0, 0.529],
                        [0, 0],
                        [0, 0],
                        [-0.113, 0.301],
                        [0, 0.492],
                        [0.472, 0.49],
                        [1.095, 0],
                        [0, 0],
                        [0.114, -0.265],
                        [0, -0.548],
                        [-0.49, -0.509],
                        [-1.095, 0],
                      ],
                      v: [
                        [-57.055, -6.124],
                        [-65.949, 15.401],
                        [-74.671, -6.236],
                        [-77.221, -7.822],
                        [-78.863, -7.059],
                        [-79.598, -5.501],
                        [-79.372, -4.141],
                        [-68.725, 21.801],
                        [-72.406, 31.26],
                        [-72.575, 32.449],
                        [-71.867, 33.923],
                        [-70.139, 34.659],
                        [-67.761, 32.902],
                        [-52.298, -4.254],
                        [-52.128, -5.471],
                        [-52.864, -7.059],
                        [-54.676, -7.822],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 14',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [146.962, 28.401], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 2,
          op: 148,
          st: 2,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Group 10',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.146], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 3,
                  s: [0],
                },
                { t: 10, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.146, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 3,
                  s: [136, 36, 0],
                  to: [5.667, 0, 0],
                  ti: [-5.667, 0, 0],
                },
                { t: 10, s: [170, 36, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [143, 36, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.794, -2.869],
                        [0, 0],
                        [0.491, 0.491],
                        [0.736, 0],
                        [0.491, -0.49],
                        [0, -0.717],
                        [0, 0],
                        [-0.491, -0.491],
                        [-0.736, 0],
                        [-0.491, 0.49],
                        [0, 0.756],
                        [0, 0],
                        [-3.644, 0],
                        [-2.643, 2.568],
                        [0, 4.607],
                        [2.871, 2.87],
                        [3.663, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, -0.717],
                        [-0.491, -0.49],
                        [-0.736, 0],
                        [-0.491, 0.491],
                        [0, 0],
                        [0, 0.756],
                        [0.491, 0.49],
                        [0.736, 0],
                        [0.491, -0.491],
                        [0, 0],
                        [2.794, 2.869],
                        [3.644, 0],
                        [2.871, -2.831],
                        [0, -4.532],
                        [-2.605, -2.605],
                        [-3.663, 0],
                      ],
                      v: [
                        [105.823, 24.599],
                        [105.823, 23.127],
                        [105.087, 21.314],
                        [103.246, 20.578],
                        [101.405, 21.314],
                        [100.669, 23.127],
                        [100.669, 60.453],
                        [101.405, 62.323],
                        [103.246, 63.059],
                        [105.087, 62.323],
                        [105.823, 60.453],
                        [105.823, 46.01],
                        [115.48, 50.315],
                        [124.911, 46.463],
                        [129.215, 35.305],
                        [124.911, 24.203],
                        [115.508, 20.295],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 11',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.567, 2.832],
                        [0, 0],
                        [-3.398, 0],
                        [-1.662, -1.699],
                        [0, -3.247],
                        [0.454, -1.284],
                        [4.305, 0],
                      ],
                      o: [
                        [0, 0],
                        [2.567, -2.833],
                        [2.794, 0],
                        [1.736, 1.851],
                        [0, 1.661],
                        [-1.434, 3.852],
                        [-3.398, 0],
                      ],
                      v: [
                        [105.823, 41.252],
                        [105.823, 29.358],
                        [114.772, 25.11],
                        [121.456, 27.658],
                        [124.061, 35.305],
                        [123.381, 39.723],
                        [114.772, 45.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 12',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [114.942, 41.677], ix: 2 },
                  a: { a: 0, k: [114.942, 41.677], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 10',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 3,
          op: 149,
          st: 3,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Group 5',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.146], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 4,
                  s: [0],
                },
                { t: 11, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.146, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 4,
                  s: [136, 36, 0],
                  to: [5.667, 0, 0],
                  ti: [-5.667, 0, 0],
                },
                { t: 11, s: [170, 36, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [143, 36, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.339, -0.34],
                        [0, 0],
                        [0, -0.34],
                        [-0.717, 0],
                        [0, 0],
                        [0, 0],
                        [-2.077, -1.209],
                        [-0.944, -0.113],
                        [-1.152, 0],
                        [-1.623, 0.755],
                        [0, 0.756],
                        [0.415, 0.377],
                        [0.509, 0],
                        [0.434, -0.17],
                        [1.019, 0],
                        [0.49, 0.301],
                        [0, 1.436],
                        [0, 0],
                        [0, 0],
                        [-0.415, 0.416],
                        [0, 0.642],
                        [0.416, 0.415],
                        [0.642, 0],
                        [0, 0],
                        [0, 0],
                        [0.208, 0.226],
                        [0.303, 0],
                      ],
                      o: [
                        [0, 0],
                        [-0.34, 0.303],
                        [0, 0.718],
                        [0, 0],
                        [0, 0],
                        [0, 3.021],
                        [0.869, 0.528],
                        [0.944, 0.113],
                        [1.151, 0],
                        [0.755, -0.34],
                        [0, -0.755],
                        [-0.416, -0.378],
                        [-0.51, 0],
                        [-0.435, 0.17],
                        [-0.982, 0],
                        [-0.869, -0.529],
                        [0, 0],
                        [0, 0],
                        [0.642, 0],
                        [0.416, -0.416],
                        [0, -0.642],
                        [-0.415, -0.415],
                        [0, 0],
                        [0, 0],
                        [0, -0.302],
                        [-0.208, -0.227],
                        [-0.301, 0],
                      ],
                      v: [
                        [141.167, 15.707],
                        [133.464, 23.24],
                        [132.954, 24.203],
                        [134.03, 25.279],
                        [138.051, 25.279],
                        [138.051, 42.838],
                        [141.167, 49.182],
                        [143.886, 50.145],
                        [147.03, 50.315],
                        [151.192, 49.182],
                        [152.325, 47.539],
                        [151.703, 45.84],
                        [150.315, 45.274],
                        [148.899, 45.529],
                        [146.718, 45.784],
                        [144.509, 45.331],
                        [143.207, 42.384],
                        [143.207, 25.279],
                        [149.606, 25.279],
                        [151.192, 24.656],
                        [151.816, 23.07],
                        [151.192, 21.484],
                        [149.606, 20.862],
                        [143.207, 20.862],
                        [143.207, 16.33],
                        [142.894, 15.537],
                        [142.129, 15.197],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 6',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [142.639, 32.756], ix: 2 },
                  a: { a: 0, k: [142.639, 32.756], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 4,
          op: 150,
          st: 4,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'Group 8',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.146], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 5,
                  s: [0],
                },
                { t: 12, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.146, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 5,
                  s: [136, 36, 0],
                  to: [5.667, 0, 0],
                  ti: [-5.667, 0, 0],
                },
                { t: 12, s: [170, 36, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [143, 36, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.605, -2.756],
                        [0, -4.494],
                        [-2.643, -2.757],
                        [-4.418, 0],
                        [-2.643, 2.756],
                        [0, 4.456],
                        [2.644, 2.795],
                        [4.381, 0],
                      ],
                      o: [
                        [-2.643, 2.758],
                        [0, 4.494],
                        [2.605, 2.756],
                        [4.381, 0],
                        [2.644, -2.794],
                        [0, -4.456],
                        [-2.643, -2.756],
                        [-4.418, 0],
                      ],
                      v: [
                        [160.142, 24.429],
                        [156.177, 35.305],
                        [160.142, 46.18],
                        [170.677, 50.315],
                        [181.212, 46.18],
                        [185.177, 35.305],
                        [181.212, 24.429],
                        [170.677, 20.295],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 9',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.114, 0.377],
                        [0.906, 0.793],
                        [0.566, 1.321],
                        [0, 1.869],
                        [-0.567, 1.322],
                        [-4.079, 0],
                        [-1.699, -1.548],
                        [0, -3.398],
                        [2.039, -1.85],
                        [1.116, -0.378],
                        [1.077, 0],
                      ],
                      o: [
                        [-1.114, -0.378],
                        [-0.868, -0.83],
                        [-0.567, -1.322],
                        [0, -1.869],
                        [1.586, -3.662],
                        [2.492, 0],
                        [2.039, 1.888],
                        [0, 3.512],
                        [-0.906, 0.793],
                        [-1.113, 0.377],
                        [-1.075, 0],
                      ],
                      v: [
                        [167.364, 45.047],
                        [164.333, 43.291],
                        [162.181, 40.063],
                        [161.332, 35.276],
                        [162.181, 30.49],
                        [170.677, 24.996],
                        [176.964, 27.318],
                        [180.023, 35.248],
                        [176.964, 43.291],
                        [173.933, 45.047],
                        [170.648, 45.613],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 10',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [170.677, 35.305], ix: 2 },
                  a: { a: 0, k: [170.677, 35.305], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 8',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 5,
          op: 151,
          st: 5,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'Group 4',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.146], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 6,
                  s: [0],
                },
                { t: 13, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.146, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 6,
                  s: [136, 36, 0],
                  to: [5.667, 0, 0],
                  ti: [-5.667, 0, 0],
                },
                { t: 13, s: [170, 36, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [143, 36, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.452, -0.472],
                        [0, -0.679],
                        [-0.454, -0.452],
                        [-0.681, 0],
                        [0, 0],
                        [0, 0],
                        [-0.491, -0.491],
                        [-0.718, 0],
                        [-0.509, 0.491],
                        [0, 0.717],
                        [0, 0],
                        [0, 0],
                        [-0.473, 0.454],
                        [0, 0.68],
                        [0.471, 0.472],
                        [0.68, 0],
                      ],
                      o: [
                        [-0.681, 0],
                        [-0.454, 0.472],
                        [0, 0.68],
                        [0.452, 0.454],
                        [0, 0],
                        [0, 0],
                        [0, 0.717],
                        [0.491, 0.491],
                        [0.717, 0],
                        [0.511, -0.491],
                        [0, 0],
                        [0, 0],
                        [0.68, 0],
                        [0.471, -0.452],
                        [0, -0.679],
                        [-0.473, -0.472],
                        [0, 0],
                      ],
                      v: [
                        [186.197, 10.213],
                        [184.498, 10.921],
                        [183.817, 12.648],
                        [184.498, 14.347],
                        [186.197, 15.027],
                        [197.752, 15.027],
                        [197.752, 47.483],
                        [198.488, 49.295],
                        [200.301, 50.032],
                        [202.141, 49.295],
                        [202.906, 47.483],
                        [202.906, 15.027],
                        [214.404, 15.027],
                        [216.132, 14.347],
                        [216.84, 12.648],
                        [216.132, 10.921],
                        [214.404, 10.213],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 5',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [200.329, 30.123], ix: 2 },
                  a: { a: 0, k: [200.329, 30.123], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 6,
          op: 152,
          st: 6,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: 'Group 6',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.146], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 7,
                  s: [0],
                },
                { t: 14, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.146, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 7,
                  s: [136, 36, 0],
                  to: [5.667, 0, 0],
                  ti: [-5.667, 0, 0],
                },
                { t: 14, s: [170, 36, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [143, 36, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.172, -1.36],
                        [0, -0.83],
                        [-0.415, -0.397],
                        [-0.377, 0],
                        [-0.302, 0.076],
                        [-2.189, 0],
                        [-1.246, -1.076],
                        [0, -2.53],
                        [0, 0],
                        [0, 0],
                        [2.303, -1.737],
                        [0, -2.794],
                        [-1.813, -1.586],
                        [-2.605, 0],
                        [-1.717, 0.906],
                        [-1.02, 1.057],
                        [0, 0],
                        [-0.491, -0.491],
                        [-0.718, 0],
                        [-0.509, 0.491],
                        [0, 0.717],
                        [0, 0],
                        [1.887, 2.04],
                        [3.852, 0],
                      ],
                      o: [
                        [-0.982, 0.416],
                        [0, 0.831],
                        [0.416, 0.397],
                        [0.378, 0],
                        [2.832, -0.906],
                        [2.568, 0],
                        [1.246, 1.076],
                        [0, 0],
                        [0, 0],
                        [-4.267, 0],
                        [-2.115, 1.586],
                        [0, 2.568],
                        [1.737, 1.51],
                        [2.644, 0],
                        [1.719, -0.906],
                        [0, 0],
                        [0, 0.717],
                        [0.491, 0.491],
                        [0.717, 0],
                        [0.51, -0.491],
                        [0, 0],
                        [0, -3.058],
                        [-2.115, -2.341],
                        [-3.398, 0],
                      ],
                      v: [
                        [218.709, 22.334],
                        [217.236, 24.203],
                        [217.859, 26.044],
                        [219.049, 26.639],
                        [220.068, 26.525],
                        [227.601, 25.166],
                        [233.322, 26.78],
                        [235.191, 32.189],
                        [235.191, 32.643],
                        [228.338, 32.643],
                        [218.483, 35.248],
                        [215.31, 41.818],
                        [218.029, 48.049],
                        [224.543, 50.315],
                        [231.084, 48.955],
                        [235.191, 46.01],
                        [235.191, 47.483],
                        [235.928, 49.295],
                        [237.741, 50.032],
                        [239.581, 49.295],
                        [240.346, 47.483],
                        [240.346, 31.453],
                        [237.514, 23.806],
                        [228.564, 20.295],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 7',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 3.02],
                        [-1.511, 0.68],
                        [-2.68, 0],
                        [0, 0],
                        [0, 0],
                        [1.529, -0.869],
                        [1.775, 0],
                      ],
                      o: [
                        [0, -1.624],
                        [1.51, -0.68],
                        [0, 0],
                        [0, 0],
                        [-0.981, 1.094],
                        [-1.53, 0.868],
                        [-4.002, 0],
                      ],
                      v: [
                        [220.464, 41.252],
                        [222.731, 37.797],
                        [229.017, 36.777],
                        [235.191, 36.777],
                        [235.191, 41.536],
                        [231.425, 44.481],
                        [226.468, 45.784],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 8',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [227.828, 35.305], ix: 2 },
                  a: { a: 0, k: [227.828, 35.305], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 7,
          op: 153,
          st: 7,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: 'Group 2',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.146], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 8,
                  s: [0],
                },
                { t: 15, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.146, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 8,
                  s: [136, 36, 0],
                  to: [5.667, 0, 0],
                  ti: [-5.667, 0, 0],
                },
                { t: 15, s: [170, 36, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [143, 36, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.492, -0.492],
                        [0, -0.755],
                        [0, 0],
                        [-0.49, -0.491],
                        [-0.736, 0],
                        [-0.49, 0.491],
                        [0, 0.717],
                        [0, 0],
                        [-3.644, 0],
                        [-2.643, 2.568],
                        [0, 4.607],
                        [2.87, 2.87],
                        [3.662, 0],
                        [2.795, -2.869],
                        [0, 0],
                        [0.492, 0.49],
                        [0.737, 0],
                      ],
                      o: [
                        [-0.49, 0.49],
                        [0, 0],
                        [0, 0.717],
                        [0.492, 0.491],
                        [0.737, 0],
                        [0.492, -0.491],
                        [0, 0],
                        [2.795, 2.869],
                        [3.644, 0],
                        [2.87, -2.831],
                        [0, -4.532],
                        [-2.605, -2.605],
                        [-3.663, 0],
                        [0, 0],
                        [0, -0.755],
                        [-0.49, -0.492],
                        [-0.736, 0],
                      ],
                      v: [
                        [249.804, 8.854],
                        [249.068, 10.722],
                        [249.068, 47.483],
                        [249.804, 49.295],
                        [251.645, 50.032],
                        [253.486, 49.295],
                        [254.222, 47.483],
                        [254.222, 46.01],
                        [263.88, 50.315],
                        [273.311, 46.463],
                        [277.616, 35.305],
                        [273.311, 24.203],
                        [263.909, 20.295],
                        [254.222, 24.599],
                        [254.222, 10.722],
                        [253.486, 8.854],
                        [251.645, 8.117],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.569, 2.832],
                        [0, 0],
                        [-3.398, 0],
                        [-1.662, -1.699],
                        [0, -3.247],
                        [0.453, -1.284],
                        [4.305, 0],
                      ],
                      o: [
                        [0, 0],
                        [2.569, -2.833],
                        [2.794, 0],
                        [1.736, 1.851],
                        [0, 1.661],
                        [-1.435, 3.852],
                        [-3.398, 0],
                      ],
                      v: [
                        [254.222, 41.252],
                        [254.222, 29.358],
                        [263.173, 25.11],
                        [269.856, 27.658],
                        [272.462, 35.305],
                        [271.782, 39.723],
                        [263.173, 45.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [263.342, 29.216], ix: 2 },
                  a: { a: 0, k: [263.342, 29.216], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 8,
          op: 154,
          st: 8,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: 'cryptotab/texts Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [170, 36, 0], ix: 2 },
            a: { a: 0, k: [143, 36, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [],
          ip: 0,
          op: 146,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 8,
      ty: 0,
      nm: 'browser',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [283.5, 234, 0], ix: 2 },
        a: { a: 0, k: [393.5, 1000, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 787,
      h: 2000,
      ip: 0,
      op: 146,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};

export const logo = {
  v: '5.7.11',
  fr: 30,
  ip: 0,
  op: 150,
  w: 576,
  h: 121,
  nm: 'texts',
  ddd: 0,
  assets: [
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 0,
          nm: 'crypto_affiliate_new',
          refId: 'comp_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [180.5, 680, 0], ix: 2, l: 2 },
            a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
            s: { a: 0, k: [202, 202, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 100,
          h: 100,
          ip: 0,
          op: 1200,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 0,
          nm: 'cryptotab 2',
          refId: 'comp_2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [388.967, 686.969, 0], ix: 2, l: 2 },
            a: { a: 0, k: [169.841, 35.588, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 340,
          h: 72,
          ip: 8.4,
          op: 183.6,
          st: 8.4,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'affilate Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.48], y: [1] },
                  o: { x: [0.298], y: [0] },
                  t: 20.4,
                  s: [-59],
                  e: [8],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.406], y: [0] },
                  t: 28.8,
                  s: [8],
                  e: [-4],
                },
                {
                  i: { x: [0.385], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 33.6,
                  s: [-4],
                  e: [0],
                },
                { t: 37.2 },
              ],
              ix: 10,
            },
            p: { a: 0, k: [540.967, 671.638, 0], ix: 2, l: 2 },
            a: { a: 0, k: [8.117, 8.117, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.48, 0.48, 0.667], y: [1, 1, 1] },
                  o: { x: [0.298, 0.298, 0.333], y: [0, 0, 0] },
                  t: 20.4,
                  s: [0, 0, 100],
                  e: [100, 100, 100],
                },
                { t: 28.8 },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.34, -0.775],
                        [0, 0],
                        [0, -0.254],
                        [-0.227, -0.246],
                        [-0.491, 0],
                        [-0.227, 0.529],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.49, 0],
                        [-0.227, 0.245],
                        [0, 0.255],
                        [0.095, 0.226],
                        [0, 0],
                        [0.756, 0],
                      ],
                      o: [
                        [-0.755, 0],
                        [0, 0],
                        [-0.094, 0.226],
                        [0, 0.255],
                        [0.226, 0.245],
                        [0.491, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.226, 0.529],
                        [0.491, 0],
                        [0.227, -0.246],
                        [0, -0.254],
                        [0, 0],
                        [-0.339, -0.775],
                        [0, 0],
                      ],
                      v: [
                        [243.655, -8.144],
                        [242.012, -6.982],
                        [234.79, 10.067],
                        [234.65, 10.789],
                        [234.989, 11.54],
                        [236.066, 11.908],
                        [237.142, 11.115],
                        [238.983, 6.697],
                        [248.611, 6.697],
                        [250.452, 11.115],
                        [251.528, 11.908],
                        [252.605, 11.54],
                        [252.944, 10.789],
                        [252.803, 10.067],
                        [245.58, -6.982],
                        [243.939, -8.144],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [243.798, -4.857],
                        [247.621, 4.346],
                        [239.973, 4.346],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.245, -0.254],
                        [0, -0.359],
                        [0, 0],
                        [-0.245, -0.246],
                        [-0.358, 0],
                        [-0.256, 0.245],
                        [0, 0.359],
                        [0, 0],
                        [0.255, 0.255],
                        [0.358, 0],
                      ],
                      o: [
                        [-0.245, 0.255],
                        [0, 0],
                        [0, 0.359],
                        [0.245, 0.245],
                        [0.358, 0],
                        [0.255, -0.246],
                        [0, 0],
                        [0, -0.359],
                        [-0.256, -0.254],
                        [-0.358, 0],
                      ],
                      v: [
                        [228.957, -7.762],
                        [228.588, -6.84],
                        [228.588, 10.633],
                        [228.957, 11.54],
                        [229.863, 11.908],
                        [230.785, 11.54],
                        [231.166, 10.633],
                        [231.166, -6.84],
                        [230.785, -7.762],
                        [229.863, -8.144],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 4',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 3,
                  ty: 'sh',
                  ix: 4,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.246, -0.254],
                        [0, -0.359],
                        [0, 0],
                        [-0.349, -0.349],
                        [-0.492, 0],
                        [0, 0],
                        [-0.254, 0.245],
                        [0, 0.358],
                        [0.256, 0.254],
                        [0.359, 0],
                        [0, 0],
                        [0, 0],
                        [0.255, 0.255],
                        [0.359, 0],
                      ],
                      o: [
                        [-0.245, 0.255],
                        [0, 0],
                        [0, 0.491],
                        [0.349, 0.348],
                        [0, 0],
                        [0.359, 0],
                        [0.256, -0.246],
                        [0, -0.359],
                        [-0.254, -0.255],
                        [0, 0],
                        [0, 0],
                        [0, -0.359],
                        [-0.255, -0.254],
                        [-0.358, 0],
                      ],
                      v: [
                        [212.645, -7.762],
                        [212.277, -6.84],
                        [212.277, 9.982],
                        [212.801, 11.243],
                        [214.061, 11.767],
                        [224.114, 11.767],
                        [225.033, 11.398],
                        [225.418, 10.492],
                        [225.033, 9.572],
                        [224.114, 9.189],
                        [214.855, 9.189],
                        [214.855, -6.84],
                        [214.47, -7.762],
                        [213.551, -8.144],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 5',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 4,
                  ty: 'sh',
                  ix: 5,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.245, -0.254],
                        [0, -0.359],
                        [0, 0],
                        [-0.246, -0.246],
                        [-0.359, 0],
                        [-0.255, 0.245],
                        [0, 0.359],
                        [0, 0],
                        [0.255, 0.255],
                        [0.358, 0],
                      ],
                      o: [
                        [-0.246, 0.255],
                        [0, 0],
                        [0, 0.359],
                        [0.245, 0.245],
                        [0.358, 0],
                        [0.255, -0.246],
                        [0, 0],
                        [0, -0.359],
                        [-0.255, -0.254],
                        [-0.359, 0],
                      ],
                      v: [
                        [204.574, -7.762],
                        [204.205, -6.84],
                        [204.205, 10.633],
                        [204.574, 11.54],
                        [205.48, 11.908],
                        [206.4, 11.54],
                        [206.781, 10.633],
                        [206.781, -6.84],
                        [206.4, -7.762],
                        [205.48, -8.144],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 6',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 5,
                  ty: 'sh',
                  ix: 6,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.341, -0.775],
                        [0, 0],
                        [0, -0.254],
                        [-0.227, -0.246],
                        [-0.491, 0],
                        [-0.227, 0.529],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.49, 0],
                        [-0.226, 0.245],
                        [0, 0.255],
                        [0.095, 0.226],
                        [0, 0],
                        [0.755, 0],
                      ],
                      o: [
                        [-0.755, 0],
                        [0, 0],
                        [-0.095, 0.226],
                        [0, 0.255],
                        [0.227, 0.245],
                        [0.492, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.226, 0.529],
                        [0.491, 0],
                        [0.226, -0.246],
                        [0, -0.254],
                        [0, 0],
                        [-0.34, -0.775],
                        [0, 0],
                      ],
                      v: [
                        [156.91, -8.144],
                        [155.267, -6.982],
                        [148.046, 10.067],
                        [147.903, 10.789],
                        [148.243, 11.54],
                        [149.319, 11.908],
                        [150.397, 11.115],
                        [152.236, 6.697],
                        [161.866, 6.697],
                        [163.707, 11.115],
                        [164.783, 11.908],
                        [165.858, 11.54],
                        [166.199, 10.789],
                        [166.056, 10.067],
                        [158.835, -6.982],
                        [157.192, -8.144],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 7',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 6,
                  ty: 'sh',
                  ix: 7,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [157.051, -4.857],
                        [160.874, 4.346],
                        [153.228, 4.346],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 8',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 7,
                  ty: 'sh',
                  ix: 8,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.245, -0.256],
                        [0, -0.359],
                        [0, 0],
                        [-0.246, -0.246],
                        [-0.358, 0],
                        [0, 0],
                        [-0.236, 0.227],
                        [0, 0.34],
                        [0.236, 0.236],
                        [0.34, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.237, 0.226],
                        [0, 0.339],
                        [0.235, 0.236],
                        [0.34, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.236, 0.227],
                        [0, 0.339],
                        [0.236, 0.236],
                        [0.34, 0],
                      ],
                      o: [
                        [-0.358, 0],
                        [-0.246, 0.254],
                        [0, 0],
                        [0, 0.358],
                        [0.245, 0.245],
                        [0, 0],
                        [0.34, 0],
                        [0.236, -0.226],
                        [0, -0.34],
                        [-0.236, -0.236],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.34, 0],
                        [0.235, -0.226],
                        [0, -0.34],
                        [-0.237, -0.237],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.34, 0],
                        [0.236, -0.227],
                        [0, -0.341],
                        [-0.236, -0.237],
                        [0, 0],
                      ],
                      v: [
                        [274.978, -8.001],
                        [274.072, -7.618],
                        [273.704, -6.7],
                        [273.704, 10.492],
                        [274.072, 11.398],
                        [274.978, 11.767],
                        [286.335, 11.767],
                        [287.199, 11.426],
                        [287.553, 10.577],
                        [287.199, 9.713],
                        [286.335, 9.359],
                        [276.281, 9.359],
                        [276.281, 3.072],
                        [285.316, 3.072],
                        [286.179, 2.732],
                        [286.533, 1.883],
                        [286.179, 1.019],
                        [285.316, 0.665],
                        [276.281, 0.665],
                        [276.281, -5.594],
                        [286.335, -5.594],
                        [287.199, -5.935],
                        [287.553, -6.784],
                        [287.199, -7.648],
                        [286.335, -8.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 9',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 8,
                  ty: 'sh',
                  ix: 9,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.226, -0.237],
                        [0, -0.341],
                        [-0.227, -0.227],
                        [-0.34, 0],
                        [0, 0],
                        [0, 0],
                        [-0.245, -0.246],
                        [-0.358, 0],
                        [-0.255, 0.245],
                        [0, 0.359],
                        [0, 0],
                        [0, 0],
                        [-0.235, 0.227],
                        [0, 0.339],
                        [0.237, 0.236],
                        [0.341, 0],
                      ],
                      o: [
                        [-0.34, 0],
                        [-0.227, 0.236],
                        [0, 0.339],
                        [0.226, 0.227],
                        [0, 0],
                        [0, 0],
                        [0, 0.359],
                        [0.245, 0.245],
                        [0.359, 0],
                        [0.254, -0.246],
                        [0, 0],
                        [0, 0],
                        [0.341, 0],
                        [0.237, -0.227],
                        [0, -0.341],
                        [-0.235, -0.237],
                        [0, 0],
                      ],
                      v: [
                        [255.238, -8.001],
                        [254.389, -7.648],
                        [254.049, -6.784],
                        [254.389, -5.935],
                        [255.238, -5.594],
                        [261.015, -5.594],
                        [261.015, 10.633],
                        [261.384, 11.54],
                        [262.29, 11.908],
                        [263.212, 11.54],
                        [263.592, 10.633],
                        [263.592, -5.594],
                        [269.342, -5.594],
                        [270.205, -5.935],
                        [270.559, -6.784],
                        [270.205, -7.648],
                        [269.342, -8.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 10',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 9,
                  ty: 'sh',
                  ix: 10,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.246, -0.256],
                        [0, -0.359],
                        [0, 0],
                        [-0.245, -0.246],
                        [-0.359, 0],
                        [-0.254, 0.245],
                        [0, 0.359],
                        [0, 0],
                        [0, 0],
                        [-0.236, 0.226],
                        [0, 0.339],
                        [0.236, 0.236],
                        [0.34, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.237, 0.227],
                        [0, 0.339],
                        [0.236, 0.236],
                        [0.339, 0],
                      ],
                      o: [
                        [-0.359, 0],
                        [-0.245, 0.254],
                        [0, 0],
                        [0, 0.359],
                        [0.246, 0.245],
                        [0.358, 0],
                        [0.255, -0.246],
                        [0, 0],
                        [0, 0],
                        [0.34, 0],
                        [0.236, -0.226],
                        [0, -0.34],
                        [-0.236, -0.237],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.339, 0],
                        [0.236, -0.227],
                        [0, -0.341],
                        [-0.237, -0.237],
                        [0, 0],
                      ],
                      v: [
                        [188.204, -8.001],
                        [187.298, -7.618],
                        [186.929, -6.7],
                        [186.929, 10.633],
                        [187.298, 11.54],
                        [188.204, 11.908],
                        [189.124, 11.54],
                        [189.507, 10.633],
                        [189.507, 3.072],
                        [198.23, 3.072],
                        [199.093, 2.732],
                        [199.447, 1.883],
                        [199.093, 1.019],
                        [198.23, 0.665],
                        [189.507, 0.665],
                        [189.507, -5.594],
                        [199.221, -5.594],
                        [200.085, -5.935],
                        [200.439, -6.784],
                        [200.085, -7.648],
                        [199.221, -8.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 11',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 10,
                  ty: 'sh',
                  ix: 11,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.247, -0.256],
                        [0, -0.359],
                        [0, 0],
                        [-0.245, -0.246],
                        [-0.358, 0],
                        [-0.255, 0.245],
                        [0, 0.359],
                        [0, 0],
                        [0, 0],
                        [-0.236, 0.226],
                        [0, 0.339],
                        [0.236, 0.236],
                        [0.341, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.236, 0.227],
                        [0, 0.339],
                        [0.237, 0.236],
                        [0.339, 0],
                      ],
                      o: [
                        [-0.358, 0],
                        [-0.245, 0.254],
                        [0, 0],
                        [0, 0.359],
                        [0.247, 0.245],
                        [0.359, 0],
                        [0.256, -0.246],
                        [0, 0],
                        [0, 0],
                        [0.341, 0],
                        [0.236, -0.226],
                        [0, -0.34],
                        [-0.236, -0.237],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.339, 0],
                        [0.237, -0.227],
                        [0, -0.341],
                        [-0.236, -0.237],
                        [0, 0],
                      ],
                      v: [
                        [170.928, -8.001],
                        [170.022, -7.618],
                        [169.653, -6.7],
                        [169.653, 10.633],
                        [170.022, 11.54],
                        [170.928, 11.908],
                        [171.848, 11.54],
                        [172.23, 10.633],
                        [172.23, 3.072],
                        [180.952, 3.072],
                        [181.817, 2.732],
                        [182.171, 1.883],
                        [181.817, 1.019],
                        [180.952, 0.665],
                        [172.23, 0.665],
                        [172.23, -5.594],
                        [181.944, -5.594],
                        [182.809, -5.935],
                        [183.163, -6.784],
                        [182.809, -7.648],
                        [181.944, -8.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 12',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-139.786, 16.261], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 12,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 3.6,
          op: 178.8,
          st: 3.6,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_1',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Shape Layer 2',
          parent: 5,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: -90, ix: 10 },
            p: { a: 0, k: [114.655, 91.538, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-2.625, 0.5, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.367, 0.367, 0.667], y: [1, 1, 1] },
                  o: { x: [0, 0, 0.333], y: [0, 0, 0] },
                  t: 22.8,
                  s: [0, 0, 100],
                  e: [100, 100, 100],
                },
                { t: 26.4 },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [2.375, 2.375], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.207843002619, 0.207843002619, 0.207843002619, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-2.625, 0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 1200,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Shape Layer 1',
          parent: 5,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: -90, ix: 10 },
            p: { a: 0, k: [114.655, 95.444, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-2.625, 0.5, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.367, 0.367, 0.667], y: [1, 1, 1] },
                  o: { x: [0, 0, 0.333], y: [0, 0, 0] },
                  t: 20.4,
                  s: [0, 0, 100],
                  e: [100, 100, 100],
                },
                { t: 24 },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [2.375, 2.375], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.207843002619, 0.207843002619, 0.207843002619, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-2.625, 0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 1200,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Group 1',
          parent: 5,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [113.909, 93.441, 0], ix: 2, l: 2 },
            a: { a: 0, k: [113.909, 93.441, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, -7.221],
                        [-3.331, 1.942],
                      ],
                      o: [
                        [-3.666, -1.909],
                        [0, 7.221],
                        [0, 0],
                      ],
                      v: [
                        [5.105, -5.312],
                        [-5.105, 0],
                        [5.105, 5.246],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2.8, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [113.821, 93.531], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 100, ix: 1 },
              e: {
                a: 1,
                k: [
                  {
                    i: { x: [0.083], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 12,
                    s: [100],
                    e: [0],
                  },
                  { t: 20.4 },
                ],
                ix: 2,
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
          ],
          ip: 0,
          op: 1200,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Group 2',
          parent: 5,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [113.909, 93.441, 0], ix: 2, l: 2 },
            a: { a: 0, k: [113.909, 93.441, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [7.004, -6.485],
                        [0, 0],
                        [-13.846, 7.233],
                      ],
                      o: [
                        [0, 0],
                        [-7.003, 6.484],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [11.907, -11.307],
                        [-4.904, -10.392],
                        [-7.402, 5.736],
                        [11.907, 9.643],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 3.5, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [111.121, 94.288], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 100, ix: 1 },
              e: {
                a: 1,
                k: [
                  {
                    i: { x: [0.083], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 7.2,
                    s: [100],
                    e: [0],
                  },
                  { t: 15.6 },
                ],
                ix: 2,
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
          ],
          ip: 0,
          op: 1200,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Group 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.289], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 0,
                  s: [-90],
                  e: [90],
                },
                { t: 15.6 },
              ],
              ix: 10,
            },
            p: { a: 0, k: [49.909, 49.941, 0], ix: 2, l: 2 },
            a: { a: 0, k: [113.909, 93.441, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 0,
                  s: [0, 0, 100],
                  e: [100, 100, 100],
                },
                { t: 8.4 },
              ],
              ix: 6,
              l: 2,
              x: 'var $bm_rt;\nvar amp, freq, decay, n, n, t, t, v;\namp = 0.06;\nfreq = 2;\ndecay = 2;\n$bm_rt = n = 0;\nif (numKeys > 0) {\n    $bm_rt = n = nearestKey(time).index;\n    if (key(n).time > time) {\n        n--;\n    }\n}\nif (n == 0) {\n    $bm_rt = t = 0;\n} else {\n    $bm_rt = t = $bm_sub(time, key(n).time);\n}\nif (n > 0 && t < 1) {\n    v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10)));\n    $bm_rt = $bm_sum(value, $bm_div($bm_mul($bm_mul(v, amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))));\n} else {\n    $bm_rt = value;\n}',
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -12.427],
                        [12.427, 0],
                        [0, 12.426],
                        [-12.426, 0],
                      ],
                      o: [
                        [0, 12.426],
                        [-12.426, 0],
                        [0, -12.427],
                        [12.427, 0],
                      ],
                      v: [
                        [22.5, 0],
                        [0, 22.5],
                        [-22.5, 0],
                        [0, -22.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.969000004787, 0.57599995931, 0.102000000898, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [113.909, 93.441], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 1200,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_2',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Group 3',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.146], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 0,
                  s: [0],
                  e: [100],
                },
                { t: 8.4 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.146, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 0,
                  s: [136, 36, 0],
                  e: [170, 36, 0],
                  to: [5.667, 0, 0],
                  ti: [-5.667, 0, 0],
                },
                { t: 8.4 },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [143, 36, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.662, -3.626],
                        [0, -6.23],
                        [-3.776, -3.701],
                        [-2.418, -0.925],
                        [-2.813, 0],
                        [-2.019, 0.68],
                        [-1.774, 0.981],
                        [0, 1.02],
                        [0.396, 0.434],
                        [0.547, 0],
                        [0.303, -0.152],
                        [1.585, -0.49],
                        [2.228, 0],
                        [2.757, 2.982],
                        [0, 4.532],
                        [-2.53, 2.756],
                        [-1.907, 0.794],
                        [-2.361, 0],
                        [-1.586, -0.49],
                        [-1.51, -0.793],
                        [-0.547, 0],
                        [-0.396, 0.433],
                        [0, 0.548],
                        [0.227, 0.359],
                        [1.077, 0.585],
                        [2.021, 0.681],
                        [2.794, 0],
                      ],
                      o: [
                        [-3.776, 3.7],
                        [0, 6.231],
                        [1.774, 1.775],
                        [2.416, 0.925],
                        [2.812, 0],
                        [2.021, -0.679],
                        [0.832, -0.416],
                        [0, -0.566],
                        [-0.396, -0.435],
                        [-0.547, 0],
                        [-1.51, 0.793],
                        [-1.586, 0.492],
                        [-4.834, 0],
                        [-2.53, -2.757],
                        [0, -4.531],
                        [1.284, -1.398],
                        [1.908, -0.793],
                        [2.36, 0],
                        [1.585, 0.491],
                        [0.303, 0.151],
                        [0.547, 0],
                        [0.396, -0.435],
                        [0, -0.547],
                        [-0.226, -0.359],
                        [-1.076, -0.586],
                        [-2.019, -0.679],
                        [-5.777, 0],
                      ],
                      v: [
                        [13.781, 15.084],
                        [8.117, 29.98],
                        [13.781, 44.877],
                        [20.069, 48.927],
                        [27.913, 50.315],
                        [35.162, 49.295],
                        [40.855, 46.803],
                        [42.101, 44.65],
                        [41.507, 43.15],
                        [40.091, 42.498],
                        [38.816, 42.725],
                        [34.172, 44.65],
                        [28.451, 45.387],
                        [17.066, 40.913],
                        [13.271, 29.98],
                        [17.066, 19.049],
                        [21.852, 15.763],
                        [28.253, 14.574],
                        [34.172, 15.31],
                        [38.816, 17.236],
                        [40.091, 17.462],
                        [41.507, 16.812],
                        [42.101, 15.338],
                        [41.761, 13.979],
                        [39.807, 12.564],
                        [35.162, 10.665],
                        [27.941, 9.647],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 4',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [25.109, 29.981], ix: 2 },
                  a: { a: 0, k: [25.109, 29.981], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 175.2,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Group 12',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.146], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 1.2,
                  s: [0],
                  e: [100],
                },
                { t: 9.6 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.146, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 1.2,
                  s: [136, 36, 0],
                  e: [170, 36, 0],
                  to: [5.667, 0, 0],
                  ti: [-5.667, 0, 0],
                },
                { t: 9.6 },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [143, 36, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [2.644, -3.55],
                        [0, 0],
                        [0.511, 0.511],
                        [0.717, 0],
                        [0.492, -0.509],
                        [0, -0.718],
                        [0, 0],
                        [-0.49, -0.491],
                        [-0.718, 0],
                        [-0.509, 0.491],
                        [0, 0.717],
                        [0, 0],
                        [-1.491, 0.925],
                        [-1.888, 0],
                        [0, 0],
                        [-0.492, 0.434],
                        [0, 0.736],
                        [0.471, 0.491],
                        [0.717, 0],
                      ],
                      o: [
                        [-3.286, 0],
                        [0, 0],
                        [0, -0.718],
                        [-0.509, -0.509],
                        [-0.718, 0],
                        [-0.49, 0.511],
                        [0, 0],
                        [0, 0.717],
                        [0.492, 0.491],
                        [0.717, 0],
                        [0.511, -0.491],
                        [0, 0],
                        [0.981, -1.473],
                        [1.491, -0.926],
                        [0, 0],
                        [0.756, 0],
                        [0.49, -0.435],
                        [0, -0.736],
                        [-0.473, -0.491],
                        [0, 0],
                      ],
                      v: [
                        [62.606, 20.295],
                        [53.713, 25.619],
                        [53.713, 23.184],
                        [52.948, 21.342],
                        [51.108, 20.578],
                        [49.294, 21.342],
                        [48.558, 23.184],
                        [48.558, 47.483],
                        [49.294, 49.295],
                        [51.108, 50.032],
                        [52.948, 49.295],
                        [53.713, 47.483],
                        [53.713, 30.264],
                        [57.423, 26.667],
                        [62.493, 25.279],
                        [62.775, 25.279],
                        [64.645, 24.628],
                        [65.381, 22.872],
                        [64.674, 21.031],
                        [62.889, 20.295],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 13',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [56.969, 35.164], ix: 2 },
                  a: { a: 0, k: [56.969, 35.164], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 12',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 1.2,
          op: 176.4,
          st: 1.2,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Group 1',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.146], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 2.4,
                  s: [0],
                  e: [100],
                },
                { t: 10.8 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.146, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 2.4,
                  s: [136, 36, 0],
                  e: [170, 36, 0],
                  to: [5.667, 0, 0],
                  ti: [-5.667, 0, 0],
                },
                { t: 10.8 },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [143, 36, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.491, -1.133],
                        [0, 0],
                        [0, 0],
                        [1.247, 0],
                        [0.49, -0.509],
                        [0, -0.528],
                        [-0.151, -0.378],
                        [0, 0],
                        [0, 0],
                        [0, -0.49],
                        [-0.472, -0.491],
                        [-0.68, 0],
                        [-0.49, 1.17],
                        [0, 0],
                        [0, 0.547],
                        [0.491, 0.511],
                        [0.717, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [-0.453, -1.058],
                        [-0.604, 0],
                        [-0.491, 0.511],
                        [0, 0.529],
                        [0, 0],
                        [0, 0],
                        [-0.113, 0.301],
                        [0, 0.492],
                        [0.472, 0.49],
                        [1.095, 0],
                        [0, 0],
                        [0.114, -0.265],
                        [0, -0.548],
                        [-0.49, -0.509],
                        [-1.095, 0],
                      ],
                      v: [
                        [-57.055, -6.124],
                        [-65.949, 15.401],
                        [-74.671, -6.236],
                        [-77.221, -7.822],
                        [-78.863, -7.059],
                        [-79.598, -5.501],
                        [-79.372, -4.141],
                        [-68.725, 21.801],
                        [-72.406, 31.26],
                        [-72.575, 32.449],
                        [-71.867, 33.923],
                        [-70.139, 34.659],
                        [-67.761, 32.902],
                        [-52.298, -4.254],
                        [-52.128, -5.471],
                        [-52.864, -7.059],
                        [-54.676, -7.822],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 14',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [146.962, 28.401], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 2.4,
          op: 177.6,
          st: 2.4,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Group 10',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.146], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 3.6,
                  s: [0],
                  e: [100],
                },
                { t: 12 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.146, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 3.6,
                  s: [136, 36, 0],
                  e: [170, 36, 0],
                  to: [5.667, 0, 0],
                  ti: [-5.667, 0, 0],
                },
                { t: 12 },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [143, 36, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.794, -2.869],
                        [0, 0],
                        [0.491, 0.491],
                        [0.736, 0],
                        [0.491, -0.49],
                        [0, -0.717],
                        [0, 0],
                        [-0.491, -0.491],
                        [-0.736, 0],
                        [-0.491, 0.49],
                        [0, 0.756],
                        [0, 0],
                        [-3.644, 0],
                        [-2.643, 2.568],
                        [0, 4.607],
                        [2.871, 2.87],
                        [3.663, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, -0.717],
                        [-0.491, -0.49],
                        [-0.736, 0],
                        [-0.491, 0.491],
                        [0, 0],
                        [0, 0.756],
                        [0.491, 0.49],
                        [0.736, 0],
                        [0.491, -0.491],
                        [0, 0],
                        [2.794, 2.869],
                        [3.644, 0],
                        [2.871, -2.831],
                        [0, -4.532],
                        [-2.605, -2.605],
                        [-3.663, 0],
                      ],
                      v: [
                        [105.823, 24.599],
                        [105.823, 23.127],
                        [105.087, 21.314],
                        [103.246, 20.578],
                        [101.405, 21.314],
                        [100.669, 23.127],
                        [100.669, 60.453],
                        [101.405, 62.323],
                        [103.246, 63.059],
                        [105.087, 62.323],
                        [105.823, 60.453],
                        [105.823, 46.01],
                        [115.48, 50.315],
                        [124.911, 46.463],
                        [129.215, 35.305],
                        [124.911, 24.203],
                        [115.508, 20.295],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 11',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.567, 2.832],
                        [0, 0],
                        [-3.398, 0],
                        [-1.662, -1.699],
                        [0, -3.247],
                        [0.454, -1.284],
                        [4.305, 0],
                      ],
                      o: [
                        [0, 0],
                        [2.567, -2.833],
                        [2.794, 0],
                        [1.736, 1.851],
                        [0, 1.661],
                        [-1.434, 3.852],
                        [-3.398, 0],
                      ],
                      v: [
                        [105.823, 41.252],
                        [105.823, 29.358],
                        [114.772, 25.11],
                        [121.456, 27.658],
                        [124.061, 35.305],
                        [123.381, 39.723],
                        [114.772, 45.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 12',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [114.942, 41.677], ix: 2 },
                  a: { a: 0, k: [114.942, 41.677], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 10',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 3.6,
          op: 178.8,
          st: 3.6,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Group 5',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.146], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 4.8,
                  s: [0],
                  e: [100],
                },
                { t: 13.2 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.146, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 4.8,
                  s: [136, 36, 0],
                  e: [170, 36, 0],
                  to: [5.667, 0, 0],
                  ti: [-5.667, 0, 0],
                },
                { t: 13.2 },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [143, 36, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.339, -0.34],
                        [0, 0],
                        [0, -0.34],
                        [-0.717, 0],
                        [0, 0],
                        [0, 0],
                        [-2.077, -1.209],
                        [-0.944, -0.113],
                        [-1.152, 0],
                        [-1.623, 0.755],
                        [0, 0.756],
                        [0.415, 0.377],
                        [0.509, 0],
                        [0.434, -0.17],
                        [1.019, 0],
                        [0.49, 0.301],
                        [0, 1.436],
                        [0, 0],
                        [0, 0],
                        [-0.415, 0.416],
                        [0, 0.642],
                        [0.416, 0.415],
                        [0.642, 0],
                        [0, 0],
                        [0, 0],
                        [0.208, 0.226],
                        [0.303, 0],
                      ],
                      o: [
                        [0, 0],
                        [-0.34, 0.303],
                        [0, 0.718],
                        [0, 0],
                        [0, 0],
                        [0, 3.021],
                        [0.869, 0.528],
                        [0.944, 0.113],
                        [1.151, 0],
                        [0.755, -0.34],
                        [0, -0.755],
                        [-0.416, -0.378],
                        [-0.51, 0],
                        [-0.435, 0.17],
                        [-0.982, 0],
                        [-0.869, -0.529],
                        [0, 0],
                        [0, 0],
                        [0.642, 0],
                        [0.416, -0.416],
                        [0, -0.642],
                        [-0.415, -0.415],
                        [0, 0],
                        [0, 0],
                        [0, -0.302],
                        [-0.208, -0.227],
                        [-0.301, 0],
                      ],
                      v: [
                        [141.167, 15.707],
                        [133.464, 23.24],
                        [132.954, 24.203],
                        [134.03, 25.279],
                        [138.051, 25.279],
                        [138.051, 42.838],
                        [141.167, 49.182],
                        [143.886, 50.145],
                        [147.03, 50.315],
                        [151.192, 49.182],
                        [152.325, 47.539],
                        [151.703, 45.84],
                        [150.315, 45.274],
                        [148.899, 45.529],
                        [146.718, 45.784],
                        [144.509, 45.331],
                        [143.207, 42.384],
                        [143.207, 25.279],
                        [149.606, 25.279],
                        [151.192, 24.656],
                        [151.816, 23.07],
                        [151.192, 21.484],
                        [149.606, 20.862],
                        [143.207, 20.862],
                        [143.207, 16.33],
                        [142.894, 15.537],
                        [142.129, 15.197],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 6',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [142.639, 32.756], ix: 2 },
                  a: { a: 0, k: [142.639, 32.756], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 4.8,
          op: 180,
          st: 4.8,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'Group 8',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.146], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 6,
                  s: [0],
                  e: [100],
                },
                { t: 14.4 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.146, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 6,
                  s: [136, 36, 0],
                  e: [170, 36, 0],
                  to: [5.667, 0, 0],
                  ti: [-5.667, 0, 0],
                },
                { t: 14.4 },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [143, 36, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.605, -2.756],
                        [0, -4.494],
                        [-2.643, -2.757],
                        [-4.418, 0],
                        [-2.643, 2.756],
                        [0, 4.456],
                        [2.644, 2.795],
                        [4.381, 0],
                      ],
                      o: [
                        [-2.643, 2.758],
                        [0, 4.494],
                        [2.605, 2.756],
                        [4.381, 0],
                        [2.644, -2.794],
                        [0, -4.456],
                        [-2.643, -2.756],
                        [-4.418, 0],
                      ],
                      v: [
                        [160.142, 24.429],
                        [156.177, 35.305],
                        [160.142, 46.18],
                        [170.677, 50.315],
                        [181.212, 46.18],
                        [185.177, 35.305],
                        [181.212, 24.429],
                        [170.677, 20.295],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 9',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.114, 0.377],
                        [0.906, 0.793],
                        [0.566, 1.321],
                        [0, 1.869],
                        [-0.567, 1.322],
                        [-4.079, 0],
                        [-1.699, -1.548],
                        [0, -3.398],
                        [2.039, -1.85],
                        [1.116, -0.378],
                        [1.077, 0],
                      ],
                      o: [
                        [-1.114, -0.378],
                        [-0.868, -0.83],
                        [-0.567, -1.322],
                        [0, -1.869],
                        [1.586, -3.662],
                        [2.492, 0],
                        [2.039, 1.888],
                        [0, 3.512],
                        [-0.906, 0.793],
                        [-1.113, 0.377],
                        [-1.075, 0],
                      ],
                      v: [
                        [167.364, 45.047],
                        [164.333, 43.291],
                        [162.181, 40.063],
                        [161.332, 35.276],
                        [162.181, 30.49],
                        [170.677, 24.996],
                        [176.964, 27.318],
                        [180.023, 35.248],
                        [176.964, 43.291],
                        [173.933, 45.047],
                        [170.648, 45.613],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 10',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [170.677, 35.305], ix: 2 },
                  a: { a: 0, k: [170.677, 35.305], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 8',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 6,
          op: 181.2,
          st: 6,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'Group 4',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.146], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 7.2,
                  s: [0],
                  e: [100],
                },
                { t: 15.6 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.146, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 7.2,
                  s: [136, 36, 0],
                  e: [170, 36, 0],
                  to: [5.667, 0, 0],
                  ti: [-5.667, 0, 0],
                },
                { t: 15.6 },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [143, 36, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.452, -0.472],
                        [0, -0.679],
                        [-0.454, -0.452],
                        [-0.681, 0],
                        [0, 0],
                        [0, 0],
                        [-0.491, -0.491],
                        [-0.718, 0],
                        [-0.509, 0.491],
                        [0, 0.717],
                        [0, 0],
                        [0, 0],
                        [-0.473, 0.454],
                        [0, 0.68],
                        [0.471, 0.472],
                        [0.68, 0],
                      ],
                      o: [
                        [-0.681, 0],
                        [-0.454, 0.472],
                        [0, 0.68],
                        [0.452, 0.454],
                        [0, 0],
                        [0, 0],
                        [0, 0.717],
                        [0.491, 0.491],
                        [0.717, 0],
                        [0.511, -0.491],
                        [0, 0],
                        [0, 0],
                        [0.68, 0],
                        [0.471, -0.452],
                        [0, -0.679],
                        [-0.473, -0.472],
                        [0, 0],
                      ],
                      v: [
                        [186.197, 10.213],
                        [184.498, 10.921],
                        [183.817, 12.648],
                        [184.498, 14.347],
                        [186.197, 15.027],
                        [197.752, 15.027],
                        [197.752, 47.483],
                        [198.488, 49.295],
                        [200.301, 50.032],
                        [202.141, 49.295],
                        [202.906, 47.483],
                        [202.906, 15.027],
                        [214.404, 15.027],
                        [216.132, 14.347],
                        [216.84, 12.648],
                        [216.132, 10.921],
                        [214.404, 10.213],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 5',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [200.329, 30.123], ix: 2 },
                  a: { a: 0, k: [200.329, 30.123], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 7.2,
          op: 182.4,
          st: 7.2,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: 'Group 6',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.146], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 8.4,
                  s: [0],
                  e: [100],
                },
                { t: 16.8 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.146, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 8.4,
                  s: [136, 36, 0],
                  e: [170, 36, 0],
                  to: [5.667, 0, 0],
                  ti: [-5.667, 0, 0],
                },
                { t: 16.8 },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [143, 36, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.172, -1.36],
                        [0, -0.83],
                        [-0.415, -0.397],
                        [-0.377, 0],
                        [-0.302, 0.076],
                        [-2.189, 0],
                        [-1.246, -1.076],
                        [0, -2.53],
                        [0, 0],
                        [0, 0],
                        [2.303, -1.737],
                        [0, -2.794],
                        [-1.813, -1.586],
                        [-2.605, 0],
                        [-1.717, 0.906],
                        [-1.02, 1.057],
                        [0, 0],
                        [-0.491, -0.491],
                        [-0.718, 0],
                        [-0.509, 0.491],
                        [0, 0.717],
                        [0, 0],
                        [1.887, 2.04],
                        [3.852, 0],
                      ],
                      o: [
                        [-0.982, 0.416],
                        [0, 0.831],
                        [0.416, 0.397],
                        [0.378, 0],
                        [2.832, -0.906],
                        [2.568, 0],
                        [1.246, 1.076],
                        [0, 0],
                        [0, 0],
                        [-4.267, 0],
                        [-2.115, 1.586],
                        [0, 2.568],
                        [1.737, 1.51],
                        [2.644, 0],
                        [1.719, -0.906],
                        [0, 0],
                        [0, 0.717],
                        [0.491, 0.491],
                        [0.717, 0],
                        [0.51, -0.491],
                        [0, 0],
                        [0, -3.058],
                        [-2.115, -2.341],
                        [-3.398, 0],
                      ],
                      v: [
                        [218.709, 22.334],
                        [217.236, 24.203],
                        [217.859, 26.044],
                        [219.049, 26.639],
                        [220.068, 26.525],
                        [227.601, 25.166],
                        [233.322, 26.78],
                        [235.191, 32.189],
                        [235.191, 32.643],
                        [228.338, 32.643],
                        [218.483, 35.248],
                        [215.31, 41.818],
                        [218.029, 48.049],
                        [224.543, 50.315],
                        [231.084, 48.955],
                        [235.191, 46.01],
                        [235.191, 47.483],
                        [235.928, 49.295],
                        [237.741, 50.032],
                        [239.581, 49.295],
                        [240.346, 47.483],
                        [240.346, 31.453],
                        [237.514, 23.806],
                        [228.564, 20.295],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 7',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 3.02],
                        [-1.511, 0.68],
                        [-2.68, 0],
                        [0, 0],
                        [0, 0],
                        [1.529, -0.869],
                        [1.775, 0],
                      ],
                      o: [
                        [0, -1.624],
                        [1.51, -0.68],
                        [0, 0],
                        [0, 0],
                        [-0.981, 1.094],
                        [-1.53, 0.868],
                        [-4.002, 0],
                      ],
                      v: [
                        [220.464, 41.252],
                        [222.731, 37.797],
                        [229.017, 36.777],
                        [235.191, 36.777],
                        [235.191, 41.536],
                        [231.425, 44.481],
                        [226.468, 45.784],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 8',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [227.828, 35.305], ix: 2 },
                  a: { a: 0, k: [227.828, 35.305], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 8.4,
          op: 183.6,
          st: 8.4,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: 'Group 2',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.146], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 9.6,
                  s: [0],
                  e: [100],
                },
                { t: 18 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.146, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 9.6,
                  s: [136, 36, 0],
                  e: [170, 36, 0],
                  to: [5.667, 0, 0],
                  ti: [-5.667, 0, 0],
                },
                { t: 18 },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [143, 36, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.492, -0.492],
                        [0, -0.755],
                        [0, 0],
                        [-0.49, -0.491],
                        [-0.736, 0],
                        [-0.49, 0.491],
                        [0, 0.717],
                        [0, 0],
                        [-3.644, 0],
                        [-2.643, 2.568],
                        [0, 4.607],
                        [2.87, 2.87],
                        [3.662, 0],
                        [2.795, -2.869],
                        [0, 0],
                        [0.492, 0.49],
                        [0.737, 0],
                      ],
                      o: [
                        [-0.49, 0.49],
                        [0, 0],
                        [0, 0.717],
                        [0.492, 0.491],
                        [0.737, 0],
                        [0.492, -0.491],
                        [0, 0],
                        [2.795, 2.869],
                        [3.644, 0],
                        [2.87, -2.831],
                        [0, -4.532],
                        [-2.605, -2.605],
                        [-3.663, 0],
                        [0, 0],
                        [0, -0.755],
                        [-0.49, -0.492],
                        [-0.736, 0],
                      ],
                      v: [
                        [249.804, 8.854],
                        [249.068, 10.722],
                        [249.068, 47.483],
                        [249.804, 49.295],
                        [251.645, 50.032],
                        [253.486, 49.295],
                        [254.222, 47.483],
                        [254.222, 46.01],
                        [263.88, 50.315],
                        [273.311, 46.463],
                        [277.616, 35.305],
                        [273.311, 24.203],
                        [263.909, 20.295],
                        [254.222, 24.599],
                        [254.222, 10.722],
                        [253.486, 8.854],
                        [251.645, 8.117],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.569, 2.832],
                        [0, 0],
                        [-3.398, 0],
                        [-1.662, -1.699],
                        [0, -3.247],
                        [0.453, -1.284],
                        [4.305, 0],
                      ],
                      o: [
                        [0, 0],
                        [2.569, -2.833],
                        [2.794, 0],
                        [1.736, 1.851],
                        [0, 1.661],
                        [-1.435, 3.852],
                        [-3.398, 0],
                      ],
                      v: [
                        [254.222, 41.252],
                        [254.222, 29.358],
                        [263.173, 25.11],
                        [269.856, 27.658],
                        [272.462, 35.305],
                        [271.782, 39.723],
                        [263.173, 45.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [263.342, 29.216], ix: 2 },
                  a: { a: 0, k: [263.342, 29.216], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 9.6,
          op: 184.8,
          st: 9.6,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: 'cryptotab/texts Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [170, 36, 0], ix: 2, l: 2 },
            a: { a: 0, k: [143, 36, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [],
          ip: 0,
          op: 175.2,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 9,
      ty: 0,
      nm: 'affiliate',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [273, 381.5, 0], ix: 2, l: 2 },
        a: { a: 0, k: [393.5, 1000, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 787,
      h: 2000,
      ip: 0,
      op: 150,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
export const logo_dark = {
  v: '5.7.11',
  fr: 30,
  ip: 0,
  op: 150,
  w: 576,
  h: 121,
  nm: 'texts',
  ddd: 0,
  assets: [
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 0,
          nm: 'crypto_affiliate_new',
          refId: 'comp_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [180.5, 680, 0], ix: 2, l: 2 },
            a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
            s: { a: 0, k: [202, 202, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 100,
          h: 100,
          ip: 0,
          op: 1200,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 0,
          nm: 'cryptotab 2',
          refId: 'comp_2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [388.967, 686.969, 0], ix: 2, l: 2 },
            a: { a: 0, k: [214.799, 35.588, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 430,
          h: 72,
          ip: 8.4,
          op: 183.6,
          st: 8.4,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'affilate Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.48], y: [1] },
                  o: { x: [0.298], y: [0] },
                  t: 20.4,
                  s: [-59],
                  e: [8],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.406], y: [0] },
                  t: 28.8,
                  s: [8],
                  e: [-4],
                },
                {
                  i: { x: [0.385], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 33.6,
                  s: [-4],
                  e: [0],
                },
                { t: 37.2 },
              ],
              ix: 10,
            },
            p: { a: 0, k: [540.967, 671.638, 0], ix: 2, l: 2 },
            a: { a: 0, k: [8.117, 8.117, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.48, 0.48, 0.667], y: [1, 1, 1] },
                  o: { x: [0.298, 0.298, 0.333], y: [0, 0, 0] },
                  t: 20.4,
                  s: [0, 0, 100],
                  e: [100, 100, 100],
                },
                { t: 28.8 },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.34, -0.775],
                        [0, 0],
                        [0, -0.254],
                        [-0.227, -0.246],
                        [-0.491, 0],
                        [-0.227, 0.529],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.49, 0],
                        [-0.227, 0.245],
                        [0, 0.255],
                        [0.095, 0.226],
                        [0, 0],
                        [0.756, 0],
                      ],
                      o: [
                        [-0.755, 0],
                        [0, 0],
                        [-0.094, 0.226],
                        [0, 0.255],
                        [0.226, 0.245],
                        [0.491, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.226, 0.529],
                        [0.491, 0],
                        [0.227, -0.246],
                        [0, -0.254],
                        [0, 0],
                        [-0.339, -0.775],
                        [0, 0],
                      ],
                      v: [
                        [243.655, -8.144],
                        [242.012, -6.982],
                        [234.79, 10.067],
                        [234.65, 10.789],
                        [234.989, 11.54],
                        [236.066, 11.908],
                        [237.142, 11.115],
                        [238.983, 6.697],
                        [248.611, 6.697],
                        [250.452, 11.115],
                        [251.528, 11.908],
                        [252.605, 11.54],
                        [252.944, 10.789],
                        [252.803, 10.067],
                        [245.58, -6.982],
                        [243.939, -8.144],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [243.798, -4.857],
                        [247.621, 4.346],
                        [239.973, 4.346],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.245, -0.254],
                        [0, -0.359],
                        [0, 0],
                        [-0.245, -0.246],
                        [-0.358, 0],
                        [-0.256, 0.245],
                        [0, 0.359],
                        [0, 0],
                        [0.255, 0.255],
                        [0.358, 0],
                      ],
                      o: [
                        [-0.245, 0.255],
                        [0, 0],
                        [0, 0.359],
                        [0.245, 0.245],
                        [0.358, 0],
                        [0.255, -0.246],
                        [0, 0],
                        [0, -0.359],
                        [-0.256, -0.254],
                        [-0.358, 0],
                      ],
                      v: [
                        [228.957, -7.762],
                        [228.588, -6.84],
                        [228.588, 10.633],
                        [228.957, 11.54],
                        [229.863, 11.908],
                        [230.785, 11.54],
                        [231.166, 10.633],
                        [231.166, -6.84],
                        [230.785, -7.762],
                        [229.863, -8.144],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 4',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 3,
                  ty: 'sh',
                  ix: 4,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.246, -0.254],
                        [0, -0.359],
                        [0, 0],
                        [-0.349, -0.349],
                        [-0.492, 0],
                        [0, 0],
                        [-0.254, 0.245],
                        [0, 0.358],
                        [0.256, 0.254],
                        [0.359, 0],
                        [0, 0],
                        [0, 0],
                        [0.255, 0.255],
                        [0.359, 0],
                      ],
                      o: [
                        [-0.245, 0.255],
                        [0, 0],
                        [0, 0.491],
                        [0.349, 0.348],
                        [0, 0],
                        [0.359, 0],
                        [0.256, -0.246],
                        [0, -0.359],
                        [-0.254, -0.255],
                        [0, 0],
                        [0, 0],
                        [0, -0.359],
                        [-0.255, -0.254],
                        [-0.358, 0],
                      ],
                      v: [
                        [212.645, -7.762],
                        [212.277, -6.84],
                        [212.277, 9.982],
                        [212.801, 11.243],
                        [214.061, 11.767],
                        [224.114, 11.767],
                        [225.033, 11.398],
                        [225.418, 10.492],
                        [225.033, 9.572],
                        [224.114, 9.189],
                        [214.855, 9.189],
                        [214.855, -6.84],
                        [214.47, -7.762],
                        [213.551, -8.144],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 5',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 4,
                  ty: 'sh',
                  ix: 5,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.245, -0.254],
                        [0, -0.359],
                        [0, 0],
                        [-0.246, -0.246],
                        [-0.359, 0],
                        [-0.255, 0.245],
                        [0, 0.359],
                        [0, 0],
                        [0.255, 0.255],
                        [0.358, 0],
                      ],
                      o: [
                        [-0.246, 0.255],
                        [0, 0],
                        [0, 0.359],
                        [0.245, 0.245],
                        [0.358, 0],
                        [0.255, -0.246],
                        [0, 0],
                        [0, -0.359],
                        [-0.255, -0.254],
                        [-0.359, 0],
                      ],
                      v: [
                        [204.574, -7.762],
                        [204.205, -6.84],
                        [204.205, 10.633],
                        [204.574, 11.54],
                        [205.48, 11.908],
                        [206.4, 11.54],
                        [206.781, 10.633],
                        [206.781, -6.84],
                        [206.4, -7.762],
                        [205.48, -8.144],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 6',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 5,
                  ty: 'sh',
                  ix: 6,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.341, -0.775],
                        [0, 0],
                        [0, -0.254],
                        [-0.227, -0.246],
                        [-0.491, 0],
                        [-0.227, 0.529],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.49, 0],
                        [-0.226, 0.245],
                        [0, 0.255],
                        [0.095, 0.226],
                        [0, 0],
                        [0.755, 0],
                      ],
                      o: [
                        [-0.755, 0],
                        [0, 0],
                        [-0.095, 0.226],
                        [0, 0.255],
                        [0.227, 0.245],
                        [0.492, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.226, 0.529],
                        [0.491, 0],
                        [0.226, -0.246],
                        [0, -0.254],
                        [0, 0],
                        [-0.34, -0.775],
                        [0, 0],
                      ],
                      v: [
                        [156.91, -8.144],
                        [155.267, -6.982],
                        [148.046, 10.067],
                        [147.903, 10.789],
                        [148.243, 11.54],
                        [149.319, 11.908],
                        [150.397, 11.115],
                        [152.236, 6.697],
                        [161.866, 6.697],
                        [163.707, 11.115],
                        [164.783, 11.908],
                        [165.858, 11.54],
                        [166.199, 10.789],
                        [166.056, 10.067],
                        [158.835, -6.982],
                        [157.192, -8.144],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 7',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 6,
                  ty: 'sh',
                  ix: 7,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [157.051, -4.857],
                        [160.874, 4.346],
                        [153.228, 4.346],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 8',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 7,
                  ty: 'sh',
                  ix: 8,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.245, -0.256],
                        [0, -0.359],
                        [0, 0],
                        [-0.246, -0.246],
                        [-0.358, 0],
                        [0, 0],
                        [-0.236, 0.227],
                        [0, 0.34],
                        [0.236, 0.236],
                        [0.34, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.237, 0.226],
                        [0, 0.339],
                        [0.235, 0.236],
                        [0.34, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.236, 0.227],
                        [0, 0.339],
                        [0.236, 0.236],
                        [0.34, 0],
                      ],
                      o: [
                        [-0.358, 0],
                        [-0.246, 0.254],
                        [0, 0],
                        [0, 0.358],
                        [0.245, 0.245],
                        [0, 0],
                        [0.34, 0],
                        [0.236, -0.226],
                        [0, -0.34],
                        [-0.236, -0.236],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.34, 0],
                        [0.235, -0.226],
                        [0, -0.34],
                        [-0.237, -0.237],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.34, 0],
                        [0.236, -0.227],
                        [0, -0.341],
                        [-0.236, -0.237],
                        [0, 0],
                      ],
                      v: [
                        [274.978, -8.001],
                        [274.072, -7.618],
                        [273.704, -6.7],
                        [273.704, 10.492],
                        [274.072, 11.398],
                        [274.978, 11.767],
                        [286.335, 11.767],
                        [287.199, 11.426],
                        [287.553, 10.577],
                        [287.199, 9.713],
                        [286.335, 9.359],
                        [276.281, 9.359],
                        [276.281, 3.072],
                        [285.316, 3.072],
                        [286.179, 2.732],
                        [286.533, 1.883],
                        [286.179, 1.019],
                        [285.316, 0.665],
                        [276.281, 0.665],
                        [276.281, -5.594],
                        [286.335, -5.594],
                        [287.199, -5.935],
                        [287.553, -6.784],
                        [287.199, -7.648],
                        [286.335, -8.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 9',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 8,
                  ty: 'sh',
                  ix: 9,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.226, -0.237],
                        [0, -0.341],
                        [-0.227, -0.227],
                        [-0.34, 0],
                        [0, 0],
                        [0, 0],
                        [-0.245, -0.246],
                        [-0.358, 0],
                        [-0.255, 0.245],
                        [0, 0.359],
                        [0, 0],
                        [0, 0],
                        [-0.235, 0.227],
                        [0, 0.339],
                        [0.237, 0.236],
                        [0.341, 0],
                      ],
                      o: [
                        [-0.34, 0],
                        [-0.227, 0.236],
                        [0, 0.339],
                        [0.226, 0.227],
                        [0, 0],
                        [0, 0],
                        [0, 0.359],
                        [0.245, 0.245],
                        [0.359, 0],
                        [0.254, -0.246],
                        [0, 0],
                        [0, 0],
                        [0.341, 0],
                        [0.237, -0.227],
                        [0, -0.341],
                        [-0.235, -0.237],
                        [0, 0],
                      ],
                      v: [
                        [255.238, -8.001],
                        [254.389, -7.648],
                        [254.049, -6.784],
                        [254.389, -5.935],
                        [255.238, -5.594],
                        [261.015, -5.594],
                        [261.015, 10.633],
                        [261.384, 11.54],
                        [262.29, 11.908],
                        [263.212, 11.54],
                        [263.592, 10.633],
                        [263.592, -5.594],
                        [269.342, -5.594],
                        [270.205, -5.935],
                        [270.559, -6.784],
                        [270.205, -7.648],
                        [269.342, -8.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 10',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 9,
                  ty: 'sh',
                  ix: 10,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.246, -0.256],
                        [0, -0.359],
                        [0, 0],
                        [-0.245, -0.246],
                        [-0.359, 0],
                        [-0.254, 0.245],
                        [0, 0.359],
                        [0, 0],
                        [0, 0],
                        [-0.236, 0.226],
                        [0, 0.339],
                        [0.236, 0.236],
                        [0.34, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.237, 0.227],
                        [0, 0.339],
                        [0.236, 0.236],
                        [0.339, 0],
                      ],
                      o: [
                        [-0.359, 0],
                        [-0.245, 0.254],
                        [0, 0],
                        [0, 0.359],
                        [0.246, 0.245],
                        [0.358, 0],
                        [0.255, -0.246],
                        [0, 0],
                        [0, 0],
                        [0.34, 0],
                        [0.236, -0.226],
                        [0, -0.34],
                        [-0.236, -0.237],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.339, 0],
                        [0.236, -0.227],
                        [0, -0.341],
                        [-0.237, -0.237],
                        [0, 0],
                      ],
                      v: [
                        [188.204, -8.001],
                        [187.298, -7.618],
                        [186.929, -6.7],
                        [186.929, 10.633],
                        [187.298, 11.54],
                        [188.204, 11.908],
                        [189.124, 11.54],
                        [189.507, 10.633],
                        [189.507, 3.072],
                        [198.23, 3.072],
                        [199.093, 2.732],
                        [199.447, 1.883],
                        [199.093, 1.019],
                        [198.23, 0.665],
                        [189.507, 0.665],
                        [189.507, -5.594],
                        [199.221, -5.594],
                        [200.085, -5.935],
                        [200.439, -6.784],
                        [200.085, -7.648],
                        [199.221, -8.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 11',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 10,
                  ty: 'sh',
                  ix: 11,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.247, -0.256],
                        [0, -0.359],
                        [0, 0],
                        [-0.245, -0.246],
                        [-0.358, 0],
                        [-0.255, 0.245],
                        [0, 0.359],
                        [0, 0],
                        [0, 0],
                        [-0.236, 0.226],
                        [0, 0.339],
                        [0.236, 0.236],
                        [0.341, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.236, 0.227],
                        [0, 0.339],
                        [0.237, 0.236],
                        [0.339, 0],
                      ],
                      o: [
                        [-0.358, 0],
                        [-0.245, 0.254],
                        [0, 0],
                        [0, 0.359],
                        [0.247, 0.245],
                        [0.359, 0],
                        [0.256, -0.246],
                        [0, 0],
                        [0, 0],
                        [0.341, 0],
                        [0.236, -0.226],
                        [0, -0.34],
                        [-0.236, -0.237],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.339, 0],
                        [0.237, -0.227],
                        [0, -0.341],
                        [-0.236, -0.237],
                        [0, 0],
                      ],
                      v: [
                        [170.928, -8.001],
                        [170.022, -7.618],
                        [169.653, -6.7],
                        [169.653, 10.633],
                        [170.022, 11.54],
                        [170.928, 11.908],
                        [171.848, 11.54],
                        [172.23, 10.633],
                        [172.23, 3.072],
                        [180.952, 3.072],
                        [181.817, 2.732],
                        [182.171, 1.883],
                        [181.817, 1.019],
                        [180.952, 0.665],
                        [172.23, 0.665],
                        [172.23, -5.594],
                        [181.944, -5.594],
                        [182.809, -5.935],
                        [183.163, -6.784],
                        [182.809, -7.648],
                        [181.944, -8.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 12',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-139.786, 16.261], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 12,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 3.6,
          op: 178.8,
          st: 3.6,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_1',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Shape Layer 2',
          parent: 5,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: -90, ix: 10 },
            p: { a: 0, k: [114.655, 91.538, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-2.625, 0.5, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.367, 0.367, 0.667], y: [1, 1, 1] },
                  o: { x: [0, 0, 0.333], y: [0, 0, 0] },
                  t: 22.8,
                  s: [0, 0, 100],
                  e: [100, 100, 100],
                },
                { t: 26.4 },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [2.375, 2.375], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.207843002619, 0.207843002619, 0.207843002619, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-2.625, 0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 1200,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Shape Layer 1',
          parent: 5,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: -90, ix: 10 },
            p: { a: 0, k: [114.655, 95.444, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-2.625, 0.5, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.367, 0.367, 0.667], y: [1, 1, 1] },
                  o: { x: [0, 0, 0.333], y: [0, 0, 0] },
                  t: 20.4,
                  s: [0, 0, 100],
                  e: [100, 100, 100],
                },
                { t: 24 },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [2.375, 2.375], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.207843002619, 0.207843002619, 0.207843002619, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-2.625, 0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 1200,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Group 1',
          parent: 5,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [113.909, 93.441, 0], ix: 2, l: 2 },
            a: { a: 0, k: [113.909, 93.441, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, -7.221],
                        [-3.331, 1.942],
                      ],
                      o: [
                        [-3.666, -1.909],
                        [0, 7.221],
                        [0, 0],
                      ],
                      v: [
                        [5.105, -5.312],
                        [-5.105, 0],
                        [5.105, 5.246],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2.8, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [113.821, 93.531], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 100, ix: 1 },
              e: {
                a: 1,
                k: [
                  {
                    i: { x: [0.083], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 12,
                    s: [100],
                    e: [0],
                  },
                  { t: 20.4 },
                ],
                ix: 2,
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
          ],
          ip: 0,
          op: 1200,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Group 2',
          parent: 5,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [113.909, 93.441, 0], ix: 2, l: 2 },
            a: { a: 0, k: [113.909, 93.441, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [7.004, -6.485],
                        [0, 0],
                        [-13.846, 7.233],
                      ],
                      o: [
                        [0, 0],
                        [-7.003, 6.484],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [11.907, -11.307],
                        [-4.904, -10.392],
                        [-7.402, 5.736],
                        [11.907, 9.643],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 3.5, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [111.121, 94.288], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 100, ix: 1 },
              e: {
                a: 1,
                k: [
                  {
                    i: { x: [0.083], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 7.2,
                    s: [100],
                    e: [0],
                  },
                  { t: 15.6 },
                ],
                ix: 2,
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
          ],
          ip: 0,
          op: 1200,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Group 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.289], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 0,
                  s: [-90],
                  e: [90],
                },
                { t: 15.6 },
              ],
              ix: 10,
            },
            p: { a: 0, k: [49.909, 49.941, 0], ix: 2, l: 2 },
            a: { a: 0, k: [113.909, 93.441, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 0,
                  s: [0, 0, 100],
                  e: [100, 100, 100],
                },
                { t: 8.4 },
              ],
              ix: 6,
              l: 2,
              x: 'var $bm_rt;\nvar amp, freq, decay, n, n, t, t, v;\namp = 0.06;\nfreq = 2;\ndecay = 2;\n$bm_rt = n = 0;\nif (numKeys > 0) {\n    $bm_rt = n = nearestKey(time).index;\n    if (key(n).time > time) {\n        n--;\n    }\n}\nif (n == 0) {\n    $bm_rt = t = 0;\n} else {\n    $bm_rt = t = $bm_sub(time, key(n).time);\n}\nif (n > 0 && t < 1) {\n    v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10)));\n    $bm_rt = $bm_sum(value, $bm_div($bm_mul($bm_mul(v, amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))));\n} else {\n    $bm_rt = value;\n}',
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -12.427],
                        [12.427, 0],
                        [0, 12.426],
                        [-12.426, 0],
                      ],
                      o: [
                        [0, 12.426],
                        [-12.426, 0],
                        [0, -12.427],
                        [12.427, 0],
                      ],
                      v: [
                        [22.5, 0],
                        [0, 22.5],
                        [-22.5, 0],
                        [0, -22.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.969000004787, 0.57599995931, 0.102000000898, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [113.909, 93.441], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 1200,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_2',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Group 3',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.146], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 0,
                  s: [0],
                  e: [100],
                },
                { t: 8.4 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.146, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 0,
                  s: [181, 36, 0],
                  e: [215, 36, 0],
                  to: [5.667, 0, 0],
                  ti: [-5.667, 0, 0],
                },
                { t: 8.4 },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [143, 36, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.662, -3.626],
                        [0, -6.23],
                        [-3.776, -3.701],
                        [-2.418, -0.925],
                        [-2.813, 0],
                        [-2.019, 0.68],
                        [-1.774, 0.981],
                        [0, 1.02],
                        [0.396, 0.434],
                        [0.547, 0],
                        [0.303, -0.152],
                        [1.585, -0.49],
                        [2.228, 0],
                        [2.757, 2.982],
                        [0, 4.532],
                        [-2.53, 2.756],
                        [-1.907, 0.794],
                        [-2.361, 0],
                        [-1.586, -0.49],
                        [-1.51, -0.793],
                        [-0.547, 0],
                        [-0.396, 0.433],
                        [0, 0.548],
                        [0.227, 0.359],
                        [1.077, 0.585],
                        [2.021, 0.681],
                        [2.794, 0],
                      ],
                      o: [
                        [-3.776, 3.7],
                        [0, 6.231],
                        [1.774, 1.775],
                        [2.416, 0.925],
                        [2.812, 0],
                        [2.021, -0.679],
                        [0.832, -0.416],
                        [0, -0.566],
                        [-0.396, -0.435],
                        [-0.547, 0],
                        [-1.51, 0.793],
                        [-1.586, 0.492],
                        [-4.834, 0],
                        [-2.53, -2.757],
                        [0, -4.531],
                        [1.284, -1.398],
                        [1.908, -0.793],
                        [2.36, 0],
                        [1.585, 0.491],
                        [0.303, 0.151],
                        [0.547, 0],
                        [0.396, -0.435],
                        [0, -0.547],
                        [-0.226, -0.359],
                        [-1.076, -0.586],
                        [-2.019, -0.679],
                        [-5.777, 0],
                      ],
                      v: [
                        [13.781, 15.084],
                        [8.117, 29.98],
                        [13.781, 44.877],
                        [20.069, 48.927],
                        [27.913, 50.315],
                        [35.162, 49.295],
                        [40.855, 46.803],
                        [42.101, 44.65],
                        [41.507, 43.15],
                        [40.091, 42.498],
                        [38.816, 42.725],
                        [34.172, 44.65],
                        [28.451, 45.387],
                        [17.066, 40.913],
                        [13.271, 29.98],
                        [17.066, 19.049],
                        [21.852, 15.763],
                        [28.253, 14.574],
                        [34.172, 15.31],
                        [38.816, 17.236],
                        [40.091, 17.462],
                        [41.507, 16.812],
                        [42.101, 15.338],
                        [41.761, 13.979],
                        [39.807, 12.564],
                        [35.162, 10.665],
                        [27.941, 9.647],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 4',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [25.109, 29.981], ix: 2 },
                  a: { a: 0, k: [25.109, 29.981], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 175.2,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Group 12',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.146], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 1.2,
                  s: [0],
                  e: [100],
                },
                { t: 9.6 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.146, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 1.2,
                  s: [181, 36, 0],
                  e: [215, 36, 0],
                  to: [5.667, 0, 0],
                  ti: [-5.667, 0, 0],
                },
                { t: 9.6 },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [143, 36, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [2.644, -3.55],
                        [0, 0],
                        [0.511, 0.511],
                        [0.717, 0],
                        [0.492, -0.509],
                        [0, -0.718],
                        [0, 0],
                        [-0.49, -0.491],
                        [-0.718, 0],
                        [-0.509, 0.491],
                        [0, 0.717],
                        [0, 0],
                        [-1.491, 0.925],
                        [-1.888, 0],
                        [0, 0],
                        [-0.492, 0.434],
                        [0, 0.736],
                        [0.471, 0.491],
                        [0.717, 0],
                      ],
                      o: [
                        [-3.286, 0],
                        [0, 0],
                        [0, -0.718],
                        [-0.509, -0.509],
                        [-0.718, 0],
                        [-0.49, 0.511],
                        [0, 0],
                        [0, 0.717],
                        [0.492, 0.491],
                        [0.717, 0],
                        [0.511, -0.491],
                        [0, 0],
                        [0.981, -1.473],
                        [1.491, -0.926],
                        [0, 0],
                        [0.756, 0],
                        [0.49, -0.435],
                        [0, -0.736],
                        [-0.473, -0.491],
                        [0, 0],
                      ],
                      v: [
                        [62.606, 20.295],
                        [53.713, 25.619],
                        [53.713, 23.184],
                        [52.948, 21.342],
                        [51.108, 20.578],
                        [49.294, 21.342],
                        [48.558, 23.184],
                        [48.558, 47.483],
                        [49.294, 49.295],
                        [51.108, 50.032],
                        [52.948, 49.295],
                        [53.713, 47.483],
                        [53.713, 30.264],
                        [57.423, 26.667],
                        [62.493, 25.279],
                        [62.775, 25.279],
                        [64.645, 24.628],
                        [65.381, 22.872],
                        [64.674, 21.031],
                        [62.889, 20.295],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 13',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [56.969, 35.164], ix: 2 },
                  a: { a: 0, k: [56.969, 35.164], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 12',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 1.2,
          op: 176.4,
          st: 1.2,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Group 1',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.146], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 2.4,
                  s: [0],
                  e: [100],
                },
                { t: 10.8 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.146, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 2.4,
                  s: [181, 36, 0],
                  e: [215, 36, 0],
                  to: [5.667, 0, 0],
                  ti: [-5.667, 0, 0],
                },
                { t: 10.8 },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [143, 36, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.491, -1.133],
                        [0, 0],
                        [0, 0],
                        [1.247, 0],
                        [0.49, -0.509],
                        [0, -0.528],
                        [-0.151, -0.378],
                        [0, 0],
                        [0, 0],
                        [0, -0.49],
                        [-0.472, -0.491],
                        [-0.68, 0],
                        [-0.49, 1.17],
                        [0, 0],
                        [0, 0.547],
                        [0.491, 0.511],
                        [0.717, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [-0.453, -1.058],
                        [-0.604, 0],
                        [-0.491, 0.511],
                        [0, 0.529],
                        [0, 0],
                        [0, 0],
                        [-0.113, 0.301],
                        [0, 0.492],
                        [0.472, 0.49],
                        [1.095, 0],
                        [0, 0],
                        [0.114, -0.265],
                        [0, -0.548],
                        [-0.49, -0.509],
                        [-1.095, 0],
                      ],
                      v: [
                        [-57.055, -6.124],
                        [-65.949, 15.401],
                        [-74.671, -6.236],
                        [-77.221, -7.822],
                        [-78.863, -7.059],
                        [-79.598, -5.501],
                        [-79.372, -4.141],
                        [-68.725, 21.801],
                        [-72.406, 31.26],
                        [-72.575, 32.449],
                        [-71.867, 33.923],
                        [-70.139, 34.659],
                        [-67.761, 32.902],
                        [-52.298, -4.254],
                        [-52.128, -5.471],
                        [-52.864, -7.059],
                        [-54.676, -7.822],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 14',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [146.962, 28.401], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 2.4,
          op: 177.6,
          st: 2.4,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Group 10',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.146], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 3.6,
                  s: [0],
                  e: [100],
                },
                { t: 12 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.146, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 3.6,
                  s: [181, 36, 0],
                  e: [215, 36, 0],
                  to: [5.667, 0, 0],
                  ti: [-5.667, 0, 0],
                },
                { t: 12 },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [143, 36, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.794, -2.869],
                        [0, 0],
                        [0.491, 0.491],
                        [0.736, 0],
                        [0.491, -0.49],
                        [0, -0.717],
                        [0, 0],
                        [-0.491, -0.491],
                        [-0.736, 0],
                        [-0.491, 0.49],
                        [0, 0.756],
                        [0, 0],
                        [-3.644, 0],
                        [-2.643, 2.568],
                        [0, 4.607],
                        [2.871, 2.87],
                        [3.663, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, -0.717],
                        [-0.491, -0.49],
                        [-0.736, 0],
                        [-0.491, 0.491],
                        [0, 0],
                        [0, 0.756],
                        [0.491, 0.49],
                        [0.736, 0],
                        [0.491, -0.491],
                        [0, 0],
                        [2.794, 2.869],
                        [3.644, 0],
                        [2.871, -2.831],
                        [0, -4.532],
                        [-2.605, -2.605],
                        [-3.663, 0],
                      ],
                      v: [
                        [105.823, 24.599],
                        [105.823, 23.127],
                        [105.087, 21.314],
                        [103.246, 20.578],
                        [101.405, 21.314],
                        [100.669, 23.127],
                        [100.669, 60.453],
                        [101.405, 62.323],
                        [103.246, 63.059],
                        [105.087, 62.323],
                        [105.823, 60.453],
                        [105.823, 46.01],
                        [115.48, 50.315],
                        [124.911, 46.463],
                        [129.215, 35.305],
                        [124.911, 24.203],
                        [115.508, 20.295],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 11',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.567, 2.832],
                        [0, 0],
                        [-3.398, 0],
                        [-1.662, -1.699],
                        [0, -3.247],
                        [0.454, -1.284],
                        [4.305, 0],
                      ],
                      o: [
                        [0, 0],
                        [2.567, -2.833],
                        [2.794, 0],
                        [1.736, 1.851],
                        [0, 1.661],
                        [-1.434, 3.852],
                        [-3.398, 0],
                      ],
                      v: [
                        [105.823, 41.252],
                        [105.823, 29.358],
                        [114.772, 25.11],
                        [121.456, 27.658],
                        [124.061, 35.305],
                        [123.381, 39.723],
                        [114.772, 45.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 12',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [114.942, 41.677], ix: 2 },
                  a: { a: 0, k: [114.942, 41.677], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 10',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 3.6,
          op: 178.8,
          st: 3.6,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Group 5',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.146], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 4.8,
                  s: [0],
                  e: [100],
                },
                { t: 13.2 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.146, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 4.8,
                  s: [181, 36, 0],
                  e: [215, 36, 0],
                  to: [5.667, 0, 0],
                  ti: [-5.667, 0, 0],
                },
                { t: 13.2 },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [143, 36, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.339, -0.34],
                        [0, 0],
                        [0, -0.34],
                        [-0.717, 0],
                        [0, 0],
                        [0, 0],
                        [-2.077, -1.209],
                        [-0.944, -0.113],
                        [-1.152, 0],
                        [-1.623, 0.755],
                        [0, 0.756],
                        [0.415, 0.377],
                        [0.509, 0],
                        [0.434, -0.17],
                        [1.019, 0],
                        [0.49, 0.301],
                        [0, 1.436],
                        [0, 0],
                        [0, 0],
                        [-0.415, 0.416],
                        [0, 0.642],
                        [0.416, 0.415],
                        [0.642, 0],
                        [0, 0],
                        [0, 0],
                        [0.208, 0.226],
                        [0.303, 0],
                      ],
                      o: [
                        [0, 0],
                        [-0.34, 0.303],
                        [0, 0.718],
                        [0, 0],
                        [0, 0],
                        [0, 3.021],
                        [0.869, 0.528],
                        [0.944, 0.113],
                        [1.151, 0],
                        [0.755, -0.34],
                        [0, -0.755],
                        [-0.416, -0.378],
                        [-0.51, 0],
                        [-0.435, 0.17],
                        [-0.982, 0],
                        [-0.869, -0.529],
                        [0, 0],
                        [0, 0],
                        [0.642, 0],
                        [0.416, -0.416],
                        [0, -0.642],
                        [-0.415, -0.415],
                        [0, 0],
                        [0, 0],
                        [0, -0.302],
                        [-0.208, -0.227],
                        [-0.301, 0],
                      ],
                      v: [
                        [141.167, 15.707],
                        [133.464, 23.24],
                        [132.954, 24.203],
                        [134.03, 25.279],
                        [138.051, 25.279],
                        [138.051, 42.838],
                        [141.167, 49.182],
                        [143.886, 50.145],
                        [147.03, 50.315],
                        [151.192, 49.182],
                        [152.325, 47.539],
                        [151.703, 45.84],
                        [150.315, 45.274],
                        [148.899, 45.529],
                        [146.718, 45.784],
                        [144.509, 45.331],
                        [143.207, 42.384],
                        [143.207, 25.279],
                        [149.606, 25.279],
                        [151.192, 24.656],
                        [151.816, 23.07],
                        [151.192, 21.484],
                        [149.606, 20.862],
                        [143.207, 20.862],
                        [143.207, 16.33],
                        [142.894, 15.537],
                        [142.129, 15.197],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 6',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [142.639, 32.756], ix: 2 },
                  a: { a: 0, k: [142.639, 32.756], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 4.8,
          op: 180,
          st: 4.8,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'Group 8',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.146], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 6,
                  s: [0],
                  e: [100],
                },
                { t: 14.4 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.146, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 6,
                  s: [181, 36, 0],
                  e: [215, 36, 0],
                  to: [5.667, 0, 0],
                  ti: [-5.667, 0, 0],
                },
                { t: 14.4 },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [143, 36, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.605, -2.756],
                        [0, -4.494],
                        [-2.643, -2.757],
                        [-4.418, 0],
                        [-2.643, 2.756],
                        [0, 4.456],
                        [2.644, 2.795],
                        [4.381, 0],
                      ],
                      o: [
                        [-2.643, 2.758],
                        [0, 4.494],
                        [2.605, 2.756],
                        [4.381, 0],
                        [2.644, -2.794],
                        [0, -4.456],
                        [-2.643, -2.756],
                        [-4.418, 0],
                      ],
                      v: [
                        [160.142, 24.429],
                        [156.177, 35.305],
                        [160.142, 46.18],
                        [170.677, 50.315],
                        [181.212, 46.18],
                        [185.177, 35.305],
                        [181.212, 24.429],
                        [170.677, 20.295],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 9',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.114, 0.377],
                        [0.906, 0.793],
                        [0.566, 1.321],
                        [0, 1.869],
                        [-0.567, 1.322],
                        [-4.079, 0],
                        [-1.699, -1.548],
                        [0, -3.398],
                        [2.039, -1.85],
                        [1.116, -0.378],
                        [1.077, 0],
                      ],
                      o: [
                        [-1.114, -0.378],
                        [-0.868, -0.83],
                        [-0.567, -1.322],
                        [0, -1.869],
                        [1.586, -3.662],
                        [2.492, 0],
                        [2.039, 1.888],
                        [0, 3.512],
                        [-0.906, 0.793],
                        [-1.113, 0.377],
                        [-1.075, 0],
                      ],
                      v: [
                        [167.364, 45.047],
                        [164.333, 43.291],
                        [162.181, 40.063],
                        [161.332, 35.276],
                        [162.181, 30.49],
                        [170.677, 24.996],
                        [176.964, 27.318],
                        [180.023, 35.248],
                        [176.964, 43.291],
                        [173.933, 45.047],
                        [170.648, 45.613],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 10',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [170.677, 35.305], ix: 2 },
                  a: { a: 0, k: [170.677, 35.305], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 8',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 6,
          op: 181.2,
          st: 6,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'Group 4',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.146], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 7.2,
                  s: [0],
                  e: [100],
                },
                { t: 15.6 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.146, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 7.2,
                  s: [181, 36, 0],
                  e: [215, 36, 0],
                  to: [5.667, 0, 0],
                  ti: [-5.667, 0, 0],
                },
                { t: 15.6 },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [143, 36, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.452, -0.472],
                        [0, -0.679],
                        [-0.454, -0.452],
                        [-0.681, 0],
                        [0, 0],
                        [0, 0],
                        [-0.491, -0.491],
                        [-0.718, 0],
                        [-0.509, 0.491],
                        [0, 0.717],
                        [0, 0],
                        [0, 0],
                        [-0.473, 0.454],
                        [0, 0.68],
                        [0.471, 0.472],
                        [0.68, 0],
                      ],
                      o: [
                        [-0.681, 0],
                        [-0.454, 0.472],
                        [0, 0.68],
                        [0.452, 0.454],
                        [0, 0],
                        [0, 0],
                        [0, 0.717],
                        [0.491, 0.491],
                        [0.717, 0],
                        [0.511, -0.491],
                        [0, 0],
                        [0, 0],
                        [0.68, 0],
                        [0.471, -0.452],
                        [0, -0.679],
                        [-0.473, -0.472],
                        [0, 0],
                      ],
                      v: [
                        [186.197, 10.213],
                        [184.498, 10.921],
                        [183.817, 12.648],
                        [184.498, 14.347],
                        [186.197, 15.027],
                        [197.752, 15.027],
                        [197.752, 47.483],
                        [198.488, 49.295],
                        [200.301, 50.032],
                        [202.141, 49.295],
                        [202.906, 47.483],
                        [202.906, 15.027],
                        [214.404, 15.027],
                        [216.132, 14.347],
                        [216.84, 12.648],
                        [216.132, 10.921],
                        [214.404, 10.213],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 5',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [200.329, 30.123], ix: 2 },
                  a: { a: 0, k: [200.329, 30.123], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 7.2,
          op: 182.4,
          st: 7.2,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: 'Group 6',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.146], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 8.4,
                  s: [0],
                  e: [100],
                },
                { t: 16.8 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.146, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 8.4,
                  s: [181, 36, 0],
                  e: [215, 36, 0],
                  to: [5.667, 0, 0],
                  ti: [-5.667, 0, 0],
                },
                { t: 16.8 },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [143, 36, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.172, -1.36],
                        [0, -0.83],
                        [-0.415, -0.397],
                        [-0.377, 0],
                        [-0.302, 0.076],
                        [-2.189, 0],
                        [-1.246, -1.076],
                        [0, -2.53],
                        [0, 0],
                        [0, 0],
                        [2.303, -1.737],
                        [0, -2.794],
                        [-1.813, -1.586],
                        [-2.605, 0],
                        [-1.717, 0.906],
                        [-1.02, 1.057],
                        [0, 0],
                        [-0.491, -0.491],
                        [-0.718, 0],
                        [-0.509, 0.491],
                        [0, 0.717],
                        [0, 0],
                        [1.887, 2.04],
                        [3.852, 0],
                      ],
                      o: [
                        [-0.982, 0.416],
                        [0, 0.831],
                        [0.416, 0.397],
                        [0.378, 0],
                        [2.832, -0.906],
                        [2.568, 0],
                        [1.246, 1.076],
                        [0, 0],
                        [0, 0],
                        [-4.267, 0],
                        [-2.115, 1.586],
                        [0, 2.568],
                        [1.737, 1.51],
                        [2.644, 0],
                        [1.719, -0.906],
                        [0, 0],
                        [0, 0.717],
                        [0.491, 0.491],
                        [0.717, 0],
                        [0.51, -0.491],
                        [0, 0],
                        [0, -3.058],
                        [-2.115, -2.341],
                        [-3.398, 0],
                      ],
                      v: [
                        [218.709, 22.334],
                        [217.236, 24.203],
                        [217.859, 26.044],
                        [219.049, 26.639],
                        [220.068, 26.525],
                        [227.601, 25.166],
                        [233.322, 26.78],
                        [235.191, 32.189],
                        [235.191, 32.643],
                        [228.338, 32.643],
                        [218.483, 35.248],
                        [215.31, 41.818],
                        [218.029, 48.049],
                        [224.543, 50.315],
                        [231.084, 48.955],
                        [235.191, 46.01],
                        [235.191, 47.483],
                        [235.928, 49.295],
                        [237.741, 50.032],
                        [239.581, 49.295],
                        [240.346, 47.483],
                        [240.346, 31.453],
                        [237.514, 23.806],
                        [228.564, 20.295],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 7',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 3.02],
                        [-1.511, 0.68],
                        [-2.68, 0],
                        [0, 0],
                        [0, 0],
                        [1.529, -0.869],
                        [1.775, 0],
                      ],
                      o: [
                        [0, -1.624],
                        [1.51, -0.68],
                        [0, 0],
                        [0, 0],
                        [-0.981, 1.094],
                        [-1.53, 0.868],
                        [-4.002, 0],
                      ],
                      v: [
                        [220.464, 41.252],
                        [222.731, 37.797],
                        [229.017, 36.777],
                        [235.191, 36.777],
                        [235.191, 41.536],
                        [231.425, 44.481],
                        [226.468, 45.784],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 8',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [227.828, 35.305], ix: 2 },
                  a: { a: 0, k: [227.828, 35.305], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 8.4,
          op: 183.6,
          st: 8.4,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: 'Group 2',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.146], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 9.6,
                  s: [0],
                  e: [100],
                },
                { t: 18 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.146, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 9.6,
                  s: [181, 36, 0],
                  e: [215, 36, 0],
                  to: [5.667, 0, 0],
                  ti: [-5.667, 0, 0],
                },
                { t: 18 },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [143, 36, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.492, -0.492],
                        [0, -0.755],
                        [0, 0],
                        [-0.49, -0.491],
                        [-0.736, 0],
                        [-0.49, 0.491],
                        [0, 0.717],
                        [0, 0],
                        [-3.644, 0],
                        [-2.643, 2.568],
                        [0, 4.607],
                        [2.87, 2.87],
                        [3.662, 0],
                        [2.795, -2.869],
                        [0, 0],
                        [0.492, 0.49],
                        [0.737, 0],
                      ],
                      o: [
                        [-0.49, 0.49],
                        [0, 0],
                        [0, 0.717],
                        [0.492, 0.491],
                        [0.737, 0],
                        [0.492, -0.491],
                        [0, 0],
                        [2.795, 2.869],
                        [3.644, 0],
                        [2.87, -2.831],
                        [0, -4.532],
                        [-2.605, -2.605],
                        [-3.663, 0],
                        [0, 0],
                        [0, -0.755],
                        [-0.49, -0.492],
                        [-0.736, 0],
                      ],
                      v: [
                        [249.804, 8.854],
                        [249.068, 10.722],
                        [249.068, 47.483],
                        [249.804, 49.295],
                        [251.645, 50.032],
                        [253.486, 49.295],
                        [254.222, 47.483],
                        [254.222, 46.01],
                        [263.88, 50.315],
                        [273.311, 46.463],
                        [277.616, 35.305],
                        [273.311, 24.203],
                        [263.909, 20.295],
                        [254.222, 24.599],
                        [254.222, 10.722],
                        [253.486, 8.854],
                        [251.645, 8.117],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.569, 2.832],
                        [0, 0],
                        [-3.398, 0],
                        [-1.662, -1.699],
                        [0, -3.247],
                        [0.453, -1.284],
                        [4.305, 0],
                      ],
                      o: [
                        [0, 0],
                        [2.569, -2.833],
                        [2.794, 0],
                        [1.736, 1.851],
                        [0, 1.661],
                        [-1.435, 3.852],
                        [-3.398, 0],
                      ],
                      v: [
                        [254.222, 41.252],
                        [254.222, 29.358],
                        [263.173, 25.11],
                        [269.856, 27.658],
                        [272.462, 35.305],
                        [271.782, 39.723],
                        [263.173, 45.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [263.342, 29.216], ix: 2 },
                  a: { a: 0, k: [263.342, 29.216], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 9.6,
          op: 184.8,
          st: 9.6,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: 'cryptotab/texts Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [215, 36, 0], ix: 2, l: 2 },
            a: { a: 0, k: [143, 36, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [],
          ip: 0,
          op: 175.2,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 5,
      ty: 0,
      nm: 'affiliate_dark',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [273, 381.5, 0], ix: 2, l: 2 },
        a: { a: 0, k: [393.5, 1000, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 787,
      h: 2000,
      ip: 0,
      op: 150,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
export const arrow = {
  v: '5.5.5',
  fr: 25,
  ip: 0,
  op: 116,
  w: 243,
  h: 361,
  nm: 'arrowonce_long',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'arrow 3',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 0,
              s: [25],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 5.839,
              s: [100],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 11.146,
              s: [50],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 16.456,
              s: [25],
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 28,
              s: [25],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 33.31,
              s: [100],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 38.616,
              s: [50],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 43.924,
              s: [25],
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 56,
              s: [25],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 61.311,
              s: [100],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 66.617,
              s: [50],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 71.926,
              s: [25],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 84,
              s: [25],
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 104.02,
              s: [25],
            },
            { t: 116, s: [25] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [122.5, 34.75, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 84,
              s: [122.5, 34.75, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.333, y: 0.333 },
              t: 91.507,
              s: [122.5, 60.75, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 96.512,
              s: [122.5, 60.75, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 104.02,
              s: [122.5, 34.75, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 116, s: [122.5, 34.75, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [0, 87, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 0,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 5.839,
              s: [110, 110, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 11.146,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 16.456,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 28,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 33.31,
              s: [110, 110, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 38.616,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 43.924,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 56,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 61.311,
              s: [110, 110, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 66.617,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 71.926,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 84,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 91.507,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.57, 0.57, 0.57], y: [1, 1, 1] },
              o: { x: [0.196, 0.196, 0.196], y: [0, 0, 0] },
              t: 96.512,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.703, 0.703, 0.703], y: [1, 1, 1] },
              o: { x: [0.326, 0.326, 0.326], y: [0, 0, 0] },
              t: 104,
              s: [100, 100, 100],
            },
            { t: 116, s: [100, 100, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-81.5, 87],
                          [-0.5, 168],
                          [80.5, 87],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 84,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-81.5, 87],
                          [-0.5, 168],
                          [80.5, 87],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 91.507,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-81.455, 167.909],
                          [-0.5, 168],
                          [80.545, 167.909],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 96.512,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-81.455, 167.909],
                          [-0.5, 168],
                          [80.545, 167.909],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 104.02,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-81.5, 87],
                          [-0.5, 168],
                          [80.5, 87],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 116,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-81.5, 87],
                          [-0.5, 168],
                          [80.5, 87],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.968627512455, 0.57647061348, 0.101960793138, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 15, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 117,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'arrow 2',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 0,
              s: [50],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 5.839,
              s: [25],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 11.146,
              s: [100],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 16.456,
              s: [50],
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 28,
              s: [50],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 33.31,
              s: [25],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 38.616,
              s: [100],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 43.924,
              s: [50],
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 56,
              s: [50],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 61.311,
              s: [25],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 66.617,
              s: [100],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 71.926,
              s: [50],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 84,
              s: [50],
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 104.02,
              s: [50],
            },
            { t: 116, s: [50] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [122.5, 136.75, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 84,
              s: [122.5, 136.75, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.333, y: 0.333 },
              t: 91.507,
              s: [122.5, 100.75, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 96.512,
              s: [122.5, 100.75, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 104.02,
              s: [122.5, 136.75, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 116, s: [122.5, 136.75, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [0, 87, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 0,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 5.839,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 11.146,
              s: [110, 110, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 16.456,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 28,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 33.31,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 38.616,
              s: [110, 110, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 43.924,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 56,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 61.311,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 66.617,
              s: [110, 110, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 71.926,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 84,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 104.02,
              s: [100, 100, 100],
            },
            { t: 116, s: [100, 100, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-81.5, 87],
                          [-0.5, 168],
                          [80.5, 87],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 84,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-81.5, 87],
                          [-0.5, 168],
                          [80.5, 87],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 91.507,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-81.5, 168],
                          [-0.5, 168],
                          [80.5, 168],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 96.512,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-81.5, 168],
                          [-0.5, 168],
                          [80.5, 168],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 104.02,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-81.5, 87],
                          [-0.5, 168],
                          [80.5, 87],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 116,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-81.5, 87],
                          [-0.5, 168],
                          [80.5, 87],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.968627512455, 0.57647061348, 0.101960793138, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 15, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 117,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'arrow',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 0,
              s: [100],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 5.839,
              s: [50],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 11.146,
              s: [25],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 16.456,
              s: [100],
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 28,
              s: [100],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 33.31,
              s: [50],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 38.616,
              s: [25],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 43.924,
              s: [100],
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 56,
              s: [100],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 61.311,
              s: [50],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 66.617,
              s: [25],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 71.926,
              s: [100],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 84,
              s: [100],
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 104.02,
              s: [100],
            },
            { t: 116, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [122.5, 238.75, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.659, y: 1 },
              o: { x: 0.297, y: 0 },
              t: 84,
              s: [122.5, 238.75, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.659, y: 0.659 },
              o: { x: 0.425, y: 0.425 },
              t: 91.507,
              s: [122.5, 138.75, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.815, y: 1 },
              o: { x: 0.425, y: 0 },
              t: 96.512,
              s: [122.5, 138.75, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.815, y: 0.815 },
              o: { x: 0.167, y: 0.167 },
              t: 104.02,
              s: [122.5, 238.75, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 116, s: [122.5, 238.75, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [0, 87, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 0,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 5.839,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 11.146,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 16.456,
              s: [110, 110, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 28,
              s: [110, 110, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 33.31,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 38.616,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 43.924,
              s: [110, 110, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 56,
              s: [110, 110, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 61.311,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 66.617,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.177, 0.177, 0.177], y: [0, 0, 0] },
              t: 71.926,
              s: [110, 110, 100],
            },
            {
              i: { x: [0.567, 0.567, 0.567], y: [0.809, 0.809, 1] },
              o: { x: [0.177, 0.177, 0.177], y: [0, 0, 0] },
              t: 84,
              s: [110, 110, 100],
            },
            {
              i: { x: [0.672, 0.672, 0.672], y: [0.996, 0.996, 0.088] },
              o: { x: [0.328, 0.328, 0.328], y: [-0.967, -0.967, 0] },
              t: 91.507,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.823, 0.823, 0.823], y: [1, 1, 1] },
              o: { x: [0.433, 0.433, 0.433], y: [0.009, 0.009, 1.806] },
              t: 96.512,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 104.02,
              s: [100, 100, 100],
            },
            { t: 116, s: [100, 100, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-81.5, 87],
                          [-0.5, 168],
                          [80.5, 87],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 84,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-81.5, 87],
                          [-0.5, 168],
                          [80.5, 87],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 91.507,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-81.5, 167.5],
                          [-0.5, 168],
                          [80.5, 167.5],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 96.512,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-81.5, 167.5],
                          [-0.5, 168],
                          [80.5, 167.5],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 104.02,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-81.5, 87],
                          [-0.5, 168],
                          [80.5, 87],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 116,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-81.5, 87],
                          [-0.5, 168],
                          [80.5, 87],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.968627512455, 0.57647061348, 0.101960793138, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 15, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 117,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};

export const pallete = {
  v: '5.7.11',
  fr: 25,
  ip: 0,
  op: 142,
  w: 90,
  h: 90,
  nm: 'icon_2',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Shape Layer 1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [45, 45, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-8.976, 11.718],
                    [-19.563, -26.187],
                  ],
                  o: [
                    [13.5, -17.625],
                    [24.115, 32.282],
                  ],
                  v: [
                    [-36.25, -23.75],
                    [29.938, -19.063],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.972549079446, 0.6, 0.149019607843, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 4, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.219], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 55,
                s: [0],
                e: [88],
              },
              { t: 67 },
            ],
            ix: 1,
          },
          e: {
            a: 1,
            k: [
              {
                i: { x: [0.274], y: [1] },
                o: { x: [0.202], y: [0] },
                t: 34,
                s: [0],
                e: [88],
              },
              { t: 46 },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false,
        },
      ],
      ip: 34,
      op: 336,
      st: 34,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Group 1',
      parent: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [43.756, 42.027, 0], ix: 2, l: 2 },
        a: { a: 0, k: [43.756, 42.027, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.693, -0.347],
                    [1.387, -3.467],
                    [-1.386, 6.934],
                    [0, 4.16],
                  ],
                  o: [
                    [4.16, 2.773],
                    [-3.467, 7.627],
                    [0.694, -4.16],
                    [0, -1.387],
                  ],
                  v: [
                    [2.253, -10.921],
                    [5.373, 3.641],
                    [-5.374, 1.561],
                    [0.866, -9.534],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.167], y: [0] },
                    t: 0,
                    s: [0.848365962505, 0.848365962505, 0.848365962505, 1],
                    e: [0.848365962505, 0.848365962505, 0.848365962505, 1],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 22,
                    s: [0.848365962505, 0.848365962505, 0.848365962505, 1],
                    e: [0.970616718367, 0.598098216337, 0.148820256252, 1],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 25,
                    s: [0.970616718367, 0.598098216337, 0.148820256252, 1],
                    e: [0.970616718367, 0.598098216337, 0.148820256252, 1],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 76,
                    s: [0.970616718367, 0.598098216337, 0.148820256252, 1],
                    e: [0.848365962505, 0.848365962505, 0.848365962505, 1],
                  },
                  { t: 84 },
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [80.502, 20.878], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 302,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Group 2',
      parent: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [43.756, 42.027, 0], ix: 2, l: 2 },
        a: { a: 0, k: [43.756, 42.027, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.347, 0],
                    [0, 0],
                    [0.346, -0.693],
                    [0, 0],
                    [0.693, 0],
                    [0, 0],
                    [0, 0.694],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.347, 0],
                    [0, 0],
                    [0, 0.347],
                    [0, 0],
                    [-0.694, -0.347],
                    [0, 0],
                    [0, -0.693],
                  ],
                  v: [
                    [-2.947, -2.427],
                    [3.64, -1.04],
                    [3.987, 0.346],
                    [3.987, 1.733],
                    [2.6, 2.427],
                    [-3.639, 0.693],
                    [-4.333, -0.694],
                    [-3.986, -1.734],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.970616718367, 0.598098216337, 0.148820256252, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [78.075, 31.8], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 302,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'bottom',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.381], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0,
              s: [0],
              e: [-52],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 19,
              s: [-52],
              e: [-55],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 22,
              s: [-55],
              e: [-52],
            },
            {
              i: { x: [0.316], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 25,
              s: [-52],
              e: [-51.545],
            },
            {
              i: { x: [0.274], y: [1] },
              o: { x: [0.202], y: [0] },
              t: 34,
              s: [-51.545],
              e: [0],
            },
            { t: 46 },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.381, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 0,
              s: [65.944, 77.006, 0],
              e: [50.944, 72.006, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 19,
              s: [50.944, 72.006, 0],
              e: [50.994, 73.822, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 22,
              s: [50.994, 73.822, 0],
              e: [50.944, 72.006, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.316, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 25,
              s: [50.944, 72.006, 0],
              e: [45.421, 65.825, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.274, y: 1 },
              o: { x: 0.202, y: 0 },
              t: 34,
              s: [45.421, 65.825, 0],
              e: [65.944, 77.006, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 46 },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [67.006, 74.527, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.381, 0.361, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 0,
              s: [100, 100, 100],
              e: [85, 80, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 19,
              s: [85, 80, 100],
              e: [80, 77, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 22,
              s: [80, 77, 100],
              e: [85, 80, 100],
            },
            {
              i: { x: [0.442, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 25,
              s: [85, 80, 100],
              e: [100, 100, 100],
            },
            { t: 46 },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.387, -0.347],
                    [0, 0],
                    [0.347, -1.387],
                    [0, 0],
                    [1.387, 0.348],
                    [-0.347, 1.387],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [1.387, 0],
                    [0, 0],
                    [-0.693, 1.04],
                    [-1.386, -0.347],
                    [0, 0],
                    [0.347, -1.386],
                  ],
                  v: [
                    [2.6, -21.842],
                    [6.413, -20.802],
                    [8.146, -18.028],
                    [-3.294, 20.108],
                    [-6.413, 21.841],
                    [-8.146, 18.721],
                    [-0.52, -20.109],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.487202692967, 0.487202692967, 0.487202692967, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [72.876, 55.721], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 302,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Group 4',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [42.694, 44.506, 0], ix: 2, l: 2 },
        a: { a: 0, k: [43.756, 42.027, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.733, 0.347],
                    [-0.347, -1.733],
                    [2.08, -0.347],
                    [0.347, 2.08],
                  ],
                  o: [
                    [2.08, -0.347],
                    [0.693, 2.081],
                    [-2.08, 0.693],
                    [-0.347, -2.08],
                  ],
                  v: [
                    [-1.04, -3.814],
                    [3.467, -1.04],
                    [0.693, 3.467],
                    [-3.813, 0.693],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.970616718367, 0.598098216337, 0.148820256252, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [20.704, 33.186], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 302,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Group 5',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [42.694, 44.506, 0], ix: 2, l: 2 },
        a: { a: 0, k: [43.756, 42.027, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.733, 0.347],
                    [-0.347, -1.733],
                    [2.08, -0.346],
                    [0.347, 2.08],
                  ],
                  o: [
                    [2.427, -0.347],
                    [0.693, 2.427],
                    [-2.08, 0.694],
                    [-0.347, -1.734],
                  ],
                  v: [
                    [-1.04, -3.814],
                    [3.467, -1.04],
                    [0.693, 3.466],
                    [-3.813, 0.693],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.970616718367, 0.598098216337, 0.148820256252, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [42.89, 20.705], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 302,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: 'Group 9',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [42.694, 44.506, 0], ix: 2, l: 2 },
        a: { a: 0, k: [43.756, 42.027, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-2.08, 0.347],
                    [-0.694, -2.08],
                    [2.08, -0.346],
                    [0.694, 1.733],
                  ],
                  o: [
                    [2.08, -0.693],
                    [0.693, 2.081],
                    [-2.08, 0.347],
                    [0, -2.08],
                  ],
                  v: [
                    [-20.339, 0.52],
                    [-15.832, 3.293],
                    [-18.606, 7.8],
                    [-23.459, 5.027],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [37.229, 42.027], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 9',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 302,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: 'Group 8',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [42.694, 44.506, 0], ix: 2, l: 2 },
        a: { a: 0, k: [43.756, 42.027, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.733, 0.347],
                    [-0.347, -2.426],
                    [2.08, -0.347],
                    [0.347, 2.08],
                  ],
                  o: [
                    [2.427, -0.346],
                    [0.693, 1.734],
                    [-1.733, 0.347],
                    [-0.347, -2.081],
                  ],
                  v: [
                    [-8.206, -21.322],
                    [-3.699, -18.202],
                    [-6.473, -14.041],
                    [-10.979, -16.814],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [37.229, 42.027], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 8',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 302,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 4,
      nm: 'Group 7',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [42.694, 44.506, 0], ix: 2, l: 2 },
        a: { a: 0, k: [43.756, 42.027, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-2.426, 0.346],
                    [-0.347, -2.08],
                    [2.08, -0.346],
                    [0.347, 2.08],
                  ],
                  o: [
                    [1.734, -0.694],
                    [0.347, 2.08],
                    [-2.08, 0.347],
                    [-0.693, -2.08],
                  ],
                  v: [
                    [12.94, -10.574],
                    [17.447, -7.801],
                    [14.327, -2.947],
                    [9.82, -6.067],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 3',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [37.229, 42.027], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 7',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 302,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      nm: 'Group 6',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [42.694, 44.506, 0], ix: 2, l: 2 },
        a: { a: 0, k: [43.756, 42.027, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-23.225, -19.068],
                    [23.573, -8.667],
                    [-1.734, -7.627],
                    [12.827, 15.255],
                    [1.04, 5.2],
                  ],
                  o: [
                    [10.747, 9.015],
                    [-5.893, 1.735],
                    [1.04, 6.934],
                    [-3.813, -4.16],
                    [-7.28, -32.937],
                  ],
                  v: [
                    [23.686, -22.709],
                    [12.594, 10.92],
                    [6.701, 28.255],
                    [-22.419, 26.522],
                    [-29.699, 12.655],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 4',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.286274509804, 0.286274509804, 0.286274509804, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [37.229, 42.027], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 302,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};

export const nftBot = {
  v: '5.7.11',
  fr: 30,
  ip: 0,
  op: 132,
  w: 800,
  h: 480,
  nm: 'nftm-8',
  ddd: 0,
  assets: [
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 0,
          nm: 'hand_cycle_front',
          refId: 'comp_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [256, 276, 0], ix: 2, l: 2 },
            a: { a: 0, k: [256, 256, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 512,
          h: 512,
          ip: 88,
          op: 132,
          st: 88,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 0,
          nm: 'hand_cycle_front',
          refId: 'comp_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [256, 276, 0], ix: 2, l: 2 },
            a: { a: 0, k: [256, 256, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 512,
          h: 512,
          ip: 44,
          op: 88,
          st: 44,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 0,
          nm: 'hand_cycle_front',
          refId: 'comp_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [256, 276, 0], ix: 2, l: 2 },
            a: { a: 0, k: [256, 256, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 512,
          h: 512,
          ip: 0,
          op: 44,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'card_5',
          parent: 5,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [32.507, 32.106, 0], ix: 2, l: 2 },
            a: { a: 0, k: [35.754, 44.691, 0], ix: 1, l: 2 },
            s: { a: 0, k: [75.455, 75.455, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.682, -0.007],
                        [0, 0],
                        [1.056, -1.366],
                        [0, 0],
                        [0, 0],
                        [1.728, -0.004],
                        [0, 0],
                        [-0.006, -2.682],
                        [-0.921, -0.91],
                        [0, 0],
                        [0, 0],
                        [0.003, 1.295],
                      ],
                      o: [
                        [0, 0],
                        [-1.727, 0.004],
                        [0, 0],
                        [0, 0],
                        [-1.061, -1.362],
                        [0, 0],
                        [-2.681, 0.006],
                        [0.004, 1.295],
                        [0, 0],
                        [0, 0],
                        [0.917, -0.913],
                        [-0.006, -2.682],
                      ],
                      v: [
                        [6.237, -8.947],
                        [5.503, -8.946],
                        [1.092, -6.773],
                        [-0.002, -5.355],
                        [-1.103, -6.766],
                        [-5.523, -8.92],
                        [-6.255, -8.917],
                        [-11.101, -4.05],
                        [-9.656, -0.606],
                        [0.032, 8.954],
                        [9.674, -0.652],
                        [11.103, -4.102],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [35.728, 45.952], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -11.473],
                        [11.472, 0],
                        [0, 11.473],
                        [-11.473, 0],
                      ],
                      o: [
                        [0, 11.473],
                        [-11.473, 0],
                        [0, -11.473],
                        [11.472, 0],
                      ],
                      v: [
                        [20.773, 0],
                        [0.001, 20.773],
                        [-20.773, 0],
                        [0.001, -20.773],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.987999949736, 0.294000004787, 0.675, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [35.76, 44.592], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -13.582],
                        [13.581, 0],
                        [0, 13.581],
                        [-13.581, 0],
                      ],
                      o: [
                        [0, 13.581],
                        [-13.581, 0],
                        [0, -13.582],
                        [13.581, 0],
                      ],
                      v: [
                        [24.591, 0],
                        [0, 24.591],
                        [-24.591, 0],
                        [0, -24.591],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [35.761, 44.592], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.471],
                        [1.471, 0],
                        [0, 1.471],
                        [-1.472, 0],
                      ],
                      o: [
                        [0, 1.471],
                        [-1.472, 0],
                        [0, -1.471],
                        [1.471, 0],
                      ],
                      v: [
                        [2.664, 0],
                        [0, 2.664],
                        [-2.664, 0],
                        [0, -2.664],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [56.907, 74.896], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.471],
                        [1.471, 0],
                        [0, 1.471],
                        [-1.472, 0],
                      ],
                      o: [
                        [0, 1.471],
                        [-1.472, 0],
                        [0, -1.471],
                        [1.471, 0],
                      ],
                      v: [
                        [2.664, 0],
                        [0, 2.664],
                        [-2.664, 0],
                        [0, -2.664],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [14.288, 74.896], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.471],
                        [1.471, 0],
                        [0, 1.471],
                        [-1.472, 0],
                      ],
                      o: [
                        [0, 1.471],
                        [-1.472, 0],
                        [0, -1.471],
                        [1.471, 0],
                      ],
                      v: [
                        [2.664, 0],
                        [0, 2.664],
                        [-2.664, 0],
                        [0, -2.664],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [56.907, 14.518], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.471],
                        [1.471, 0],
                        [0, 1.471],
                        [-1.472, 0],
                      ],
                      o: [
                        [0, 1.471],
                        [-1.472, 0],
                        [0, -1.471],
                        [1.471, 0],
                      ],
                      v: [
                        [2.664, 0],
                        [0, 2.664],
                        [-2.664, 0],
                        [0, -2.664],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [14.288, 14.518], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 7',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [5.07, 0],
                        [0, 0],
                        [0, 5.071],
                        [0, 0],
                        [-5.07, 0],
                        [0, 0],
                        [0, -5.07],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-5.07, 0],
                        [0, 0],
                        [0, -5.07],
                        [0, 0],
                        [5.07, 0],
                        [0, 0],
                        [0, 5.071],
                      ],
                      v: [
                        [22.064, 40.076],
                        [-22.065, 40.076],
                        [-31.245, 30.895],
                        [-31.245, -30.896],
                        [-22.065, -40.076],
                        [22.064, -40.076],
                        [31.245, -30.896],
                        [31.245, 30.895],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.626999978458, 0.2, 0.8, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [35.761, 44.592], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 8',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 8,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [7.674, 0],
                        [0, 0],
                        [0, 7.675],
                        [0, 0],
                        [-7.675, 0],
                        [0, 0],
                        [0, -7.674],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-7.675, 0],
                        [0, 0],
                        [0, -7.674],
                        [0, 0],
                        [7.674, 0],
                        [0, 0],
                        [0, 7.675],
                      ],
                      v: [
                        [21.615, 44.342],
                        [-21.615, 44.342],
                        [-35.511, 30.446],
                        [-35.511, -30.446],
                        [-21.615, -44.342],
                        [21.615, -44.342],
                        [35.511, -30.446],
                        [35.511, 30.446],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [35.761, 44.592], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 9',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 9,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 31,
          st: -102,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 3,
          nm: 'bitcoin 6',
          parent: 14,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: -102,
                  s: [0],
                  e: [0],
                },
                {
                  i: { x: [0.772], y: [1] },
                  o: { x: [0.522], y: [0] },
                  t: -95,
                  s: [0],
                  e: [180],
                },
                {
                  i: { x: [0.596], y: [1] },
                  o: { x: [0.307], y: [0] },
                  t: -80,
                  s: [180],
                  e: [180],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.58], y: [0] },
                  t: -73,
                  s: [180],
                  e: [0],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: -58,
                  s: [0],
                  e: [0],
                },
                {
                  i: { x: [0.772], y: [1] },
                  o: { x: [0.522], y: [0] },
                  t: -51,
                  s: [0],
                  e: [180],
                },
                {
                  i: { x: [0.596], y: [1] },
                  o: { x: [0.307], y: [0] },
                  t: -36,
                  s: [180],
                  e: [180],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.58], y: [0] },
                  t: -29,
                  s: [180],
                  e: [0],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: -14,
                  s: [0],
                  e: [0],
                },
                {
                  i: { x: [0.772], y: [1] },
                  o: { x: [0.522], y: [0] },
                  t: -7,
                  s: [0],
                  e: [180],
                },
                {
                  i: { x: [0.596], y: [1] },
                  o: { x: [0.307], y: [0] },
                  t: 8,
                  s: [180],
                  e: [180],
                },
                {
                  i: { x: [0.368], y: [1] },
                  o: { x: [0.58], y: [0] },
                  t: 15,
                  s: [180],
                  e: [0],
                },
                { t: 30 },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.57, y: 0.541 },
                  o: { x: 0.167, y: 0.167 },
                  t: -102,
                  s: [370.541, -24.034, 0],
                  e: [361.988, 9.41, 0],
                  to: [12.889, 62.553, 0],
                  ti: [45.023, -42.894, 0],
                },
                {
                  i: { x: 0.653, y: 0.707 },
                  o: { x: 0.317, y: 0.244 },
                  t: -100,
                  s: [361.988, 9.41, 0],
                  e: [334.584, 41.108, 0],
                  to: [-19.86, 18.921, 0],
                  ti: [40.364, -56.19, 0],
                },
                {
                  i: { x: 0.662, y: 0.781 },
                  o: { x: 0.326, y: 0.193 },
                  t: -99,
                  s: [334.584, 41.108, 0],
                  e: [280.841, 86.179, 0],
                  to: [-12.979, 18.068, 0],
                  ti: [38.562, -24.79, 0],
                },
                {
                  i: { x: 0.668, y: 0.725 },
                  o: { x: 0.333, y: 0.267 },
                  t: -98,
                  s: [280.841, 86.179, 0],
                  e: [235.364, 120.044, 0],
                  to: [-29.071, 18.688, 0],
                  ti: [35.935, -17.308, 0],
                },
                {
                  i: { x: 0.675, y: 0.829 },
                  o: { x: 0.34, y: 0.163 },
                  t: -97,
                  s: [235.364, 120.044, 0],
                  e: [145.701, 161.17, 0],
                  to: [-33.538, 16.153, 0],
                  ti: [40.355, -10.71, 0],
                },
                {
                  i: { x: 0.685, y: 1 },
                  o: { x: 0.349, y: 0.165 },
                  t: -96,
                  s: [145.701, 161.17, 0],
                  e: [38.834, 164.99, 0],
                  to: [-37.423, 9.932, 0],
                  ti: [6.353, 13.673, 0],
                },
                {
                  i: { x: 0.178, y: 0.974 },
                  o: { x: 0.046, y: 0 },
                  t: -95,
                  s: [38.834, 164.99, 0],
                  e: [-302.629, -517.937, 0],
                  to: [-60.163, -129.472, 0],
                  ti: [130.678, -2.795, 0],
                },
                {
                  i: { x: 1, y: 1 },
                  o: { x: 0.704, y: 0.044 },
                  t: -87.941,
                  s: [-302.629, -517.937, 0],
                  e: [-483.117, -10.62, 0],
                  to: [-130.678, 2.795, 0],
                  ti: [2.439, -0.407, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -80,
                  s: [-483.117, -10.62, 0],
                  e: [-486.776, -10.62, 0],
                  to: [-2.439, 0.407, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -79,
                  s: [-486.776, -10.62, 0],
                  e: [-489.215, -5.742, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -78,
                  s: [-489.215, -5.742, 0],
                  e: [-485.556, 13.161, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -77,
                  s: [-485.556, 13.161, 0],
                  e: [-458.117, 45.478, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -76,
                  s: [-458.117, 45.478, 0],
                  e: [-413.605, 82.063, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -75,
                  s: [-413.605, 82.063, 0],
                  e: [-329.459, 132.673, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.685, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: -74,
                  s: [-329.459, 132.673, 0],
                  e: [-252.02, 162.551, 0],
                  to: [0, 0, 0],
                  ti: [-65.434, 161.332, 0],
                },
                {
                  i: { x: 0.178, y: 0.974 },
                  o: { x: 0.046, y: 0 },
                  t: -73,
                  s: [-252.02, 162.551, 0],
                  e: [122.371, -515.498, 0],
                  to: [65.434, -161.332, 0],
                  ti: [-110.556, -0.864, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.704, y: 0.044 },
                  t: -66,
                  s: [122.371, -515.498, 0],
                  e: [370.541, -24.034, 0],
                  to: [110.556, 0.864, 0],
                  ti: [-37.398, -181.504, 0],
                },
                {
                  i: { x: 0.57, y: 0.541 },
                  o: { x: 0.167, y: 0.167 },
                  t: -58,
                  s: [370.541, -24.034, 0],
                  e: [361.988, 9.41, 0],
                  to: [12.889, 62.553, 0],
                  ti: [45.023, -42.894, 0],
                },
                {
                  i: { x: 0.653, y: 0.707 },
                  o: { x: 0.317, y: 0.244 },
                  t: -56,
                  s: [361.988, 9.41, 0],
                  e: [334.584, 41.108, 0],
                  to: [-19.86, 18.921, 0],
                  ti: [40.364, -56.19, 0],
                },
                {
                  i: { x: 0.662, y: 0.781 },
                  o: { x: 0.326, y: 0.193 },
                  t: -55,
                  s: [334.584, 41.108, 0],
                  e: [280.841, 86.179, 0],
                  to: [-12.979, 18.068, 0],
                  ti: [38.562, -24.79, 0],
                },
                {
                  i: { x: 0.668, y: 0.725 },
                  o: { x: 0.333, y: 0.267 },
                  t: -54,
                  s: [280.841, 86.179, 0],
                  e: [235.364, 120.044, 0],
                  to: [-29.071, 18.688, 0],
                  ti: [35.935, -17.308, 0],
                },
                {
                  i: { x: 0.675, y: 0.829 },
                  o: { x: 0.34, y: 0.163 },
                  t: -53,
                  s: [235.364, 120.044, 0],
                  e: [145.701, 161.17, 0],
                  to: [-33.538, 16.153, 0],
                  ti: [40.355, -10.71, 0],
                },
                {
                  i: { x: 0.685, y: 1 },
                  o: { x: 0.349, y: 0.165 },
                  t: -52,
                  s: [145.701, 161.17, 0],
                  e: [38.834, 164.99, 0],
                  to: [-37.423, 9.932, 0],
                  ti: [6.353, 13.673, 0],
                },
                {
                  i: { x: 0.178, y: 0.974 },
                  o: { x: 0.046, y: 0 },
                  t: -51,
                  s: [38.834, 164.99, 0],
                  e: [-302.629, -517.937, 0],
                  to: [-60.163, -129.472, 0],
                  ti: [130.678, -2.795, 0],
                },
                {
                  i: { x: 1, y: 1 },
                  o: { x: 0.704, y: 0.044 },
                  t: -43.941,
                  s: [-302.629, -517.937, 0],
                  e: [-483.117, -10.62, 0],
                  to: [-130.678, 2.795, 0],
                  ti: [2.439, -0.407, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -36,
                  s: [-483.117, -10.62, 0],
                  e: [-486.776, -10.62, 0],
                  to: [-2.439, 0.407, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -35,
                  s: [-486.776, -10.62, 0],
                  e: [-489.215, -5.742, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -34,
                  s: [-489.215, -5.742, 0],
                  e: [-485.556, 13.161, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -33,
                  s: [-485.556, 13.161, 0],
                  e: [-458.117, 45.478, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -32,
                  s: [-458.117, 45.478, 0],
                  e: [-413.605, 82.063, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -31,
                  s: [-413.605, 82.063, 0],
                  e: [-329.459, 132.673, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.685, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: -30,
                  s: [-329.459, 132.673, 0],
                  e: [-252.02, 162.551, 0],
                  to: [0, 0, 0],
                  ti: [-65.434, 161.332, 0],
                },
                {
                  i: { x: 0.178, y: 0.974 },
                  o: { x: 0.046, y: 0 },
                  t: -29,
                  s: [-252.02, 162.551, 0],
                  e: [122.371, -515.498, 0],
                  to: [65.434, -161.332, 0],
                  ti: [-110.556, -0.864, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.704, y: 0.044 },
                  t: -22,
                  s: [122.371, -515.498, 0],
                  e: [370.541, -24.034, 0],
                  to: [110.556, 0.864, 0],
                  ti: [-37.398, -181.504, 0],
                },
                {
                  i: { x: 0.57, y: 0.541 },
                  o: { x: 0.167, y: 0.167 },
                  t: -14,
                  s: [370.541, -24.034, 0],
                  e: [361.988, 9.41, 0],
                  to: [12.889, 62.553, 0],
                  ti: [45.023, -42.894, 0],
                },
                {
                  i: { x: 0.653, y: 0.707 },
                  o: { x: 0.317, y: 0.244 },
                  t: -12,
                  s: [361.988, 9.41, 0],
                  e: [334.584, 41.108, 0],
                  to: [-19.86, 18.921, 0],
                  ti: [40.364, -56.19, 0],
                },
                {
                  i: { x: 0.662, y: 0.781 },
                  o: { x: 0.326, y: 0.193 },
                  t: -11,
                  s: [334.584, 41.108, 0],
                  e: [280.841, 86.179, 0],
                  to: [-12.979, 18.068, 0],
                  ti: [38.562, -24.79, 0],
                },
                {
                  i: { x: 0.668, y: 0.725 },
                  o: { x: 0.333, y: 0.267 },
                  t: -10,
                  s: [280.841, 86.179, 0],
                  e: [235.364, 120.044, 0],
                  to: [-29.071, 18.688, 0],
                  ti: [35.935, -17.308, 0],
                },
                {
                  i: { x: 0.675, y: 0.829 },
                  o: { x: 0.34, y: 0.163 },
                  t: -9,
                  s: [235.364, 120.044, 0],
                  e: [145.701, 161.17, 0],
                  to: [-33.538, 16.153, 0],
                  ti: [40.355, -10.71, 0],
                },
                {
                  i: { x: 0.685, y: 1 },
                  o: { x: 0.349, y: 0.165 },
                  t: -8,
                  s: [145.701, 161.17, 0],
                  e: [38.834, 164.99, 0],
                  to: [-37.423, 9.932, 0],
                  ti: [6.353, 13.673, 0],
                },
                {
                  i: { x: 0.178, y: 0.974 },
                  o: { x: 0.046, y: 0 },
                  t: -7,
                  s: [38.834, 164.99, 0],
                  e: [-302.629, -517.937, 0],
                  to: [-60.163, -129.472, 0],
                  ti: [130.678, -2.795, 0],
                },
                {
                  i: { x: 1, y: 1 },
                  o: { x: 0.704, y: 0.044 },
                  t: 0.059,
                  s: [-302.629, -517.937, 0],
                  e: [-483.117, -10.62, 0],
                  to: [-130.678, 2.795, 0],
                  ti: [2.439, -0.407, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 8,
                  s: [-483.117, -10.62, 0],
                  e: [-486.776, -10.62, 0],
                  to: [-2.439, 0.407, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 9,
                  s: [-486.776, -10.62, 0],
                  e: [-489.215, -5.742, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 10,
                  s: [-489.215, -5.742, 0],
                  e: [-485.556, 13.161, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 11,
                  s: [-485.556, 13.161, 0],
                  e: [-458.117, 45.478, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 12,
                  s: [-458.117, 45.478, 0],
                  e: [-413.605, 82.063, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 13,
                  s: [-413.605, 82.063, 0],
                  e: [-329.459, 132.673, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.685, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 14,
                  s: [-329.459, 132.673, 0],
                  e: [-252.02, 162.551, 0],
                  to: [0, 0, 0],
                  ti: [-65.434, 161.332, 0],
                },
                {
                  i: { x: 0.178, y: 0.974 },
                  o: { x: 0.046, y: 0 },
                  t: 15,
                  s: [-252.02, 162.551, 0],
                  e: [122.371, -515.498, 0],
                  to: [65.434, -161.332, 0],
                  ti: [-110.556, -0.864, 0],
                },
                {
                  i: { x: 0.999, y: 1 },
                  o: { x: 0.704, y: 0.044 },
                  t: 22,
                  s: [122.371, -515.498, 0],
                  e: [370.541, -24.034, 0],
                  to: [110.556, 0.864, 0],
                  ti: [-37.398, -181.504, 0],
                },
                { t: 30 },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [31.75, 32.75, 0], ix: 1, l: 2 },
            s: { a: 0, k: [489.084, 489.084, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 0,
          op: 31,
          st: -112,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'card_3',
          parent: 7,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [32.507, 32.106, 0], ix: 2, l: 2 },
            a: { a: 0, k: [35.754, 44.691, 0], ix: 1, l: 2 },
            s: { a: 0, k: [75.455, 75.455, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.682, -0.007],
                        [0, 0],
                        [1.056, -1.366],
                        [0, 0],
                        [0, 0],
                        [1.728, -0.004],
                        [0, 0],
                        [-0.006, -2.682],
                        [-0.921, -0.91],
                        [0, 0],
                        [0, 0],
                        [0.003, 1.295],
                      ],
                      o: [
                        [0, 0],
                        [-1.727, 0.004],
                        [0, 0],
                        [0, 0],
                        [-1.061, -1.362],
                        [0, 0],
                        [-2.681, 0.006],
                        [0.004, 1.295],
                        [0, 0],
                        [0, 0],
                        [0.917, -0.913],
                        [-0.006, -2.682],
                      ],
                      v: [
                        [6.237, -8.947],
                        [5.503, -8.946],
                        [1.092, -6.773],
                        [-0.002, -5.355],
                        [-1.103, -6.766],
                        [-5.523, -8.92],
                        [-6.255, -8.917],
                        [-11.101, -4.05],
                        [-9.656, -0.606],
                        [0.032, 8.954],
                        [9.674, -0.652],
                        [11.103, -4.102],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [35.728, 45.952], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -11.473],
                        [11.472, 0],
                        [0, 11.473],
                        [-11.473, 0],
                      ],
                      o: [
                        [0, 11.473],
                        [-11.473, 0],
                        [0, -11.473],
                        [11.472, 0],
                      ],
                      v: [
                        [20.773, 0],
                        [0.001, 20.773],
                        [-20.773, 0],
                        [0.001, -20.773],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.987999949736, 0.294000004787, 0.675, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [35.76, 44.592], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -13.582],
                        [13.581, 0],
                        [0, 13.581],
                        [-13.581, 0],
                      ],
                      o: [
                        [0, 13.581],
                        [-13.581, 0],
                        [0, -13.582],
                        [13.581, 0],
                      ],
                      v: [
                        [24.591, 0],
                        [0, 24.591],
                        [-24.591, 0],
                        [0, -24.591],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [35.761, 44.592], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.471],
                        [1.471, 0],
                        [0, 1.471],
                        [-1.472, 0],
                      ],
                      o: [
                        [0, 1.471],
                        [-1.472, 0],
                        [0, -1.471],
                        [1.471, 0],
                      ],
                      v: [
                        [2.664, 0],
                        [0, 2.664],
                        [-2.664, 0],
                        [0, -2.664],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [56.907, 74.896], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.471],
                        [1.471, 0],
                        [0, 1.471],
                        [-1.472, 0],
                      ],
                      o: [
                        [0, 1.471],
                        [-1.472, 0],
                        [0, -1.471],
                        [1.471, 0],
                      ],
                      v: [
                        [2.664, 0],
                        [0, 2.664],
                        [-2.664, 0],
                        [0, -2.664],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [14.288, 74.896], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.471],
                        [1.471, 0],
                        [0, 1.471],
                        [-1.472, 0],
                      ],
                      o: [
                        [0, 1.471],
                        [-1.472, 0],
                        [0, -1.471],
                        [1.471, 0],
                      ],
                      v: [
                        [2.664, 0],
                        [0, 2.664],
                        [-2.664, 0],
                        [0, -2.664],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [56.907, 14.518], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.471],
                        [1.471, 0],
                        [0, 1.471],
                        [-1.472, 0],
                      ],
                      o: [
                        [0, 1.471],
                        [-1.472, 0],
                        [0, -1.471],
                        [1.471, 0],
                      ],
                      v: [
                        [2.664, 0],
                        [0, 2.664],
                        [-2.664, 0],
                        [0, -2.664],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [14.288, 14.518], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 7',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [5.07, 0],
                        [0, 0],
                        [0, 5.071],
                        [0, 0],
                        [-5.07, 0],
                        [0, 0],
                        [0, -5.07],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-5.07, 0],
                        [0, 0],
                        [0, -5.07],
                        [0, 0],
                        [5.07, 0],
                        [0, 0],
                        [0, 5.071],
                      ],
                      v: [
                        [22.064, 40.076],
                        [-22.065, 40.076],
                        [-31.245, 30.895],
                        [-31.245, -30.896],
                        [-22.065, -40.076],
                        [22.064, -40.076],
                        [31.245, -30.896],
                        [31.245, 30.895],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.626999978458, 0.2, 0.8, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [35.761, 44.592], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 8',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 8,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [7.674, 0],
                        [0, 0],
                        [0, 7.675],
                        [0, 0],
                        [-7.675, 0],
                        [0, 0],
                        [0, -7.674],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-7.675, 0],
                        [0, 0],
                        [0, -7.674],
                        [0, 0],
                        [7.674, 0],
                        [0, 0],
                        [0, 7.675],
                      ],
                      v: [
                        [21.615, 44.342],
                        [-21.615, 44.342],
                        [-35.511, 30.446],
                        [-35.511, -30.446],
                        [-21.615, -44.342],
                        [21.615, -44.342],
                        [35.511, -30.446],
                        [35.511, 30.446],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [35.761, 44.592], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 9',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 9,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 30,
          op: 132,
          st: 30,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 3,
          nm: 'bitcoin 4',
          parent: 14,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: 30,
                  s: [0],
                  e: [0],
                },
                {
                  i: { x: [0.772], y: [1] },
                  o: { x: [0.522], y: [0] },
                  t: 37,
                  s: [0],
                  e: [180],
                },
                {
                  i: { x: [0.596], y: [1] },
                  o: { x: [0.307], y: [0] },
                  t: 52,
                  s: [180],
                  e: [180],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.58], y: [0] },
                  t: 59,
                  s: [180],
                  e: [0],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: 74,
                  s: [0],
                  e: [0],
                },
                {
                  i: { x: [0.772], y: [1] },
                  o: { x: [0.522], y: [0] },
                  t: 81,
                  s: [0],
                  e: [180],
                },
                {
                  i: { x: [0.596], y: [1] },
                  o: { x: [0.307], y: [0] },
                  t: 96,
                  s: [180],
                  e: [180],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.58], y: [0] },
                  t: 103,
                  s: [180],
                  e: [0],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: 118,
                  s: [0],
                  e: [0],
                },
                {
                  i: { x: [0.772], y: [1] },
                  o: { x: [0.522], y: [0] },
                  t: 125,
                  s: [0],
                  e: [180],
                },
                {
                  i: { x: [0.596], y: [1] },
                  o: { x: [0.307], y: [0] },
                  t: 140,
                  s: [180],
                  e: [180],
                },
                {
                  i: { x: [0.368], y: [1] },
                  o: { x: [0.58], y: [0] },
                  t: 147,
                  s: [180],
                  e: [0],
                },
                { t: 162 },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.57, y: 0.541 },
                  o: { x: 0.167, y: 0.167 },
                  t: 30,
                  s: [370.541, -24.034, 0],
                  e: [361.988, 9.41, 0],
                  to: [12.889, 62.553, 0],
                  ti: [45.023, -42.894, 0],
                },
                {
                  i: { x: 0.653, y: 0.707 },
                  o: { x: 0.317, y: 0.244 },
                  t: 32,
                  s: [361.988, 9.41, 0],
                  e: [334.584, 41.108, 0],
                  to: [-19.86, 18.921, 0],
                  ti: [40.364, -56.19, 0],
                },
                {
                  i: { x: 0.662, y: 0.781 },
                  o: { x: 0.326, y: 0.193 },
                  t: 33,
                  s: [334.584, 41.108, 0],
                  e: [280.841, 86.179, 0],
                  to: [-12.979, 18.068, 0],
                  ti: [38.562, -24.79, 0],
                },
                {
                  i: { x: 0.668, y: 0.725 },
                  o: { x: 0.333, y: 0.267 },
                  t: 34,
                  s: [280.841, 86.179, 0],
                  e: [235.364, 120.044, 0],
                  to: [-29.071, 18.688, 0],
                  ti: [35.935, -17.308, 0],
                },
                {
                  i: { x: 0.675, y: 0.829 },
                  o: { x: 0.34, y: 0.163 },
                  t: 35,
                  s: [235.364, 120.044, 0],
                  e: [145.701, 161.17, 0],
                  to: [-33.538, 16.153, 0],
                  ti: [40.355, -10.71, 0],
                },
                {
                  i: { x: 0.685, y: 1 },
                  o: { x: 0.349, y: 0.165 },
                  t: 36,
                  s: [145.701, 161.17, 0],
                  e: [38.834, 164.99, 0],
                  to: [-37.423, 9.932, 0],
                  ti: [6.353, 13.673, 0],
                },
                {
                  i: { x: 0.178, y: 0.974 },
                  o: { x: 0.046, y: 0 },
                  t: 37,
                  s: [38.834, 164.99, 0],
                  e: [-302.629, -517.937, 0],
                  to: [-60.163, -129.472, 0],
                  ti: [130.678, -2.795, 0],
                },
                {
                  i: { x: 1, y: 1 },
                  o: { x: 0.704, y: 0.044 },
                  t: 44.059,
                  s: [-302.629, -517.937, 0],
                  e: [-483.117, -10.62, 0],
                  to: [-130.678, 2.795, 0],
                  ti: [2.439, -0.407, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 52,
                  s: [-483.117, -10.62, 0],
                  e: [-486.776, -10.62, 0],
                  to: [-2.439, 0.407, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 53,
                  s: [-486.776, -10.62, 0],
                  e: [-489.215, -5.742, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 54,
                  s: [-489.215, -5.742, 0],
                  e: [-485.556, 13.161, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 55,
                  s: [-485.556, 13.161, 0],
                  e: [-458.117, 45.478, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 56,
                  s: [-458.117, 45.478, 0],
                  e: [-413.605, 82.063, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 57,
                  s: [-413.605, 82.063, 0],
                  e: [-329.459, 132.673, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.685, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 58,
                  s: [-329.459, 132.673, 0],
                  e: [-252.02, 162.551, 0],
                  to: [0, 0, 0],
                  ti: [-65.434, 161.332, 0],
                },
                {
                  i: { x: 0.178, y: 0.974 },
                  o: { x: 0.046, y: 0 },
                  t: 59,
                  s: [-252.02, 162.551, 0],
                  e: [122.371, -515.498, 0],
                  to: [65.434, -161.332, 0],
                  ti: [-110.556, -0.864, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.704, y: 0.044 },
                  t: 66,
                  s: [122.371, -515.498, 0],
                  e: [370.541, -24.034, 0],
                  to: [110.556, 0.864, 0],
                  ti: [-37.398, -181.504, 0],
                },
                {
                  i: { x: 0.57, y: 0.541 },
                  o: { x: 0.167, y: 0.167 },
                  t: 74,
                  s: [370.541, -24.034, 0],
                  e: [361.988, 9.41, 0],
                  to: [12.889, 62.553, 0],
                  ti: [45.023, -42.894, 0],
                },
                {
                  i: { x: 0.653, y: 0.707 },
                  o: { x: 0.317, y: 0.244 },
                  t: 76,
                  s: [361.988, 9.41, 0],
                  e: [334.584, 41.108, 0],
                  to: [-19.86, 18.921, 0],
                  ti: [40.364, -56.19, 0],
                },
                {
                  i: { x: 0.662, y: 0.781 },
                  o: { x: 0.326, y: 0.193 },
                  t: 77,
                  s: [334.584, 41.108, 0],
                  e: [280.841, 86.179, 0],
                  to: [-12.979, 18.068, 0],
                  ti: [38.562, -24.79, 0],
                },
                {
                  i: { x: 0.668, y: 0.725 },
                  o: { x: 0.333, y: 0.267 },
                  t: 78,
                  s: [280.841, 86.179, 0],
                  e: [235.364, 120.044, 0],
                  to: [-29.071, 18.688, 0],
                  ti: [35.935, -17.308, 0],
                },
                {
                  i: { x: 0.675, y: 0.829 },
                  o: { x: 0.34, y: 0.163 },
                  t: 79,
                  s: [235.364, 120.044, 0],
                  e: [145.701, 161.17, 0],
                  to: [-33.538, 16.153, 0],
                  ti: [40.355, -10.71, 0],
                },
                {
                  i: { x: 0.685, y: 1 },
                  o: { x: 0.349, y: 0.165 },
                  t: 80,
                  s: [145.701, 161.17, 0],
                  e: [38.834, 164.99, 0],
                  to: [-37.423, 9.932, 0],
                  ti: [6.353, 13.673, 0],
                },
                {
                  i: { x: 0.178, y: 0.974 },
                  o: { x: 0.046, y: 0 },
                  t: 81,
                  s: [38.834, 164.99, 0],
                  e: [-302.629, -517.937, 0],
                  to: [-60.163, -129.472, 0],
                  ti: [130.678, -2.795, 0],
                },
                {
                  i: { x: 1, y: 1 },
                  o: { x: 0.704, y: 0.044 },
                  t: 88.059,
                  s: [-302.629, -517.937, 0],
                  e: [-483.117, -10.62, 0],
                  to: [-130.678, 2.795, 0],
                  ti: [2.439, -0.407, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 96,
                  s: [-483.117, -10.62, 0],
                  e: [-486.776, -10.62, 0],
                  to: [-2.439, 0.407, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 97,
                  s: [-486.776, -10.62, 0],
                  e: [-489.215, -5.742, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 98,
                  s: [-489.215, -5.742, 0],
                  e: [-485.556, 13.161, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 99,
                  s: [-485.556, 13.161, 0],
                  e: [-458.117, 45.478, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 100,
                  s: [-458.117, 45.478, 0],
                  e: [-413.605, 82.063, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 101,
                  s: [-413.605, 82.063, 0],
                  e: [-329.459, 132.673, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.685, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 102,
                  s: [-329.459, 132.673, 0],
                  e: [-252.02, 162.551, 0],
                  to: [0, 0, 0],
                  ti: [-65.434, 161.332, 0],
                },
                {
                  i: { x: 0.178, y: 0.974 },
                  o: { x: 0.046, y: 0 },
                  t: 103,
                  s: [-252.02, 162.551, 0],
                  e: [122.371, -515.498, 0],
                  to: [65.434, -161.332, 0],
                  ti: [-110.556, -0.864, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.704, y: 0.044 },
                  t: 110,
                  s: [122.371, -515.498, 0],
                  e: [370.541, -24.034, 0],
                  to: [110.556, 0.864, 0],
                  ti: [-37.398, -181.504, 0],
                },
                {
                  i: { x: 0.57, y: 0.541 },
                  o: { x: 0.167, y: 0.167 },
                  t: 118,
                  s: [370.541, -24.034, 0],
                  e: [361.988, 9.41, 0],
                  to: [12.889, 62.553, 0],
                  ti: [45.023, -42.894, 0],
                },
                {
                  i: { x: 0.653, y: 0.707 },
                  o: { x: 0.317, y: 0.244 },
                  t: 120,
                  s: [361.988, 9.41, 0],
                  e: [334.584, 41.108, 0],
                  to: [-19.86, 18.921, 0],
                  ti: [40.364, -56.19, 0],
                },
                {
                  i: { x: 0.662, y: 0.781 },
                  o: { x: 0.326, y: 0.193 },
                  t: 121,
                  s: [334.584, 41.108, 0],
                  e: [280.841, 86.179, 0],
                  to: [-12.979, 18.068, 0],
                  ti: [38.562, -24.79, 0],
                },
                {
                  i: { x: 0.668, y: 0.725 },
                  o: { x: 0.333, y: 0.267 },
                  t: 122,
                  s: [280.841, 86.179, 0],
                  e: [235.364, 120.044, 0],
                  to: [-29.071, 18.688, 0],
                  ti: [35.935, -17.308, 0],
                },
                {
                  i: { x: 0.675, y: 0.829 },
                  o: { x: 0.34, y: 0.163 },
                  t: 123,
                  s: [235.364, 120.044, 0],
                  e: [145.701, 161.17, 0],
                  to: [-33.538, 16.153, 0],
                  ti: [40.355, -10.71, 0],
                },
                {
                  i: { x: 0.685, y: 1 },
                  o: { x: 0.349, y: 0.165 },
                  t: 124,
                  s: [145.701, 161.17, 0],
                  e: [38.834, 164.99, 0],
                  to: [-37.423, 9.932, 0],
                  ti: [6.353, 13.673, 0],
                },
                {
                  i: { x: 0.178, y: 0.974 },
                  o: { x: 0.046, y: 0 },
                  t: 125,
                  s: [38.834, 164.99, 0],
                  e: [-302.629, -517.937, 0],
                  to: [-60.163, -129.472, 0],
                  ti: [130.678, -2.795, 0],
                },
                {
                  i: { x: 1, y: 1 },
                  o: { x: 0.704, y: 0.044 },
                  t: 132.059,
                  s: [-302.629, -517.937, 0],
                  e: [-483.117, -10.62, 0],
                  to: [-130.678, 2.795, 0],
                  ti: [2.439, -0.407, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 140,
                  s: [-483.117, -10.62, 0],
                  e: [-486.776, -10.62, 0],
                  to: [-2.439, 0.407, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 141,
                  s: [-486.776, -10.62, 0],
                  e: [-489.215, -5.742, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 142,
                  s: [-489.215, -5.742, 0],
                  e: [-485.556, 13.161, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 143,
                  s: [-485.556, 13.161, 0],
                  e: [-458.117, 45.478, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 144,
                  s: [-458.117, 45.478, 0],
                  e: [-413.605, 82.063, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 145,
                  s: [-413.605, 82.063, 0],
                  e: [-329.459, 132.673, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.685, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 146,
                  s: [-329.459, 132.673, 0],
                  e: [-252.02, 162.551, 0],
                  to: [0, 0, 0],
                  ti: [-65.434, 161.332, 0],
                },
                {
                  i: { x: 0.178, y: 0.974 },
                  o: { x: 0.046, y: 0 },
                  t: 147,
                  s: [-252.02, 162.551, 0],
                  e: [122.371, -515.498, 0],
                  to: [65.434, -161.332, 0],
                  ti: [-110.556, -0.864, 0],
                },
                {
                  i: { x: 0.999, y: 1 },
                  o: { x: 0.704, y: 0.044 },
                  t: 154,
                  s: [122.371, -515.498, 0],
                  e: [370.541, -24.034, 0],
                  to: [110.556, 0.864, 0],
                  ti: [-37.398, -181.504, 0],
                },
                { t: 162 },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [31.75, 32.75, 0], ix: 1, l: 2 },
            s: { a: 0, k: [489.084, 489.084, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 30,
          op: 132,
          st: 20,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: 'card_4',
          parent: 9,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [32.657, 32.106, 0], ix: 2, l: 2 },
            a: { a: 0, k: [35.824, 44.758, 0], ix: 1, l: 2 },
            s: { a: 0, k: [75.455, 75.455, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [2.642, -15.863],
                        [-7.934, 2.643],
                        [-0.001, 2.643],
                        [-2.643, 15.863],
                        [7.934, -2.643],
                        [-0.001, -2.643],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [35.769, 44.294], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -11.473],
                        [11.472, 0],
                        [0, 11.473],
                        [-11.473, 0],
                      ],
                      o: [
                        [0, 11.473],
                        [-11.473, 0],
                        [0, -11.473],
                        [11.472, 0],
                      ],
                      v: [
                        [20.773, 0],
                        [0.001, 20.773],
                        [-20.773, 0],
                        [0.001, -20.773],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.258999992819, 0.522000002394, 0.957000014361, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [35.76, 44.592], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -13.582],
                        [13.581, 0],
                        [0, 13.581],
                        [-13.581, 0],
                      ],
                      o: [
                        [0, 13.581],
                        [-13.581, 0],
                        [0, -13.582],
                        [13.581, 0],
                      ],
                      v: [
                        [24.591, 0],
                        [0, 24.591],
                        [-24.591, 0],
                        [0, -24.591],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [35.761, 44.592], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.471],
                        [1.471, 0],
                        [0, 1.471],
                        [-1.472, 0],
                      ],
                      o: [
                        [0, 1.471],
                        [-1.472, 0],
                        [0, -1.471],
                        [1.471, 0],
                      ],
                      v: [
                        [2.664, 0],
                        [0, 2.664],
                        [-2.664, 0],
                        [0, -2.664],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [56.907, 74.896], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.471],
                        [1.471, 0],
                        [0, 1.471],
                        [-1.472, 0],
                      ],
                      o: [
                        [0, 1.471],
                        [-1.472, 0],
                        [0, -1.471],
                        [1.471, 0],
                      ],
                      v: [
                        [2.664, 0],
                        [0, 2.664],
                        [-2.664, 0],
                        [0, -2.664],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [14.288, 74.896], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.471],
                        [1.471, 0],
                        [0, 1.471],
                        [-1.472, 0],
                      ],
                      o: [
                        [0, 1.471],
                        [-1.472, 0],
                        [0, -1.471],
                        [1.471, 0],
                      ],
                      v: [
                        [2.664, 0],
                        [0, 2.664],
                        [-2.664, 0],
                        [0, -2.664],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [56.907, 14.518], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.471],
                        [1.471, 0],
                        [0, 1.471],
                        [-1.472, 0],
                      ],
                      o: [
                        [0, 1.471],
                        [-1.472, 0],
                        [0, -1.471],
                        [1.471, 0],
                      ],
                      v: [
                        [2.664, 0],
                        [0, 2.664],
                        [-2.664, 0],
                        [0, -2.664],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [14.288, 14.518], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 7',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [5.07, 0],
                        [0, 0],
                        [0, 5.071],
                        [0, 0],
                        [-5.07, 0],
                        [0, 0],
                        [0, -5.07],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-5.07, 0],
                        [0, 0],
                        [0, -5.07],
                        [0, 0],
                        [5.07, 0],
                        [0, 0],
                        [0, 5.071],
                      ],
                      v: [
                        [22.064, 40.076],
                        [-22.065, 40.076],
                        [-31.245, 30.895],
                        [-31.245, -30.896],
                        [-22.065, -40.076],
                        [22.064, -40.076],
                        [31.245, -30.896],
                        [31.245, 30.895],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.969000004787, 0.57599995931, 0.102000000898, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [35.761, 44.592], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 8',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 8,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [7.674, 0],
                        [0, 0],
                        [0, 7.675],
                        [0, 0],
                        [-7.675, 0],
                        [0, 0],
                        [0, -7.674],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-7.675, 0],
                        [0, 0],
                        [0, -7.674],
                        [0, 0],
                        [7.674, 0],
                        [0, 0],
                        [0, 7.675],
                      ],
                      v: [
                        [21.615, 44.342],
                        [-21.615, 44.342],
                        [-35.511, 30.446],
                        [-35.511, -30.446],
                        [-21.615, -44.342],
                        [21.615, -44.342],
                        [35.511, -30.446],
                        [35.511, 30.446],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [35.761, 44.592], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 9',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 9,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 16,
          st: -117,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 3,
          nm: 'bitcoin 5',
          parent: 14,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: -117,
                  s: [0],
                  e: [0],
                },
                {
                  i: { x: [0.772], y: [1] },
                  o: { x: [0.522], y: [0] },
                  t: -110,
                  s: [0],
                  e: [180],
                },
                {
                  i: { x: [0.596], y: [1] },
                  o: { x: [0.307], y: [0] },
                  t: -95,
                  s: [180],
                  e: [180],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.58], y: [0] },
                  t: -88,
                  s: [180],
                  e: [0],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: -73,
                  s: [0],
                  e: [0],
                },
                {
                  i: { x: [0.772], y: [1] },
                  o: { x: [0.522], y: [0] },
                  t: -66,
                  s: [0],
                  e: [180],
                },
                {
                  i: { x: [0.596], y: [1] },
                  o: { x: [0.307], y: [0] },
                  t: -51,
                  s: [180],
                  e: [180],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.58], y: [0] },
                  t: -44,
                  s: [180],
                  e: [0],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: -29,
                  s: [0],
                  e: [0],
                },
                {
                  i: { x: [0.772], y: [1] },
                  o: { x: [0.522], y: [0] },
                  t: -22,
                  s: [0],
                  e: [180],
                },
                {
                  i: { x: [0.596], y: [1] },
                  o: { x: [0.307], y: [0] },
                  t: -7,
                  s: [180],
                  e: [180],
                },
                {
                  i: { x: [0.368], y: [1] },
                  o: { x: [0.58], y: [0] },
                  t: 0,
                  s: [180],
                  e: [0],
                },
                { t: 15 },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.57, y: 0.541 },
                  o: { x: 0.167, y: 0.167 },
                  t: -117,
                  s: [370.541, -24.034, 0],
                  e: [361.988, 9.41, 0],
                  to: [12.889, 62.553, 0],
                  ti: [45.023, -42.894, 0],
                },
                {
                  i: { x: 0.653, y: 0.707 },
                  o: { x: 0.317, y: 0.244 },
                  t: -115,
                  s: [361.988, 9.41, 0],
                  e: [334.584, 41.108, 0],
                  to: [-19.86, 18.921, 0],
                  ti: [40.364, -56.19, 0],
                },
                {
                  i: { x: 0.662, y: 0.781 },
                  o: { x: 0.326, y: 0.193 },
                  t: -114,
                  s: [334.584, 41.108, 0],
                  e: [280.841, 86.179, 0],
                  to: [-12.979, 18.068, 0],
                  ti: [38.562, -24.79, 0],
                },
                {
                  i: { x: 0.668, y: 0.725 },
                  o: { x: 0.333, y: 0.267 },
                  t: -113,
                  s: [280.841, 86.179, 0],
                  e: [235.364, 120.044, 0],
                  to: [-29.071, 18.688, 0],
                  ti: [35.935, -17.308, 0],
                },
                {
                  i: { x: 0.675, y: 0.829 },
                  o: { x: 0.34, y: 0.163 },
                  t: -112,
                  s: [235.364, 120.044, 0],
                  e: [145.701, 161.17, 0],
                  to: [-33.538, 16.153, 0],
                  ti: [40.355, -10.71, 0],
                },
                {
                  i: { x: 0.685, y: 1 },
                  o: { x: 0.349, y: 0.165 },
                  t: -111,
                  s: [145.701, 161.17, 0],
                  e: [38.834, 164.99, 0],
                  to: [-37.423, 9.932, 0],
                  ti: [6.353, 13.673, 0],
                },
                {
                  i: { x: 0.178, y: 0.974 },
                  o: { x: 0.046, y: 0 },
                  t: -110,
                  s: [38.834, 164.99, 0],
                  e: [-302.629, -517.937, 0],
                  to: [-60.163, -129.472, 0],
                  ti: [130.678, -2.795, 0],
                },
                {
                  i: { x: 1, y: 1 },
                  o: { x: 0.704, y: 0.044 },
                  t: -102.941,
                  s: [-302.629, -517.937, 0],
                  e: [-483.117, -10.62, 0],
                  to: [-130.678, 2.795, 0],
                  ti: [2.439, -0.407, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -95,
                  s: [-483.117, -10.62, 0],
                  e: [-486.776, -10.62, 0],
                  to: [-2.439, 0.407, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -94,
                  s: [-486.776, -10.62, 0],
                  e: [-489.215, -5.742, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -93,
                  s: [-489.215, -5.742, 0],
                  e: [-485.556, 13.161, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -92,
                  s: [-485.556, 13.161, 0],
                  e: [-458.117, 45.478, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -91,
                  s: [-458.117, 45.478, 0],
                  e: [-413.605, 82.063, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -90,
                  s: [-413.605, 82.063, 0],
                  e: [-329.459, 132.673, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.685, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: -89,
                  s: [-329.459, 132.673, 0],
                  e: [-252.02, 162.551, 0],
                  to: [0, 0, 0],
                  ti: [-65.434, 161.332, 0],
                },
                {
                  i: { x: 0.178, y: 0.974 },
                  o: { x: 0.046, y: 0 },
                  t: -88,
                  s: [-252.02, 162.551, 0],
                  e: [122.371, -515.498, 0],
                  to: [65.434, -161.332, 0],
                  ti: [-110.556, -0.864, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.704, y: 0.044 },
                  t: -81,
                  s: [122.371, -515.498, 0],
                  e: [370.541, -24.034, 0],
                  to: [110.556, 0.864, 0],
                  ti: [-37.398, -181.504, 0],
                },
                {
                  i: { x: 0.57, y: 0.541 },
                  o: { x: 0.167, y: 0.167 },
                  t: -73,
                  s: [370.541, -24.034, 0],
                  e: [361.988, 9.41, 0],
                  to: [12.889, 62.553, 0],
                  ti: [45.023, -42.894, 0],
                },
                {
                  i: { x: 0.653, y: 0.707 },
                  o: { x: 0.317, y: 0.244 },
                  t: -71,
                  s: [361.988, 9.41, 0],
                  e: [334.584, 41.108, 0],
                  to: [-19.86, 18.921, 0],
                  ti: [40.364, -56.19, 0],
                },
                {
                  i: { x: 0.662, y: 0.781 },
                  o: { x: 0.326, y: 0.193 },
                  t: -70,
                  s: [334.584, 41.108, 0],
                  e: [280.841, 86.179, 0],
                  to: [-12.979, 18.068, 0],
                  ti: [38.562, -24.79, 0],
                },
                {
                  i: { x: 0.668, y: 0.725 },
                  o: { x: 0.333, y: 0.267 },
                  t: -69,
                  s: [280.841, 86.179, 0],
                  e: [235.364, 120.044, 0],
                  to: [-29.071, 18.688, 0],
                  ti: [35.935, -17.308, 0],
                },
                {
                  i: { x: 0.675, y: 0.829 },
                  o: { x: 0.34, y: 0.163 },
                  t: -68,
                  s: [235.364, 120.044, 0],
                  e: [145.701, 161.17, 0],
                  to: [-33.538, 16.153, 0],
                  ti: [40.355, -10.71, 0],
                },
                {
                  i: { x: 0.685, y: 1 },
                  o: { x: 0.349, y: 0.165 },
                  t: -67,
                  s: [145.701, 161.17, 0],
                  e: [38.834, 164.99, 0],
                  to: [-37.423, 9.932, 0],
                  ti: [6.353, 13.673, 0],
                },
                {
                  i: { x: 0.178, y: 0.974 },
                  o: { x: 0.046, y: 0 },
                  t: -66,
                  s: [38.834, 164.99, 0],
                  e: [-302.629, -517.937, 0],
                  to: [-60.163, -129.472, 0],
                  ti: [130.678, -2.795, 0],
                },
                {
                  i: { x: 1, y: 1 },
                  o: { x: 0.704, y: 0.044 },
                  t: -58.941,
                  s: [-302.629, -517.937, 0],
                  e: [-483.117, -10.62, 0],
                  to: [-130.678, 2.795, 0],
                  ti: [2.439, -0.407, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -51,
                  s: [-483.117, -10.62, 0],
                  e: [-486.776, -10.62, 0],
                  to: [-2.439, 0.407, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -50,
                  s: [-486.776, -10.62, 0],
                  e: [-489.215, -5.742, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -49,
                  s: [-489.215, -5.742, 0],
                  e: [-485.556, 13.161, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -48,
                  s: [-485.556, 13.161, 0],
                  e: [-458.117, 45.478, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -47,
                  s: [-458.117, 45.478, 0],
                  e: [-413.605, 82.063, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -46,
                  s: [-413.605, 82.063, 0],
                  e: [-329.459, 132.673, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.685, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: -45,
                  s: [-329.459, 132.673, 0],
                  e: [-252.02, 162.551, 0],
                  to: [0, 0, 0],
                  ti: [-65.434, 161.332, 0],
                },
                {
                  i: { x: 0.178, y: 0.974 },
                  o: { x: 0.046, y: 0 },
                  t: -44,
                  s: [-252.02, 162.551, 0],
                  e: [122.371, -515.498, 0],
                  to: [65.434, -161.332, 0],
                  ti: [-110.556, -0.864, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.704, y: 0.044 },
                  t: -37,
                  s: [122.371, -515.498, 0],
                  e: [370.541, -24.034, 0],
                  to: [110.556, 0.864, 0],
                  ti: [-37.398, -181.504, 0],
                },
                {
                  i: { x: 0.57, y: 0.541 },
                  o: { x: 0.167, y: 0.167 },
                  t: -29,
                  s: [370.541, -24.034, 0],
                  e: [361.988, 9.41, 0],
                  to: [12.889, 62.553, 0],
                  ti: [45.023, -42.894, 0],
                },
                {
                  i: { x: 0.653, y: 0.707 },
                  o: { x: 0.317, y: 0.244 },
                  t: -27,
                  s: [361.988, 9.41, 0],
                  e: [334.584, 41.108, 0],
                  to: [-19.86, 18.921, 0],
                  ti: [40.364, -56.19, 0],
                },
                {
                  i: { x: 0.662, y: 0.781 },
                  o: { x: 0.326, y: 0.193 },
                  t: -26,
                  s: [334.584, 41.108, 0],
                  e: [280.841, 86.179, 0],
                  to: [-12.979, 18.068, 0],
                  ti: [38.562, -24.79, 0],
                },
                {
                  i: { x: 0.668, y: 0.725 },
                  o: { x: 0.333, y: 0.267 },
                  t: -25,
                  s: [280.841, 86.179, 0],
                  e: [235.364, 120.044, 0],
                  to: [-29.071, 18.688, 0],
                  ti: [35.935, -17.308, 0],
                },
                {
                  i: { x: 0.675, y: 0.829 },
                  o: { x: 0.34, y: 0.163 },
                  t: -24,
                  s: [235.364, 120.044, 0],
                  e: [145.701, 161.17, 0],
                  to: [-33.538, 16.153, 0],
                  ti: [40.355, -10.71, 0],
                },
                {
                  i: { x: 0.685, y: 1 },
                  o: { x: 0.349, y: 0.165 },
                  t: -23,
                  s: [145.701, 161.17, 0],
                  e: [38.834, 164.99, 0],
                  to: [-37.423, 9.932, 0],
                  ti: [6.353, 13.673, 0],
                },
                {
                  i: { x: 0.178, y: 0.974 },
                  o: { x: 0.046, y: 0 },
                  t: -22,
                  s: [38.834, 164.99, 0],
                  e: [-302.629, -517.937, 0],
                  to: [-60.163, -129.472, 0],
                  ti: [130.678, -2.795, 0],
                },
                {
                  i: { x: 1, y: 1 },
                  o: { x: 0.704, y: 0.044 },
                  t: -14.941,
                  s: [-302.629, -517.937, 0],
                  e: [-483.117, -10.62, 0],
                  to: [-130.678, 2.795, 0],
                  ti: [2.439, -0.407, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -7,
                  s: [-483.117, -10.62, 0],
                  e: [-486.776, -10.62, 0],
                  to: [-2.439, 0.407, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -6,
                  s: [-486.776, -10.62, 0],
                  e: [-489.215, -5.742, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -5,
                  s: [-489.215, -5.742, 0],
                  e: [-485.556, 13.161, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -4,
                  s: [-485.556, 13.161, 0],
                  e: [-458.117, 45.478, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -3,
                  s: [-458.117, 45.478, 0],
                  e: [-413.605, 82.063, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -2,
                  s: [-413.605, 82.063, 0],
                  e: [-329.459, 132.673, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.685, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: -1,
                  s: [-329.459, 132.673, 0],
                  e: [-252.02, 162.551, 0],
                  to: [0, 0, 0],
                  ti: [-65.434, 161.332, 0],
                },
                {
                  i: { x: 0.178, y: 0.974 },
                  o: { x: 0.046, y: 0 },
                  t: 0,
                  s: [-252.02, 162.551, 0],
                  e: [122.371, -515.498, 0],
                  to: [65.434, -161.332, 0],
                  ti: [-110.556, -0.864, 0],
                },
                {
                  i: { x: 0.999, y: 1 },
                  o: { x: 0.704, y: 0.044 },
                  t: 7,
                  s: [122.371, -515.498, 0],
                  e: [370.541, -24.034, 0],
                  to: [110.556, 0.864, 0],
                  ti: [-37.398, -181.504, 0],
                },
                { t: 15 },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [31.75, 32.75, 0], ix: 1, l: 2 },
            s: { a: 0, k: [489.084, 489.084, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 0,
          op: 16,
          st: -127,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: 'card_2',
          parent: 11,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [32.657, 32.106, 0], ix: 2, l: 2 },
            a: { a: 0, k: [35.824, 44.758, 0], ix: 1, l: 2 },
            s: { a: 0, k: [75.455, 75.455, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [2.642, -15.863],
                        [-7.934, 2.643],
                        [-0.001, 2.643],
                        [-2.643, 15.863],
                        [7.934, -2.643],
                        [-0.001, -2.643],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [35.769, 44.294], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -11.473],
                        [11.472, 0],
                        [0, 11.473],
                        [-11.473, 0],
                      ],
                      o: [
                        [0, 11.473],
                        [-11.473, 0],
                        [0, -11.473],
                        [11.472, 0],
                      ],
                      v: [
                        [20.773, 0],
                        [0.001, 20.773],
                        [-20.773, 0],
                        [0.001, -20.773],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.258999992819, 0.522000002394, 0.957000014361, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [35.76, 44.592], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -13.582],
                        [13.581, 0],
                        [0, 13.581],
                        [-13.581, 0],
                      ],
                      o: [
                        [0, 13.581],
                        [-13.581, 0],
                        [0, -13.582],
                        [13.581, 0],
                      ],
                      v: [
                        [24.591, 0],
                        [0, 24.591],
                        [-24.591, 0],
                        [0, -24.591],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [35.761, 44.592], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.471],
                        [1.471, 0],
                        [0, 1.471],
                        [-1.472, 0],
                      ],
                      o: [
                        [0, 1.471],
                        [-1.472, 0],
                        [0, -1.471],
                        [1.471, 0],
                      ],
                      v: [
                        [2.664, 0],
                        [0, 2.664],
                        [-2.664, 0],
                        [0, -2.664],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [56.907, 74.896], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.471],
                        [1.471, 0],
                        [0, 1.471],
                        [-1.472, 0],
                      ],
                      o: [
                        [0, 1.471],
                        [-1.472, 0],
                        [0, -1.471],
                        [1.471, 0],
                      ],
                      v: [
                        [2.664, 0],
                        [0, 2.664],
                        [-2.664, 0],
                        [0, -2.664],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [14.288, 74.896], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.471],
                        [1.471, 0],
                        [0, 1.471],
                        [-1.472, 0],
                      ],
                      o: [
                        [0, 1.471],
                        [-1.472, 0],
                        [0, -1.471],
                        [1.471, 0],
                      ],
                      v: [
                        [2.664, 0],
                        [0, 2.664],
                        [-2.664, 0],
                        [0, -2.664],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [56.907, 14.518], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.471],
                        [1.471, 0],
                        [0, 1.471],
                        [-1.472, 0],
                      ],
                      o: [
                        [0, 1.471],
                        [-1.472, 0],
                        [0, -1.471],
                        [1.471, 0],
                      ],
                      v: [
                        [2.664, 0],
                        [0, 2.664],
                        [-2.664, 0],
                        [0, -2.664],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [14.288, 14.518], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 7',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [5.07, 0],
                        [0, 0],
                        [0, 5.071],
                        [0, 0],
                        [-5.07, 0],
                        [0, 0],
                        [0, -5.07],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-5.07, 0],
                        [0, 0],
                        [0, -5.07],
                        [0, 0],
                        [5.07, 0],
                        [0, 0],
                        [0, 5.071],
                      ],
                      v: [
                        [22.064, 40.076],
                        [-22.065, 40.076],
                        [-31.245, 30.895],
                        [-31.245, -30.896],
                        [-22.065, -40.076],
                        [22.064, -40.076],
                        [31.245, -30.896],
                        [31.245, 30.895],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.969000004787, 0.57599995931, 0.102000000898, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [35.761, 44.592], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 8',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 8,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [7.674, 0],
                        [0, 0],
                        [0, 7.675],
                        [0, 0],
                        [-7.675, 0],
                        [0, 0],
                        [0, -7.674],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-7.675, 0],
                        [0, 0],
                        [0, -7.674],
                        [0, 0],
                        [7.674, 0],
                        [0, 0],
                        [0, 7.675],
                      ],
                      v: [
                        [21.615, 44.342],
                        [-21.615, 44.342],
                        [-35.511, 30.446],
                        [-35.511, -30.446],
                        [-21.615, -44.342],
                        [21.615, -44.342],
                        [35.511, -30.446],
                        [35.511, 30.446],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [35.761, 44.592], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 9',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 9,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 15,
          op: 132,
          st: 15,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 3,
          nm: 'bitcoin 3',
          parent: 14,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: 15,
                  s: [0],
                  e: [0],
                },
                {
                  i: { x: [0.772], y: [1] },
                  o: { x: [0.522], y: [0] },
                  t: 22,
                  s: [0],
                  e: [180],
                },
                {
                  i: { x: [0.596], y: [1] },
                  o: { x: [0.307], y: [0] },
                  t: 37,
                  s: [180],
                  e: [180],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.58], y: [0] },
                  t: 44,
                  s: [180],
                  e: [0],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: 59,
                  s: [0],
                  e: [0],
                },
                {
                  i: { x: [0.772], y: [1] },
                  o: { x: [0.522], y: [0] },
                  t: 66,
                  s: [0],
                  e: [180],
                },
                {
                  i: { x: [0.596], y: [1] },
                  o: { x: [0.307], y: [0] },
                  t: 81,
                  s: [180],
                  e: [180],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.58], y: [0] },
                  t: 88,
                  s: [180],
                  e: [0],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: 103,
                  s: [0],
                  e: [0],
                },
                {
                  i: { x: [0.772], y: [1] },
                  o: { x: [0.522], y: [0] },
                  t: 110,
                  s: [0],
                  e: [180],
                },
                {
                  i: { x: [0.596], y: [1] },
                  o: { x: [0.307], y: [0] },
                  t: 125,
                  s: [180],
                  e: [180],
                },
                {
                  i: { x: [0.368], y: [1] },
                  o: { x: [0.58], y: [0] },
                  t: 132,
                  s: [180],
                  e: [0],
                },
                { t: 147 },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.57, y: 0.541 },
                  o: { x: 0.167, y: 0.167 },
                  t: 15,
                  s: [370.541, -24.034, 0],
                  e: [361.988, 9.41, 0],
                  to: [12.889, 62.553, 0],
                  ti: [45.023, -42.894, 0],
                },
                {
                  i: { x: 0.653, y: 0.707 },
                  o: { x: 0.317, y: 0.244 },
                  t: 17,
                  s: [361.988, 9.41, 0],
                  e: [334.584, 41.108, 0],
                  to: [-19.86, 18.921, 0],
                  ti: [40.364, -56.19, 0],
                },
                {
                  i: { x: 0.662, y: 0.781 },
                  o: { x: 0.326, y: 0.193 },
                  t: 18,
                  s: [334.584, 41.108, 0],
                  e: [280.841, 86.179, 0],
                  to: [-12.979, 18.068, 0],
                  ti: [38.562, -24.79, 0],
                },
                {
                  i: { x: 0.668, y: 0.725 },
                  o: { x: 0.333, y: 0.267 },
                  t: 19,
                  s: [280.841, 86.179, 0],
                  e: [235.364, 120.044, 0],
                  to: [-29.071, 18.688, 0],
                  ti: [35.935, -17.308, 0],
                },
                {
                  i: { x: 0.675, y: 0.829 },
                  o: { x: 0.34, y: 0.163 },
                  t: 20,
                  s: [235.364, 120.044, 0],
                  e: [145.701, 161.17, 0],
                  to: [-33.538, 16.153, 0],
                  ti: [40.355, -10.71, 0],
                },
                {
                  i: { x: 0.685, y: 1 },
                  o: { x: 0.349, y: 0.165 },
                  t: 21,
                  s: [145.701, 161.17, 0],
                  e: [38.834, 164.99, 0],
                  to: [-37.423, 9.932, 0],
                  ti: [6.353, 13.673, 0],
                },
                {
                  i: { x: 0.178, y: 0.974 },
                  o: { x: 0.046, y: 0 },
                  t: 22,
                  s: [38.834, 164.99, 0],
                  e: [-302.629, -517.937, 0],
                  to: [-60.163, -129.472, 0],
                  ti: [130.678, -2.795, 0],
                },
                {
                  i: { x: 1, y: 1 },
                  o: { x: 0.704, y: 0.044 },
                  t: 29.059,
                  s: [-302.629, -517.937, 0],
                  e: [-483.117, -10.62, 0],
                  to: [-130.678, 2.795, 0],
                  ti: [2.439, -0.407, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 37,
                  s: [-483.117, -10.62, 0],
                  e: [-486.776, -10.62, 0],
                  to: [-2.439, 0.407, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 38,
                  s: [-486.776, -10.62, 0],
                  e: [-489.215, -5.742, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 39,
                  s: [-489.215, -5.742, 0],
                  e: [-485.556, 13.161, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 40,
                  s: [-485.556, 13.161, 0],
                  e: [-458.117, 45.478, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 41,
                  s: [-458.117, 45.478, 0],
                  e: [-413.605, 82.063, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 42,
                  s: [-413.605, 82.063, 0],
                  e: [-329.459, 132.673, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.685, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 43,
                  s: [-329.459, 132.673, 0],
                  e: [-252.02, 162.551, 0],
                  to: [0, 0, 0],
                  ti: [-65.434, 161.332, 0],
                },
                {
                  i: { x: 0.178, y: 0.974 },
                  o: { x: 0.046, y: 0 },
                  t: 44,
                  s: [-252.02, 162.551, 0],
                  e: [122.371, -515.498, 0],
                  to: [65.434, -161.332, 0],
                  ti: [-110.556, -0.864, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.704, y: 0.044 },
                  t: 51,
                  s: [122.371, -515.498, 0],
                  e: [370.541, -24.034, 0],
                  to: [110.556, 0.864, 0],
                  ti: [-37.398, -181.504, 0],
                },
                {
                  i: { x: 0.57, y: 0.541 },
                  o: { x: 0.167, y: 0.167 },
                  t: 59,
                  s: [370.541, -24.034, 0],
                  e: [361.988, 9.41, 0],
                  to: [12.889, 62.553, 0],
                  ti: [45.023, -42.894, 0],
                },
                {
                  i: { x: 0.653, y: 0.707 },
                  o: { x: 0.317, y: 0.244 },
                  t: 61,
                  s: [361.988, 9.41, 0],
                  e: [334.584, 41.108, 0],
                  to: [-19.86, 18.921, 0],
                  ti: [40.364, -56.19, 0],
                },
                {
                  i: { x: 0.662, y: 0.781 },
                  o: { x: 0.326, y: 0.193 },
                  t: 62,
                  s: [334.584, 41.108, 0],
                  e: [280.841, 86.179, 0],
                  to: [-12.979, 18.068, 0],
                  ti: [38.562, -24.79, 0],
                },
                {
                  i: { x: 0.668, y: 0.725 },
                  o: { x: 0.333, y: 0.267 },
                  t: 63,
                  s: [280.841, 86.179, 0],
                  e: [235.364, 120.044, 0],
                  to: [-29.071, 18.688, 0],
                  ti: [35.935, -17.308, 0],
                },
                {
                  i: { x: 0.675, y: 0.829 },
                  o: { x: 0.34, y: 0.163 },
                  t: 64,
                  s: [235.364, 120.044, 0],
                  e: [145.701, 161.17, 0],
                  to: [-33.538, 16.153, 0],
                  ti: [40.355, -10.71, 0],
                },
                {
                  i: { x: 0.685, y: 1 },
                  o: { x: 0.349, y: 0.165 },
                  t: 65,
                  s: [145.701, 161.17, 0],
                  e: [38.834, 164.99, 0],
                  to: [-37.423, 9.932, 0],
                  ti: [6.353, 13.673, 0],
                },
                {
                  i: { x: 0.178, y: 0.974 },
                  o: { x: 0.046, y: 0 },
                  t: 66,
                  s: [38.834, 164.99, 0],
                  e: [-302.629, -517.937, 0],
                  to: [-60.163, -129.472, 0],
                  ti: [130.678, -2.795, 0],
                },
                {
                  i: { x: 1, y: 1 },
                  o: { x: 0.704, y: 0.044 },
                  t: 73.059,
                  s: [-302.629, -517.937, 0],
                  e: [-483.117, -10.62, 0],
                  to: [-130.678, 2.795, 0],
                  ti: [2.439, -0.407, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 81,
                  s: [-483.117, -10.62, 0],
                  e: [-486.776, -10.62, 0],
                  to: [-2.439, 0.407, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 82,
                  s: [-486.776, -10.62, 0],
                  e: [-489.215, -5.742, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 83,
                  s: [-489.215, -5.742, 0],
                  e: [-485.556, 13.161, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 84,
                  s: [-485.556, 13.161, 0],
                  e: [-458.117, 45.478, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 85,
                  s: [-458.117, 45.478, 0],
                  e: [-413.605, 82.063, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 86,
                  s: [-413.605, 82.063, 0],
                  e: [-329.459, 132.673, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.685, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 87,
                  s: [-329.459, 132.673, 0],
                  e: [-252.02, 162.551, 0],
                  to: [0, 0, 0],
                  ti: [-65.434, 161.332, 0],
                },
                {
                  i: { x: 0.178, y: 0.974 },
                  o: { x: 0.046, y: 0 },
                  t: 88,
                  s: [-252.02, 162.551, 0],
                  e: [122.371, -515.498, 0],
                  to: [65.434, -161.332, 0],
                  ti: [-110.556, -0.864, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.704, y: 0.044 },
                  t: 95,
                  s: [122.371, -515.498, 0],
                  e: [370.541, -24.034, 0],
                  to: [110.556, 0.864, 0],
                  ti: [-37.398, -181.504, 0],
                },
                {
                  i: { x: 0.57, y: 0.541 },
                  o: { x: 0.167, y: 0.167 },
                  t: 103,
                  s: [370.541, -24.034, 0],
                  e: [361.988, 9.41, 0],
                  to: [12.889, 62.553, 0],
                  ti: [45.023, -42.894, 0],
                },
                {
                  i: { x: 0.653, y: 0.707 },
                  o: { x: 0.317, y: 0.244 },
                  t: 105,
                  s: [361.988, 9.41, 0],
                  e: [334.584, 41.108, 0],
                  to: [-19.86, 18.921, 0],
                  ti: [40.364, -56.19, 0],
                },
                {
                  i: { x: 0.662, y: 0.781 },
                  o: { x: 0.326, y: 0.193 },
                  t: 106,
                  s: [334.584, 41.108, 0],
                  e: [280.841, 86.179, 0],
                  to: [-12.979, 18.068, 0],
                  ti: [38.562, -24.79, 0],
                },
                {
                  i: { x: 0.668, y: 0.725 },
                  o: { x: 0.333, y: 0.267 },
                  t: 107,
                  s: [280.841, 86.179, 0],
                  e: [235.364, 120.044, 0],
                  to: [-29.071, 18.688, 0],
                  ti: [35.935, -17.308, 0],
                },
                {
                  i: { x: 0.675, y: 0.829 },
                  o: { x: 0.34, y: 0.163 },
                  t: 108,
                  s: [235.364, 120.044, 0],
                  e: [145.701, 161.17, 0],
                  to: [-33.538, 16.153, 0],
                  ti: [40.355, -10.71, 0],
                },
                {
                  i: { x: 0.685, y: 1 },
                  o: { x: 0.349, y: 0.165 },
                  t: 109,
                  s: [145.701, 161.17, 0],
                  e: [38.834, 164.99, 0],
                  to: [-37.423, 9.932, 0],
                  ti: [6.353, 13.673, 0],
                },
                {
                  i: { x: 0.178, y: 0.974 },
                  o: { x: 0.046, y: 0 },
                  t: 110,
                  s: [38.834, 164.99, 0],
                  e: [-302.629, -517.937, 0],
                  to: [-60.163, -129.472, 0],
                  ti: [130.678, -2.795, 0],
                },
                {
                  i: { x: 1, y: 1 },
                  o: { x: 0.704, y: 0.044 },
                  t: 117.059,
                  s: [-302.629, -517.937, 0],
                  e: [-483.117, -10.62, 0],
                  to: [-130.678, 2.795, 0],
                  ti: [2.439, -0.407, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 125,
                  s: [-483.117, -10.62, 0],
                  e: [-486.776, -10.62, 0],
                  to: [-2.439, 0.407, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 126,
                  s: [-486.776, -10.62, 0],
                  e: [-489.215, -5.742, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 127,
                  s: [-489.215, -5.742, 0],
                  e: [-485.556, 13.161, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 128,
                  s: [-485.556, 13.161, 0],
                  e: [-458.117, 45.478, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 129,
                  s: [-458.117, 45.478, 0],
                  e: [-413.605, 82.063, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 130,
                  s: [-413.605, 82.063, 0],
                  e: [-329.459, 132.673, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.685, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 131,
                  s: [-329.459, 132.673, 0],
                  e: [-252.02, 162.551, 0],
                  to: [0, 0, 0],
                  ti: [-65.434, 161.332, 0],
                },
                {
                  i: { x: 0.178, y: 0.974 },
                  o: { x: 0.046, y: 0 },
                  t: 132,
                  s: [-252.02, 162.551, 0],
                  e: [122.371, -515.498, 0],
                  to: [65.434, -161.332, 0],
                  ti: [-110.556, -0.864, 0],
                },
                {
                  i: { x: 0.999, y: 1 },
                  o: { x: 0.704, y: 0.044 },
                  t: 139,
                  s: [122.371, -515.498, 0],
                  e: [370.541, -24.034, 0],
                  to: [110.556, 0.864, 0],
                  ti: [-37.398, -181.504, 0],
                },
                { t: 147 },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [31.75, 32.75, 0], ix: 1, l: 2 },
            s: { a: 0, k: [489.084, 489.084, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 15,
          op: 132,
          st: 5,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 12,
          ty: 4,
          nm: 'card_1',
          parent: 13,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [32.859, 31.98, 0], ix: 2, l: 2 },
            a: { a: 0, k: [35.902, 44.86, 0], ix: 1, l: 2 },
            s: { a: 0, k: [75.241, 75.241, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.748, 0],
                        [0, 0.852],
                        [0.749, 0],
                        [0, -0.853],
                      ],
                      o: [
                        [0.749, 0],
                        [0, -0.853],
                        [-0.748, 0],
                        [0, 0.852],
                      ],
                      v: [
                        [0, 1.542],
                        [1.355, 0.001],
                        [0, -1.542],
                        [-1.355, 0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [41.604, 49.458], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.859, 0],
                        [0, 2.117],
                        [1.858, 0],
                        [0, -2.117],
                      ],
                      o: [
                        [1.858, 0],
                        [0, -2.117],
                        [-1.859, 0],
                        [0, 2.117],
                      ],
                      v: [
                        [0.001, 3.833],
                        [3.366, 0.001],
                        [0.001, -3.833],
                        [-3.366, 0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [30.727, 44.846], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-3.494, 0],
                        [0, 3.494],
                        [3.494, 0],
                        [0, -3.494],
                      ],
                      o: [
                        [3.494, 0],
                        [0, -3.494],
                        [-3.494, 0],
                        [0, 3.494],
                      ],
                      v: [
                        [0, 6.326],
                        [6.327, -0.001],
                        [0, -6.326],
                        [-6.327, -0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.969000004787, 0.57599995931, 0.102000000898, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [35.558, 11.981], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [35.761, 9.965],
                        [35.761, 26.162],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.219999994016, 0.219999994016, 0.219999994016, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2.757, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-4.854, 0],
                        [0, 1.712],
                        [4.854, 0],
                        [0, -1.712],
                      ],
                      o: [
                        [4.854, 0],
                        [0, -1.712],
                        [-4.854, 0],
                        [0, 1.712],
                      ],
                      v: [
                        [0.001, 3.101],
                        [8.788, 0],
                        [0.001, -3.101],
                        [-8.788, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.20800000359, 0.20800000359, 0.20800000359, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [35.76, 26.163], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-18.559, -14.725],
                        [1.278, 2.229],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-0.359, -2.213],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-17.569, 6.217],
                        [17.569, 8.041],
                        [13.268, -0.748],
                        [5.197, -8.041],
                        [-1.255, -7.325],
                        [-10.044, -4.208],
                        [-13.44, -2.136],
                        [-15.014, 3.631],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.969000004787, 0.57599995931, 0.102000000898, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [37.015, 33.471], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-3.167, 0],
                        [0, 5.439],
                        [3.167, 0],
                        [0, -5.439],
                      ],
                      o: [
                        [3.167, 0],
                        [0, -5.439],
                        [-3.167, 0],
                        [0, 5.439],
                      ],
                      v: [
                        [0, 9.848],
                        [5.734, 0],
                        [0, -9.848],
                        [-5.734, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.20800000359, 0.20800000359, 0.20800000359, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [36.025, 45.345], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 7',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-6.334, 0],
                        [0, 9.131],
                        [6.334, 0],
                        [0, -9.132],
                      ],
                      o: [
                        [6.334, 0],
                        [0, -9.132],
                        [-6.334, 0],
                        [0, 9.131],
                      ],
                      v: [
                        [0, 16.533],
                        [11.469, 0],
                        [0, -16.533],
                        [-11.469, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [36.025, 43.319], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 8',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 8,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-10.921, 0],
                        [0, 10.921],
                        [10.921, 0],
                        [0, -10.921],
                      ],
                      o: [
                        [10.921, 0],
                        [0, -10.921],
                        [-10.921, 0],
                        [0, 10.921],
                      ],
                      v: [
                        [0, 19.774],
                        [19.774, -0.001],
                        [0, -19.774],
                        [-19.774, -0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.969000004787, 0.57599995931, 0.102000000898, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [35.761, 44.145], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 9',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 9,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -11.473],
                        [11.472, 0],
                        [0, 11.473],
                        [-11.473, 0],
                      ],
                      o: [
                        [0, 11.473],
                        [-11.473, 0],
                        [0, -11.473],
                        [11.472, 0],
                      ],
                      v: [
                        [20.773, 0],
                        [0.001, 20.773],
                        [-20.773, 0],
                        [0.001, -20.773],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [35.76, 44.592], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 10',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 10,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -13.582],
                        [13.581, 0],
                        [0, 13.581],
                        [-13.581, 0],
                      ],
                      o: [
                        [0, 13.581],
                        [-13.581, 0],
                        [0, -13.582],
                        [13.581, 0],
                      ],
                      v: [
                        [24.591, 0],
                        [0, 24.591],
                        [-24.591, 0],
                        [0, -24.591],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [35.761, 44.592], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 11',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 11,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.471],
                        [1.471, 0],
                        [0, 1.471],
                        [-1.472, 0],
                      ],
                      o: [
                        [0, 1.471],
                        [-1.472, 0],
                        [0, -1.471],
                        [1.471, 0],
                      ],
                      v: [
                        [2.664, 0],
                        [0, 2.664],
                        [-2.664, 0],
                        [0, -2.664],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [56.907, 74.896], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 12',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 12,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.471],
                        [1.471, 0],
                        [0, 1.471],
                        [-1.472, 0],
                      ],
                      o: [
                        [0, 1.471],
                        [-1.472, 0],
                        [0, -1.471],
                        [1.471, 0],
                      ],
                      v: [
                        [2.664, 0],
                        [0, 2.664],
                        [-2.664, 0],
                        [0, -2.664],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [14.288, 74.896], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 13',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 13,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.471],
                        [1.471, 0],
                        [0, 1.471],
                        [-1.472, 0],
                      ],
                      o: [
                        [0, 1.471],
                        [-1.472, 0],
                        [0, -1.471],
                        [1.471, 0],
                      ],
                      v: [
                        [2.664, 0],
                        [0, 2.664],
                        [-2.664, 0],
                        [0, -2.664],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [56.907, 14.518], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 14',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 14,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.471],
                        [1.471, 0],
                        [0, 1.471],
                        [-1.472, 0],
                      ],
                      o: [
                        [0, 1.471],
                        [-1.472, 0],
                        [0, -1.471],
                        [1.471, 0],
                      ],
                      v: [
                        [2.664, 0],
                        [0, 2.664],
                        [-2.664, 0],
                        [0, -2.664],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [14.288, 14.518], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 15',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 15,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [5.07, 0],
                        [0, 0],
                        [0, 5.071],
                        [0, 0],
                        [-5.07, 0],
                        [0, 0],
                        [0, -5.07],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-5.07, 0],
                        [0, 0],
                        [0, -5.07],
                        [0, 0],
                        [5.07, 0],
                        [0, 0],
                        [0, 5.071],
                      ],
                      v: [
                        [22.064, 40.076],
                        [-22.065, 40.076],
                        [-31.245, 30.895],
                        [-31.245, -30.896],
                        [-22.065, -40.076],
                        [22.064, -40.076],
                        [31.245, -30.896],
                        [31.245, 30.895],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.258999992819, 0.522000002394, 0.957000014361, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [35.761, 44.592], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 16',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 16,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [7.674, 0],
                        [0, 0],
                        [0, 7.675],
                        [0, 0],
                        [-7.675, 0],
                        [0, 0],
                        [0, -7.674],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-7.675, 0],
                        [0, 0],
                        [0, -7.674],
                        [0, 0],
                        [7.674, 0],
                        [0, 0],
                        [0, 7.675],
                      ],
                      v: [
                        [21.615, 44.342],
                        [-21.615, 44.342],
                        [-35.511, 30.446],
                        [-35.511, -30.446],
                        [-21.615, -44.342],
                        [21.615, -44.342],
                        [35.511, -30.446],
                        [35.511, 30.446],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [35.761, 44.592], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 17',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 17,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 13,
          ty: 3,
          nm: 'bitcoin 2',
          parent: 14,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: 0,
                  s: [0],
                  e: [0],
                },
                {
                  i: { x: [0.772], y: [1] },
                  o: { x: [0.522], y: [0] },
                  t: 7,
                  s: [0],
                  e: [180],
                },
                {
                  i: { x: [0.596], y: [1] },
                  o: { x: [0.307], y: [0] },
                  t: 22,
                  s: [180],
                  e: [180],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.58], y: [0] },
                  t: 29,
                  s: [180],
                  e: [0],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: 44,
                  s: [0],
                  e: [0],
                },
                {
                  i: { x: [0.772], y: [1] },
                  o: { x: [0.522], y: [0] },
                  t: 51,
                  s: [0],
                  e: [180],
                },
                {
                  i: { x: [0.596], y: [1] },
                  o: { x: [0.307], y: [0] },
                  t: 66,
                  s: [180],
                  e: [180],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.58], y: [0] },
                  t: 73,
                  s: [180],
                  e: [0],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: 88,
                  s: [0],
                  e: [0],
                },
                {
                  i: { x: [0.772], y: [1] },
                  o: { x: [0.522], y: [0] },
                  t: 95,
                  s: [0],
                  e: [180],
                },
                {
                  i: { x: [0.596], y: [1] },
                  o: { x: [0.307], y: [0] },
                  t: 110,
                  s: [180],
                  e: [180],
                },
                {
                  i: { x: [0.368], y: [1] },
                  o: { x: [0.58], y: [0] },
                  t: 117,
                  s: [180],
                  e: [0],
                },
                { t: 132 },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.57, y: 0.541 },
                  o: { x: 0.167, y: 0.167 },
                  t: 0,
                  s: [370.541, -24.034, 0],
                  e: [361.988, 9.41, 0],
                  to: [12.889, 62.553, 0],
                  ti: [45.023, -42.894, 0],
                },
                {
                  i: { x: 0.653, y: 0.707 },
                  o: { x: 0.317, y: 0.244 },
                  t: 2,
                  s: [361.988, 9.41, 0],
                  e: [334.584, 41.108, 0],
                  to: [-19.86, 18.921, 0],
                  ti: [40.364, -56.19, 0],
                },
                {
                  i: { x: 0.662, y: 0.781 },
                  o: { x: 0.326, y: 0.193 },
                  t: 3,
                  s: [334.584, 41.108, 0],
                  e: [280.841, 86.179, 0],
                  to: [-12.979, 18.068, 0],
                  ti: [38.562, -24.79, 0],
                },
                {
                  i: { x: 0.668, y: 0.725 },
                  o: { x: 0.333, y: 0.267 },
                  t: 4,
                  s: [280.841, 86.179, 0],
                  e: [235.364, 120.044, 0],
                  to: [-29.071, 18.688, 0],
                  ti: [35.935, -17.308, 0],
                },
                {
                  i: { x: 0.675, y: 0.829 },
                  o: { x: 0.34, y: 0.163 },
                  t: 5,
                  s: [235.364, 120.044, 0],
                  e: [145.701, 161.17, 0],
                  to: [-33.538, 16.153, 0],
                  ti: [40.355, -10.71, 0],
                },
                {
                  i: { x: 0.685, y: 1 },
                  o: { x: 0.349, y: 0.165 },
                  t: 6,
                  s: [145.701, 161.17, 0],
                  e: [38.834, 164.99, 0],
                  to: [-37.423, 9.932, 0],
                  ti: [6.353, 13.673, 0],
                },
                {
                  i: { x: 0.178, y: 0.974 },
                  o: { x: 0.046, y: 0 },
                  t: 7,
                  s: [38.834, 164.99, 0],
                  e: [-302.629, -517.937, 0],
                  to: [-60.163, -129.472, 0],
                  ti: [130.678, -2.795, 0],
                },
                {
                  i: { x: 1, y: 1 },
                  o: { x: 0.704, y: 0.044 },
                  t: 14.059,
                  s: [-302.629, -517.937, 0],
                  e: [-483.117, -10.62, 0],
                  to: [-130.678, 2.795, 0],
                  ti: [2.439, -0.407, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 22,
                  s: [-483.117, -10.62, 0],
                  e: [-486.776, -10.62, 0],
                  to: [-2.439, 0.407, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 23,
                  s: [-486.776, -10.62, 0],
                  e: [-489.215, -5.742, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 24,
                  s: [-489.215, -5.742, 0],
                  e: [-485.556, 13.161, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 25,
                  s: [-485.556, 13.161, 0],
                  e: [-458.117, 45.478, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 26,
                  s: [-458.117, 45.478, 0],
                  e: [-413.605, 82.063, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 27,
                  s: [-413.605, 82.063, 0],
                  e: [-329.459, 132.673, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.685, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 28,
                  s: [-329.459, 132.673, 0],
                  e: [-252.02, 162.551, 0],
                  to: [0, 0, 0],
                  ti: [-65.434, 161.332, 0],
                },
                {
                  i: { x: 0.178, y: 0.974 },
                  o: { x: 0.046, y: 0 },
                  t: 29,
                  s: [-252.02, 162.551, 0],
                  e: [122.371, -515.498, 0],
                  to: [65.434, -161.332, 0],
                  ti: [-110.556, -0.864, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.704, y: 0.044 },
                  t: 36,
                  s: [122.371, -515.498, 0],
                  e: [370.541, -24.034, 0],
                  to: [110.556, 0.864, 0],
                  ti: [-37.398, -181.504, 0],
                },
                {
                  i: { x: 0.57, y: 0.541 },
                  o: { x: 0.167, y: 0.167 },
                  t: 44,
                  s: [370.541, -24.034, 0],
                  e: [361.988, 9.41, 0],
                  to: [12.889, 62.553, 0],
                  ti: [45.023, -42.894, 0],
                },
                {
                  i: { x: 0.653, y: 0.707 },
                  o: { x: 0.317, y: 0.244 },
                  t: 46,
                  s: [361.988, 9.41, 0],
                  e: [334.584, 41.108, 0],
                  to: [-19.86, 18.921, 0],
                  ti: [40.364, -56.19, 0],
                },
                {
                  i: { x: 0.662, y: 0.781 },
                  o: { x: 0.326, y: 0.193 },
                  t: 47,
                  s: [334.584, 41.108, 0],
                  e: [280.841, 86.179, 0],
                  to: [-12.979, 18.068, 0],
                  ti: [38.562, -24.79, 0],
                },
                {
                  i: { x: 0.668, y: 0.725 },
                  o: { x: 0.333, y: 0.267 },
                  t: 48,
                  s: [280.841, 86.179, 0],
                  e: [235.364, 120.044, 0],
                  to: [-29.071, 18.688, 0],
                  ti: [35.935, -17.308, 0],
                },
                {
                  i: { x: 0.675, y: 0.829 },
                  o: { x: 0.34, y: 0.163 },
                  t: 49,
                  s: [235.364, 120.044, 0],
                  e: [145.701, 161.17, 0],
                  to: [-33.538, 16.153, 0],
                  ti: [40.355, -10.71, 0],
                },
                {
                  i: { x: 0.685, y: 1 },
                  o: { x: 0.349, y: 0.165 },
                  t: 50,
                  s: [145.701, 161.17, 0],
                  e: [38.834, 164.99, 0],
                  to: [-37.423, 9.932, 0],
                  ti: [6.353, 13.673, 0],
                },
                {
                  i: { x: 0.178, y: 0.974 },
                  o: { x: 0.046, y: 0 },
                  t: 51,
                  s: [38.834, 164.99, 0],
                  e: [-302.629, -517.937, 0],
                  to: [-60.163, -129.472, 0],
                  ti: [130.678, -2.795, 0],
                },
                {
                  i: { x: 1, y: 1 },
                  o: { x: 0.704, y: 0.044 },
                  t: 58.059,
                  s: [-302.629, -517.937, 0],
                  e: [-483.117, -10.62, 0],
                  to: [-130.678, 2.795, 0],
                  ti: [2.439, -0.407, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 66,
                  s: [-483.117, -10.62, 0],
                  e: [-486.776, -10.62, 0],
                  to: [-2.439, 0.407, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 67,
                  s: [-486.776, -10.62, 0],
                  e: [-489.215, -5.742, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 68,
                  s: [-489.215, -5.742, 0],
                  e: [-485.556, 13.161, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 69,
                  s: [-485.556, 13.161, 0],
                  e: [-458.117, 45.478, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 70,
                  s: [-458.117, 45.478, 0],
                  e: [-413.605, 82.063, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 71,
                  s: [-413.605, 82.063, 0],
                  e: [-329.459, 132.673, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.685, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 72,
                  s: [-329.459, 132.673, 0],
                  e: [-252.02, 162.551, 0],
                  to: [0, 0, 0],
                  ti: [-65.434, 161.332, 0],
                },
                {
                  i: { x: 0.178, y: 0.974 },
                  o: { x: 0.046, y: 0 },
                  t: 73,
                  s: [-252.02, 162.551, 0],
                  e: [122.371, -515.498, 0],
                  to: [65.434, -161.332, 0],
                  ti: [-110.556, -0.864, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.704, y: 0.044 },
                  t: 80,
                  s: [122.371, -515.498, 0],
                  e: [370.541, -24.034, 0],
                  to: [110.556, 0.864, 0],
                  ti: [-37.398, -181.504, 0],
                },
                {
                  i: { x: 0.57, y: 0.541 },
                  o: { x: 0.167, y: 0.167 },
                  t: 88,
                  s: [370.541, -24.034, 0],
                  e: [361.988, 9.41, 0],
                  to: [12.889, 62.553, 0],
                  ti: [45.023, -42.894, 0],
                },
                {
                  i: { x: 0.653, y: 0.707 },
                  o: { x: 0.317, y: 0.244 },
                  t: 90,
                  s: [361.988, 9.41, 0],
                  e: [334.584, 41.108, 0],
                  to: [-19.86, 18.921, 0],
                  ti: [40.364, -56.19, 0],
                },
                {
                  i: { x: 0.662, y: 0.781 },
                  o: { x: 0.326, y: 0.193 },
                  t: 91,
                  s: [334.584, 41.108, 0],
                  e: [280.841, 86.179, 0],
                  to: [-12.979, 18.068, 0],
                  ti: [38.562, -24.79, 0],
                },
                {
                  i: { x: 0.668, y: 0.725 },
                  o: { x: 0.333, y: 0.267 },
                  t: 92,
                  s: [280.841, 86.179, 0],
                  e: [235.364, 120.044, 0],
                  to: [-29.071, 18.688, 0],
                  ti: [35.935, -17.308, 0],
                },
                {
                  i: { x: 0.675, y: 0.829 },
                  o: { x: 0.34, y: 0.163 },
                  t: 93,
                  s: [235.364, 120.044, 0],
                  e: [145.701, 161.17, 0],
                  to: [-33.538, 16.153, 0],
                  ti: [40.355, -10.71, 0],
                },
                {
                  i: { x: 0.685, y: 1 },
                  o: { x: 0.349, y: 0.165 },
                  t: 94,
                  s: [145.701, 161.17, 0],
                  e: [38.834, 164.99, 0],
                  to: [-37.423, 9.932, 0],
                  ti: [6.353, 13.673, 0],
                },
                {
                  i: { x: 0.178, y: 0.974 },
                  o: { x: 0.046, y: 0 },
                  t: 95,
                  s: [38.834, 164.99, 0],
                  e: [-302.629, -517.937, 0],
                  to: [-60.163, -129.472, 0],
                  ti: [130.678, -2.795, 0],
                },
                {
                  i: { x: 1, y: 1 },
                  o: { x: 0.704, y: 0.044 },
                  t: 102.059,
                  s: [-302.629, -517.937, 0],
                  e: [-483.117, -10.62, 0],
                  to: [-130.678, 2.795, 0],
                  ti: [2.439, -0.407, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 110,
                  s: [-483.117, -10.62, 0],
                  e: [-486.776, -10.62, 0],
                  to: [-2.439, 0.407, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 111,
                  s: [-486.776, -10.62, 0],
                  e: [-489.215, -5.742, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 112,
                  s: [-489.215, -5.742, 0],
                  e: [-485.556, 13.161, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 113,
                  s: [-485.556, 13.161, 0],
                  e: [-458.117, 45.478, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 114,
                  s: [-458.117, 45.478, 0],
                  e: [-413.605, 82.063, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 115,
                  s: [-413.605, 82.063, 0],
                  e: [-329.459, 132.673, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.685, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 116,
                  s: [-329.459, 132.673, 0],
                  e: [-252.02, 162.551, 0],
                  to: [0, 0, 0],
                  ti: [-65.434, 161.332, 0],
                },
                {
                  i: { x: 0.178, y: 0.974 },
                  o: { x: 0.046, y: 0 },
                  t: 117,
                  s: [-252.02, 162.551, 0],
                  e: [122.371, -515.498, 0],
                  to: [65.434, -161.332, 0],
                  ti: [-110.556, -0.864, 0],
                },
                {
                  i: { x: 0.999, y: 1 },
                  o: { x: 0.704, y: 0.044 },
                  t: 124,
                  s: [122.371, -515.498, 0],
                  e: [370.541, -24.034, 0],
                  to: [110.556, 0.864, 0],
                  ti: [-37.398, -181.504, 0],
                },
                { t: 132 },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [31.75, 32.75, 0], ix: 1, l: 2 },
            s: { a: 0, k: [489.084, 489.084, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 0,
          op: 132,
          st: -10,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 14,
          ty: 3,
          nm: 'Null 79',
          sr: 1,
          ks: {
            o: { a: 0, k: 0, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [281, 319, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [43, 43, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 15,
          ty: 4,
          nm: 'Layer 9 Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [287.22, 388.406, 0], ix: 2, l: 2 },
            a: { a: 0, k: [22.672, 31.348, 0], ix: 1, l: 2 },
            s: { a: 0, k: [140, 140, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [2.239, -13.432],
                        [-6.718, 2.238],
                        [0.001, 2.238],
                        [-2.238, 13.432],
                        [6.718, -2.238],
                        [0.001, -2.238],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [22.673, 23.325], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-9.252, 0],
                        [-0.864, -9.038],
                        [0, 0.587],
                        [9.843, 0],
                        [0, -9.844],
                        [-0.055, -0.565],
                      ],
                      o: [
                        [9.257, 0],
                        [0.059, -0.565],
                        [0, -9.844],
                        [-9.843, 0],
                        [0, 0.587],
                        [0.864, -9.038],
                      ],
                      v: [
                        [0, -6.326],
                        [17.73, 9.773],
                        [17.821, 8.049],
                        [0, -9.773],
                        [-17.821, 8.049],
                        [-17.73, 9.773],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.211999990426, 0.46699999641, 0.838999968884, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [22.669, 14.626], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -6.382],
                        [8.451, -1.397],
                        [0.996, 0],
                        [0.273, 0.018],
                        [0.3, 0.038],
                        [2.111, 1.206],
                        [0, 6.654],
                        [-8.81, 1.079],
                        [-0.741, 0],
                        [-1.633, -0.496],
                        [-0.282, -0.1],
                        [-1.06, -0.669],
                      ],
                      o: [
                        [0, 8.847],
                        [-0.952, 0.155],
                        [-0.283, 0],
                        [-0.31, -0.01],
                        [-2.533, -0.236],
                        [-5.403, -3.052],
                        [0, -9.102],
                        [0.714, -0.09],
                        [1.792, 0],
                        [0.291, 0.082],
                        [1.196, 0.427],
                        [5.044, 3.139],
                      ],
                      v: [
                        [17.822, 0],
                        [2.925, 17.584],
                        [0, 17.821],
                        [-0.842, 17.794],
                        [-1.756, 17.729],
                        [-8.775, 15.51],
                        [-17.822, 0],
                        [-2.184, -17.686],
                        [0, -17.821],
                        [5.153, -17.062],
                        [6.022, -16.78],
                        [9.421, -15.124],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.258999992819, 0.522000002394, 0.957000014361, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [22.674, 22.675], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.337, -0.842],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.255, 0],
                        [0.346, 0.024],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.196, 0.197],
                        [-0.354, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.501, 0.541],
                      ],
                      v: [
                        [6.457, -19.684],
                        [5.478, -14.776],
                        [4.883, -11.765],
                        [-0.74, 16.545],
                        [-1.717, 21.466],
                        [-5.397, 21.767],
                        [-6.457, 21.73],
                        [-5.548, 17.164],
                        [0.639, -13.971],
                        [1.271, -17.178],
                        [2.181, -21.767],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.383999992819, 0.62400004069, 0.976000019148, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [28.071, 23.328], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.056, -0.618],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.656, 1.515],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.933, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-3.184, -0.299],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.895, -0.115],
                        [2.256, -0.005],
                      ],
                      v: [
                        [8.763, -21.41],
                        [7.853, -16.866],
                        [7.206, -13.623],
                        [0.961, 17.826],
                        [0.065, 22.365],
                        [-8.763, 19.572],
                        [-7.807, 14.756],
                        [-2.141, -13.759],
                        [-1.426, -17.371],
                        [-0.47, -22.187],
                        [2.276, -22.36],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.383999992819, 0.62400004069, 0.976000019148, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [20.393, 22.615], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -8.028],
                        [10.635, -1.761],
                        [1.255, 0],
                        [0.345, 0.022],
                        [0.382, 0.046],
                        [2.656, 1.514],
                        [0, 8.374],
                        [-11.085, 1.355],
                        [-0.932, 0],
                        [-2.061, -0.623],
                        [-0.354, -0.122],
                        [-1.333, -0.842],
                      ],
                      o: [
                        [0, 11.13],
                        [-1.197, 0.195],
                        [-0.355, 0],
                        [-0.392, -0.014],
                        [-3.183, -0.3],
                        [-6.796, -3.839],
                        [0, -11.453],
                        [0.896, -0.114],
                        [2.252, 0],
                        [0.368, 0.105],
                        [1.506, 0.542],
                        [6.349, 3.952],
                      ],
                      v: [
                        [22.422, -0.002],
                        [3.682, 22.122],
                        [0.002, 22.422],
                        [-1.057, 22.386],
                        [-2.209, 22.304],
                        [-11.037, 19.511],
                        [-22.422, -0.002],
                        [-2.75, -22.249],
                        [-0.003, -22.422],
                        [6.484, -21.467],
                        [7.575, -21.112],
                        [11.851, -19.028],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.258999992819, 0.522000002394, 0.957000014361, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [22.672, 22.677], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [3.459, -12.275],
                        [8.313, 10.309],
                        [-0.402, 6.71],
                        [-8.313, 12.275],
                        [-8.035, -11.124],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.172999991623, 0.172999991623, 0.172999991623, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [32.483, 45.802], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 7',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-10.121, 0],
                        [0, 10.071],
                        [10.111, 0],
                        [0, -10.07],
                      ],
                      o: [
                        [10.12, 0],
                        [-0.013, -10.074],
                        [-10.121, 0],
                        [0, 10.08],
                      ],
                      v: [
                        [0.001, 18.249],
                        [18.322, -0.001],
                        [0.001, -18.249],
                        [-18.322, -0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.258999992819, 0.522000002394, 0.957000014361, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [22.555, 22.666], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 8',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 8,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-3.458, -12.275],
                        [-8.313, 10.309],
                        [0.402, 6.71],
                        [8.313, 12.275],
                        [8.035, -11.124],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.172999991623, 0.172999991623, 0.172999991623, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [12.86, 45.802], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 9',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 9,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 6.809],
                        [6.344, 3.957],
                        [1.506, 0.537],
                        [0.369, 0.1],
                        [1.66, 0.123],
                        [0.564, 0],
                        [0.897, -0.113],
                        [0, -11.448],
                        [-4.79, -4.108],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.2, -0.005],
                        [-0.359, 0],
                        [-0.542, 0.041],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, -8.024],
                        [-1.333, -0.842],
                        [-0.354, -0.127],
                        [-1.542, -0.464],
                        [-0.556, -0.041],
                        [-0.933, 0],
                        [-11.084, 1.355],
                        [0, 6.809],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.195, 0.014],
                        [0.345, 0.022],
                        [0.555, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [4.794, -4.118],
                      ],
                      v: [
                        [22.42, -6.495],
                        [11.854, -25.526],
                        [7.577, -27.605],
                        [6.486, -27.96],
                        [1.674, -28.851],
                        [0, -28.911],
                        [-2.748, -28.738],
                        [-22.42, -6.491],
                        [-14.592, 10.526],
                        [-18.122, 26.945],
                        [-9.406, 23.348],
                        [-1.497, 28.91],
                        [-1.651, 15.865],
                        [-1.06, 15.893],
                        [0, 15.929],
                        [1.647, 15.865],
                        [1.491, 28.91],
                        [9.401, 23.348],
                        [18.116, 26.945],
                        [14.587, 10.526],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.219999994016, 0.219999994016, 0.219999994016, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [22.674, 33.532], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 29, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 10',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 10,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 16,
          ty: 4,
          nm: 'crypto_icon',
          parent: 17,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1046.885, 885.221, 0], ix: 2, l: 2 },
            a: { a: 0, k: [172.956, 172.956, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-4.145, 2.176],
                        [-1.503, 0],
                        [-1.088, -1.192],
                        [0, -1.555],
                        [2.279, -1.139],
                        [5.544, -1.865],
                        [7.72, 0],
                        [6.632, 2.538],
                        [4.87, 4.87],
                        [0, 17.098],
                        [-10.363, 10.156],
                        [-15.855, 0],
                        [-5.544, -1.865],
                        [-2.953, -1.606],
                        [-0.622, -0.984],
                        [0, -1.502],
                        [1.088, -1.193],
                        [1.502, 0],
                        [0.829, 0.415],
                        [4.352, 1.346],
                        [6.477, 0],
                        [5.233, -2.176],
                        [3.523, -3.834],
                        [0, -12.435],
                        [-6.943, -7.564],
                        [-13.264, 0],
                        [-4.352, 1.347],
                      ],
                      o: [
                        [0.829, -0.415],
                        [1.502, 0],
                        [1.088, 1.191],
                        [0, 2.798],
                        [-4.87, 2.695],
                        [-5.544, 1.866],
                        [-7.72, 0],
                        [-6.632, -2.54],
                        [-10.363, -10.155],
                        [0, -17.098],
                        [10.051, -9.948],
                        [7.668, 0],
                        [5.544, 1.866],
                        [2.953, 1.607],
                        [0.622, 0.985],
                        [0, 1.503],
                        [-1.088, 1.191],
                        [-1.503, 0],
                        [-4.145, -2.176],
                        [-4.352, -1.348],
                        [-6.476, 0],
                        [-5.233, 2.175],
                        [-6.943, 7.565],
                        [0, 12.435],
                        [7.564, 8.186],
                        [6.113, 0],
                        [4.352, -1.347],
                      ],
                      v: [
                        [37.616, 34.973],
                        [41.114, 34.351],
                        [44.999, 36.139],
                        [46.63, 40.258],
                        [43.211, 46.164],
                        [27.591, 53.003],
                        [7.695, 55.801],
                        [-13.833, 51.994],
                        [-31.086, 40.879],
                        [-46.63, 0],
                        [-31.086, -40.88],
                        [7.773, -55.801],
                        [27.591, -53.004],
                        [40.336, -47.797],
                        [45.698, -43.911],
                        [46.63, -40.18],
                        [44.999, -36.138],
                        [41.114, -34.351],
                        [37.616, -34.973],
                        [24.87, -40.257],
                        [8.627, -42.278],
                        [-8.938, -39.014],
                        [-22.071, -29.999],
                        [-32.485, 0],
                        [-22.071, 29.999],
                        [9.172, 42.279],
                        [24.87, 40.258],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 8.635, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [174.683, 172.628], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-4.145, 2.176],
                        [-1.503, 0],
                        [-1.088, -1.192],
                        [0, -1.555],
                        [2.279, -1.139],
                        [5.544, -1.865],
                        [7.72, 0],
                        [6.632, 2.538],
                        [4.87, 4.87],
                        [0, 17.098],
                        [-10.363, 10.156],
                        [-15.855, 0],
                        [-5.544, -1.865],
                        [-2.953, -1.606],
                        [-0.622, -0.984],
                        [0, -1.502],
                        [1.088, -1.193],
                        [1.502, 0],
                        [0.829, 0.415],
                        [4.352, 1.346],
                        [6.477, 0],
                        [5.233, -2.176],
                        [3.523, -3.834],
                        [0, -12.435],
                        [-6.943, -7.564],
                        [-13.264, 0],
                        [-4.352, 1.347],
                      ],
                      o: [
                        [0.829, -0.415],
                        [1.502, 0],
                        [1.088, 1.191],
                        [0, 2.798],
                        [-4.87, 2.695],
                        [-5.544, 1.866],
                        [-7.72, 0],
                        [-6.632, -2.54],
                        [-10.363, -10.155],
                        [0, -17.098],
                        [10.051, -9.948],
                        [7.668, 0],
                        [5.544, 1.866],
                        [2.953, 1.607],
                        [0.622, 0.985],
                        [0, 1.503],
                        [-1.088, 1.191],
                        [-1.503, 0],
                        [-4.145, -2.176],
                        [-4.352, -1.348],
                        [-6.476, 0],
                        [-5.233, 2.175],
                        [-6.943, 7.565],
                        [0, 12.435],
                        [7.564, 8.186],
                        [6.113, 0],
                        [4.352, -1.347],
                      ],
                      v: [
                        [37.616, 34.973],
                        [41.114, 34.351],
                        [44.999, 36.139],
                        [46.63, 40.258],
                        [43.211, 46.164],
                        [27.591, 53.003],
                        [7.695, 55.801],
                        [-13.833, 51.994],
                        [-31.086, 40.879],
                        [-46.63, 0],
                        [-31.086, -40.88],
                        [7.773, -55.801],
                        [27.591, -53.004],
                        [40.336, -47.797],
                        [45.698, -43.911],
                        [46.63, -40.18],
                        [44.999, -36.138],
                        [41.114, -34.351],
                        [37.616, -34.973],
                        [24.87, -40.257],
                        [8.627, -42.278],
                        [-8.938, -39.014],
                        [-22.071, -29.999],
                        [-32.485, 0],
                        [-22.071, 29.999],
                        [9.172, 42.279],
                        [24.87, 40.258],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [174.683, 172.628], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-8.29, 4.353],
                        [-3.005, 0],
                        [-2.177, -2.384],
                        [0, -3.108],
                        [4.56, -2.279],
                        [11.088, -3.731],
                        [15.44, 0],
                        [13.264, 5.078],
                        [9.741, 9.741],
                        [0, 34.195],
                        [-20.724, 20.31],
                        [-31.709, 0],
                        [-11.088, -3.73],
                        [-5.906, -3.212],
                        [-1.244, -1.968],
                        [0, -3.005],
                        [2.175, -2.383],
                        [3.005, 0],
                        [1.658, 0.83],
                        [8.704, 2.694],
                        [12.953, 0],
                        [10.465, -4.351],
                        [7.046, -7.669],
                        [0, -24.87],
                        [-13.886, -15.13],
                        [-26.528, 0],
                        [-8.705, 2.695],
                      ],
                      o: [
                        [1.658, -0.829],
                        [3.005, 0],
                        [2.175, 2.383],
                        [0, 5.596],
                        [-9.74, 5.388],
                        [-11.088, 3.73],
                        [-15.44, 0],
                        [-13.264, -5.077],
                        [-20.724, -20.31],
                        [0, -34.197],
                        [20.103, -19.896],
                        [15.336, 0],
                        [11.088, 3.731],
                        [5.907, 3.213],
                        [1.243, 1.969],
                        [0, 3.005],
                        [-2.177, 2.383],
                        [-3.005, 0],
                        [-8.29, -4.352],
                        [-8.705, -2.694],
                        [-12.953, 0],
                        [-10.467, 4.353],
                        [-13.886, 15.129],
                        [0, 24.869],
                        [15.129, 16.372],
                        [12.227, 0],
                        [8.704, -2.693],
                      ],
                      v: [
                        [75.231, 69.945],
                        [82.226, 68.702],
                        [89.997, 72.277],
                        [93.261, 80.514],
                        [86.422, 92.329],
                        [55.18, 106.007],
                        [15.388, 111.603],
                        [-27.667, 103.986],
                        [-62.174, 81.759],
                        [-93.261, 0.001],
                        [-62.174, -81.759],
                        [15.544, -111.603],
                        [55.18, -106.008],
                        [80.67, -95.594],
                        [91.396, -87.822],
                        [93.261, -80.361],
                        [89.997, -72.277],
                        [82.226, -68.702],
                        [75.231, -69.947],
                        [49.74, -80.516],
                        [17.253, -84.556],
                        [-17.875, -78.03],
                        [-44.143, -59.998],
                        [-64.972, 0.001],
                        [-44.143, 59.999],
                        [18.341, 84.556],
                        [49.74, 80.514],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [162.594, 172.3], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-95.383, 0],
                        [0, -95.383],
                        [95.383, 0],
                        [0, 95.383],
                      ],
                      o: [
                        [95.383, 0],
                        [0, 95.383],
                        [-95.383, 0],
                        [0, -95.383],
                      ],
                      v: [
                        [0, -172.706],
                        [172.706, 0],
                        [0, 172.706],
                        [-172.706, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.969000004787, 0.57599995931, 0.102000000898, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [172.956, 172.956], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 17,
          ty: 0,
          nm: 'body',
          parent: 14,
          refId: 'comp_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 360, ix: 10 },
            p: { a: 0, k: [-55.145, 179.221, 0], ix: 2, l: 2 },
            a: { a: 0, k: [1046.885, 885.221, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 2277,
          h: 1600,
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 18,
          ty: 0,
          nm: 'nftm_head',
          parent: 17,
          refId: 'comp_4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: -0.394, ix: 10 },
            p: { a: 0, k: [1045.634, 524, 0], ix: 2, l: 2 },
            a: { a: 0, k: [188.727, 485.03, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 392,
          h: 552,
          ip: 88,
          op: 132,
          st: 88,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 19,
          ty: 0,
          nm: 'nftm_head',
          parent: 17,
          refId: 'comp_4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: -0.394, ix: 10 },
            p: { a: 0, k: [1045.634, 524, 0], ix: 2, l: 2 },
            a: { a: 0, k: [188.727, 485.03, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 392,
          h: 552,
          ip: 44,
          op: 88,
          st: 44,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 20,
          ty: 0,
          nm: 'nftm_head',
          parent: 17,
          refId: 'comp_4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: -0.394, ix: 10 },
            p: { a: 0, k: [1045.634, 524, 0], ix: 2, l: 2 },
            a: { a: 0, k: [188.727, 485.03, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 392,
          h: 552,
          ip: 0,
          op: 44,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 21,
          ty: 4,
          nm: 'neck',
          parent: 17,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 19.5, ix: 10 },
            p: { a: 0, k: [1036.487, 703.017, 0], ix: 2, l: 2 },
            a: { a: 0, k: [138.982, 259.79, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-0.269, -7.616],
                        [-0.609, -7.568],
                        [-1.044, -7.498],
                        [-1.596, -7.393],
                        [-2.279, -7.242],
                        [-3.067, -7.042],
                        [-3.862, -6.812],
                        [-4.512, -6.608],
                        [-4.916, -6.495],
                        [-5.067, -6.501],
                        [-5.026, -6.602],
                        [-4.862, -6.755],
                        [-4.63, -6.927],
                        [-4.367, -7.087],
                        [-4.102, -7.233],
                        [-3.844, -7.358],
                        [-3.602, -7.46],
                        [-3.379, -7.544],
                        [-3.175, -7.617],
                      ],
                      o: [
                        [0, 8.354],
                        [0.296, 8.384],
                        [0.675, 8.393],
                        [1.162, 8.354],
                        [1.776, 8.227],
                        [2.503, 7.955],
                        [3.273, 7.515],
                        [3.954, 6.974],
                        [4.434, 6.492],
                        [4.68, 6.184],
                        [4.729, 6.068],
                        [4.641, 6.096],
                        [4.469, 6.211],
                        [4.251, 6.36],
                        [4.019, 6.522],
                        [3.786, 6.676],
                        [3.562, 6.815],
                        [3.351, 6.939],
                        [3.156, 7.049],
                        [0, 0],
                      ],
                      v: [
                        [-51.074, -209.802],
                        [-123.646, -184.573],
                        [-49.424, -164.883],
                        [-119.375, -133.115],
                        [-43.401, -122.266],
                        [-107.806, -80.459],
                        [-31.158, -83.568],
                        [-85.658, -29.588],
                        [-11.049, -47.893],
                        [-56.258, 14.11],
                        [16.269, -11.403],
                        [-26.92, 52.087],
                        [46.012, 27.889],
                        [-0.308, 89.158],
                        [74.045, 69.87],
                        [23.404, 127.632],
                        [98.952, 113.755],
                        [44.482, 167.929],
                        [120.759, 158.851],
                        [63.255, 209.802],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.219999994016, 0.219999994016, 0.219999994016, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 17.695, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [168.152, 254.039], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 22,
          ty: 0,
          nm: 'hand_cycle_back',
          refId: 'comp_5',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [256, 271, 0], ix: 2, l: 2 },
            a: { a: 0, k: [256, 256, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 512,
          h: 512,
          ip: 88,
          op: 132,
          st: 88,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 23,
          ty: 0,
          nm: 'hand_cycle_back',
          refId: 'comp_5',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [256, 271, 0], ix: 2, l: 2 },
            a: { a: 0, k: [256, 256, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 512,
          h: 512,
          ip: 44,
          op: 88,
          st: 44,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 24,
          ty: 0,
          nm: 'hand_cycle_back',
          refId: 'comp_5',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [256, 271, 0], ix: 2, l: 2 },
            a: { a: 0, k: [256, 256, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 512,
          h: 512,
          ip: 0,
          op: 44,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_1',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 0,
          nm: 'nftm_left_hand_front',
          refId: 'comp_2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [201.524, 328.217, 0], ix: 2, l: 2 },
            a: { a: 0, k: [454.585, 440.061, 0], ix: 1, l: 2 },
            s: { a: 0, k: [-52.46, 52.46, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 1236,
          h: 1085,
          ip: 0,
          op: 44,
          st: -6,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 0,
          nm: 'nftm_left_hand_front',
          refId: 'comp_2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [312.433, 328.217, 0], ix: 2, l: 2 },
            a: { a: 0, k: [454.585, 440.061, 0], ix: 1, l: 2 },
            s: { a: 0, k: [52.46, 52.46, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 1236,
          h: 1085,
          ip: 0,
          op: 44,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_2',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'fingers',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.904], y: [1] },
                  o: { x: [0.528], y: [0] },
                  t: 0,
                  s: [191.636],
                  e: [147.636],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.08], y: [0] },
                  t: 7.2,
                  s: [147.636],
                  e: [191.636],
                },
                {
                  i: { x: [0.904], y: [1] },
                  o: { x: [0.528], y: [0] },
                  t: 14.4,
                  s: [191.636],
                  e: [147.636],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.08], y: [0] },
                  t: 21.6,
                  s: [147.636],
                  e: [191.636],
                },
                {
                  i: { x: [0.904], y: [1] },
                  o: { x: [0.528], y: [0] },
                  t: 28.8,
                  s: [191.636],
                  e: [147.636],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.08], y: [0] },
                  t: 36,
                  s: [147.636],
                  e: [191.636],
                },
                {
                  i: { x: [0.904], y: [1] },
                  o: { x: [0.528], y: [0] },
                  t: 43.2,
                  s: [191.636],
                  e: [147.636],
                },
                {
                  i: { x: [0.28], y: [1] },
                  o: { x: [0.08], y: [0] },
                  t: 50.4,
                  s: [147.636],
                  e: [191.636],
                },
                { t: 57.6 },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.96, y: 0.994 },
                  o: { x: 0.72, y: 0.101 },
                  t: 0,
                  s: [707.301, 525.135, 0],
                  e: [500.301, 638.135, 0],
                  to: [30.699, 77.865, 0],
                  ti: [61.699, 58.865, 0],
                },
                {
                  i: { x: 0.28, y: 1 },
                  o: { x: 0.008, y: 0.001 },
                  t: 7.2,
                  s: [500.301, 638.135, 0],
                  e: [707.301, 525.135, 0],
                  to: [-61.699, -58.865, 0],
                  ti: [-30.699, -77.865, 0],
                },
                {
                  i: { x: 0.96, y: 0.994 },
                  o: { x: 0.72, y: 0.101 },
                  t: 14.4,
                  s: [707.301, 525.135, 0],
                  e: [500.301, 638.135, 0],
                  to: [30.699, 77.865, 0],
                  ti: [61.699, 58.865, 0],
                },
                {
                  i: { x: 0.28, y: 1 },
                  o: { x: 0.008, y: 0.001 },
                  t: 21.6,
                  s: [500.301, 638.135, 0],
                  e: [707.301, 525.135, 0],
                  to: [-61.699, -58.865, 0],
                  ti: [-30.699, -77.865, 0],
                },
                {
                  i: { x: 0.96, y: 0.994 },
                  o: { x: 0.72, y: 0.101 },
                  t: 28.8,
                  s: [707.301, 525.135, 0],
                  e: [500.301, 638.135, 0],
                  to: [30.699, 77.865, 0],
                  ti: [61.699, 58.865, 0],
                },
                {
                  i: { x: 0.28, y: 1 },
                  o: { x: 0.008, y: 0.001 },
                  t: 36,
                  s: [500.301, 638.135, 0],
                  e: [707.301, 525.135, 0],
                  to: [-61.699, -58.865, 0],
                  ti: [-30.699, -77.865, 0],
                },
                {
                  i: { x: 0.96, y: 0.994 },
                  o: { x: 0.72, y: 0.101 },
                  t: 43.2,
                  s: [707.301, 525.135, 0],
                  e: [500.301, 638.135, 0],
                  to: [30.699, 77.865, 0],
                  ti: [61.699, 58.865, 0],
                },
                {
                  i: { x: 0.28, y: 0.903 },
                  o: { x: 0.008, y: 0.001 },
                  t: 50.4,
                  s: [500.301, 638.135, 0],
                  e: [708.301, 525.135, 0],
                  to: [-61.699, -58.865, 0],
                  ti: [-56.301, -69.135, 0],
                },
                { t: 57.6 },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [-102, 96, 0], ix: 1, l: 2 },
            s: { a: 0, k: [-95.103, 95.103, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-35.5, -12.5],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [35.5, 12.5],
                        [0, 0],
                      ],
                      v: [
                        [-161.5, 143],
                        [-100, 93.5],
                        [-75.5, 166],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.278431372549, 0.278431372549, 0.278431372549, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 40, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Shape 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 90,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_3',
      layers: [
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Group 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1046.885, 885.221, 0], ix: 2, l: 2 },
            a: { a: 0, k: [266.319, 266.319, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -103.457],
                        [103.457, 0],
                        [0, 103.456],
                        [-103.457, 0],
                      ],
                      o: [
                        [0, 103.456],
                        [-103.457, 0],
                        [0, -103.457],
                        [103.457, 0],
                      ],
                      v: [
                        [187.326, 0],
                        [-0.001, 187.326],
                        [-187.325, 0],
                        [-0.001, -187.326],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.219999994016, 0.219999994016, 0.219999994016, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [266.32, 266.319], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -117.001],
                        [117.001, 0],
                        [0, 117.001],
                        [-117.002, 0],
                      ],
                      o: [
                        [0, 117.001],
                        [-117.002, 0],
                        [0, -117.001],
                        [117.001, 0],
                      ],
                      v: [
                        [211.849, 0],
                        [0, 211.849],
                        [-211.85, 0],
                        [0, -211.849],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.277999997606, 0.277999997606, 0.277999997606, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [266.319, 266.319], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -117.001],
                        [117.001, 0],
                        [0, 117.001],
                        [-117.002, 0],
                      ],
                      o: [
                        [0, 117.001],
                        [-117.002, 0],
                        [0, -117.001],
                        [117.001, 0],
                      ],
                      v: [
                        [211.849, 0],
                        [0, 211.849],
                        [-211.85, 0],
                        [0, -211.849],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.277999997606, 0.277999997606, 0.277999997606, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 21.788, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  d: [
                    { n: 'd', nm: 'dash', v: { a: 0, k: 66.557, ix: 1 } },
                    { n: 'g', nm: 'gap', v: { a: 0, k: 0, ix: 2 } },
                    { n: 'd', nm: 'dash2', v: { a: 0, k: 0, ix: 3 } },
                    { n: 'g', nm: 'gap2', v: { a: 0, k: 66.557, ix: 4 } },
                    { n: 'o', nm: 'offset', v: { a: 0, k: 0, ix: 7 } },
                  ],
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [266.319, 266.319], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 600,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_4',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Group 1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [222.759, 231.976, 0], ix: 2, l: 2 },
            a: { a: 0, k: [52.437, 31.907, 0], ix: 1, l: 2 },
            s: { a: 0, k: [330, 330, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 0,
                        s: [
                          {
                            i: [
                              [-0.018, 0.205],
                              [0, 0],
                            ],
                            o: [
                              [-0.018, 0.205],
                              [0, 0],
                            ],
                            v: [
                              [0.018, -0.158],
                              [-0.018, 0.158],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-0.018, 0.205],
                              [0, 0],
                            ],
                            o: [
                              [-0.018, 0.205],
                              [0, 0],
                            ],
                            v: [
                              [0.018, -0.158],
                              [-0.018, 0.158],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 10.8,
                        s: [
                          {
                            i: [
                              [-0.018, 0.205],
                              [0, 0],
                            ],
                            o: [
                              [-0.018, 0.205],
                              [0, 0],
                            ],
                            v: [
                              [0.018, -0.158],
                              [-0.018, 0.158],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-0.018, 0.205],
                              [0, 0],
                            ],
                            o: [
                              [-0.018, 0.205],
                              [0, 0],
                            ],
                            v: [
                              [0.018, -0.158],
                              [-0.018, 0.158],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 22.8,
                        s: [
                          {
                            i: [
                              [-0.018, 0.205],
                              [0, 0],
                            ],
                            o: [
                              [-0.018, 0.205],
                              [0, 0],
                            ],
                            v: [
                              [0.018, -0.158],
                              [-0.018, 0.158],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-0.018, 0.205],
                              [0, 0],
                            ],
                            o: [
                              [-0.018, 0.205],
                              [0, 0],
                            ],
                            v: [
                              [0.018, -0.158],
                              [-0.018, 0.158],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 33.6,
                        s: [
                          {
                            i: [
                              [-0.018, 0.205],
                              [0, 0],
                            ],
                            o: [
                              [-0.018, 0.205],
                              [0, 0],
                            ],
                            v: [
                              [0.018, -0.158],
                              [-0.018, 0.158],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-0.018, 0.205],
                              [0, 0],
                            ],
                            o: [
                              [-0.018, 0.205],
                              [0, 0],
                            ],
                            v: [
                              [0.018, -0.158],
                              [-0.018, 0.158],
                            ],
                            c: true,
                          },
                        ],
                      },
                      { t: 44.4 },
                    ],
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.447000002394, 0.651000019148, 0.976000019148, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [79.852, 63.401], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Group 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [222.759, 231.976, 0], ix: 2, l: 2 },
            a: { a: 0, k: [52.437, 31.907, 0], ix: 1, l: 2 },
            s: { a: 0, k: [330, 330, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.333, y: 0 },
                        t: 0,
                        s: [
                          {
                            i: [
                              [-6.584, 5.948],
                              [-24.181, -7.829],
                              [0.019, -5.085],
                              [12.432, 4.215],
                              [8.86, -9.042],
                              [0, 0],
                              [-1.141, -0.952],
                            ],
                            o: [
                              [8.832, -7.973],
                              [12.15, 3.934],
                              [0.939, -6.251],
                              [-31.061, -10.53],
                              [-7.549, 7.704],
                              [0, 0],
                              [0.329, -3.403],
                            ],
                            v: [
                              [-42.252, -1.942],
                              [15.491, -3.879],
                              [31.869, 24.975],
                              [17.06, -6.605],
                              [-45.08, -3.049],
                              [-43.153, 15.372],
                              [-41.449, 16.933],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-6.584, 5.948],
                              [-25.095, -7.957],
                              [0.019, -5.085],
                              [12.584, 4.208],
                              [8.895, -9.007],
                              [0, 0],
                              [-1.034, -0.97],
                            ],
                            o: [
                              [8.832, -7.973],
                              [12.168, 3.875],
                              [0.939, -6.251],
                              [-31.1, -10.409],
                              [-7.543, 7.64],
                              [0, 0],
                              [0.329, -3.403],
                            ],
                            v: [
                              [-41.067, -1.751],
                              [16.81, -4.214],
                              [32.383, 22.208],
                              [18.217, -7.017],
                              [-43.741, -2.886],
                              [-42.986, 15.352],
                              [-41.449, 16.933],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 2.4,
                        s: [
                          {
                            i: [
                              [-6.584, 5.948],
                              [-25.095, -7.957],
                              [0.019, -5.085],
                              [12.584, 4.208],
                              [8.895, -9.007],
                              [0, 0],
                              [-1.034, -0.97],
                            ],
                            o: [
                              [8.832, -7.973],
                              [12.168, 3.875],
                              [0.939, -6.251],
                              [-31.1, -10.409],
                              [-7.543, 7.64],
                              [0, 0],
                              [0.329, -3.403],
                            ],
                            v: [
                              [-41.067, -1.751],
                              [16.81, -4.214],
                              [32.383, 22.208],
                              [18.217, -7.017],
                              [-43.741, -2.886],
                              [-42.986, 15.352],
                              [-41.449, 16.933],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-6.584, 5.948],
                              [-27.429, -8.284],
                              [0.019, -5.085],
                              [12.972, 4.19],
                              [8.983, -8.918],
                              [0, 0],
                              [-0.759, -1.014],
                            ],
                            o: [
                              [8.832, -7.973],
                              [12.214, 3.723],
                              [0.939, -6.251],
                              [-31.199, -10.1],
                              [-7.528, 7.477],
                              [0, 0],
                              [0.329, -3.403],
                            ],
                            v: [
                              [-38.042, -1.263],
                              [20.177, -5.068],
                              [34.315, 16.014],
                              [21.168, -8.065],
                              [-40.326, -2.472],
                              [-42.559, 15.304],
                              [-41.449, 16.933],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.167, y: 0.167 },
                        t: 4.8,
                        s: [
                          {
                            i: [
                              [-6.584, 5.948],
                              [-27.429, -8.284],
                              [0.019, -5.085],
                              [12.972, 4.19],
                              [8.983, -8.918],
                              [0, 0],
                              [-0.759, -1.014],
                            ],
                            o: [
                              [8.832, -7.973],
                              [12.214, 3.723],
                              [0.939, -6.251],
                              [-31.199, -10.1],
                              [-7.528, 7.477],
                              [0, 0],
                              [0.329, -3.403],
                            ],
                            v: [
                              [-38.042, -1.263],
                              [20.177, -5.068],
                              [34.315, 16.014],
                              [21.168, -8.065],
                              [-40.326, -2.472],
                              [-42.559, 15.304],
                              [-41.449, 16.933],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-6.584, 5.948],
                              [-31.428, -8.844],
                              [0.019, -5.085],
                              [13.636, 4.16],
                              [9.134, -8.765],
                              [0, 0],
                              [-0.289, -1.09],
                            ],
                            o: [
                              [8.832, -7.973],
                              [12.293, 3.462],
                              [0.939, -6.251],
                              [-31.37, -9.57],
                              [-7.501, 7.198],
                              [0, 0],
                              [0.329, -3.403],
                            ],
                            v: [
                              [-32.858, -0.427],
                              [25.946, -6.531],
                              [41.945, 7.248],
                              [26.227, -9.863],
                              [-34.473, -1.761],
                              [-41.827, 15.22],
                              [-41.449, 16.933],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.333, y: 0 },
                        t: 10.8,
                        s: [
                          {
                            i: [
                              [-6.584, 5.948],
                              [-31.428, -8.844],
                              [0.019, -5.085],
                              [13.636, 4.16],
                              [9.134, -8.765],
                              [0, 0],
                              [-0.289, -1.09],
                            ],
                            o: [
                              [8.832, -7.973],
                              [12.293, 3.462],
                              [0.939, -6.251],
                              [-31.37, -9.57],
                              [-7.501, 7.198],
                              [0, 0],
                              [0.329, -3.403],
                            ],
                            v: [
                              [-32.858, -0.427],
                              [25.946, -6.531],
                              [41.945, 7.248],
                              [26.227, -9.863],
                              [-34.473, -1.761],
                              [-41.827, 15.22],
                              [-41.449, 16.933],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-6.584, 5.948],
                              [-30.674, -8.739],
                              [0.019, -5.085],
                              [13.511, 4.166],
                              [9.106, -8.794],
                              [0, 0],
                              [-0.378, -1.076],
                            ],
                            o: [
                              [8.832, -7.973],
                              [12.278, 3.511],
                              [0.939, -6.251],
                              [-31.338, -9.67],
                              [-7.506, 7.251],
                              [0, 0],
                              [0.329, -3.403],
                            ],
                            v: [
                              [-33.835, -0.585],
                              [24.858, -6.255],
                              [40.139, 8.94],
                              [25.273, -9.524],
                              [-35.577, -1.895],
                              [-41.965, 15.236],
                              [-41.449, 16.933],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 13.2,
                        s: [
                          {
                            i: [
                              [-6.584, 5.948],
                              [-30.674, -8.739],
                              [0.019, -5.085],
                              [13.511, 4.166],
                              [9.106, -8.794],
                              [0, 0],
                              [-0.378, -1.076],
                            ],
                            o: [
                              [8.832, -7.973],
                              [12.278, 3.511],
                              [0.939, -6.251],
                              [-31.338, -9.67],
                              [-7.506, 7.251],
                              [0, 0],
                              [0.329, -3.403],
                            ],
                            v: [
                              [-33.835, -0.585],
                              [24.858, -6.255],
                              [40.139, 8.94],
                              [25.273, -9.524],
                              [-35.577, -1.895],
                              [-41.965, 15.236],
                              [-41.449, 16.933],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-6.584, 5.948],
                              [-27.528, -8.298],
                              [0.019, -5.085],
                              [12.988, 4.189],
                              [8.987, -8.914],
                              [0, 0],
                              [-0.748, -1.016],
                            ],
                            o: [
                              [8.832, -7.973],
                              [12.216, 3.716],
                              [2.072, -8.948],
                              [-31.204, -10.087],
                              [-7.527, 7.47],
                              [0, 0],
                              [0.329, -3.403],
                            ],
                            v: [
                              [-37.914, -1.242],
                              [20.32, -5.104],
                              [34.844, 16.179],
                              [21.294, -8.11],
                              [-40.181, -2.454],
                              [-42.541, 15.302],
                              [-41.449, 16.933],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 16.8,
                        s: [
                          {
                            i: [
                              [-6.584, 5.948],
                              [-27.528, -8.298],
                              [0.019, -5.085],
                              [12.988, 4.189],
                              [8.987, -8.914],
                              [0, 0],
                              [-0.748, -1.016],
                            ],
                            o: [
                              [8.832, -7.973],
                              [12.216, 3.716],
                              [2.072, -8.948],
                              [-31.204, -10.087],
                              [-7.527, 7.47],
                              [0, 0],
                              [0.329, -3.403],
                            ],
                            v: [
                              [-37.914, -1.242],
                              [20.32, -5.104],
                              [34.844, 16.179],
                              [21.294, -8.11],
                              [-40.181, -2.454],
                              [-42.541, 15.302],
                              [-41.449, 16.933],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-6.584, 5.948],
                              [-25.8, -8.056],
                              [0.019, -5.085],
                              [12.701, 4.202],
                              [8.922, -8.98],
                              [0, 0],
                              [-0.951, -0.983],
                            ],
                            o: [
                              [8.832, -7.973],
                              [12.182, 3.829],
                              [1.487, -7.556],
                              [-31.13, -10.315],
                              [-7.539, 7.591],
                              [0, 0],
                              [0.329, -3.403],
                            ],
                            v: [
                              [-40.153, -1.604],
                              [17.828, -4.472],
                              [32.703, 20.415],
                              [19.109, -7.334],
                              [-42.709, -2.761],
                              [-42.857, 15.338],
                              [-41.449, 16.933],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.167, y: 0.167 },
                        t: 19.2,
                        s: [
                          {
                            i: [
                              [-6.584, 5.948],
                              [-25.8, -8.056],
                              [0.019, -5.085],
                              [12.701, 4.202],
                              [8.922, -8.98],
                              [0, 0],
                              [-0.951, -0.983],
                            ],
                            o: [
                              [8.832, -7.973],
                              [12.182, 3.829],
                              [1.487, -7.556],
                              [-31.13, -10.315],
                              [-7.539, 7.591],
                              [0, 0],
                              [0.329, -3.403],
                            ],
                            v: [
                              [-40.153, -1.604],
                              [17.828, -4.472],
                              [32.703, 20.415],
                              [19.109, -7.334],
                              [-42.709, -2.761],
                              [-42.857, 15.338],
                              [-41.449, 16.933],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-6.584, 5.948],
                              [-24.181, -7.829],
                              [0.019, -5.085],
                              [12.432, 4.215],
                              [8.86, -9.042],
                              [0, 0],
                              [-1.141, -0.952],
                            ],
                            o: [
                              [8.832, -7.973],
                              [12.15, 3.934],
                              [0.939, -6.251],
                              [-31.061, -10.53],
                              [-7.549, 7.704],
                              [0, 0],
                              [0.329, -3.403],
                            ],
                            v: [
                              [-42.252, -1.942],
                              [15.491, -3.879],
                              [31.869, 24.975],
                              [17.06, -6.605],
                              [-45.08, -3.049],
                              [-43.153, 15.372],
                              [-41.449, 16.933],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.333, y: 0 },
                        t: 22.8,
                        s: [
                          {
                            i: [
                              [-6.584, 5.948],
                              [-24.181, -7.829],
                              [0.019, -5.085],
                              [12.432, 4.215],
                              [8.86, -9.042],
                              [0, 0],
                              [-1.141, -0.952],
                            ],
                            o: [
                              [8.832, -7.973],
                              [12.15, 3.934],
                              [0.939, -6.251],
                              [-31.061, -10.53],
                              [-7.549, 7.704],
                              [0, 0],
                              [0.329, -3.403],
                            ],
                            v: [
                              [-42.252, -1.942],
                              [15.491, -3.879],
                              [31.869, 24.975],
                              [17.06, -6.605],
                              [-45.08, -3.049],
                              [-43.153, 15.372],
                              [-41.449, 16.933],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-6.584, 5.948],
                              [-25.095, -7.957],
                              [0.019, -5.085],
                              [12.584, 4.208],
                              [8.895, -9.007],
                              [0, 0],
                              [-1.034, -0.97],
                            ],
                            o: [
                              [8.832, -7.973],
                              [12.168, 3.875],
                              [0.939, -6.251],
                              [-31.1, -10.409],
                              [-7.543, 7.64],
                              [0, 0],
                              [0.329, -3.403],
                            ],
                            v: [
                              [-41.067, -1.751],
                              [16.81, -4.214],
                              [32.383, 22.208],
                              [18.217, -7.017],
                              [-43.741, -2.886],
                              [-42.986, 15.352],
                              [-41.449, 16.933],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 25.2,
                        s: [
                          {
                            i: [
                              [-6.584, 5.948],
                              [-25.095, -7.957],
                              [0.019, -5.085],
                              [12.584, 4.208],
                              [8.895, -9.007],
                              [0, 0],
                              [-1.034, -0.97],
                            ],
                            o: [
                              [8.832, -7.973],
                              [12.168, 3.875],
                              [0.939, -6.251],
                              [-31.1, -10.409],
                              [-7.543, 7.64],
                              [0, 0],
                              [0.329, -3.403],
                            ],
                            v: [
                              [-41.067, -1.751],
                              [16.81, -4.214],
                              [32.383, 22.208],
                              [18.217, -7.017],
                              [-43.741, -2.886],
                              [-42.986, 15.352],
                              [-41.449, 16.933],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-6.584, 5.948],
                              [-27.429, -8.284],
                              [0.019, -5.085],
                              [12.972, 4.19],
                              [8.983, -8.918],
                              [0, 0],
                              [-0.759, -1.014],
                            ],
                            o: [
                              [8.832, -7.973],
                              [12.214, 3.723],
                              [0.939, -6.251],
                              [-31.199, -10.1],
                              [-7.528, 7.477],
                              [0, 0],
                              [0.329, -3.403],
                            ],
                            v: [
                              [-38.042, -1.263],
                              [20.177, -5.068],
                              [34.315, 16.014],
                              [21.168, -8.065],
                              [-40.326, -2.472],
                              [-42.559, 15.304],
                              [-41.449, 16.933],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.167, y: 0.167 },
                        t: 27.6,
                        s: [
                          {
                            i: [
                              [-6.584, 5.948],
                              [-27.429, -8.284],
                              [0.019, -5.085],
                              [12.972, 4.19],
                              [8.983, -8.918],
                              [0, 0],
                              [-0.759, -1.014],
                            ],
                            o: [
                              [8.832, -7.973],
                              [12.214, 3.723],
                              [0.939, -6.251],
                              [-31.199, -10.1],
                              [-7.528, 7.477],
                              [0, 0],
                              [0.329, -3.403],
                            ],
                            v: [
                              [-38.042, -1.263],
                              [20.177, -5.068],
                              [34.315, 16.014],
                              [21.168, -8.065],
                              [-40.326, -2.472],
                              [-42.559, 15.304],
                              [-41.449, 16.933],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-6.584, 5.948],
                              [-31.428, -8.844],
                              [0.019, -5.085],
                              [13.636, 4.16],
                              [9.134, -8.765],
                              [0, 0],
                              [-0.289, -1.09],
                            ],
                            o: [
                              [8.832, -7.973],
                              [12.293, 3.462],
                              [0.939, -6.251],
                              [-31.37, -9.57],
                              [-7.501, 7.198],
                              [0, 0],
                              [0.329, -3.403],
                            ],
                            v: [
                              [-32.858, -0.427],
                              [25.946, -6.531],
                              [41.945, 7.248],
                              [26.227, -9.863],
                              [-34.473, -1.761],
                              [-41.827, 15.22],
                              [-41.449, 16.933],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.333, y: 0 },
                        t: 33.6,
                        s: [
                          {
                            i: [
                              [-6.584, 5.948],
                              [-31.428, -8.844],
                              [0.019, -5.085],
                              [13.636, 4.16],
                              [9.134, -8.765],
                              [0, 0],
                              [-0.289, -1.09],
                            ],
                            o: [
                              [8.832, -7.973],
                              [12.293, 3.462],
                              [0.939, -6.251],
                              [-31.37, -9.57],
                              [-7.501, 7.198],
                              [0, 0],
                              [0.329, -3.403],
                            ],
                            v: [
                              [-32.858, -0.427],
                              [25.946, -6.531],
                              [41.945, 7.248],
                              [26.227, -9.863],
                              [-34.473, -1.761],
                              [-41.827, 15.22],
                              [-41.449, 16.933],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-6.584, 5.948],
                              [-30.674, -8.739],
                              [0.019, -5.085],
                              [13.511, 4.166],
                              [9.106, -8.794],
                              [0, 0],
                              [-0.378, -1.076],
                            ],
                            o: [
                              [8.832, -7.973],
                              [12.278, 3.511],
                              [0.939, -6.251],
                              [-31.338, -9.67],
                              [-7.506, 7.251],
                              [0, 0],
                              [0.329, -3.403],
                            ],
                            v: [
                              [-33.835, -0.585],
                              [24.858, -6.255],
                              [40.139, 8.94],
                              [25.273, -9.524],
                              [-35.577, -1.895],
                              [-41.965, 15.236],
                              [-41.449, 16.933],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 36,
                        s: [
                          {
                            i: [
                              [-6.584, 5.948],
                              [-30.674, -8.739],
                              [0.019, -5.085],
                              [13.511, 4.166],
                              [9.106, -8.794],
                              [0, 0],
                              [-0.378, -1.076],
                            ],
                            o: [
                              [8.832, -7.973],
                              [12.278, 3.511],
                              [0.939, -6.251],
                              [-31.338, -9.67],
                              [-7.506, 7.251],
                              [0, 0],
                              [0.329, -3.403],
                            ],
                            v: [
                              [-33.835, -0.585],
                              [24.858, -6.255],
                              [40.139, 8.94],
                              [25.273, -9.524],
                              [-35.577, -1.895],
                              [-41.965, 15.236],
                              [-41.449, 16.933],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-6.584, 5.948],
                              [-27.528, -8.298],
                              [0.019, -5.085],
                              [12.988, 4.189],
                              [8.987, -8.914],
                              [0, 0],
                              [-0.748, -1.016],
                            ],
                            o: [
                              [8.832, -7.973],
                              [12.216, 3.716],
                              [2.072, -8.948],
                              [-31.204, -10.087],
                              [-7.527, 7.47],
                              [0, 0],
                              [0.329, -3.403],
                            ],
                            v: [
                              [-37.914, -1.242],
                              [20.32, -5.104],
                              [34.844, 16.179],
                              [21.294, -8.11],
                              [-40.181, -2.454],
                              [-42.541, 15.302],
                              [-41.449, 16.933],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 39.6,
                        s: [
                          {
                            i: [
                              [-6.584, 5.948],
                              [-27.528, -8.298],
                              [0.019, -5.085],
                              [12.988, 4.189],
                              [8.987, -8.914],
                              [0, 0],
                              [-0.748, -1.016],
                            ],
                            o: [
                              [8.832, -7.973],
                              [12.216, 3.716],
                              [2.072, -8.948],
                              [-31.204, -10.087],
                              [-7.527, 7.47],
                              [0, 0],
                              [0.329, -3.403],
                            ],
                            v: [
                              [-37.914, -1.242],
                              [20.32, -5.104],
                              [34.844, 16.179],
                              [21.294, -8.11],
                              [-40.181, -2.454],
                              [-42.541, 15.302],
                              [-41.449, 16.933],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-6.584, 5.948],
                              [-25.8, -8.056],
                              [0.019, -5.085],
                              [12.701, 4.202],
                              [8.922, -8.98],
                              [0, 0],
                              [-0.951, -0.983],
                            ],
                            o: [
                              [8.832, -7.973],
                              [12.182, 3.829],
                              [1.487, -7.556],
                              [-31.13, -10.315],
                              [-7.539, 7.591],
                              [0, 0],
                              [0.329, -3.403],
                            ],
                            v: [
                              [-40.153, -1.604],
                              [17.828, -4.472],
                              [32.703, 20.415],
                              [19.109, -7.334],
                              [-42.709, -2.761],
                              [-42.857, 15.338],
                              [-41.449, 16.933],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.167, y: 0.167 },
                        t: 42,
                        s: [
                          {
                            i: [
                              [-6.584, 5.948],
                              [-25.8, -8.056],
                              [0.019, -5.085],
                              [12.701, 4.202],
                              [8.922, -8.98],
                              [0, 0],
                              [-0.951, -0.983],
                            ],
                            o: [
                              [8.832, -7.973],
                              [12.182, 3.829],
                              [1.487, -7.556],
                              [-31.13, -10.315],
                              [-7.539, 7.591],
                              [0, 0],
                              [0.329, -3.403],
                            ],
                            v: [
                              [-40.153, -1.604],
                              [17.828, -4.472],
                              [32.703, 20.415],
                              [19.109, -7.334],
                              [-42.709, -2.761],
                              [-42.857, 15.338],
                              [-41.449, 16.933],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-6.584, 5.948],
                              [-24.181, -7.829],
                              [0.019, -5.085],
                              [12.432, 4.215],
                              [8.86, -9.042],
                              [0, 0],
                              [-1.141, -0.952],
                            ],
                            o: [
                              [8.832, -7.973],
                              [12.15, 3.934],
                              [0.939, -6.251],
                              [-31.061, -10.53],
                              [-7.549, 7.704],
                              [0, 0],
                              [0.329, -3.403],
                            ],
                            v: [
                              [-42.252, -1.942],
                              [15.491, -3.879],
                              [31.869, 24.975],
                              [17.06, -6.605],
                              [-45.08, -3.049],
                              [-43.153, 15.372],
                              [-41.449, 16.933],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 44.4,
                        s: [
                          {
                            i: [
                              [-6.584, 5.948],
                              [-24.181, -7.829],
                              [0.019, -5.085],
                              [12.432, 4.215],
                              [8.86, -9.042],
                              [0, 0],
                              [-1.141, -0.952],
                            ],
                            o: [
                              [8.832, -7.973],
                              [12.15, 3.934],
                              [0.939, -6.251],
                              [-31.061, -10.53],
                              [-7.549, 7.704],
                              [0, 0],
                              [0.329, -3.403],
                            ],
                            v: [
                              [-42.252, -1.942],
                              [15.491, -3.879],
                              [31.869, 24.975],
                              [17.06, -6.605],
                              [-45.08, -3.049],
                              [-43.153, 15.372],
                              [-41.449, 16.933],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-6.584, 5.948],
                              [-24.181, -7.829],
                              [0.019, -5.085],
                              [12.432, 4.215],
                              [8.86, -9.042],
                              [0, 0],
                              [-1.141, -0.952],
                            ],
                            o: [
                              [8.832, -7.973],
                              [12.15, 3.934],
                              [0.939, -6.251],
                              [-31.061, -10.53],
                              [-7.549, 7.704],
                              [0, 0],
                              [0.329, -3.403],
                            ],
                            v: [
                              [-42.252, -1.942],
                              [15.491, -3.879],
                              [31.869, 24.975],
                              [17.06, -6.605],
                              [-45.08, -3.049],
                              [-43.153, 15.372],
                              [-41.449, 16.933],
                            ],
                            c: true,
                          },
                        ],
                      },
                      { t: 45.6 },
                    ],
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.447000002394, 0.651000019148, 0.976000019148, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [47.942, 38.32], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Group 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [222.759, 231.976, 0], ix: 2, l: 2 },
            a: { a: 0, k: [52.437, 31.907, 0], ix: 1, l: 2 },
            s: { a: 0, k: [330, 330, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 0,
                        s: [
                          {
                            i: [
                              [-2.159, -2.434],
                              [-28.18, -4.863],
                              [0, 0],
                              [32.064, 6.89],
                              [4.235, -7.711],
                            ],
                            o: [
                              [0, 0],
                              [23.416, 4.04],
                              [0, 0],
                              [-30.138, -6.476],
                              [-5.495, 10.007],
                            ],
                            v: [
                              [-48.964, 12.775],
                              [-11.513, 2.11],
                              [24.379, 21.081],
                              [-3.712, -13.789],
                              [-52.446, -5.423],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-0.209, -2.563],
                              [-28.343, -3.802],
                              [0, 0],
                              [31.945, 7.421],
                              [8.916, -8.982],
                            ],
                            o: [
                              [0, 0],
                              [21.83, 2.928],
                              [0, 0],
                              [-25.593, -5.945],
                              [-8.916, 8.983],
                            ],
                            v: [
                              [-48.964, 12.775],
                              [1.138, 1.125],
                              [24.379, 21.081],
                              [18.636, -13.032],
                              [-40.173, -6.938],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 10.8,
                        s: [
                          {
                            i: [
                              [-0.209, -2.563],
                              [-28.343, -3.802],
                              [0, 0],
                              [31.945, 7.421],
                              [8.916, -8.982],
                            ],
                            o: [
                              [0, 0],
                              [21.83, 2.928],
                              [0, 0],
                              [-25.593, -5.945],
                              [-8.916, 8.983],
                            ],
                            v: [
                              [-48.964, 12.775],
                              [1.138, 1.125],
                              [24.379, 21.081],
                              [18.636, -13.032],
                              [-40.173, -6.938],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-2.159, -2.434],
                              [-28.18, -4.863],
                              [0, 0],
                              [32.064, 6.89],
                              [4.235, -7.711],
                            ],
                            o: [
                              [0, 0],
                              [23.416, 4.04],
                              [0, 0],
                              [-30.138, -6.476],
                              [-5.495, 10.007],
                            ],
                            v: [
                              [-48.964, 12.775],
                              [-11.513, 2.11],
                              [24.379, 21.081],
                              [-3.712, -13.789],
                              [-52.446, -5.423],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 22.8,
                        s: [
                          {
                            i: [
                              [-2.159, -2.434],
                              [-28.18, -4.863],
                              [0, 0],
                              [32.064, 6.89],
                              [4.235, -7.711],
                            ],
                            o: [
                              [0, 0],
                              [23.416, 4.04],
                              [0, 0],
                              [-30.138, -6.476],
                              [-5.495, 10.007],
                            ],
                            v: [
                              [-48.964, 12.775],
                              [-11.513, 2.11],
                              [24.379, 21.081],
                              [-3.712, -13.789],
                              [-52.446, -5.423],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-0.209, -2.563],
                              [-28.343, -3.802],
                              [0, 0],
                              [31.945, 7.421],
                              [8.916, -8.982],
                            ],
                            o: [
                              [0, 0],
                              [21.83, 2.928],
                              [0, 0],
                              [-25.593, -5.945],
                              [-8.916, 8.983],
                            ],
                            v: [
                              [-48.964, 12.775],
                              [1.138, 1.125],
                              [24.379, 21.081],
                              [18.636, -13.032],
                              [-40.173, -6.938],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 33.6,
                        s: [
                          {
                            i: [
                              [-0.209, -2.563],
                              [-28.343, -3.802],
                              [0, 0],
                              [31.945, 7.421],
                              [8.916, -8.982],
                            ],
                            o: [
                              [0, 0],
                              [21.83, 2.928],
                              [0, 0],
                              [-25.593, -5.945],
                              [-8.916, 8.983],
                            ],
                            v: [
                              [-48.964, 12.775],
                              [1.138, 1.125],
                              [24.379, 21.081],
                              [18.636, -13.032],
                              [-40.173, -6.938],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-2.159, -2.434],
                              [-28.18, -4.863],
                              [0, 0],
                              [32.064, 6.89],
                              [4.235, -7.711],
                            ],
                            o: [
                              [0, 0],
                              [23.416, 4.04],
                              [0, 0],
                              [-30.138, -6.476],
                              [-5.495, 10.007],
                            ],
                            v: [
                              [-48.964, 12.775],
                              [-11.513, 2.11],
                              [24.379, 21.081],
                              [-3.712, -13.789],
                              [-52.446, -5.423],
                            ],
                            c: true,
                          },
                        ],
                      },
                      { t: 44.4 },
                    ],
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.294000004787, 0.541000007181, 0.961000031116, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [55.451, 42.482], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Group 4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [197.917, 180.789, 0], ix: 2, l: 2 },
            a: { a: 0, k: [44.909, 16.395, 0], ix: 1, l: 2 },
            s: { a: 0, k: [330, 330, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 0,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-5.189, -10.02],
                              [-10.016, 9.177],
                              [-3.214, 8.047],
                              [-2.751, 14.868],
                              [-0.482, 13.289],
                              [2.44, 2.958],
                              [-4.112, 3.776],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [1.175, -11.383],
                              [-3.652, 7.814],
                              [3.15, 6.684],
                              [3.613, 13.505],
                              [5.882, 11.926],
                              [8.804, 1.595],
                              [2.252, 2.413],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 10.8,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [1.175, -11.383],
                              [-3.652, 7.814],
                              [3.15, 6.684],
                              [3.613, 13.505],
                              [5.882, 11.926],
                              [8.804, 1.595],
                              [2.252, 2.413],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-5.189, -10.02],
                              [-10.016, 9.177],
                              [-3.214, 8.047],
                              [-2.751, 14.868],
                              [-0.482, 13.289],
                              [2.44, 2.958],
                              [-4.112, 3.776],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 22.8,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-5.189, -10.02],
                              [-10.016, 9.177],
                              [-3.214, 8.047],
                              [-2.751, 14.868],
                              [-0.482, 13.289],
                              [2.44, 2.958],
                              [-4.112, 3.776],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [1.175, -11.383],
                              [-3.652, 7.814],
                              [3.15, 6.684],
                              [3.613, 13.505],
                              [5.882, 11.926],
                              [8.804, 1.595],
                              [2.252, 2.413],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 33.6,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [1.175, -11.383],
                              [-3.652, 7.814],
                              [3.15, 6.684],
                              [3.613, 13.505],
                              [5.882, 11.926],
                              [8.804, 1.595],
                              [2.252, 2.413],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-5.189, -10.02],
                              [-10.016, 9.177],
                              [-3.214, 8.047],
                              [-2.751, 14.868],
                              [-0.482, 13.289],
                              [2.44, 2.958],
                              [-4.112, 3.776],
                            ],
                            c: true,
                          },
                        ],
                      },
                      { t: 44.4 },
                    ],
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [44.909, 16.395], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Group 5',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [222.759, 231.976, 0], ix: 2, l: 2 },
            a: { a: 0, k: [52.437, 31.907, 0], ix: 1, l: 2 },
            s: { a: 0, k: [330, 330, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 0,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [-21.293, 8.84],
                              [2.216, -31.099],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-12.458, 16.431],
                              [12.016, -16.536],
                              [-15.35, 20.173],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [0, 0],
                              [-21.293, 8.84],
                              [2.216, -31.099],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-8.518, 14.461],
                              [15.956, -18.506],
                              [-11.41, 18.203],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 10.8,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [-21.293, 8.84],
                              [2.216, -31.099],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-8.518, 14.461],
                              [15.956, -18.506],
                              [-11.41, 18.203],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [0, 0],
                              [-21.293, 8.84],
                              [2.216, -31.099],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-12.458, 16.431],
                              [12.016, -16.536],
                              [-15.35, 20.173],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 22.8,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [-21.293, 8.84],
                              [2.216, -31.099],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-12.458, 16.431],
                              [12.016, -16.536],
                              [-15.35, 20.173],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [0, 0],
                              [-21.293, 8.84],
                              [2.216, -31.099],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-8.518, 14.461],
                              [15.956, -18.506],
                              [-11.41, 18.203],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 33.6,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [-21.293, 8.84],
                              [2.216, -31.099],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-8.518, 14.461],
                              [15.956, -18.506],
                              [-11.41, 18.203],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [0, 0],
                              [-21.293, 8.84],
                              [2.216, -31.099],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-12.458, 16.431],
                              [12.016, -16.536],
                              [-15.35, 20.173],
                            ],
                            c: true,
                          },
                        ],
                      },
                      { t: 44.4 },
                    ],
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.447000002394, 0.651000019148, 0.976000019148, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [24.601, 23.2], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'Group 6',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [222.759, 231.976, 0], ix: 2, l: 2 },
            a: { a: 0, k: [52.437, 31.907, 0], ix: 1, l: 2 },
            s: { a: 0, k: [330, 330, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 0,
                        s: [
                          {
                            i: [
                              [-6.728, -21.929],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [-4.853, -23.17],
                              [0, 0],
                            ],
                            v: [
                              [5.832, 13.181],
                              [8.367, 14.569],
                              [-12.307, -11.538],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-6.728, -21.929],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [-4.853, -23.17],
                              [0, 0],
                            ],
                            v: [
                              [9.62, 11.969],
                              [12.155, 13.357],
                              [-8.519, -12.75],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 10.8,
                        s: [
                          {
                            i: [
                              [-6.728, -21.929],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [-4.853, -23.17],
                              [0, 0],
                            ],
                            v: [
                              [9.62, 11.969],
                              [12.155, 13.357],
                              [-8.519, -12.75],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-6.728, -21.929],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [-4.853, -23.17],
                              [0, 0],
                            ],
                            v: [
                              [5.832, 13.181],
                              [8.367, 14.569],
                              [-12.307, -11.538],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 22.8,
                        s: [
                          {
                            i: [
                              [-6.728, -21.929],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [-4.853, -23.17],
                              [0, 0],
                            ],
                            v: [
                              [5.832, 13.181],
                              [8.367, 14.569],
                              [-12.307, -11.538],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-6.728, -21.929],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [-4.853, -23.17],
                              [0, 0],
                            ],
                            v: [
                              [9.62, 11.969],
                              [12.155, 13.357],
                              [-8.519, -12.75],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 33.6,
                        s: [
                          {
                            i: [
                              [-6.728, -21.929],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [-4.853, -23.17],
                              [0, 0],
                            ],
                            v: [
                              [9.62, 11.969],
                              [12.155, 13.357],
                              [-8.519, -12.75],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-6.728, -21.929],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [-4.853, -23.17],
                              [0, 0],
                            ],
                            v: [
                              [5.832, 13.181],
                              [8.367, 14.569],
                              [-12.307, -11.538],
                            ],
                            c: true,
                          },
                        ],
                      },
                      { t: 44.4 },
                    ],
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.447000002394, 0.651000019148, 0.976000019148, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [57.271, 17.583], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'Group 7',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [222.759, 231.976, 0], ix: 2, l: 2 },
            a: { a: 0, k: [52.437, 31.907, 0], ix: 1, l: 2 },
            s: { a: 0, k: [330, 330, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 0,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [-13.723, 16.808],
                              [-6.615, -0.688],
                              [1.645, -31.948],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [10.705, -13.112],
                              [10.652, 1.108],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-31.811, 27.408],
                              [-24.208, -10.359],
                              [6.603, -25.034],
                              [38.338, 21.204],
                              [0.196, 9.474],
                              [-21.044, 14.119],
                              [-26.183, 20.988],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [0, 0],
                              [-13.979, 16.596],
                              [-6.407, -1.784],
                              [-3.684, -20.425],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [13.979, -16.594],
                              [6.41, 1.784],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-31.811, 27.408],
                              [-24.056, -10.814],
                              [11.755, -24.125],
                              [38.035, 6.355],
                              [0.196, 9.474],
                              [-21.044, 14.119],
                              [-26.183, 20.988],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 10.8,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [-13.979, 16.596],
                              [-6.407, -1.784],
                              [-3.684, -20.425],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [13.979, -16.594],
                              [6.41, 1.784],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-31.811, 27.408],
                              [-24.056, -10.814],
                              [11.755, -24.125],
                              [38.035, 6.355],
                              [0.196, 9.474],
                              [-21.044, 14.119],
                              [-26.183, 20.988],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [0, 0],
                              [-13.723, 16.808],
                              [-6.615, -0.688],
                              [1.645, -31.948],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [10.705, -13.112],
                              [10.652, 1.108],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-31.811, 27.408],
                              [-24.208, -10.359],
                              [6.603, -25.034],
                              [38.338, 21.204],
                              [0.196, 9.474],
                              [-21.044, 14.119],
                              [-26.183, 20.988],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 22.8,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [-13.723, 16.808],
                              [-6.615, -0.688],
                              [1.645, -31.948],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [10.705, -13.112],
                              [10.652, 1.108],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-31.811, 27.408],
                              [-24.208, -10.359],
                              [6.603, -25.034],
                              [38.338, 21.204],
                              [0.196, 9.474],
                              [-21.044, 14.119],
                              [-26.183, 20.988],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [0, 0],
                              [-13.979, 16.596],
                              [-6.407, -1.784],
                              [-3.684, -20.425],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [13.979, -16.594],
                              [6.41, 1.784],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-31.811, 27.408],
                              [-24.056, -10.814],
                              [11.755, -24.125],
                              [38.035, 6.355],
                              [0.196, 9.474],
                              [-21.044, 14.119],
                              [-26.183, 20.988],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 33.6,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [-13.979, 16.596],
                              [-6.407, -1.784],
                              [-3.684, -20.425],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [13.979, -16.594],
                              [6.41, 1.784],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-31.811, 27.408],
                              [-24.056, -10.814],
                              [11.755, -24.125],
                              [38.035, 6.355],
                              [0.196, 9.474],
                              [-21.044, 14.119],
                              [-26.183, 20.988],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [0, 0],
                              [-13.723, 16.808],
                              [-6.615, -0.688],
                              [1.645, -31.948],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [10.705, -13.112],
                              [10.652, 1.108],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-31.811, 27.408],
                              [-24.208, -10.359],
                              [6.603, -25.034],
                              [38.338, 21.204],
                              [0.196, 9.474],
                              [-21.044, 14.119],
                              [-26.183, 20.988],
                            ],
                            c: true,
                          },
                        ],
                      },
                      { t: 44.4 },
                    ],
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.294000004787, 0.541000007181, 0.961000031116, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [38.285, 27.658], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 7',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: 'circle',
          parent: 16,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [116.163, 202.585, 0], ix: 2, l: 2 },
            a: { a: 0, k: [127.163, 205.585, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -22.407],
                        [22.406, 0],
                        [0, 22.406],
                        [-22.407, 0],
                      ],
                      o: [
                        [0, 22.406],
                        [-22.407, 0],
                        [0, -22.407],
                        [22.406, 0],
                      ],
                      v: [
                        [40.571, 0],
                        [0.001, 40.57],
                        [-40.571, 0],
                        [0.001, -40.57],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.969000004787, 0.57599995931, 0.102000000898, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [125.836, 40.82], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 44.4,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: 'antenne_line',
          parent: 16,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [127.163, 205.585, 0], ix: 2, l: 2 },
            a: { a: 0, k: [127.163, 205.585, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-23.779, -59.359],
                        [0, 0],
                      ],
                      o: [
                        [23.779, 59.359],
                        [0, 0],
                      ],
                      v: [
                        [106.163, 42.147],
                        [127.163, 172.673],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.207843154669, 0.207843154669, 0.207843154669, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 17.695, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 44.4,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: 'antenne_bottom',
          parent: 16,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [127.163, 205.585, 0], ix: 2, l: 2 },
            a: { a: 0, k: [127.163, 205.585, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -10.976],
                        [31.149, 0],
                        [0, 10.976],
                        [-31.149, 0],
                      ],
                      o: [
                        [0, 10.976],
                        [-31.149, 0],
                        [0, -10.976],
                        [31.149, 0],
                      ],
                      v: [
                        [56.4, 0],
                        [0, 19.875],
                        [-56.4, 0],
                        [0, -19.875],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.20800000359, 0.20800000359, 0.20800000359, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [127.163, 172.672], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 44.4,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 4,
          nm: 'Group 6',
          parent: 15,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [151.163, 205.585, 0], ix: 2, l: 2 },
            a: { a: 0, k: [127.163, 205.585, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 0,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [-63.568, -32.273],
                              [8.128, 14.33],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [88.18, -102.174],
                              [-2.3, -14.23],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-98.483, 52.128],
                              [112.987, 52.127],
                              [96.209, 4.863],
                              [62.608, -31.461],
                              [-5.665, -49.719],
                              [-58.44, -27.965],
                              [-86.236, -0.495],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [0, 0],
                              [-42.29, -39.673],
                              [8.128, 14.33],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [88.18, -102.174],
                              [-2.3, -14.23],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-98.483, 52.128],
                              [101.487, 43.627],
                              [84.709, -3.637],
                              [59.108, -28.961],
                              [-5.665, -49.719],
                              [-58.44, -27.965],
                              [-86.236, -0.495],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 10.8,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [-42.29, -39.673],
                              [8.128, 14.33],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [88.18, -102.174],
                              [-2.3, -14.23],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-98.483, 52.128],
                              [101.487, 43.627],
                              [84.709, -3.637],
                              [59.108, -28.961],
                              [-5.665, -49.719],
                              [-58.44, -27.965],
                              [-86.236, -0.495],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [0, 0],
                              [-63.568, -32.273],
                              [8.128, 14.33],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [88.18, -102.174],
                              [-2.3, -14.23],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-98.483, 52.128],
                              [112.987, 52.127],
                              [96.209, 4.863],
                              [62.608, -31.461],
                              [-5.665, -49.719],
                              [-58.44, -27.965],
                              [-86.236, -0.495],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 22.8,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [-63.568, -32.273],
                              [8.128, 14.33],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [88.18, -102.174],
                              [-2.3, -14.23],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-98.483, 52.128],
                              [112.987, 52.127],
                              [96.209, 4.863],
                              [62.608, -31.461],
                              [-5.665, -49.719],
                              [-58.44, -27.965],
                              [-86.236, -0.495],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [0, 0],
                              [-42.29, -39.673],
                              [8.128, 14.33],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [88.18, -102.174],
                              [-2.3, -14.23],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-98.483, 52.128],
                              [101.487, 43.627],
                              [84.709, -3.637],
                              [59.108, -28.961],
                              [-5.665, -49.719],
                              [-58.44, -27.965],
                              [-86.236, -0.495],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 33.6,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [-42.29, -39.673],
                              [8.128, 14.33],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [88.18, -102.174],
                              [-2.3, -14.23],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-98.483, 52.128],
                              [101.487, 43.627],
                              [84.709, -3.637],
                              [59.108, -28.961],
                              [-5.665, -49.719],
                              [-58.44, -27.965],
                              [-86.236, -0.495],
                            ],
                            c: true,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [0, 0],
                              [-63.568, -32.273],
                              [8.128, 14.33],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [88.18, -102.174],
                              [-2.3, -14.23],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-98.483, 52.128],
                              [112.987, 52.127],
                              [96.209, 4.863],
                              [62.608, -31.461],
                              [-5.665, -49.719],
                              [-58.44, -27.965],
                              [-86.236, -0.495],
                            ],
                            c: true,
                          },
                        ],
                      },
                      { t: 44.4 },
                    ],
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.969000004787, 0.57599995931, 0.102000000898, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [135.246, 210.051], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 44.4,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 12,
          ty: 4,
          nm: 'blick_2',
          parent: 14,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 0,
                  s: [127.163, 205.585, 0],
                  e: [125.163, 195.585, 0],
                  to: [-0.333, -1.667, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 10.8,
                  s: [125.163, 195.585, 0],
                  e: [127.163, 205.585, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 22.8,
                  s: [127.163, 205.585, 0],
                  e: [125.163, 195.585, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 33.6,
                  s: [125.163, 195.585, 0],
                  e: [127.163, 205.585, 0],
                  to: [0, 0, 0],
                  ti: [-0.333, -1.667, 0],
                },
                { t: 44.4 },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [127.163, 205.585, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -5.451],
                        [4.785, 0],
                        [0, 5.451],
                        [-4.785, 0],
                      ],
                      o: [
                        [0, 5.451],
                        [-4.785, 0],
                        [0, -5.451],
                        [4.785, 0],
                      ],
                      v: [
                        [8.664, 0],
                        [0, 9.87],
                        [-8.664, 0],
                        [0, -9.87],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [196.697, 314.036], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 44.4,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 13,
          ty: 4,
          nm: 'blick_1',
          parent: 14,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 0,
                  s: [127.163, 205.585, 0],
                  e: [125.163, 195.585, 0],
                  to: [-0.333, -1.667, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 10.8,
                  s: [125.163, 195.585, 0],
                  e: [127.163, 205.585, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 22.8,
                  s: [127.163, 205.585, 0],
                  e: [125.163, 195.585, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 33.6,
                  s: [125.163, 195.585, 0],
                  e: [127.163, 205.585, 0],
                  to: [0, 0, 0],
                  ti: [-0.333, -1.667, 0],
                },
                { t: 44.4 },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [127.163, 205.585, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -13.572],
                        [11.914, 0],
                        [0, 13.572],
                        [-11.914, 0],
                      ],
                      o: [
                        [0, 13.572],
                        [-11.914, 0],
                        [0, -13.572],
                        [11.914, 0],
                      ],
                      v: [
                        [21.572, -0.001],
                        [0, 24.575],
                        [-21.572, -0.001],
                        [0, -24.575],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [126.853, 284.426], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 44.4,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 14,
          ty: 4,
          nm: 'eye_dot',
          parent: 15,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 0,
                  s: [113.163, 205.585, 0],
                  e: [138.163, 205.585, 0],
                  to: [4.167, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 10.8,
                  s: [138.163, 205.585, 0],
                  e: [113.163, 205.585, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 22.8,
                  s: [113.163, 205.585, 0],
                  e: [138.163, 205.585, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 33.6,
                  s: [138.163, 205.585, 0],
                  e: [113.163, 205.585, 0],
                  to: [0, 0, 0],
                  ti: [4.167, 0, 0],
                },
                { t: 44.4 },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [127.163, 205.585, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -34.897],
                        [20.313, 0],
                        [0, 34.897],
                        [-20.312, 0],
                      ],
                      o: [
                        [0, 34.897],
                        [-20.312, 0],
                        [0, -34.897],
                        [20.313, 0],
                      ],
                      v: [
                        [36.779, 0],
                        [-0.001, 63.188],
                        [-36.779, 0],
                        [-0.001, -63.188],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.20800000359, 0.20800000359, 0.20800000359, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [160.864, 287.588], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 7',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 44.4,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 15,
          ty: 4,
          nm: 'eye',
          parent: 16,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 0,
                  s: [75.163, 211.585, 0],
                  e: [114.163, 211.585, 0],
                  to: [6.5, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 10.8,
                  s: [114.163, 211.585, 0],
                  e: [75.163, 211.585, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 22.8,
                  s: [75.163, 211.585, 0],
                  e: [114.163, 211.585, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 33.6,
                  s: [114.163, 211.585, 0],
                  e: [75.163, 211.585, 0],
                  to: [0, 0, 0],
                  ti: [6.5, 0, 0],
                },
                { t: 44.4 },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [127.163, 205.585, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -58.611],
                        [40.624, 0],
                        [0, 58.612],
                        [-40.624, 0],
                      ],
                      o: [
                        [0, 58.612],
                        [-40.624, 0],
                        [0, -58.611],
                        [40.624, 0],
                      ],
                      v: [
                        [73.557, -0.001],
                        [0, 106.126],
                        [-73.557, -0.001],
                        [0, -106.126],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [160.864, 278.799], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 8',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 44.4,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 16,
          ty: 4,
          nm: 'back',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [188.221, 280.08, 0], ix: 2, l: 2 },
            a: { a: 0, k: [127.163, 205.585, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -70.092],
                        [70.092, 0],
                        [0, 70.092],
                        [-70.092, 0],
                      ],
                      o: [
                        [0, 70.092],
                        [-70.092, 0],
                        [0, -70.092],
                        [70.092, 0],
                      ],
                      v: [
                        [126.913, 0],
                        [0, 126.913],
                        [-126.913, 0],
                        [0, -126.913],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.969000004787, 0.57599995931, 0.102000000898, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [127.163, 284.008], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 9',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 44.4,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_5',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 0,
          nm: 'nftm_left_hand',
          refId: 'comp_6',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [201.524, 328.217, 0], ix: 2, l: 2 },
            a: { a: 0, k: [454.585, 440.061, 0], ix: 1, l: 2 },
            s: { a: 0, k: [-52.46, 52.46, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 1236,
          h: 1085,
          ip: 0,
          op: 44,
          st: -6,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 0,
          nm: 'nftm_left_hand',
          refId: 'comp_6',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [312.433, 328.217, 0], ix: 2, l: 2 },
            a: { a: 0, k: [454.585, 440.061, 0], ix: 1, l: 2 },
            s: { a: 0, k: [52.46, 52.46, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 1236,
          h: 1085,
          ip: 0,
          op: 44,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_6',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'fingers',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.904], y: [1] },
                  o: { x: [0.528], y: [0] },
                  t: 0,
                  s: [191.636],
                  e: [147.636],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.08], y: [0] },
                  t: 7.2,
                  s: [147.636],
                  e: [191.636],
                },
                {
                  i: { x: [0.904], y: [1] },
                  o: { x: [0.528], y: [0] },
                  t: 14.4,
                  s: [191.636],
                  e: [147.636],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.08], y: [0] },
                  t: 21.6,
                  s: [147.636],
                  e: [191.636],
                },
                {
                  i: { x: [0.904], y: [1] },
                  o: { x: [0.528], y: [0] },
                  t: 28.8,
                  s: [191.636],
                  e: [147.636],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.08], y: [0] },
                  t: 36,
                  s: [147.636],
                  e: [191.636],
                },
                {
                  i: { x: [0.904], y: [1] },
                  o: { x: [0.528], y: [0] },
                  t: 43.2,
                  s: [191.636],
                  e: [147.636],
                },
                {
                  i: { x: [0.28], y: [1] },
                  o: { x: [0.08], y: [0] },
                  t: 50.4,
                  s: [147.636],
                  e: [191.636],
                },
                { t: 57.6 },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.96, y: 0.994 },
                  o: { x: 0.72, y: 0.101 },
                  t: 0,
                  s: [707.301, 525.135, 0],
                  e: [500.301, 638.135, 0],
                  to: [30.699, 77.865, 0],
                  ti: [61.699, 58.865, 0],
                },
                {
                  i: { x: 0.28, y: 1 },
                  o: { x: 0.008, y: 0.001 },
                  t: 7.2,
                  s: [500.301, 638.135, 0],
                  e: [707.301, 525.135, 0],
                  to: [-61.699, -58.865, 0],
                  ti: [-30.699, -77.865, 0],
                },
                {
                  i: { x: 0.96, y: 0.994 },
                  o: { x: 0.72, y: 0.101 },
                  t: 14.4,
                  s: [707.301, 525.135, 0],
                  e: [500.301, 638.135, 0],
                  to: [30.699, 77.865, 0],
                  ti: [61.699, 58.865, 0],
                },
                {
                  i: { x: 0.28, y: 1 },
                  o: { x: 0.008, y: 0.001 },
                  t: 21.6,
                  s: [500.301, 638.135, 0],
                  e: [707.301, 525.135, 0],
                  to: [-61.699, -58.865, 0],
                  ti: [-30.699, -77.865, 0],
                },
                {
                  i: { x: 0.96, y: 0.994 },
                  o: { x: 0.72, y: 0.101 },
                  t: 28.8,
                  s: [707.301, 525.135, 0],
                  e: [500.301, 638.135, 0],
                  to: [30.699, 77.865, 0],
                  ti: [61.699, 58.865, 0],
                },
                {
                  i: { x: 0.28, y: 1 },
                  o: { x: 0.008, y: 0.001 },
                  t: 36,
                  s: [500.301, 638.135, 0],
                  e: [707.301, 525.135, 0],
                  to: [-61.699, -58.865, 0],
                  ti: [-30.699, -77.865, 0],
                },
                {
                  i: { x: 0.96, y: 0.994 },
                  o: { x: 0.72, y: 0.101 },
                  t: 43.2,
                  s: [707.301, 525.135, 0],
                  e: [500.301, 638.135, 0],
                  to: [30.699, 77.865, 0],
                  ti: [61.699, 58.865, 0],
                },
                {
                  i: { x: 0.28, y: 0.903 },
                  o: { x: 0.008, y: 0.001 },
                  t: 50.4,
                  s: [500.301, 638.135, 0],
                  e: [708.301, 525.135, 0],
                  to: [-61.699, -58.865, 0],
                  ti: [-56.301, -69.135, 0],
                },
                { t: 57.6 },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [-102, 96, 0], ix: 1, l: 2 },
            s: { a: 0, k: [-95.103, 95.103, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-35.5, -12.5],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [35.5, 12.5],
                        [0, 0],
                      ],
                      v: [
                        [-161.5, 143],
                        [-100, 93.5],
                        [-75.5, 166],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.278431372549, 0.278431372549, 0.278431372549, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 40, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Shape 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 90,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'back_finger',
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [-104, 94, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-104, 94, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-121.5, 167],
                        [-100.5, 97],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.219607858097, 0.219607858097, 0.219607858097, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 40, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Shape 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 90,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'fist',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [603.854, 566.476, 0], ix: 2, l: 2 },
            a: { a: 0, k: [198.161, 173.332, 0], ix: 1, l: 2 },
            s: { a: 0, k: [-100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.664, y: 0 },
                        t: 0,
                        s: [
                          {
                            i: [
                              [-8.208, -114.888],
                              [-65.069, 104.971],
                            ],
                            o: [
                              [9.437, 132.086],
                              [0, 0],
                            ],
                            v: [
                              [-109.557, -43.411],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [3.737, -108.462],
                              [-73.262, 97.047],
                            ],
                            o: [
                              [13.798, 113.071],
                              [0, 0],
                            ],
                            v: [
                              [-107.281, -3.947],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 2.4,
                        s: [
                          {
                            i: [
                              [3.737, -108.462],
                              [-73.262, 97.047],
                            ],
                            o: [
                              [13.798, 113.071],
                              [0, 0],
                            ],
                            v: [
                              [-107.281, -3.947],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [21.132, -99.104],
                              [-85.191, 85.508],
                            ],
                            o: [
                              [20.149, 85.382],
                              [0, 0],
                            ],
                            v: [
                              [-92.914, 22.819],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 3.6,
                        s: [
                          {
                            i: [
                              [21.132, -99.104],
                              [-85.191, 85.508],
                            ],
                            o: [
                              [20.149, 85.382],
                              [0, 0],
                            ],
                            v: [
                              [-92.914, 22.819],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [57.362, -79.612],
                              [-110.039, 61.475],
                            ],
                            o: [
                              [-21.724, 52.208],
                              [0, 0],
                            ],
                            v: [
                              [-57.041, 57.492],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 4.8,
                        s: [
                          {
                            i: [
                              [57.362, -79.612],
                              [-110.039, 61.475],
                            ],
                            o: [
                              [-21.724, 52.208],
                              [0, 0],
                            ],
                            v: [
                              [-57.041, 57.492],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [93.592, -60.121],
                              [-134.886, 37.443],
                            ],
                            o: [
                              [-56.597, 27.034],
                              [0, 0],
                            ],
                            v: [
                              [7.833, 87.166],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.932, y: 1 },
                        o: { x: 0.167, y: 0.167 },
                        t: 6,
                        s: [
                          {
                            i: [
                              [93.592, -60.121],
                              [-134.886, 37.443],
                            ],
                            o: [
                              [-56.597, 27.034],
                              [0, 0],
                            ],
                            v: [
                              [7.833, 87.166],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [125.208, -43.111],
                              [-156.569, 16.471],
                            ],
                            o: [
                              [-125.208, 43.112],
                              [0, 0],
                            ],
                            v: [
                              [93.443, 73.589],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.113, y: 0 },
                        t: 7.2,
                        s: [
                          {
                            i: [
                              [125.208, -43.111],
                              [-156.569, 16.471],
                            ],
                            o: [
                              [-125.208, 43.112],
                              [0, 0],
                            ],
                            v: [
                              [93.443, 73.589],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [94.044, -59.877],
                              [-135.197, 37.143],
                            ],
                            o: [
                              [-82.202, 65.353],
                              [0, 0],
                            ],
                            v: [
                              [61.166, -9.183],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 8.4,
                        s: [
                          {
                            i: [
                              [94.044, -59.877],
                              [-135.197, 37.143],
                            ],
                            o: [
                              [-82.202, 65.353],
                              [0, 0],
                            ],
                            v: [
                              [61.166, -9.183],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [57.33, -79.629],
                              [-110.017, 61.497],
                            ],
                            o: [
                              [-34.035, 105.555],
                              [0, 0],
                            ],
                            v: [
                              [0.27, -41.355],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 9.6,
                        s: [
                          {
                            i: [
                              [57.33, -79.629],
                              [-110.017, 61.497],
                            ],
                            o: [
                              [-34.035, 105.555],
                              [0, 0],
                            ],
                            v: [
                              [0.27, -41.355],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [19.039, -100.23],
                              [-83.756, 86.897],
                            ],
                            o: [
                              [-18.061, 113.915],
                              [0, 0],
                            ],
                            v: [
                              [-47.599, -53.017],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.167, y: 0.167 },
                        t: 10.8,
                        s: [
                          {
                            i: [
                              [19.039, -100.23],
                              [-83.756, 86.897],
                            ],
                            o: [
                              [-18.061, 113.915],
                              [0, 0],
                            ],
                            v: [
                              [-47.599, -53.017],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-8.208, -114.888],
                              [-65.069, 104.971],
                            ],
                            o: [
                              [9.437, 132.086],
                              [0, 0],
                            ],
                            v: [
                              [-109.557, -43.411],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.664, y: 0 },
                        t: 14.4,
                        s: [
                          {
                            i: [
                              [-8.208, -114.888],
                              [-65.069, 104.971],
                            ],
                            o: [
                              [9.437, 132.086],
                              [0, 0],
                            ],
                            v: [
                              [-109.557, -43.411],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [3.737, -108.462],
                              [-73.262, 97.047],
                            ],
                            o: [
                              [13.798, 113.071],
                              [0, 0],
                            ],
                            v: [
                              [-107.281, -3.947],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 16.8,
                        s: [
                          {
                            i: [
                              [3.737, -108.462],
                              [-73.262, 97.047],
                            ],
                            o: [
                              [13.798, 113.071],
                              [0, 0],
                            ],
                            v: [
                              [-107.281, -3.947],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [21.132, -99.104],
                              [-85.191, 85.508],
                            ],
                            o: [
                              [20.149, 85.382],
                              [0, 0],
                            ],
                            v: [
                              [-92.914, 22.819],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 18,
                        s: [
                          {
                            i: [
                              [21.132, -99.104],
                              [-85.191, 85.508],
                            ],
                            o: [
                              [20.149, 85.382],
                              [0, 0],
                            ],
                            v: [
                              [-92.914, 22.819],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [57.362, -79.612],
                              [-110.039, 61.475],
                            ],
                            o: [
                              [-21.724, 52.208],
                              [0, 0],
                            ],
                            v: [
                              [-57.041, 57.492],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 19.2,
                        s: [
                          {
                            i: [
                              [57.362, -79.612],
                              [-110.039, 61.475],
                            ],
                            o: [
                              [-21.724, 52.208],
                              [0, 0],
                            ],
                            v: [
                              [-57.041, 57.492],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [93.592, -60.121],
                              [-134.886, 37.443],
                            ],
                            o: [
                              [-56.597, 27.034],
                              [0, 0],
                            ],
                            v: [
                              [7.833, 87.166],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.932, y: 1 },
                        o: { x: 0.167, y: 0.167 },
                        t: 20.4,
                        s: [
                          {
                            i: [
                              [93.592, -60.121],
                              [-134.886, 37.443],
                            ],
                            o: [
                              [-56.597, 27.034],
                              [0, 0],
                            ],
                            v: [
                              [7.833, 87.166],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [125.208, -43.111],
                              [-156.569, 16.471],
                            ],
                            o: [
                              [-125.208, 43.112],
                              [0, 0],
                            ],
                            v: [
                              [93.443, 73.589],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.113, y: 0 },
                        t: 21.6,
                        s: [
                          {
                            i: [
                              [125.208, -43.111],
                              [-156.569, 16.471],
                            ],
                            o: [
                              [-125.208, 43.112],
                              [0, 0],
                            ],
                            v: [
                              [93.443, 73.589],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [94.044, -59.877],
                              [-135.197, 37.143],
                            ],
                            o: [
                              [-82.202, 65.353],
                              [0, 0],
                            ],
                            v: [
                              [61.166, -9.183],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 22.8,
                        s: [
                          {
                            i: [
                              [94.044, -59.877],
                              [-135.197, 37.143],
                            ],
                            o: [
                              [-82.202, 65.353],
                              [0, 0],
                            ],
                            v: [
                              [61.166, -9.183],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [57.33, -79.629],
                              [-110.017, 61.497],
                            ],
                            o: [
                              [-34.035, 105.555],
                              [0, 0],
                            ],
                            v: [
                              [0.27, -41.355],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 24,
                        s: [
                          {
                            i: [
                              [57.33, -79.629],
                              [-110.017, 61.497],
                            ],
                            o: [
                              [-34.035, 105.555],
                              [0, 0],
                            ],
                            v: [
                              [0.27, -41.355],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [19.039, -100.23],
                              [-83.756, 86.897],
                            ],
                            o: [
                              [-18.061, 113.915],
                              [0, 0],
                            ],
                            v: [
                              [-47.599, -53.017],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.167, y: 0.167 },
                        t: 25.2,
                        s: [
                          {
                            i: [
                              [19.039, -100.23],
                              [-83.756, 86.897],
                            ],
                            o: [
                              [-18.061, 113.915],
                              [0, 0],
                            ],
                            v: [
                              [-47.599, -53.017],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-8.208, -114.888],
                              [-65.069, 104.971],
                            ],
                            o: [
                              [9.437, 132.086],
                              [0, 0],
                            ],
                            v: [
                              [-109.557, -43.411],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.664, y: 0 },
                        t: 28.8,
                        s: [
                          {
                            i: [
                              [-8.208, -114.888],
                              [-65.069, 104.971],
                            ],
                            o: [
                              [9.437, 132.086],
                              [0, 0],
                            ],
                            v: [
                              [-109.557, -43.411],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [3.737, -108.462],
                              [-73.262, 97.047],
                            ],
                            o: [
                              [13.798, 113.071],
                              [0, 0],
                            ],
                            v: [
                              [-107.281, -3.947],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 31.2,
                        s: [
                          {
                            i: [
                              [3.737, -108.462],
                              [-73.262, 97.047],
                            ],
                            o: [
                              [13.798, 113.071],
                              [0, 0],
                            ],
                            v: [
                              [-107.281, -3.947],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [21.132, -99.104],
                              [-85.191, 85.508],
                            ],
                            o: [
                              [20.149, 85.382],
                              [0, 0],
                            ],
                            v: [
                              [-92.914, 22.819],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 32.4,
                        s: [
                          {
                            i: [
                              [21.132, -99.104],
                              [-85.191, 85.508],
                            ],
                            o: [
                              [20.149, 85.382],
                              [0, 0],
                            ],
                            v: [
                              [-92.914, 22.819],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [57.362, -79.612],
                              [-110.039, 61.475],
                            ],
                            o: [
                              [-21.724, 52.208],
                              [0, 0],
                            ],
                            v: [
                              [-57.041, 57.492],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 33.6,
                        s: [
                          {
                            i: [
                              [57.362, -79.612],
                              [-110.039, 61.475],
                            ],
                            o: [
                              [-21.724, 52.208],
                              [0, 0],
                            ],
                            v: [
                              [-57.041, 57.492],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [93.592, -60.121],
                              [-134.886, 37.443],
                            ],
                            o: [
                              [-56.597, 27.034],
                              [0, 0],
                            ],
                            v: [
                              [7.833, 87.166],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.932, y: 1 },
                        o: { x: 0.167, y: 0.167 },
                        t: 34.8,
                        s: [
                          {
                            i: [
                              [93.592, -60.121],
                              [-134.886, 37.443],
                            ],
                            o: [
                              [-56.597, 27.034],
                              [0, 0],
                            ],
                            v: [
                              [7.833, 87.166],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [125.208, -43.111],
                              [-156.569, 16.471],
                            ],
                            o: [
                              [-125.208, 43.112],
                              [0, 0],
                            ],
                            v: [
                              [93.443, 73.589],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.113, y: 0 },
                        t: 36,
                        s: [
                          {
                            i: [
                              [125.208, -43.111],
                              [-156.569, 16.471],
                            ],
                            o: [
                              [-125.208, 43.112],
                              [0, 0],
                            ],
                            v: [
                              [93.443, 73.589],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [94.044, -59.877],
                              [-135.197, 37.143],
                            ],
                            o: [
                              [-82.202, 65.353],
                              [0, 0],
                            ],
                            v: [
                              [61.166, -9.183],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 37.2,
                        s: [
                          {
                            i: [
                              [94.044, -59.877],
                              [-135.197, 37.143],
                            ],
                            o: [
                              [-82.202, 65.353],
                              [0, 0],
                            ],
                            v: [
                              [61.166, -9.183],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [57.33, -79.629],
                              [-110.017, 61.497],
                            ],
                            o: [
                              [-34.035, 105.555],
                              [0, 0],
                            ],
                            v: [
                              [0.27, -41.355],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 38.4,
                        s: [
                          {
                            i: [
                              [57.33, -79.629],
                              [-110.017, 61.497],
                            ],
                            o: [
                              [-34.035, 105.555],
                              [0, 0],
                            ],
                            v: [
                              [0.27, -41.355],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [19.039, -100.23],
                              [-83.756, 86.897],
                            ],
                            o: [
                              [-18.061, 113.915],
                              [0, 0],
                            ],
                            v: [
                              [-47.599, -53.017],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.167, y: 0.167 },
                        t: 39.6,
                        s: [
                          {
                            i: [
                              [19.039, -100.23],
                              [-83.756, 86.897],
                            ],
                            o: [
                              [-18.061, 113.915],
                              [0, 0],
                            ],
                            v: [
                              [-47.599, -53.017],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-8.208, -114.888],
                              [-65.069, 104.971],
                            ],
                            o: [
                              [9.437, 132.086],
                              [0, 0],
                            ],
                            v: [
                              [-109.557, -43.411],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.664, y: 0 },
                        t: 43.2,
                        s: [
                          {
                            i: [
                              [-8.208, -114.888],
                              [-65.069, 104.971],
                            ],
                            o: [
                              [9.437, 132.086],
                              [0, 0],
                            ],
                            v: [
                              [-109.557, -43.411],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [3.737, -108.462],
                              [-73.262, 97.047],
                            ],
                            o: [
                              [13.798, 113.071],
                              [0, 0],
                            ],
                            v: [
                              [-107.281, -3.947],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 45.6,
                        s: [
                          {
                            i: [
                              [3.737, -108.462],
                              [-73.262, 97.047],
                            ],
                            o: [
                              [13.798, 113.071],
                              [0, 0],
                            ],
                            v: [
                              [-107.281, -3.947],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [21.132, -99.104],
                              [-85.191, 85.508],
                            ],
                            o: [
                              [20.149, 85.382],
                              [0, 0],
                            ],
                            v: [
                              [-92.914, 22.819],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 46.8,
                        s: [
                          {
                            i: [
                              [21.132, -99.104],
                              [-85.191, 85.508],
                            ],
                            o: [
                              [20.149, 85.382],
                              [0, 0],
                            ],
                            v: [
                              [-92.914, 22.819],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [57.362, -79.612],
                              [-110.039, 61.475],
                            ],
                            o: [
                              [-21.724, 52.208],
                              [0, 0],
                            ],
                            v: [
                              [-57.041, 57.492],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 48,
                        s: [
                          {
                            i: [
                              [57.362, -79.612],
                              [-110.039, 61.475],
                            ],
                            o: [
                              [-21.724, 52.208],
                              [0, 0],
                            ],
                            v: [
                              [-57.041, 57.492],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [93.592, -60.121],
                              [-134.886, 37.443],
                            ],
                            o: [
                              [-56.597, 27.034],
                              [0, 0],
                            ],
                            v: [
                              [7.833, 87.166],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.932, y: 1 },
                        o: { x: 0.167, y: 0.167 },
                        t: 49.2,
                        s: [
                          {
                            i: [
                              [93.592, -60.121],
                              [-134.886, 37.443],
                            ],
                            o: [
                              [-56.597, 27.034],
                              [0, 0],
                            ],
                            v: [
                              [7.833, 87.166],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [125.208, -43.111],
                              [-156.569, 16.471],
                            ],
                            o: [
                              [-125.208, 43.112],
                              [0, 0],
                            ],
                            v: [
                              [93.443, 73.589],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.113, y: 0 },
                        t: 50.4,
                        s: [
                          {
                            i: [
                              [125.208, -43.111],
                              [-156.569, 16.471],
                            ],
                            o: [
                              [-125.208, 43.112],
                              [0, 0],
                            ],
                            v: [
                              [93.443, 73.589],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [94.044, -59.877],
                              [-135.197, 37.143],
                            ],
                            o: [
                              [-82.202, 65.353],
                              [0, 0],
                            ],
                            v: [
                              [61.166, -9.183],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 51.6,
                        s: [
                          {
                            i: [
                              [94.044, -59.877],
                              [-135.197, 37.143],
                            ],
                            o: [
                              [-82.202, 65.353],
                              [0, 0],
                            ],
                            v: [
                              [61.166, -9.183],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [57.33, -79.629],
                              [-110.017, 61.497],
                            ],
                            o: [
                              [-34.035, 105.555],
                              [0, 0],
                            ],
                            v: [
                              [0.27, -41.355],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 52.8,
                        s: [
                          {
                            i: [
                              [57.33, -79.629],
                              [-110.017, 61.497],
                            ],
                            o: [
                              [-34.035, 105.555],
                              [0, 0],
                            ],
                            v: [
                              [0.27, -41.355],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [19.039, -100.23],
                              [-83.756, 86.897],
                            ],
                            o: [
                              [-18.061, 113.915],
                              [0, 0],
                            ],
                            v: [
                              [-47.599, -53.017],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.28, y: 1 },
                        o: { x: 0.167, y: 0.167 },
                        t: 54,
                        s: [
                          {
                            i: [
                              [19.039, -100.23],
                              [-83.756, 86.897],
                            ],
                            o: [
                              [-18.061, 113.915],
                              [0, 0],
                            ],
                            v: [
                              [-47.599, -53.017],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-8.208, -114.888],
                              [-65.069, 104.971],
                            ],
                            o: [
                              [9.437, 132.086],
                              [0, 0],
                            ],
                            v: [
                              [-109.557, -43.411],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      { t: 57.6 },
                    ],
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.969000004787, 0.57599995931, 0.102000000898, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 38.766, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 2',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tm',
                  s: { a: 0, k: 33, ix: 1 },
                  e: { a: 0, k: 58, ix: 2 },
                  o: { a: 0, k: 0, ix: 3 },
                  m: 1,
                  ix: 3,
                  nm: 'Trim Paths 1',
                  mn: 'ADBE Vector Filter - Trim',
                  hd: false,
                },
                {
                  ind: 3,
                  ty: 'sh',
                  ix: 4,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.664, y: 0 },
                        t: 0,
                        s: [
                          {
                            i: [
                              [-13.155, -126.967],
                              [-63.069, 105.971],
                            ],
                            o: [
                              [13.792, 133.112],
                              [0, 0],
                            ],
                            v: [
                              [-111.557, -42.411],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-1.163, -119.296],
                              [-71.396, 98.047],
                            ],
                            o: [
                              [17.194, 113.272],
                              [0, 0],
                            ],
                            v: [
                              [-109.281, -2.947],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 2.4,
                        s: [
                          {
                            i: [
                              [-1.163, -119.296],
                              [-71.396, 98.047],
                            ],
                            o: [
                              [17.194, 113.272],
                              [0, 0],
                            ],
                            v: [
                              [-109.281, -2.947],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [16.3, -108.124],
                              [-83.521, 86.508],
                            ],
                            o: [
                              [22.149, 84.382],
                              [0, 0],
                            ],
                            v: [
                              [-94.914, 23.819],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 3.6,
                        s: [
                          {
                            i: [
                              [16.3, -108.124],
                              [-83.521, 86.508],
                            ],
                            o: [
                              [22.149, 84.382],
                              [0, 0],
                            ],
                            v: [
                              [-94.914, 23.819],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [52.673, -84.856],
                              [-108.776, 62.475],
                            ],
                            o: [
                              [-19.474, 50.208],
                              [0, 0],
                            ],
                            v: [
                              [-59.041, 58.492],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 4.8,
                        s: [
                          {
                            i: [
                              [52.673, -84.856],
                              [-108.776, 62.475],
                            ],
                            o: [
                              [-19.474, 50.208],
                              [0, 0],
                            ],
                            v: [
                              [-59.041, 58.492],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [89.046, -61.588],
                              [-134.031, 38.443],
                            ],
                            o: [
                              [-45.097, 17.534],
                              [0, 0],
                            ],
                            v: [
                              [5.833, 88.166],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.932, y: 1 },
                        o: { x: 0.167, y: 0.167 },
                        t: 6,
                        s: [
                          {
                            i: [
                              [89.046, -61.588],
                              [-134.031, 38.443],
                            ],
                            o: [
                              [-45.097, 17.534],
                              [0, 0],
                            ],
                            v: [
                              [5.833, 88.166],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [120.787, -41.284],
                              [-156.069, 17.471],
                            ],
                            o: [
                              [-123.208, 42.112],
                              [0, 0],
                            ],
                            v: [
                              [91.443, 74.589],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.113, y: 0 },
                        t: 7.2,
                        s: [
                          {
                            i: [
                              [120.787, -41.284],
                              [-156.069, 17.471],
                            ],
                            o: [
                              [-123.208, 42.112],
                              [0, 0],
                            ],
                            v: [
                              [91.443, 74.589],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [89.5, -61.298],
                              [-134.346, 38.143],
                            ],
                            o: [
                              [-79.972, 62.975],
                              [0, 0],
                            ],
                            v: [
                              [59.166, -8.183],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 8.4,
                        s: [
                          {
                            i: [
                              [89.5, -61.298],
                              [-134.346, 38.143],
                            ],
                            o: [
                              [-79.972, 62.975],
                              [0, 0],
                            ],
                            v: [
                              [59.166, -8.183],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [52.641, -84.877],
                              [-108.754, 62.497],
                            ],
                            o: [
                              [-31.535, 104.555],
                              [0, 0],
                            ],
                            v: [
                              [-1.73, -40.355],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 9.6,
                        s: [
                          {
                            i: [
                              [52.641, -84.877],
                              [-108.754, 62.497],
                            ],
                            o: [
                              [-31.535, 104.555],
                              [0, 0],
                            ],
                            v: [
                              [-1.73, -40.355],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [14.199, -109.469],
                              [-82.062, 87.897],
                            ],
                            o: [
                              [-14.187, 114.527],
                              [0, 0],
                            ],
                            v: [
                              [-49.599, -52.017],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.167, y: 0.167 },
                        t: 10.8,
                        s: [
                          {
                            i: [
                              [14.199, -109.469],
                              [-82.062, 87.897],
                            ],
                            o: [
                              [-14.187, 114.527],
                              [0, 0],
                            ],
                            v: [
                              [-49.599, -52.017],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-13.155, -126.967],
                              [-63.069, 105.971],
                            ],
                            o: [
                              [13.792, 133.112],
                              [0, 0],
                            ],
                            v: [
                              [-111.557, -42.411],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.664, y: 0 },
                        t: 14.4,
                        s: [
                          {
                            i: [
                              [-13.155, -126.967],
                              [-63.069, 105.971],
                            ],
                            o: [
                              [13.792, 133.112],
                              [0, 0],
                            ],
                            v: [
                              [-111.557, -42.411],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-1.163, -119.296],
                              [-71.396, 98.047],
                            ],
                            o: [
                              [17.194, 113.272],
                              [0, 0],
                            ],
                            v: [
                              [-109.281, -2.947],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 16.8,
                        s: [
                          {
                            i: [
                              [-1.163, -119.296],
                              [-71.396, 98.047],
                            ],
                            o: [
                              [17.194, 113.272],
                              [0, 0],
                            ],
                            v: [
                              [-109.281, -2.947],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [16.3, -108.124],
                              [-83.521, 86.508],
                            ],
                            o: [
                              [22.149, 84.382],
                              [0, 0],
                            ],
                            v: [
                              [-94.914, 23.819],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 18,
                        s: [
                          {
                            i: [
                              [16.3, -108.124],
                              [-83.521, 86.508],
                            ],
                            o: [
                              [22.149, 84.382],
                              [0, 0],
                            ],
                            v: [
                              [-94.914, 23.819],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [52.673, -84.856],
                              [-108.776, 62.475],
                            ],
                            o: [
                              [-19.474, 50.208],
                              [0, 0],
                            ],
                            v: [
                              [-59.041, 58.492],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 19.2,
                        s: [
                          {
                            i: [
                              [52.673, -84.856],
                              [-108.776, 62.475],
                            ],
                            o: [
                              [-19.474, 50.208],
                              [0, 0],
                            ],
                            v: [
                              [-59.041, 58.492],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [89.046, -61.588],
                              [-134.031, 38.443],
                            ],
                            o: [
                              [-45.097, 17.534],
                              [0, 0],
                            ],
                            v: [
                              [5.833, 88.166],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.932, y: 1 },
                        o: { x: 0.167, y: 0.167 },
                        t: 20.4,
                        s: [
                          {
                            i: [
                              [89.046, -61.588],
                              [-134.031, 38.443],
                            ],
                            o: [
                              [-45.097, 17.534],
                              [0, 0],
                            ],
                            v: [
                              [5.833, 88.166],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [120.787, -41.284],
                              [-156.069, 17.471],
                            ],
                            o: [
                              [-123.208, 42.112],
                              [0, 0],
                            ],
                            v: [
                              [91.443, 74.589],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.113, y: 0 },
                        t: 21.6,
                        s: [
                          {
                            i: [
                              [120.787, -41.284],
                              [-156.069, 17.471],
                            ],
                            o: [
                              [-123.208, 42.112],
                              [0, 0],
                            ],
                            v: [
                              [91.443, 74.589],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [89.5, -61.298],
                              [-134.346, 38.143],
                            ],
                            o: [
                              [-79.972, 62.975],
                              [0, 0],
                            ],
                            v: [
                              [59.166, -8.183],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 22.8,
                        s: [
                          {
                            i: [
                              [89.5, -61.298],
                              [-134.346, 38.143],
                            ],
                            o: [
                              [-79.972, 62.975],
                              [0, 0],
                            ],
                            v: [
                              [59.166, -8.183],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [52.641, -84.877],
                              [-108.754, 62.497],
                            ],
                            o: [
                              [-31.535, 104.555],
                              [0, 0],
                            ],
                            v: [
                              [-1.73, -40.355],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 24,
                        s: [
                          {
                            i: [
                              [52.641, -84.877],
                              [-108.754, 62.497],
                            ],
                            o: [
                              [-31.535, 104.555],
                              [0, 0],
                            ],
                            v: [
                              [-1.73, -40.355],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [14.199, -109.469],
                              [-82.062, 87.897],
                            ],
                            o: [
                              [-14.187, 114.527],
                              [0, 0],
                            ],
                            v: [
                              [-49.599, -52.017],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.167, y: 0.167 },
                        t: 25.2,
                        s: [
                          {
                            i: [
                              [14.199, -109.469],
                              [-82.062, 87.897],
                            ],
                            o: [
                              [-14.187, 114.527],
                              [0, 0],
                            ],
                            v: [
                              [-49.599, -52.017],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-13.155, -126.967],
                              [-63.069, 105.971],
                            ],
                            o: [
                              [13.792, 133.112],
                              [0, 0],
                            ],
                            v: [
                              [-111.557, -42.411],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.664, y: 0 },
                        t: 28.8,
                        s: [
                          {
                            i: [
                              [-13.155, -126.967],
                              [-63.069, 105.971],
                            ],
                            o: [
                              [13.792, 133.112],
                              [0, 0],
                            ],
                            v: [
                              [-111.557, -42.411],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-1.163, -119.296],
                              [-71.396, 98.047],
                            ],
                            o: [
                              [17.194, 113.272],
                              [0, 0],
                            ],
                            v: [
                              [-109.281, -2.947],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 31.2,
                        s: [
                          {
                            i: [
                              [-1.163, -119.296],
                              [-71.396, 98.047],
                            ],
                            o: [
                              [17.194, 113.272],
                              [0, 0],
                            ],
                            v: [
                              [-109.281, -2.947],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [16.3, -108.124],
                              [-83.521, 86.508],
                            ],
                            o: [
                              [22.149, 84.382],
                              [0, 0],
                            ],
                            v: [
                              [-94.914, 23.819],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 32.4,
                        s: [
                          {
                            i: [
                              [16.3, -108.124],
                              [-83.521, 86.508],
                            ],
                            o: [
                              [22.149, 84.382],
                              [0, 0],
                            ],
                            v: [
                              [-94.914, 23.819],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [52.673, -84.856],
                              [-108.776, 62.475],
                            ],
                            o: [
                              [-19.474, 50.208],
                              [0, 0],
                            ],
                            v: [
                              [-59.041, 58.492],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 33.6,
                        s: [
                          {
                            i: [
                              [52.673, -84.856],
                              [-108.776, 62.475],
                            ],
                            o: [
                              [-19.474, 50.208],
                              [0, 0],
                            ],
                            v: [
                              [-59.041, 58.492],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [89.046, -61.588],
                              [-134.031, 38.443],
                            ],
                            o: [
                              [-45.097, 17.534],
                              [0, 0],
                            ],
                            v: [
                              [5.833, 88.166],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.932, y: 1 },
                        o: { x: 0.167, y: 0.167 },
                        t: 34.8,
                        s: [
                          {
                            i: [
                              [89.046, -61.588],
                              [-134.031, 38.443],
                            ],
                            o: [
                              [-45.097, 17.534],
                              [0, 0],
                            ],
                            v: [
                              [5.833, 88.166],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [120.787, -41.284],
                              [-156.069, 17.471],
                            ],
                            o: [
                              [-123.208, 42.112],
                              [0, 0],
                            ],
                            v: [
                              [91.443, 74.589],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.113, y: 0 },
                        t: 36,
                        s: [
                          {
                            i: [
                              [120.787, -41.284],
                              [-156.069, 17.471],
                            ],
                            o: [
                              [-123.208, 42.112],
                              [0, 0],
                            ],
                            v: [
                              [91.443, 74.589],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [89.5, -61.298],
                              [-134.346, 38.143],
                            ],
                            o: [
                              [-79.972, 62.975],
                              [0, 0],
                            ],
                            v: [
                              [59.166, -8.183],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 37.2,
                        s: [
                          {
                            i: [
                              [89.5, -61.298],
                              [-134.346, 38.143],
                            ],
                            o: [
                              [-79.972, 62.975],
                              [0, 0],
                            ],
                            v: [
                              [59.166, -8.183],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [52.641, -84.877],
                              [-108.754, 62.497],
                            ],
                            o: [
                              [-31.535, 104.555],
                              [0, 0],
                            ],
                            v: [
                              [-1.73, -40.355],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 38.4,
                        s: [
                          {
                            i: [
                              [52.641, -84.877],
                              [-108.754, 62.497],
                            ],
                            o: [
                              [-31.535, 104.555],
                              [0, 0],
                            ],
                            v: [
                              [-1.73, -40.355],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [14.199, -109.469],
                              [-82.062, 87.897],
                            ],
                            o: [
                              [-14.187, 114.527],
                              [0, 0],
                            ],
                            v: [
                              [-49.599, -52.017],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.167, y: 0.167 },
                        t: 39.6,
                        s: [
                          {
                            i: [
                              [14.199, -109.469],
                              [-82.062, 87.897],
                            ],
                            o: [
                              [-14.187, 114.527],
                              [0, 0],
                            ],
                            v: [
                              [-49.599, -52.017],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-13.155, -126.967],
                              [-63.069, 105.971],
                            ],
                            o: [
                              [13.792, 133.112],
                              [0, 0],
                            ],
                            v: [
                              [-111.557, -42.411],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.664, y: 0 },
                        t: 43.2,
                        s: [
                          {
                            i: [
                              [-13.155, -126.967],
                              [-63.069, 105.971],
                            ],
                            o: [
                              [13.792, 133.112],
                              [0, 0],
                            ],
                            v: [
                              [-111.557, -42.411],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-1.163, -119.296],
                              [-71.396, 98.047],
                            ],
                            o: [
                              [17.194, 113.272],
                              [0, 0],
                            ],
                            v: [
                              [-109.281, -2.947],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 45.6,
                        s: [
                          {
                            i: [
                              [-1.163, -119.296],
                              [-71.396, 98.047],
                            ],
                            o: [
                              [17.194, 113.272],
                              [0, 0],
                            ],
                            v: [
                              [-109.281, -2.947],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [16.3, -108.124],
                              [-83.521, 86.508],
                            ],
                            o: [
                              [22.149, 84.382],
                              [0, 0],
                            ],
                            v: [
                              [-94.914, 23.819],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 46.8,
                        s: [
                          {
                            i: [
                              [16.3, -108.124],
                              [-83.521, 86.508],
                            ],
                            o: [
                              [22.149, 84.382],
                              [0, 0],
                            ],
                            v: [
                              [-94.914, 23.819],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [52.673, -84.856],
                              [-108.776, 62.475],
                            ],
                            o: [
                              [-19.474, 50.208],
                              [0, 0],
                            ],
                            v: [
                              [-59.041, 58.492],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 48,
                        s: [
                          {
                            i: [
                              [52.673, -84.856],
                              [-108.776, 62.475],
                            ],
                            o: [
                              [-19.474, 50.208],
                              [0, 0],
                            ],
                            v: [
                              [-59.041, 58.492],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [89.046, -61.588],
                              [-134.031, 38.443],
                            ],
                            o: [
                              [-45.097, 17.534],
                              [0, 0],
                            ],
                            v: [
                              [5.833, 88.166],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.932, y: 1 },
                        o: { x: 0.167, y: 0.167 },
                        t: 49.2,
                        s: [
                          {
                            i: [
                              [89.046, -61.588],
                              [-134.031, 38.443],
                            ],
                            o: [
                              [-45.097, 17.534],
                              [0, 0],
                            ],
                            v: [
                              [5.833, 88.166],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [120.787, -41.284],
                              [-156.069, 17.471],
                            ],
                            o: [
                              [-123.208, 42.112],
                              [0, 0],
                            ],
                            v: [
                              [91.443, 74.589],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.113, y: 0 },
                        t: 50.4,
                        s: [
                          {
                            i: [
                              [120.787, -41.284],
                              [-156.069, 17.471],
                            ],
                            o: [
                              [-123.208, 42.112],
                              [0, 0],
                            ],
                            v: [
                              [91.443, 74.589],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [89.5, -61.298],
                              [-134.346, 38.143],
                            ],
                            o: [
                              [-79.972, 62.975],
                              [0, 0],
                            ],
                            v: [
                              [59.166, -8.183],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 51.6,
                        s: [
                          {
                            i: [
                              [89.5, -61.298],
                              [-134.346, 38.143],
                            ],
                            o: [
                              [-79.972, 62.975],
                              [0, 0],
                            ],
                            v: [
                              [59.166, -8.183],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [52.641, -84.877],
                              [-108.754, 62.497],
                            ],
                            o: [
                              [-31.535, 104.555],
                              [0, 0],
                            ],
                            v: [
                              [-1.73, -40.355],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 52.8,
                        s: [
                          {
                            i: [
                              [52.641, -84.877],
                              [-108.754, 62.497],
                            ],
                            o: [
                              [-31.535, 104.555],
                              [0, 0],
                            ],
                            v: [
                              [-1.73, -40.355],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [14.199, -109.469],
                              [-82.062, 87.897],
                            ],
                            o: [
                              [-14.187, 114.527],
                              [0, 0],
                            ],
                            v: [
                              [-49.599, -52.017],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.28, y: 1 },
                        o: { x: 0.167, y: 0.167 },
                        t: 54,
                        s: [
                          {
                            i: [
                              [14.199, -109.469],
                              [-82.062, 87.897],
                            ],
                            o: [
                              [-14.187, 114.527],
                              [0, 0],
                            ],
                            v: [
                              [-49.599, -52.017],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                        e: [
                          {
                            i: [
                              [-13.155, -126.967],
                              [-63.069, 105.971],
                            ],
                            o: [
                              [13.792, 133.112],
                              [0, 0],
                            ],
                            v: [
                              [-111.557, -42.411],
                              [148.305, -128.27],
                            ],
                            c: false,
                          },
                        ],
                      },
                      { t: 57.6 },
                    ],
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.219999994016, 0.219999994016, 0.219999994016, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 17.695, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [203.78, 174.156], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 8',
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 90,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_7',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 0,
          nm: 'sticker_nft_server_NFTM',
          refId: 'comp_8',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [266, 268.5, 0], ix: 2, l: 2 },
            a: { a: 0, k: [256, 256, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 512,
          h: 512,
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 0,
          nm: 'sticker_nft_server_NFTM',
          refId: 'comp_8',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [266, 510.5, 0], ix: 2, l: 2 },
            a: { a: 0, k: [256, 256, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 512,
          h: 512,
          ip: 67,
          op: 144,
          st: 67,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 0,
          nm: 'sticker_nft_server_NFTM',
          refId: 'comp_8',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [266, 510.5, 0], ix: 2, l: 2 },
            a: { a: 0, k: [256, 256, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 512,
          h: 512,
          ip: -16,
          op: 67,
          st: -65,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'server_back Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [116.201, 268.5, 0], ix: 2, l: 2 },
            a: { a: 0, k: [105.951, 256.25, 0], ix: 1, l: 2 },
            s: { a: 0, k: [98, 98, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-105.746, 276.918],
                        [105.655, 276.918],
                        [105.701, -256],
                        [-105.701, -256],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.187999994615, 0.187999994615, 0.187999994615, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [105.951, 256.25], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'rd',
              nm: 'Round Corners 1',
              r: { a: 0, k: 20, ix: 1 },
              ix: 2,
              mn: 'ADBE Vector Filter - RC',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_8',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Group 1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.338, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 20,
                  s: [124.698, 231.478, 0],
                  e: [124.698, 215.478, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 34,
                  s: [124.698, 215.478, 0],
                  e: [124.698, 215.478, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.338, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 81,
                  s: [124.698, 215.478, 0],
                  e: [124.698, 231.478, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 95 },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [54.821, 24.327, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.489, 0],
                        [0, 0],
                        [0, 1.489],
                        [0, 0],
                        [-1.489, 0],
                        [0, 0],
                        [0, -1.49],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-1.489, 0],
                        [0, 0],
                        [0, -1.49],
                        [0, 0],
                        [1.489, 0],
                        [0, 0],
                        [0, 1.489],
                      ],
                      v: [
                        [6.076, 8.092],
                        [-6.076, 8.092],
                        [-8.772, 5.397],
                        [-8.772, -5.395],
                        [-6.076, -8.092],
                        [6.076, -8.092],
                        [8.772, -5.395],
                        [8.772, 5.397],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.338], y: [1] },
                        o: { x: [0.333], y: [0] },
                        t: 20,
                        s: [0.246999987434, 0.246999987434, 0.246999987434, 1],
                        e: [0.216000005603, 0.800000011921, 0.20000000298, 1],
                      },
                      {
                        i: { x: [0.833], y: [1] },
                        o: { x: [0.167], y: [0] },
                        t: 34,
                        s: [0.216000005603, 0.800000011921, 0.20000000298, 1],
                        e: [0.216000005603, 0.800000011921, 0.20000000298, 1],
                      },
                      {
                        i: { x: [0.338], y: [1] },
                        o: { x: [0.333], y: [0] },
                        t: 81,
                        s: [0.216000005603, 0.800000011921, 0.20000000298, 1],
                        e: [0.24699999392, 0.24699999392, 0.24699999392, 1],
                      },
                      { t: 95 },
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [54.821, 24.327], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Group 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.338, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 13,
                  s: [101.798, 231.478, 0],
                  e: [101.798, 215.493, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 27,
                  s: [101.798, 215.493, 0],
                  e: [101.798, 215.493, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.338, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 88,
                  s: [101.798, 215.493, 0],
                  e: [101.798, 231.478, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 102 },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [31.922, 8.342, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.489, 0],
                        [0, 0],
                        [0, 1.489],
                        [0, 0],
                        [-1.489, 0],
                        [0, 0],
                        [0, -1.49],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-1.489, 0],
                        [0, 0],
                        [0, -1.49],
                        [0, 0],
                        [1.489, 0],
                        [0, 0],
                        [0, 1.489],
                      ],
                      v: [
                        [6.076, 8.092],
                        [-6.076, 8.092],
                        [-8.772, 5.397],
                        [-8.772, -5.395],
                        [-6.076, -8.092],
                        [6.076, -8.092],
                        [8.772, -5.395],
                        [8.772, 5.397],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.338], y: [1] },
                        o: { x: [0.333], y: [0] },
                        t: 13,
                        s: [0.24699999392, 0.24699999392, 0.24699999392, 1],
                        e: [0.216000005603, 0.800000011921, 0.20000000298, 1],
                      },
                      {
                        i: { x: [0.833], y: [1] },
                        o: { x: [0.167], y: [0] },
                        t: 27,
                        s: [0.216000005603, 0.800000011921, 0.20000000298, 1],
                        e: [0.216000005603, 0.800000011921, 0.20000000298, 1],
                      },
                      {
                        i: { x: [0.338], y: [1] },
                        o: { x: [0.333], y: [0] },
                        t: 88,
                        s: [0.216000005603, 0.800000011921, 0.20000000298, 1],
                        e: [0.24699999392, 0.24699999392, 0.24699999392, 1],
                      },
                      { t: 102 },
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [31.922, 8.342], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Group 5',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.338, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 6,
                  s: [78.899, 231.478, 0],
                  e: [78.899, 215.478, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 20,
                  s: [78.899, 215.478, 0],
                  e: [78.899, 215.478, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.338, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 95,
                  s: [78.899, 215.478, 0],
                  e: [78.899, 231.478, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 109 },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [9.022, 24.327, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.489, 0],
                        [0, 0],
                        [0, 1.489],
                        [0, 0],
                        [-1.489, 0],
                        [0, 0],
                        [0, -1.49],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-1.489, 0],
                        [0, 0],
                        [0, -1.49],
                        [0, 0],
                        [1.489, 0],
                        [0, 0],
                        [0, 1.489],
                      ],
                      v: [
                        [6.076, 8.092],
                        [-6.076, 8.092],
                        [-8.772, 5.397],
                        [-8.772, -5.395],
                        [-6.076, -8.092],
                        [6.076, -8.092],
                        [8.772, -5.395],
                        [8.772, 5.397],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.338], y: [1] },
                        o: { x: [0.333], y: [0] },
                        t: 6,
                        s: [0.246999987434, 0.246999987434, 0.246999987434, 1],
                        e: [0.216000005603, 0.800000011921, 0.20000000298, 1],
                      },
                      {
                        i: { x: [0.833], y: [1] },
                        o: { x: [0.167], y: [0] },
                        t: 20,
                        s: [0.216000005603, 0.800000011921, 0.20000000298, 1],
                        e: [0.216000005603, 0.800000011921, 0.20000000298, 1],
                      },
                      {
                        i: { x: [0.338], y: [1] },
                        o: { x: [0.333], y: [0] },
                        t: 95,
                        s: [0.216000005603, 0.800000011921, 0.20000000298, 1],
                        e: [0.24699999392, 0.24699999392, 0.24699999392, 1],
                      },
                      { t: 109 },
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [9.022, 24.327], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Group 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [101.798, 223.485, 0], ix: 2, l: 2 },
            a: { a: 0, k: [31.922, 16.335, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.489, 0],
                        [0, 0],
                        [0, 1.489],
                        [0, 0],
                        [-1.489, 0],
                        [0, 0],
                        [0, -1.489],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-1.489, 0],
                        [0, 0],
                        [0, -1.489],
                        [0, 0],
                        [1.489, 0],
                        [0, 0],
                        [0, 1.489],
                      ],
                      v: [
                        [6.076, 16.084],
                        [-6.076, 16.084],
                        [-8.772, 13.389],
                        [-8.772, -13.389],
                        [-6.076, -16.084],
                        [6.076, -16.084],
                        [8.772, -13.389],
                        [8.772, 13.389],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.187999994615, 0.187999994615, 0.187999994615, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [54.821, 16.335], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Group 4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [101.798, 223.485, 0], ix: 2, l: 2 },
            a: { a: 0, k: [31.922, 16.335, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.489, 0],
                        [0, 0],
                        [0, 1.489],
                        [0, 0],
                        [-1.489, 0],
                        [0, 0],
                        [0, -1.489],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-1.489, 0],
                        [0, 0],
                        [0, -1.489],
                        [0, 0],
                        [1.489, 0],
                        [0, 0],
                        [0, 1.489],
                      ],
                      v: [
                        [6.076, 16.084],
                        [-6.076, 16.084],
                        [-8.772, 13.389],
                        [-8.772, -13.389],
                        [-6.076, -16.084],
                        [6.076, -16.084],
                        [8.772, -13.389],
                        [8.772, 13.389],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.187999994615, 0.187999994615, 0.187999994615, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [31.922, 16.335], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'Group 6',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [101.798, 223.485, 0], ix: 2, l: 2 },
            a: { a: 0, k: [31.922, 16.335, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.489, 0],
                        [0, 0],
                        [0, 1.489],
                        [0, 0],
                        [-1.489, 0],
                        [0, 0],
                        [0, -1.489],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-1.489, 0],
                        [0, 0],
                        [0, -1.489],
                        [0, 0],
                        [1.489, 0],
                        [0, 0],
                        [0, 1.489],
                      ],
                      v: [
                        [6.076, 16.084],
                        [-6.076, 16.084],
                        [-8.772, 13.389],
                        [-8.772, -13.389],
                        [-6.076, -16.084],
                        [6.076, -16.084],
                        [8.772, -13.389],
                        [8.772, 13.389],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.187999994615, 0.187999994615, 0.187999994615, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [9.022, 16.335], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'red',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 0,
                  s: [100],
                  e: [0],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: 2,
                  s: [0],
                  e: [0],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 114,
                  s: [0],
                  e: [100],
                },
                { t: 116 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [44.178, 211.447, 0], ix: 2, l: 2 },
            a: { a: 0, k: [18.298, 4.296, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -2.234],
                        [2.234, 0],
                        [0, 2.235],
                        [-2.235, 0],
                      ],
                      o: [
                        [0, 2.235],
                        [-2.235, 0],
                        [0, -2.234],
                        [2.234, 0],
                      ],
                      v: [
                        [4.046, 0],
                        [0, 4.046],
                        [-4.046, 0],
                        [0, -4.046],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.969000004787, 0.258999992819, 0.286000001197, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [32.301, 4.296], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: 'green',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 0,
                  s: [0],
                  e: [100],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.167], y: [0] },
                  t: 2,
                  s: [100],
                  e: [100],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 114,
                  s: [100],
                  e: [0],
                },
                { t: 116 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [44.178, 211.447, 0], ix: 2, l: 2 },
            a: { a: 0, k: [18.298, 4.296, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -2.234],
                        [2.234, 0],
                        [0, 2.235],
                        [-2.235, 0],
                      ],
                      o: [
                        [0, 2.235],
                        [-2.235, 0],
                        [0, -2.234],
                        [2.234, 0],
                      ],
                      v: [
                        [4.046, 0],
                        [0, 4.046],
                        [-4.046, 0],
                        [0, -4.046],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.216000007181, 0.8, 0.2, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [18.298, 4.296], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: 'Group 9',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [58.238, 211.447, 0], ix: 2, l: 2 },
            a: { a: 0, k: [4.296, 4.296, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -2.234],
                        [2.234, 0],
                        [0, 2.235],
                        [-2.235, 0],
                      ],
                      o: [
                        [0, 2.235],
                        [-2.235, 0],
                        [0, -2.234],
                        [2.234, 0],
                      ],
                      v: [
                        [4.046, 0],
                        [0, 4.046],
                        [-4.046, 0],
                        [0, -4.046],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.187999994615, 0.187999994615, 0.187999994615, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [4.296, 4.296], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: 'Group 8',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [44.175, 211.447, 0], ix: 2, l: 2 },
            a: { a: 0, k: [4.296, 4.296, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -2.234],
                        [2.234, 0],
                        [0, 2.235],
                        [-2.235, 0],
                      ],
                      o: [
                        [0, 2.235],
                        [-2.235, 0],
                        [0, -2.234],
                        [2.234, 0],
                      ],
                      v: [
                        [4.046, 0],
                        [0, 4.046],
                        [-4.046, 0],
                        [0, -4.046],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.187999994615, 0.187999994615, 0.187999994615, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [4.296, 4.296], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 4,
          nm: 'Group 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [30.175, 211.447, 0], ix: 2, l: 2 },
            a: { a: 0, k: [4.296, 4.296, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -2.234],
                        [2.234, 0],
                        [0, 2.235],
                        [-2.235, 0],
                      ],
                      o: [
                        [0, 2.235],
                        [-2.235, 0],
                        [0, -2.234],
                        [2.234, 0],
                      ],
                      v: [
                        [4.046, 0],
                        [0, 4.046],
                        [-4.046, 0],
                        [0, -4.046],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.187999994615, 0.187999994615, 0.187999994615, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [4.296, 4.296], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 12,
          ty: 4,
          nm: 'lines Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [163.929, 223.486, 0], ix: 2, l: 2 },
            a: { a: 0, k: [23.647, 16.334, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.151, 0],
                        [0, 0],
                        [0, 1.151],
                        [-1.151, 0],
                        [0, 0],
                        [0, -1.152],
                      ],
                      o: [
                        [0, 0],
                        [-1.151, 0],
                        [0, -1.152],
                        [0, 0],
                        [1.151, 0],
                        [0, 1.151],
                      ],
                      v: [
                        [21.312, 2.085],
                        [-21.313, 2.085],
                        [-23.397, 0],
                        [-21.313, -2.085],
                        [21.312, -2.085],
                        [23.397, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.187999994615, 0.187999994615, 0.187999994615, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [23.647, 30.334], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.151, 0],
                        [0, 0],
                        [0, 1.151],
                        [-1.151, 0],
                        [0, 0],
                        [0, -1.152],
                      ],
                      o: [
                        [0, 0],
                        [-1.151, 0],
                        [0, -1.152],
                        [0, 0],
                        [1.151, 0],
                        [0, 1.151],
                      ],
                      v: [
                        [21.312, 2.085],
                        [-21.313, 2.085],
                        [-23.397, 0.001],
                        [-21.313, -2.085],
                        [21.312, -2.085],
                        [23.397, 0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.187999994615, 0.187999994615, 0.187999994615, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [23.647, 23.334], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.151, 0],
                        [0, 0],
                        [0, 1.151],
                        [-1.151, 0],
                        [0, 0],
                        [0, -1.152],
                      ],
                      o: [
                        [0, 0],
                        [-1.151, 0],
                        [0, -1.152],
                        [0, 0],
                        [1.151, 0],
                        [0, 1.151],
                      ],
                      v: [
                        [21.312, 2.085],
                        [-21.313, 2.085],
                        [-23.397, 0],
                        [-21.313, -2.085],
                        [21.312, -2.085],
                        [23.397, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.187999994615, 0.187999994615, 0.187999994615, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [23.647, 16.335], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.151, 0],
                        [0, 0],
                        [0, 1.151],
                        [-1.151, 0],
                        [0, 0],
                        [0, -1.152],
                      ],
                      o: [
                        [0, 0],
                        [-1.151, 0],
                        [0, -1.152],
                        [0, 0],
                        [1.151, 0],
                        [0, 1.151],
                      ],
                      v: [
                        [21.312, 2.085],
                        [-21.313, 2.085],
                        [-23.397, 0],
                        [-21.313, -2.085],
                        [21.312, -2.085],
                        [23.397, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.187999994615, 0.187999994615, 0.187999994615, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [23.647, 9.335], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.151, 0],
                        [0, 0],
                        [0, 1.151],
                        [-1.151, 0],
                        [0, 0],
                        [0, -1.152],
                      ],
                      o: [
                        [0, 0],
                        [-1.151, 0],
                        [0, -1.152],
                        [0, 0],
                        [1.151, 0],
                        [0, 1.151],
                      ],
                      v: [
                        [21.312, 2.085],
                        [-21.313, 2.085],
                        [-23.397, 0],
                        [-21.313, -2.085],
                        [21.312, -2.085],
                        [23.397, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.187999994615, 0.187999994615, 0.187999994615, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [23.647, 2.335], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 13,
          ty: 4,
          nm: 'Group 19',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 49,
                  s: [0],
                  e: [100],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 50,
                  s: [100],
                  e: [100],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 57,
                  s: [100],
                  e: [0],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 58,
                  s: [0],
                  e: [0],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 61,
                  s: [0],
                  e: [100],
                },
                { t: 62 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [159.382, 121.679, 0], ix: 2, l: 2 },
            a: { a: 0, k: [113.247, 6.043, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.011, -3.133],
                        [3.132, -0.01],
                        [0.012, 3.132],
                        [-3.133, 0.011],
                      ],
                      o: [
                        [0.011, 3.133],
                        [-3.133, 0.012],
                        [-0.011, -3.133],
                        [3.132, -0.011],
                      ],
                      v: [
                        [5.673, -0.021],
                        [0.02, 5.672],
                        [-5.673, 0.021],
                        [-0.02, -5.672],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.258999992819, 0.522000002394, 0.957000014361, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [113.246, 6.043], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 49,
          op: 66,
          st: 49,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 14,
          ty: 4,
          nm: 'Group 18',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 14,
                  s: [0],
                  e: [100],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 15,
                  s: [100],
                  e: [100],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 22,
                  s: [100],
                  e: [0],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 23,
                  s: [0],
                  e: [0],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 26,
                  s: [0],
                  e: [100],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 27,
                  s: [100],
                  e: [100],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 32,
                  s: [100],
                  e: [0],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 33,
                  s: [0],
                  e: [0],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 36,
                  s: [0],
                  e: [100],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 37,
                  s: [100],
                  e: [100],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 42,
                  s: [100],
                  e: [0],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 43,
                  s: [0],
                  e: [0],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 46,
                  s: [0],
                  e: [100],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 47,
                  s: [100],
                  e: [100],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 69,
                  s: [100],
                  e: [0],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 70,
                  s: [0],
                  e: [0],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 73,
                  s: [0],
                  e: [100],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 74,
                  s: [100],
                  e: [100],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 79,
                  s: [100],
                  e: [0],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 80,
                  s: [0],
                  e: [0],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 83,
                  s: [0],
                  e: [100],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 84,
                  s: [100],
                  e: [100],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 89,
                  s: [100],
                  e: [0],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 90,
                  s: [0],
                  e: [0],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 93,
                  s: [0],
                  e: [100],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 94,
                  s: [100],
                  e: [100],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 99,
                  s: [100],
                  e: [0],
                },
                { t: 100 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [105.806, 121.815, 0], ix: 2, l: 2 },
            a: { a: 0, k: [74.921, 6.18, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.011, -3.133],
                        [3.132, -0.01],
                        [0.012, 3.132],
                        [-3.133, 0.011],
                      ],
                      o: [
                        [0.011, 3.133],
                        [-3.133, 0.012],
                        [-0.011, -3.133],
                        [3.132, -0.011],
                      ],
                      v: [
                        [5.673, -0.021],
                        [0.02, 5.672],
                        [-5.673, 0.021],
                        [-0.02, -5.672],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.258999992819, 0.522000002394, 0.957000014361, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [113.246, 6.043], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 14,
          op: 132,
          st: 14,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 15,
          ty: 4,
          nm: 'Group 17',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 12,
                  s: [0],
                  e: [100],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 13,
                  s: [100],
                  e: [100],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 101,
                  s: [100],
                  e: [0],
                },
                { t: 102 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [105.806, 121.815, 0], ix: 2, l: 2 },
            a: { a: 0, k: [74.921, 6.18, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.011, -3.133],
                        [3.132, -0.011],
                        [0.012, 3.132],
                        [-3.133, 0.011],
                      ],
                      o: [
                        [0.011, 3.133],
                        [-3.133, 0.012],
                        [-0.011, -3.133],
                        [3.132, -0.011],
                      ],
                      v: [
                        [5.673, -0.021],
                        [0.02, 5.672],
                        [-5.673, 0.021],
                        [-0.02, -5.672],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.258999992819, 0.522000002394, 0.957000014361, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [97.916, 6.098], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 12,
          op: 132,
          st: 12,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 16,
          ty: 4,
          nm: 'Group 16',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 10,
                  s: [0],
                  e: [100],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 11,
                  s: [100],
                  e: [100],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 103,
                  s: [100],
                  e: [0],
                },
                { t: 104 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [105.806, 121.815, 0], ix: 2, l: 2 },
            a: { a: 0, k: [74.921, 6.18, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.011, -3.133],
                        [3.132, -0.011],
                        [0.012, 3.132],
                        [-3.133, 0.011],
                      ],
                      o: [
                        [0.011, 3.133],
                        [-3.133, 0.012],
                        [-0.011, -3.133],
                        [3.132, -0.011],
                      ],
                      v: [
                        [5.673, -0.021],
                        [0.021, 5.672],
                        [-5.673, 0.021],
                        [-0.02, -5.672],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.258999992819, 0.522000002394, 0.957000014361, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [82.585, 6.153], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 10,
          op: 132,
          st: 10,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 17,
          ty: 4,
          nm: 'Group 15',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 8,
                  s: [0],
                  e: [100],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 9,
                  s: [100],
                  e: [100],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 105,
                  s: [100],
                  e: [0],
                },
                { t: 106 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [105.806, 121.815, 0], ix: 2, l: 2 },
            a: { a: 0, k: [74.921, 6.18, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.011, -3.133],
                        [3.132, -0.011],
                        [0.012, 3.133],
                        [-3.133, 0.011],
                      ],
                      o: [
                        [0.011, 3.133],
                        [-3.133, 0.012],
                        [-0.011, -3.132],
                        [3.132, -0.012],
                      ],
                      v: [
                        [5.673, -0.02],
                        [0.021, 5.672],
                        [-5.673, 0.02],
                        [-0.02, -5.672],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.258999992819, 0.522000002394, 0.957000014361, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [67.255, 6.207], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 8,
          op: 132,
          st: 8,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 18,
          ty: 4,
          nm: 'Group 14',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 6,
                  s: [0],
                  e: [100],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 7,
                  s: [100],
                  e: [100],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 107,
                  s: [100],
                  e: [0],
                },
                { t: 108 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [105.806, 121.815, 0], ix: 2, l: 2 },
            a: { a: 0, k: [74.921, 6.18, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.011, -3.132],
                        [3.132, -0.011],
                        [0.012, 3.132],
                        [-3.133, 0.011],
                      ],
                      o: [
                        [0.011, 3.133],
                        [-3.133, 0.011],
                        [-0.01, -3.133],
                        [3.132, -0.011],
                      ],
                      v: [
                        [5.672, -0.021],
                        [0.02, 5.673],
                        [-5.673, 0.021],
                        [-0.02, -5.672],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.258999992819, 0.522000002394, 0.957000014361, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [51.925, 6.262], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 7',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 6,
          op: 132,
          st: 6,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 19,
          ty: 4,
          nm: 'Group 13',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 4,
                  s: [0],
                  e: [100],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 5,
                  s: [100],
                  e: [100],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 109,
                  s: [100],
                  e: [0],
                },
                { t: 110 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [67.479, 121.952, 0], ix: 2, l: 2 },
            a: { a: 0, k: [36.594, 6.316, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.011, -3.133],
                        [3.132, -0.011],
                        [0.012, 3.132],
                        [-3.133, 0.011],
                      ],
                      o: [
                        [0.011, 3.133],
                        [-3.133, 0.011],
                        [-0.01, -3.133],
                        [3.132, -0.011],
                      ],
                      v: [
                        [5.672, -0.021],
                        [0.02, 5.673],
                        [-5.673, 0.021],
                        [-0.02, -5.672],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.258999992819, 0.522000002394, 0.957000014361, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [36.594, 6.316], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 8',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 4,
          op: 132,
          st: 4,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 20,
          ty: 4,
          nm: 'Group 12',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 2,
                  s: [0],
                  e: [100],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 3,
                  s: [100],
                  e: [100],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 111,
                  s: [100],
                  e: [0],
                },
                { t: 112 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [52.148, 122.006, 0], ix: 2, l: 2 },
            a: { a: 0, k: [21.263, 6.371, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.011, -3.133],
                        [3.132, -0.01],
                        [0.012, 3.132],
                        [-3.133, 0.011],
                      ],
                      o: [
                        [0.011, 3.133],
                        [-3.133, 0.012],
                        [-0.01, -3.133],
                        [3.132, -0.011],
                      ],
                      v: [
                        [5.672, -0.021],
                        [0.02, 5.672],
                        [-5.673, 0.021],
                        [-0.02, -5.672],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.258999992819, 0.522000002394, 0.957000014361, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [21.264, 6.371], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 9',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 2,
          op: 132,
          st: 2,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 21,
          ty: 4,
          nm: 'Group 11',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 0,
                  s: [0],
                  e: [100],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 1,
                  s: [100],
                  e: [100],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 113,
                  s: [100],
                  e: [0],
                },
                { t: 114 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [36.818, 122.061, 0], ix: 2, l: 2 },
            a: { a: 0, k: [5.933, 6.425, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.011, -3.133],
                        [3.133, -0.011],
                        [0.012, 3.132],
                        [-3.133, 0.011],
                      ],
                      o: [
                        [0.011, 3.133],
                        [-3.133, 0.012],
                        [-0.01, -3.133],
                        [3.132, -0.011],
                      ],
                      v: [
                        [5.672, -0.021],
                        [0.02, 5.672],
                        [-5.673, 0.021],
                        [-0.02, -5.672],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.258999992819, 0.522000002394, 0.957000014361, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [5.933, 6.426], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 10',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 22,
          ty: 4,
          nm: 'Group 1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [105.806, 121.815, 0], ix: 2, l: 2 },
            a: { a: 0, k: [74.921, 6.18, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.011, -3.133],
                        [3.132, -0.011],
                        [0.012, 3.133],
                        [-3.133, 0.011],
                      ],
                      o: [
                        [0.011, 3.132],
                        [-3.133, 0.012],
                        [-0.011, -3.132],
                        [3.132, -0.012],
                      ],
                      v: [
                        [5.673, -0.02],
                        [0.021, 5.672],
                        [-5.672, 0.02],
                        [-0.019, -5.672],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.117647066303, 0.117647066303, 0.117647066303, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [143.908, 5.934], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 23,
          ty: 4,
          nm: 'Group 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [105.806, 121.815, 0], ix: 2, l: 2 },
            a: { a: 0, k: [74.921, 6.18, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.011, -3.132],
                        [3.132, -0.011],
                        [0.012, 3.132],
                        [-3.133, 0.011],
                      ],
                      o: [
                        [0.011, 3.133],
                        [-3.133, 0.011],
                        [-0.011, -3.133],
                        [3.132, -0.011],
                      ],
                      v: [
                        [5.673, -0.021],
                        [0.021, 5.673],
                        [-5.672, 0.021],
                        [-0.019, -5.672],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.117647066303, 0.117647066303, 0.117647066303, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [128.577, 5.989], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 24,
          ty: 4,
          nm: 'Group 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [105.806, 121.815, 0], ix: 2, l: 2 },
            a: { a: 0, k: [74.921, 6.18, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.011, -3.133],
                        [3.132, -0.01],
                        [0.012, 3.132],
                        [-3.133, 0.011],
                      ],
                      o: [
                        [0.011, 3.133],
                        [-3.133, 0.012],
                        [-0.011, -3.133],
                        [3.132, -0.011],
                      ],
                      v: [
                        [5.673, -0.021],
                        [0.02, 5.672],
                        [-5.673, 0.021],
                        [-0.02, -5.672],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.117647066303, 0.117647066303, 0.117647066303, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [113.246, 6.043], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 25,
          ty: 4,
          nm: 'Group 4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [105.806, 121.815, 0], ix: 2, l: 2 },
            a: { a: 0, k: [74.921, 6.18, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.011, -3.133],
                        [3.132, -0.011],
                        [0.012, 3.132],
                        [-3.133, 0.011],
                      ],
                      o: [
                        [0.011, 3.133],
                        [-3.133, 0.012],
                        [-0.011, -3.133],
                        [3.132, -0.011],
                      ],
                      v: [
                        [5.673, -0.021],
                        [0.02, 5.672],
                        [-5.673, 0.021],
                        [-0.02, -5.672],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.117647066303, 0.117647066303, 0.117647066303, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [97.916, 6.098], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 26,
          ty: 4,
          nm: 'Group 5',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [105.806, 121.815, 0], ix: 2, l: 2 },
            a: { a: 0, k: [74.921, 6.18, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.011, -3.133],
                        [3.132, -0.011],
                        [0.012, 3.132],
                        [-3.133, 0.011],
                      ],
                      o: [
                        [0.011, 3.133],
                        [-3.133, 0.012],
                        [-0.011, -3.133],
                        [3.132, -0.011],
                      ],
                      v: [
                        [5.673, -0.021],
                        [0.021, 5.672],
                        [-5.673, 0.021],
                        [-0.02, -5.672],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.117647066303, 0.117647066303, 0.117647066303, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [82.585, 6.153], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 27,
          ty: 4,
          nm: 'Group 6',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [105.806, 121.815, 0], ix: 2, l: 2 },
            a: { a: 0, k: [74.921, 6.18, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.011, -3.133],
                        [3.132, -0.011],
                        [0.012, 3.133],
                        [-3.133, 0.011],
                      ],
                      o: [
                        [0.011, 3.133],
                        [-3.133, 0.012],
                        [-0.011, -3.132],
                        [3.132, -0.012],
                      ],
                      v: [
                        [5.673, -0.02],
                        [0.021, 5.672],
                        [-5.673, 0.02],
                        [-0.02, -5.672],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.117647066303, 0.117647066303, 0.117647066303, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [67.255, 6.207], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 28,
          ty: 4,
          nm: 'Group 7',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [105.806, 121.815, 0], ix: 2, l: 2 },
            a: { a: 0, k: [74.921, 6.18, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.011, -3.132],
                        [3.132, -0.011],
                        [0.012, 3.132],
                        [-3.133, 0.011],
                      ],
                      o: [
                        [0.011, 3.133],
                        [-3.133, 0.011],
                        [-0.01, -3.133],
                        [3.132, -0.011],
                      ],
                      v: [
                        [5.672, -0.021],
                        [0.02, 5.673],
                        [-5.673, 0.021],
                        [-0.02, -5.672],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.117647066303, 0.117647066303, 0.117647066303, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [51.925, 6.262], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 7',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 29,
          ty: 4,
          nm: 'Group 8',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [67.479, 121.952, 0], ix: 2, l: 2 },
            a: { a: 0, k: [36.594, 6.316, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.011, -3.133],
                        [3.132, -0.011],
                        [0.012, 3.132],
                        [-3.133, 0.011],
                      ],
                      o: [
                        [0.011, 3.133],
                        [-3.133, 0.011],
                        [-0.01, -3.133],
                        [3.132, -0.011],
                      ],
                      v: [
                        [5.672, -0.021],
                        [0.02, 5.673],
                        [-5.673, 0.021],
                        [-0.02, -5.672],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.117647066303, 0.117647066303, 0.117647066303, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [36.594, 6.316], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 8',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 30,
          ty: 4,
          nm: 'Group 9',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [52.148, 122.006, 0], ix: 2, l: 2 },
            a: { a: 0, k: [21.263, 6.371, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.011, -3.133],
                        [3.132, -0.01],
                        [0.012, 3.132],
                        [-3.133, 0.011],
                      ],
                      o: [
                        [0.011, 3.133],
                        [-3.133, 0.012],
                        [-0.01, -3.133],
                        [3.132, -0.011],
                      ],
                      v: [
                        [5.672, -0.021],
                        [0.02, 5.672],
                        [-5.673, 0.021],
                        [-0.02, -5.672],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.117647066303, 0.117647066303, 0.117647066303, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [21.264, 6.371], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 9',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 31,
          ty: 4,
          nm: 'Group 10',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [36.818, 122.061, 0], ix: 2, l: 2 },
            a: { a: 0, k: [5.933, 6.425, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.011, -3.133],
                        [3.133, -0.011],
                        [0.012, 3.132],
                        [-3.133, 0.011],
                      ],
                      o: [
                        [0.011, 3.133],
                        [-3.133, 0.012],
                        [-0.01, -3.133],
                        [3.132, -0.011],
                      ],
                      v: [
                        [5.672, -0.021],
                        [0.02, 5.672],
                        [-5.673, 0.021],
                        [-0.02, -5.672],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.117647066303, 0.117647066303, 0.117647066303, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [5.933, 6.426], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 10',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 32,
          ty: 4,
          nm: 'line Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [105.743, 104.383, 0], ix: 2, l: 2 },
            a: { a: 0, k: [74.927, 6.079, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.958, -0.003],
                        [0, 0],
                        [0.003, 0.959],
                        [0, 0],
                        [-0.959, 0.003],
                        [0, 0],
                        [-0.003, -0.959],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-0.958, 0.003],
                        [0, 0],
                        [-0.003, -0.958],
                        [0, 0],
                        [0.959, -0.003],
                        [0, 0],
                        [0.004, 0.959],
                      ],
                      v: [
                        [72.944, 5.306],
                        [-72.905, 5.826],
                        [-74.646, 4.096],
                        [-74.674, -3.565],
                        [-72.944, -5.306],
                        [72.904, -5.826],
                        [74.646, -4.096],
                        [74.673, 3.564],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.246999987434, 0.246999987434, 0.246999987434, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [74.927, 6.079], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 33,
          ty: 4,
          nm: 'reshetka Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [105.893, 146.425, 0], ix: 2, l: 2 },
            a: { a: 0, k: [82.072, 54.215, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-74.083, 40.579],
                        [74.374, 40.05],
                        [74.396, 46.011],
                        [-74.063, 46.54],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-74.132, 27.222],
                        [74.327, 26.692],
                        [74.349, 32.653],
                        [-74.109, 33.182],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-74.178, 13.863],
                        [74.28, 13.334],
                        [74.301, 19.294],
                        [-74.158, 19.822],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 3,
                  ty: 'sh',
                  ix: 4,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-74.227, 0.503],
                        [74.232, -0.024],
                        [74.254, 5.934],
                        [-74.206, 6.463],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 4',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 4,
                  ty: 'sh',
                  ix: 5,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [81.822, 53.383],
                        [81.757, 35.186],
                        [81.748, 32.626],
                        [81.727, 26.666],
                        [81.723, 25.627],
                        [81.663, 8.84],
                        [81.653, 5.908],
                        [81.632, -0.051],
                        [81.624, -2.128],
                        [81.585, -13.041],
                        [74.187, -13.014],
                        [74.206, -7.424],
                        [-74.253, -6.895],
                        [-74.273, -12.485],
                        [81.585, -13.041],
                        [81.439, -53.964],
                        [-81.822, -53.382],
                        [-81.676, -12.459],
                        [-75.566, -12.481],
                        [-81.671, -12.459],
                        [-81.652, -6.869],
                        [-81.656, -6.869],
                        [-81.63, 0.531],
                        [-81.626, 0.531],
                        [-81.604, 6.49],
                        [-81.607, 6.49],
                        [-81.581, 13.889],
                        [-81.577, 13.889],
                        [-81.557, 19.848],
                        [-81.561, 19.848],
                        [-81.535, 27.248],
                        [-81.529, 27.248],
                        [-81.508, 33.208],
                        [-81.512, 33.208],
                        [-81.486, 40.605],
                        [-81.482, 40.605],
                        [-81.46, 46.566],
                        [-81.465, 46.566],
                        [-81.439, 53.964],
                        [-81.434, 53.964],
                        [-74.036, 53.938],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 5',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 1,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.187999994615, 0.187999994615, 0.187999994615, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [82.072, 54.215], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 7,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 34,
          ty: 4,
          nm: 'Group 1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [105.604, 62.179, 0], ix: 2, l: 2 },
            a: { a: 0, k: [81.978, 31.113, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [7.678, -0.027],
                        [0, 0],
                        [-0.028, -7.678],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-7.678, 0.027],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.028, -7.677],
                      ],
                      v: [
                        [67.551, -30.837],
                        [-67.791, -30.354],
                        [-81.7, -16.345],
                        [-81.532, 30.863],
                        [81.728, 30.281],
                        [81.56, -16.927],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-0.026, -7.056],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-7.056, 0.024],
                      ],
                      o: [
                        [7.056, -0.025],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.025, -7.056],
                        [0, 0],
                      ],
                      v: [
                        [67.555, -29.673],
                        [80.397, -16.923],
                        [80.56, 29.122],
                        [-80.373, 29.695],
                        [-80.537, -16.349],
                        [-67.787, -29.19],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 1,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.267973776425, 0.267973776425, 0.267973776425, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [81.978, 31.113], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 35,
          ty: 4,
          nm: 'Group 5',
          sr: 1,
          ks: {
            o: { a: 0, k: 9, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [105.604, 62.179, 0], ix: 2, l: 2 },
            a: { a: 0, k: [81.978, 31.113, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [16.357, -30.64],
                        [-2.049, 30.59],
                        [-16.357, 30.641],
                        [2.061, -30.589],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.569000004787, 0.569000004787, 0.569000004787, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [94.989, 31.054], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 36,
          ty: 4,
          nm: 'Group 6',
          sr: 1,
          ks: {
            o: { a: 0, k: 9, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [105.604, 62.179, 0], ix: 2, l: 2 },
            a: { a: 0, k: [81.978, 31.113, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [27.949, -30.682],
                        [9.542, 30.548],
                        [-27.949, 30.682],
                        [-9.543, -30.548],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.569000004787, 0.569000004787, 0.569000004787, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [62.249, 31.171], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 37,
          ty: 4,
          nm: 'Group 20',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [105.604, 62.179, 0], ix: 2, l: 2 },
            a: { a: 0, k: [81.978, 31.113, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.027, -7.678],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-7.678, 0.027],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.027, -7.677],
                        [0, 0],
                        [7.678, -0.028],
                      ],
                      v: [
                        [81.541, -16.938],
                        [81.709, 30.267],
                        [-81.541, 30.848],
                        [-81.709, -16.357],
                        [-67.8, -30.365],
                        [67.532, -30.847],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'gf',
                  o: { a: 0, k: 100, ix: 10 },
                  r: 1,
                  bm: 0,
                  g: {
                    p: 3,
                    k: {
                      a: 0,
                      k: [
                        0, 0.265, 0.265, 0.265, 0.735, 0.319, 0.319, 0.319, 1, 0.373, 0.373, 0.373, 0, 1, 0.525, 0.5, 1,
                        0,
                      ],
                      ix: 9,
                    },
                  },
                  s: { a: 0, k: [-1.5, -128.5], ix: 5 },
                  e: { a: 0, k: [0, 19], ix: 6 },
                  t: 1,
                  nm: 'Gradient Fill 1',
                  mn: 'ADBE Vector Graphic - G-Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [81.991, 31.126], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 7',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 38,
          ty: 4,
          nm: 'Group 7',
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [105.604, 62.179, 0], ix: 2, l: 2 },
            a: { a: 0, k: [81.978, 31.113, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.027, -7.678],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-7.678, 0.027],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.027, -7.677],
                        [0, 0],
                        [7.678, -0.028],
                      ],
                      v: [
                        [81.541, -16.938],
                        [81.709, 30.267],
                        [-81.541, 30.848],
                        [-81.709, -16.357],
                        [-67.8, -30.365],
                        [67.532, -30.847],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.117647066303, 0.117647066303, 0.117647066303, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [81.991, 31.126], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 7',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 39,
          ty: 0,
          nm: 'bitcoins',
          tt: 1,
          refId: 'comp_9',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [256, 256, 0], ix: 2, l: 2 },
            a: { a: 0, k: [256, 256, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 512,
          h: 512,
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 40,
          ty: 4,
          nm: 'back_1 Outlines 3',
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [105.794, 136.195, 0], ix: 2, l: 2 },
            a: { a: 0, k: [87.143, 110.11, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [81.724, 34.744],
                        [-81.475, 35.325],
                        [-81.724, -34.744],
                        [81.474, -35.325],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.118000000598, 0.118000000598, 0.118000000598, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [87.309, 138.979], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 41,
          ty: 4,
          nm: 'fan Outlines 3',
          tt: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.564], y: [1] },
                  o: { x: [0.562], y: [0] },
                  t: 0,
                  s: [0],
                  e: [720],
                },
                { t: 114 },
              ],
              ix: 10,
            },
            p: { a: 0, k: [32.204, 163.426, 0], ix: 2, l: 2 },
            a: { a: 0, k: [33.899, 35.152, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.012, -3.336],
                        [3.336, -0.012],
                        [0.012, 3.336],
                        [-3.336, 0.012],
                      ],
                      o: [
                        [0.012, 3.336],
                        [-3.336, 0.012],
                        [-0.012, -3.336],
                        [3.336, -0.012],
                      ],
                      v: [
                        [6.041, -0.022],
                        [0.021, 6.04],
                        [-6.04, 0.022],
                        [-0.021, -6.04],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.118000000598, 0.118000000598, 0.118000000598, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [33.899, 35.153], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.326, -0.345],
                        [1.362, -0.185],
                        [4.986, 2.435],
                        [-0.207, 0.807],
                        [-3.158, 1.411],
                        [-2.269, 2.049],
                        [0.133, 0.634],
                        [5.046, 4.832],
                        [0.364, -1.32],
                        [0.522, -1.272],
                        [4.591, -3.11],
                        [0.586, 0.597],
                        [-0.355, 3.419],
                        [0.64, 2.989],
                        [0.615, 0.201],
                        [6.707, -1.953],
                        [-0.963, -0.976],
                        [-0.84, -1.088],
                        [-0.508, -5.49],
                        [0.837, -0.218],
                        [2.737, 1.983],
                        [2.909, 0.94],
                        [0.483, -0.433],
                        [1.662, -6.785],
                        [-1.326, 0.345],
                        [-1.363, 0.185],
                        [-5.02, -2.215],
                        [0.222, -0.894],
                        [3.053, -1.364],
                        [2.269, -2.049],
                        [-0.133, -0.634],
                        [-5.045, -4.832],
                        [-0.363, 1.322],
                        [-0.522, 1.272],
                        [-4.444, 3.228],
                        [-0.655, -0.606],
                        [0.347, -3.349],
                        [-0.641, -2.989],
                        [-0.615, -0.201],
                        [-6.707, 1.953],
                        [0.962, 0.976],
                        [0.841, 1.088],
                        [0.47, 5.505],
                        [-0.8, 0.247],
                        [-2.771, -2.008],
                        [-2.909, -0.94],
                        [-0.483, 0.432],
                        [-1.661, 6.785],
                      ],
                      o: [
                        [-1.344, 0.364],
                        [-4.618, 0.666],
                        [0.379, -0.723],
                        [5.888, -0.015],
                        [2.933, -1.296],
                        [0.474, -0.431],
                        [-1.377, -6.617],
                        [-0.991, -0.94],
                        [-0.357, 1.346],
                        [-1.728, 4.323],
                        [-0.433, -0.721],
                        [2.897, -5.085],
                        [0.343, -3.189],
                        [-0.137, -0.626],
                        [-6.42, -2.116],
                        [-1.309, 0.389],
                        [0.986, 0.982],
                        [2.832, 3.597],
                        [-0.903, 0.007],
                        [-2.948, -4.941],
                        [-2.589, -1.892],
                        [-0.61, -0.196],
                        [-5.042, 4.501],
                        [-0.317, 1.328],
                        [1.344, -0.364],
                        [4.469, -0.645],
                        [-0.432, 0.791],
                        [-5.692, 0.113],
                        [-2.933, 1.296],
                        [-0.474, 0.431],
                        [1.378, 6.617],
                        [0.991, 0.94],
                        [0.357, -1.345],
                        [1.681, -4.207],
                        [0.489, 0.751],
                        [-2.786, 5.01],
                        [-0.344, 3.189],
                        [0.136, 0.626],
                        [6.42, 2.116],
                        [1.309, -0.389],
                        [-0.986, -0.982],
                        [-2.849, -3.618],
                        [0.867, -0.052],
                        [2.954, 5.022],
                        [2.589, 1.892],
                        [0.61, 0.196],
                        [5.042, -4.502],
                        [0.318, -1.328],
                      ],
                      v: [
                        [31.101, 6.003],
                        [27.033, 6.826],
                        [9.212, 4.923],
                        [10.089, 2.62],
                        [25.066, -0.359],
                        [32.943, -5.418],
                        [33.49, -7.13],
                        [23.738, -24.784],
                        [20.749, -23.932],
                        [19.426, -19.997],
                        [8.898, -5.535],
                        [7.368, -7.522],
                        [12.223, -21.888],
                        [11.781, -31.238],
                        [10.57, -32.568],
                        [-9.594, -32.949],
                        [-10.351, -29.936],
                        [-7.605, -26.823],
                        [-0.368, -10.751],
                        [-2.979, -10.397],
                        [-12.844, -21.529],
                        [-21.163, -25.82],
                        [-22.92, -25.438],
                        [-33.333, -8.165],
                        [-31.101, -6.004],
                        [-27.032, -6.826],
                        [-9.699, -5.154],
                        [-10.678, -2.615],
                        [-25.066, 0.359],
                        [-32.943, 5.417],
                        [-33.49, 7.129],
                        [-23.738, 24.784],
                        [-20.749, 23.931],
                        [-19.426, 19.999],
                        [-9.271, 5.793],
                        [-7.546, 7.833],
                        [-12.221, 21.887],
                        [-11.78, 31.238],
                        [-10.571, 32.568],
                        [9.594, 32.949],
                        [10.351, 29.935],
                        [7.605, 26.822],
                        [0.359, 10.654],
                        [2.868, 10.21],
                        [12.844, 21.529],
                        [21.163, 25.82],
                        [22.92, 25.439],
                        [33.331, 8.165],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.246999987434, 0.246999987434, 0.246999987434, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [33.899, 35.153], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 42,
          ty: 4,
          nm: 'back_1 Outlines 2',
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [105.794, 136.195, 0], ix: 2, l: 2 },
            a: { a: 0, k: [87.143, 110.11, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [81.724, 34.744],
                        [-81.475, 35.325],
                        [-81.724, -34.744],
                        [81.474, -35.325],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.118000000598, 0.118000000598, 0.118000000598, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [87.309, 138.979], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 43,
          ty: 4,
          nm: 'fan Outlines 2',
          tt: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.564], y: [1] },
                  o: { x: [0.562], y: [0] },
                  t: 0,
                  s: [0],
                  e: [720],
                },
                { t: 114 },
              ],
              ix: 10,
            },
            p: { a: 0, k: [180.204, 163.426, 0], ix: 2, l: 2 },
            a: { a: 0, k: [33.899, 35.152, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.012, -3.336],
                        [3.336, -0.012],
                        [0.012, 3.336],
                        [-3.336, 0.012],
                      ],
                      o: [
                        [0.012, 3.336],
                        [-3.336, 0.012],
                        [-0.012, -3.336],
                        [3.336, -0.012],
                      ],
                      v: [
                        [6.041, -0.022],
                        [0.021, 6.04],
                        [-6.04, 0.022],
                        [-0.021, -6.04],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.118000000598, 0.118000000598, 0.118000000598, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [33.899, 35.153], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.326, -0.345],
                        [1.362, -0.185],
                        [4.986, 2.435],
                        [-0.207, 0.807],
                        [-3.158, 1.411],
                        [-2.269, 2.049],
                        [0.133, 0.634],
                        [5.046, 4.832],
                        [0.364, -1.32],
                        [0.522, -1.272],
                        [4.591, -3.11],
                        [0.586, 0.597],
                        [-0.355, 3.419],
                        [0.64, 2.989],
                        [0.615, 0.201],
                        [6.707, -1.953],
                        [-0.963, -0.976],
                        [-0.84, -1.088],
                        [-0.508, -5.49],
                        [0.837, -0.218],
                        [2.737, 1.983],
                        [2.909, 0.94],
                        [0.483, -0.433],
                        [1.662, -6.785],
                        [-1.326, 0.345],
                        [-1.363, 0.185],
                        [-5.02, -2.215],
                        [0.222, -0.894],
                        [3.053, -1.364],
                        [2.269, -2.049],
                        [-0.133, -0.634],
                        [-5.045, -4.832],
                        [-0.363, 1.322],
                        [-0.522, 1.272],
                        [-4.444, 3.228],
                        [-0.655, -0.606],
                        [0.347, -3.349],
                        [-0.641, -2.989],
                        [-0.615, -0.201],
                        [-6.707, 1.953],
                        [0.962, 0.976],
                        [0.841, 1.088],
                        [0.47, 5.505],
                        [-0.8, 0.247],
                        [-2.771, -2.008],
                        [-2.909, -0.94],
                        [-0.483, 0.432],
                        [-1.661, 6.785],
                      ],
                      o: [
                        [-1.344, 0.364],
                        [-4.618, 0.666],
                        [0.379, -0.723],
                        [5.888, -0.015],
                        [2.933, -1.296],
                        [0.474, -0.431],
                        [-1.377, -6.617],
                        [-0.991, -0.94],
                        [-0.357, 1.346],
                        [-1.728, 4.323],
                        [-0.433, -0.721],
                        [2.897, -5.085],
                        [0.343, -3.189],
                        [-0.137, -0.626],
                        [-6.42, -2.116],
                        [-1.309, 0.389],
                        [0.986, 0.982],
                        [2.832, 3.597],
                        [-0.903, 0.007],
                        [-2.948, -4.941],
                        [-2.589, -1.892],
                        [-0.61, -0.196],
                        [-5.042, 4.501],
                        [-0.317, 1.328],
                        [1.344, -0.364],
                        [4.469, -0.645],
                        [-0.432, 0.791],
                        [-5.692, 0.113],
                        [-2.933, 1.296],
                        [-0.474, 0.431],
                        [1.378, 6.617],
                        [0.991, 0.94],
                        [0.357, -1.345],
                        [1.681, -4.207],
                        [0.489, 0.751],
                        [-2.786, 5.01],
                        [-0.344, 3.189],
                        [0.136, 0.626],
                        [6.42, 2.116],
                        [1.309, -0.389],
                        [-0.986, -0.982],
                        [-2.849, -3.618],
                        [0.867, -0.052],
                        [2.954, 5.022],
                        [2.589, 1.892],
                        [0.61, 0.196],
                        [5.042, -4.502],
                        [0.318, -1.328],
                      ],
                      v: [
                        [31.101, 6.003],
                        [27.033, 6.826],
                        [9.212, 4.923],
                        [10.089, 2.62],
                        [25.066, -0.359],
                        [32.943, -5.418],
                        [33.49, -7.13],
                        [23.738, -24.784],
                        [20.749, -23.932],
                        [19.426, -19.997],
                        [8.898, -5.535],
                        [7.368, -7.522],
                        [12.223, -21.888],
                        [11.781, -31.238],
                        [10.57, -32.568],
                        [-9.594, -32.949],
                        [-10.351, -29.936],
                        [-7.605, -26.823],
                        [-0.368, -10.751],
                        [-2.979, -10.397],
                        [-12.844, -21.529],
                        [-21.163, -25.82],
                        [-22.92, -25.438],
                        [-33.333, -8.165],
                        [-31.101, -6.004],
                        [-27.032, -6.826],
                        [-9.699, -5.154],
                        [-10.678, -2.615],
                        [-25.066, 0.359],
                        [-32.943, 5.417],
                        [-33.49, 7.129],
                        [-23.738, 24.784],
                        [-20.749, 23.931],
                        [-19.426, 19.999],
                        [-9.271, 5.793],
                        [-7.546, 7.833],
                        [-12.221, 21.887],
                        [-11.78, 31.238],
                        [-10.571, 32.568],
                        [9.594, 32.949],
                        [10.351, 29.935],
                        [7.605, 26.822],
                        [0.359, 10.654],
                        [2.868, 10.21],
                        [12.844, 21.529],
                        [21.163, 25.82],
                        [22.92, 25.439],
                        [33.331, 8.165],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.246999987434, 0.246999987434, 0.246999987434, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [33.899, 35.153], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 44,
          ty: 4,
          nm: 'fan Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.564], y: [1] },
                  o: { x: [0.562], y: [0] },
                  t: 0,
                  s: [0],
                  e: [720],
                },
                { t: 114 },
              ],
              ix: 10,
            },
            p: { a: 0, k: [105.954, 163.426, 0], ix: 2, l: 2 },
            a: { a: 0, k: [33.899, 35.152, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.012, -3.336],
                        [3.336, -0.012],
                        [0.012, 3.336],
                        [-3.336, 0.012],
                      ],
                      o: [
                        [0.012, 3.336],
                        [-3.336, 0.012],
                        [-0.012, -3.336],
                        [3.336, -0.012],
                      ],
                      v: [
                        [6.041, -0.022],
                        [0.021, 6.04],
                        [-6.04, 0.022],
                        [-0.021, -6.04],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.118000000598, 0.118000000598, 0.118000000598, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [33.899, 35.153], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.326, -0.345],
                        [1.362, -0.185],
                        [4.986, 2.435],
                        [-0.207, 0.807],
                        [-3.158, 1.411],
                        [-2.269, 2.049],
                        [0.133, 0.634],
                        [5.046, 4.832],
                        [0.364, -1.32],
                        [0.522, -1.272],
                        [4.591, -3.11],
                        [0.586, 0.597],
                        [-0.355, 3.419],
                        [0.64, 2.989],
                        [0.615, 0.201],
                        [6.707, -1.953],
                        [-0.963, -0.976],
                        [-0.84, -1.088],
                        [-0.508, -5.49],
                        [0.837, -0.218],
                        [2.737, 1.983],
                        [2.909, 0.94],
                        [0.483, -0.433],
                        [1.662, -6.785],
                        [-1.326, 0.345],
                        [-1.363, 0.185],
                        [-5.02, -2.215],
                        [0.222, -0.894],
                        [3.053, -1.364],
                        [2.269, -2.049],
                        [-0.133, -0.634],
                        [-5.045, -4.832],
                        [-0.363, 1.322],
                        [-0.522, 1.272],
                        [-4.444, 3.228],
                        [-0.655, -0.606],
                        [0.347, -3.349],
                        [-0.641, -2.989],
                        [-0.615, -0.201],
                        [-6.707, 1.953],
                        [0.962, 0.976],
                        [0.841, 1.088],
                        [0.47, 5.505],
                        [-0.8, 0.247],
                        [-2.771, -2.008],
                        [-2.909, -0.94],
                        [-0.483, 0.432],
                        [-1.661, 6.785],
                      ],
                      o: [
                        [-1.344, 0.364],
                        [-4.618, 0.666],
                        [0.379, -0.723],
                        [5.888, -0.015],
                        [2.933, -1.296],
                        [0.474, -0.431],
                        [-1.377, -6.617],
                        [-0.991, -0.94],
                        [-0.357, 1.346],
                        [-1.728, 4.323],
                        [-0.433, -0.721],
                        [2.897, -5.085],
                        [0.343, -3.189],
                        [-0.137, -0.626],
                        [-6.42, -2.116],
                        [-1.309, 0.389],
                        [0.986, 0.982],
                        [2.832, 3.597],
                        [-0.903, 0.007],
                        [-2.948, -4.941],
                        [-2.589, -1.892],
                        [-0.61, -0.196],
                        [-5.042, 4.501],
                        [-0.317, 1.328],
                        [1.344, -0.364],
                        [4.469, -0.645],
                        [-0.432, 0.791],
                        [-5.692, 0.113],
                        [-2.933, 1.296],
                        [-0.474, 0.431],
                        [1.378, 6.617],
                        [0.991, 0.94],
                        [0.357, -1.345],
                        [1.681, -4.207],
                        [0.489, 0.751],
                        [-2.786, 5.01],
                        [-0.344, 3.189],
                        [0.136, 0.626],
                        [6.42, 2.116],
                        [1.309, -0.389],
                        [-0.986, -0.982],
                        [-2.849, -3.618],
                        [0.867, -0.052],
                        [2.954, 5.022],
                        [2.589, 1.892],
                        [0.61, 0.196],
                        [5.042, -4.502],
                        [0.318, -1.328],
                      ],
                      v: [
                        [31.101, 6.003],
                        [27.033, 6.826],
                        [9.212, 4.923],
                        [10.089, 2.62],
                        [25.066, -0.359],
                        [32.943, -5.418],
                        [33.49, -7.13],
                        [23.738, -24.784],
                        [20.749, -23.932],
                        [19.426, -19.997],
                        [8.898, -5.535],
                        [7.368, -7.522],
                        [12.223, -21.888],
                        [11.781, -31.238],
                        [10.57, -32.568],
                        [-9.594, -32.949],
                        [-10.351, -29.936],
                        [-7.605, -26.823],
                        [-0.368, -10.751],
                        [-2.979, -10.397],
                        [-12.844, -21.529],
                        [-21.163, -25.82],
                        [-22.92, -25.438],
                        [-33.333, -8.165],
                        [-31.101, -6.004],
                        [-27.032, -6.826],
                        [-9.699, -5.154],
                        [-10.678, -2.615],
                        [-25.066, 0.359],
                        [-32.943, 5.417],
                        [-33.49, 7.129],
                        [-23.738, 24.784],
                        [-20.749, 23.931],
                        [-19.426, 19.999],
                        [-9.271, 5.793],
                        [-7.546, 7.833],
                        [-12.221, 21.887],
                        [-11.78, 31.238],
                        [-10.571, 32.568],
                        [9.594, 32.949],
                        [10.351, 29.935],
                        [7.605, 26.822],
                        [0.359, 10.654],
                        [2.868, 10.21],
                        [12.844, 21.529],
                        [21.163, 25.82],
                        [22.92, 25.439],
                        [33.331, 8.165],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.246999987434, 0.246999987434, 0.246999987434, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [33.899, 35.153], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 45,
          ty: 4,
          nm: 'back_1 Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [105.794, 136.195, 0], ix: 2, l: 2 },
            a: { a: 0, k: [87.143, 110.11, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [81.724, 34.744],
                        [-81.475, 35.325],
                        [-81.724, -34.744],
                        [81.474, -35.325],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.118000000598, 0.118000000598, 0.118000000598, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [87.309, 138.979], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.027, -7.678],
                        [0, 0],
                        [7.677, -0.028],
                        [0, 0],
                        [0.027, 7.678],
                        [0, 0],
                        [-7.678, 0.027],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0.027, 7.677],
                        [0, 0],
                        [-7.677, 0.028],
                        [0, 0],
                        [-0.027, -7.677],
                        [0, 0],
                        [7.678, -0.028],
                      ],
                      v: [
                        [81.384, -67.963],
                        [81.866, 67.381],
                        [67.957, 81.39],
                        [-67.376, 81.872],
                        [-81.384, 67.963],
                        [-81.866, -67.381],
                        [-67.957, -81.389],
                        [67.375, -81.872],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.149000010771, 0.149000010771, 0.149000010771, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [87.123, 87.13], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [8.144, -0.029],
                        [0, 0],
                        [-0.03, -8.338],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-5.133, 0],
                        [0, 0],
                        [0, 5.134],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-0.03, -8.338],
                        [0, 0],
                        [-8.145, 0.029],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 5.134],
                        [0, 0],
                        [5.133, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [86.882, 64.522],
                        [86.315, -94.723],
                        [71.453, -109.831],
                        [-72.109, -109.319],
                        [-86.863, -94.105],
                        [-86.285, 68.049],
                        [-86.265, 68.049],
                        [-86.265, 100.565],
                        [-76.971, 109.859],
                        [77.598, 109.859],
                        [86.893, 100.565],
                        [86.893, 64.522],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.149000010771, 0.149000010771, 0.149000010771, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [87.143, 110.109], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_9',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'bitcoin 9',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 81,
                  s: [-3.467, 62.692, 0],
                  e: [218.033, 62.692, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 104 },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [31.75, 32.75, 0], ix: 1, l: 2 },
            s: { a: 0, k: [67, 67, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.457, 3.041],
                        [4.392, 1.489],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.933, 0.21],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.721, 0.176],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.054, -0.036],
                        [0.043, -0.727],
                        [0, 0],
                        [-0.138, -0.054],
                        [0.127, 0.03],
                        [0, 0],
                        [0.985, 0.24],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.812, -0.205],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.902, -0.22],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.877, 5.282],
                        [3.12, 1.6],
                      ],
                      o: [
                        [0.631, -4.154],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.914, -0.225],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.757, -0.169],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.409, 0.347],
                        [0, 0],
                        [0.098, 0.025],
                        [-0.115, -0.028],
                        [0, 0],
                        [-0.172, 0.421],
                        [0.035, 0.049],
                        [0, 0],
                        [0, 0],
                        [0.843, 0.208],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.949, 0.254],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [5.932, 1.105],
                        [1.514, -4.253],
                        [2.272, -0.515],
                      ],
                      v: [
                        [14.81, -3.873],
                        [7.837, -11.749],
                        [9.262, -17.376],
                        [5.783, -18.229],
                        [4.396, -12.751],
                        [1.609, -13.397],
                        [3.006, -18.912],
                        [-0.47, -19.765],
                        [-1.896, -14.141],
                        [-4.117, -14.654],
                        [-4.113, -14.672],
                        [-8.91, -15.852],
                        [-9.836, -12.193],
                        [-7.309, -11.575],
                        [-5.689, -9.583],
                        [-7.312, -3.173],
                        [-6.95, -3.059],
                        [-7.317, -3.148],
                        [-9.592, 5.831],
                        [-11.186, 6.645],
                        [-13.714, 6.023],
                        [-15.441, 9.944],
                        [-10.915, 11.055],
                        [-8.435, 11.685],
                        [-9.875, 17.376],
                        [-6.4, 18.23],
                        [-4.974, 12.599],
                        [-2.202, 13.307],
                        [-3.623, 18.912],
                        [-0.144, 19.765],
                        [1.295, 14.085],
                        [13.564, 9.462],
                        [10.369, 1.155],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.124, -4.435],
                        [2.358, 0.576],
                        [0, 0],
                      ],
                      o: [
                        [-1.075, 4.254],
                        [0, 0],
                        [2.358, 0.58],
                      ],
                      v: [
                        [6.862, 7.1],
                        [-3.844, 8.478],
                        [-1.934, 0.938],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.021, -4.035],
                        [1.964, 0.482],
                        [0, 0],
                      ],
                      o: [
                        [-0.981, 3.869],
                        [0, 0],
                        [1.964, 0.482],
                      ],
                      v: [
                        [7.938, -3.935],
                        [-1.06, -2.513],
                        [0.672, -9.352],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 1,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [31.209, 32.75], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -17.949],
                        [17.949, 0],
                        [0, 17.949],
                        [-17.949, 0],
                      ],
                      o: [
                        [0, 17.949],
                        [-17.949, 0],
                        [0, -17.949],
                        [17.949, 0],
                      ],
                      v: [
                        [32.5, 0],
                        [0, 32.5],
                        [-32.5, 0],
                        [0, -32.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.968627510819, 0.576470588235, 0.101960791794, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [32.75, 32.75], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 81,
          op: 105,
          st: 65.7998046875,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'bitcoin 8',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 57,
                  s: [-3.467, 62.692, 0],
                  e: [218.033, 62.692, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 80 },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [31.75, 32.75, 0], ix: 1, l: 2 },
            s: { a: 0, k: [67, 67, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.457, 3.041],
                        [4.392, 1.489],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.933, 0.21],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.721, 0.176],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.054, -0.036],
                        [0.043, -0.727],
                        [0, 0],
                        [-0.138, -0.054],
                        [0.127, 0.03],
                        [0, 0],
                        [0.985, 0.24],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.812, -0.205],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.902, -0.22],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.877, 5.282],
                        [3.12, 1.6],
                      ],
                      o: [
                        [0.631, -4.154],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.914, -0.225],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.757, -0.169],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.409, 0.347],
                        [0, 0],
                        [0.098, 0.025],
                        [-0.115, -0.028],
                        [0, 0],
                        [-0.172, 0.421],
                        [0.035, 0.049],
                        [0, 0],
                        [0, 0],
                        [0.843, 0.208],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.949, 0.254],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [5.932, 1.105],
                        [1.514, -4.253],
                        [2.272, -0.515],
                      ],
                      v: [
                        [14.81, -3.873],
                        [7.837, -11.749],
                        [9.262, -17.376],
                        [5.783, -18.229],
                        [4.396, -12.751],
                        [1.609, -13.397],
                        [3.006, -18.912],
                        [-0.47, -19.765],
                        [-1.896, -14.141],
                        [-4.117, -14.654],
                        [-4.113, -14.672],
                        [-8.91, -15.852],
                        [-9.836, -12.193],
                        [-7.309, -11.575],
                        [-5.689, -9.583],
                        [-7.312, -3.173],
                        [-6.95, -3.059],
                        [-7.317, -3.148],
                        [-9.592, 5.831],
                        [-11.186, 6.645],
                        [-13.714, 6.023],
                        [-15.441, 9.944],
                        [-10.915, 11.055],
                        [-8.435, 11.685],
                        [-9.875, 17.376],
                        [-6.4, 18.23],
                        [-4.974, 12.599],
                        [-2.202, 13.307],
                        [-3.623, 18.912],
                        [-0.144, 19.765],
                        [1.295, 14.085],
                        [13.564, 9.462],
                        [10.369, 1.155],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.124, -4.435],
                        [2.358, 0.576],
                        [0, 0],
                      ],
                      o: [
                        [-1.075, 4.254],
                        [0, 0],
                        [2.358, 0.58],
                      ],
                      v: [
                        [6.862, 7.1],
                        [-3.844, 8.478],
                        [-1.934, 0.938],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.021, -4.035],
                        [1.964, 0.482],
                        [0, 0],
                      ],
                      o: [
                        [-0.981, 3.869],
                        [0, 0],
                        [1.964, 0.482],
                      ],
                      v: [
                        [7.938, -3.935],
                        [-1.06, -2.513],
                        [0.672, -9.352],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 1,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [31.209, 32.75], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -17.949],
                        [17.949, 0],
                        [0, 17.949],
                        [-17.949, 0],
                      ],
                      o: [
                        [0, 17.949],
                        [-17.949, 0],
                        [0, -17.949],
                        [17.949, 0],
                      ],
                      v: [
                        [32.5, 0],
                        [0, 32.5],
                        [-32.5, 0],
                        [0, -32.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.968627510819, 0.576470588235, 0.101960791794, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [32.75, 32.75], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 57,
          op: 81,
          st: 41.7998046875,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'bitcoin 7',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 33,
                  s: [-3.467, 62.692, 0],
                  e: [218.033, 62.692, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 56 },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [31.75, 32.75, 0], ix: 1, l: 2 },
            s: { a: 0, k: [67, 67, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.457, 3.041],
                        [4.392, 1.489],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.933, 0.21],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.721, 0.176],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.054, -0.036],
                        [0.043, -0.727],
                        [0, 0],
                        [-0.138, -0.054],
                        [0.127, 0.03],
                        [0, 0],
                        [0.985, 0.24],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.812, -0.205],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.902, -0.22],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.877, 5.282],
                        [3.12, 1.6],
                      ],
                      o: [
                        [0.631, -4.154],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.914, -0.225],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.757, -0.169],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.409, 0.347],
                        [0, 0],
                        [0.098, 0.025],
                        [-0.115, -0.028],
                        [0, 0],
                        [-0.172, 0.421],
                        [0.035, 0.049],
                        [0, 0],
                        [0, 0],
                        [0.843, 0.208],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.949, 0.254],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [5.932, 1.105],
                        [1.514, -4.253],
                        [2.272, -0.515],
                      ],
                      v: [
                        [14.81, -3.873],
                        [7.837, -11.749],
                        [9.262, -17.376],
                        [5.783, -18.229],
                        [4.396, -12.751],
                        [1.609, -13.397],
                        [3.006, -18.912],
                        [-0.47, -19.765],
                        [-1.896, -14.141],
                        [-4.117, -14.654],
                        [-4.113, -14.672],
                        [-8.91, -15.852],
                        [-9.836, -12.193],
                        [-7.309, -11.575],
                        [-5.689, -9.583],
                        [-7.312, -3.173],
                        [-6.95, -3.059],
                        [-7.317, -3.148],
                        [-9.592, 5.831],
                        [-11.186, 6.645],
                        [-13.714, 6.023],
                        [-15.441, 9.944],
                        [-10.915, 11.055],
                        [-8.435, 11.685],
                        [-9.875, 17.376],
                        [-6.4, 18.23],
                        [-4.974, 12.599],
                        [-2.202, 13.307],
                        [-3.623, 18.912],
                        [-0.144, 19.765],
                        [1.295, 14.085],
                        [13.564, 9.462],
                        [10.369, 1.155],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.124, -4.435],
                        [2.358, 0.576],
                        [0, 0],
                      ],
                      o: [
                        [-1.075, 4.254],
                        [0, 0],
                        [2.358, 0.58],
                      ],
                      v: [
                        [6.862, 7.1],
                        [-3.844, 8.478],
                        [-1.934, 0.938],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.021, -4.035],
                        [1.964, 0.482],
                        [0, 0],
                      ],
                      o: [
                        [-0.981, 3.869],
                        [0, 0],
                        [1.964, 0.482],
                      ],
                      v: [
                        [7.938, -3.935],
                        [-1.06, -2.513],
                        [0.672, -9.352],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 1,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [31.209, 32.75], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -17.949],
                        [17.949, 0],
                        [0, 17.949],
                        [-17.949, 0],
                      ],
                      o: [
                        [0, 17.949],
                        [-17.949, 0],
                        [0, -17.949],
                        [17.949, 0],
                      ],
                      v: [
                        [32.5, 0],
                        [0, 32.5],
                        [-32.5, 0],
                        [0, -32.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.968627510819, 0.576470588235, 0.101960791794, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [32.75, 32.75], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 33,
          op: 57,
          st: 17.7998046875,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'bitcoin 6',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 9,
                  s: [-3.467, 62.692, 0],
                  e: [218.033, 62.692, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 32 },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [31.75, 32.75, 0], ix: 1, l: 2 },
            s: { a: 0, k: [67, 67, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.457, 3.041],
                        [4.392, 1.489],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.933, 0.21],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.721, 0.176],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.054, -0.036],
                        [0.043, -0.727],
                        [0, 0],
                        [-0.138, -0.054],
                        [0.127, 0.03],
                        [0, 0],
                        [0.985, 0.24],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.812, -0.205],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.902, -0.22],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.877, 5.282],
                        [3.12, 1.6],
                      ],
                      o: [
                        [0.631, -4.154],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.914, -0.225],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.757, -0.169],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.409, 0.347],
                        [0, 0],
                        [0.098, 0.025],
                        [-0.115, -0.028],
                        [0, 0],
                        [-0.172, 0.421],
                        [0.035, 0.049],
                        [0, 0],
                        [0, 0],
                        [0.843, 0.208],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.949, 0.254],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [5.932, 1.105],
                        [1.514, -4.253],
                        [2.272, -0.515],
                      ],
                      v: [
                        [14.81, -3.873],
                        [7.837, -11.749],
                        [9.262, -17.376],
                        [5.783, -18.229],
                        [4.396, -12.751],
                        [1.609, -13.397],
                        [3.006, -18.912],
                        [-0.47, -19.765],
                        [-1.896, -14.141],
                        [-4.117, -14.654],
                        [-4.113, -14.672],
                        [-8.91, -15.852],
                        [-9.836, -12.193],
                        [-7.309, -11.575],
                        [-5.689, -9.583],
                        [-7.312, -3.173],
                        [-6.95, -3.059],
                        [-7.317, -3.148],
                        [-9.592, 5.831],
                        [-11.186, 6.645],
                        [-13.714, 6.023],
                        [-15.441, 9.944],
                        [-10.915, 11.055],
                        [-8.435, 11.685],
                        [-9.875, 17.376],
                        [-6.4, 18.23],
                        [-4.974, 12.599],
                        [-2.202, 13.307],
                        [-3.623, 18.912],
                        [-0.144, 19.765],
                        [1.295, 14.085],
                        [13.564, 9.462],
                        [10.369, 1.155],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.124, -4.435],
                        [2.358, 0.576],
                        [0, 0],
                      ],
                      o: [
                        [-1.075, 4.254],
                        [0, 0],
                        [2.358, 0.58],
                      ],
                      v: [
                        [6.862, 7.1],
                        [-3.844, 8.478],
                        [-1.934, 0.938],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.021, -4.035],
                        [1.964, 0.482],
                        [0, 0],
                      ],
                      o: [
                        [-0.981, 3.869],
                        [0, 0],
                        [1.964, 0.482],
                      ],
                      v: [
                        [7.938, -3.935],
                        [-1.06, -2.513],
                        [0.672, -9.352],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 1,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [31.209, 32.75], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -17.949],
                        [17.949, 0],
                        [0, 17.949],
                        [-17.949, 0],
                      ],
                      o: [
                        [0, 17.949],
                        [-17.949, 0],
                        [0, -17.949],
                        [17.949, 0],
                      ],
                      v: [
                        [32.5, 0],
                        [0, 32.5],
                        [-32.5, 0],
                        [0, -32.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.968627510819, 0.576470588235, 0.101960791794, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [32.75, 32.75], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 9,
          op: 33,
          st: -6.2001953125,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'stuff Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [398.615, 446.772, 0], ix: 2, l: 2 },
        a: { a: 0, k: [88.257, 33.787, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [14.292, 9.813],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [15.548, 3.839],
                    [-15.548, -4.906],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.948999980852, 0.948999980852, 0.948999980852, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 6, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [145.779, 25.02], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [18.989, 3.139],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [14.23, -5.854],
                    [-14.23, 2.715],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.948999980852, 0.948999980852, 0.948999980852, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 6, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [145.426, 26.364], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.84, 0],
                    [0, 1.554],
                    [0.839, 0],
                    [0, -1.552],
                  ],
                  o: [
                    [0.839, 0],
                    [0, -1.552],
                    [-0.84, 0],
                    [0, 1.554],
                  ],
                  v: [
                    [0.001, 2.812],
                    [1.521, -0.001],
                    [0.001, -2.812],
                    [-1.521, -0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.658999992819, 0.361000001197, 0.067000003889, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [131.201, 29.079], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.895, 0.219],
                    [0.377, 1.543],
                    [0.893, -0.219],
                    [-0.377, -1.543],
                  ],
                  o: [
                    [0.894, -0.219],
                    [-0.377, -1.544],
                    [-0.895, 0.219],
                    [0.378, 1.544],
                  ],
                  v: [
                    [0.684, 2.794],
                    [1.619, -0.395],
                    [-0.683, -2.794],
                    [-1.619, 0.396],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.658999992819, 0.361000001197, 0.067000003889, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [161.51, 28.841], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.921, 0],
                    [0, 1.589],
                    [0.92, 0],
                    [0, -1.589],
                  ],
                  o: [
                    [0.92, 0],
                    [0, -1.589],
                    [-0.921, 0],
                    [0, 1.589],
                  ],
                  v: [
                    [0.001, 2.876],
                    [1.667, 0.001],
                    [0.001, -2.876],
                    [-1.667, 0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.658999992819, 0.361000001197, 0.067000003889, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [159.655, 20.509], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.921, 0],
                    [0, 1.589],
                    [0.92, 0],
                    [0, -1.588],
                  ],
                  o: [
                    [0.92, 0],
                    [0, -1.588],
                    [-0.921, 0],
                    [0, 1.589],
                  ],
                  v: [
                    [0, 2.876],
                    [1.666, -0.001],
                    [0, -2.876],
                    [-1.666, -0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.658999992819, 0.361000001197, 0.067000003889, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [130.228, 20.118], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-8.093, 0],
                    [0, 2.186],
                    [8.093, 0],
                    [0, -2.186],
                  ],
                  o: [
                    [8.093, 0],
                    [0, -2.186],
                    [-8.093, 0],
                    [0, 2.186],
                  ],
                  v: [
                    [0, 3.957],
                    [14.653, 0],
                    [0, -3.957],
                    [-14.653, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [147.732, 40.623], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 7',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [1.705, 0],
                    [0, 0],
                    [-2.239, 4.096],
                  ],
                  o: [
                    [0, 0],
                    [0, 1.701],
                    [0, 0],
                    [7.936, -4.608],
                    [0, 0],
                  ],
                  v: [
                    [16.417, -7.146],
                    [16.417, 4.372],
                    [13.335, 7.456],
                    [-16.417, 7.456],
                    [-1.893, -7.146],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.827000038297, 0.827000038297, 0.827000038297, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [159.795, 59.869], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 8',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 8,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -4.409],
                    [0.013, -0.171],
                    [8.023, -2.648],
                    [0, 0],
                    [-27.722, 1.649],
                    [-0.719, 4.229],
                  ],
                  o: [
                    [0, 0.173],
                    [-0.327, 5.43],
                    [0, 0],
                    [-18.903, -3.308],
                    [29.358, -1.748],
                    [5.417, 3.381],
                  ],
                  v: [
                    [27.731, -0.498],
                    [27.714, 0.027],
                    [13.922, 12.834],
                    [3.634, 12.834],
                    [-16.22, 1.918],
                    [18.943, -12.835],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.885999971278, 0.885999971278, 0.885999971278, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [148.481, 52.768], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 9',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 9,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [-1.705, 0],
                    [0, 0],
                    [0, 1.701],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 1.701],
                    [0, 0],
                    [1.705, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-28.647, -7.146],
                    [-28.647, 4.372],
                    [-25.565, 7.456],
                    [25.565, 7.456],
                    [28.647, 4.372],
                    [28.647, -7.146],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.885999971278, 0.885999971278, 0.885999971278, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [147.564, 59.869], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 10',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 10,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -4.409],
                    [-0.013, -0.171],
                    [-8.023, -2.648],
                    [0, 0],
                    [-0.327, 5.43],
                    [0, 0.173],
                    [15.821, 0],
                    [5.15, -3.221],
                  ],
                  o: [
                    [0, 0.173],
                    [0.327, 5.43],
                    [0, 0],
                    [8.023, -2.648],
                    [0.013, -0.171],
                    [0, -8.604],
                    [-7.711, 0],
                    [-5.417, 3.376],
                  ],
                  v: [
                    [-28.647, 2.193],
                    [-28.63, 2.719],
                    [-14.837, 15.525],
                    [14.839, 15.525],
                    [28.63, 2.719],
                    [28.647, 2.193],
                    [-0.001, -15.525],
                    [-19.859, -10.139],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.948999980852, 0.948999980852, 0.948999980852, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [147.564, 50.077], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 11',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 11,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-1.024, 7.708],
                    [0, 0],
                    [0.012, -0.005],
                    [9.202, -1.434],
                    [1.889, 2.679],
                    [0.056, 0.081],
                    [2.317, -8.259],
                    [0, 0],
                    [0.057, 0.508],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [-4.518, -8.444],
                    [0, 0],
                    [0, 0],
                    [-2.084, 0.417],
                    [-6.812, 1.064],
                    [-0.582, -0.951],
                    [3.414, 8.095],
                    [0, 0],
                    [-0.021, -0.5],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [15.422, 11.76],
                    [10.25, -12.458],
                    [10.245, -12.462],
                    [10.229, -12.452],
                    [-1.195, -3.87],
                    [-14.401, -10.451],
                    [-15.422, -12.064],
                    [-13.631, 12.462],
                    [-5.996, 12.277],
                    [-6.122, 10.766],
                    [11.65, 12.415],
                    [11.314, 10.786],
                    [15.146, 11.768],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.658999992819, 0.361000001197, 0.067000003889, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [146.623, 25.556], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 12',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 12,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.722, -2.959],
                    [-0.805, -1.912],
                    [-0.004, -0.004],
                    [2.316, -8.259],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.025, 7.708],
                    [-0.779, 2.227],
                    [3.157, -0.09],
                    [0, 0],
                  ],
                  o: [
                    [1.107, 1.903],
                    [0, 0],
                    [3.415, 8.095],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-4.517, -8.445],
                    [0.301, -2.291],
                    [1.037, -2.979],
                    [0, 0],
                    [-3.423, 0.098],
                  ],
                  v: [
                    [-16.001, -11.374],
                    [-13.13, -5.651],
                    [-13.12, -5.643],
                    [-11.329, 18.884],
                    [-3.695, 18.699],
                    [17.447, 18.19],
                    [17.723, 18.183],
                    [12.553, -6.035],
                    [14.171, -12.809],
                    [9.774, -18.794],
                    [-12.23, -18.156],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.859000052658, 0.493999974868, 0.097999999102, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [144.321, 19.134], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 13',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 13,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [28.872, 0],
                    [-2.786, 14.886],
                    [-13.637, 0],
                    [-3.079, -16.403],
                  ],
                  o: [
                    [-28.872, 0],
                    [4.353, -23.249],
                    [13.637, 0],
                    [2.803, 14.885],
                  ],
                  v: [
                    [0.224, 24.865],
                    [-26.31, 3.77],
                    [-1.964, -24.865],
                    [26.258, 5.338],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.969000004787, 0.57599995931, 0.102000000898, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [147.168, 34.335], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 14',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 14,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-14.292, 9.813],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-15.547, 3.839],
                    [15.547, -4.906],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.948999980852, 0.948999980852, 0.948999980852, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 6, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [30.739, 25.02], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 15',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 15,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-18.99, 3.139],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-14.227, -5.854],
                    [14.227, 2.715],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.948999980852, 0.948999980852, 0.948999980852, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 6, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [31.086, 26.364], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 16',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 16,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.839, 0],
                    [0, 1.554],
                    [0.839, 0],
                    [0, -1.552],
                  ],
                  o: [
                    [0.839, 0],
                    [0, -1.552],
                    [-0.839, 0],
                    [0, 1.554],
                  ],
                  v: [
                    [0, 2.812],
                    [1.52, -0.001],
                    [0, -2.812],
                    [-1.52, -0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.658999992819, 0.361000001197, 0.067000003889, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [45.317, 29.079], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 17',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 17,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.377, 1.543],
                    [0.894, 0.218],
                    [0.377, -1.543],
                    [-0.894, -0.218],
                  ],
                  o: [
                    [0.377, -1.544],
                    [-0.894, -0.219],
                    [-0.378, 1.543],
                    [0.894, 0.219],
                  ],
                  v: [
                    [1.619, 0.396],
                    [0.683, -2.794],
                    [-1.618, -0.396],
                    [-0.683, 2.794],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.658999992819, 0.361000001197, 0.067000003889, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [15.235, 28.686], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 18',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 18,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.92, 0],
                    [0, 1.589],
                    [0.92, 0],
                    [0, -1.589],
                  ],
                  o: [
                    [0.92, 0],
                    [0, -1.589],
                    [-0.92, 0],
                    [0, 1.589],
                  ],
                  v: [
                    [0, 2.876],
                    [1.667, 0.001],
                    [0, -2.876],
                    [-1.667, 0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.658999992819, 0.361000001197, 0.067000003889, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [16.859, 20.509], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 19',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 19,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.921, 0],
                    [0, 1.589],
                    [0.92, 0],
                    [0, -1.588],
                  ],
                  o: [
                    [0.92, 0],
                    [0, -1.588],
                    [-0.921, 0],
                    [0, 1.589],
                  ],
                  v: [
                    [0, 2.876],
                    [1.667, -0.001],
                    [0, -2.876],
                    [-1.667, -0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.658999992819, 0.361000001197, 0.067000003889, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [46.286, 20.118], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 20',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 20,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-8.093, 0],
                    [0, 2.186],
                    [8.093, 0],
                    [0, -2.186],
                  ],
                  o: [
                    [8.093, 0],
                    [0, -2.186],
                    [-8.093, 0],
                    [0, 2.186],
                  ],
                  v: [
                    [0, 3.957],
                    [14.653, 0],
                    [0, -3.957],
                    [-14.653, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [29.122, 40.623], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 21',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 21,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [1.705, 0],
                    [0, 0],
                    [-2.239, 4.096],
                  ],
                  o: [
                    [0, 0],
                    [0, 1.701],
                    [0, 0],
                    [7.936, -4.608],
                    [0, 0],
                  ],
                  v: [
                    [16.417, -7.146],
                    [16.417, 4.372],
                    [13.333, 7.456],
                    [-16.417, 7.456],
                    [-1.893, -7.146],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.827000038297, 0.827000038297, 0.827000038297, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [41.186, 59.869], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 22',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 22,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -4.409],
                    [0.013, -0.171],
                    [8.022, -2.648],
                    [0, 0],
                    [-27.722, 1.649],
                    [-0.719, 4.229],
                  ],
                  o: [
                    [0, 0.173],
                    [-0.327, 5.43],
                    [0, 0],
                    [-18.903, -3.308],
                    [29.359, -1.748],
                    [5.417, 3.381],
                  ],
                  v: [
                    [27.731, -0.498],
                    [27.714, 0.027],
                    [13.921, 12.834],
                    [3.634, 12.834],
                    [-16.221, 1.918],
                    [18.942, -12.835],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.885999971278, 0.885999971278, 0.885999971278, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [29.872, 52.768], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 23',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 23,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [1.705, 0],
                    [0, 0],
                    [0, 1.701],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 1.701],
                    [0, 0],
                    [-1.705, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [28.648, -7.146],
                    [28.648, 4.372],
                    [25.565, 7.456],
                    [-25.565, 7.456],
                    [-28.648, 4.372],
                    [-28.648, -7.146],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.885999971278, 0.885999971278, 0.885999971278, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [28.954, 59.869], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 24',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 24,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -4.409],
                    [0.013, -0.171],
                    [8.022, -2.648],
                    [0, 0],
                    [0.327, 5.43],
                    [0, 0.173],
                    [-15.82, 0],
                    [-5.149, -3.221],
                  ],
                  o: [
                    [0, 0.173],
                    [-0.327, 5.43],
                    [0, 0],
                    [-8.023, -2.648],
                    [-0.013, -0.171],
                    [0, -8.604],
                    [7.712, 0],
                    [5.417, 3.376],
                  ],
                  v: [
                    [28.648, 2.193],
                    [28.631, 2.719],
                    [14.839, 15.525],
                    [-14.838, 15.525],
                    [-28.631, 2.719],
                    [-28.648, 2.193],
                    [0, -15.525],
                    [19.859, -10.139],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.948999980852, 0.948999980852, 0.948999980852, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [28.954, 50.077], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 25',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 25,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-3.415, 8.095],
                    [0.582, -0.951],
                    [6.808, 1.059],
                    [2.084, 0.417],
                    [0, 0],
                    [0, 0],
                    [4.517, -8.444],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.026, -0.5],
                    [0, 0],
                  ],
                  o: [
                    [-0.056, 0.081],
                    [-1.894, 2.679],
                    [-9.202, -1.434],
                    [-0.009, -0.008],
                    [0, 0],
                    [1.025, 7.708],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.056, 0.508],
                    [0, 0],
                    [-2.317, -8.258],
                  ],
                  v: [
                    [15.422, -12.065],
                    [14.401, -10.451],
                    [1.195, -3.871],
                    [-10.229, -12.453],
                    [-10.246, -12.461],
                    [-10.25, -12.458],
                    [-15.422, 11.76],
                    [-15.146, 11.768],
                    [-11.314, 10.787],
                    [-11.65, 12.414],
                    [6.121, 10.765],
                    [5.996, 12.277],
                    [13.631, 12.461],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.658999992819, 0.361000001197, 0.067000003889, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [29.895, 25.557], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 26',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 26,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.723, -2.959],
                    [0.805, -1.912],
                    [0.004, -0.004],
                    [-2.317, -8.259],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.025, 7.708],
                    [0.779, 2.227],
                    [-3.157, -0.09],
                    [0, 0],
                  ],
                  o: [
                    [-1.106, 1.903],
                    [0, 0],
                    [-3.415, 8.095],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [4.517, -8.445],
                    [-0.301, -2.291],
                    [-1.037, -2.979],
                    [0, 0],
                    [3.423, 0.098],
                  ],
                  v: [
                    [16.001, -11.374],
                    [13.128, -5.651],
                    [13.12, -5.643],
                    [11.329, 18.884],
                    [3.695, 18.699],
                    [-17.449, 18.19],
                    [-17.723, 18.183],
                    [-12.553, -6.035],
                    [-14.172, -12.809],
                    [-9.775, -18.794],
                    [12.229, -18.156],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.859000052658, 0.493999974868, 0.097999999102, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [32.197, 19.134], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 27',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 27,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-28.872, 0],
                    [2.786, 14.886],
                    [13.637, 0],
                    [3.079, -16.403],
                  ],
                  o: [
                    [28.872, 0],
                    [-4.353, -23.249],
                    [-13.638, 0],
                    [-2.799, 14.885],
                  ],
                  v: [
                    [-0.224, 24.865],
                    [26.31, 3.77],
                    [1.964, -24.865],
                    [-26.258, 5.338],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.969000004787, 0.57599995931, 0.102000000898, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [29.346, 34.335], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 28',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 28,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [12.651, 0],
                    [2.135, 12.035],
                    [0, -1.524],
                    [-14.176, 0],
                    [0, 14.176],
                    [0.263, 1.464],
                  ],
                  o: [
                    [-12.65, 0],
                    [-0.263, 1.464],
                    [0, 14.176],
                    [14.175, 0],
                    [0, -1.524],
                    [-2.144, 12.035],
                  ],
                  v: [
                    [-0.004, 6.119],
                    [-25.276, -15.084],
                    [-25.686, -10.602],
                    [0.001, 15.084],
                    [25.686, -10.602],
                    [25.277, -15.084],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.859000052658, 0.493999974868, 0.097999999102, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [146.655, 51.703], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 29',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 29,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-14.188, 0],
                    [0, 14.184],
                    [14.185, 0],
                    [0, -14.183],
                  ],
                  o: [
                    [14.185, 0],
                    [0, -14.183],
                    [-14.184, 0],
                    [0, 14.184],
                  ],
                  v: [
                    [0, 25.686],
                    [25.686, -0.001],
                    [0, -25.686],
                    [-25.686, -0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.969000004787, 0.57599995931, 0.102000000898, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [146.651, 41.097], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 30',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 30,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 132,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 0,
      nm: 'sticker_jongler_nftm',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [397.5, 206.5, 0], ix: 2, l: 2 },
        a: { a: 0, k: [256, 256, 0], ix: 1, l: 2 },
        s: { a: 0, k: [69, 69, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 512,
      h: 512,
      ip: 0,
      op: 132,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'bot Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [376.004, 279.12, 0], ix: 2, l: 2 },
        a: { a: 0, k: [149.961, 200.842, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0.112, -2.015],
                    [0.249, -2.007],
                    [0.393, -1.982],
                    [0.562, -1.942],
                    [0.722, -1.911],
                    [0.867, -1.87],
                    [0.971, -1.838],
                    [1.027, -1.838],
                    [1.011, -1.87],
                    [0.94, -1.927],
                    [0.834, -1.992],
                    [0.682, -2.07],
                    [0.53, -2.119],
                    [0.377, -2.167],
                    [0.241, -2.184],
                    [0.112, -2.191],
                    [0.008, -2.199],
                    [-0.08, -2.183],
                    [-0.16, -2.183],
                  ],
                  o: [
                    [0, 2.2],
                    [-0.121, 2.199],
                    [-0.273, 2.184],
                    [-0.433, 2.151],
                    [-0.602, 2.095],
                    [-0.771, 2.022],
                    [-0.899, 1.942],
                    [-0.988, 1.87],
                    [-1.02, 1.83],
                    [-0.987, 1.823],
                    [-0.907, 1.847],
                    [-0.779, 1.885],
                    [-0.634, 1.927],
                    [-0.489, 1.958],
                    [-0.345, 1.99],
                    [-0.217, 2.005],
                    [-0.105, 2.022],
                    [-0.008, 2.031],
                    [0.073, 2.031],
                    [0, 0],
                  ],
                  v: [
                    [24.789, -57.645],
                    [4.013, -51.905],
                    [23.978, -43.766],
                    [2.633, -40.813],
                    [21.151, -29.793],
                    [-0.393, -30.315],
                    [16.126, -16.486],
                    [-5.154, -20.009],
                    [9.93, -4.622],
                    [-11.198, -9.029],
                    [4.214, 6.029],
                    [-17.178, 3.293],
                    [-0.073, 16.389],
                    [-21.618, 16.736],
                    [-2.754, 27.169],
                    [-24.043, 30.542],
                    [-3.997, 38.495],
                    [-24.78, 44.224],
                    [-4.063, 50.212],
                    [-24.299, 57.645],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.219999994016, 0.219999994016, 0.219999994016, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 5.177, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [131.987, 312.945], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 11',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-0.128, -2.104],
                    [-0.274, -2.087],
                    [-0.426, -2.063],
                    [-0.594, -2.031],
                    [-0.754, -1.999],
                    [-0.89, -1.958],
                    [-0.987, -1.926],
                    [-1.028, -1.935],
                    [-1.02, -1.95],
                    [-0.955, -1.999],
                    [-0.851, -2.07],
                    [-0.714, -2.135],
                    [-0.563, -2.191],
                    [-0.41, -2.231],
                    [-0.266, -2.255],
                    [-0.135, -2.271],
                    [-0.023, -2.271],
                    [0.072, -2.263],
                    [0.16, -2.263],
                  ],
                  o: [
                    [0, 2.279],
                    [0.136, 2.271],
                    [0.289, 2.256],
                    [0.466, 2.215],
                    [0.633, 2.159],
                    [0.795, 2.086],
                    [0.915, 2.016],
                    [0.996, 1.951],
                    [1.028, 1.918],
                    [0.995, 1.918],
                    [0.924, 1.935],
                    [0.811, 1.975],
                    [0.674, 2.007],
                    [0.521, 2.047],
                    [0.385, 2.071],
                    [0.248, 2.087],
                    [0.129, 2.103],
                    [0.025, 2.11],
                    [-0.073, 2.111],
                    [0, 0],
                  ],
                  v: [
                    [-4.342, -59.182],
                    [-25.575, -51.974],
                    [-3.62, -47.464],
                    [-23.665, -37.423],
                    [-1.285, -36.259],
                    [-19.506, -23.216],
                    [2.808, -25.409],
                    [-13.631, -10.182],
                    [8.429, -14.299],
                    [-7.465, 1.504],
                    [14.666, -2.204],
                    [-2.264, 12.476],
                    [20.117, 11.152],
                    [1.42, 23.512],
                    [23.761, 25.31],
                    [3.541, 34.998],
                    [25.469, 39.621],
                    [4.27, 46.941],
                    [25.632, 53.755],
                    [3.869, 59.181],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.219999994016, 0.219999994016, 0.219999994016, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 5.177, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [213.738, 309.891], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 14',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 132,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'bags_2 Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [691.31, 413.742, 0], ix: 2, l: 2 },
        a: { a: 0, k: [101.818, 78.308, 0], ix: 1, l: 2 },
        s: { a: 0, k: [93, 93, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.763, 5.068],
                    [7.327, 2.485],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.553, 0.354],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.207, 0.29],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.085, -0.056],
                    [0.071, -1.207],
                    [0, 0],
                    [-0.227, -0.092],
                    [0.212, 0.05],
                    [0, 0],
                    [1.638, 0.403],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.356, -0.346],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.504, -0.367],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-3.134, 8.809],
                    [5.202, 2.668],
                  ],
                  o: [
                    [1.052, -6.925],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.525, -0.374],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.263, -0.282],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [2.351, 0.578],
                    [0, 0],
                    [0.162, 0.042],
                    [-0.192, -0.05],
                    [0, 0],
                    [-0.29, 0.706],
                    [0.057, 0.085],
                    [0, 0],
                    [0, 0],
                    [1.405, 0.346],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.581, 0.424],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [9.889, 1.841],
                    [2.52, -7.087],
                    [3.783, -0.854],
                  ],
                  v: [
                    [24.689, -6.449],
                    [13.062, -19.579],
                    [15.441, -28.96],
                    [9.646, -30.386],
                    [7.331, -21.252],
                    [2.686, -22.332],
                    [5.015, -31.521],
                    [-0.78, -32.948],
                    [-3.159, -23.574],
                    [-6.865, -24.428],
                    [-6.858, -24.456],
                    [-14.856, -26.426],
                    [-16.401, -20.327],
                    [-12.187, -19.296],
                    [-9.484, -15.978],
                    [-12.187, -5.291],
                    [-11.587, -5.1],
                    [-12.202, -5.249],
                    [-15.992, 9.723],
                    [-18.647, 11.078],
                    [-22.861, 10.042],
                    [-25.741, 16.577],
                    [-18.195, 18.427],
                    [-14.058, 19.479],
                    [-16.457, 28.966],
                    [-10.663, 30.392],
                    [-8.284, 21.003],
                    [-3.66, 22.184],
                    [-6.033, 31.522],
                    [-0.237, 32.947],
                    [2.164, 23.482],
                    [22.62, 15.773],
                    [17.291, 1.923],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.877, -7.397],
                    [3.932, 0.96],
                    [0, 0],
                  ],
                  o: [
                    [-1.793, 7.087],
                    [0, 0],
                    [3.932, 0.974],
                  ],
                  v: [
                    [11.439, 11.841],
                    [-6.407, 14.135],
                    [-3.222, 1.563],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 2,
              ty: 'sh',
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.708, -6.728],
                    [3.275, 0.796],
                    [0, 0],
                  ],
                  o: [
                    [-1.638, 6.452],
                    [0, 0],
                    [3.275, 0.805],
                  ],
                  v: [
                    [13.232, -6.554],
                    [-1.769, -4.182],
                    [1.119, -15.583],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 3',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [69.8, 100.716], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 5,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.389, 0.728],
                    [1.602, 5.329],
                    [-1.08, -0.12],
                    [-0.063, -0.275],
                    [3.388, -5.669],
                    [0.96, 0.53],
                  ],
                  o: [
                    [2.541, -4.786],
                    [-0.311, -1.038],
                    [0.918, 0.107],
                    [2.302, 6.205],
                    [-0.452, 0.634],
                    [-0.713, -0.389],
                  ],
                  v: [
                    [-3.575, 7.38],
                    [-1.69, -7.86],
                    [-0.088, -9.802],
                    [1.662, -9.286],
                    [-0.137, 9.188],
                    [-2.982, 9.392],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [76.823, 44.4], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [8.894, 6.988],
                    [-0.634, -0.635],
                    [-3.459, -10.214],
                    [1.236, 0.035],
                  ],
                  o: [
                    [-3.543, -10.447],
                    [1.27, -0.636],
                    [8.076, 6.833],
                    [0.332, 1.193],
                    [0, 0],
                  ],
                  v: [
                    [7.151, 14.71],
                    [-11.004, -12.255],
                    [-7.194, -14.161],
                    [10.673, 12.43],
                    [8.852, 14.76],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [86.11, 47.749], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.087, 0],
                    [0, 0],
                    [0, 1.08],
                    [-1.08, 0],
                    [0, 0],
                    [0, -1.08],
                  ],
                  o: [
                    [0, 0],
                    [-1.087, 0],
                    [0, -1.087],
                    [0, 0],
                    [1.087, 0],
                    [0, 1.087],
                  ],
                  v: [
                    [14.256, 1.962],
                    [-14.255, 1.962],
                    [-16.219, 0.001],
                    [-14.255, -1.962],
                    [14.256, -1.962],
                    [16.219, 0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [71.621, 37.661], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.031, -0.12],
                    [0, 0],
                    [0.114, 1.031],
                    [0, 0],
                    [-1.03, 0.114],
                    [0, 0],
                    [-0.114, -1.031],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.031, 0.12],
                    [0, 0],
                    [-0.12, -1.032],
                    [0, 0],
                    [1.03, -0.12],
                    [0, 0],
                    [0.119, 1.023],
                  ],
                  v: [
                    [13.232, 0.445],
                    [-12.794, 3.417],
                    [-14.87, 1.765],
                    [-14.883, 1.632],
                    [-13.232, -0.446],
                    [12.795, -3.417],
                    [14.87, -1.765],
                    [14.884, -1.631],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [72.362, 34.041], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [0.907, -9.953],
                    [-7.84, 9.953],
                    [7.84, -9.953],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.819999964097, 0.46699999641, 0.081999999402, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [61.505, 48.448], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [2.647, 0.374],
                    [2.386, 0.607],
                    [0.283, -0.423],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-2.648, -0.374],
                    [-0.479, -0.119],
                    [-0.211, 0.325],
                    [0, 0],
                  ],
                  v: [
                    [11.097, 14.601],
                    [3.663, -13.833],
                    [0.819, -13.833],
                    [-4.215, -14.814],
                    [-10.886, -14.814],
                    [8.548, 15.237],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.819999964097, 0.46699999641, 0.081999999402, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [56.06, 20.682], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 7',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [45.121, -10.377],
                    [24.552, 71.309],
                    [0, 0],
                    [0, 0],
                    [3.177, -1.009],
                    [5.366, 5.873],
                    [0, 0],
                    [0, 0],
                    [-6.099, -34.971],
                  ],
                  o: [
                    [38.126, -0.155],
                    [-13.341, -38.761],
                    [0, 0],
                    [1.397, -3.021],
                    [0, 0],
                    [0, 0],
                    [-2.407, -2.964],
                    [0, 0],
                    [3.593, 20.541],
                  ],
                  v: [
                    [-38.009, 74.642],
                    [13.457, 9.827],
                    [-26.738, -38.308],
                    [-8.743, -63.036],
                    [-13.389, -68.288],
                    [-29.194, -70.815],
                    [-29.646, -71.373],
                    [-33.147, -37.836],
                    [-8.384, 23.345],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.819999964097, 0.46699999641, 0.081999999402, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [108.447, 75.229], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 8',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 8,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-37.49, 0.269],
                    [24.552, 71.309],
                    [0, 0],
                    [0, 0],
                    [3.176, -1.009],
                    [1.686, -0.282],
                    [2.146, 2.647],
                    [0, 0],
                    [3.177, -2.117],
                    [0, 0],
                    [3.875, 1.306],
                    [0, 0],
                    [-0.628, -1.023],
                    [0, 0],
                    [12.593, -33.192],
                  ],
                  o: [
                    [38.126, -0.268],
                    [-13.341, -38.761],
                    [0, 0],
                    [1.397, -3.021],
                    [0, 0],
                    [-3.361, 0.558],
                    [0, 0],
                    [-2.407, -2.964],
                    [0, 0],
                    [-3.402, 2.266],
                    [-2.64, -0.889],
                    [-1.193, -0.142],
                    [0, 0],
                    [0, 0],
                    [-23.352, 61.547],
                  ],
                  v: [
                    [-2.915, 74.963],
                    [48.552, 10.148],
                    [8.357, -37.987],
                    [26.352, -62.715],
                    [21.706, -67.967],
                    [16.145, -67.042],
                    [5.909, -70.494],
                    [5.456, -71.052],
                    [-4.391, -72.541],
                    [-8.471, -69.816],
                    [-19.794, -69.513],
                    [-30.854, -70.818],
                    [-32.253, -68.638],
                    [-14.034, -38.785],
                    [-49.751, 10.141],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.969000004787, 0.57599995931, 0.102000000898, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [73.353, 74.908], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 9',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 9,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.678, 4.503],
                    [6.509, 2.203],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.384, 0.311],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.066, 0.262],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.077, -0.057],
                    [0.064, -1.08],
                    [0, 0],
                    [-0.205, -0.085],
                    [0.191, 0.049],
                    [0, 0],
                    [1.454, 0.353],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.2, -0.304],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.334, -0.332],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-2.781, 7.821],
                    [4.617, 2.365],
                  ],
                  o: [
                    [0.932, -6.148],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.355, -0.331],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.123, -0.254],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [2.089, 0.515],
                    [0, 0],
                    [0.141, 0.036],
                    [-0.169, -0.042],
                    [0, 0],
                    [-0.254, 0.621],
                    [0.049, 0.07],
                    [0, 0],
                    [0, 0],
                    [1.25, 0.311],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.404, 0.374],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [8.781, 1.638],
                    [2.237, -6.297],
                    [3.346, -0.783],
                  ],
                  v: [
                    [21.925, -5.742],
                    [11.598, -17.404],
                    [13.708, -25.733],
                    [8.555, -26.997],
                    [6.5, -18.887],
                    [2.371, -19.839],
                    [4.44, -28.007],
                    [-0.707, -29.27],
                    [-2.816, -20.94],
                    [-6.106, -21.703],
                    [-6.099, -21.731],
                    [-13.2, -23.474],
                    [-14.57, -18.06],
                    [-10.829, -17.142],
                    [-8.429, -14.192],
                    [-10.829, -4.698],
                    [-10.293, -4.528],
                    [-10.836, -4.662],
                    [-14.204, 8.63],
                    [-16.561, 9.837],
                    [-20.303, 8.919],
                    [-22.857, 14.722],
                    [-16.152, 16.366],
                    [-12.48, 17.298],
                    [-14.612, 25.727],
                    [-9.467, 26.99],
                    [-7.356, 18.654],
                    [-3.255, 19.705],
                    [-5.358, 28.007],
                    [-0.206, 29.27],
                    [1.927, 20.862],
                    [20.097, 14.016],
                    [15.367, 1.719],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.66, -6.571],
                    [3.494, 0.855],
                    [0, 0],
                  ],
                  o: [
                    [-1.587, 6.297],
                    [0, 0],
                    [3.487, 0.854],
                  ],
                  v: [
                    [10.157, 10.507],
                    [-5.697, 12.547],
                    [-2.866, 1.387],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 2,
              ty: 'sh',
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.518, -5.972],
                    [2.908, 0.713],
                    [0, 0],
                  ],
                  o: [
                    [-1.454, 5.732],
                    [0, 0],
                    [2.909, 0.713],
                  ],
                  v: [
                    [11.746, -5.834],
                    [-1.574, -3.73],
                    [0.988, -13.853],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 3',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [135.303, 106.236], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 10',
          np: 5,
          cix: 2,
          bm: 0,
          ix: 10,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.346, 0.643],
                    [1.426, 4.729],
                    [-0.953, -0.112],
                    [-0.056, -0.247],
                    [3.007, -5.04],
                    [0.854, 0.473],
                  ],
                  o: [
                    [2.252, -4.25],
                    [-0.276, -0.925],
                    [0.811, 0.092],
                    [2.047, 5.514],
                    [-0.403, 0.566],
                    [-0.628, -0.36],
                  ],
                  v: [
                    [-3.173, 6.547],
                    [-1.499, -6.985],
                    [-0.081, -8.708],
                    [1.472, -8.249],
                    [-0.122, 8.163],
                    [-2.65, 8.347],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [141.531, 56.217], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 11',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 11,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [7.906, 6.205],
                    [-0.565, -0.564],
                    [-3.07, -9.071],
                    [1.1, 0.035],
                  ],
                  o: [
                    [-3.148, -9.275],
                    [1.13, -0.565],
                    [7.172, 6.071],
                    [0.297, 1.058],
                    [0, 0],
                  ],
                  v: [
                    [6.354, 13.066],
                    [-9.777, -10.885],
                    [-6.388, -12.579],
                    [9.48, 11.041],
                    [7.865, 13.109],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [149.78, 59.178], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 12',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 12,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.96, 0],
                    [0, 0],
                    [0, 0.96],
                    [-0.96, 0],
                    [0, 0],
                    [0, -0.96],
                  ],
                  o: [
                    [0, 0],
                    [-0.96, 0],
                    [0, -0.96],
                    [0, 0],
                    [0.96, 0],
                    [0, 0.96],
                  ],
                  v: [
                    [12.661, 1.743],
                    [-12.66, 1.743],
                    [-14.404, -0.001],
                    [-12.66, -1.743],
                    [12.661, -1.743],
                    [14.404, -0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [136.916, 50.22], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 13',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 13,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.917, -0.106],
                    [0, 0],
                    [0.099, 0.917],
                    [0, 0],
                    [-0.918, 0.098],
                    [0, 0],
                    [-0.099, -0.918],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.918, 0.106],
                    [0, 0],
                    [-0.106, -0.918],
                    [0, 0],
                    [0.918, -0.105],
                    [0, 0],
                    [0.106, 0.911],
                  ],
                  v: [
                    [11.754, 0.391],
                    [-11.365, 3.032],
                    [-13.208, 1.564],
                    [-13.221, 1.451],
                    [-11.753, -0.391],
                    [11.365, -3.033],
                    [13.206, -1.563],
                    [13.221, -1.451],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [137.568, 47.005], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 14',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 14,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [0.812, -8.844],
                    [-6.96, 8.844],
                    [6.96, -8.844],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.819999964097, 0.46699999641, 0.081999999402, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [127.926, 59.799], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 15',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 15,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [2.351, 0.332],
                    [2.117, 0.543],
                    [0.253, -0.374],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-2.351, -0.332],
                    [-0.424, -0.106],
                    [-0.191, 0.289],
                    [0, 0],
                  ],
                  v: [
                    [9.858, 12.967],
                    [3.257, -12.283],
                    [0.73, -12.283],
                    [-3.738, -13.158],
                    [-9.667, -13.158],
                    [7.591, 13.532],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.819999964097, 0.46699999641, 0.081999999402, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [123.094, 35.142], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 16',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 16,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [40.067, -9.22],
                    [21.806, 63.333],
                    [0, 0],
                    [0, 0],
                    [2.816, -0.903],
                    [4.764, 5.216],
                    [0, 0],
                    [0, 0],
                    [-5.421, -31.059],
                  ],
                  o: [
                    [33.862, -0.142],
                    [-11.852, -34.427],
                    [0, 0],
                    [1.242, -2.683],
                    [0, 0],
                    [0, 0],
                    [-2.138, -2.634],
                    [0, 0],
                    [3.177, 18.227],
                  ],
                  v: [
                    [-33.76, 66.314],
                    [11.954, 8.748],
                    [-23.743, -34.001],
                    [-7.762, -55.961],
                    [-11.885, -60.621],
                    [-25.918, -62.865],
                    [-26.32, -63.359],
                    [-29.433, -33.571],
                    [-7.437, 20.769],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.819999964097, 0.46699999641, 0.081999999402, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [169.627, 83.572], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 17',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 17,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-33.297, 0.232],
                    [21.805, 63.333],
                    [0, 0],
                    [0, 0],
                    [2.816, -0.903],
                    [1.496, -0.247],
                    [1.905, 2.351],
                    [0, 0],
                    [2.824, -1.877],
                    [0, 0],
                    [3.444, 1.165],
                    [0, 0],
                    [-0.558, -0.911],
                    [0, 0],
                    [11.188, -29.479],
                  ],
                  o: [
                    [33.862, -0.24],
                    [-11.852, -34.427],
                    [0, 0],
                    [1.243, -2.683],
                    [0, 0],
                    [-2.987, 0.494],
                    [0, 0],
                    [-2.138, -2.633],
                    [0, 0],
                    [-3.02, 2.012],
                    [-2.344, -0.79],
                    [-1.059, -0.127],
                    [0, 0],
                    [0, 0],
                    [-20.74, 54.65],
                  ],
                  v: [
                    [-2.587, 66.599],
                    [43.127, 9.033],
                    [7.43, -33.716],
                    [23.41, -55.676],
                    [19.289, -60.336],
                    [14.348, -59.517],
                    [5.256, -62.581],
                    [4.853, -63.075],
                    [-3.893, -64.402],
                    [-7.522, -61.987],
                    [-17.58, -61.719],
                    [-27.406, -62.877],
                    [-28.649, -60.935],
                    [-12.47, -34.422],
                    [-44.192, 9.033],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.969000004787, 0.57599995931, 0.102000000898, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [138.455, 83.286], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 18',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 18,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [20.739, 0],
                    [3.502, 19.73],
                    [0, -2.499],
                    [-23.238, 0],
                    [0, 23.238],
                    [0.431, 2.401],
                  ],
                  o: [
                    [-20.739, 0],
                    [-0.429, 2.401],
                    [0, 23.238],
                    [23.238, 0],
                    [0, -2.499],
                    [-3.515, 19.73],
                  ],
                  v: [
                    [-0.007, 10.031],
                    [-41.436, -24.728],
                    [-42.107, -17.379],
                    [0.001, 24.727],
                    [42.107, -17.379],
                    [41.436, -24.728],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.859000052658, 0.493999974868, 0.097999999102, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [89.722, 123.626], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 19',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 19,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-23.253, 0],
                    [0, 23.252],
                    [23.252, 0],
                    [0, -23.253],
                  ],
                  o: [
                    [23.252, 0],
                    [0, -23.253],
                    [-23.253, 0],
                    [0, 23.252],
                  ],
                  v: [
                    [0, 42.107],
                    [42.107, 0.001],
                    [0, -42.107],
                    [-42.107, 0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.969000004787, 0.57599995931, 0.102000000898, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [89.715, 106.24], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 20',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 20,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 132,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'bags_1 Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [103.521, 411.208, 0], ix: 2, l: 2 },
        a: { a: 0, k: [103.198, 80.595, 0], ix: 1, l: 2 },
        s: { a: 0, k: [93, 93, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.783, 5.217],
                    [7.539, 2.555],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.602, 0.361],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.235, 0.304],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.092, -0.057],
                    [0.07, -1.249],
                    [0, 0],
                    [-0.24, -0.1],
                    [0.219, 0.055],
                    [0, 0],
                    [1.695, 0.41],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.398, -0.353],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.546, -0.374],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-3.226, 9.064],
                    [5.357, 2.746],
                  ],
                  o: [
                    [1.08, -7.13],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.568, -0.389],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.298, -0.29],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [2.414, 0.592],
                    [0, 0],
                    [0.169, 0.043],
                    [-0.198, -0.05],
                    [0, 0],
                    [-0.296, 0.72],
                    [0.057, 0.085],
                    [0, 0],
                    [0, 0],
                    [1.447, 0.36],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.631, 0.437],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [10.179, 1.898],
                    [2.598, -7.299],
                    [3.896, -0.868],
                  ],
                  v: [
                    [25.419, -6.632],
                    [13.455, -20.15],
                    [15.898, -29.807],
                    [9.925, -31.275],
                    [7.554, -21.872],
                    [2.767, -22.981],
                    [5.168, -32.446],
                    [-0.798, -33.915],
                    [-3.247, -24.265],
                    [-7.059, -25.148],
                    [-7.052, -25.176],
                    [-15.282, -27.201],
                    [-16.871, -20.926],
                    [-12.536, -19.867],
                    [-9.755, -16.451],
                    [-12.543, -5.453],
                    [-11.922, -5.255],
                    [-12.551, -5.41],
                    [-16.454, 9.999],
                    [-19.193, 11.396],
                    [-23.534, 10.331],
                    [-26.499, 17.058],
                    [-18.735, 18.964],
                    [-14.477, 20.044],
                    [-16.948, 29.813],
                    [-10.983, 31.282],
                    [-8.534, 21.618],
                    [-3.776, 22.832],
                    [-6.211, 32.446],
                    [-0.24, 33.915],
                    [2.231, 24.167],
                    [23.288, 16.232],
                    [17.803, 1.98],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.927, -7.616],
                    [4.053, 0.989],
                    [0, 0],
                  ],
                  o: [
                    [-1.842, 7.3],
                    [0, 0],
                    [4.052, 0.988],
                  ],
                  v: [
                    [11.782, 12.201],
                    [-6.593, 14.566],
                    [-3.318, 1.627],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 2,
              ty: 'sh',
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.751, -6.925],
                    [3.374, 0.825],
                    [0, 0],
                  ],
                  o: [
                    [-1.68, 6.642],
                    [0, 0],
                    [3.374, 0.82],
                  ],
                  v: [
                    [13.631, -6.737],
                    [-1.813, -4.295],
                    [1.158, -16.028],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 3',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [127.227, 103.646], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 5,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.396, 0.748],
                    [1.645, 5.485],
                    [-1.108, -0.127],
                    [-0.064, -0.283],
                    [3.486, -5.838],
                    [0.988, 0.544],
                  ],
                  o: [
                    [2.612, -4.927],
                    [-0.325, -1.066],
                    [0.946, 0.107],
                    [2.372, 6.388],
                    [-0.466, 0.65],
                    [-0.727, -0.402],
                  ],
                  v: [
                    [-3.681, 7.596],
                    [-1.74, -8.09],
                    [-0.095, -10.088],
                    [1.705, -9.558],
                    [-0.144, 9.46],
                    [-3.074, 9.671],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [134.458, 45.681], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [9.155, 7.201],
                    [-0.657, -0.656],
                    [-3.565, -10.517],
                    [1.27, 0.036],
                  ],
                  o: [
                    [-3.649, -10.751],
                    [1.306, -0.656],
                    [8.315, 7.032],
                    [0.346, 1.229],
                    [0, 0],
                  ],
                  v: [
                    [7.358, 15.148],
                    [-11.333, -12.615],
                    [-7.409, -14.577],
                    [10.987, 12.798],
                    [9.116, 15.197],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [144.024, 49.126], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.115, 0],
                    [0, 0],
                    [0, 1.116],
                    [-1.115, 0],
                    [0, 0],
                    [0, -1.115],
                  ],
                  o: [
                    [0, 0],
                    [-1.115, 0],
                    [0, -1.115],
                    [0, 0],
                    [1.115, 0],
                    [0, 1.116],
                  ],
                  v: [
                    [14.676, 2.018],
                    [-14.676, 2.018],
                    [-16.695, -0.001],
                    [-14.676, -2.018],
                    [14.676, -2.018],
                    [16.695, -0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [129.104, 38.749], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.059, -0.12],
                    [0, 0],
                    [0.121, 1.059],
                    [0, 0],
                    [-1.059, 0.12],
                    [0, 0],
                    [-0.12, -1.059],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.059, 0.12],
                    [0, 0],
                    [-0.12, -1.059],
                    [0, 0],
                    [1.059, -0.119],
                    [0, 0],
                    [0.12, 1.059],
                  ],
                  v: [
                    [13.623, 0.458],
                    [-13.172, 3.516],
                    [-15.312, 1.814],
                    [-15.325, 1.68],
                    [-13.624, -0.46],
                    [13.172, -3.517],
                    [15.311, -1.814],
                    [15.325, -1.68],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [129.866, 35.015], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [0.932, -10.249],
                    [-8.068, 10.249],
                    [8.068, -10.249],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.819999964097, 0.46699999641, 0.081999999402, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [118.693, 49.846], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [2.732, 0.388],
                    [2.456, 0.621],
                    [0.289, -0.438],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-2.725, -0.388],
                    [-0.495, -0.127],
                    [-0.219, 0.332],
                    [0, 0],
                  ],
                  v: [
                    [11.421, 15.029],
                    [3.776, -14.238],
                    [0.847, -14.238],
                    [-4.334, -15.247],
                    [-11.202, -15.247],
                    [8.802, 15.685],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.819999964097, 0.46699999641, 0.081999999402, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [113.087, 21.264], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 7',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [46.441, -10.68],
                    [25.271, 73.407],
                    [0, 0],
                    [0, 0],
                    [3.268, -1.044],
                    [5.527, 6.042],
                    [0, 0],
                    [0, 0],
                    [-6.275, -36.001],
                  ],
                  o: [
                    [39.248, -0.162],
                    [-13.737, -39.903],
                    [0, 0],
                    [1.44, -3.113],
                    [0, 0],
                    [0, 0],
                    [-2.478, -3.05],
                    [0, 0],
                    [3.691, 21.135],
                  ],
                  v: [
                    [-39.127, 76.609],
                    [13.857, 9.887],
                    [-27.515, -39.659],
                    [-8.993, -65.114],
                    [-13.771, -70.514],
                    [-30.042, -73.119],
                    [-30.508, -73.69],
                    [-34.117, -39.164],
                    [-8.625, 23.815],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.819999964097, 0.46699999641, 0.081999999402, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [167.019, 77.646], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 8',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 8,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-38.591, 0.275],
                    [25.271, 73.407],
                    [0, 0],
                    [0, 0],
                    [3.269, -1.045],
                    [1.744, -0.289],
                    [2.217, 2.725],
                    [0, 0],
                    [3.268, -2.181],
                    [0, 0],
                    [3.989, 1.348],
                    [0, 0],
                    [-0.649, -1.058],
                    [0, 0],
                    [12.967, -34.165],
                  ],
                  o: [
                    [39.248, -0.282],
                    [-13.736, -39.904],
                    [0, 0],
                    [1.44, -3.113],
                    [0, 0],
                    [-3.459, 0.579],
                    [0, 0],
                    [-2.477, -3.05],
                    [0, 0],
                    [-3.502, 2.335],
                    [-2.717, -0.918],
                    [-1.228, -0.147],
                    [0, 0],
                    [0, 0],
                    [-24.043, 63.326],
                  ],
                  v: [
                    [-3, 76.937],
                    [49.984, 10.215],
                    [8.612, -39.332],
                    [27.134, -64.786],
                    [22.356, -70.186],
                    [16.624, -69.233],
                    [6.085, -72.784],
                    [5.619, -73.355],
                    [-4.518, -74.887],
                    [-8.718, -72.084],
                    [-20.373, -71.774],
                    [-31.758, -73.116],
                    [-33.199, -70.864],
                    [-14.442, -40.128],
                    [-51.212, 10.237],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.969000004787, 0.57599995931, 0.102000000898, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [130.89, 77.318], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 9',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 9,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.741, 4.927],
                    [7.115, 2.415],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.51, 0.338],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.164, 0.283],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.092, -0.057],
                    [0.071, -1.179],
                    [0, 0],
                    [-0.226, -0.085],
                    [0.204, 0.049],
                    [0, 0],
                    [1.595, 0.389],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.313, -0.332],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.461, -0.361],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-3.042, 8.563],
                    [5.062, 2.59],
                  ],
                  o: [
                    [1.023, -6.734],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.483, -0.367],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.228, -0.275],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [2.288, 0.564],
                    [0, 0],
                    [0.155, 0.042],
                    [-0.191, -0.049],
                    [0, 0],
                    [-0.282, 0.684],
                    [0.056, 0.078],
                    [0, 0],
                    [0, 0],
                    [1.363, 0.339],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.538, 0.409],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [9.614, 1.793],
                    [2.45, -6.896],
                    [3.685, -0.841],
                  ],
                  v: [
                    [24.005, -6.279],
                    [12.704, -19.049],
                    [15.01, -28.169],
                    [9.37, -29.552],
                    [7.126, -20.673],
                    [2.609, -21.717],
                    [4.874, -30.654],
                    [-0.758, -32.037],
                    [-3.068, -22.917],
                    [-6.667, -23.75],
                    [-6.661, -23.778],
                    [-14.439, -25.691],
                    [-15.943, -19.761],
                    [-11.849, -18.759],
                    [-9.223, -15.534],
                    [-11.855, -5.142],
                    [-11.27, -4.959],
                    [-11.863, -5.107],
                    [-15.548, 9.449],
                    [-18.131, 10.768],
                    [-22.232, 9.759],
                    [-25.028, 16.112],
                    [-17.694, 17.912],
                    [-13.676, 18.936],
                    [-16.014, 28.161],
                    [-10.38, 29.545],
                    [-8.072, 20.418],
                    [-3.576, 21.569],
                    [-5.877, 30.654],
                    [-0.237, 32.037],
                    [2.1, 22.832],
                    [21.984, 15.335],
                    [16.803, 1.874],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.821, -7.192],
                    [3.819, 0.938],
                    [0, 0],
                  ],
                  o: [
                    [-1.743, 6.898],
                    [0, 0],
                    [3.819, 0.939],
                  ],
                  v: [
                    [11.122, 11.509],
                    [-6.23, 13.741],
                    [-3.131, 1.521],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 2,
              ty: 'sh',
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.66, -6.544],
                    [3.183, 0.783],
                    [0, 0],
                  ],
                  o: [
                    [-1.588, 6.268],
                    [0, 0],
                    [3.177, 0.783],
                  ],
                  v: [
                    [12.865, -6.378],
                    [-1.719, -4.077],
                    [1.09, -15.159],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 3',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [67.885, 106.47], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 10',
          np: 5,
          cix: 2,
          bm: 0,
          ix: 10,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.374, 0.706],
                    [1.553, 5.181],
                    [-1.052, -0.12],
                    [-0.063, -0.268],
                    [3.296, -5.513],
                    [0.939, 0.516],
                  ],
                  o: [
                    [2.471, -4.651],
                    [-0.303, -1.01],
                    [0.89, 0.098],
                    [2.238, 6.036],
                    [-0.438, 0.614],
                    [-0.699, -0.389],
                  ],
                  v: [
                    [-3.48, 7.171],
                    [-1.645, -7.645],
                    [-0.085, -9.53],
                    [1.616, -9.029],
                    [-0.134, 8.936],
                    [-2.902, 9.134],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [74.716, 51.71], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 11',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 11,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [8.647, 6.798],
                    [-0.614, -0.614],
                    [-3.36, -9.931],
                    [1.207, 0.035],
                  ],
                  o: [
                    [-3.445, -10.157],
                    [1.235, -0.621],
                    [7.857, 6.643],
                    [0.325, 1.159],
                    [0, 0],
                  ],
                  v: [
                    [6.953, 14.308],
                    [-10.701, -11.916],
                    [-6.996, -13.772],
                    [10.376, 12.084],
                    [8.604, 14.351],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [83.736, 54.957], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 12',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 12,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.052, 0],
                    [0, 0],
                    [0, 1.052],
                    [-1.052, 0],
                    [0, 0],
                    [0, -1.052],
                  ],
                  o: [
                    [0, 0],
                    [-1.052, 0],
                    [0, -1.052],
                    [0, 0],
                    [1.052, 0],
                    [0, 1.052],
                  ],
                  v: [
                    [13.86, 1.906],
                    [-13.86, 1.906],
                    [-15.766, 0],
                    [-13.86, -1.906],
                    [13.86, -1.906],
                    [15.766, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [69.657, 45.152], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 13',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 13,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.002, -0.113],
                    [0, 0],
                    [0.113, 1.003],
                    [0, 0],
                    [-1.002, 0.113],
                    [0, 0],
                    [-0.113, -1.002],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.002, 0.113],
                    [0, 0],
                    [-0.113, -1.002],
                    [0, 0],
                    [1.003, -0.113],
                    [0, 0],
                    [0.106, 1.002],
                  ],
                  v: [
                    [12.869, 0.434],
                    [-12.438, 3.321],
                    [-14.456, 1.711],
                    [-14.471, 1.584],
                    [-12.861, -0.434],
                    [12.445, -3.321],
                    [14.464, -1.712],
                    [14.478, -1.585],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [70.373, 41.626], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 14',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 14,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [0.883, -9.682],
                    [-7.624, 9.682],
                    [7.624, -9.682],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.819999964097, 0.46699999641, 0.081999999402, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [59.812, 55.638], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 15',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 15,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [2.577, 0.367],
                    [2.315, 0.586],
                    [0.276, -0.409],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-2.576, -0.368],
                    [-0.466, -0.12],
                    [-0.211, 0.311],
                    [0, 0],
                  ],
                  v: [
                    [10.793, 14.199],
                    [3.564, -13.45],
                    [0.797, -13.45],
                    [-4.094, -14.404],
                    [-10.582, -14.404],
                    [8.315, 14.813],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.819999964097, 0.46699999641, 0.081999999402, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [54.52, 28.637], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 16',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 16,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [43.872, -10.094],
                    [23.867, 69.333],
                    [0, 0],
                    [0, 0],
                    [3.084, -0.988],
                    [5.217, 5.703],
                    [0, 0],
                    [0, 0],
                    [-5.93, -34.01],
                  ],
                  o: [
                    [37.074, -0.155],
                    [-12.974, -37.688],
                    [0, 0],
                    [1.355, -2.944],
                    [0, 0],
                    [0, 0],
                    [-2.344, -2.88],
                    [0, 0],
                    [3.48, 19.949],
                  ],
                  v: [
                    [-36.957, 72.359],
                    [13.09, 9.337],
                    [-25.987, -37.464],
                    [-8.488, -61.507],
                    [-12.999, -66.611],
                    [-28.367, -69.067],
                    [-28.804, -69.604],
                    [-32.214, -36.992],
                    [-8.135, 22.502],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.819999964097, 0.46699999641, 0.081999999402, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [105.46, 81.903], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 17',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 17,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-36.452, 0.262],
                    [23.866, 69.333],
                    [0, 0],
                    [0, 0],
                    [3.084, -0.988],
                    [1.645, -0.276],
                    [2.089, 2.57],
                    [0, 0],
                    [3.085, -2.062],
                    [0, 0],
                    [3.762, 1.278],
                    [0, 0],
                    [-0.608, -1.002],
                    [0, 0],
                    [12.247, -32.274],
                  ],
                  o: [
                    [37.074, -0.261],
                    [-12.975, -37.688],
                    [0, 0],
                    [1.356, -2.944],
                    [0, 0],
                    [-3.268, 0.544],
                    [0, 0],
                    [-2.343, -2.88],
                    [0, 0],
                    [-3.31, 2.202],
                    [-2.563, -0.869],
                    [-1.165, -0.134],
                    [0, 0],
                    [0, 0],
                    [-22.73, 59.831],
                  ],
                  v: [
                    [-2.831, 72.673],
                    [47.217, 9.651],
                    [8.139, -37.15],
                    [25.638, -61.193],
                    [21.128, -66.297],
                    [15.712, -65.4],
                    [5.76, -68.753],
                    [5.322, -69.29],
                    [-4.25, -70.736],
                    [-8.217, -68.09],
                    [-19.222, -67.8],
                    [-29.979, -69.071],
                    [-31.342, -66.946],
                    [-13.624, -37.92],
                    [-48.354, 9.658],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.969000004787, 0.57599995931, 0.102000000898, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [71.333, 81.589], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 18',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 18,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [20.739, 0],
                    [3.509, 19.73],
                    [0, -2.499],
                    [-23.238, 0],
                    [0, 23.238],
                    [0.431, 2.401],
                  ],
                  o: [
                    [-20.739, 0],
                    [-0.43, 2.401],
                    [0, 23.238],
                    [23.238, 0],
                    [0, -2.499],
                    [-3.458, 19.73],
                  ],
                  v: [
                    [0.001, 10.031],
                    [-41.437, -24.728],
                    [-42.107, -17.379],
                    [0.001, 24.727],
                    [42.106, -17.379],
                    [41.436, -24.728],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.859000052658, 0.493999974868, 0.097999999102, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [102.216, 128.447], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 19',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 19,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-23.252, 0],
                    [0, 23.252],
                    [23.252, 0],
                    [0, -23.253],
                  ],
                  o: [
                    [23.252, 0],
                    [0, -23.253],
                    [-23.252, 0],
                    [0, 23.252],
                  ],
                  v: [
                    [0.001, 42.107],
                    [42.106, 0.001],
                    [0.001, -42.107],
                    [-42.107, 0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.969000004787, 0.57599995931, 0.102000000898, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [102.216, 111.061], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 20',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 20,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 132,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 0,
      nm: 'server_NFTM',
      refId: 'comp_7',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [591, 337, 0], ix: 2, l: 2 },
        a: { a: 0, k: [110, 305.906, 0], ix: 1, l: 2 },
        s: { a: 0, k: [65, 65, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 800,
      h: 585,
      ip: -18,
      op: 65,
      st: -67,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 0,
      nm: 'server_NFTM',
      refId: 'comp_7',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [591, 337, 0], ix: 2, l: 2 },
        a: { a: 0, k: [110, 305.906, 0], ix: 1, l: 2 },
        s: { a: 0, k: [65, 65, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 800,
      h: 585,
      ip: 65,
      op: 144,
      st: 65,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 0,
      nm: 'server_NFTM',
      refId: 'comp_7',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [199.5, 337, 0], ix: 2, l: 2 },
        a: { a: 0, k: [110, 305.906, 0], ix: 1, l: 2 },
        s: { a: 0, k: [65, 65, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 800,
      h: 585,
      ip: 0,
      op: 132,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      nm: 'Layer 6 Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [399.5, 350, 0], ix: 2, l: 2 },
        a: { a: 0, k: [339.948, 339.948, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 0,
              s: [100, 100, 100],
              e: [102, 102, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 65,
              s: [102, 102, 100],
              e: [100, 100, 100],
            },
            { t: 132 },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-183.083, 0],
                    [0, -183.083],
                    [183.082, 0],
                    [0, 183.082],
                  ],
                  o: [
                    [183.082, 0],
                    [0, 183.082],
                    [-183.083, 0],
                    [0, -183.083],
                  ],
                  v: [
                    [0, -331.5],
                    [331.5, 0],
                    [0, 331.5],
                    [-331.5, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.969000004787, 0.57599995931, 0.102000000898, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 16.895, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [339.948, 339.948], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 5, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-160.181, 0],
                    [0, -160.181],
                    [160.182, 0],
                    [0, 160.182],
                  ],
                  o: [
                    [160.182, 0],
                    [0, 160.182],
                    [-160.181, 0],
                    [0, -160.181],
                  ],
                  v: [
                    [0, -290.034],
                    [290.034, 0],
                    [0, 290.034],
                    [-290.034, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.258999992819, 0.522000002394, 0.957000014361, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 16.895, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [339.948, 339.948], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 10, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-138.409, 0],
                    [0, -138.409],
                    [138.409, 0],
                    [0, 138.409],
                  ],
                  o: [
                    [138.409, 0],
                    [0, 138.409],
                    [-138.409, 0],
                    [0, -138.409],
                  ],
                  v: [
                    [0, -250.612],
                    [250.612, 0],
                    [0, 250.612],
                    [-250.612, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.969000004787, 0.57599995931, 0.102000000898, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 16.895, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [339.948, 339.948], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 20, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-116.637, 0],
                    [0, -116.637],
                    [116.637, 0],
                    [0, 116.637],
                  ],
                  o: [
                    [116.637, 0],
                    [0, 116.637],
                    [-116.637, 0],
                    [0, -116.637],
                  ],
                  v: [
                    [0, -211.19],
                    [211.19, 0],
                    [0, 211.19],
                    [-211.19, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.258999992819, 0.522000002394, 0.957000014361, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 16.895, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [339.948, 339.948], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 40, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-94.865, 0],
                    [0, -94.865],
                    [94.865, 0],
                    [0, 94.865],
                  ],
                  o: [
                    [94.865, 0],
                    [0, 94.865],
                    [-94.865, 0],
                    [0, -94.865],
                  ],
                  v: [
                    [0, -171.768],
                    [171.768, 0],
                    [0, 171.768],
                    [-171.768, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.969000004787, 0.57599995931, 0.102000000898, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 16.895, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [339.948, 339.947], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 80, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-73.093, 0],
                    [0, -73.093],
                    [73.093, 0],
                    [0, 73.092],
                  ],
                  o: [
                    [73.093, 0],
                    [0, 73.092],
                    [-73.093, 0],
                    [0, -73.093],
                  ],
                  v: [
                    [0, -132.345],
                    [132.346, 0],
                    [0, 132.346],
                    [-132.346, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.258999992819, 0.522000002394, 0.957000014361, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 16.895, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [339.828, 339.829], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 132,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
