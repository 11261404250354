import styles from './Toast.module.scss';
import { useEffect, useRef, useState } from 'react';
const FINISH = 69;
const STEP = 0.5;
const TIME = 10000 / (FINISH / STEP);
export default function ToastCircle() {
  const ref = useRef<SVGCircleElement>(null);
  const [angle, setAngle] = useState(0);
  useEffect(() => {
    const timer = setInterval(() => {
      setAngle((prev) => {
        if (prev >= FINISH / STEP) {
          clearInterval(timer);
          return prev;
        }
        return STEP + prev;
      });
    }, TIME);
  }, []);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      viewBox="0 0 24 24"
      preserveAspectRatio="none"
      className={styles.svg}
    >
      <circle
        ref={ref}
        cx="36"
        cy="12"
        r="11"
        fill="none"
        stroke="#BEC1C8"
        strokeWidth="2"
        strokeDasharray={`${angle},20000`}
        transform="rotate(-90,24,24)"
      />
    </svg>
  );
}
