export const poolTexts = [
  'CTPool.Body_10',
  'CTPool.Body_9',
  'CTPool.Body_8',
  'CTPool.Body_7',
  'CTPool.Body_6',
  'CTPool.Body_5',
  'CTPool.Body_4',
  'CTPool.Body_3',
  'CTPool.Body_2',
  'CTPool.Body_1',
];

export const poolTitles = [
  'CTPool.Title_15',
  'CTPool.Title_14',
  'CTPool.Title_13',
  'CTPool.Title_12',
  'CTPool.Title_11',
  'CTPool.Title_10',
  'CTPool.Title_9',
  'CTPool.Title_8',
  'CTPool.Title_7',
  'CTPool.Title_6',
  'CTPool.Title_5',
  'CTPool.Title_4',
  'CTPool.Title_3',
  'CTPool.Title_2',
  'CTPool.Title_1',
];
