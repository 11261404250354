import { AppDispatch } from '../..';
import { USER_ERROR, USER_REQUEST, USER_SUCCESS } from '../constant';
import { rpc } from '../../backend/Rpc';
import { BROWSER_BANNED } from '@utils/links';

export default function checkUser(withoutRedirect?: boolean, navigate?: () => void) {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch({ type: USER_REQUEST });
      const storage_tokens = localStorage.getItem('tokens');
      const tokens = JSON.parse(storage_tokens!);
      await rpc.transmit('auth', { access_token: tokens.access_token });
      const resp = await rpc.transmit('users.me', {});

      // HSH min
      const currencies = await rpc.transmit('currencies', {});
      const hshMinWithdrawal = currencies.find((currency) => currency.symbol === 'HSH')?.min_withdrawal;

      dispatch({
        type: USER_SUCCESS,
        id: resp.id,
        email: resp.email,
        tags: resp.tags,
        minWithdraw: resp.min_withdrawal_amount,
        hshMinWithdrawal,
        ncwMinWithdraw: resp.ncw_min_withdrawal_amount,
        verified: resp.email_verified,
        is_banned: resp.is_banned,
      });
    } catch (e: any) {
      dispatch({ type: USER_ERROR });
      const reason = e.json;
      if (!!reason) {
        if (!withoutRedirect) {
          if (navigate) {
            navigate();
            return;
          }
          if (reason.code === 'account_banned') {
            window.location.href = BROWSER_BANNED;
          } else {
            window.location.href = '/login/?next=/app/';
          }
        }
      }
    }
  };
}
