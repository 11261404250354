import React from 'react';
import styles from './BrowserBannedView.module.scss';
import { Button, ButtonVariant } from '@shared/index';
import { SUPPORT } from '@utils/links';
import { useTranslation } from 'react-i18next';

export const BrowserBannedView = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.banned}>
      <div className={styles.userBanned}>
        <h1 className={styles.userBanned__title}>{t('Banned.AllertTitle')}</h1>
        <div className={styles.userBanned__body}>
          <p className={styles.userBanned__body__description}>{t('Banned.Allert')}</p>
        </div>
        <div className={styles.userBanned__footer}>
          <Button
            type={'link'}
            to={`${SUPPORT}/account-was-deleted`}
            variant={ButtonVariant.Primary}
            className={styles.userBanned__footer__btn}
          >
            {t('Support')}
          </Button>
        </div>
      </div>
    </div>
  );
};
