import React, { FC, useEffect, useMemo } from 'react';
import styles from './CalcResult.module.scss';
import s from './CalcResultItem.module.scss';
import { ReactComponent as PersonSvg } from '@assets/colorless/person.svg';
import { ReactComponent as QuestionMarkOrangeSvg } from '@assets/colorless/question-mark-orange.svg';
import { ReactComponent as CoinSvg } from '@assets/coin.svg';
import { CalcResultItem } from './CalcResultItem';
import { useSelector } from 'react-redux';
import { rootRateType } from '@service/reducers/rateReducer';
import { useTranslation } from 'react-i18next';
import CountUp from 'react-countup';
import cls from 'classnames';

export type CalcResultProps = {
  friends: number;
  friendsOfFriends: number;
  onModalCall: () => void;
};

export const INCOME_PER_USER = 4;
const referralsLevels = [15, 10, 5, 3, 2, 1, 0.5, 0.25, 0.125, 0.0625];

export const CalcResult: FC<CalcResultProps> = (props) => {
  const { friends, friendsOfFriends, onModalCall } = props;
  const rate = useSelector((store: { rate: rootRateType }) => store.rate);
  const { t } = useTranslation();

  const computedFriends = useMemo(() => {
    return (
      referralsLevels.reduce((prev, current, idx) => prev + friends * Math.pow(friendsOfFriends, idx), friends) -
      friends
    );
  }, [friends, friendsOfFriends]);

  const computedIncome = useMemo(() => {
    return referralsLevels.reduce((prev, current, idx) => {
      const roundedFriends = friends * Math.pow(friendsOfFriends, idx);
      const income = Math.floor(((roundedFriends * INCOME_PER_USER) / 100) * current);

      return prev + income;
    }, 0);
  }, [friends, friendsOfFriends]);

  useEffect(
    () =>
      document.getElementById('calcResult')!.scrollIntoView({
        block: 'nearest',
      }),
    [friends, friendsOfFriends]
  );

  return (
    <div className={styles.calcResult_wrapper} id="calcResult">
      <div className={styles.calcResult__result}>
        <div className={styles.calcResult__top}>
          <div className={styles.calcResult__top__cell_wide}>
            <p className={styles.calcResult__top__label}>
              {/*fixme: l10n */}
              <PersonSvg className={styles.calcResult__top__icon} />
              {t('Referrals')}
            </p>
          </div>
          <div className={styles.calcResult__top__cell} />
          <div className={styles.calcResult__top__cell_wide}>
            <p className={styles.calcResult__top__label}>{t('Estimated Income')}</p>
            <p className={styles.calcResult__top__sublabel}>{t('Monthly income in USD (approx.)')}</p>
          </div>
        </div>
        <div className={styles.calcResult__body}>
          <div className={styles.calcResult__body__result}>
            {referralsLevels.map((level, idx) => {
              return (
                <CalcResultItem
                  friends={friends}
                  friendsOfFriends={friendsOfFriends}
                  level={idx}
                  percent={level}
                  rate={rate.btc2usd}
                  key={level}
                />
              );
            })}
          </div>

          <div className={cls([s.calcResultItem, s.calcResultItem__total])}>
            <div className={s.calcResultItem__cell__wide}>
              <p className={s.calcResultItem__value}>
                <CountUp start={0} end={computedFriends} separator={' '} />
              </p>
            </div>
            <div className={s.calcResultItem__cell}>
              <p className={s.calcResultItem__value__small}>
                <b>{t('Total')}</b>
                <span className={s.calcResultItem__value__small_orange}>{t('Estimated Values')}</span>
              </p>
            </div>
            <div className={s.calcResultItem__cell__wide}>
              <p className={s.calcResultItem__value}>
                $<CountUp start={0} end={computedIncome} separator={' '} />
              </p>
            </div>
          </div>

          {/*  <p className={styles.calcResult__total__label}>{t('Total')}</p>*/}
          {/*  <p className={styles.calcResult__total__friends__label}>*/}
          {/*    {t('Your friends and friends of them')}*/}
          {/*  </p>*/}
          {/*  <p className={styles.calcResult__total__friends__value}>*/}
          {/*    <PersonSvg className={styles.calcResult__total__friends__icon} />*/}
          {/*    <CountUp start={0} end={computedFriends} separator={' '} />*/}
          {/*  </p>*/}
          {/*</div>*/}

          {/*<div className={styles.calcResult__body__income}>*/}
          {/*  <span className={styles.calcResult__body__income__label}>*/}
          {/*    {t('Your monthly income could be:')}*/}
          {/*  </span>*/}
          {/*  <div className={styles.calcResult__body__income__value}>*/}
          {/*    $<CountUp start={0} end={computedIncome} separator={' '} />*/}
          {/*  </div>*/}
          {/*  <span className={styles.calcResult__body__income__coin}>*/}
          {/*    <CoinSvg />*/}
          {/*    BTC*/}
          {/*  </span>*/}
          <button className={styles.calcResult__body__modal_btn} onClick={onModalCall}>
            <QuestionMarkOrangeSvg />
            {t('How we calculate estimated income?')}
          </button>
        </div>
      </div>
    </div>
  );
};
