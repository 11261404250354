import React from 'react';
import { FarmAboutView } from './view/FarmAboutView';
import { FarmSubheader } from '../headersComponents/FarmSubheader';
import { HelmetHead } from '../headersComponents/HelmetHead';
import { useTranslation } from 'react-i18next';
import { product } from '@utils/productName';

export const FarmAbout = () => {
  const { t } = useTranslation();
  return (
    <>
      <HelmetHead title={t('About')} product={product.farm} />
      <FarmSubheader />
      <FarmAboutView />
    </>
  );
};
