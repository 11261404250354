export const jsColors = {
  blue: '#4285F4',
  white: '#fff',
  lightGray: '#f7f7f7',
  orange: '#F7931A',
  red: '#F74249',
  lightOrange: '#fffaf3',
  secondOrange: '#FFF5E9',
  black: '#000',
  secondGray: '#9c9c9c',
  darkGray: '#494949',
  gray1: '#212121',
  textGray: '#b3b3b3',
  textGray2: '#8e8e8e',
  borderGray: '#d6d6d6',
  borderDark: '#cecece',
  middleGray: '#757575',
  error: '#f74249',
  hydro: '#1ab9e5',
  purple: '#a033cc',
  green: '#1dc886',
  success: '#37CC33',
  pergament: '#fffdfd',
  gold: '#f7c21b',
} as const;

export const jsAdditionColors = {
  blue: '#f0f6ff',
  success: '#ECFFEC',
  red: '#FFF3F4',
  gold: '#FFFBEB',
  gray: '#ebebeb',
} as const;
