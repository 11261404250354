import { Trans } from 'react-i18next';
import {
  BROWSER_ABOUT,
  BROWSER_DASH,
  BROWSER_LINKS,
  BROWSER_PROMO,
  BROWSER_STATISTIC,
  BROWSER_WITHDRAWAL,
  FARM_ABOUT,
  FARM_ABOUT_PRO,
  FARM_DASH,
  FARM_LINK_INFO,
  FARM_PROMO,
  FARM_STATISTIC,
  MAIN_ABOUT_URL,
  MAIN_LINK_URL,
  MAIN_PROMO_URL,
  MAIN_STATISTIC_URL,
  MAIN_URL,
  NFT_ABOUT,
  NFT_DASH,
  NFT_PROMO,
  POOL_ABOUT,
  POOL_DASH,
  POOL_LINKS,
  POOL_PROMO,
  POOL_STATISTIC,
} from '@utils/links';
import { InfoSvg, LinkSvg, PaintSvg, RectSvg, StatisticSvg, WithdrawSvg } from '@assets/colorless';
import { PRODUCT_KIND } from '@utils/checkPathname';
import { ReactNode } from 'react';

export type FooterConfigItem = {
  url: string;
  text: string;
  Icon: ReactNode;
};
export const config: {
  [K in PRODUCT_KIND]: FooterConfigItem[];
} = {
  browser: [
    {
      url: BROWSER_DASH,
      text: 'Dashboard',
      Icon: <RectSvg />,
    },
    {
      url: BROWSER_STATISTIC,
      text: 'Statistics',
      Icon: <StatisticSvg />,
    },
    {
      url: BROWSER_LINKS,
      text: 'menu.LinksAndCodes',
      Icon: <LinkSvg />,
    },
    {
      url: BROWSER_PROMO,
      text: 'Promo',
      Icon: <PaintSvg />,
    },
    {
      url: BROWSER_WITHDRAWAL,
      text: 'Withdraw',
      Icon: <WithdrawSvg />,
    },
    {
      url: BROWSER_ABOUT,
      text: 'About',
      Icon: <InfoSvg />,
    },
  ],
  farm: [
    {
      url: FARM_DASH,
      text: 'Dashboard',
      Icon: <RectSvg />,
    },
    {
      url: FARM_STATISTIC,
      text: 'Statistics',
      Icon: <StatisticSvg />,
    },
    {
      url: FARM_LINK_INFO,
      text: 'menu.LinksAndCodes',
      Icon: <LinkSvg />,
    },
    {
      url: FARM_PROMO,
      text: 'Promo',
      Icon: <PaintSvg />,
    },
    {
      url: BROWSER_WITHDRAWAL,
      text: 'Withdraw',
      Icon: <WithdrawSvg />,
    },
    {
      url: FARM_ABOUT,
      text: 'About',
      Icon: <InfoSvg />,
    },
  ],
  farm_pro: [
    {
      url: FARM_DASH,
      text: 'Dashboard',
      Icon: <RectSvg />,
    },
    {
      url: FARM_STATISTIC,
      text: 'Statistics',
      Icon: <StatisticSvg />,
    },
    {
      url: FARM_LINK_INFO,
      text: 'menu.LinksAndCodes',
      Icon: <LinkSvg />,
    },
    {
      url: FARM_PROMO,
      text: 'Promo',
      Icon: <PaintSvg />,
    },
    {
      url: BROWSER_WITHDRAWAL,
      text: 'Withdraw',
      Icon: <WithdrawSvg />,
    },
    {
      url: FARM_ABOUT_PRO,
      text: 'About',
      Icon: <InfoSvg />,
    },
  ],
  nft: [
    {
      url: NFT_DASH,
      Icon: <RectSvg />,
      text: 'Dashboard',
    },
    {
      url: NFT_PROMO,
      text: 'Promo',
      Icon: <PaintSvg />,
    },
    {
      url: NFT_ABOUT,
      text: 'About',
      Icon: <InfoSvg />,
    },
  ],
  all: [
    {
      url: MAIN_URL,
      text: 'Dashboard',
      Icon: <RectSvg />,
    },
    {
      url: MAIN_STATISTIC_URL,
      text: 'Statistics',
      Icon: <StatisticSvg />,
    },
    {
      url: MAIN_LINK_URL,
      text: 'menu.LinksAndCodes',
      Icon: <LinkSvg />,
    },
    {
      url: MAIN_PROMO_URL,
      text: 'Promo',
      Icon: <PaintSvg />,
    },
    {
      url: BROWSER_WITHDRAWAL,
      text: 'Withdraw',
      Icon: <WithdrawSvg />,
    },
    {
      url: MAIN_ABOUT_URL,
      text: 'About',
      Icon: <InfoSvg />,
    },
  ],
  pool: [
    {
      url: POOL_DASH,
      text: 'Dashboard',
      Icon: <RectSvg />,
    },
    {
      url: POOL_STATISTIC,
      text: 'Statistics',
      Icon: <StatisticSvg />,
    },
    {
      url: POOL_LINKS,
      text: 'menu.LinksAndCodes',
      Icon: <LinkSvg />,
    },
    {
      url: POOL_PROMO,
      text: 'Promo',
      Icon: <PaintSvg />,
    },
    {
      url: BROWSER_WITHDRAWAL,
      text: 'Withdraw',
      Icon: <WithdrawSvg />,
    },
    {
      url: POOL_ABOUT,
      text: 'About',
      Icon: <InfoSvg />,
    },
  ],
};
