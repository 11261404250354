import { useMemo } from 'react';

export const useBannerJS = (link: string, src: string, srcSet: string, width: number, height: number) =>
  useMemo(
    () => `<div id="ctab_ad"></div>
<script type="text/javascript">
var link = document.createElement('a');
var linkImg = document.createElement('img')
linkImg.src = '${src}'
linkImg.srcset = '${srcSet}'
link.appendChild(linkImg)
link.href = '${link}';
link.style.width = '${width}px';
link.style.height = '${height}px';
document.getElementById('ctab_ad').appendChild(link);
</script>`,
    [link, src, srcSet, width, height]
  );
