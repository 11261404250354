import { useTranslation } from 'react-i18next';
import { HelmetHead } from '../headersComponents/HelmetHead';
import { AllSubheader } from '@pages/headersComponents/AllSubheader';

import { product } from '@utils/productName';
import FarmStatisticsView from './view/FarmStatisticsView';
import { FarmSubheader } from '@pages/headersComponents/FarmSubheader';

export function FarmStatistics() {
  const { t } = useTranslation();

  return (
    <>
      <HelmetHead title={t('Statistics')} product={product.farm} />
      <FarmSubheader />
      <FarmStatisticsView />
    </>
  );
}
