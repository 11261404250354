import React from 'react';
import { FarmAboutProView } from './view/FarmAboutProView';
import { FarmProSubheader } from '@pages/headersComponents/FarmProSubheader';
import { HelmetHead } from '../headersComponents/HelmetHead';
import { useTranslation } from 'react-i18next';
import { product } from '@utils/productName';

export const FarmAboutPro = () => {
  const { t } = useTranslation();
  return (
    <>
      <HelmetHead title={t('About')} product={product.farm_pro} />
      <FarmProSubheader />
      <FarmAboutProView />
    </>
  );
};
