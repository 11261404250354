import styles from './LinksView.module.scss';
import { useTranslation } from 'react-i18next';
import checkE from '@utils/checkE';
import cls from 'classnames';
import { useBreakpoints } from '@hooks/useBreakpoints';
export type LinksTotalInfo = {
  open: number;
  install: number;
  revenue: number;
  active: number;
};

export type LinksTotalProps = {
  info: LinksTotalInfo;
};

export default function LinksTotal({ info }: LinksTotalProps) {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();
  if (!isMobile) {
    return (
      <div className={cls([styles.row, styles.total])}>
        <div className={styles.row__name}>
          <div className={styles.row__name_id}>{t('Total')}:</div>
        </div>
        <div className={styles.row__copy}></div>
        <div className={styles.row__comm}></div>
        <div className={styles.row__num}>{info?.open}</div>
        <div className={styles.row__num}>{info?.install}</div>
        <div className={styles.row__num}>{info?.active}</div>
        <div className={styles.row__btc}>
          <div className={styles.row__btc_balance}>
            <span>{info && checkE(info?.revenue)}</span> BTC
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={cls([styles.total])}>
        <div className={styles.name}>{t('Total')}:</div>

        <div className={styles.line}>
          <div className={styles.heading}>{t('Opens')}</div>
          <div className={styles.row__num}>{info.open || 0}</div>
        </div>
        <div className={styles.line}>
          <div className={styles.heading}>{t('Referrals')}</div>
          <div className={styles.row__num}>{info.install || 0}</div>
        </div>
        <div className={styles.line}>
          <div className={styles.heading}>{t('Active Miners')}</div>
          <div className={styles.row__num}>{info.active || 0}</div>
        </div>
        <div className={styles.line}>
          <div className={styles.heading}>{t('Earnings')}</div>
          <div className={styles.row__btc}>
            <div className={styles.row__btc_balance}>
              <span>{info && checkE(info?.revenue)}</span> BTC
            </div>
          </div>
        </div>
      </div>
    );
  }
}
