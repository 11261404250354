import { t } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Container, Steps } from '@shared/index';
import styles from './Start.module.scss';
import { ReactComponent as Step1Svg } from './icons/step-1.svg';
import { ReactComponent as Step2Svg } from './icons/step-2.svg';
import { ReactComponent as Step3Svg } from './icons/step-3.svg';
import { ReactComponent as Step4Svg } from './icons/step-4.svg';

export const Start = () => {
  const { t } = useTranslation();
  return (
    <Card className={styles.card}>
      <div className={styles.start}>
        <h2 className={styles.start__title}>{t('Start right away!')}</h2>
        <p className={styles.start__description}>
          {t(
            'Invite as many new users as you can. You will receive a percentage from the earnings of all users who joined using your personal link.'
          )}
        </p>
        <StartSteps />
      </div>
    </Card>
  );
};

export const StartSteps = () => {
  return (
    <Steps>
      <Steps.Item
        Icon={Step1Svg}
        title={t('Invite new users via the personal link, encourage them to use CryptoTab Browser')}
        withArrow={false}
      />
      <Steps.Item
        Icon={Step2Svg}
        title={t(
          'All people invited by you, by users you’ve invited and by their referrals—become members of your mining network'
        )}
      />
      <Steps.Item
        Icon={Step3Svg}
        title={t('The members of your mining network actively use the browser for surfing & mining')}
      />
      <Steps.Item
        Icon={Step4Svg}
        title={t('You’ll be getting a commission fee depending on the network’s size and members activity')}
      />
    </Steps>
  );
};
