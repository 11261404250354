import { HelmetHead } from '@pages/headersComponents/HelmetHead';
import { product } from '@utils/productName';
import PoolSubheader from '@pages/headersComponents/PoolSubheader';
import PoolAboutView from '@pages/PoolAbout/view/PoolAboutView';
import { useTranslation } from 'react-i18next';

export default function PoolAbout() {
  const { t } = useTranslation();
  return (
    <>
      <HelmetHead title={t('About')} product={product.pool} />
      <PoolSubheader />
      <PoolAboutView />
    </>
  );
}
