import React, { useEffect } from 'react';
import styles from './ParallaxBackground.module.scss';

import Particle1 from './particles/particles-1.svg';
import Particle2 from './particles/particles-2.svg';
import Particle3 from './particles/particles-3.svg';
import Particle4 from './particles/particles-4.svg';
import Particle5 from './particles/particles-5.svg';
import Particle6 from './particles/particles-6.svg';
import Particle7 from './particles/particles-7.svg';
import Particle8 from './particles/particles-8.svg';

import Parallax from 'parallax-js';

type ParallaxBackgroundProps = {
  className?: string;
};

export const ParallaxBackground = ({ className }: ParallaxBackgroundProps) => {
  useEffect(() => {
    const scenes = document.querySelectorAll('[data-scene]');
    scenes.forEach((el) => new Parallax(el as HTMLElement));
  }, []);
  return (
    <>
      <div className={`${styles.parallaxBackground} ${className}`} data-scene="parallax-scene" data-invert-y="false">
        <div data-depth="0.92">
          <img src={Particle1} alt={'particle-1'} className={styles.parallaxBackground__particle_1} />
        </div>
        <div data-depth="0.85">
          <img src={Particle2} alt={'particle-2'} className={styles.parallaxBackground__particle_2} />
        </div>
      </div>

      <div className={`${styles.parallaxBackground} ${className}`} data-scene="parallax-scene" data-invert-x="false">
        <div data-depth="0.68">
          <img src={Particle3} alt={'particle-3'} className={styles.parallaxBackground__particle_3} />
        </div>
        <div data-depth="0.28">
          <img src={Particle4} alt={'particle-4'} className={styles.parallaxBackground__particle_4} />
        </div>
      </div>

      <div className={`${styles.parallaxBackground} ${className}`} data-scene="parallax-scene">
        <div data-depth="0.11">
          <img src={Particle5} alt={'particle-5'} className={styles.parallaxBackground__particle_5} />
        </div>
        <div data-depth="0.94">
          <img src={Particle6} alt={'particle-6'} className={styles.parallaxBackground__particle_6} />
        </div>
      </div>
      <div className={`${styles.parallaxBackground} ${className}`} data-scene="parallax-scene" data-invert-x="false">
        <div data-depth="0.49">
          <img src={Particle7} alt={'particle-7'} className={styles.parallaxBackground__particle_7} />
        </div>
        <div data-depth="0.34">
          <img src={Particle8} alt={'particle-8'} className={styles.parallaxBackground__particle_8} />
        </div>
      </div>
    </>
  );
};
