import { useTranslation } from 'react-i18next';
import { HelmetHead } from '../headersComponents/HelmetHead';
import SupportSuccessView from './view/SupportSuccessView';
import { BrowserSubheader } from '@pages/headersComponents/BrowserSubheader';
import React from 'react';

export default function SupportSuccess() {
  const { t } = useTranslation();
  return (
    <>
      <HelmetHead title={t('Support')} />
      <BrowserSubheader />
      <SupportSuccessView />
    </>
  );
}
