import React from 'react';
import Subheader from '../../components/Subheader';
import styles from '../../components/Subheader/Subheader.module.scss';
import { config } from '@pages/headersComponents/config';
import { NavLink } from 'react-router-dom';
import { AllSvg } from '@assets/colored';
import { useTranslation } from 'react-i18next';

export const AllSubheader = () => {
  const { t } = useTranslation();
  return (
    <Subheader Icon={<AllSvg />}>
      {config.all.map((b) => (
        <NavLink exact to={{ pathname: b.url }} key={b.url} activeClassName={styles.active}>
          {b.Icon}
          {t(b.text)}
        </NavLink>
      ))}
    </Subheader>
  );
};
