import { RefObject, useEffect } from 'react';
export default function useClickOutside<T>(
  elem: RefObject<T | HTMLDivElement>,
  handler: () => void,
  attached: boolean
) {
  useEffect(() => {
    if (!attached) return;

    const handleClick = (e: Event) => {
      if (
        !elem.current ||
        (!!(e.target instanceof HTMLElement && e.target.className) &&
          typeof e.target.className === 'string' &&
          !!e.target.className.match('lang'))
      )
        return;
      if (elem.current instanceof HTMLElement && !elem.current.contains(e.target as Node)) {
        handler();
      }
    };

    document.addEventListener('mousedown', handleClick);

    return () => document.removeEventListener('mousedown', handleClick);
  }, [elem, handler, attached]);
}
