import { Trans, useTranslation } from 'react-i18next';
import { Button, ButtonVariant } from '@shared/index';
import styles from './WithdrawalModal.module.scss';
import { ReactComponent as Confirm } from '@assets/withdraw-confirm.svg';
import { useSelector } from 'react-redux';
import { rootUser } from '@service/reducers/userReducer';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { BROWSER_BALANCE } from '@utils/links';

export function ModalConfirm({ isBitcoin }: { isBitcoin: boolean }) {
  const history = useHistory();
  const { t } = useTranslation();
  const userMail = useSelector((store: { user: rootUser }) => store.user.email);

  const handleOpenInbox = useCallback(() => {
    history.replace(`${BROWSER_BALANCE}/${isBitcoin ? 'BTC' : 'HSH'}`);
    window.open('https://mail.google.com/', '_blank');
  }, [history]);

  return (
    <>
      <div className={styles.body}>
        <Confirm />
        <div className={styles.title}>{t('Confirm Withdrawal')}</div>
        <div className={styles.text}>
          <Trans i18nKey={'ConfirmWithdrawal.Description1'} values={{ email: userMail }} components={{ b: <b /> }} />
        </div>
      </div>
      <div className={styles.footer}>
        <Button
          type="button"
          size="medium"
          onClick={handleOpenInbox}
          className={styles.btn_only}
          variant={ButtonVariant.Primary}
        >
          {t('ConfirmWithdrawal.Btn1')}
        </Button>
      </div>
    </>
  );
}
