import { useTranslation } from 'react-i18next';
import { mapStatus } from '@pages/BrowserBalance/view/components/mapStatus';
import { WithdrawStatus } from '@utils/transactionStatus';
import { WithdrawalPaymentService } from '../../../../backend/JsonRpcApi';
import { ReactComponent as WithdrawalComplete } from '@assets/colored/withdrawal-complete.svg';

export const WithdrawalNote = ({ status, type }: { status: number; type?: WithdrawalPaymentService }) => {
  const { t } = useTranslation();
  const [, description] = mapStatus(status, t);

  switch (status) {
    case WithdrawStatus.created:
      return (
        <>
          <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="30" cy="30" r="28" stroke="#F7931A" strokeWidth="4" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M47 21.3059C46.9815 18.9312 44.9136 17 42.3636 17H17.6364C15.0864 17 13.0185 18.9226 13 21.3059V21.3507V38.6667C13 41.0587 15.0771 43 17.6364 43H42.3636C44.9229 43 47 41.0587 47 38.6667V21.3507V21.3059ZM17.5502 20H42.4478C43.0609 20 43.5854 20.3375 43.839 20.834L29.999 30.1715L16.159 20.834C16.4111 20.3375 16.937 20 17.5502 20ZM16 38.5C16 39.325 16.7 40 17.5556 40H42.4444C43.3 40 44 39.325 44 38.5V24.3785L30.8944 33.2285C30.6222 33.4055 30.3111 33.5 30 33.5C29.6889 33.5 29.3778 33.407 29.1056 33.2285L16 24.3785V38.5Z"
              fill="#4285F4"
            />
          </svg>
          <span>{t('Confirm_Email')}</span>
        </>
      );
    case WithdrawStatus.confirmed:
    case WithdrawStatus.reconfirmed:
      return (
        <>
          <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="30" cy="30" r="28" stroke="#F7931A" strokeWidth="4" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M45 30C45 21.7608 38.2392 15 30 15C21.7608 15 15 21.7608 15 30C15 38.2392 21.7608 45 30 45C38.2392 45 45 38.2392 45 30ZM37.5361 34.366L36.3386 36.3386L28.5216 31.4784V22.4639H30.7752V30.2809L37.5361 34.366Z"
              fill="#4285F4"
            />
          </svg>
          <span>{t('Wait_confirm')}</span>
        </>
      );
    case WithdrawStatus.approved:
      return (
        <>
          <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="30" cy="30" r="28" stroke="#F7931A" strokeWidth="4" />
            <path
              d="M19.2861 29.9999L28.2147 38.9285L40.7147 19.2856"
              stroke="#F7931A"
              strokeWidth="4"
              strokeLinecap="round"
            />
          </svg>
          <span>{description}</span>
        </>
      );
    case WithdrawStatus.sent:
    case WithdrawStatus.processed:
      return (
        <>
          <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="30" cy="30" r="28" stroke="#F7931A" strokeWidth="4" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M45 30C45 21.7608 38.2392 15 30 15C21.7608 15 15 21.7608 15 30C15 38.2392 21.7608 45 30 45C38.2392 45 45 38.2392 45 30ZM37.5361 34.366L36.3386 36.3386L28.5216 31.4784V22.4639H30.7752V30.2809L37.5361 34.366Z"
              fill="#4285F4"
            />
          </svg>
          <span>{description}</span>
        </>
      );
    case WithdrawStatus.complete: {
      return (
        <>
          <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
            <g clip-path="url(#clip0_7845_234378)">
              <circle cx="30" cy="30" r="28" stroke="#37CC33" stroke-width="4" />
              <circle cx="30" cy="30" r="20" fill="#37CC33" />
              <path
                d="M21.1113 29.5959L28.182 36.6666L38.081 21.1111"
                stroke="white"
                stroke-width="3.33333"
                stroke-linecap="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_7845_234378">
                <rect width="60" height="60" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span>{description}</span>
        </>
      );
    }
    default:
      return (
        <>
          <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="30" cy="29.9998" r="28" stroke="#F74249" strokeWidth="4" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M26.5714 29.9998L19.4142 22.8425C18.6332 22.0615 18.6332 20.7952 19.4142 20.0141L20.0144 19.414C20.7954 18.6329 22.0617 18.6329 22.8428 19.414L30 26.5712L37.1572 19.414C37.9383 18.6329 39.2046 18.6329 39.9856 19.414L40.5858 20.0141C41.3668 20.7952 41.3668 22.0615 40.5858 22.8425L33.4286 29.9998L40.5858 37.157C41.3668 37.938 41.3668 39.2043 40.5858 39.9854L39.9856 40.5855C39.2046 41.3666 37.9383 41.3666 37.1572 40.5855L30 33.4283L22.8428 40.5855C22.0617 41.3666 20.7954 41.3666 20.0144 40.5855L19.4142 39.9854C18.6332 39.2043 18.6332 37.938 19.4142 37.157L26.5714 29.9998Z"
              fill="currentColor"
            />
          </svg>
          {description}
        </>
      );
  }
};
