import styles from './CalcSlider.module.scss';
import React, { FC, useCallback, useState } from 'react';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import './rangeSlider.css';
import { ReactComponent as InfoIcon } from '@assets/info-icon.svg';
import { Tooltip } from '@shared/Tooltip/Toolkit';

export type CalcSliderProps = {
  value: number;
  max?: number;
  label?: string;
  onChange: (val: number) => void;
  tooltip?: string;
};

export const CalcSlider: FC<CalcSliderProps> = (props) => {
  const { value, max, label, onChange } = props;
  const [_value, setValue] = useState(value);

  const handleOnChange = useCallback(
    (val: number) => {
      setValue(val);
      onChange(val);
    },
    [setValue, onChange]
  );

  return (
    <div className={styles.calcSlider}>
      <div className={styles.calcSlider__slider_wrapper}>
        <p className={styles.calcSlider__label}>
          {label}
          {props.tooltip && (
            <Tooltip className={styles.calcSlider__tooltip} text={props.tooltip}>
              <InfoIcon className={styles.calcSlider__icon} width={20} height={20} />
            </Tooltip>
          )}
        </p>
        <div className={styles.calcSlider__value}>{_value}</div>
        <div className={styles.calcSlider__slider}>
          <Slider value={_value} onChange={handleOnChange} max={max} tooltip={false} />
        </div>
      </div>
    </div>
  );
};
