import { useTranslation } from 'react-i18next';
import { BrandCard, BrandContainer, BrandLink, PromoSection } from '@shared/index';
import brIcon from '@assets/browser-icon.svg';
import brIcon500 from '@assets/browser-icon-500.png';
import brIcon2000 from '@assets/browser-icon-2000.png';
import brLogo from '@assets/browser-logo.svg';
import brLogo500 from '@assets/browser-logo-500.png';
import brLogo2000 from '@assets/browser-logo-2000.png';
import ui from '@assets/browser-ui-preview.png';
import ui2x from '@assets/browser-ui-preview-retina.png';

const iconStyle = { width: 80, height: 80 };
const logoStyles = { maxWidth: 231, width: '100%' };

export function BrandBlock() {
  const { t } = useTranslation();
  return (
    <PromoSection
      title={t('Brand resources')}
      text={t('Copy icon, logo, interface screenshots and create your unique promotional content')}
    >
      <BrandContainer>
        <BrandCard
          title={t('Product Icon')}
          src={brIcon}
          imgStyle={iconStyle}
          list={[
            { res: '(.png)', title: t('500px'), href: brIcon500 },
            { res: '(.png)', title: t('2000px'), href: brIcon2000 },
            { res: '(.svg)', title: t('Vector'), href: brIcon },
          ]}
        />
        <BrandCard
          title={t('Product Logo')}
          src={brLogo}
          imgStyle={logoStyles}
          list={[
            { res: '(.png)', title: t('500px'), href: brLogo500 },
            { res: '(.png)', title: t('2000px'), href: brLogo2000 },
            { res: '(.svg)', title: t('Vector'), href: brLogo },
          ]}
        />
        <BrandCard
          title={t('UI Screens')}
          src={ui}
          srcSet={ui2x}
          list={[
            {
              res: '(.zip)',
              title: t('Download UI Screens'),
              href: 'https://cdn.cryptobrowser.store/static/affiliate/files/cryptotab_ui-screen_en.zip',
            },
          ]}
        />
      </BrandContainer>
    </PromoSection>
  );
}
