import { FormEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonVariant } from '@shared/index';
import styles from './LinksCreateCard.module.scss';
import { ReactComponent as New } from '@assets/colored/new.svg';
import { inherits } from 'util';

export type LinksCreateCardProps = {
  onSubmit: (e: FormEvent, comment: string) => void;
  length: number;
};

export function LinksCreateCard({ onSubmit, length }: LinksCreateCardProps) {
  const { t } = useTranslation();
  const [comment, setComment] = useState('');
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    ref.current?.focus();
  }, []);
  return (
    <div className={styles.container}>
      <form className={styles.card} onSubmit={(e: FormEvent) => onSubmit(e, comment)}>
        <div className={styles.header}>{t('Create Link ID')}</div>
        {length < 50 ? (
          <>
            <div className={styles.body}>
              <p className={styles.body__text}>
                {t(
                  'Create additional personal link to track different traffic sources with different metrics to understand conversion rates.'
                )}
              </p>
              <div className={styles.group}>
                <label htmlFor="comment">{t('Comment')}</label>
                <input
                  type="text"
                  placeholder={t('Comment')}
                  id="comment"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </div>
            </div>
            <div className={styles.footer}>
              <Button type="button" variant={ButtonVariant.Primary} Icon={<New />} className={styles.footer__btn}>
                {t('Create')}
              </Button>
            </div>
          </>
        ) : (
          <div className={styles.error}>
            <span style={{ color: 'inherit' }}>{t('You can not create more than 50 links.')}</span>
          </div>
        )}
      </form>
    </div>
  );
}
