import dayjs from 'dayjs';
import { AppDispatch } from '../..';
import { RATE_ERROR, RATE_REQUEST, RATE_SUCCESS } from '../constant';
import { rpc } from '../../backend/Rpc';

type HistoryResp = [number, number];

export default function getRate() {
  const bodyBTC = {
    from_currency: 'BTC',
    to_currency: 'USD',
  };

  const bodyHSC = {
    from_currency: 'HSH',
    to_currency: 'USD',
  };

  return async function (dispatch: AppDispatch) {
    try {
      dispatch({ type: RATE_REQUEST });
      const history = await rpc.transmit('currencies.history', {
        ...bodyBTC,
        interval: 'daily',
        time_start: +dayjs().add(-1, 'months').toDate(),
        time_end: +dayjs().toDate(),
      });

      const rateBTC = await rpc.transmit('currencies.rate', bodyBTC);
      const rateHSH = await rpc.transmit('currencies.rate', bodyHSC);

      const updateHistory = history.map(([date, avg]: HistoryResp) => ({
        date: dayjs(date * 1000).format('YYYY-MM-DD'),
        avg: +avg.toFixed(2),
      }));
      dispatch({
        type: RATE_SUCCESS,
        btc2usd: rateBTC.rate.toFixed(2),
        hsh2usd: rateHSH.rate,
        btc_history: updateHistory,
      });
    } catch (e) {
      dispatch({ type: RATE_ERROR });
      console.log(e);
    }
  };
}
