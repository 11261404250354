import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import useFilteredPoolLinks from '@hooks/useFilteredPoolLinks';
import { Root } from '@service/reducers';
import MinedView from '@shared/MinedView/MinedView';
import { MindedLevels } from '@shared/MinedView/MinedLevels';
import { POOL_STATISTIC } from '@utils/links';

export type BrowserMinedProps = {
  id?: number;
};

export function PoolMined({ id }: BrowserMinedProps) {
  const [active, setActive] = useState(`${!!id ? id : 'All Link IDs'}`);
  const stats_links_get = useSelector((store: Root) => store.poolStatistic.stats_links_get);
  const filteredLinks = useFilteredPoolLinks(active);
  const { period_start, period_end } = useSelector((store: Root) => store.browserGraph);
  const poolGraph = useSelector((store: Root) => store.poolStatistic);
  const data = useMemo(
    () =>
      'All Link IDs' !== active
        ? (poolGraph.levels[active] as MindedLevels) || Array(3).fill({ ref: 0, mined: 0 })
        : (poolGraph.levels.total as MindedLevels),
    [active, poolGraph.levels]
  );
  const total = useMemo(
    () =>
      data?.reduce(
        (acc, item) => ({
          ref: acc.ref + item.ref,
          mined: acc.mined + item.mined,
        }),
        { ref: 0, mined: 0 }
      ),
    [data, id]
  );
  return (
    <MinedView
      id={id}
      info={filteredLinks}
      isLoaded={stats_links_get}
      active={active}
      setActive={setActive}
      start={period_start}
      end={period_end}
      total={total}
      data={data}
      isOpensLink={`${POOL_STATISTIC}/#open`}
      isInstallLink={`${POOL_STATISTIC}/#install`}
      isMinerLink={`${POOL_STATISTIC}/#miner`}
      buttonLink={POOL_STATISTIC}
    />
  );
}
