import { ChangeEvent } from 'react';
import styles from './SupportInput.module.scss';
import { FormStateDate } from '../../FormStateData';
import { SupportError, SupportErrorVariant } from '../../SupportError';

export type SupportInputProps = {
  label: string;
  variant: SupportInputVariant;
  name: keyof FormStateDate;
  value: string;
  error?: SupportError;
  onChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
};

export enum SupportInputVariant {
  Default,
  Email,
  Text,
  AccountId,
  Captcha,
  Date,
  Url,
}

export default function SupportInput(props: SupportInputProps) {
  const { label, variant, name, value, onChange, error } = props;
  const errorType: Record<SupportInputVariant, SupportErrorVariant> = {
    [SupportInputVariant.Default]: SupportErrorVariant.Default,
    [SupportInputVariant.Email]: SupportErrorVariant.Email,
    [SupportInputVariant.Text]: SupportErrorVariant.Text,
    [SupportInputVariant.AccountId]: SupportErrorVariant.AccountId,
    [SupportInputVariant.Captcha]: SupportErrorVariant.Captcha,
    [SupportInputVariant.Date]: SupportErrorVariant.Date,
    [SupportInputVariant.Url]: SupportErrorVariant.Url,
  };

  return (
    <div className={styles.container}>
      <div className={styles.label}>{label}</div>
      {(variant === SupportInputVariant.Email ||
        variant === SupportInputVariant.Date ||
        variant === SupportInputVariant.AccountId ||
        variant === SupportInputVariant.Url ||
        variant === SupportInputVariant.Default) && (
        <input
          type="text"
          className={styles.input}
          name={name}
          placeholder="Start typing…"
          value={value}
          onChange={onChange}
        />
      )}
      {variant === SupportInputVariant.Text && (
        <>
          <textarea
            className={styles.textarea}
            name={name}
            placeholder="Issue description at least 30 symbols"
            minLength={30}
            value={value}
            onChange={onChange}
          />
        </>
      )}
      {error?.variant === errorType[variant] && <div className={styles.error}>{error.error}</div>}
    </div>
  );
}
