import { ChangeEvent, useCallback, useRef, useState } from 'react';
import styles from './SupportDownload.module.scss';
import { CrossSvg } from '@assets/colorless';

type SupportDownloadProps = {
  onSetFile: (files: File | null) => void;
};

export default function SupportDownload(props: SupportDownloadProps) {
  const { onSetFile } = props;

  const [file, setFile] = useState<File | null>(null);
  const input = useRef<HTMLInputElement>(null);
  const onClick = useCallback(() => {
    input.current?.click();
  }, [input]);

  const handleSetFile = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      const file = files ? files[0] : null;
      if (!file) return null;

      setFile(file);
      onSetFile(file);
    },
    [onSetFile]
  );

  const onRemoveFile = () => {
    setFile(null);
    onSetFile(null);
    input.current!.value = '';
  };

  return (
    <div className={styles.container}>
      <div className={styles.label}>Screenshot (optional):</div>
      <div className={styles.row}>
        <button
          onClick={(e) => {
            e.preventDefault();
            onClick();
          }}
          type="button"
          className={styles.button}
        >
          CHOOSE FILE
        </button>
        <input type="file" name="file" ref={input} onChange={handleSetFile} accept=".jpg, .jpeg, .png, .gif" />
        <span className={styles.title}>JPG, PNG or GIF. Max. size: 20MB</span>
      </div>
      {file && (
        <div className={styles.info}>
          <span>{file.size > 20971520 ? 'Error size file' : file.name}</span>
          <CrossSvg onClick={onRemoveFile} />
        </div>
      )}
    </div>
  );
}
