import {
  BROWSER_ABOUT,
  BROWSER_DASH,
  BROWSER_PROMO,
  FARM_ABOUT,
  FARM_DASH,
  FARM_PROMO,
  POOL_ABOUT,
  POOL_DASH,
  POOL_PROMO,
} from '@utils/links';

export const footerLinksConfig = {
  farm: {
    dashboard: FARM_DASH,
    promo: FARM_PROMO,
    about: FARM_ABOUT,
  },
  browser: {
    dashboard: BROWSER_DASH,
    promo: BROWSER_PROMO,
    about: BROWSER_ABOUT,
  },
  pool: {
    dashboard: POOL_DASH,
    promo: POOL_PROMO,
    about: POOL_ABOUT,
  },
};
