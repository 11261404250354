import { BrowserMined, Container } from '@shared/index';
import BrowserGraph from '../../BrowserDashboard/view/components/BrowserGraph';
import MinersGraph from '../../BrowserDashboard/view/components/MinersGraph/MinersGraph';
import { LinkCard } from '../../../shared/LinkCard/LinkCard';
import { EarningsGraph } from '@pages/BrowserDashboard/view/components/EarningsGraph/EarningsGraph';
import { LinkAcc } from '@service/reducers/balanceReducer';

export type LinkInfoViewProps = {
  link: LinkAcc;
  onSave: (link: LinkAcc) => Promise<void>;
  id: number;
  url: string;
  onGeneratePost: () => void;
};

export function LinkInfoView({ id, link, onSave, url, onGeneratePost }: LinkInfoViewProps) {
  return (
    <Container vertical={20}>
      <LinkCard link={link} onSave={onSave} url={url} onGeneratePost={onGeneratePost} />
      <BrowserMined id={id} isShowListId={false} />
      <BrowserGraph id={id} type="open" />
      <BrowserGraph id={id} type="install" />
      <MinersGraph id={id} />
      <EarningsGraph />
    </Container>
  );
}
