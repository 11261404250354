import styles from './MinedView.module.scss';
import { ReactNode } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
export type BrowserMinedCardProps = {
  href?: string;
  label: string;
  Icon: ReactNode;
  number: number | string;
  subtitle?: string;
  className?: string;
  external?: boolean;
};

export function MinedCard({ href, label, number, Icon, subtitle, className, external }: BrowserMinedCardProps) {
  const content = () => {
    return (
      <>
        <div className={styles.card__label}>
          {label} {Icon}
        </div>
        {subtitle && <div className={styles.card__subtitle} dangerouslySetInnerHTML={{ __html: subtitle }} />}
        <div className={styles.card__wrapper}>
          {Icon}
          <div className={styles.card__number} dangerouslySetInnerHTML={{ __html: `${number}` }} />
        </div>
      </>
    );
  };

  if (external) {
    return (
      <Link to={href} className={cn(styles.card, className)}>
        {content()}
      </Link>
    );
  }

  return (
    <a href={href} className={cn(styles.card, className)}>
      {content()}
    </a>
  );
}
