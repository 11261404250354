import { NETWORK_ALERT_ERROR, NETWORK_ALERT_REQUEST, NETWORK_ALERT_SUCCESS } from '../constant';

const initialState = {
  overload: false,
  network_alert_request: false,
  network_alert_error: false,
  network_alert_success: false,
};

type Action = {
  type: string;
  overload: boolean;
};

export type rootNetworkAlert = typeof initialState;

export default function networkAlertReducers(state = initialState, action: Action) {
  switch (action.type) {
    case NETWORK_ALERT_REQUEST: {
      return {
        ...state,
        network_alert_request: true,
        network_alert_error: false,
      };
    }
    case NETWORK_ALERT_ERROR: {
      return {
        ...state,
        network_alert_request: false,
        network_alert_error: true,
      };
    }
    case NETWORK_ALERT_SUCCESS: {
      return {
        ...state,
        overload: action.overload,
        network_alert_request: false,
        network_alert_error: false,
        network_alert_success: true,
      };
    }
    default:
      return state;
  }
}
