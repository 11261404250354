import { Span } from './Tabs';

export const GraphLevelsColors = [
  '#4285F4',
  '#005E87',
  '#A5DC29',
  '#37CC33',
  '#15993A',
  '#F7C21A',
  '#F7931A',
  '#FC4BAC',
  '#8256C1',
  '#F74249',
];
export const mapForEmptyValue: { [key in Span]: string } = {
  [Span.Day]: 'day',
  [Span.Week]: 'week',
  [Span.Month]: 'month',
};
