import { NFT_PROMO_ERROR, NFT_PROMO_REQUEST, NFT_PROMO_SUCCESS } from '../constant';

const initialState = {
  promo: [],
  promo_request: false,
  promo_error: false,
  promo_get: false,
};

type Action = {
  type: string;
  promo: NFTPromoType[];
};

export type rootNFTPromo = Omit<typeof initialState, 'promo'> & {
  promo: NFTPromoType[];
};

export type NFTPromoType = {
  code: string;
  created_at: string;
  discount: string;
  amount: string;
};

export default function nftPromoReducer(state = initialState, action: Action) {
  switch (action.type) {
    case NFT_PROMO_REQUEST: {
      return {
        ...state,
        promo_request: true,
        promo_error: false,
      };
    }
    case NFT_PROMO_ERROR: {
      return {
        ...state,
        promo_request: false,
        promo_error: true,
      };
    }
    case NFT_PROMO_SUCCESS: {
      return {
        ...state,
        promo: action.promo,
        promo_request: false,
        promo_error: false,
        promo_get: true,
      };
    }
    default:
      return state;
  }
}
