import { FC } from 'react';
import { SuspendController } from './SuspendController/SuspendController';
import styles from './BrowserSuspendView.module.scss';
import { Container } from '@shared/Container/Container';

export const BrowserSuspendView: FC<{ suspendConfig: Record<string, any> }> = ({ suspendConfig }) => {
  return (
    <div className={styles.suspend}>
      <div className={styles.suspend__decoration} />
      <Container>
        <SuspendController suspendConfig={suspendConfig} />
      </Container>
    </div>
  );
};
