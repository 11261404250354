import { ButtonHTMLAttributes, ElementType, forwardRef, PropsWithChildren, ReactNode, Ref } from 'react';
import cn from 'classnames';
import styles from './ModalLayout.module.scss';
import { Modal } from '@shared/Modal/Modal';
import { useTranslation } from 'react-i18next';
import { LoadSpinner } from '@shared/Loader';

interface ChildrenProps {
  children?: ReactNode;
}

interface ClassNameProps {
  className?: string;
}

interface HeaderProps extends ClassNameProps {
  hiddenDesktop?: boolean;
  image?: boolean;
}

const ModalLayoutHeader = ({
  children,
  className,
  hiddenDesktop,
  image,
  align = 'center',
}: PropsWithChildren<HeaderProps & ContentProps>) => (
  <div
    className={cn(
      className,
      styles.header,
      image && styles.header_image,
      hiddenDesktop && styles.hiddenDesktop,
      styles[align]
    )}
  >
    {children}
  </div>
);

interface TitleProps extends ClassNameProps {
  size?: 'xsmall' | 'small' | 'min';
}

const ModalLayoutTitle = ({ children, className, size = 'min' }: PropsWithChildren<TitleProps>) => (
  <h3 className={cn(className, styles[size], styles.title)}>{children}</h3>
);

const ModalLayoutDescription = ({ children, className }: PropsWithChildren<ClassNameProps>) => (
  <p className={cn(className, styles.description)}>{children}</p>
);

interface IconProps {
  color?: string;
}

const ModalLayoutIcon = ({ children, className, color }: PropsWithChildren<ClassNameProps & IconProps>) => (
  <p className={cn(className, styles.icon, color && styles[color])}>{children}</p>
);

export interface ContentProps {
  align?: 'left' | 'center';
  padding?: 'small' | 'min';
}

const ModalLayoutContent = ({
  children,
  className,
  align = 'center',
  padding = 'min',
}: PropsWithChildren<ClassNameProps & ContentProps>) => (
  <div className={cn(className, styles[padding], styles.content, styles[align])}>{children}</div>
);

interface FooterProps extends ChildrenProps {
  gray?: boolean;
}

const ModalLayoutFooter = ({ children, gray, align = 'center' }: PropsWithChildren<FooterProps & ContentProps>) => (
  <div className={cn(styles.footer, gray && styles.gray, styles[align])}>{children}</div>
);

interface ModalLayoutProps extends ChildrenProps {
  onClose: () => void;
  isOpen: boolean;
  overlay?: boolean;
  overlayClassName?: string;
  closeIconStyle?: 'default' | 'second';
}

const ModalLayout = ({
  className,
  children,
  onClose,
  isOpen,
}: PropsWithChildren<ModalLayoutProps & ClassNameProps>) => (
  <Modal className={cn(styles.modal, className)} opened={isOpen} onClose={onClose} closeBtnClassName={styles.close}>
    {children}
  </Modal>
);

const ModalLayoutForPromo = forwardRef(
  (props: PropsWithChildren<ModalLayoutProps & ClassNameProps>, ref?: Ref<HTMLInputElement>) => {
    const { className, children, onClose, isOpen } = props;
    const { t } = useTranslation();
    return (
      <Modal
        className={cn(styles.modal, styles.modal_second, className)}
        opened={isOpen}
        onClose={onClose}
        closeBtnClassName={styles.close}
      >
        {children}
      </Modal>
    );
  }
);

export type ButtonSize = 'xs' | 's' | 'm' | 'l' | 'xl';
export type ButtonColor = 'orange' | 'blue' | 'green' | 'black' | 'red' | 'inherit';
export type ButtonVariant = 'filled' | 'outline' | 'text';
export type ButtonJustify = 'center' | 'space-between';

export type ButtonCommonProps = {
  size?: ButtonSize;
  color?: ButtonColor;
  variant?: ButtonVariant;
  justify?: ButtonJustify;
  borderRadius?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
  loaderSize?: 'small' | 'default';
  as?: ElementType;
  icon?: ReactNode;
  iconPosition?: 'right' | 'left';
  wide?: boolean;
  disabled?: boolean | undefined;
  href?: string | undefined;
  target?: string | undefined;
  rel?: string | undefined;
  to?: string;
};

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & PropsWithChildren<ButtonCommonProps>;

const ModalLayoutButton = forwardRef((props: ButtonProps, ref?: Ref<HTMLButtonElement>) => {
  const {
    children,
    color = 'black',
    size = 's',
    variant = 'filled',
    disabled = false,
    className,
    fullWidth,
    wide, // ????
    loading, // ????
    loaderSize, // ???
    as: Component = 'button',
    icon,
    iconPosition = 'left',
    borderRadius,
    to,
    ...rest
  } = props;

  return (
    <Component
      className={cn(
        styles.button,
        styles[variant],
        styles[color],
        styles[size],
        {
          [styles.wide]: wide,
          [styles.fullWidth]: fullWidth,
          [styles.rounded]: borderRadius,
          [styles.disabled]: disabled,
        },
        className
      )}
      disabled={disabled}
      ref={ref}
      to={to}
      {...rest}
    >
      {loading ? (
        <LoadSpinner />
      ) : (
        <>
          {icon && iconPosition === 'left' && icon}
          {children}
          {icon && iconPosition === 'right' && icon}
        </>
      )}
    </Component>
  );
});

export default Object.assign(ModalLayout, {
  ModalLayoutForPromo: ModalLayoutForPromo,
  Title: ModalLayoutTitle,
  Description: ModalLayoutDescription,
  Icon: ModalLayoutIcon,
  Content: ModalLayoutContent,
  Header: ModalLayoutHeader,
  Footer: ModalLayoutFooter,
  Button: ModalLayoutButton,
});
