import { RATE_ERROR, RATE_REQUEST, RATE_SUCCESS } from '../constant';

const initialState = {
  usd_request: false,
  usd_error: false,
  hsh2usd: 0,
  btc2usd: 0,
  btc_history: [] as btcHistory[],
};

type Action = {
  type: string;
  hsh2usd?: number;
  btc2usd?: number;
  btc_history?: btcHistory[];
};

export type rootRateType = typeof initialState;

type btcHistory = {
  date: string;
  avg: number;
};

export default function rateReducer(state = initialState, action: Action) {
  switch (action.type) {
    case RATE_REQUEST: {
      return {
        ...state,
        usd_request: true,
        usd_error: false,
      };
    }
    case RATE_ERROR: {
      return {
        ...state,
        usd_request: true,
        usd_error: false,
      };
    }
    case RATE_SUCCESS: {
      return {
        ...state,
        usd_request: false,
        usd_error: false,
        hsh2usd: action.hsh2usd,
        btc2usd: action.btc2usd,
        btc_history: action.btc_history,
      };
    }
    default:
      return state;
  }
}
