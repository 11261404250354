import { useEffect } from 'react';
import { KeyCodes } from '../constants/keyCodes';

export const useOnKeyClick = (handler: () => void, keyCodes: KeyCodes[] | KeyCodes) => {
  useEffect(() => {
    const listener = (evt: KeyboardEvent) => {
      if (Array.isArray(keyCodes)) {
        if (keyCodes.includes(evt.key as KeyCodes)) {
          handler();
        }
      } else if ((evt.key as KeyCodes) === keyCodes) {
        handler();
      }
    };

    window.addEventListener('keydown', listener);

    return () => {
      window.removeEventListener('keydown', listener);
    };
  }, [handler]);
};
