import { Modal } from '@shared/Modal/Modal';
import { useModal } from '@shared/Modal/useModal';
import React, { createContext, useContext, useState, ReactElement, PropsWithChildren } from 'react';
interface ModalContextValue {
  opened: boolean;
  openModal: () => void;
  closeModal: () => void;
  createModalContent: (content: ReactElement, className?: string) => void;
  clearModalContent: () => void;
  classNameContent: string;
}

const ModalContext = createContext<ModalContextValue | undefined>(undefined);
export const ModalProvider = ({ children }: PropsWithChildren) => {
  const { opened, onOpen, onClose } = useModal();
  const [modalContent, setModalContent] = useState<ReactElement | null>(null);
  const [classNameContent, setClassNameContent] = useState<string>('');

  const closeModal = () => {
    onClose();
    clearModalContent();
  };

  const clearModalContent = () => {
    setModalContent(null);
    setClassNameContent('');
  };

  const createModalContent = (content: ReactElement, classNameContent: string = '') => {
    setModalContent(content);
    setClassNameContent(classNameContent);
    onOpen();
  };

  return (
    <ModalContext.Provider
      value={{
        opened,
        openModal: onOpen,
        closeModal,
        createModalContent,
        clearModalContent,
        classNameContent,
      }}
    >
      {children}
      {modalContent && (
        <Modal opened={opened} onClose={closeModal} classNameBackground={classNameContent}>
          {modalContent}
        </Modal>
      )}
    </ModalContext.Provider>
  );
};

export const useModalContext = (): ModalContextValue => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('add ModalProvider');
  }
  return context;
};
