import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { externalLinks } from '@utils/externalLinks';
import { useModal } from '@shared/Modal/useModal';
import { CheckSvg } from '@assets/colorless';
import ModalHSHLayout from '@shared/ModalHSHLayout';
import { ModalsHSHType } from '../index';
import styles from '@shared/ModalHSHLayout/ModalHSHLayout.module.scss';
import { convertBigValueToLocale } from '@utils/convertBigValueToLocale';

export function ModalIncreasingHSH({ balance }: ModalsHSHType) {
  const { t } = useTranslation();
  const [incBalance, setIncBalance] = useState(0);
  const { opened, onOpen, onClose } = useModal(false);

  const openModal = useCallback(() => {
    if (+balance > 0) {
      const localDate = localStorage.getItem('balanceHSH') || '{}';
      const lastBalance = JSON.parse(localDate);

      if (lastBalance.balance >= 0 && !lastBalance.isShow) {
        return onClose();
      } else if (+lastBalance.balance < +balance) {
        setIncBalance(+balance - +lastBalance.balance);
        onOpen();
      }

      const balanceHSH = { balance, isShow: true };
      localStorage.setItem('balanceHSH', JSON.stringify(balanceHSH));
    }
  }, [balance]);

  useEffect(() => {
    openModal();
  }, [balance]);

  const handleDontShow = useCallback(() => {
    const localDate = localStorage.getItem('balanceHSH') || '{}';
    const lastBalance = JSON.parse(localDate);
    const visit = { ...lastBalance, isShow: !lastBalance.isShow };
    localStorage.setItem('balanceHSH', JSON.stringify(visit));
  }, []);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <ModalHSHLayout
      opened={opened}
      onClose={handleClose}
      title={
        <Trans
          i18nKey="HSH_Upd.Dash.Top_up_HSH"
          components={{ b: <b className={styles.green} /> }}
          values={{ HSH: convertBigValueToLocale(incBalance) }}
        />
      }
      text={
        <Trans
          i18nKey="HSH.Topup.popup.Description"
          components={{
            span: <span className={styles.green} />,
            b: <b />,
            a: <a href={externalLinks.ncw} className={styles.link} target="_blank" rel="noreferrer" />,
          }}
          values={{ HSH: convertBigValueToLocale(incBalance) }}
        />
      }
      button={{
        icon: <CheckSvg />,
        text: t('HSH_Upd.CB_active_popup.Btn'),
        type: 'button',
        onClick: handleClose,
      }}
      unShow={{
        handleDontShow: handleDontShow,
        text: t('Don’t show this window again'),
      }}
      isDecoration={true}
    />
  );
}
