import { useEffect } from 'react';
import { ErrorPageView } from './view/ErrorPageView';
import { HelmetHead } from '../headersComponents/HelmetHead';
import * as Sentry from '@sentry/react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const ErrorPage = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    Sentry.captureException(new Error(`Webservice Currently Unavailable - 505(${pathname})`));
  }, [pathname]);
  const { t } = useTranslation();
  return (
    <>
      <HelmetHead title={t('error505.Title')} />
      <ErrorPageView />
    </>
  );
};
