import { Span } from '@shared/Graph/Tabs';
import dayjs from 'dayjs';
import { Discreteness } from '../backend/JsonRpcApi';

export const MOB_VISIBLE_BARS = 7;
export const VISIBLE_BARS = 19;

export const defaultXformat = (span: Span) => {
  if (span === Span.Month) {
    return (timestamp: number) => dayjs(timestamp).format('MM.YY');
  } else {
    return (timestamp: number) => dayjs(timestamp).format('DD.MM');
  }
};

export const customYformat = (num: number): string => {
  const step = 1e-8; // Шаг
  const threshold = 1e-8; // Минимальный шаг для отображения

  // Всегда отображаем ноль
  if (num === 0) {
    return '0';
  }

  // Не отображать значения ниже заданного порога
  if (num < threshold) {
    return '';
  }
  if (num > 1e-6) {
    return `${num}`;
  }
  // Округляем значение до ближайшего кратного шага
  const roundedNum = Math.round(num / step) * step;

  // Если округленное значение не совпадает с оригинальным, не отображаем его
  return Math.abs(num - roundedNum) < 1e-9 ? `${roundedNum.toFixed(8)}` : '';
};

export const btcToSatoshi = (btc: number) => {
  return btc * 100000000;
};
export const span_to_discreteness = (span: Span): Discreteness => {
  if (span == Span.Month) {
    return 'months' as Discreteness;
  } else if (span == Span.Week) {
    return 'weeks' as Discreteness;
  }
  return 'days' as Discreteness;
};
export const GraphDateConfig = {
  [Span.Day]: {
    format: (period: string | number) => dayjs(period).format('DD.MM'),
    parse: (timestamp: number) => dayjs(timestamp).valueOf(),
  },
  [Span.Week]: {
    format: (period: string | number) => dayjs(period).startOf('week').format('DD.MM'),
    parse: (timestamp: number) => dayjs(timestamp).startOf('week').valueOf(),
  },
  [Span.Month]: {
    format: (period: string | number) => dayjs(period).startOf('month').format('MM.YYYY'),
    parse: (timestamp: number) => dayjs(timestamp).startOf('month').valueOf(),
  },
};
