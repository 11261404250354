import styles from './CommonLinkModal.module.scss';
import classNames from 'classnames';
import { Button, ButtonVariant } from '..';
import { ReactNode, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

const COMMENT_CHARS_LIMIT = 40;

type CommonLinkModalProps = {
  icon?: ReactNode;
  btnIcon?: ReactNode;
  title: string;
  description: string;
  btnText: string;
  onSubmit: (value: string) => Promise<void> | (() => void);
  withComment?: boolean;
};

export const CommonLinkModal = (props: CommonLinkModalProps) => {
  const { icon, title, description, onSubmit, withComment = false, btnIcon, btnText } = props;

  const [value, setValue] = useState('');
  const { t } = useTranslation();

  const countClassnames = classNames(styles.length, {
    [styles.empty]: value.length === 0,
    [styles.positive]:
      value.length !== 0 && COMMENT_CHARS_LIMIT - value.length > 0 && value.length < COMMENT_CHARS_LIMIT,
    [styles.equals]: COMMENT_CHARS_LIMIT - value.length === 0,
    [styles.negative]: COMMENT_CHARS_LIMIT - value.length < 0,
  });

  const handleSubmit = useCallback(() => {
    onSubmit(value);
  }, [onSubmit, value]);

  return (
    <div className={styles.root}>
      <div className={styles.body}>
        {icon && icon}
        <h4 className={styles.title}>{title}</h4>
        <p className={styles.description}>{description}</p>
        {withComment && (
          <div className={styles.comment}>
            <label htmlFor="comment">{t('Comment')}</label>
            <textarea
              className={COMMENT_CHARS_LIMIT - value.length < 0 ? styles.error : undefined}
              placeholder={t('Comment')}
              name="comment"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            <span className={countClassnames}>{COMMENT_CHARS_LIMIT - value.length}</span>
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <Button
          disabled={COMMENT_CHARS_LIMIT - value.length < 0}
          type={'button'}
          variant={ButtonVariant.Primary}
          onClick={handleSubmit}
          Icon={btnIcon}
          className={styles.btn}
        >
          {btnText}
        </Button>
      </div>
    </div>
  );
};
