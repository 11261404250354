import styles from './ShareBtn.module.scss';
import { Button, ButtonVariant } from '@shared/Button/Button';
import { ShareBlock } from '@shared/ShareBlock/ShareBlock';
import { useRef, useState } from 'react';
import { ShareSvg } from '@assets/colorless';
import useClickOutside from '@hooks/useClickOutside';

type ShareBtnProps = {
  shareUrl: string;
};
export default function ShareBtn({ shareUrl }: ShareBtnProps) {
  const [show, setShow] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);
  useClickOutside(ref, () => setShow(false), show);
  return (
    <Button
      type="button"
      size="medium"
      ref={ref}
      className={styles.btn}
      variant={ButtonVariant.Primary}
      onClick={() => setShow(!show)}
      Icon={<ShareSvg color="#fff" />}
    >
      {show && (
        <div className={styles.list} id="commonAboutShare">
          <ShareBlock url={shareUrl} size="l" limit={20} />
        </div>
      )}
    </Button>
  );
}
