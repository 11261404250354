import styles from './SettingsView.module.scss';
import google from '@assets/icon_google.png';
import apple from '@assets/icon_apple.svg';
import fb from '@assets/icon_facebook.svg';
import tw from '@assets/icon_twitter.png';
import vk from '@assets/icon_vk.png';
import { Trans } from 'react-i18next';

export function SettingsLogin() {
  return (
    <div className={styles.login}>
      <div className={styles.login__container}>
        <a href={`/login/facebook/?next=/app/settings/`} className={styles.login__item}>
          <img src={fb} alt="icon" />
          <span>Facebook</span>
        </a>
        <a href={`/login/google-oauth2/?next=/app/settings/`} className={styles.login__item}>
          <img src={google} alt="icon" />
          <span>Google</span>
        </a>
        <a href="/login/apple-id/?next=/app/settings/" className={styles.login__item}>
          <img src={apple} alt="icon" />
          <span>Apple</span>
        </a>
        <a href="/login/twitter/?next=/app/settings/" className={styles.login__item}>
          <img src={tw} alt="icon" />
          <span>Twitter</span>
        </a>
        <a href="/login/vk-oauth2/?next=/app/settings/" className={styles.login__item}>
          <img src={vk} alt="icon" />
          <span>VK</span>
        </a>
      </div>
      <div className={styles.login__subtext}>
        <Trans
          i18nKey={
            'By signing in you agree with our <a href="/terms/">Terms of Use</a> and <a href="/privacy/">Privacy Policy</a>.'
          }
          components={{
            a: <a href="https://cryptobrowser.site/terms/" />,
            a2: <a href="https://cryptobrowser.site/privacy/" />,
          }}
        />
      </div>
    </div>
  );
}
