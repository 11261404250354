import React, { FC, SVGProps } from 'react';
import styles from './AffiliateFeature.module.scss';

export type AffiliateFeatureProps = {
  Icon: FC<SVGProps<SVGSVGElement>>;
  title: string;
  isAnimation?: boolean;
};

export const AffiliateFeature: FC<AffiliateFeatureProps> = (props) => {
  const { Icon, title, isAnimation } = props;
  return (
    <div className={`${styles.affiliate__feature} ${isAnimation && 'animate__animated aff-anim'}`} data-anim="fadeInUp">
      <div className={styles.affiliate__feature_body}>
        <div className={styles.affiliate__feature_icon}>
          <Icon />
        </div>
        <h5 className={styles.affiliate__feature_title}>{title}</h5>
      </div>
    </div>
  );
};
