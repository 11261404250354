import { useCallback, useEffect, useMemo, useState } from 'react';
import { rpc } from '../backend/Rpc';
import { ContentPage, PageCategory } from '../backend/JsonRpcApi';
import i18next from 'i18next';

export const SHOW_PROMO_LOCAL_KEY = 'show_promo';
export const usePromoBannerVariant = () => {
  const [promos, setPromos] = useState<ContentPage[]>([]);
  const fetchPromos = useCallback(async () => {
    const res = await rpc.transmit('content.category_pages', {
      categories: [PageCategory.Modals],
      language: i18next.language.split('-')[0],
      placing: ['ct-affiliate-modal'],
    });
    if (res.items) {
      setPromos(res.items);
    }
  }, []);
  useEffect(() => {
    void fetchPromos();
  }, [fetchPromos]);
  return useMemo(() => {
    if (promos.length === 0) {
      return undefined;
    }
    let allId: (number | string)[] = [];
    const info = new Map<number, ContentPage>();
    promos.forEach((p) => {
      info.set(p.id, p);
      allId.push(p.id);
    });
    if (!/advPromo=hide/gi.test(document.cookie)) {
      const isViewedAdv = window.localStorage.getItem(SHOW_PROMO_LOCAL_KEY)?.split(' ') || [];
      const isHidden = allId.filter((x) => !isViewedAdv.includes(x.toString()));
      if (isViewedAdv.length >= promos.length) {
        window.localStorage.removeItem(SHOW_PROMO_LOCAL_KEY);
      }
      return Number(isHidden[0]) ? info.get(Number(isHidden[0])) : undefined;
    }
    return promos.length > 0 ? promos[0] : undefined;
  }, [promos]);
};
