import cn from 'classnames';
import { useEffect } from 'react';
import { useModal } from '@shared/Modal/useModal';
import { Modal } from '@shared/Modal/Modal';
import styles from './ModalLoader.module.scss';
import { ReactComponent as AnimLoader } from './loader-anim.svg';

export default function ModalLoader({ isShow, className }: { isShow: boolean; className?: string }) {
  const { opened, onOpen, onClose } = useModal(isShow);

  useEffect(() => {
    if (isShow) onOpen();
    else onClose();
  }, [isShow]);

  return (
    <Modal opened={opened} onClose={onClose} closeBtnClassName={styles.hidden} isDontClose={true}>
      <div className={cn(styles.block, className)}>
        <AnimLoader />
      </div>
    </Modal>
  );
}
