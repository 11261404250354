import { useTranslation } from 'react-i18next';
import styles from './WithdrawalView.module.scss';
import { ReactComponent as ClockSVG } from '@assets/colored/clock.svg';

export default function WithdrawalLimitView() {
  const { t } = useTranslation();
  return (
    <div className={styles.card__body}>
      <ClockSVG className={styles.limit__icon} />
      <div className={styles.limit__title}>{t('Withdrawal requests limit is reached')}</div>
      <div className={styles.limit__description}>
        {t(
          'You have reached the limit of 10 simultaneous withdrawals. To request a new one, please wait for the current withdrawal to be completed or cancel it.'
        )}
      </div>
    </div>
  );
}
