import { PROMO_ERROR, PROMO_REQUEST, PROMO_SUCCESS } from '../constant';
const t: PromoType[] = [];
const initialState = {
  promo_request: false,
  promo_success: false,
  promo_get: false,
  promos: t,
};

type Action = {
  type: string;
  promos: PromoType[];
};

export type PromoType = {
  code: string;
  sold_count: number;
  alltime_income: number;
  active_count: number;
};

export type rootPromoType = typeof initialState;

export default function promoReducer(state = initialState, action: Action) {
  switch (action.type) {
    case PROMO_REQUEST: {
      return {
        ...state,
        promo_request: true,
        promo_error: false,
        promo_get: false,
      };
    }
    case PROMO_ERROR: {
      return {
        ...state,
        promo_request: false,
        promo_error: true,
      };
    }
    case PROMO_SUCCESS: {
      return {
        ...state,
        promo_request: false,
        promo_error: false,
        promo_get: true,
        promos: action.promos,
      };
    }
    default:
      return state;
  }
}
