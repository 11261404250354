import React, { useEffect } from 'react';
import { NotFoundView } from './view/NotFoundView';
import { HelmetHead } from '../headersComponents/HelmetHead';
import * as Sentry from '@sentry/react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const NotFound = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    Sentry.captureException(new Error(`Bad route request - 404(${pathname})`));
  }, [pathname]);
  const { t } = useTranslation();

  return (
    <>
      <HelmetHead title={t('error404.Title')} />
      <NotFoundView />
    </>
  );
};
