import { useTranslation } from 'react-i18next';
import styles from './FarmTabs.module.scss';
import { NavLink, useHistory } from 'react-router-dom';
import { FARM_ABOUT, FARM_ABOUT_PRO } from '@utils/links';
import { ReactComponent as FarmProLogoSmSvg } from '@assets/colored/farm-pro-logo-sm.svg';
import React, { HTMLAttributes } from 'react';
import { ReactComponent as FarmLogoSmSvg } from '@assets/colored/farm-logo-sm.svg';

type FarmTabsProps = HTMLAttributes<HTMLDivElement> & {};

export function FarmTabs(props: FarmTabsProps) {
  const { ...rest } = props;
  const { t } = useTranslation();
  let history = useHistory();

  function farmHandleClick() {
    history.push(FARM_ABOUT);
  }

  function farmProHandleClick() {
    history.push(FARM_ABOUT_PRO);
  }

  return (
    <div className={styles.tabs} {...rest}>
      <p className={styles.tabs__title}>{t('Product Version:')}</p>
      <div className={styles.tabs__switch}>
        <NavLink
          to={FARM_ABOUT}
          activeClassName={styles.tabs__switch_item_active}
          className={styles.tabs__switch_item}
          onClick={() => farmHandleClick()}
        >
          <FarmLogoSmSvg />
          CT Farm
        </NavLink>

        <NavLink
          to={FARM_ABOUT_PRO}
          activeClassName={styles.tabs__switch_item_active}
          className={styles.tabs__switch_item}
          onClick={() => farmProHandleClick()}
        >
          <FarmProLogoSmSvg />
          CT Farm PRO
        </NavLink>
      </div>
    </div>
  );
}
