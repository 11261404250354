import { Card, StoreButtonKind, StoreButton } from '@shared/index';
import { useTranslation } from 'react-i18next';
import { useAnimation } from '@hooks/useAnimation';
import styles from './Earn.module.scss';
import { AndroidModal } from '@shared/AndroidModal/AndroidModal';
import { useModal } from '@shared/Modal/useModal';
import { Modal } from '@shared/Modal/Modal';
import { Picture } from '@shared/Picture/Picture';
import React from 'react';

export const Earn = () => {
  const { t } = useTranslation();
  useAnimation();

  const { onOpen, opened, onClose } = useModal();

  return (
    <Card className={styles.card}>
      <Modal onClose={onClose} opened={opened} closeBtnClassName={styles.close}>
        <AndroidModal onClose={onClose} />
      </Modal>
      <div className={styles.earn}>
        <h2 className={styles.earn__title}>{t('mobile_app_browser.title')}</h2>
        <p className={styles.earn__description}>{t('mobile_app_browser.desc')}</p>
        <div className={styles.earn__btn_group}>
          <StoreButton onClick={onOpen} kind={StoreButtonKind.PlayMarket} text={'GOOGLE PLAY'} as={'button'} />
          {/* <StoreButton
            kind={StoreButtonKind.AppStore}
            link={'https://itunes.apple.com/app/cryptotab-browser-pro/id1524974223/'}
            text={'APP STORE'}
            target={'_blank'}
          /> */}
        </div>
        <div className={styles.earn__image}>
          <Picture
            imageDesktop={'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-browser/devices.png'}
            imageDesktop2x={'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-browser/devices@2x.png'}
            imageMob={'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-browser/devices-mob.png'}
            imageMob2x={'https://cdn.cryptobrowser.site/images/affiliate-redesign/ct-browser/devices-mob@2x.png'}
            breakPoint={'640px'}
          ></Picture>
        </div>
      </div>
    </Card>
  );
};
