import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import getFarmEarnings from '@service/actions/getFarmEarnings';
import { rootFarmGraph } from '@service/reducers/farmGraphReducer';
import { Span } from '@shared/Graph/Tabs';
import { rootUser } from '@service/reducers/userReducer';
import { rootBrowserGraph } from '@service/reducers/browserStatsReducer';
import { SET_BROWSER_PERIOD } from '@service/constant';
import GraphComponent from '@shared/Graph/GraphComponent/GraphComponent';
import { customYformat } from '@utils/graph';

export function EarningsCard() {
  const { t } = useTranslation();
  const [selectPeriod, setSelectPeriod] = useState(Span.Day);
  const dispatch = useDispatch();
  const store = useSelector((store: { user: rootUser; browserGraph: rootBrowserGraph }) => store);
  const period = useSelector((store: { browserGraph: rootBrowserGraph }) => ({
    start: store.browserGraph.period_start,
    end: store.browserGraph.period_end,
  }));
  const onPeriodChange = useCallback(
    (period: { period_start: string; period_end: string }) => {
      dispatch({
        type: SET_BROWSER_PERIOD,
        ...period,
      });
    },
    [dispatch]
  );
  useEffect(() => {
    store.user.id !== 0 &&
      dispatch(
        getFarmEarnings(selectPeriod, store.user.id, store.browserGraph.period_start, store.browserGraph.period_end)
      );
  }, [dispatch, selectPeriod, store.user.id, store.browserGraph.period_start, store.browserGraph.period_end]);
  const data = useSelector((store: { farmGraph: rootFarmGraph }) => store.farmGraph.earning_data);

  let total = useMemo(() => {
    const res = data.reduce((acc: number, item) => acc + Number(item.value), 0).toFixed(8);
    return res;
  }, [data]);
  const totalEarning = +total ? total + ' BTC' : undefined;
  return (
    <GraphComponent
      total={totalEarning}
      onPeriodChange={onPeriodChange}
      period={period}
      type={'value'}
      onSpanChanged={setSelectPeriod}
      span={selectPeriod}
      title={t('Earnings')}
      data={data}
      tooltip={t('Earnings.tooltip')}
      id="earnings"
      yFormat={customYformat}
    />
  );
}
