import React from 'react';
import { HelmetHead } from '../headersComponents/HelmetHead';
import { BrowserBannedView } from './view/BrowserBannedView';

export const BrowserBanned = () => {
  return (
    <div>
      <HelmetHead />
      <BrowserBannedView />
    </div>
  );
};
