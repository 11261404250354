import { ConnectionError } from './ConnectionError';

export class AbortedConnection extends ConnectionError {
  public readonly isAbortedConnection = true;

  constructor() {
    super('The connection aborted');
  }
}

export function isAbortedConnection(e: unknown): e is AbortedConnection {
  return e !== null && typeof e === 'object' && 'isAbortedConnection' in e;
}
