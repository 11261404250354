import { useTranslation } from 'react-i18next';
import { TextCard, PromoSection } from '@shared/index';
import styles from './TextBlock.module.scss';
import FooterPromoSection from '@shared/FooterPromoSection/FooterPromoSection';
import { usePromoShowOthers } from '@hooks/usePromoShowOthers';
import { useMemo } from 'react';

export type TextBlockProps = {
  texts: string[];
};

export function TextBlock({ texts }: TextBlockProps) {
  const { t } = useTranslation();
  const { visible, showAll, onOther, isHide } = usePromoShowOthers(3, texts.length);

  return (
    <PromoSection
      title={t('Texts for promo posts')}
      text={t('Use these prepared texts to make your posts in social with ease.')}
      id="text"
      Footer={<FooterPromoSection onOtherClick={onOther} onAllClick={showAll} isHide={isHide} />}
    >
      <div className={styles.container}>
        {texts.slice(visible.from, visible.to).map((item) => (
          <TextCard text={item} key={item} />
        ))}
      </div>
    </PromoSection>
  );
}
