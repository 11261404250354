import { CSSProperties } from 'react';
import { FarmAboutCode } from '@shared/index';
import styles from './EmptyFarmBanners.module.scss';

export type EmptyFarmBannersProps = {
  style?: CSSProperties;
};

export default function EmptyFarmBanners({ style }: EmptyFarmBannersProps) {
  return (
    <div className={styles.container} style={style}>
      <FarmAboutCode xsVariant />
    </div>
  );
}
