import i18next from 'i18next';
import { useMemo } from 'react';

export type Player = 'youtube' | 'vimeo';

const PLAYERS_URLS_MAP: Record<Player, string> = {
  youtube: 'https://www.youtube.com/embed/',
  vimeo: 'https://player.vimeo.com/video/',
};

const PARAMS = '?mute=1&controls=1&showinfo=0&rel=0&loop=1';

export const useVideoUrl = (videos: Record<string, string>, player: Player, params?: string) => {
  const videoUrl = videos[i18next.language as keyof typeof videos] || videos.en;

  return useMemo(() => {
    return `${PLAYERS_URLS_MAP[player]}${videoUrl}${params ? params : PARAMS}`;
  }, [videoUrl, player, params]);
};
