import { Trans, useTranslation } from 'react-i18next';
import React, { useCallback, useEffect } from 'react';
import { useModal } from '@shared/Modal/useModal';
import ModalHSHLayout from '@shared/ModalHSHLayout';
import { CheckSvg } from '@assets/colorless';
import { BROWSER_WITHDRAWAL } from '@utils/links';
import { formatAddress } from '@utils/formatAddress';
import { useBreakpoints } from '@hooks/useBreakpoints';

interface ModalNcwSuccessProps {
  isShow: boolean;
  email: string;
  changeIsShow: () => void;
  BTCOrHSH: string;
}

export default function ModalNcwSuccess({ isShow, changeIsShow, email, BTCOrHSH }: ModalNcwSuccessProps) {
  const { opened, onOpen, onClose } = useModal(isShow);
  const { isMobile } = useBreakpoints();
  const { t } = useTranslation();

  useEffect(() => {
    if (isShow) onOpen();
  }, [isShow]);

  const handleClose = useCallback(() => {
    changeIsShow();
    onClose();
  }, []);

  return (
    <ModalHSHLayout
      opened={opened}
      onClose={handleClose}
      title={t('Connect_NCW.Confirmation.Title')}
      text={
        <Trans
          defaults="Connect_NCW.Confirmation.Description"
          values={{ email: formatAddress(email, isMobile) }}
          components={{ b: <b /> }}
        />
      }
      isBackButton={true}
      button={{
        type: 'link',
        icon: <CheckSvg />,
        text: t('OK, GOT IT'),
        link: `${BROWSER_WITHDRAWAL}/${BTCOrHSH}`,
      }}
      isNCW={true}
    />
  );
}
