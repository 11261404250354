import overview1 from './images/step-01@2x.png';
import overview2 from './images/step-02@2x.png';
import overview3 from './images/step-03@2x.png';
import overview4 from './images/step-04@2x.png';
import overview5 from './images/step-05@2x.png';
import overview6 from './images/step-06@2x.png';
import overviewMobile1 from './images/step-01_mobile@2x.png';
import overviewMobile2 from './images/step-02_mobile@2x.png';
import overviewMobile3 from './images/step-03_mobile@2x.png';
import overviewMobile4 from './images/step-04_mobile@2x.png';
import overviewMobile5 from './images/step-05_mobile@2x.png';
import overviewMobile6 from './images/step-06_mobile@2x.png';
// import icon1 from './images/Icon-1@2x.png';
// import icon2 from './images/Icon-2@2x.png';
// import icon3 from './images/Icon-3@2x.png';
// import { ReactComponent as Icon4 } from './images/icon-4.svg';
// import { ReactComponent as Icon5 } from './images/icon-5.svg';
import icon6 from './images/Icon-6@2x.png';

import React from 'react';
import { BROWSER_DASH } from '@utils/links';

type LinkType = {
  name: string;
  icon: React.ReactNode | string;
  link: string;
  isReversed?: boolean | undefined;
  isBlack?: boolean | undefined;
};

export type SlideType = {
  title: string;
  text: string;
  links?: LinkType[];
  image: string;
  imageMobile: string;
};

export const slideData: SlideType[] = [
  {
    title: 'Tutorial.Scr_1.Title',
    text: 'Tutorial.Scr_1.Description',
    image: overview1,
    imageMobile: overviewMobile1,
  },
  {
    title: 'Tutorial.Scr_2.Title',
    text: 'Tutorial.Scr_2.Description',
    image: overview2,
    imageMobile: overviewMobile2,
  },
  {
    title: 'Tutorial.Scr_3.Title',
    text: 'Tutorial.Scr_3.Description',
    // links: [
    //     {
    //         name: 'CT BROWSER',
    //         icon: icon1,
    //         link: 'https://cryptobrowser.site/app/browser/promo',
    //         isBlack: true
    //     },
    //     {
    //         name: 'CT POOL',
    //         icon: icon2,
    //         link: 'https://cryptobrowser.site/app/pool/promo',
    //         isBlack: true
    //     },
    //     {
    //         name: 'CT FARM',
    //         icon: icon3,
    //         link: 'https://cryptobrowser.site/app/farm/promo',
    //         isBlack: true
    //     },
    // ],
    image: overview3,
    imageMobile: overviewMobile3,
  },
  {
    title: 'Tutorial.Scr_4.Title',
    text: 'Tutorial.Scr_4.Description',
    image: overview4,
    imageMobile: overviewMobile4,
  },
  {
    title: 'Tutorial.Scr_5.Title',
    text: 'Tutorial.Scr_5.Description',
    // links: [
    //     {
    //         name: 'Tutorial.Payments_journal',
    //         icon: <Icon4 />,
    //         link: 'https://cryptobrowser.site/journal/',
    //         isBlack: false
    //     },
    // ],
    image: overview5,
    imageMobile: overviewMobile5,
  },
  {
    title: 'Tutorial.Scr_6.Title',
    text: 'Tutorial.Scr_6.Description',
    links: [
      // {
      //     name: 'Tutorial.Scr_6.Learn.Btn',
      //     icon: <Icon5 />,
      //     link: 'https://cryptobrowser.site/app/',
      //     isBlack: false
      // },
      {
        name: 'Tutorial.Start.Btn',
        icon: icon6,
        link: BROWSER_DASH,
        isReversed: true,
      },
    ],
    image: overview6,
    imageMobile: overviewMobile6,
  },
];
