import styles from './WithdrawalModal.module.scss';
import { WithdrawalModalState } from '../WithdrawalView';
import { ModalError } from './ModalError';
import { ModalConfirm } from './ModalConfirm';
import { ModalWallet } from '../PromoModals/ModalWallet';
import { PromoModalWithBotAndCheck } from '../PromoModals/PromoModalWithBotAndCheck';
import ModalChange from './ModalChange';
import { usePromoVariant } from '@hooks/usePromoVariant';
import { ModalNetworkAlert } from './ModalNetworkAlert';
import cls from 'classnames';
import { useCallback } from 'react';
import { Modal } from '@shared/Modal/Modal';
import { useModal } from '@shared/Modal/useModal';

export type WithdrawalModalProps = {
  handleModalClose: () => void;
  variant: WithdrawalModalState;
  onResolveChange: () => void;
  isBitcoin: boolean;
};

export function WithdrawalModal({ handleModalClose, variant, onResolveChange, isBitcoin }: WithdrawalModalProps) {
  const promoInfo = usePromoVariant();
  const { opened, onClose } = useModal(true);
  const handleClose = useCallback(() => {
    if (variant === WithdrawalModalState.Promo || variant === WithdrawalModalState.NetworkAlert) {
      return null;
    }
    handleModalClose();
    onClose();
  }, [onClose, variant]);

  return (
    <Modal opened={opened} onClose={handleClose} className={styles.container}>
      <div
        className={cls([
          styles.card,
          variant === WithdrawalModalState.NetworkAlert && styles.card__wide,
          variant === WithdrawalModalState.Loader && styles.card__loader,
        ])}
        onClick={(e) => e.stopPropagation()}
      >
        {variant === WithdrawalModalState.Error && <ModalError onClose={handleClose} />}
        {variant === WithdrawalModalState.Confirm && <ModalConfirm isBitcoin={isBitcoin} />}
        {variant === WithdrawalModalState.Wallet && <ModalWallet onClose={handleClose} />}
        {variant === WithdrawalModalState.Change && (
          <ModalChange onClose={handleClose} onResolveChange={onResolveChange} />
        )}
        {variant === WithdrawalModalState.Promo && <PromoModalWithBotAndCheck onClose={handleClose} {...promoInfo} />}
        {variant === WithdrawalModalState.NetworkAlert && <ModalNetworkAlert onClose={handleClose} />}
      </div>
    </Modal>
  );
}
