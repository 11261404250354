import React from 'react';
import { SupportForm } from './SupportForm/SupportForm';
import { FormStateDate } from '../FormStateData';
import { SupportFormKind } from '../SupportFormKind';
import { SupportFormExtended } from './SupportFormExtended/SupportFormExtended';
import { SupportFormBot } from './SupportFormBot/SupportFormBot';
import { Withdrawal } from '../../../backend/JsonRpcApi';

export type SupportViewProps = {
  kind: SupportFormKind;
  withdrawals: Withdrawal[];
  getWithdrawals: () => void;
  email: string;
  onSubmit: (formState: FormStateDate) => void;
  onBotSubmit: (id: string) => void;
  onBack: () => void;
  hasRequested: boolean;
  isSubmitting?: boolean;
};

export default function SupportView(props: SupportViewProps) {
  const {
    kind = SupportFormKind.Default,
    email,
    withdrawals,
    hasRequested,
    getWithdrawals,
    onSubmit,
    onBotSubmit,
    onBack,
    isSubmitting,
  } = props;
  return (
    <>
      {kind === SupportFormKind.Default && (
        <SupportForm isSubmitting={isSubmitting} email={email} onSubmit={onSubmit} onBack={onBack} />
      )}
      {kind === SupportFormKind.Extended && (
        <SupportFormExtended isSubmitting={isSubmitting} email={email} onSubmit={onSubmit} onBack={onBack} />
      )}
      {kind === SupportFormKind.Bot && (
        <SupportFormBot
          isSubmitting={isSubmitting}
          hasRequested={hasRequested}
          getWithdrawals={getWithdrawals}
          withdrawals={withdrawals}
          onSubmit={onBotSubmit}
          onBack={onBack}
        />
      )}
    </>
  );
}
