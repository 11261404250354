export const formatAddress = (adr: string, isMobile?: boolean) => {
  let max = 10;
  let min = 7;

  if ((adr.length <= 25 && !isMobile) || (adr.length <= 10 && isMobile)) return adr;
  if (/^[A-Z]+|[A-Z]+$/g.test(adr) && isMobile) {
    min = 5;
  }

  return `${adr.slice(0, isMobile ? min : max)}...${adr.slice(isMobile ? -min : -max)}`;
};
