import React, { useCallback, useEffect } from 'react';
import { BrowserBalanceView } from './view/BrowserBalanceView';
import { BrowserSubheader } from '../headersComponents/BrowserSubheader';
import { useDispatch, useSelector } from 'react-redux';
import { getWithdrawals } from '@service/actions/getWithdrawals';
import { useTranslation } from 'react-i18next';
import getNetworkAlert from '@service/actions/getNetworkAlert';
import { showToast, ToastKind } from '../../components/Toast/Toast';
import { HelmetHead } from '../headersComponents/HelmetHead';
import { Root } from '@service/reducers';
import { selectWithdrawals } from '@service/selectors/selectors';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { BROWSER_BALANCE } from '@utils/links';

export const BrowserBalance = () => {
  const dispatch = useDispatch();
  const { isBitcoin } = useSelector((state: Root) => state.balance);
  const withdrawals = useSelector((state: Root) => selectWithdrawals(state));

  const match = useRouteMatch<{ BTCOrHSH: string }>();
  const history = useHistory();

  const { isNextWithdrawalsLoading, withdrawalsToShow, hasRequested, limit, perPage, index } = withdrawals;

  const { t } = useTranslation();

  useEffect(() => {
    if (match.params.BTCOrHSH != undefined) {
      dispatch({ type: 'CHANGE_IS_BITCOIN', isBitcoin: match.params.BTCOrHSH === 'BTC' });
    }

    dispatch(getWithdrawals(true));
  }, []);

  useEffect(() => {
    dispatch(getNetworkAlert());
  }, [dispatch]);

  useEffect(() => {
    if (withdrawals.isAddedWithdrawalHSH) {
      showToast(t('HSH.Withdr_success.Toast'), ToastKind.Success);
    }
  }, []);

  const setIsBitcoin = useCallback((value: boolean) => {
    dispatch({ type: 'CHANGE_IS_BITCOIN', isBitcoin: value });
    history.push(`${BROWSER_BALANCE}/${value ? 'BTC' : 'HSH'}`);
    dispatch(getWithdrawals(true));
  }, []);

  return (
    <div>
      <HelmetHead title={t('Payment History')} />
      <BrowserSubheader />
      <BrowserBalanceView
        history={withdrawalsToShow}
        isNextWithdrawalsLoading={isNextWithdrawalsLoading}
        limit={limit}
        perPage={perPage}
        index={index}
        isBitcoin={isBitcoin}
        setIsBitcoin={setIsBitcoin}
        hasRequested={hasRequested}
      />
    </div>
  );
};
