import WithdrawHeader from '@pages/Withdrawal/view/WithdrawHeader';
import WithdrawalLimitView from '@pages/Withdrawal/view/WithdrawalLimitView';
import WithdrawalBody, { WithdrawalBodyProps } from '@pages/Withdrawal/view/WithdrawalBody';
import WithdrawalViewFooter, { WithdrawalViewFooterProps } from '@pages/Withdrawal/view/WithdrawalViewFooter';
import { useSelector } from 'react-redux';
import { Root } from '@service/reducers';
import BannerWithdrawal from '@pages/Withdrawal/view/BannerWithdrawal';

type WithdrawTabProps = WithdrawalViewFooterProps &
  WithdrawalBodyProps & {
    waitTransactionLength: number;
    isSuspend: boolean;
  };
export default function WithdrawTab(props: WithdrawTabProps) {
  const { waitTransactionLength, isSuspend, ...rest } = props;
  const { hasRequested } = useSelector((store: Root) => store.withdrawals);

  return (
    <>
      <WithdrawHeader
        balance={rest.balance}
        balanceHSH={rest.balanceHSH}
        isBitcoin={rest.isBitcoin}
        isSuspend={isSuspend}
      />
      {waitTransactionLength >= 10 && rest.isBitcoin && hasRequested ? (
        <WithdrawalLimitView />
      ) : (
        <WithdrawalBody isSuspend={isSuspend} {...rest} />
      )}
      <WithdrawalViewFooter isSuspend={isSuspend} {...rest} />
    </>
  );
}
