import {
  BROWSER_WITHDRAWAL,
  MAIN_ABOUT_URL,
  MAIN_LINK_URL,
  MAIN_PROMO_URL,
  MAIN_STATISTIC_URL,
  MAIN_URL,
} from '@utils/links';

export enum PRODUCT_KIND {
  All = 'all',
  Farm = 'farm',
  FarmPro = 'farm_pro',
  Pool = 'pool',
  Browser = 'browser',
  Nft = 'nft',
}

export default function checkPathname(path: string): PRODUCT_KIND | null {
  path = path.replace(/\/$/, '');

  if (
    [MAIN_URL, MAIN_PROMO_URL, MAIN_LINK_URL, MAIN_ABOUT_URL, MAIN_STATISTIC_URL, BROWSER_WITHDRAWAL].some((url) => {
      if (url === MAIN_URL) return path === MAIN_URL;
      return path.includes(url);
    })
  ) {
    return PRODUCT_KIND.All;
  }

  if (path.match('app/farm/')) {
    return PRODUCT_KIND.Farm;
  }

  if (path.match('app/farm-pro/')) {
    return PRODUCT_KIND.FarmPro;
  }

  if (path.match('app/pool/')) {
    return PRODUCT_KIND.Pool;
  }

  if (path.match('app/browser/')) {
    return PRODUCT_KIND.Browser;
  }

  return null;
}
