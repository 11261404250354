import { useTranslation } from 'react-i18next';
import { Pallet } from '../Pallet/Pallet';
import styles from './PromoTop.module.scss';
import cn from 'classnames';
import { Picture } from '@shared/Picture/Picture';

export type PromoTopProps = {
  imageDesktop: string;
  imageDesktop2x: string;
  imageMob: string;
  imageMob2x: string;
  List?: React.ReactNode;
  classImg?: string;
  Title?: string | React.ReactNode;
  Description?: string | React.ReactNode;
};

export function PromoTop({
  imageDesktop,
  imageDesktop2x,
  imageMob,
  imageMob2x,
  List,
  classImg,
  Title,
  Description,
}: PromoTopProps) {
  const { t } = useTranslation();
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <Pallet />
        <h1 className={styles.title}>{Title || t('Generate promo materials with your personal link')}</h1>
        <p className={styles.description}>
          {Description ||
            t(
              "Ready-made banners, images of various sizes, and posts—we've taken care of everything. Use our promo materials, attract new users and send invitations to your friends and followers on social networks. Expand your mining network and increase your chances of winning."
            )}
        </p>
        <div className={styles.buttons}>
          {List ? (
            List
          ) : (
            <>
              <a href="#banners">{t('Banners')}</a>
              <a href="#landings">{t('Landings')}</a>
              <a href="#social">{t('Social')}</a>
              <a href="#video">{t('Video')}</a>
            </>
          )}
        </div>
      </div>
      <Picture
        imageDesktop={imageDesktop}
        imageDesktop2x={imageDesktop2x}
        imageMob={imageMob}
        imageMob2x={imageMob2x}
        breakPoint={'600px'}
        className={cn(styles.img, classImg)}
      ></Picture>
    </section>
  );
}
