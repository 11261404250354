import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { BROWSER_WITHDRAWAL_NCW_WALLET } from '@utils/links';
import { Button, ButtonVariant } from '@shared/Button/Button';
import { IconWalletSvg } from '@assets/colorless';
import { NcwLogo } from '@assets/colored';
import styles from './NcwBanner.module.scss';
import React from 'react';
import { externalLinks } from '@utils/externalLinks';

export default function NcwBanner({ isBitcoin }: { isBitcoin: boolean }) {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.body}>
          <NcwLogo width={52} height={52} />
          <p>
            {isBitcoin ? (
              <Trans
                i18nKey="NCW.Banner.Withdr"
                components={{
                  a: <a href={externalLinks.ncwTry} target="_blank" rel="noreferrer" />,
                }}
              />
            ) : (
              <Trans
                i18nKey="Withdrawal_not_BTC.NCW_Banner.Title"
                components={{
                  a: <a href={externalLinks.ncwTry} target="_blank" rel="noreferrer" />,
                }}
                values={{ currency: 'HSH' }}
              />
            )}
          </p>
        </div>
        <Button
          Icon={<IconWalletSvg />}
          type="link"
          to={`${BROWSER_WITHDRAWAL_NCW_WALLET}/${isBitcoin ? 'BTC' : 'HSH'}`}
          size={'small'}
          variant={ButtonVariant.Primary}
          className={styles.button}
        >
          {t('CREATE WALLET')}
        </Button>
      </div>
    </div>
  );
}
