export interface IJsonRpcException<C extends number, D = unknown> {
  readonly isJsonRpcException: true;
  readonly code: C;
  readonly data: D;
}

export class JsonRpcException extends Error {
  readonly isJsonRpcException = true;

  constructor(message: string, public readonly code: number, public readonly data: unknown) {
    super(message);
  }
}

export function isJsonRpcException(e: unknown): e is JsonRpcException {
  return e !== null && typeof e === 'object' && 'isJsonRpcException' in e;
}
