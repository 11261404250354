import styles from './BrandBlock.module.scss';

export type BrandLinkProps = {
  title: string;
  res: string;
  href: string;
};

export function BrandLink({ title, res, href }: BrandLinkProps) {
  return (
    <div className={styles.link}>
      <a href={href} download>
        {title}
      </a>
      <span>{res}</span>
    </div>
  );
}
