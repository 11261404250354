import React, { CSSProperties, FC, HTMLAttributes, PropsWithChildren, useMemo } from 'react';
import styles from './Container.module.scss';

export type ContainerProps = PropsWithChildren<
  HTMLAttributes<HTMLDivElement> & {
    vertical?: number | 'auto';
    horizontal?: number | 'auto';
    marginTop?: number | 'auto';
    marginBottom?: number | 'auto';
  }
>;

export const Container: FC<ContainerProps> = ({ children, ...props }) => {
  const { vertical = 0, horizontal = 'auto', marginTop, marginBottom, className = '', ...rest } = props;

  const inlineStyles = useMemo((): CSSProperties => {
    return {
      marginInline: horizontal && horizontal >= 0 ? `${horizontal}px` : horizontal,
      marginBlock: vertical && vertical >= 0 ? `${vertical}px` : vertical,
      marginTop: marginTop && marginTop >= 0 ? `${marginTop}px` : marginTop,
      marginBottom: marginBottom && marginBottom >= 0 ? `${marginBottom}px` : marginBottom,
    };
  }, [vertical, horizontal]);

  return (
    <div className={`${styles.container} ${className}`} style={inlineStyles} {...rest}>
      {children}
    </div>
  );
};
