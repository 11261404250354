import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import getBalances from '@service/actions/getBalances';
import getBrowserStatsLinks from '@service/actions/getBrowserStatsLinks';
import { BROWSER_LINKS, BROWSER_STATISTIC } from '@utils/links';
import { SET_BROWSER_PERIOD } from '@service/constant';
import LinksView from '@shared/LinksView/LinksView';
import useFilteredBrowserLinks from '@hooks/useFilteredBrowserLinks';
import { Root } from '@service/reducers';
import { useTranslation } from 'react-i18next';
import { PRODUCT_KIND } from '@utils/checkPathname';
import { useGeneratePostModal } from '@hooks/useGeneratePostModal';
import { selectBalance, selectBrowserGraph } from '@service/selectors/selectors';
import getBrowserDashboardInfo from '@service/actions/getBrowserDashboardInfo';
import { Span } from '@shared/Graph/Tabs';

export function BrowserLinks() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const browserGraph = useSelector((store: Root) => selectBrowserGraph(store));
  const balance = useSelector((store: Root) => selectBalance(store));

  const period = {
    start: browserGraph.period_start,
    end: browserGraph.period_end,
  };

  const onPeriodChange = useCallback(
    (period: { period_start: string; period_end: string }) => {
      dispatch({
        type: SET_BROWSER_PERIOD,
        ...period,
      });
      dispatch(getBrowserStatsLinks(period.period_start, period.period_end));
    },
    [dispatch]
  );
  const handleRefresh = useCallback(() => {
    dispatch(getBalances());
    dispatch(getBrowserDashboardInfo(browserGraph.period_start, browserGraph.period_end, Span.Day, true));
  }, [dispatch, browserGraph.period_start, browserGraph.period_end]);

  const count = useMemo(() => balance.links.filter((i) => i.type !== 'farm').length, [balance.links]);
  const totalInfo = useFilteredBrowserLinks();

  const { handleGeneratePostClick } = useGeneratePostModal(PRODUCT_KIND.Browser, { timeoutDelay: 2000 });

  const isLoading = !browserGraph.stats_links_get && !balance.get_balance;

  return (
    <LinksView
      count={count}
      handleRefresh={handleRefresh}
      link={BROWSER_LINKS}
      landLink={'https://cryptotabbrowser.com/'}
      period={period}
      projectType={'browser'}
      totalInfo={totalInfo}
      onPeriodChange={onPeriodChange}
      linksStatisticInfo={{
        path: BROWSER_STATISTIC,
        title: t('Statistics'),
      }}
      onLinkCreate={handleGeneratePostClick}
      isLoading={isLoading}
    />
  );
}
