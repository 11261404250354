import dayjs from 'dayjs';
import { Span } from '@shared/Graph/Tabs';

export const useFarmRespInfo = (period: Span) => ({
  start: FarmRequest[period].start,
  discreteness: FarmRequest[period].discreteness,
  end: dayjs(new Date()).format('YYYY-MM-DD'),
});

export const FarmRequest = {
  [Span.Day]: {
    start: dayjs(new Date()).subtract(1, Span.Day).format('YYYY-MM-DD'),
    discreteness: Span.Day,
  },
  [Span.Week]: {
    start: dayjs(new Date()).subtract(7, Span.Day).format('YYYY-MM-DD'),
    discreteness: Span.Week,
  },
  [Span.Month]: {
    start: dayjs(new Date()).subtract(1, Span.Month).format('YYYY-MM-DD'),
    discreteness: Span.Month,
  },
};
