import { useEffect, useRef } from 'react';

export enum ScrollToSection {
  MINING_NETWORK = 'mining-network',
}

type ScrollToSectionProps = {
  section: ScrollToSection;
  offset?: number;
};

export const useScrollToSection = ({ section, offset = 100 }: ScrollToSectionProps) => {
  const animationFrameId = useRef<number | null>(null);

  useEffect(() => {
    const hash = window.location.hash.replace('#', '');
    if (hash !== section) return;

    const scrollToSection = () => {
      const disableSmoothScrolling = () => {
        document.documentElement.style.scrollBehavior = 'auto';
      };

      const enableSmoothScrolling = () => {
        document.documentElement.style.scrollBehavior = 'smooth';
      };

      disableSmoothScrolling();

      const startPosition = window.scrollY;
      const duration = 500;
      let startTime: number | null = null;

      const animateScroll = (currentTime: number) => {
        if (startTime === null) startTime = currentTime;
        const timeElapsed = currentTime - startTime;

        const element = document.getElementById(section);
        if (!element) {
          animationFrameId.current = null;
          return;
        }

        // Recalculate target position dynamically
        const targetPosition = element.offsetTop - offset;
        const distance = targetPosition - startPosition;

        // Linear progress (timeElapsed / duration)
        const progress = Math.min(timeElapsed / duration, 1);
        const scrollTo = startPosition + distance * progress;

        window.scrollTo(0, scrollTo);

        if (progress < 1) {
          animationFrameId.current = requestAnimationFrame(animateScroll);
        } else {
          // Animation complete
          // Reset hash to prevent browser from scrolling to the section again
          window.history.replaceState(null, '', ' ');
          enableSmoothScrolling();
        }
      };

      const cancelAnimation = () => {
        if (animationFrameId.current) {
          cancelAnimationFrame(animationFrameId.current);
          animationFrameId.current = null;
        }
      };

      // Start animation
      cancelAnimation();
      animationFrameId.current = requestAnimationFrame(animateScroll);

      // Cancel animation on user interaction
      const handleUserScroll = () => {
        cancelAnimation();
        enableSmoothScrolling(); // Re-enable smooth scrolling if user interrupts
        window.removeEventListener('wheel', handleUserScroll);
        window.removeEventListener('touchmove', handleUserScroll);
      };

      window.addEventListener('wheel', handleUserScroll, { passive: true });
      window.addEventListener('touchmove', handleUserScroll, { passive: true });
    };

    scrollToSection();

    return () => {
      if (animationFrameId.current) cancelAnimationFrame(animationFrameId.current);
    };
  }, [section, offset]);
};
