import { initReactI18next } from 'react-i18next';
import LngDetector from 'i18next-browser-languagedetector';
import i18next from 'i18next';
import en from '@ctaf/localization/locales/en.json';
import ru from '@ctaf/localization/locales/ru.json';
import de from '@ctaf/localization/locales/de.json';
import es from '@ctaf/localization/locales/es.json';
import fr from '@ctaf/localization/locales/fr.json';
import it from '@ctaf/localization/locales/it.json';
import pt from '@ctaf/localization/locales/pt.json';

i18next
  .use(initReactI18next)
  .use(LngDetector)
  .init({
    compatibilityJSON: 'v3',
    fallbackLng: 'en',
    detection: {
      caches: false,
    },
    resources: {
      en: {
        translation: en,
      },
      ru: {
        translation: ru,
      },
      de: {
        translation: de,
      },
      es: {
        translation: es,
      },
      fr: {
        translation: fr,
      },
      it: {
        translation: it,
      },
      pt: {
        translation: pt,
      },
    },
  });
