import { useSelector } from 'react-redux';
import { Root } from '@service/reducers';
import DesktopFooter from './DesktopFooter';
import MobileFooter from './MobileFooter';
import BottomMobileSafeArea from '../BottomMobileSafeArea/BottomMobileSafeArea';
import React from 'react';

export default function Footer() {
  const { isMobile } = useSelector((root: Root) => root.system);

  if (isMobile) {
    return <MobileFooter />;
  }
  return <DesktopFooter />;
}
