import styles from './MobileFooter.module.scss';
import { Button } from '@shared/Button/Button';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ArrowLeftSmSvg } from '@assets/colorless';
import { BROWSER_BALANCE, MAIN_URL } from '@utils/links';

export default function MobileFooter() {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className={styles.container}>
      <Button type="button" size="medium" Icon={<ArrowLeftSmSvg />} onClick={() => history.push(MAIN_URL)}>
        {t('GO BACK')}
      </Button>
    </div>
  );
}
