import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';
import { useBreakpoints } from '@hooks/useBreakpoints';

export default function Header() {
  const { isMobile, isTable } = useBreakpoints();

  if (isMobile || isTable) {
    return <MobileHeader />;
  }
  return <DesktopHeader />;
}
