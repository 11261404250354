export const farmText = [
  'Have you heard about mining farms? Now you can build one yourself using CryptoTab Farm! And the best thing is you don’t need any investment or special knowledge for that. Just use your computer to install the CT Farm miner or try the Pool Miner feature if you don’t own a PC. Control the mining process through the app — everything is quick and easy! Learn more here —',
  'Hey guys! CryptoTab has launched its new product — CryptoTab Farm and I think you should give it a try. With it, you can create your own mining farm, connect an unlimited number of computers for free, and manage their work through your phone. You can even build your own crypto farm without having a PC using the special Pool Miner feature! This is a new way to get income in BTC!',
  'Did you know that you can build your own mining farm without any investment in just a couple of clicks? With CryptoTab Farm, it is real! It opens up completely new prospects for earning BTC — even if you don’t have your own PC — just use the Pool Miner feature! Plus, you get the free Pool Miner with your first subscription. Want to get income in BTC? That’s the right way to start.',
  'Hi everybody! I want to share some good news. Now you can build your own farm! With CryptoTab Farm, it’s super easy to do. All you need is to install the CT Farm miner on your PC and easily manage it through your phone! Connect all of your idle hardware while doing it — for absolutely free! And if you want to try it but don’t own a PC, there is the Pool Miner feature for that! Explore all the options and start earning BTC! Use my link —',
  'Hi friends! I started using CryptoTab Farm and I want to share with you all the cool features it has. With CT Farm, you can control hundreds of mining computers from one phone at no cost while managing everything from the schedule to the hashrate and temperature. And even if you don’t own a PC, you can use the Pool Miner feature and start earning BTC! Sounds awesome, right? Try it yourself!',
  "CryptoTab Farm is a whole new way of earning BTC. First, you can connect all of your computers for absolutely free or try the Pool Miner feature if you don't own a PC to get a steady income. And if you want to increase it — take advantage of the special referral system! Share your discount code with others, and get 15%% of their mining while they receive 15%% off on any purchase. There are plenty of opportunities with CT Farm! Follow my link to start —",
  'Want to begin mining but don’t know where to start? Build your own mining farm using CryptoTab Farm! It’s super convenient and easy — connect all of your computers to the farm, adjust settings to suit your needs, and earn BTC! Don’t have your own PC? Then use Pool Miners just for you. Still not sure? Use the trial period and get a free Pool Miner with it to explore all available features. Give it a try and you won’t be disappointed!',
  'I want to tell you about CryptoTab Farm! It is the easiest way to set up your own mining farm using all of the PCs for free! Adjust settings at any time, get notified, set the working schedule of miners, and even temperature, while doing it at the ease through your phone. What’s more, take part in Affiliate Program — boost your income and get discounts! Sounds exciting? Then give it a try —',
  'Are your PCs collecting dust? Well, then install CryptoTab Farm, and connect all of your hardware to it to transform the idle computer power into BTC income. Build your own mining farm and do it — for absolutely free! And if you’re not sure, activate the trial period: explore all of the features while receiving the Pool Miner with it! Try everything for yourself —',
  'Have you already tried CryptoTab Farm? If not, then you are missing out! I think it’s the best way to create your own crypto farm and start earning BTC without any investment. You can not only connect all of your hardware to the farm for free but also use the Pool Miner feature if you don’t own a PC. Choose what suits you best! And with my discount code, you’ll get 15%% off any in-app purchase —',
  'Fast and efficient mining, permanent income, unlimited withdrawals, and much more! With CryptoTab Farm you have everything you need to create your own crypto farm and start earning BTC. There are no limits to the number of computers you can add which you can do with no investment! And thanks to the Pool Miner feature, you can explore all the possibilities and start earning with just your phone. Mining has never been so easy!',
  'Always wanted to build your own crypto farm, but you don’t own a PC? With CryptoTab Farm, it’s not a problem! Explore mining, check different configurations, and start earning with no hardware or any special knowledge — just use the Pool Miner feature! This option is excellent for both newbies and experienced crypto farmers. Besides, you can get the free Pool Miner with your first subscription! Try it yourself —',
  'Do you have a lot of followers on social media? Want to benefit from it? Well, then share your personal link, invite them to install CryptoTab Farm, and become a part of your mining network. By doing that, you receive a bonus of 15%% of your referrals’ mining while they get 15%% off on any in-app purchase! Use CryptoTab Farm, join the Affiliate Program and earn more without any extra effort! Here is the link —',
  "Do you think that creating a mining farm requires a lot of investment? What if I tell you that you can do it for absolutely free? That's right! With CryptoTab Farm, you can connect all hardware you have to your farm — at no cost! Add your, your friend’s and family’s PCs, expand your farm, and earn BTC! Try it now and see for yourself how profitable it is.",
  'Thought about your own mining farm, but don’t know a lot about them? Then you should try CryptoTab Farm! It is the best way to start! Explore mining, see how it works, and check which configurations suit you the best —  without a need to install anything on your PC. With the Pool Miner feature, you can mine with just your phone! And you can get one Pool Miner for absolutely free with your first subscription — even a trial one! Isn’t it cool? It’s the best time to start!',
];

// TODO: CHANGE TO REAL TITLES
export const farmTitles = [
  'CTFarm_pro.Title_1',
  'CTFarm_pro.Title_2',
  'CTFarm_pro.Title_3',
  'CTFarm_pro.Title_4',
  'CTFarm_pro.Title_5',
  'CTFarm_pro.Title_6',
  'CTFarm_pro.Title_7',
  'CTFarm_pro.Title_8',
  'CTFarm_pro.Title_9',
  'CTFarm_pro.Title_10',
];
