import { NFT_STATS_ERROR, NFT_STATS_GET_ACCOUNTS, NFT_STATS_REQUEST, NFT_STATS_SUCCESS } from '../constant';

const initialState = {
  payments: [],
  tokens: [],
  accounts: [],
  click: [],
  apply: [],
  request: false,
  error: false,
  get: false,
  get_accounts: false,
};

type AсtionType = {
  type: string;
  payments?: NftPaymentsItem[];
  tokens?: NftTokensItem[];
  accounts?: NftTokensItem[];
  click?: NftTokensItem[];
  apply?: NftTokensItem[];
};

export type NftPaymentsItem = {
  timestamp: number;
  account_id: number;
  promo_code: string;
  amount: string;
};

export type NftTokensItem = {
  timestamp: number;
  code: string;
  tokens: number;
  accounts: number;
  value: number;
};

export type rootNftStatsType = Omit<typeof initialState, 'payments' | 'tokens' | 'accounts' | 'click' | 'apply'> & {
  payments: NftPaymentsItem[];
  tokens: NftTokensItem[];
  accounts: NftTokensItem[];
  click: NftTokensItem[];
  apply: NftTokensItem[];
};

export default function nftStatsReducer(state = initialState, action: AсtionType) {
  switch (action.type) {
    case NFT_STATS_REQUEST: {
      return {
        ...state,
        error: false,
        request: true,
      };
    }
    case NFT_STATS_ERROR: {
      return {
        ...state,
        error: true,
        request: false,
      };
    }
    case NFT_STATS_SUCCESS: {
      return {
        ...state,
        payments: action.payments,
        tokens: action.tokens,
        click: action.click,
        apply: action.apply,
        error: false,
        request: false,
        get: true,
      };
    }

    case NFT_STATS_GET_ACCOUNTS: {
      return {
        ...state,
        get_accounts: true,
        accounts: action.accounts,
      };
    }
    default: {
      return state;
    }
  }
}
