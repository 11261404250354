import React, { FC } from 'react';
import styles from './AndroidModal.module.scss';
import { Button, ButtonVariant } from '@shared/index';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Root } from '@service/reducers';
import cn from 'classnames';
type ItemModalProps = {
  IconBig: React.FC<React.SVGProps<SVGSVGElement>>;
  IconBigDark: React.FC<React.SVGProps<SVGSVGElement>>;
  features?: {
    Icon: React.FC<React.SVGProps<SVGSVGElement>>;
    text: string;
  }[];
  link: string;
  IconSmall: React.FC<React.SVGProps<SVGSVGElement>>;
  buttonStyle?: string;
};

export const CardItem: FC<ItemModalProps> = (props) => {
  const { t } = useTranslation();
  const { IconBig, IconBigDark, features, link, IconSmall } = props;
  const { isDarkTheme } = useSelector((store: Root) => store.system);
  return (
    <a href={link} target="_blank" rel="noreferrer" className={styles.androidModal__item}>
      <div className={styles.androidModal__item_header}>
        {isDarkTheme ? (
          <IconBigDark className={styles.androidModal__icon_big} />
        ) : (
          <IconBig className={styles.androidModal__icon_big} />
        )}
      </div>
      <div className={styles.androidModal__features}>
        {features?.map(({ Icon, text }) => (
          <div key={text} className={styles.androidModal__feature}>
            <Icon />
            <Trans i18nKey={t(text)} components={{ b: <b /> }} />
          </div>
        ))}
      </div>
      <div className={styles.androidModal__btn_wrapper}>
        <Button
          type={'button'}
          variant={ButtonVariant.Primary}
          iconPosition="right"
          Icon={<IconSmall />}
          className={cn([styles.androidModal__btn, props.buttonStyle])}
        >
          {t('DOWNLOAD')}
        </Button>
      </div>
    </a>
  );
};
