import styles from './BannerAddLinks.module.scss';
import arrow from '@assets/icon-arrows-down.svg';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Root } from '@service/reducers';
import { useEffect, useState } from 'react';
import { CrossSvg } from '@assets/colorless';
import { AddLinkButton } from '@shared/AddLinkButton/AddLinkButton';

type BannerAddLinkProps = {
  onLinkCreate: () => void;
};

export function BannerAddLinks({ onLinkCreate }: BannerAddLinkProps) {
  const { t } = useTranslation();
  const links = useSelector((store: Root) => store.balance.links);
  const filteredLinks = links.filter((i) => i.type !== 'farm');

  const [showBanner, setBannerShown] = useState(false);
  const onClose = () => setBannerShown(false);

  useEffect(() => {
    if (filteredLinks.length < 1) {
      setBannerShown(true);
    }
  }, [filteredLinks]);

  if (showBanner) {
    return (
      <div className={styles.container}>
        <button onClick={onClose} className={styles.close}>
          <CrossSvg />
        </button>
        <img className={styles.img} src={arrow} alt="arrow" />
        <div className={styles.title}>{t('Do you have a various traffic sources?')}</div>
        <div className={styles.text}>
          {t(
            'Create additional personal link to track different traffic sources with different metrics to understand conversion rates.'
          )}
        </div>
        <AddLinkButton btnClassName={styles.btn} onLinkCreate={onLinkCreate} />
      </div>
    );
  } else return <></>;
}
