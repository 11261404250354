import dayJs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import i18next from 'i18next';

dayJs.extend(utc);
dayJs.extend(customParseFormat);

export const formatDate = (time: number, format = 'dd DD MMM YYYY') => {
  dayJs.locale(i18next.language);
  return dayJs(time).format(format);
};
