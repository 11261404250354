import styles from './AffilateVideo.module.scss';
import { Player, useVideoUrl } from '@hooks/useVideoUrl';
import { useCallback, useState } from 'react';
import classNames from 'classnames';
import { Picture } from '@shared/Picture/Picture';

interface AffilateVideoProps {
  player: Player;
  videos: Record<string, string>;
  previewSrc?: string;
  previewRetinaSrc?: string;
  decorationImage?: string;
  rootClassname?: string;
}

export const AffilateVideo = ({
  videos,
  previewSrc,
  decorationImage,
  player,
  rootClassname,
  previewRetinaSrc,
}: AffilateVideoProps) => {
  const [showVideo, setShowVideo] = useState(false);
  const videoUrl = useVideoUrl(videos, player);

  const handleImageClick = useCallback(() => {
    setShowVideo(true);
  }, []);

  return (
    <div className={classNames([styles.root, rootClassname])}>
      <img
        onClick={handleImageClick}
        src={decorationImage}
        className={classNames(styles.image, {
          [styles.hide]: showVideo,
        })}
        alt="coin"
      />
      <div className={styles.video}>
        <iframe
          className={classNames('js-video', {
            [styles.show]: showVideo,
          })}
          title={videoUrl}
          frameBorder="0"
          allowFullScreen
          allow="autoplay"
          src={`${videoUrl}&autoplay=${showVideo ? 1 : 0}`}
        />
        {previewSrc && previewRetinaSrc && (
          <Picture
            imageDesktop={previewSrc}
            imageDesktop2x={previewRetinaSrc}
            onClick={handleImageClick}
            alt={videoUrl}
          ></Picture>
        )}
      </div>
    </div>
  );
};
