import { useTranslation } from 'react-i18next';
import styles from './WithdrawalModal.module.scss';
import { ReactComponent as Fail } from '@assets/withdraw-fail.svg';
import { Button, ButtonVariant } from '@shared/index';
import { useSelector } from 'react-redux';
import { WithdrawalsState } from '@service/reducers/withdrawalsReducer';

type ModalErrorProps = {
  onClose: () => void;
};

export function ModalError({ onClose }: ModalErrorProps) {
  const { t } = useTranslation();
  const error = useSelector((store: { withdrawals: WithdrawalsState }) => store.withdrawals.errorWithdrawal);
  return (
    <>
      <div className={styles.body}>
        <Fail />
        <div className={styles.title}>{t('WithdrawalConfirmError.Title')}</div>
        <div className={styles.text}>{t(error)}</div>
      </div>
      <div className={styles.footer}>
        <Button
          type="button"
          size="medium"
          className={styles.btn_only}
          onClick={onClose}
          variant={ButtonVariant.Primary}
        >
          {t('Ok')}
        </Button>
      </div>
    </>
  );
}
