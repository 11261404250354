import { useTranslation } from 'react-i18next';
import cls from 'classnames';
import { Button, ButtonVariant } from '@shared/Button/Button';
import { BtcSvg, HshSvg } from '@assets/colored';
import cn from 'classnames';
import styles from './WithdrawalView.module.scss';

export type WithdrawalSwitchTabsProps = {
  isBitcoin: boolean;
  setIsBitcoin: (val: boolean) => void;
  className?: string;
};

export function WithdrawalSwitchTabs({ isBitcoin, setIsBitcoin, className = '' }: WithdrawalSwitchTabsProps) {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.group__buttons, `${className}`)}>
      <Button
        Icon={<BtcSvg className={styles.buttons__svg} />}
        type="button"
        size="medium"
        variant={isBitcoin ? ButtonVariant.Primary : ButtonVariant.Default}
        className={cls(
          styles['buttons__switch--left'],
          styles.buttons__switch,
          !isBitcoin && styles[`buttons__switch--inactive`]
        )}
        onClick={() => !isBitcoin && setIsBitcoin(true)}
      >
        {t('Bitcoin')}
      </Button>
      <Button
        Icon={<HshSvg className={styles.buttons__svg} />}
        type="button"
        size="medium"
        variant={isBitcoin ? ButtonVariant.Default : ButtonVariant.Primary}
        className={cls(
          styles['buttons__switch--right'],
          styles.buttons__switch,
          isBitcoin && styles[`buttons__switch--inactive`]
        )}
        onClick={() => isBitcoin && setIsBitcoin(false)}
      >
        {t('Hashcoin')}
      </Button>
    </div>
  );
}
