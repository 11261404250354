export default function getUtmParamsString() {
  const searchParams = new URLSearchParams(document.location.search);
  let utmString = '&';

  for (let key of Array.from(searchParams.keys())) {
    if (key.indexOf('utm_') === 0) {
      utmString += `${key}=${searchParams.get(key)}&`;
    }
  }

  utmString = utmString.slice(0, -1);

  return utmString;
}
