import { useTranslation } from 'react-i18next';
import styles from './FarmPromoCodes.module.scss';
import cls from 'classnames';
import { ReactComponent as FarmProLogoSmSvg } from '@assets/colored/farm-pro-logo-sm.svg';
import { HTMLAttributes } from 'react';
import { ReactComponent as FarmLogoSmSvg } from '@assets/colored/farm-logo-sm.svg';
import { Button, ButtonVariant } from '@shared/Button/Button';

export enum FarmPromoTabs {
  Usually,
  Pro,
}

export type FarmPromoCodesTabsProps = HTMLAttributes<HTMLDivElement> & {
  active: FarmPromoTabs;
  dash?: boolean;
  set: (val: FarmPromoTabs) => void;
};

export function FarmPromoCodesTabs(props: FarmPromoCodesTabsProps) {
  const { active, set, dash, ...rest } = props;
  const { t } = useTranslation();

  return (
    <div className={styles.tabs} {...rest}>
      <p className={styles.tabs__title}>{t('Product Version:')}</p>
      <div className={styles.tabs__switch}>
        <Button
          type={'button'}
          className={cls(styles.tabs__switch_item, {
            [styles.tabs__switch_item_active]: active === FarmPromoTabs.Usually,
          })}
          onClick={() => set(FarmPromoTabs.Usually)}
          Icon={<FarmLogoSmSvg />}
          variant={active === FarmPromoTabs.Usually ? ButtonVariant.Primary : ButtonVariant.Default}
        >
          CT Farm
        </Button>
        <Button
          type={'button'}
          className={cls(styles.tabs__switch_item, { [styles.tabs__switch_item_active]: active === FarmPromoTabs.Pro })}
          onClick={() => set(FarmPromoTabs.Pro)}
          Icon={<FarmProLogoSmSvg />}
          variant={active === FarmPromoTabs.Pro ? ButtonVariant.Primary : ButtonVariant.Default}
        >
          CT Farm PRO
        </Button>
      </div>
    </div>
  );
}
