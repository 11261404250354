export interface TMining {
  id: string;
  hps: number;
  end_time: string;
  deleted: boolean;
}

export interface AuthRequest {
  access_token: string;
  lang?: string;
}

export interface Account {
  address: string;
}

export interface WalletsPendingPaymentRequest {
  amount: number;
  created_at: Date;
  id: string;
  meta: { [key: string]: any };
  purchase_at: Date;
  type: string;
}

export interface HshUserRegistrationRequest {
  amount: number;
}

export interface WalletsWithdrawalsChangeRequest {
  account_id: string;
  amount: number;
  client_ip?: string;
  created_at: Date;
  currency: string;
  id: string;
  meta?: Meta;
  service: string;
  status: WithdrawalStatus;
  to_address: string;
  txid?: string;
  updated_at: Date;
}

export interface Meta {
  account_id?: string;
  address?: string;
  email?: string;
}

export interface PoolMinerMessage {
  id: string;
  hps: number;
  end_time: number;
  deleted: boolean;
}

export interface PoolMiningMessage {
  id: string;
  hps: number;
  end_time: number;
  deleted: boolean;
}

type DeviceTypes = 'ANDROID' | 'IOS' | 'WEB';
export interface FcmRegisterRequest {
  token: string;
  device_type: DeviceTypes;
}

export interface NftMining {
  address: string;
  amount: number;
  hps: number;
  end_time: number;
}

export interface NftMiningAddressInfo {
  address: string;
  total: number;
  max: number;
  max_hps: number;
}

interface LinkBalance {
  link_id: number;
  ct?: ProjectBalance;
  ct_nft?: ProjectBalance;
  ct_pool?: ProjectBalance;
  farm?: ProjectBalance;
  nft?: ProjectBalance;
  eggs?: ProjectBalance;
}

interface EarningBalance {
  mined?: number;
  ref?: number;
}

interface ProjectBalance {
  total?: number;
  earnings?: EarningBalance;
  locked?: number;
}

type LinkType = 'None' | '' | 'revshare' | 'orange' | 'active' | 'cb_click' | 'farm';

interface Link {
  id: number;
  type?: LinkType;
  name?: string;
  comment?: string;
  url?: string;
  is_banned?: boolean;
}

interface NftPromoCode {
  code: string;
  created_at: number;
  discount?: number;
  is_deleted?: boolean;
}

interface FarmPromoCode {
  active_count?: number;
  alltime_income?: number;
  code: string;
  sold_count?: number;
}

// request
export type WithdrawalTypesType = 'browser' | 'farm' | 'hsh' | 'nft' | 'pool' | 'pool_mobile' | 'pool_nft' | 'regular';

export interface WalletsWithdrawalsCreateRequest {
  address: string;
  amount: number;
  type: WithdrawalTypesType;
}

// response
export interface Meta {
  address?: string;
  account_id?: string;
  email?: string;
}

export enum WithdrawalStatus {
  Canceled = 'canceled',
  Completed = 'completed',
  Confirmed = 'confirmed',
  Created = 'created',
  Error = 'error',
  Pending = 'pending',
  PendingFunds = 'pending_funds',
  Rejected = 'rejected',
  RejectedNull = 'rejected_null',
  Sent = 'sent',
}

export interface WalletsWithdrawalsCreateResponse {
  account_id: string;
  amount: number;
  client_ip?: string;
  created_at: Date;
  currency: string;
  id: string;
  meta?: Meta;
  service: string;
  status: WithdrawalStatus;
  to_address: string;
  txid?: string;
  updated_at: Date;
}

// request
export interface WalletsWithdrawalsGetRequest {
  id: string;
}

// response
export interface WalletsWithdrawalsGetResponse {
  account_id: string;
  amount: number;
  client_ip?: string;
  created_at: Date;
  currency: string;
  id: string;
  meta?: Meta;
  service: string;
  status: WithdrawalStatus;
  to_address: string;
  txid?: string;
  updated_at: Date;
}

export interface Meta {
  address?: string;
  account_id?: string;
  email?: string;
}

// request
export interface WalletsWithdrawalsCancelRequest {
  withdrawal_id: string;
}

// response
export interface WalletsWithdrawalsCancelResponse {
  account_id: string;
  amount: number;
  client_ip?: string;
  created_at: Date;
  currency: string;
  id: string;
  meta?: Meta;
  service: string;
  status: WithdrawalStatus;
  to_address: string;
  txid?: string;
  updated_at: Date;
}

// request
export interface WalletsCheckAddressRequest {
  address: string;
}

// response
export type WalletsCheckAddressResponse = BtcAddressResponse | NcwAddressResponse;

export interface BtcAddressResponse {
  type: string;
}

export interface NcwAddressResponse {
  account_id: string;
  email: string;
  type: string;
}

// request
interface GetWithdrawalsRequest {
  limit?: number;
  offset?: number;
}

// response
interface WithdrawalsResponse {
  total: number;
  limit: number;
  offset: number;
  items: Withdrawal[];
}

type WithdrawalState = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

type WithdrawalType = 'regular' | 'browser' | 'farm' | 'hsh' | 'nft' | 'pool_nft';

export type WithdrawalPaymentService = 'bitcoin' | 'ncw';

export interface Withdrawal {
  id: string;
  request_date: number;
  address: string;
  amount: string;
  state: WithdrawalState;
  type?: WithdrawalType;
  bitcoin_transaction?: string;
  payment_service: WithdrawalPaymentService;
  transaction_id: string;
  meta?: {
    type?: string;
    email?: string;
    account_id?: string;
  };
}

interface ReferralsResponse {
  items: ReferralsItem[];
}

export interface ReferralsItem {
  timestamp: number;
  account_id: number;
  lvl: number;
  value: number;
}

export interface ReferralPaymentsResponse {
  items: ReferralPaymentsItem[];
}

export interface ReferralPaymentsItem {
  timestamp?: number;
  account_id: number;
  lvl: number;
  users: number;
  amount: number;
}

// response
export type CurrenciesResponse = Currency[];

export interface Currency {
  features?: Feature[];
  img: string;
  min_withdrawal: number;
  name: string;
  symbol: string;
  unit: number;
  url: string;
}

export enum Feature {
  PoolMining = 'pool_mining',
}

// request
interface GetLastRateRequest {
  from_currency: string;
  to_currency: string;
}

// response
interface GetLastRateResponse {
  from_currency: string;
  to_currency: string;
  rate: number;
  last_updated?: string;
}

interface GetHistoryRequest {
  from_currency: string;
  interval?: 'daily' | 'hourly';
  time_end?: number;
  time_start?: number;
  to_currency: string;
}

// request
interface CreateIssueRequest {
  subject_id?: number;
  user_id?: string;
  email?: string;
  summary: string;
  message: string;
  attachments?: AttachmentInfo[];
}

interface AttachmentInfo {
  filename: string;
  content: string;
}

// response
interface CreateIssueResponse {
  issue_id?: string;
}

// response
interface WalletsBalanceResponse {
  accrued: number;
  group: string;
  total: number;
}

// request
interface WalletsPendingBalanceRequest {
  income?: boolean;
  outgoing?: boolean;
}

// response
interface WalletsPendingBalanceResponse {
  income?: number;
  outgoing?: number;
  earliest_payment_at?: number;
}

// response
export interface BtcWithdrawalMeta {
  address: string;
}

export interface NcwWithdrawalMeta {
  account_id: string;
  email: string;
}

export type WalletsWithdrawalsLastAddressesResponseUnion = BtcWithdrawalMeta | NcwWithdrawalMeta;

export type WalletsWithdrawalsLastAddressesResponse = WalletsWithdrawalsLastAddressesResponseUnion[];

// request
export interface WithdrawalsGetNcwAddressesRequest {
  limit?: number;
}

// response
export type WithdrawalsGetNcwAddressesResponse = NcwInfo[];

export interface NcwInfo {
  account_id: string;
  email: string;
}

// request
export interface WalletsWithdrawalsHistoryRequest {
  limit?: number;
  offset?: number;
  status?: WithdrawalStatus[];
}

// response
export interface WalletsWithdrawalsHistoryResponse {
  items: WithdrawalWallets[];
  limit?: number;
  offset?: number;
  total: number;
}

export interface WithdrawalWallets {
  account_id: string;
  amount: number;
  client_ip?: string;
  created_at: Date;
  currency: string;
  id: string;
  meta?: Meta;
  service: string;
  status: WithdrawalStatus;
  to_address: string;
  txid?: string;
  updated_at: Date;
}

export interface Meta {
  address?: string;
  account_id?: string;
  email?: string;
}

// request
interface CreateWithdrawalIssueRequest {
  id: string;
  message: string;
}

// response
interface WithdrawalIssueResponse {
  id: number;
  group: SupportTaskGroup;
  data: object;
  time: string;
  user_id: number;
}

type SupportTaskGroup = 'withdrawals';

// request
interface DeleteWithdrawalRequest {
  id: string;
}

interface ConfirmWithdrawalRequest {
  code: string;
}

interface GetPromoRequest {
  lang?: string;
}
interface GetWithdrawalRequest {
  id: string;
}

// response
interface ChangeLinkJsonRpcRequest {
  name?: string;
  comment?: string;
  link_id: number;
}
interface CreateLinkRequest {
  name?: string;
  comment?: string;
}
interface DeleteSocialAuthRequest {
  id: number;
}

// response
interface PromoCodeShort {
  code: string;
}

// request
interface GetPromoRequest {
  lang?: string;
}

// response
interface Landing {
  id: number;
  category: string;
  url: string;
  image: string;
}

// request
export interface ApiStatisticsRequest {
  start_date?: string;
  end_date?: string;
  discreteness?: Discreteness;
}
interface AccountStatisticsRequest {
  start_time?: string;
  end_time?: string;
  discreteness?: Discreteness;
  promo_code?: string;
}

interface GetAccountStatisticsResponse {
  items: {
    timestamp: number;
    value?: number | string;
  }[];
}
export type Discreteness = 'months' | 'weeks' | 'days'; // | 'hours'

// response
interface ReferralsResponse {
  items: ReferralsItem[];
}

//request
export interface StatisticsGroupRequest {
  discreteness?: Discreteness;
  end_date?: string;
  group: AffiliateGroup;
  start_date?: string;
}

export enum AffiliateGroup {
  Browser = 'browser',
  Farm = 'farm',
  Nft = 'nft',
  Pool = 'pool',
}

// request
interface PaymentStatisticsRequest {
  farm_ids?: number[];
  worker_ids?: string[];
  start_time?: string;
  end_time?: string;
  discreteness?: Discreteness;
  miner_ids?: number[];
  payment_type_ids?: number[];
}

// response
interface GetPaymentStatisticsResponse {
  items: PaymentStatisticItem[];
}

interface PaymentStatisticItem {
  value: number;
  timestamp: number;
}

export type paymentType = 0 | 1 | 3;
// payment type
// Default = 0
// Mining = 1
// Referral = 3

interface ActivityStatisticsRequest {
  discreteness?: Discreteness;
  end_time?: string;
  farm_ids?: number[];
  miner_ids?: number[];
  payment_type_ids?: paymentType[];
  start_time?: string;
  worker_ids?: string[];
}

interface GetAggregatedAccountStatisticsResponse {
  items: {
    amount?: number | string;
    farm_id: number;
    workers_count: number;
  }[];
}
interface GetWorkersActivityStatisticsResponse {
  items: {
    value?: string | number;
    timestamp: number;
  }[];
}

// request
interface GetPagesRequest {
  type: PageType;
  language?: string;
  limit?: number;
  offset?: number;
}

type PageType = 'news' | 'digest';

// response
interface GetPagesResponse {
  total: number;
  items: Page[];
}

interface Page {
  id: number;
  name: string;
  url: string;
  title: string;
  description: string;
  body: string;
  image: string;
  published_at: string;
  tags: string[];
  placing: string[];
}

// response
export interface SocialAuth {
  id: number;
  provider: 'facebook' | 'google-oauth2' | 'apple-id' | 'vk-oauth2' | 'twitter' | 'telegram';
  uid: string;
  extra_data: string;
  user_id: number;
  created: string;
  modified: string;
  is_ncw?: boolean;
}

interface GetAddressesRequest {
  limit?: number;
}

interface JsonRpcCreateWithdrawalRequest {
  address: string;
  captcha?: string;
  amount: number;
  type?: WithdrawalType;
  x_real_ip?: string;
}

interface PromoCodeStatsResponse {
  timestamp: number;
  code: string;
  value: number;
}
interface CtNftReferralPayments {
  timestamp: number;
  account_id: number;
  promo_code: string;
  amount: number;
}
interface GetLastAddressesRequest {
  blockchain: string;
}

// response
interface GetLastAddressesResponse {
  addresses?: string[];
}
interface ActionsResponse {
  items: ActionsItem[];
}

interface ActionsItem {
  timestamp: number;
  account_id: number;
  click?: number;
  download?: number;
  install?: number;
}
interface ReconfirmWithdrawalRequest {
  id: string;
}

// request
export interface NcwFindAccountRequest {
  email: string;
}

// response
export type NcwFindAccountResponse = FindAccountResponse | null;

export interface FindAccountResponse {
  account_id: string;
  email: string;
}

// request
export interface NcwLastNcwAddressesRequest {
  limit?: number;
}

// response
export type NcwLastNcwAddressesResponse = lastNcwInfo[];

export interface lastNcwInfo {
  account_id: string;
  email?: string;
}

// request
interface CancelWithdrawalRequest {
  code: string;
}

// request
interface GetPromoRequest {
  lang?: string;
}

// response
interface Landing {
  id: number;
  category: string;
  url: string;
  image: string;
}

export enum Product {
  CT = 'ct',
  Farm = 'farm',
  Pool = 'pool',
}

export interface StatisticsV2ClicksRequest {
  account_id?: number;
  discreteness?: Discreteness;
  end_date?: string;
  product_ids?: Product[];
  start_date?: string;
}
interface CtMiningPoolMinersItem {
  timestamp?: number;
  parent_id: string;
  count: number;
}

interface ContentCategoryPagesRequest {
  categories: PageCategory[];
  language?: string;
  limit?: number;
  offset?: number;
  placing?: string[];
}

export interface ContentPage {
  actions?: PublicPageAction[];
  body: string;
  category?: PageCategory;
  description: string;
  id: number;
  image: string;
  meta?: { [key: string]: any };
  name: string;
  options: PageOptions;
  placing: string[];
  published_at: string;
  tags: string[];
  title: string;
  url: string;
}

interface ContentCategoryPagesResponse {
  items: ContentPage[];
  total: number;
}

export interface PublicPageAction {
  title: string;
  url: string;
}

export interface PageOptions {
  priority: number;
  throttle: number;
}

export enum PageCategory {
  Banners = 'banners',
  Modals = 'modals',
  News = 'news',
}

export type StatisticsV2ClicksResponse = ClicksV2StatisticsItem[];

export interface ClicksV2StatisticsItem {
  advert_id: string;
  timestamp: number;
  value: number;
}

export interface UsersMeSetEmailRequest {
  email: string;
  next_url?: string;
}

export enum UsersMeSetEmailError {
  EmailAlreadyConfirmed = 6000,
  Throttled = 6003,
}

export type ContestsHalloween2024ParticipantGetResponse = PublicParticipant | null;

export interface PublicParticipant {
  account_id: string;
  can_open_chest?: boolean;
  state: PublicState;
}

export interface DefinitionsInterface {
  PublicState?: PublicState;
}

export interface PublicState {
  candy_coins?: number;
  chest_opened?: number;
}

export interface JsonRpcApi {
  'statistics.v2.clicks': (request: StatisticsV2ClicksRequest) => ClicksV2StatisticsItem[];
  'statistics.referrals': (request: ApiStatisticsRequest) => ReferralsResponse;
  'statistics.referrals.payments': (request: StatisticsGroupRequest) => ReferralPaymentsResponse;
  'statistics.pool_miners': (request: ApiStatisticsRequest) => CtMiningPoolMinersItem[];
  'statistics.payments': (request: ApiStatisticsRequest) => ReferralPaymentsResponse;
  'ping': () => void;
  'auth': (request: AuthRequest) => Account;
  'content.pages': (request: GetPagesRequest) => GetPagesResponse;
  'contests.halloween_2024.participant.get': () => ContestsHalloween2024ParticipantGetResponse;
  'currencies': () => CurrenciesResponse;
  'currencies.rate': (request: GetLastRateRequest) => GetLastRateResponse;
  'users.me.links.balances': () => LinkBalance[];
  'users.me.links': () => Link[];
  'promo.get_pool_landings': (request: GetPromoRequest) => Landing[];
  'withdrawals.get': (request: GetWithdrawalsRequest) => WithdrawalsResponse;
  'currencies.history': (request: GetHistoryRequest) => [number, number][];
  'mining.pool.list': () => void;
  'mining.nft.start': () => void;
  'mining.nft.stop': () => void;
  'mining.nft.list': () => NftMining[];
  'mining.nft.info': () => NftMiningAddressInfo[];
  'mining.pool.miners': () => TMining[];
  'mining.pool.start': () => TMining[];
  'mining.pool.stop': () => void;
  'user.auth': () => void;
  'content.category_pages': (request: ContentCategoryPagesRequest) => ContentCategoryPagesResponse;
  'nft.promo-codes': () => NftPromoCode[];
  'users.me': () => {
    id: number;
    email: string;
    email_verified: boolean;
    email_verified_at: string | null;
    date_joined: number;
    tags: string[];
    min_withdrawal_amount?: string;
    ncw_min_withdrawal_amount?: string;
    is_banned: boolean;
  };
  'farm.promo-codes': () => FarmPromoCode[];
  'fcm.register': (request: FcmRegisterRequest) => void;
  'support.create_issue': (request: CreateIssueRequest) => CreateIssueResponse;
  'wallets.balance': () => WalletsBalanceResponse[];
  'wallets.pending.balance': (request: WalletsPendingBalanceRequest) => WalletsPendingBalanceResponse;
  'wallets.withdrawals.last_addresses': () => WalletsWithdrawalsLastAddressesResponse;
  'wallets.withdrawals.history': (request: WalletsWithdrawalsHistoryRequest) => WalletsWithdrawalsHistoryResponse;
  'wallets.withdrawals.create': (request: WalletsWithdrawalsCreateRequest) => WalletsWithdrawalsCreateResponse;
  'wallets.withdrawals.get': (request: WalletsWithdrawalsGetRequest) => WalletsWithdrawalsGetResponse;
  'wallets.withdrawals.cancel': (request: WalletsWithdrawalsCancelRequest) => WalletsWithdrawalsCancelResponse;
  'wallets.check_address': (request: WalletsCheckAddressRequest) => WalletsCheckAddressResponse;
  'withdrawals.create_issue': (request: CreateWithdrawalIssueRequest) => WithdrawalIssueResponse;
  'withdrawals.delete_withdrawal': (request: DeleteWithdrawalRequest) => Withdrawal;
  'withdrawals.reconfirm_withdrawal': (request: ReconfirmWithdrawalRequest) => Withdrawal;
  'withdrawals.cancel_withdrawal_confirm': (request: CancelWithdrawalRequest) => Withdrawal;
  'withdrawals.confirm_withdrawal': (request: ConfirmWithdrawalRequest) => Withdrawal;
  'withdrawals.get_withdrawal': (request: GetWithdrawalRequest) => Withdrawal;
  'withdrawals.get_addresses': (request: GetAddressesRequest) => string[];
  'withdrawals.create_withdrawal': (request: JsonRpcCreateWithdrawalRequest) => Withdrawal;
  'withdrawals.get_ncw_addresses': (request: WithdrawalsGetNcwAddressesRequest) => WithdrawalsGetNcwAddressesResponse;
  'promo.get_videos': (request: GetPromoRequest) => Record<string, string>;
  'nft.create-promo-code': () => NftPromoCode;
  'users.me.update_link': (request: ChangeLinkJsonRpcRequest) => Link;
  'users.me.create_link': (request: CreateLinkRequest) => Link;
  'users.me.set_email': (request: UsersMeSetEmailRequest) => void;
  'users.me.delete_social_auth': (request: DeleteSocialAuthRequest) => void;
  'farm.create-promo-code': () => PromoCodeShort;
  'promo.get_landings': (request: GetPromoRequest) => Landing[];
  'farm.get_payments_statistics': (request: PaymentStatisticsRequest) => GetPaymentStatisticsResponse;
  'farm.get_accounts_statistics': (request: AccountStatisticsRequest) => GetAccountStatisticsResponse;
  'farm.get_activity_statistics': (request: ActivityStatisticsRequest) => GetWorkersActivityStatisticsResponse;
  'farm.get_aggregate_activity_statistics': (
    request: ActivityStatisticsRequest
  ) => GetAggregatedAccountStatisticsResponse;
  'users.me.social_auth': () => SocialAuth[];
  'nft.get_accounts_statistics': (request: ApiStatisticsRequest) => PromoCodeStatsResponse[];
  'nft.get_payments_statistics': (request: ApiStatisticsRequest) => CtNftReferralPayments[];
  'nft.get_tokens_statistics': (request: ApiStatisticsRequest) => PromoCodeStatsResponse[];
  'nft.get_clicks_statistics': (request: ApiStatisticsRequest) => PromoCodeStatsResponse[];
  'nft.get_apply_statistics': (request: ApiStatisticsRequest) => PromoCodeStatsResponse[];
  'get_system_settings': () => any;
  'ncw.get_last_addresses': (request: GetLastAddressesRequest) => GetLastAddressesResponse;
  'ncw.find_account': (request: NcwFindAccountRequest) => NcwFindAccountResponse;
  'ncw.last_ncw_addresses': (request: NcwLastNcwAddressesRequest) => NcwLastNcwAddressesResponse;
  'statistics.actions': (request: ApiStatisticsRequest) => ActionsResponse;

  'error': () => void;
}

export interface JsonRpcApiMethods {
  'app.reload': () => void;
  'wallets.pending_payment': (message: WalletsPendingPaymentRequest) => void;
  'hsh.user.registration': (message: HshUserRegistrationRequest) => void;
  'wallets.withdrawals.change': (message: WalletsWithdrawalsChangeRequest) => void;
  'mining.pool.miner': (message: PoolMinerMessage) => void;
  'mining.pool.mining': (message: PoolMiningMessage) => void;
  'mining.nft': (message: NftMining[]) => void;
  'mining.nft.info': (message: NftMiningAddressInfo[]) => void;
}
