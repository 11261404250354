import { useCallback, useEffect, useState } from 'react';
import styles from './Pagination.module.scss';
import { Button, ButtonVariant } from '@shared/Button/Button';
import { ArrowLeftSmSvg } from '@assets/colorless';
import { ArrowRightSvg } from '@assets/colored';
import cn from 'classnames';

export type PaginationProps = {
  index: number;
  totalPage: number;
  handleChangePage: (page: number) => void;
};

export const Pagination = (props: PaginationProps) => {
  const { index, totalPage, handleChangePage } = props;

  const [step, setStep] = useState(index > 0 ? index : 1);
  const [pages, setPages] = useState<(string | number)[]>([]);

  useEffect(() => {
    setStep(index > 0 ? index : 1);
  }, [index]);

  const range = useCallback((start: number, end: number) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
  }, []);

  const getPagination = useCallback((currentPage: number, totalPageCount: number, siblingCount: number = 1) => {
    const totalPageNumbers = siblingCount + 5;

    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);
      return [...leftRange, '...', totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
      return [firstPageIndex, '...', ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, '...', ...middleRange, '...', lastPageIndex];
    }

    return [];
  }, []);

  useEffect(() => {
    setPages(getPagination(step, totalPage));
  }, [step, totalPage, getPagination]);

  const handleClick = useCallback(
    (idx: number) => {
      setStep(idx);
      handleChangePage(idx);
    },
    [handleChangePage]
  );

  return totalPage && totalPage >= 1 ? (
    <div className={styles.pagination}>
      <Button
        type="button"
        Icon={<ArrowLeftSmSvg />}
        size="medium"
        disabled={step === 1}
        className={cn(styles.pagination__item, styles.pagination__item_svg)}
        onClick={() => handleClick(Math.max(step - 1, 1))}
      />
      {pages.map((e, idx) => {
        return (
          <div key={idx}>
            {typeof e === 'string' ? (
              <Button type="button" size="medium" variant={ButtonVariant.Text} className={styles.pagination__item_dot}>
                {e}
              </Button>
            ) : (
              <Button
                type="button"
                size="medium"
                variant={step === e ? ButtonVariant.Primary : ButtonVariant.Default}
                className={cn(styles.pagination__item, step === e && styles[`pagination__item--active`])}
                onClick={() => handleClick(+e)}
              >
                {+e}
              </Button>
            )}
          </div>
        );
      })}
      <Button
        type="button"
        Icon={<ArrowRightSvg />}
        size="medium"
        disabled={step === totalPage}
        className={cn(styles.pagination__item, styles.pagination__item_svg)}
        onClick={() => handleClick(Math.min(step + 1, totalPage))}
      />
    </div>
  ) : null;
};
