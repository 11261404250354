import React from 'react';
import styles from './TopInfo.module.scss';

import EcoSystemIcon from '@assets/colored/eco-system.svg';
import { useTranslation } from 'react-i18next';
export default function TopInfo() {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <img src={EcoSystemIcon} />
      <h1>{t('MoreBTC.Page.Title')}</h1>
      <p>{t('MoreBTC.Page.Description')}</p>
    </div>
  );
}
