import React, { useEffect, useMemo } from 'react';
import { BrowserPromoView } from './view/BrowserPromoView';
import { BrowserSubheader } from '../headersComponents/BrowserSubheader';
import { useTranslation } from 'react-i18next';
import { HelmetHead } from '../headersComponents/HelmetHead';
import { useDispatch, useSelector } from 'react-redux';
import getBalances from '@service/actions/getBalances';
import getBrowserLand from '@service/actions/getBrowserLand';
import getBrowserVideo from '@service/actions/getBrowserVideo';
import i18next from 'i18next';
import { product } from '@utils/productName';
import { Root } from '@service/reducers';

export const BrowserPromo = () => {
  const { t } = useTranslation();
  const store = useSelector((store: Root) => store);
  const dispatch = useDispatch();
  useEffect(() => {
    !store.balance.get_balance &&
      !store.balance.balance_request &&
      !store.balance.balance_error &&
      dispatch(getBalances());
    !store.browserPromo.land_get && dispatch(getBrowserLand());
  }, [dispatch, store.balance.get_balance, store.browserPromo.land_get, store.balance.balance_error]);
  useEffect(() => {
    dispatch(getBrowserVideo(i18next.language));
  }, [i18next.language]);
  const list = useMemo(
    () => store.balance.links?.filter((item) => item.type === '' || item.type === null).map((item) => '' + item.id),
    [store.balance.links]
  );

  return (
    <>
      <HelmetHead title={t('Promo')} product={product.ct} />
      <BrowserSubheader />
      <BrowserPromoView list={list} land={store.browserPromo.land} />
    </>
  );
};
