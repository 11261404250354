import styles from './WithdrawalTag.module.scss';

export default function WithdrawalTag({ state }: { state: keyof typeof tagConfig }) {
  return <div className={`${styles.tag} ${tagConfig[state].class}`}>{tagConfig[state].name}</div>;
}

export const tagConfig = {
  1: {
    name: 'created',
    class: styles.tag_created,
  },
  2: {
    name: 'confirmed',
    class: styles.tag_confirmed,
  },
  3: {
    name: 'canceled',
    class: styles.tag_canceled,
  },
  7: {
    name: 'processed',
    class: styles.tag_processed,
  },
  9: {
    name: 'complete',
    class: styles.tag_complete,
  },
  11: {
    name: 'reconfirm',
    class: styles.tag_reconfirm,
  },
  12: {
    name: 'reconfirmed',
    class: styles.tag_reconfirmed,
  },
};
