import { useTranslation } from 'react-i18next';
import { SmallList } from '@shared/SmallList/SmallList';
import styles from './PoolTabBanners.module.scss';

export type BannerTabsProps = {
  setPromo: (val: string) => void;
  promoAll: string[];
};

export default function PoolTabBanners({ setPromo, promoAll }: BannerTabsProps) {
  const { t } = useTranslation();
  return (
    <div className={styles.tab}>
      <div className={styles.tab__title}>{t('Link Settings')}</div>
      <div className={styles.tab__list}>
        <SmallList
          title={t('Promo Code')}
          List={
            <>
              {promoAll?.map((item) => (
                <div key={item} className={styles.tab__promo} onClick={() => setPromo(item)}>
                  {item}
                </div>
              ))}
            </>
          }
        />
      </div>
    </div>
  );
}
