import { RuSvg, ItSvg, PtSvg, DeSvg, EnSvg, EsSvg, FrSvg } from '@shared/Lang/Lang';
export const bannerSizes = [
  { value: '120x600', label: '120x600' },
  { value: '160x600', label: '160x600' },
  { value: '250x250', label: '250x250' },
  { value: '300x250', label: '300x250' },
  { value: '320x50', label: '320x50' },
  { value: '320x100', label: '320x100' },
  { value: '320x480', label: '320x480' },
  { value: '468x60', label: '468x60' },
  { value: '728x90', label: '728x90' },
  { value: '768x1024', label: '768x1024' },
  { value: '970x250', label: '970x250' },
];

export const bannerLang = [
  {
    value: 'ru',
    label: 'Russian',
    Icon: <RuSvg />,
  },
  {
    value: 'en',
    label: 'English',
    Icon: <EnSvg />,
  },
  {
    value: 'es',
    label: 'Spanish',
    Icon: <EsSvg />,
  },
  {
    value: 'fr',
    label: 'French',
    Icon: <FrSvg />,
  },
  {
    value: 'de',
    label: 'German',
    Icon: <DeSvg />,
  },
  {
    value: 'it',
    label: 'Italian',
    Icon: <ItSvg />,
  },
  {
    value: 'pt',
    label: 'Portuguese',
    Icon: <PtSvg />,
  },
];
