import { Steps } from '../Steps/Steps';
import { ReactComponent as StarCardSvg } from './icons/star-card.svg';
import { ReactComponent as CrowdSvg } from './icons/crowd.svg';
import { ReactComponent as LaptopSvg } from './icons/laptop.svg';
import { ReactComponent as MoneyBagSvg } from './icons/money-bag.svg';

export type StepsGroupProps = {
  titles: (string | JSX.Element)[];
  images?: React.FC<React.SVGProps<SVGSVGElement>>[];
};

export function StepsGroup({ titles, images }: StepsGroupProps) {
  return (
    <Steps>
      <Steps.Item
        Icon={images?.[0] || StarCardSvg}
        title={titles[0] as string | (string & JSX.Element)}
        withArrow={false}
      />
      <Steps.Item Icon={images?.[1] || CrowdSvg} title={titles[1] as string | (string & JSX.Element)} />
      <Steps.Item Icon={images?.[2] || LaptopSvg} title={titles[2] as string | (string & JSX.Element)} />
      <Steps.Item Icon={images?.[3] || MoneyBagSvg} title={titles[3] as string | (string & JSX.Element)} />
    </Steps>
  );
}
