import { ReactNode, useCallback, useEffect, useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './SimpleBanner.module.scss';
import { CrossSvg, InfoSvg } from '@assets/colorless';

export type SimpleBannerProps = {
  src: string;
  title: string;
  text: string | React.ReactNode;
  href: string;
  linkText?: boolean;
  btnText?: string;
  imgHeight?: number;
  Label?: ReactNode;
  cookie?: string;
};

export function SimpleBanner({
  src,
  title,
  imgHeight,
  cookie,
  Label,
  text,
  href,
  linkText,
  btnText,
}: SimpleBannerProps) {
  const { t } = useTranslation();
  const { show, close, onClick } = useSimpleBanner(href, cookie);
  if (!show) {
    return null;
  }
  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.cross} onClick={close}>
        <CrossSvg width={12} height={12} />
      </div>
      {Label}
      <img src={src} alt="img" className={styles.icon} style={{ height: imgHeight }} />
      <div className={styles.title}>{title}</div>
      <div className={styles.text} onClick={(e) => linkText && e.stopPropagation()}>
        {text}
      </div>
      <div className={styles.btn}>
        <InfoSvg />
        {btnText || t('VIEW DETAILS')}
      </div>
    </div>
  );
}

export const useSimpleBanner = (href: string, cookie?: string) => {
  const [show, setShow] = useState(false);
  const onClick = useCallback(() => window.open(href), [href]);
  const close = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      if (cookie) {
        window.localStorage.setItem(cookie, 'true');
        setShow(false);
      }
    },
    [cookie]
  );
  useEffect(() => {
    if (cookie && window.localStorage.getItem(cookie) !== 'true') {
      setShow(true);
    }
  }, [cookie]);
  return { setShow, show, onClick, close };
};
