import { useEffect } from 'react';

export const useDetectMobileType = () => {
  useEffect(() => {
    if (/iPhone|iPod|iPad|Mac/i.test(navigator.userAgent) && navigator.maxTouchPoints) {
      window.document.body.classList.add('ios');
    }

    if (/Android/gi.test(window.navigator.userAgent)) {
      window.document.body.classList.add('android');
    }
  }, []);
};
