import { useRef } from 'react';
import styles from './PromoModal.module.scss';
import { ReactComponent as ArrowIcon } from '../../assets/colorless/arrow-right-sm.svg';
import ModalLayout from '../ModalLayout/ModalLayout';
import { ContentPage } from '../../backend/JsonRpcApi';
import { Trans } from 'react-i18next';
import { Picture } from '@shared/Picture/Picture';

type TPromoModalProps = {
  opened: boolean;
  onClose: () => void;
  modal: ContentPage;
};

const TEN_HOURS_IN_MS = 36000000;

export const PromoModal = ({ onClose: modalClose, opened, modal }: TPromoModalProps) => {
  const { title, image, body, meta, actions } = modal;
  const { Title, Content, Footer, Description, Header, ModalLayoutForPromo, Button } = ModalLayout;

  const setLocalStorage = (id: string) => {
    const recordedData = localStorage.getItem('viewedOffers') || '{}';
    let viewedOffers = JSON.parse(recordedData);

    viewedOffers[id] = { showTime: Date.now() };
    localStorage.setItem('viewedOffers', JSON.stringify(viewedOffers));
  };

  const handleClose = () => {
    setLocalStorage(`viewedModal_${modal.id}`);
    const throttleTime = Date.now() + TEN_HOURS_IN_MS;
    localStorage.setItem('modalsThrottle', throttleTime.toString());
    modalClose();
  };

  const onClose = (url: string) => {
    window.open(url, '_blank');
    handleClose();
  };

  const checkboxRef = useRef<HTMLInputElement>(null);
  return (
    <ModalLayoutForPromo onClose={handleClose} isOpen={opened} ref={checkboxRef}>
      <Header image>
        <div className={styles.imageWrapper}>
          <picture>
            {meta && meta.image_mob && <source srcSet={meta.image_mob} media="(max-width: 767px)" />}
            <img src={image} alt={title} className={styles.image} />
          </picture>
        </div>
      </Header>
      <Content align="left" padding="small">
        <Title size="small">{title}</Title>
        <Description>
          <Trans i18nKey={body} components={{ b: <b /> }} />
        </Description>
      </Content>
      {actions && actions.length > 0 && (
        <Footer gray>
          {actions.map((item) => (
            <Button key={item.url} color="blue" onClick={() => onClose(item.url)} wide>
              {item.title}
              <ArrowIcon />
            </Button>
          ))}
        </Footer>
      )}
    </ModalLayoutForPromo>
  );
};
