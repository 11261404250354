import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import { ArrowLeftSmSvg } from '@assets/colorless';
import { MAIN_URL } from '@utils/links';
import styles from './Header.module.scss';

export default function SimpleMobileHeader({ title }: { title: string }) {
  const history = useHistory();

  const handleBack = () => {
    history.push(MAIN_URL);
  };

  return (
    <div className={cn(styles.mob_container, styles.mob_container_simple)}>
      <ArrowLeftSmSvg onClick={handleBack} />
      <span>{title}</span>
    </div>
  );
}
