import React, { FormEvent, useState } from 'react';
import styles from './EmailConfirmation.module.scss';
import { EmailNotVerifiedIcon } from './assets';
import { Button, ButtonVariant } from '@shared/Button/Button';
import { ArrowLeftSvg, ArrowRightSvg, EmailSvg } from '@assets/colorless';
import { rpc } from '../../backend/Rpc';
import { useTranslation } from 'react-i18next';
import { BROWSER_DASH } from '@utils/links';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

type EmailConfirmationFormProps = {
  email: string;
  onSubmitted: (email: string) => void;
  onError: (code: number) => void;
  onGoBack: () => void;
};

export const EmailConfirmationForm = ({ email, onSubmitted, onError, onGoBack }: EmailConfirmationFormProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(email);

  const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

  const onSubmit = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const nextUrl = searchParams.get('next');

    rpc
      .transmit('users.me.set_email', { email: value, next_url: nextUrl ?? undefined })
      .then(() => onSubmitted(value))
      .catch((e) => onError(e.code));
  };

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <div className={styles.iconContainer}>
          <EmailNotVerifiedIcon />
        </div>
        <div className={styles.innerContainer}>
          <h1 className={styles.title}>{t('Aff.Verify.Title')}</h1>
          <p className={styles.description}>{t('Aff.Verify.Description')}</p>

          <div className={styles.inputContainer}>
            <EmailSvg />
            <input
              value={value}
              onChange={(e) => setValue(e.target.value)}
              placeholder={t('Aff.Verify.Email.Filler')}
            />
          </div>
        </div>
      </div>

      <div className={styles.footer}>
        <div className={styles.buttonsGroup}>
          <Button
            className={styles.button}
            type={'button'}
            variant={ButtonVariant.Primary}
            disabled={!isEmail}
            onClick={onSubmit}
          >
            {t('Aff.Verify.Verify.Btn')}
            <ArrowRightSvg />
          </Button>
        </div>
      </div>
    </div>
  );
};
