import { BANNER_CLEAR, BANNER_ERROR, BANNER_REQUEST, BANNER_SUCCESS } from '../constant';

const initialState = {
  banners: [],
  request: false,
  error: false,
};

type ActionType = {
  type: string;
  banners?: BannerType[];
};

export type BannerType = {
  created: string;
  default_images: Image;
  default_lang: string;
  height: number;
  id: number;
  images: Image;
  sizes: string;
  status: number;
  weight: number;
  width: number;
};

type Image = {
  '': string;
  '2x': string;
};

export type rootBanner = typeof initialState;

export default function bannerReducer(state = initialState, action: ActionType) {
  switch (action.type) {
    case BANNER_REQUEST: {
      return {
        ...state,
        request: true,
        error: false,
      };
    }
    case BANNER_ERROR: {
      return {
        ...state,
        request: false,
        error: true,
      };
    }
    case BANNER_SUCCESS: {
      return {
        ...state,
        banners: action.banners,
        request: false,
        error: false,
      };
    }
    case BANNER_CLEAR: {
      return {
        banners: [],
        request: false,
        error: false,
      };
    }
    default:
      return state;
  }
}
